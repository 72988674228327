import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Field } from 'formik'
import classNames from 'classnames'
import moment from 'moment'
import {
  Card,
  Form,
  GridWrapper,
  GridItem,
  Heading3,
  List,
  ListItem,
  FilledButton,
} from '@jsluna/react'
import { SelectField } from '@jsluna/form/extensions/formik'

import Section from 'src/components/Section'
import DocumentLink from 'src/components/DocumentLink'
import { APPROVED_ID } from 'src/modules/Nominations/statuses'

const statusToOption = ({ id, title }) => ({ id, label: title, value: id })

function NominationsDetailForm({
  nomination: {
    id,
    type,
    cycle,
    category,
    generating,
    url,
    lastUpdatedByName = '',
    lastUpdatedDate = '',
    status,
    createdAt,
  },
  handleSubmit: onSubmit,
  pristine,
  submitting,
  cancel,
  isReadOnly,
  statuses,
  showUrl,
}) {
  return (
    <Form onSubmit={onSubmit}>
      <Section>
        <Card>
          <div className="ln-o-flag@sm ln-u-push-bottom">
            <Heading3 className="ln-o-flag__body ln-u-soft-right">
              {cycle.title}: {category.title} Nomination
            </Heading3>
            {showUrl && <DocumentLink {...{ generating, url, id, type, createdAt }} />}
          </div>
          <div
            className={classNames(
              'ln-c-fieldset',
              isReadOnly ? '' : 'ln-c-form-group ln-c-form-group--with-separator'
            )}
          >
            <GridWrapper matrix>
              <GridItem size="1/1">
                <div className="ln-c-label ln-u-soft-right ln-u-flush-bottom">Cycle</div>
                <Link to={`/cycles/${cycle.businessUnit}/${cycle.id}/details`}>{cycle.title}</Link>
              </GridItem>
              <GridItem size="1/1">
                <div className="ln-c-label ln-u-soft-right ln-u-flush-bottom">Category</div>
                <span>{category.title}</span>
              </GridItem>
              <GridItem size="1/2@sm">
                <Field
                  name="status"
                  label="Status"
                  component={SelectField}
                  disabled={isReadOnly}
                  options={statuses.map(statusToOption)}
                  placeholder={false}
                />
              </GridItem>
              {status === APPROVED_ID && lastUpdatedByName !== '' && lastUpdatedDate !== '' && (
                <GridItem size="1/1">
                  <div className="ln-c-label ln-u-soft-right ln-u-flush-bottom">
                    Last updated by
                  </div>
                  <span data-control="last-updated">
                    {lastUpdatedByName} on {moment(lastUpdatedDate).format('Do MMM YYYY')}
                  </span>
                </GridItem>
              )}
            </GridWrapper>
          </div>
          {!isReadOnly && (
            <List type="matrix">
              <ListItem type="matrix">
                <FilledButton disabled={pristine || submitting} type="submit">
                  Save
                </FilledButton>
              </ListItem>
              <ListItem type="matrix">
                <FilledButton color="dark" disabled={submitting} onClick={cancel}>
                  Cancel
                </FilledButton>
              </ListItem>
            </List>
          )}
        </Card>
      </Section>
    </Form>
  )
}

NominationsDetailForm.propTypes = {
  nomination: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    cycle: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      businessUnit: PropTypes.string,
    }),
    category: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
    generating: PropTypes.bool,
    url: PropTypes.string,
    lastUpdatedByName: PropTypes.string,
    lastUpdatedDate: PropTypes.string,
    status: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  showUrl: PropTypes.bool,
}

NominationsDetailForm.defaultProps = {
  pristine: false,
  submitting: false,
  isReadOnly: false,
  showUrl: false,
}

export default NominationsDetailForm
