/* eslint-disable max-len */
import { createSelector } from 'reselect'

const defaults = {
  users: { key: 'name', ascending: false },
  cycles: { key: 'dates', ascending: false },
  spacePlans: { key: 'dates', ascending: false },
  spaceGroups: { key: 'position', ascending: true },
  spaces: { key: 'spaceGroupPosition', ascending: true },
  cycleSpaces: { key: 'spaceName', ascending: true },
}

const selectTables = state => state.ui && state.ui.tables
const selectTableByType = type =>
  createSelector(selectTables, state => state[type] || defaults[type] || {})

export { selectTables, selectTableByType }
