import Api from 'src/api/Base'

class PostGroup extends Api {
  requestUrl = `/api/group`

  method = 'POST'

  formatRequestData = params => params.group
}
export default PostGroup
