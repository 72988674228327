import React from 'react'
import PropTypes from 'prop-types'
import { GridWrapper, GridItem } from '@jsluna/react'

import LabelledText from 'src/components/Form/LabelledText'

import ReadOnlyCategories from './ReadOnlyCategories'

const ReadOnlyDetails = ({
  startDate,
  endDate,
  title,
  comment,
  selectedCategories,
  storeTitleComment,
  isProposition,
}) => (
  <GridWrapper matrix>
    <GridItem size="1/2">
      <LabelledText label="Start Date" text={startDate} />
    </GridItem>
    <GridItem size="1/2">
      <LabelledText label="End Date" text={endDate} />
    </GridItem>
    <GridItem size="1/1">
      <LabelledText label="Theme" text={title} />
    </GridItem>
    <GridItem size="1/1">
      <LabelledText label="Proposition POS" text={isProposition ? 'YES' : 'NO'} />
    </GridItem>
    <GridItem size="1/2">
      <LabelledText label="Comment" text={comment} />
    </GridItem>
    <GridItem size="1/2">
      <LabelledText label="Store Title Comment" text={storeTitleComment} />
    </GridItem>
    <GridItem size="1/1">
      <ReadOnlyCategories categories={selectedCategories} />
    </GridItem>
  </GridWrapper>
)

ReadOnlyDetails.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  selectedCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      theme: PropTypes.string.isRequired,
      active: PropTypes.bool,
    })
  ).isRequired,
  title: PropTypes.string,
  comment: PropTypes.string,
  storeTitleComment: PropTypes.string,
  isProposition: PropTypes.bool,
}

ReadOnlyDetails.defaultProps = {
  title: '',
  comment: '',
  storeTitleComment: '',
  isProposition: false,
}

export default ReadOnlyDetails
