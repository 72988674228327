import PropTypes from 'prop-types'
import React from 'react'

import { GridItem } from '@jsluna/react'

import pluralize from 'src/utils/pluralize'

const Duration = ({ duration }) => (
  <GridItem size="1/1">
    <div className="ln-c-label ln-u-soft-right">Duration</div>
    <span>
      {duration} {pluralize(duration, 'day')}
    </span>
  </GridItem>
)

Duration.propTypes = {
  duration: PropTypes.number.isRequired,
}

export default Duration
