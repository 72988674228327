import loader from 'src/hoc/loader'

import Exports from './Exports'
import { fetchCycles } from '../Cycles/store'

const getData = async (props, dispatch) => {
  await dispatch(fetchCycles('food')).unwrap()
}

export default loader(getData)(Exports)
