import { combineReducers } from 'redux'

import loading from './loading/reducer'
import tables from './tables/reducer'
import cycles from './cycles/reducer'

export default combineReducers({
  loading,
  tables,
  cycles,
})
