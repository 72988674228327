import { createAsyncThunk } from '@reduxjs/toolkit'
import { DeleteShelfProductLayout, PostShelfProductLayout } from 'src/api/ShelfProductLayout'
import { handleCreateAsyncThunkResult } from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'ShelfProductLayout'
const buildThunkPrefix = (slice, thunkName) => `${slice}/${thunkName}`

const actionDeleteShelfProductLayout = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'deleteShelfProductLayout'),
  async (id, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const deleteShelfProductLayoutInstance = new DeleteShelfProductLayout(store, {
      params: { id },
    })
    const response = await handleCreateAsyncThunkResult(
      deleteShelfProductLayoutInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionCreateShelfProductLayout = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'createShelfProductLayout'),
  async (shelfProductLayout, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const postShelfProductLayoutInstance = new PostShelfProductLayout(store, {
      params: shelfProductLayout,
    })
    const response = await handleCreateAsyncThunkResult(
      postShelfProductLayoutInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

export { actionDeleteShelfProductLayout, actionCreateShelfProductLayout }
