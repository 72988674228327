import React, { Component } from 'react'
import { connect } from 'react-redux'

import keyBy from 'src/utils/keyBy'
import sortArray from 'src/utils/sortArray'

import { selectTableByType } from 'src/store/ui/tables/selectors'
import Table from './Table'

const resolve = (object, resolver) =>
  typeof resolver === 'function' ? resolver(object) : object[resolver]

const resolveRow = (columns, row) => {
  const cells = columns.map(({ key, name: label, link, value, sortValue, searchValue }) => {
    const resolvedDisplayValue = resolve(row, value)
    const resolvedSortValue = sortValue ? resolve(row, sortValue) || '' : resolvedDisplayValue
    const resolvedSearchValue = searchValue ? resolve(row, searchValue) : resolvedDisplayValue
    return {
      key,
      label,
      sortValue: resolvedSortValue,
      displayValue: resolvedDisplayValue,
      searchValue: resolvedSearchValue,
      link: resolve(row, link),
    }
  })

  return {
    id: row.id,
    text: cells
      .map(({ searchValue }) => searchValue)
      .join(' ')
      .toLowerCase(),
    cells,
    cellsByKey: keyBy(cells, 'key'),
  }
}

const resolveRows = (columns, rows) => rows.map(row => resolveRow(columns, row))

const sortRows = (state, type, rows, secondarySortColumn) => {
  const { key, ascending } = selectTableByType(type)(state)
  const sortedRows = sortArray(
    rows,
    `cellsByKey.${key}.sortValue`,
    !ascending,
    `cellsByKey.${secondarySortColumn}.sortValue`
  )
  return sortedRows
}

export const filterRows = (rows, query = '') => {
  const filterQuery = String(query).toLowerCase()
  return rows.filter(({ text = '' }) => text.includes(filterQuery))
}

export const buildMapStateToProps = () => {
  const buildRows = (state, { dataSelector, columns, type, search, secondarySortColumn }) => {
    const rows = dataSelector(state)
    const resolvedRows = resolveRows(columns, rows)

    const filteredRows = filterRows(resolvedRows, search)
    return sortRows(state, type, filteredRows, secondarySortColumn)
  }

  return (state, ownProps) => ({
    onSearchChange: ownProps.onSearchChange,
    rows: buildRows(state, ownProps),
    search: ownProps.search,
  })
}

const ConnectedTable = connect(buildMapStateToProps())(Table)

class TableWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = { search: '' }

    this.onSearchChange = this.onSearchChange.bind(this)
  }

  onSearchChange(event) {
    const search = event.target.value
    this.setState(() => ({ search }))
  }

  render() {
    const { search } = this.state
    return <ConnectedTable onSearchChange={this.onSearchChange} search={search} {...this.props} />
  }
}

export default TableWrapper
