export const SSPSA_NONFOOD_VERSION_CURRENT = 'current'
export const SSPSA_NONFOOD_VERSION_PREVIOUS = 'previous'
export const SSPSA_NONFOOD_VERSION_UPCOMING = 'upcoming'
export const SSPSA_NONFOOD_VERSION_PREVIEW = 'preview'

export const SSPSA_NONFOOD_VERSION_MESSAGES = {
  [SSPSA_NONFOOD_VERSION_CURRENT]: `SSPSA for the ${SSPSA_NONFOOD_VERSION_CURRENT} cycle not available.`,
  [SSPSA_NONFOOD_VERSION_PREVIOUS]: `SSPSA for the ${SSPSA_NONFOOD_VERSION_PREVIOUS} cycle not available.`,
  [SSPSA_NONFOOD_VERSION_UPCOMING]:
    'SSPSA for the next cycle is not yet available. A first look will be available from the Wednesday 2 weeks before plinth changeover, and then the final version will be accessible the day before.',
}

export const SSPSA_NONFOOD_VERSION_NO_SSPSA_GENERATED_MESSAGES = {
  [SSPSA_NONFOOD_VERSION_CURRENT]: `There is no Non Food SSPSA for the ${SSPSA_NONFOOD_VERSION_CURRENT} cycle for your store - your store is not ranged for any Non Food secondary spaces.`,
  [SSPSA_NONFOOD_VERSION_PREVIOUS]: `There is no Non Food SSPSA for the ${SSPSA_NONFOOD_VERSION_PREVIOUS} cycle for your store - your store is not ranged for any Non Food secondary spaces.`,
  [SSPSA_NONFOOD_VERSION_UPCOMING]: `There is no Non Food SSPSA for the ${SSPSA_NONFOOD_VERSION_UPCOMING} cycle for your store - your store is not ranged for any Non Food secondary spaces.`,
  [SSPSA_NONFOOD_VERSION_PREVIEW]: `There is no Non Food SSPSA for the ${SSPSA_NONFOOD_VERSION_PREVIEW} cycle for your store - your store is not ranged for any Non Food secondary spaces.`,
}

export const SSPSA_NONFOOD_VERSIONS = [
  SSPSA_NONFOOD_VERSION_UPCOMING,
  SSPSA_NONFOOD_VERSION_CURRENT,
  SSPSA_NONFOOD_VERSION_PREVIOUS,
  SSPSA_NONFOOD_VERSION_PREVIEW,
]
