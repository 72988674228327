import React from 'react'
import PropTypes from 'prop-types'
import HeaderCell from 'src/components/HeaderCell'
import { getLayoutTypeLabel } from 'src/utils/layoutTypes'
import Shelf from './Shelf'

const Shelves = ({ changeControl, shelves, layoutType }) => (
  <div className="ln-c-table-container">
    <table className="ln-c-table ln-c-table--fixed ln-u-text-align-center ln-u-border-bottom">
      <thead className="ln-c-table__header">
        <tr className="ln-c-table__header-row">
          <HeaderCell>{getLayoutTypeLabel(layoutType)}</HeaderCell>
          <HeaderCell>Change Type</HeaderCell>
          <HeaderCell>SKU</HeaderCell>
          <HeaderCell>Description</HeaderCell>
          <HeaderCell>HFSS</HeaderCell>
          <HeaderCell>Changed Field</HeaderCell>
          <HeaderCell>Old Value</HeaderCell>
          <HeaderCell>New Value</HeaderCell>
        </tr>
      </thead>
      <tbody className="ln-c-table__body ln-c-striped">
        {shelves.map(shelf => (
          <Shelf key={shelf.id} changeControl={changeControl} shelf={shelf} />
        ))}
      </tbody>
    </table>
  </div>
)

Shelves.propTypes = {
  changeControl: PropTypes.shape({}).isRequired,
  shelves: PropTypes.arrayOf(
    PropTypes.shape({
      shelf: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    })
  ),
  layoutType: PropTypes.string.isRequired,
}

Shelves.defaultProps = {
  shelves: [],
}

export default Shelves
