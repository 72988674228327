import PropTypes from 'prop-types'
import React from 'react'

import { DateRangePicker as RangePicker } from '@jsluna/react'
import moment from 'moment/moment'

const DateRangePicker = ({
  label,
  name,
  disabled,
  required,
  startDate,
  endDate,
  minDate,
  maxDate,
  numberOfMonths,
  onDatesChange,
  outOfRange,
}) => {
  const [focusedInput, setFocusedInput] = React.useState(null)

  const isOutsideRange = date =>
    !moment(date).isBetween(minDate || '1970-01-01', maxDate || '9999-12-31', 'day', '[]') ||
    outOfRange(date)

  return (
    <div data-control={name}>
      <label htmlFor={name} className="ln-c-label">
        {label}
        <span className="ln-u-visually-hidden">format YYYY-MM-DD</span>
      </label>
      <RangePicker
        startDate={startDate ? moment(startDate) : null}
        startDateId="start-date"
        endDate={endDate ? moment(endDate) : null}
        endDateId="end-date"
        displayFormat="Do MMM Y"
        isOutsideRange={isOutsideRange}
        onDatesChange={onDatesChange}
        onFocusChange={input => setFocusedInput(input)}
        numberOfMonths={numberOfMonths}
        keepOpenOnDateSelect={false}
        focusedInput={focusedInput}
        required={required}
        disabled={disabled}
      />
    </div>
  )
}

DateRangePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  numberOfMonths: PropTypes.number,
  onDatesChange: PropTypes.func,
  outOfRange: PropTypes.func,
}

DateRangePicker.defaultProps = {
  label: null,
  name: null,
  disabled: false,
  required: false,
  startDate: null,
  endDate: null,
  minDate: null,
  maxDate: null,
  numberOfMonths: 1,
  onDatesChange: () => null,
  outOfRange: () => null,
}

export default DateRangePicker
