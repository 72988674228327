import { ALL } from 'src/constants/spacePlanner'
import { SAVE_CATEGORY_FILTER } from '../../actions'

const initialState = ALL

const reducer = (state = initialState, { type, payload } = {}) => {
  if (type === SAVE_CATEGORY_FILTER) {
    return payload.categorySelectionType
  }

  return state
}

export default reducer
