import React, { Component } from 'react'
import { connect } from 'react-redux'

import sortArray from 'src/utils/sortArray'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import CheckboxGroup from './CheckboxGroup'

const sortRows = rows => {
  const sortedRows = sortArray(rows, 'title')
  return sortedRows
}

export const filterRows = (rows, query = '') => {
  const filterQuery = String(query).toLowerCase()
  return rows.filter(text => {
    return text.title.toLowerCase().includes(filterQuery)
  })
}

export const getCheckBoxOptionsData = (allOptions, selectedSpaces, search) => {
  return search !== ''
    ? sortRows(
        allOptions.filter(opt => opt.label.toLowerCase().includes(String(search).toLowerCase()))
      )
    : sortRows(allOptions.filter(opt => selectedSpaces.includes(opt.id)))
}

const connectedCheckBoxGroup = ({
  options,
  label,
  name,
  disabled,
  searchEnabled,
  onSearchChange,
  displayMessage,
  updateListOfSelectedValues,
}) => (
  <div>
    {label && <div className="ln-c-label ln-u-soft-right">{label}</div>}
    <Field
      updateListOfSelectedValues={updateListOfSelectedValues}
      name={name}
      options={options}
      component={CheckboxGroup}
      disabled={disabled}
      searchEnabled={searchEnabled}
      onSearchChange={onSearchChange}
      displayMessage={displayMessage}
    />
  </div>
)

const ConnectedCheckBoxGroup = connect(null)(connectedCheckBoxGroup)

class CheckBoxGroupWrapper extends Component {
  constructor(props) {
    super(props)
    const { options, selectedSpaces } = this.props
    this.state = {
      displayMessage: false,
      selectedSpaces,
      availableOptions: selectedSpaces
        ? options.filter(opt => selectedSpaces.includes(opt.id))
        : [],
    }

    this.onSearchChange = this.onSearchChange.bind(this)
    this.setSelectedValues = this.setSelectedValues.bind(this)
  }

  onSearchChange(event) {
    const search = event.target.value
    const { handleChange } = this.props
    const displayMessage = search !== ''
    const { options } = this.props
    const { selectedSpaces } = this.state
    const availableOptions = getCheckBoxOptionsData(options, selectedSpaces, search)
    handleChange(event)
    this.setState({ displayMessage, availableOptions })
  }

  setSelectedValues(selectedValues) {
    this.setState({ selectedSpaces: selectedValues })
  }

  render() {
    const { displayMessage, availableOptions } = this.state

    return (
      <ConnectedCheckBoxGroup
        onSearchChange={this.onSearchChange}
        {...this.props}
        options={availableOptions}
        displayMessage={displayMessage}
        updateListOfSelectedValues={this.setSelectedValues}
      />
    )
  }
}
CheckBoxGroupWrapper.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedSpaces: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  displayMessage: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}
CheckBoxGroupWrapper.defaultProps = {
  label: undefined,
  disabled: false,
  displayMessage: false,
  selectedSpaces: [],
}
export default CheckBoxGroupWrapper
