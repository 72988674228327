import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { getFormatDate, getStatusColor } from 'src/utils/shipperCompliance'

const DueDate = ({ touchpoint }) => {
  const { timelineStatus, dueDate } = touchpoint
  const { foreColor, bgColor } = getStatusColor(timelineStatus)

  const dueDateClassNames = classNames(
    'ln-u-padding*1/2 ln-u-caption c-touchpoint-due-date',
    foreColor,
    bgColor
  )

  return <div className={dueDateClassNames}>{getFormatDate(dueDate)}</div>
}

DueDate.propTypes = {
  touchpoint: PropTypes.shape({
    timelineStatus: PropTypes.string,
    dueDate: PropTypes.string,
  }).isRequired,
}

export default DueDate
