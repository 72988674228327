import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { selectCategories } from 'src/store/modules/asyncThunks/categories'
import { selectAllRoles } from 'src/store/modules/asyncThunks/roles'
import {
  selectUserInfo,
  updateUser,
  selectUserRequestedInfo,
  selectIsNewUserRequestedStatus,
} from '../store'

import Form from './Form'

export const mapStateToProps = state => {
  const user = selectUserInfo(state) || {}
  const userRequested = selectUserRequestedInfo(state) || {}
  const allRoles = selectAllRoles(state) || []
  const allCategories = selectCategories(state) || []
  const isUserRequestedStatus = selectIsNewUserRequestedStatus(state) || false

  return {
    user: !isUserRequestedStatus ? user : userRequested,
    roles: [...allRoles],
    allCategories: [...allCategories],
  }
}

const returnAction = () => push('/users')
const mapDispatchToProps = dispatch => ({
  submit: async (user, id) => {
    await dispatch(updateUser(id, user))
    dispatch(returnAction())
  },
  handleReject: async user => {
    await dispatch(updateUser(user.id, user, true))
    dispatch(returnAction())
  },
  cancel: () => dispatch(returnAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
