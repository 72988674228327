import { addOneDayToYMD, subtractOneDayToYMD } from 'src/utils/day'
import sortArray from 'src/utils/sortArray'

function buildGaps({ ranges, startDate, endDate }) {
  const gaps = []

  if (ranges.length === 0) {
    return [{ startDate, endDate }]
  }

  const sortedRanges = sortArray(ranges, 'startDate')

  sortedRanges.forEach((assignment, index) => {
    const previous = sortedRanges[index - 1]
    const next = sortedRanges[index + 1]

    if (!previous && assignment.startDate > startDate) {
      gaps.push({ startDate, endDate: subtractOneDayToYMD(assignment.startDate) })
    }

    if (previous && addOneDayToYMD(previous.endDate) !== assignment.startDate) {
      gaps.push({
        startDate: addOneDayToYMD(previous.endDate),
        endDate: subtractOneDayToYMD(assignment.startDate),
      })
    }

    if (!next && assignment.endDate < endDate) {
      gaps.push({ startDate: addOneDayToYMD(assignment.endDate), endDate })
    }
  })

  return gaps
}

export default buildGaps
