import moment from 'moment'
import { DATE_FORMAT_DEFAULT } from './dateRange'

const getWeekDay = date => {
  return moment(date).isoWeekday()
}

const getDate = date => {
  return moment(date).format(DATE_FORMAT_DEFAULT)
}

const getDateOfPreviousWeekDay = (date, weeksOrDaysCount, dateUnit, dayOftheWeek) => {
  return moment(date)
    .subtract(weeksOrDaysCount, dateUnit)
    .isoWeekday(dayOftheWeek)
    .format(DATE_FORMAT_DEFAULT)
}

const getDateOfCurrentWeekDay = (date, dayOftheWeek) => {
  return moment(date).isoWeekday(dayOftheWeek).format(DATE_FORMAT_DEFAULT)
}

const getPreviousWeeksAndDays = (date, weeksOrDaysCount, dateUnit) => {
  return moment(date).subtract(weeksOrDaysCount, dateUnit).format(DATE_FORMAT_DEFAULT)
}

export {
  getWeekDay,
  getDate,
  getDateOfPreviousWeekDay,
  getDateOfCurrentWeekDay,
  getPreviousWeeksAndDays,
}
