import React from 'react'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

const withDragDropContext = ComposedComponent => {
  const wrappedComponentName =
    ComposedComponent.displayName || ComposedComponent.name || 'Component'

  const WithDragDropContext = props => (
    <DndProvider backend={HTML5Backend}>
      <ComposedComponent {...props} />
    </DndProvider>
  )

  WithDragDropContext.displayName = `WithDragDropContext(${wrappedComponentName})`

  return WithDragDropContext
}

export default withDragDropContext
