const isPositiveInteger = str => {
  const number = Math.floor(Number(str))
  return !Number.isNaN(parseFloat(number)) && Number.isFinite(number) && number >= 0
}

function validate(values) {
  const errors = {}
  const { title, position } = values

  if (!title || title.trim().length === 0) {
    errors.title = 'Title cannot be empty'
  }

  if (!isPositiveInteger(position)) {
    errors.position = 'Position must be a number'
  }

  return errors
}

export default validate
