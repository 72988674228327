import React from 'react'
import { Redirect, Route, IndexRoute } from 'react-router'

import { authorizedStoreColleague } from 'src/hoc/auth'
import RangeAndSpace from 'src/modules/RangeAndSpace'
import StoreSearch from 'src/modules/RangeAndSpace/StoreSearch'

const route = (
  <Route name="range-and-space" path="range-and-space">
    <IndexRoute component={authorizedStoreColleague(StoreSearch)} />
    <Route path=":storeNumber" component={authorizedStoreColleague(RangeAndSpace)} />
    <Redirect from="/range-and-space/:storeNumber/upcoming" to="/range-and-space/:storeNumber" />
    <Redirect from="/range-and-space/:storeNumber/current" to="/range-and-space/:storeNumber" />
    <Redirect from="/range-and-space/:storeNumber/previous" to="/range-and-space/:storeNumber" />
    <Redirect from="/range-and-space/:storeNumber/*" to="/range-and-space/:storeNumber/current" />
  </Route>
)

export default route
