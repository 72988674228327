// standard layout types
export const SHELF_LAYOUT_TYPE = 'shelf'
export const SHIPPER_LAYOUT_TYPE = 'shipper'
export const STACK_LAYOUT_TYPE = 'stack'
export const PALLET_LAYOUT_TYPE = 'pallet'
export const PRODUCE_LAYOUT_TYPE = 'produce'
export const PRODUCE_3X4_LAYOUT_TYPE = 'produce3x4'
export const PRODUCE_4X4_LAYOUT_TYPE = 'produce4x4'
export const CLIPSTRIP_LAYOUT_TYPE = 'clipstrip'

// non-standard layout types
export const FROZEN_LAYOUT_TYPE = 'frozen'
export const FROZEN_FULL_HEIGHT_LAYOUT_TYPE = 'fullheightfrozen'
export const FROZEN_HALF_HEIGHT_LAYOUT_TYPE = 'halfheightfrozen'
export const WELL_LAYOUT_TYPE = 'well'

export const STANDARD_LAYOUT_TYPES = [
  SHELF_LAYOUT_TYPE,
  SHIPPER_LAYOUT_TYPE,
  STACK_LAYOUT_TYPE,
  PALLET_LAYOUT_TYPE,
  PRODUCE_LAYOUT_TYPE,
  PRODUCE_3X4_LAYOUT_TYPE,
  PRODUCE_4X4_LAYOUT_TYPE,
  CLIPSTRIP_LAYOUT_TYPE,
]
export const NON_STANDARD_LAYOUT_TYPES = [
  FROZEN_LAYOUT_TYPE,
  FROZEN_FULL_HEIGHT_LAYOUT_TYPE,
  FROZEN_HALF_HEIGHT_LAYOUT_TYPE,
  WELL_LAYOUT_TYPE,
]
export const LAYOUT_TYPES = [...STANDARD_LAYOUT_TYPES, ...NON_STANDARD_LAYOUT_TYPES]

export const LAYOUT_TYPE_LABELS = {
  [SHELF_LAYOUT_TYPE]: 'Shelf',
  [SHIPPER_LAYOUT_TYPE]: 'Shipper',
  [STACK_LAYOUT_TYPE]: 'Stack',
  [PALLET_LAYOUT_TYPE]: 'Pallet',
  [PRODUCE_LAYOUT_TYPE]: 'Produce',
  [PRODUCE_3X4_LAYOUT_TYPE]: 'Produce 3x4',
  [PRODUCE_4X4_LAYOUT_TYPE]: 'Produce 4x4',
  [CLIPSTRIP_LAYOUT_TYPE]: 'Clipstrip',
  [FROZEN_LAYOUT_TYPE]: 'Frozen',
  [FROZEN_FULL_HEIGHT_LAYOUT_TYPE]: 'Frozen Full Height',
  [FROZEN_HALF_HEIGHT_LAYOUT_TYPE]: 'Frozen Half Height',
  [WELL_LAYOUT_TYPE]: 'Well',
}
