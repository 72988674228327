export default function validate({ changeControlReasonCode, changeControlDescription }) {
  const errors = {}

  if (!changeControlReasonCode) {
    errors.changeControlReasonCode = 'Reason Code is required'
  }

  if (!changeControlDescription) {
    errors.changeControlDescription = 'Reason Description is required'
  }

  return errors
}
