import Api from 'src/api/Base'

class GetSpacesBySpacePlan extends Api {
  requestUrl =
    this.getParams().groupId === null
      ? `/api/SpacePlan/${this.getParams().spacePlanId}/spaces`
      : `/api/SpacePlan/${this.getParams().spacePlanId}/spaces?groupId=${this.getParams().groupId}`
}

export default GetSpacesBySpacePlan
