import { buildReducer, bindSelector } from 'src/store/modules'
import { actionClearDataHandler } from 'src/utils/createAsyncThunkHandler'
import { createSelector } from 'reselect'
import uiReducer from './reducer'
import { spaceAssignmentsSlice } from './reducer/spaceAssignmentReducer'

export const NAMESPACE = 'CycleSpaces'

const selectSelf = state => state.modules.CycleSpaces[spaceAssignmentsSlice.name]
const selectSpaceAssignmentsByCycleId = createSelector(selectSelf, module => module.data)

const selectUi = bindSelector(NAMESPACE)(state => state.ui)

const actionClearData = actionClearDataHandler(NAMESPACE)

export default buildReducer(NAMESPACE, {
  ui: uiReducer,
  [spaceAssignmentsSlice.name]: spaceAssignmentsSlice.reducer,
})

export { selectUi, selectSpaceAssignmentsByCycleId, actionClearData }
