import { createAsyncThunk } from '@reduxjs/toolkit'

import { handleCreateAsyncThunkResult, buildThunkPrefix } from 'src/utils/createAsyncThunkHandler'
import { GetPsaPdfByStoreNumber } from 'src/api/RangeAndSpace'

const NAMESPACE = 'PsaPdfByStoreNumberDetails'

const fetchPsaPdfByStoreNumber = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchPsaPdfByStoreNumber'),
  async ({ storeNumber, cycleId, isSegmentedVersion }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getPsaPdfByStoreNumberInstance = new GetPsaPdfByStoreNumber(store, {
      params: { storeNumber, cycleId, isSegmentedVersion },
    })

    const response = await handleCreateAsyncThunkResult(
      getPsaPdfByStoreNumberInstance,
      dispatch,
      rejectWithValue,
      false
    )
    return response
  }
)

// eslint-disable-next-line import/prefer-default-export
export { fetchPsaPdfByStoreNumber }
