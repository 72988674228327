import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import { createSelector } from 'reselect'
import GetSpaceById from 'src/api/Space/GetSpaceById'

const { createAsyncThunk, createSlice } = require('@reduxjs/toolkit')

const STORE_PATH = `modules`

const NAMESPACE = 'SpaceDetails'
const buildThunkPrefix = (slice, thunkName) => `${STORE_PATH}/${slice}/${thunkName}`

const selectSelf = state => state.modules[NAMESPACE]
const selectSpaceDetails = createSelector(selectSelf, module => module.data || {})

const fetchSpaceDetails = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchSpaceDetails'),
  async (id, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getSpaceByIdInstance = new GetSpaceById(store, {
      params: { id },
    })
    const response = await handleCreateAsyncThunkResult(
      getSpaceByIdInstance,
      dispatch,
      rejectWithValue
    )

    return response
  }
)

const fetchSpaceDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSpaceDetails.pending, pendingDataHandler)
    builder.addCase(fetchSpaceDetails.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpaceDetails.rejected, rejectedDataHandler)
  },
})

export default { [NAMESPACE]: fetchSpaceDetailsSlice.reducer }

export { fetchSpaceDetails, selectSpaceDetails }
