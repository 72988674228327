import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, RadioButtonField } from '@jsluna/react'

import {
  TOUCHPOINT_COMPLETED,
  TOUCHPOINT_NOT_COMPLETED,
  TOUCHPOINTS_WITH_OPTIONS,
} from 'src/constants/shipperCompliance'

import {
  getOptions,
  shipperComplianceIsCompleted,
  shipperComplianceIsOverdue,
  shipperComplianceIsPending,
} from 'src/utils/shipperCompliance'

const TouchPoint = ({ id, touchpointType, touchpoint, prevTouchpoint, isReadOnly, onChange }) => {
  const touchpointCompleted = shipperComplianceIsCompleted(touchpoint)
  const prevTouchPointOverdue = prevTouchpoint ? shipperComplianceIsOverdue(prevTouchpoint) : false
  const isDisabled =
    isReadOnly ||
    shipperComplianceIsPending(touchpoint) ||
    touchpointCompleted ||
    prevTouchPointOverdue
  const hasOptions = Object.keys(TOUCHPOINTS_WITH_OPTIONS).includes(touchpointType)

  return hasOptions ? (
    <RadioButtonField
      name={`touchpoint-${touchpointType}-${id}`}
      hideLabel
      disabled={isDisabled}
      options={getOptions(touchpointType)}
      value={touchpointCompleted ? touchpoint.state : ''}
      onChange={({ target: { value } }) => onChange(touchpointType, value)}
    />
  ) : (
    <Checkbox
      name={`touchpoint-${touchpointType}-${id}`}
      className="c-checkbox-compact ln-u-justify-content-center"
      disabled={isDisabled}
      checked={touchpointCompleted}
      onChange={({ target: { checked } }) =>
        onChange(touchpointType, checked ? TOUCHPOINT_COMPLETED : TOUCHPOINT_NOT_COMPLETED)
      }
    />
  )
}

TouchPoint.propTypes = {
  id: PropTypes.string.isRequired,
  touchpoint: PropTypes.shape({
    state: PropTypes.string,
  }).isRequired,
  prevTouchpoint: PropTypes.shape({}),
  touchpointType: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  onChange: PropTypes.func,
}

TouchPoint.defaultProps = {
  isReadOnly: false,
  onChange: () => {},
  prevTouchpoint: null,
}

export default TouchPoint
