import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import classNames from 'classnames'
import { Heading4 } from '@jsluna/react'

import StatusCardChevron from 'src/components/StatusCardChevron'

import statuses, { DRAFT_ID, IN_REVIEW_ID, APPROVED_ID } from 'src/modules/Nominations/statuses'

const classes = {
  [DRAFT_ID]: 'ln-c-status-card--grey',
  [IN_REVIEW_ID]: 'ln-c-status-card--amber',
  [APPROVED_ID]: 'ln-c-status-card--green',
}

function StatusCard({ nomination = {}, cycleVisible, spacePlanVisible }) {
  const { status, categoryTitle = {}, cycleTitle = {}, spacePlanTitle = {}, id } = nomination
  const { title: statusTitle } = statuses.get(status)

  return (
    <Link
      to={`/nominations/${id}`}
      className={classNames('ln-c-status-card ln-c-status-card--link', classes[status])}
    >
      <div className="ln-o-flag ln-u-fill-height">
        <div className="ln-o-flag__body">
          <div className="ln-c-status-card__content">
            <Heading4 className="ln-u-flush-bottom">{categoryTitle}</Heading4>
            {spacePlanVisible && spacePlanTitle && <div>{spacePlanTitle}</div>}
            {cycleVisible && cycleTitle && <div>{cycleTitle}</div>}
          </div>
        </div>
        <div className="ln-o-flag__component ln-o-flag__component--nowrap">
          <div className="ln-c-status-card__content">{statusTitle}</div>
        </div>
      </div>
      <StatusCardChevron />
    </Link>
  )
}

StatusCard.propTypes = {
  nomination: PropTypes.shape({
    status: PropTypes.string,
    categoryTitle: PropTypes.string,
    cycleTitle: PropTypes.string,
    spacePlanTitle: PropTypes.string,
    id: PropTypes.string,
  }),
  cycleVisible: PropTypes.bool,
  spacePlanVisible: PropTypes.bool,
}

StatusCard.defaultProps = {
  nomination: {},
  cycleVisible: false,
  spacePlanVisible: false,
}

export default StatusCard
