import moment from 'moment'

import {
  mandatoryWithChangeControl,
  mandatoryWithChangeControlNonFood,
} from 'src/constants/mandatoryChangeControlFields'

import { isNumber, isToDecimalPlaces } from 'src/utils/validation'
import keyBy from 'src/utils/keyBy'

import {
  PROMO_MECHANIC_NOT_DEFINED_ID,
  SHELF_FILL_TYPE_OPTIONS,
} from 'src/constants/shelfProductDetails'

const identity = (product, { key }) => {
  const value = String(product[key])
  return { [key]: value }
}

const toYesNo = (product, { key }) => {
  const value = String(product[key])
  return { [key]: value.toLowerCase().trim() === 'yes' }
}

const required = fn => (value, options) => {
  const { key, name } = options
  const field = value[key]
  if (field) return fn(value, options)
  return { errors: [`${name} is required`] }
}

const notRequired = fn => (value, options) => {
  const { key } = options
  const field = value[key]

  if (field === '') {
    // eslint-disable-next-line no-param-reassign
    value[key] = undefined
  }

  if (field) return fn(value, options)
  return {}
}

const toCurrency = (product, { key, name }) => {
  const value = String(product[key])
  const errors = { errors: [`${name} is invalid`] }

  if (!/^-?£\d{1,3}(?:,\d{3})*(?:\.\d+)? ?$/g.test(value)) return errors

  const parsedValue = value.replace(/[^-?0-9.]+/g, '')

  return { [key]: parsedValue }
}

const toDate = (product, { key, name }) => {
  const format = 'YYYY-MM-DD'
  const value = String(product[key])

  let parsedValue = moment(value, format, true)
  if (parsedValue.isValid()) return { [key]: parsedValue.format(format) }

  parsedValue = moment(value, 'D/M/YY', true)
  if (parsedValue.isValid()) return { [key]: parsedValue.format(format) }

  return { errors: [`${name} is invalid`] }
}

const toFloat = (product, { key, name }) => {
  const value = String(product[key])
  const errors = { errors: [`${name} is invalid`] }

  if (!/^\d+(?:\.\d+)?$/g.test(value)) return errors

  const parsedValue = parseFloat(value, 10)
  if (Number.isNaN(parsedValue)) return { errors: [`${name} is invalid`] }

  return { [key]: parsedValue }
}

const toInt = (product, { key, name }) => {
  const value = product[key]
  const errors = { errors: [`${name} is invalid`] }

  if (!/^\d+$/g.test(String(value))) return errors

  const parsedValue = parseInt(value, 10)
  if (Number.isNaN(parsedValue)) return { errors: [`${name} is invalid`] }

  return { [key]: parsedValue }
}

const toShelf = (product, { spaceInstance } = {}) => {
  const isAlpha = /^[A-Z]{1}(?:\((?:BASE|TOP)\))?$/
  const upperCaseShelf = product.shelf.toUpperCase()
  const shelfNumber = isAlpha.test(upperCaseShelf)
    ? upperCaseShelf.charCodeAt(0) - 64
    : parseInt(upperCaseShelf, 10)

  if (!isNumber(shelfNumber)) {
    return { errors: ['Shelf is invalid'] }
  }

  const shelf = spaceInstance.shelves[shelfNumber - 1]
  if (shelf && shelf.id) {
    return {
      shelf: shelfNumber,
      shelfId: shelf.id,
      errors: [],
    }
  }

  return { errors: ['Shelf is invalid'] }
}

const toBusinessUnit = (
  product,
  { lookupBusinessUnit, spaceInstance, isLockedForChangeControl }
) => {
  // If shelf is not set then toShelf will return an error, returning an empty
  // object here to prevent duplicate errors being displayed to the user.
  if (!product.shelf) return {}

  const { shelfId, errors } = toShelf(product, { spaceInstance, isLockedForChangeControl })
  if (errors.length > 0) return {}

  const { category } = product
  if (category) {
    const businessUnit = lookupBusinessUnit(shelfId, category)
    return businessUnit || { errors: [`${category} not found`] }
  }

  return { errors: [`${category} not found`] }
}

const toCategory = (
  product,
  { key, name, lookupCategory, spaceInstance, isLockedForChangeControl }
) => {
  // If shelf is not set then toShelf will return an error, returning an empty
  // object here to prevent duplicate errors being displayed to the user.
  if (!product.shelf) return {}

  const { shelfId, errors } = toShelf(product, { spaceInstance, isLockedForChangeControl })
  if (errors.length > 0) return {}

  const category = product[key]
  if (!category) return { errors: [`${name} is required`] }

  const categoryId = lookupCategory(shelfId, category)
  if (categoryId) return { categoryId }
  return { errors: [`${name} not found`] }
}

const toPercentage = (product, { key, name, decimalPlaces = 0 }) => {
  const value = product[key]
  const isToXDecimalPlaces = isToDecimalPlaces(decimalPlaces)
  const errors = { errors: [`${name} is invalid`] }

  if (!/^\d+\.?\d*%$/g.test(String(value)) || !isNumber(value)) return errors
  if (!isToXDecimalPlaces(parseFloat(value, 10)))
    return { errors: [`${name} can only have two decimal places`] }

  const parsedValue = parseFloat(value, 10)
  return { [key]: parsedValue }
}

const toShelfFill = (product, { key, name }) => {
  const value = String(product[key])
  const errors = { errors: [`${name} is invalid`] }

  if (!/^\d+(?:\.\d+)?$/g.test(value)) return errors

  const parsedValue = parseFloat(value, 10)
  if (Number.isNaN(parsedValue)) return { errors: [`${name} is invalid`] }

  if (parsedValue < 0) return { errors: [`${name} value should be greater than 0`] }

  return { [key]: parsedValue }
}

const toShelfFillType = (product, { key }) => {
  const shelfFillType = product[key]
  const validatedShelfFillType = SHELF_FILL_TYPE_OPTIONS.find(
    shelfFillTypeOption => shelfFillTypeOption.label === shelfFillType
  )

  return validatedShelfFillType
    ? { shelfFillType: validatedShelfFillType.value }
    : { errors: ['Shelf Fill Type is invalid'] }
}

const toSku = async (product, { lookupSku }) => {
  if (!product.sku) return { errors: ['SKU is required'] }

  const validatedProduct = await lookupSku(product.sku)

  return validatedProduct
    ? {
        productId: validatedProduct.id,
        description: validatedProduct.description,
        source: validatedProduct?.source,
        hfss: validatedProduct?.hfss,
      }
    : { errors: ['SKU not found'] }
}

const toOldSku = async (product, { lookupSku }) => {
  if (!product.oldSku) return {}

  const validatedOldProduct = await lookupSku(product.oldSku)

  if (validatedOldProduct) {
    const { id: oldProductId } = validatedOldProduct
    return { oldProductId }
  }
  return { errors: ['Old SKU not found'] }
}

const toPromoMechanic = async (product, { lookupPromoMechanic }) => {
  const validatedProduct = await lookupPromoMechanic(product.promoMechanic)

  return validatedProduct
    ? { promoMechanicId: validatedProduct.id }
    : { errors: ['Promo Mechanic not found'] }
}

const toSalesPhaseWeeks = values => {
  const salesPhaseWeeks = []
  for (let i = 0; i < 5; i += 1) {
    const valueAsString = values[`salesPhaseWeeks[${i}]`]
    const value = parseInt(valueAsString, 10)
    salesPhaseWeeks.push(value || null)
  }
  if (salesPhaseWeeks.filter(week => !!week).length === 0) return {}
  return { salesPhaseWeeks }
}

const isLocked = ({ isLockedForChangeControl }) => isLockedForChangeControl

const valueIsRequired = ({ key, parseFn, forceRequired = false, requiredFn } = {}) => async (
  product,
  options
) => {
  const businessUnit = toBusinessUnit(product, options)
  const mandatoryFields =
    businessUnit === 'food' ? mandatoryWithChangeControl : mandatoryWithChangeControlNonFood

  let isRequired = forceRequired || (isLocked(options) && mandatoryFields.includes(key))

  isRequired = requiredFn ? await requiredFn(product, options) : isRequired

  return isRequired ? required(parseFn)(product, options) : notRequired(parseFn)(product, options)
}

const pricePointIsRequired = async (product, options) => {
  const { lookupPromoMechanic } = options
  const validatedPromoMechanic = await lookupPromoMechanic(product.promoMechanic)

  return !validatedPromoMechanic || validatedPromoMechanic.id !== PROMO_MECHANIC_NOT_DEFINED_ID
}

const toPositiveCurrency = (product, { key, name }) => {
  const value = String(product[key])
  const errors = { errors: [`${name} is invalid`] }

  if (!/^-?£\d{1,3}(?:,\d{3})*(?:\.\d+)? ?$/g.test(value)) return errors

  const parsedValue = value.replace(/[^-?0-9.]+/g, '')
  if (parsedValue < 0) return { errors: [`${name} value should be greater than 0`] }

  return { [key]: parsedValue }
}

const nppDateIsRequired = (product, options) => {
  const businessUnit = toBusinessUnit(product, options)
  const { nppRequired = 'no' } = product
  return businessUnit === 'non-food' && nppRequired !== null && nppRequired.toLowerCase() === 'yes'
}

const DEFAULT_COLUMN = {
  parse: () => undefined,
  example: undefined,
}

const columns = [
  {
    name: 'Shelf',
    key: 'shelf',
    example: 1,
    parse: valueIsRequired({ key: 'shelf', parseFn: toShelf, forceRequired: true }),
  },
  {
    name: 'SKU',
    key: 'sku',
    example: '0',
    parse: valueIsRequired({ key: 'sku', parseFn: toSku, forceRequired: true }),
  },
  {
    name: 'Comment',
    key: 'comment',
    parse: valueIsRequired({ key: 'comment', parseFn: identity }),
  },
  {
    name: 'Store Comment',
    key: 'storeComment',
    parse: valueIsRequired({ key: 'storeComment', parseFn: identity }),
  },
  {
    name: 'Old SKU',
    key: 'oldSku',
    example: '0',
    parse: valueIsRequired({ key: 'oldSku', parseFn: toOldSku }),
  },
  {
    name: 'Shelf Fill',
    key: 'shelfFill',
    example: 2,
    parse: valueIsRequired({
      key: 'shelfFill',
      parseFn: toShelfFill,
    }),
  },
  {
    name: 'Shelf Fill Type',
    key: 'shelfFillType',
    example: 'Case Fill',
    parse: valueIsRequired({ key: 'shelfFillType', parseFn: toShelfFillType }),
  },
  {
    name: 'Promo Case Size',
    key: 'promoCaseSize',
    example: 2,
    parse: valueIsRequired({ key: 'promoCaseSize', parseFn: toInt }),
  },
  {
    name: 'RMS % Uplift for Aisle',
    key: 'percentUpliftAisle',
    example: '1.23%',
    decimalPlaces: 2,
    parse: valueIsRequired({ key: 'percentUpliftAisle', parseFn: toPercentage }),
  },
  {
    name: 'RMS % Uplift for Plinth',
    key: 'percentUpliftPlinth',
    example: '4.56%',
    decimalPlaces: 2,
    parse: valueIsRequired({ key: 'percentUpliftPlinth', parseFn: toPercentage }),
  },
  {
    name: 'Category',
    key: 'category',
    example: 'Bakery',
    parse: valueIsRequired({ key: 'category', parseFn: toCategory, forceRequired: true }),
  },
  {
    name: 'Promo Mechanic Type',
    key: 'promoMechanic',
    example: 'TPR',
    parse: valueIsRequired({ key: 'promoMechanic', parseFn: toPromoMechanic, forceRequired: true }),
  },
  {
    name: 'Promo Mechanic Value',
    key: 'promoMechanicValue',
    parse: valueIsRequired({ key: 'promoMechanicValue', parseFn: identity }),
  },
  {
    name: 'Pack Size',
    key: 'packSize',
    example: 12,
    parse: valueIsRequired({ key: 'packSize', parseFn: toInt }),
  },
  {
    name: 'Price Point',
    key: 'pricePoint',
    example: '£3.49',
    parse: valueIsRequired({
      key: 'pricePoint',
      parseFn: toPositiveCurrency,
      requiredFn: pricePointIsRequired,
    }),
  },
  {
    name: 'Facings on Shelf',
    key: 'facingsOnShelf',
    example: 3,
    parse: valueIsRequired({ key: 'facingsOnShelf', parseFn: toInt }),
  },
  {
    name: 'UBW',
    key: 'ubw',
    example: 4.5,
    parse: valueIsRequired({ key: 'ubw', parseFn: toFloat }),
  },
  {
    name: 'Profit (PPP)',
    key: 'profitPpp',
    example: '£2.50',
    parse: valueIsRequired({
      key: 'profitPpp',
      parseFn: toCurrency,
    }),
  },
  {
    name: 'Original Retail Price',
    key: 'originalRetailPrice',
    example: '£3.49',
    parse: valueIsRequired({ key: 'originalRetailPrice', parseFn: toCurrency }),
  },
  {
    name: 'Clearance Price',
    key: 'clearancePrice',
    example: '£1.39',
    parse: valueIsRequired({ key: 'clearancePrice', parseFn: toCurrency }),
  },
  {
    name: 'WIGIG',
    key: 'wigig',
    example: 'no',
    parse: valueIsRequired({ key: 'wigig', parseFn: toYesNo }),
  },
  {
    name: 'Special Buy',
    key: 'specialBuy',
    example: 'no',
    parse: valueIsRequired({ key: 'specialBuy', parseFn: toYesNo }),
  },
  {
    name: '% Sold @ Clearance Price',
    key: 'percentSoldClearancePrice',
    example: '20%',
    decimalPlaces: 0,
    parse: valueIsRequired({ key: 'percentSoldClearancePrice', parseFn: toPercentage }),
  },
  {
    name: '% Sold @ Promo Price',
    key: 'percentSoldPromoPrice',
    example: '80%',
    decimalPlaces: 0,
    parse: valueIsRequired({ key: 'percentSoldPromoPrice', parseFn: toPercentage }),
  },
  {
    name: 'Forecasted Sales for Cycle',
    key: 'salesForecastPromoCycle',
    example: '£175,000',
    parse: valueIsRequired({ key: 'salesForecastPromoCycle', parseFn: toCurrency }),
  },
  {
    name: 'Forecasted Sales for Line',
    key: 'salesForecastLine',
    example: '£375,000',
    parse: valueIsRequired({ key: 'salesForecastLine', parseFn: toCurrency }),
  },
  {
    name: 'SOA Per Unit',
    key: 'soaPerUnit',
    example: '£1.29',
    parse: valueIsRequired({ key: 'soaPerUnit', parseFn: toCurrency }),
  },
  {
    name: 'Bulk Support',
    key: 'bulkSupport',
    example: '£500,000',
    parse: valueIsRequired({ key: 'bulkSupport', parseFn: toCurrency }),
  },
  {
    name: 'Sales Phasing Week 1 (Part Week)',
    key: 'salesPhaseWeeks[0]',
    example: '30%',
    parse: valueIsRequired({ key: 'salesPhaseWeeks[0]', parseFn: toSalesPhaseWeeks }),
  },
  {
    name: 'Sales Phasing Week 2',
    key: 'salesPhaseWeeks[1]',
    example: '20%',
    parse: valueIsRequired({ key: 'salesPhaseWeeks[1]', parseFn: toSalesPhaseWeeks }),
  },
  {
    name: 'Sales Phasing Week 3',
    key: 'salesPhaseWeeks[2]',
    example: '20%',
    parse: valueIsRequired({ key: 'salesPhaseWeeks[2]', parseFn: toSalesPhaseWeeks }),
  },
  {
    name: 'Sales Phasing Week 4',
    key: 'salesPhaseWeeks[3]',
    example: '20%',
    parse: valueIsRequired({ key: 'salesPhaseWeeks[3]', parseFn: toSalesPhaseWeeks }),
  },
  {
    name: 'Sales Phasing Week 5',
    key: 'salesPhaseWeeks[4]',
    example: '10%',
    parse: valueIsRequired({ key: 'salesPhaseWeeks[4]', parseFn: toSalesPhaseWeeks }),
  },
  {
    name: 'NPP Required',
    key: 'nppRequired',
    example: 'yes',
    parse: valueIsRequired({ key: 'nppRequired', parseFn: toYesNo }),
  },
  {
    name: 'NPP In Store Date',
    key: 'nppInStoreDate',
    example: '2018-01-01',
    parse: valueIsRequired({
      key: 'nppInStoreDate',
      parseFn: toDate,
      requiredFn: nppDateIsRequired,
    }),
  },
  {
    name: 'Stock in Shipper/FSDU',
    key: 'stockInShipperFdsu',
    example: 'no',
    parse: valueIsRequired({ key: 'stockInShipperFdsu', parseFn: toYesNo }),
  },
  {
    name: 'Forecast Purchase Quantity',
    key: 'forecastPurchaseQuantity',
    example: '3',
    parse: valueIsRequired({ key: 'forecastPurchaseQuantity', parseFn: toInt }),
  },
  {
    name: 'Stock Value Bought for Promo (Full Retail)',
    key: 'stockValueForPromo',
    example: '£123.45',
    parse: valueIsRequired({ key: 'stockValueForPromo', parseFn: toCurrency }),
  },
].map(column => ({
  ...DEFAULT_COLUMN,
  ...column,
}))
const columnsKeyed = keyBy(columns, 'key')

export { columns, columnsKeyed }
