import React, { useState } from 'react'
import { Card, Heading3 } from '@jsluna/react'
import PropTypes from 'prop-types'
import {
  ButtonGroupPrimary,
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
} from '@jsluna/button'
import { Link } from 'react-router'
import { Edit } from '@jsluna/icons'

import Section from 'src/components/Section'

import Cell from 'src/components/RowCell'
import HeaderCell from 'src/components/HeaderCell'
import createPosName from 'src/utils/createPOSName'
import { StatusCard } from '@jsluna/card'
import ShelfPos from './ShelfPos'
import DeleteDialog from './DeleteDialog'
import DeleteButton from './DeleteButton'

const POSAdvice = ({
  shelfProductsWithPOS,
  id: spaceAssignmentId,
  shelfId,
  actionUpdateShelfProductsWithPOS,
  refreshPOSWithShelfProducts,
  canUpdateShelvesProductsWithPOS,
  canCreateShelvesProductsWithPOS,
  changeControlCreatedByOtherUser,
  query,
}) => {
  const [showPOSUpdateModal, setShowPOSUpdateModal] = useState(false)
  const [editPOS, setEditPOS] = useState(null)
  const [createPOS, setCreatePOS] = useState(false)

  const posListWithProducts = shelfProductsWithPOS.reduce((posList, product) => {
    if (product.pointOfSaleAdvice) {
      const posAdvice = posList.find(pos => pos.id === product.pointOfSaleAdvice.id)
      if (posAdvice) {
        posAdvice.productTitles = `${posAdvice.productTitles}, \n ${product.title} - ${product.sku}`
        posAdvice.shelfProductList.push(product.id)
      } else {
        posList.push({
          ...product.pointOfSaleAdvice,
          productTitles: `${product.title} - ${product.sku}`,
          shelfProductList: [product.id],
        })
      }
    }
    return posList
  }, [])

  return (
    <div data-control="pos-list">
      {changeControlCreatedByOtherUser && (
        <StatusCard
          className="ln-u-push-bottom"
          status="warning"
          data-control="change-control-by-another-user"
        >
          <div>
            There is an existing change control request created by {changeControlCreatedByOtherUser}{' '}
            for this space assignment
          </div>
        </StatusCard>
      )}

      <Section>
        <ButtonGroupWrapper className="ln-u-hard-top ln-u-push-bottom">
          <ButtonGroupSecondary>
            {canCreateShelvesProductsWithPOS && shelfProductsWithPOS.length > 0 && (
              <FilledButton
                data-control="add-pos"
                className="ln-u-push-bottom"
                onClick={() => {
                  setEditPOS({})
                  setCreatePOS(true)
                  setShowPOSUpdateModal(true)
                }}
              >
                Assign POS Advice
              </FilledButton>
            )}
          </ButtonGroupSecondary>
          <ButtonGroupPrimary>
            <FilledButton
              color="dark"
              element={
                <Link
                  to={{
                    pathname: query?.rt ?? `/space-assignments/${spaceAssignmentId}`,
                  }}
                />
              }
            >
              Back to the Space Assignment
            </FilledButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>

        <ShelfPos
          open={showPOSUpdateModal}
          onClose={() => setShowPOSUpdateModal(false)}
          shelfId={shelfId}
          posAdviceWithProducts={editPOS}
          createPOSFlag={createPOS}
        />

        <Card>
          <Heading3 className="ln-u-visually-hidden">POS List</Heading3>
          <div className="ln-c-table-container">
            <table className="ln-c-table ln-c-table--fixed ln-u-text-align-center ln-u-border-bottom">
              <thead className="ln-c-table__header">
                <tr className="ln-c-table__row">
                  <HeaderCell className="c-pos-header-cell-pos-type">POS</HeaderCell>
                  <HeaderCell className="c-pos-header-cell-product-list">Products</HeaderCell>
                  <HeaderCell className="c-pos-header-cell-dept-com" />
                </tr>
              </thead>
              <tbody>
                {posListWithProducts.map(posAdvice => (
                  <tr
                    data-control="pos-shelf-product-row"
                    key={posAdvice.id}
                    className="c-pos-product-row"
                    data-pos={posAdvice.id}
                  >
                    <Cell>{createPosName(posAdvice)}</Cell>
                    <Cell>{posAdvice.productTitles}</Cell>
                    <Cell>
                      {canUpdateShelvesProductsWithPOS && (
                        <>
                          <button
                            className="c-icon-button u-bg-none"
                            data-control="edit-shelf-product-pos"
                            type="button"
                            onClick={() => {
                              setShowPOSUpdateModal(true)
                              setEditPOS(posAdvice)
                              setCreatePOS(false)
                            }}
                          >
                            <Edit />
                          </button>

                          <DeleteDialog
                            shelfId={shelfId}
                            posAdvice={posAdvice}
                            element={DeleteButton}
                            detachPOSFromProducts={actionUpdateShelfProductsWithPOS}
                            refresh={refreshPOSWithShelfProducts}
                            disabled={false}
                          />
                        </>
                      )}
                    </Cell>
                  </tr>
                ))}
              </tbody>
            </table>
            {shelfProductsWithPOS.length === 0 && <Card>No products available in this shelf</Card>}
            {shelfProductsWithPOS.length > 0 && posListWithProducts.length === 0 && (
              <Card>
                No products are assigned to POS.{' '}
                {changeControlCreatedByOtherUser.length === 0 &&
                  canCreateShelvesProductsWithPOS && (
                    <>Please click on Assign POS advice button to assign POS to products</>
                  )}
              </Card>
            )}
          </div>
        </Card>
      </Section>
    </div>
  )
}

POSAdvice.propTypes = {
  shelfProductsWithPOS: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
    })
  ),
  id: PropTypes.string.isRequired,
  shelfId: PropTypes.string.isRequired,
  actionUpdateShelfProductsWithPOS: PropTypes.func.isRequired,
  refreshPOSWithShelfProducts: PropTypes.func.isRequired,
  canUpdateShelvesProductsWithPOS: PropTypes.bool.isRequired,
  canCreateShelvesProductsWithPOS: PropTypes.bool.isRequired,
  changeControlCreatedByOtherUser: PropTypes.string,
  query: PropTypes.shape({
    rt: PropTypes.string,
  }),
}

POSAdvice.defaultProps = {
  shelfProductsWithPOS: [],
  changeControlCreatedByOtherUser: '',
  query: {},
}

export default POSAdvice
