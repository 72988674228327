const handleSubmit = async (
  { paCode, posType, description, shelfProductList, posId, createPOSFlag },
  {
    props: {
      actionUpdateShelfProductsWithPOS,
      actionAddShelfProductsWithPOS,
      onClose,
      shelfId,
      refreshPOSWithShelfProducts,
      refreshPOSAdviceList,
    },
  }
) => {
  if (createPOSFlag && (!posId || posId === 'new')) {
    await actionAddShelfProductsWithPOS({
      posAdvice: {
        type: posType,
        paCode,
        description,
      },
      shelfProductList,
      shelfId,
    })
    await refreshPOSAdviceList()
  } else if (createPOSFlag && posId) {
    await actionAddShelfProductsWithPOS({
      shelfId,
      shelfProductList,
      pointOfSaleId: posId,
    })
    await refreshPOSAdviceList()
  } else {
    await actionUpdateShelfProductsWithPOS({
      shelfId,
      shelfProductList,
      pointOfSaleId: posId,
    })
  }
  await refreshPOSWithShelfProducts(shelfId)
  onClose()
}

export default handleSubmit
