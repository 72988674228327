import React from 'react'
import PropTypes from 'prop-types'

const buildString = quantity => {
  switch (quantity) {
    case 0:
      return 'No results'
    case 1:
      return `${quantity} result`
    default:
      return `${quantity} results`
  }
}

const Quantity = ({ quantity }) => <div>{buildString(quantity)} found</div>

Quantity.propTypes = {
  quantity: PropTypes.number,
}

Quantity.defaultProps = {
  quantity: 0,
}

export default Quantity
