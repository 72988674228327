import { createAsyncThunk } from '@reduxjs/toolkit'
import { DeleteShelf, PatchShelf } from 'src/api/Shelf'
import { handleCreateAsyncThunkResult } from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'Shelf'
const buildThunkPrefix = (slice, thunkName) => `${slice}/${thunkName}`

const actionUpdateShelf = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'updateShelf'),
  async ({ id, shelf }, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const patchShelfInstance = new PatchShelf(store, {
      params: {
        id,
        shelf,
      },
    })
    const response = await handleCreateAsyncThunkResult(
      patchShelfInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionDeleteShelf = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'deleteShelf'),
  async (id, { getState, rejectWithValue, dispatch }) => {
    const store = getState()

    const deleteShelfInstance = new DeleteShelf(store, {
      params: { id },
    })

    const response = await handleCreateAsyncThunkResult(
      deleteShelfInstance,
      dispatch,
      rejectWithValue
    )

    return response
  }
)

export { actionUpdateShelf, actionDeleteShelf }
