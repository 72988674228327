import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  CHANGE_TYPE_ADD,
  CHANGE_TYPE_MODIFY,
  CHANGE_TYPE_MOVE,
  CHANGE_TYPE_REMOVE,
} from 'src/constants/changeTypes'

const getBadgeType = title =>
  ({
    [CHANGE_TYPE_ADD]: { className: 'c-change-type-badge--add', title: 'Added' },
    [CHANGE_TYPE_MODIFY]: {
      className: 'c-change-type-badge--modify',
      title: 'Modified',
    },
    [CHANGE_TYPE_MOVE]: {
      className: 'c-change-type-badge--move',
      title: 'Moved',
    },
    [CHANGE_TYPE_REMOVE]: { className: 'c-change-type-badge--remove', title: 'Removed' },
  }[title])

const ChangeTypeBadge = ({ id, type, count }) => {
  const badgeType = getBadgeType(type)

  return (
    <div
      className={classNames('c-change-type-badge', badgeType.className)}
      data-control={`change-type-${type}-${id}`}
    >
      {badgeType.title}
      {count ? ` - ${count}` : null}
    </div>
  )
}

ChangeTypeBadge.defaultProps = {
  count: null,
}

ChangeTypeBadge.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  count: PropTypes.number,
}

export default ChangeTypeBadge
