export default function handleSubmit(
  { user: { roleId, userCategories } },
  { props: { id, submit } }
) {
  const user = {
    roleId,
    userCategories,
  }

  return submit(user, id)
}
