const buildCoverageOrCatTitle = (
  excludeSpaceWithNoMatchingCategories,
  excludeSpaceWithFullCoverage
) => {
  if (excludeSpaceWithNoMatchingCategories && excludeSpaceWithFullCoverage) {
    return 'with selected categories and missing coverage'
  }
  if (excludeSpaceWithNoMatchingCategories) {
    return 'with selected categories'
  }
  return 'with missing coverage'
}

const buildTitle = ({
  excludeSpaceWithNoMatchingCategories,
  excludeSpaceWithFullCoverage,
  businessUnitFilter,
}) => {
  let title = ''
  if (businessUnitFilter === 'food') {
    title = 'Food spaces'
  } else if (businessUnitFilter === 'non-food') {
    title = 'Non-food spaces'
  } else {
    title = 'Spaces'
  }
  if (excludeSpaceWithNoMatchingCategories || excludeSpaceWithFullCoverage) {
    title += ` ${buildCoverageOrCatTitle(
      excludeSpaceWithNoMatchingCategories,
      excludeSpaceWithFullCoverage
    )}`
  }
  return title
}

export default buildTitle
