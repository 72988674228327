import { createSelector } from 'reselect'

const NAMESPACE = 'SpaceAssignmentWithSpaceInstances'

const selectSelf = state => state.modules[NAMESPACE]
const selectSpaceAssignmentWithSpaceInstances = createSelector(
  selectSelf,
  module => module?.data || {}
)

const selectSpace = createSelector(selectSelf, module => module.data.space)

const selectSpaceInstanceById = (state, instanceId) =>
  createSelector(
    selectSpaceAssignmentWithSpaceInstances,
    spaceAssignmentWithSpaceInstances =>
      spaceAssignmentWithSpaceInstances?.spaceInstances.find(
        spaceInstance => spaceInstance.id === instanceId
      ) || {}
  )(state)

const selectShelfById = (state, shelfId) =>
  createSelector(
    selectSpaceAssignmentWithSpaceInstances,
    spaceAssignmentWithSpaceInstances =>
      spaceAssignmentWithSpaceInstances?.spaceInstances.reduce(
        (shelfByID, { shelves }) => shelfByID || shelves.find(shelf => shelf.id === shelfId),
        undefined
      ) || {}
  )(state)

const selectSpaceInstanceClustersByShelfId = (state, shelfId) =>
  createSelector(
    () => selectShelfById(state, shelfId),
    shelf => selectSpaceInstanceById(state, shelf.spaceInstanceId)?.clusterDistributions || {}
  )(state)

const selectShelfProductById = (state, productId) =>
  createSelector(
    selectSpaceAssignmentWithSpaceInstances,
    spaceAssignmentWithSpaceInstances =>
      spaceAssignmentWithSpaceInstances?.spaceInstances.reduce(
        (reduce, spaceInstance) =>
          reduce ||
          spaceInstance.shelves.reduce(
            (prev, shelf) => prev || shelf.shelfProducts.find(product => product.id === productId),
            undefined
          ),
        undefined
      ) || {}
  )(state)

export {
  selectSpaceAssignmentWithSpaceInstances,
  selectSpaceInstanceById,
  selectShelfById,
  selectShelfProductById,
  selectSpaceInstanceClustersByShelfId,
  selectSpace,
}
