import { actionCreateShelfProduct } from 'src/modules/ShelfProductsDetail/reducer/shelfProduct'

const toNumber = value => {
  if (value === undefined) {
    return undefined
  }

  if (value === null || value === '') {
    return null
  }
  return Number.parseFloat(value)
}

const getPrecedingShelfProduct = (products = []) => {
  let precedingShelfProduct = null

  if (!products.length) {
    return precedingShelfProduct
  }

  precedingShelfProduct = products[products.length - 1]

  return precedingShelfProduct
}

const handleSubmit = (values, { props: { submit }, setErrors }) => {
  const {
    category: categoryId,
    comment,
    product: { id: productId },
    promoMechanic: promoMechanicId,
    promoMechanicValue,
    shelf: { id: shelfId, shelfProducts: products },
    pricePoint,
  } = values

  const precedingShelfProduct = getPrecedingShelfProduct(products)

  if (productId) {
    const product = {
      categoryId,
      clusters: [],
      comment,
      productId,
      promoMechanicId,
      promoMechanicValue,
      shelfId,
      precedingShelfProductId: precedingShelfProduct?.id,
      pricePoint: toNumber(pricePoint),
    }
    const action = actionCreateShelfProduct(product)
    submit(action)
  } else {
    setErrors({ product: { sku: 'A product must be selected' } })
  }
}

export default handleSubmit
