import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

function Cell({ children, dataLabel, link }) {
  return (
    <td className="ln-c-table__cell" data-label={dataLabel}>
      {link ? (
        <Link to={link} className="ln-c-table__link">
          {children}
        </Link>
      ) : (
        children
      )}
    </td>
  )
}

Cell.propTypes = {
  children: PropTypes.node,
  dataLabel: PropTypes.string.isRequired,
  link: PropTypes.string,
}

Cell.defaultProps = {
  children: null,
  link: null,
}

export default Cell
