import React from 'react'
import PropTypes from 'prop-types'
import { Route, IndexRoute, Link } from 'react-router'
import { Heading2, Tabs, TabLink } from '@jsluna/react'

import { authorized } from 'src/hoc/auth'
import SpacesList from 'src/modules/Spaces'
import SpacesDetail from 'src/modules/SpacesDetail'
import SpaceTypesList from 'src/modules/SpaceTypes'
import SpaceTypesDetail from 'src/modules/SpaceTypesDetail'
import SpaceGroupsList from 'src/modules/SpaceGroups'
import SpaceGroupsDetail from 'src/modules/SpaceGroupsDetail'

const isTabActive = (tabPath, pathName) => pathName.endsWith(tabPath)

const tabs = [
  {
    to: '/spaces',
    label: 'Spaces',
  },
  {
    to: '/spaces/types',
    label: 'Types',
  },
  {
    to: '/spaces/groups',
    label: 'Groups',
  },
]

const SpacesTabs = ({ location, children }) => (
  <div>
    <Heading2>Spaces</Heading2>
    <Tabs>
      {tabs.map(tab => (
        <TabLink
          key={tab.to}
          element={<Link to={tab.to} />}
          active={isTabActive(tab.to, location.pathname)}
        >
          {tab.label}
        </TabLink>
      ))}
    </Tabs>
    {children}
  </div>
)

SpacesTabs.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
}

const route = (
  <Route name="Spaces" path="spaces" showInNav>
    {/* GET /spaces | /spaces/types | /spaces/groups */}
    <Route component={authorized(SpacesTabs)}>
      <IndexRoute component={SpacesList} />
      <Route exact path="types" name="Space Types" component={SpaceTypesList} />
      <Route exact path="groups" name="Space Groups" component={SpaceGroupsList} />
    </Route>

    {/* GET /spaces/:id */}
    <Route component={authorized(SpacesDetail)}>
      <Route path=":id" />
    </Route>

    {/* GET/spaces/types/:id|create */}
    <Route component={authorized(SpaceTypesDetail)}>
      <Route path="types/create" />
      <Route path="types/:id" />
    </Route>

    {/* GET/spaces/groups/:id|create */}
    <Route component={authorized(SpaceGroupsDetail)}>
      <Route path="groups/create" />
      <Route path="groups/:id" />
    </Route>
  </Route>
)

export default route
