import React from 'react'
import PropTypes from 'prop-types'

const TemplateLink = ({ url, label }) => (
  <a
    className="ln-c-button ln-c-button--filled"
    href={url || '#'}
    target={url ? '_blank' : '_self'}
    rel="noopener noreferrer"
  >
    {label}
  </a>
)

TemplateLink.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string.isRequired,
}

TemplateLink.defaultProps = {
  url: null,
}

export default TemplateLink
