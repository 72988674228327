import { connect } from 'react-redux'

import withModal from 'src/hoc/withModal'

import Form from './Form'

export const mapStateToProps = () => {
  return { isSubmitting: false, description: '' }
}

const wrappedComponent = connect(mapStateToProps)(Form)

export default withModal(wrappedComponent)
