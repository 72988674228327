/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

const findProduce = (rows, row, column) => {
  const productRow = rows.find(({ shelfProductId: currentShelfProductId, shelfProductLayouts }) =>
    shelfProductLayouts
      ? shelfProductLayouts.findIndex(
          ({ index, position, shelfProduct: { id: shelfProductIdLayout } }) =>
            currentShelfProductId === shelfProductIdLayout &&
            index - 1 === row &&
            position - 1 === column
        ) !== -1
      : false
  )

  return productRow ? `${productRow.description} - (${productRow.sku})` : 'No SKU assigned'
}

const ProductTable = ({ productRows, rows, columns }) => (
  <table className="ln-c-table ln-c-table--fixed">
    <tbody className="ln-c-table__body">
      {[...Array(rows)].map((row, rowIndex) => (
        <tr key={`produce-row-${rowIndex}`}>
          {[...Array(columns)].map((column, columnIndex) => (
            <td
              key={`produce-item-${rowIndex}-${columnIndex}`}
              className="ln-c-table__cell ln-u-text-align-center c-psa-produce-cell"
              data-control={`produce-item-${rowIndex}-${columnIndex}`}
            >
              {findProduce(productRows, rowIndex, columnIndex)}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
)

ProductTable.propTypes = {
  productRows: PropTypes.arrayOf(
    PropTypes.shape({
      shelfProductId: PropTypes.string,
      shelfProductLayouts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          index: PropTypes.number,
          location: PropTypes.string,
          position: PropTypes.number,
          shelfProduct: PropTypes.shape({
            id: PropTypes.string,
          }),
        })
      ),
    })
  ),
  rows: PropTypes.number,
  columns: PropTypes.number,
}

ProductTable.defaultProps = {
  productRows: [{}],
  rows: 1,
  columns: 1,
}

export default ProductTable
