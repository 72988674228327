export const SSPSA_VERSION_CURRENT = 'current'
export const SSPSA_VERSION_PREVIOUS = 'previous'
export const SSPSA_VERSION_UPCOMING = 'upcoming'
export const SSPSA_VERSION_PREVIEW = 'preview'
export const SSPSA_VERSION_IN_PROGRESS = 'in-progress'

export const SSPSA_VERSION_MESSAGES = {
  [SSPSA_VERSION_CURRENT]: `SSPSA for the ${SSPSA_VERSION_CURRENT} cycle not available.`,
  [SSPSA_VERSION_PREVIOUS]: `SSPSA for the ${SSPSA_VERSION_PREVIOUS} cycle not available.`,
  [SSPSA_VERSION_UPCOMING]:
    'SSPSA for the next cycle is not yet available. A first look will be available from the Thursday before plinth changeover, and then the final version will be accessible the day before.',
}

export const SSPSA_VERSIONS = [
  SSPSA_VERSION_UPCOMING,
  SSPSA_VERSION_CURRENT,
  SSPSA_VERSION_PREVIOUS,
  SSPSA_VERSION_PREVIEW,
]
export const SSPSA_PREVIEW_VERSIONS = [
  SSPSA_VERSION_IN_PROGRESS,
  SSPSA_VERSION_UPCOMING,
  SSPSA_VERSION_CURRENT,
  SSPSA_VERSION_PREVIOUS,
  SSPSA_VERSION_PREVIEW,
]
