import React from 'react'
import { Heading2 } from '@jsluna/react'

import Section from 'src/components/Section'

const UnauthorisedError = () => (
  <Section>
    <Heading2>You are not authorised to view this page</Heading2>
    <p>Please ensure you are logged in and your role is correct.</p>
    <p>If your role or permissions have recently changed, you may need to refresh your browser.</p>
  </Section>
)

export default UnauthorisedError
