import Moment from 'moment'
import { extendMoment } from 'moment-range/dist/moment-range'

const moment = extendMoment(Moment)
export const DATE_FORMAT_DEFAULT = 'YYYY-MM-DD'

export const generateDateRange = (start, max = 28, end, format = DATE_FORMAT_DEFAULT) => {
  if (!start) {
    return []
  }

  const startDate = moment(start)
  let endDate

  if (end && moment(end).diff(start, 'days') <= max) {
    endDate = moment(end)
  } else {
    endDate = moment(startDate).add(max - 1, 'days')
  }

  const dates = moment.range(startDate, endDate).by('days')
  return [...dates].map(date => moment(date).format(format))
}

export const calculateDateRange = (entries = []) => {
  let startDate
  let endDate

  entries.forEach(entry => {
    if (!startDate || entry.startDate < startDate) {
      startDate = entry.startDate // eslint-disable-line prefer-destructuring
    }

    if (!endDate || entry.endDate > endDate) {
      endDate = entry.endDate // eslint-disable-line prefer-destructuring
    }
  })

  return { startDate, endDate }
}
