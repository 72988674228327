import { hasRequiredPermission } from 'src/utils/permissions'
import {
  UPDATE_SHELVES_PRODUCTS_CATEGORY,
  UPDATE_SHELVES_PRODUCTS_PROMO_MECHANIC,
  UPDATE_SHELVES_PRODUCTS_PACK_SIZE,
  UPDATE_SHELVES_PRODUCTS_SHELF_FILL,
  UPDATE_SHELVES_PRODUCTS_COMMENT,
  UPDATE_SHELVES_PRODUCTS_STORE_COMMENT,
  UPDATE_SHELVES_PRODUCTS_CLUSTERS,
  UPDATE_SHELVES_PRODUCTS_PERCENT_UPLIFT_AISLE,
  UPDATE_SHELVES_PRODUCTS_PERCENT_UPLIFT_PLINTH,
  UPDATE_SHELVES_PRODUCTS_WIGIG,
  UPDATE_SHELVES_PRODUCTS_PRICE_POINT,
  UPDATE_SHELVES_PRODUCTS_PROMO_CASE_SIZE,
  UPDATE_SHELVES_PRODUCTS_PROMOTIONAL,
  UPDATE_SHELVES_PRODUCTS_NF_PROMOTIONAL,
  UPDATE_SHELVES_PRODUCTS_NF_STOCK,
  UPDATE_SHELVES_PRODUCTS_NF_FINANCIALS,
} from 'src/constants/permissions'

const buildDisabled = ({ user }) => {
  const disabled = {
    category: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_CATEGORY),
    promoMechanic: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_PROMO_MECHANIC),
    promoMechanicValue: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_PROMO_MECHANIC),
    packSize: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_PACK_SIZE),
    shelfFill: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_SHELF_FILL),
    shelfFillType: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_SHELF_FILL),
    comment: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_COMMENT),
    storeComment: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_STORE_COMMENT),
    clusters: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_CLUSTERS),
    percentUpliftAisle: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_PERCENT_UPLIFT_AISLE),
    percentUpliftPlinth: !hasRequiredPermission(
      user,
      UPDATE_SHELVES_PRODUCTS_PERCENT_UPLIFT_PLINTH
    ),
    wigig: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_WIGIG),
    pricePoint: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_PRICE_POINT),
    promoCaseSize: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_PROMO_CASE_SIZE),
    // Promotional
    facingsOnShelf: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_PROMOTIONAL),
    ubw: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_PROMOTIONAL),
    originalRetailPrice: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_PROMOTIONAL),
    profitPpp: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_PROMOTIONAL),
    // Non-Food Promotional
    depClass: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_PROMOTIONAL),
    specialBuy: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_PROMOTIONAL),
    clearancePrice: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_PROMOTIONAL),
    // Non-Food Financials
    percentSoldPromoPrice: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_FINANCIALS),
    percentSoldClearancePrice: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_FINANCIALS),
    salesPhaseWeeks: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_FINANCIALS),
    soaPerUnit: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_FINANCIALS),
    bulkSupport: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_FINANCIALS),
    salesForecastPromoCycle: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_FINANCIALS),
    salesForecastLine: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_FINANCIALS),
    // Non-Food Stock
    nppRequired: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_STOCK),
    nppInStoreDate: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_STOCK),
    forecastPurchaseQuantity: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_STOCK),
    stockInShipperFdsu: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_STOCK),
    stockValueForPromo: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_NF_STOCK),
  }

  return disabled
}

export default buildDisabled
