import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import memoise from 'memoizee'

import { ArrowDown, ArrowUp } from '@jsluna/icons'

import pluralize from 'src/utils/pluralize'

import Cell from 'src/components/RowCell'
import ChangeTypeBadge from 'src/components/ChangeTypeBadge'
import InfoBadge from 'src/components/InfoBadge'

import { hfssStatus } from 'src/functions/productHfssStatus'
import createPosName from 'src/utils/createPOSName'

import Editor from '../../ShelfProductsEditor'
import DeleteButton from '../DeleteButton'
import DeleteDialog from '../DeleteDialog'
import EditButton from '../EditButton'
import withDragDrop from './withDragDrop'

const toDateString = memoise((date, format = 'D-MMM') => moment(date).format(format), { length: 2 })

const buildShelfFill = ({ shelfFill, shelfFillType }) =>
  shelfFill && shelfFillType ? `${shelfFill} ${pluralize(shelfFill, shelfFillType)}` : ''

const buildFacingsOnShelf = ({ facingsOnShelf }) =>
  facingsOnShelf ? `${facingsOnShelf} ${pluralize(facingsOnShelf, 'facing', 'facings')}` : ''

const buildPricePoint = ({ pricePoint }) => (pricePoint ? `£${pricePoint}` : '')

const buildSku = product => {
  if (product.deptComm) {
    return `${product.sku} - ${product.deptComm}`
  }
  return product.sku
}

const getShelfPosition = (shelves, shelfId) => shelves.findIndex(({ id }) => id === shelfId)

const getPreviousShelf = (shelves, shelfId) => {
  const index = getShelfPosition(shelves, shelfId)
  const previousShelf = shelves[index - 1]

  return previousShelf
}

const getNextShelf = (shelves, shelfId) => {
  const index = getShelfPosition(shelves, shelfId)
  const nextShelf = shelves[index + 1]

  return nextShelf
}

const Product = ({
  assignmentStartDate,
  assignmentEndDate,
  canDelete,
  canMove,
  canUpdate,
  categories,
  deleteProducts,
  disableUpdateDelete,
  hasChange,
  hasPendingChange,
  isChangeControlView,
  isLockedForChangeControl,
  isTopShelf,
  isBottomShelf,
  moveProduct,
  moveProductToShelf,
  overrideStartDate,
  overrideEndDate,
  pendingChangeControl,
  position,
  itemsOnShelf,
  refresh,
  shelfId,
  shelfProduct,
  weeks,
  shelves,
  spaceAssignment,
}) => {
  const isFirstProduct = position === 0
  const isLastProduct = position === itemsOnShelf - 1
  const canMoveUp = !isFirstProduct || (isFirstProduct && !isTopShelf)
  const canMoveDown = !isLastProduct || (isLastProduct && !isBottomShelf)
  const productStartDate = overrideStartDate || assignmentStartDate
  const productEndDate = overrideEndDate || assignmentEndDate
  const { hfss } = shelfProduct
  const productHfssIndicator = hfss && hfssStatus(hfss)

  return (
    <>
      <Cell>{shelfProduct.sku}</Cell>
      <Cell>{shelfProduct.deptComm && shelfProduct.deptComm}</Cell>
      {isChangeControlView && (
        <Cell>
          {hasChange && shelfProduct.changeType && (
            <ChangeTypeBadge id={shelfProduct.id} type={shelfProduct.changeType} />
          )}
        </Cell>
      )}
      <Cell>{shelfProduct.description}</Cell>
      <Cell className="c-hfss">{productHfssIndicator}</Cell>
      <Cell>
        {shelfProduct?.hasProductClustering && (
          <div className="c-icon-badge" data-control={`info-badge-${shelfProduct.id}`}>
            <InfoBadge message="This product has specific clustering" />
          </div>
        )}
      </Cell>
      <Cell>{shelfProduct.promoType}</Cell>
      <Cell>{buildShelfFill(shelfProduct)}</Cell>
      <Cell>{buildFacingsOnShelf(shelfProduct)}</Cell>
      <Cell>
        {toDateString(productStartDate)} - {toDateString(productEndDate)}
      </Cell>
      <Cell>
        {createPosName({
          type: shelfProduct?.posType,
          paCode: shelfProduct?.paCode,
          description: shelfProduct?.posDescription,
        })}
      </Cell>
      <Cell>{shelfProduct.createdBy.name}</Cell>
      <Cell>{buildPricePoint(shelfProduct)}</Cell>
      <Cell {...(canUpdate(shelfProduct) ? { 'data-control': 'edit' } : {})} alignment="right">
        {(isChangeControlView ? canMoveUp && shelfProduct.changeType !== 'remove' : canMoveUp) && (
          <button
            className="c-icon-button u-bg-none"
            data-control="move-up"
            disabled={!canMove}
            onClick={() => {
              if (isFirstProduct) {
                const previousShelf = getPreviousShelf(shelves, shelfId)

                moveProductToShelf(shelfProduct.id, previousShelf.id)
              } else {
                moveProduct(shelfProduct.id, position, position - 1)
              }
            }}
            type="button"
          >
            <ArrowUp />
          </button>
        )}
        {(isChangeControlView
          ? canMoveDown && shelfProduct.changeType !== 'remove'
          : canMoveDown) && (
          <button
            className="c-icon-button u-bg-none"
            data-control="move-down"
            disabled={!canMove}
            onClick={() => {
              if (isLastProduct) {
                const nextShelf = getNextShelf(shelves, shelfId)

                moveProductToShelf(shelfProduct.id, nextShelf.id, 0)
              } else {
                moveProduct(shelfProduct.id, position, position + 1)
              }
            }}
            type="button"
          >
            <ArrowDown />
          </button>
        )}
        {canUpdate(shelfProduct) && (
          <Editor
            assignmentStartDate={assignmentStartDate}
            assignmentEndDate={assignmentEndDate}
            categories={categories}
            disableUpdate={disableUpdateDelete}
            element={EditButton}
            hasPendingChange={hasPendingChange}
            id={shelfProduct.id}
            isLockedForChangeControl={isLockedForChangeControl}
            pendingChangeControl={pendingChangeControl}
            isChangeControlView={isChangeControlView}
            label={buildSku(shelfProduct)}
            onUpdate={refresh}
            weeks={weeks}
          />
        )}
        {(isChangeControlView
          ? canDelete(shelfProduct) && shelfProduct.changeType !== 'remove'
          : canDelete(shelfProduct)) && (
          <DeleteDialog
            deleteProducts={deleteProducts}
            disabled={disableUpdateDelete}
            element={DeleteButton}
            isLockedForChangeControl={isLockedForChangeControl}
            spaceAssignment={spaceAssignment}
            shelfProduct={shelfProduct}
          />
        )}
      </Cell>
    </>
  )
}

Product.propTypes = {
  assignmentStartDate: PropTypes.string,
  assignmentEndDate: PropTypes.string,
  canDelete: PropTypes.func.isRequired,
  canMove: PropTypes.bool.isRequired,
  canUpdate: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteProducts: PropTypes.func.isRequired,
  disableUpdateDelete: PropTypes.bool.isRequired,
  hasChange: PropTypes.bool.isRequired,
  hasPendingChange: PropTypes.bool.isRequired,
  isChangeControlView: PropTypes.bool.isRequired,
  isLockedForChangeControl: PropTypes.bool.isRequired,
  isTopShelf: PropTypes.bool.isRequired,
  isBottomShelf: PropTypes.bool.isRequired,
  overrideStartDate: PropTypes.string,
  overrideEndDate: PropTypes.string,
  moveProduct: PropTypes.func.isRequired,
  moveProductToShelf: PropTypes.func.isRequired,
  pendingChangeControl: PropTypes.shape({}),
  position: PropTypes.number.isRequired,
  itemsOnShelf: PropTypes.number.isRequired,
  refresh: PropTypes.func.isRequired,
  shelfId: PropTypes.string.isRequired,
  shelfProduct: PropTypes.shape({
    clusters: PropTypes.arrayOf(PropTypes.shape({})),
    changeType: PropTypes.string,
    promoType: PropTypes.string,
    hasProductClustering: PropTypes.bool,
    sku: PropTypes.string,
    deptComm: PropTypes.string,
    description: PropTypes.string,
    hfss: PropTypes.string,
    id: PropTypes.string.isRequired,
    facingsOnShelf: PropTypes.number,
    createdBy: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    posType: PropTypes.string,
    paCode: PropTypes.string,
    posDescription: PropTypes.string,
  }).isRequired,
  weeks: PropTypes.arrayOf(PropTypes.number).isRequired,
  shelves: PropTypes.arrayOf(PropTypes.shape({})),
  spaceAssignment: PropTypes.shape({}),
}

Product.defaultProps = {
  assignmentStartDate: null,
  assignmentEndDate: null,
  overrideStartDate: null,
  overrideEndDate: null,
  shelves: [],
  spaceAssignment: null,
  pendingChangeControl: null,
}

export default withDragDrop(Product)
