import React from 'react'
import PropTypes from 'prop-types'
import { Heading3 } from '@jsluna/react'
import ConnectedSpaceInstance from './SpaceInstance'

const SpaceInstances = ({ assignment, changeControlId }) => {
  const {
    spaceInstances = [],
    id: spaceAssignmentId,
    pendingChangeControl: changeControl,
  } = assignment

  return (
    <div>
      <Heading3>Change Control Details</Heading3>
      {spaceInstances.map(instance => {
        const { id, title, layoutType } = instance
        return (
          <ConnectedSpaceInstance
            changeControlId={changeControlId}
            changeControl={changeControl}
            instanceId={id}
            instanceTitle={title}
            key={id}
            spaceInstance={instance}
            spaceAssignmentId={spaceAssignmentId}
            layoutType={layoutType}
          />
        )
      })}
    </div>
  )
}

SpaceInstances.propTypes = {
  assignment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    spaceInstances: PropTypes.arrayOf(PropTypes.shape({})),
    autoSpaceInstance: PropTypes.shape({
      id: PropTypes.string,
    }),
    pendingChangeControl: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  changeControlId: PropTypes.string.isRequired,
}

export default SpaceInstances
