import React from 'react'
import PropTypes from 'prop-types'

import restructureData from 'src/utils/timelines/restructureData'
import Tracks from '.'
import Element from './Element'

const Track = ({ time, elements, isOpen, tracks, clickElement }) => {
  const resArrElements = restructureData(elements)
  return (
    <>
      {resArrElements.map(restructuredElements => (
        <div className="tr-track" key={`track-${restructuredElements.id}`}>
          <div className="pt-track__elements">
            {restructuredElements
              .filter(({ start, end }) => end > start)
              .map(restructuredElement => (
                <Element
                  key={restructuredElement.id}
                  time={time}
                  clickElement={clickElement}
                  {...restructuredElement}
                />
              ))}
          </div>
          {isOpen && tracks && tracks.length > 0 && (
            <Tracks time={time} tracks={tracks} clickElement={clickElement} />
          )}
        </div>
      ))}
    </>
  )
}

Track.propTypes = {
  time: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool,
  elements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
  clickElement: PropTypes.func,
}

Track.defaultProps = {
  isOpen: false,
  tracks: [],
  clickElement: undefined,
}

export default Track
