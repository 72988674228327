import axios from 'axios'
import queryString from 'qs'
import { selectConfig } from 'src/store/config/selectors'
import { selectToken } from 'src/store/data/selectors'
import LoginScope from 'src/utils/loginScope'
import msalInstance from 'src/msalAuthentication'
import { SET_TOKEN } from 'src/constants/api'
import moment, { now } from 'moment/moment'

class Api {
  baseUrl = '/net'

  credentials = null

  requestUrl = ''

  responseType = null

  method = 'GET'

  getHeaders() {
    return this.credentials !== null && this.credentials !== undefined
      ? {
          Authorization: `Bearer ${this.credentials}`,
        }
      : {}
  }

  mock = {}

  constructor(store, options = {}) {
    this.options = options
    const config = selectConfig(store)
    this.baseUrl = config?.apiUrl !== undefined ? config.apiUrl : '/net'
    this.credentials = selectToken(store)
  }

  makeMockRequest() {
    return Promise.resolve(this.mock)
  }

  formatRequestData = params => params

  formatResponseData = data => data

  getParams() {
    return this.options.params
  }

  getQueryParams() {
    if (this.options.queryParams) {
      return `?${queryString.stringify(this.options.queryParams)}`
    }
    return ''
  }

  getEndpointUrl() {
    return `${this.baseUrl}${this.requestUrl}${this.getQueryParams()}`
  }

  makeRequest() {
    const request = {
      headers: this.getHeaders(),
      method: this.method,
      url: this.getEndpointUrl(),
      responseType: this.responseType,
    }
    if (this.method === 'POST' || this.method === 'PATCH' || this.method === 'DELETE') {
      request.data = this.formatRequestData(this.getParams())
    }

    return axios(request)
  }

  isTokenExpired() {
    const base64EncodedPayload = this.credentials && this.credentials.split('.')[1]

    if (base64EncodedPayload) {
      const payload = atob(base64EncodedPayload)
      const claims = JSON.parse(payload)
      const exp = new Date(claims.exp * 1000)
      return moment(now()).isSameOrAfter(exp)
    }

    return true
  }

  async call(dispatch) {
    if (this.isTokenExpired()) {
      try {
        const accessTokenRequest = {
          scopes: [LoginScope()],
          account: msalInstance.getActiveAccount(),
        }

        let msalResponse = null

        try {
          msalResponse = await msalInstance.acquireTokenSilent(accessTokenRequest)
        } catch (tokenError) {
          try {
            msalResponse = await msalInstance.acquireTokenPopup(accessTokenRequest)
          } catch (popupError) {
            msalResponse = null
            return Promise.reject(popupError)
          }
        }

        dispatch({ type: SET_TOKEN, payload: { mainToken: msalResponse.accessToken } })
        await Promise.resolve()

        this.credentials = msalResponse.accessToken
      } catch (error) {
        return Promise.reject(error)
      }
    }

    return this.makeRequest().then(
      result => this.formatResponseData(result),
      error => {
        return Promise.reject(error)
      }
    )
  }
}

export default Api
