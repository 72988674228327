import { actionUpdateCategoryById, actionCreateCategory } from '../store'

const handleSubmit = async (values, { props: { afterSubmit, submit } }) => {
  const { id: categoryId, title, shortTitle, theme, isPriceLock, active, businessUnit } = values
  const category = {
    id: categoryId,
    shortTitle,
    title,
    theme,
    isPriceLock,
    active,
    businessUnit,
  }

  const action = categoryId
    ? actionUpdateCategoryById({ categoryId, category })
    : actionCreateCategory(category)

  await submit(action)
  afterSubmit()
}

export default handleSubmit
