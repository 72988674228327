import Categories from './Categories'

const getRole = user => (user.role ? user.role : 'Unassigned')
const getSortAndSearchValue = ({ categories = [] }) =>
  categories
    .map(category => category.title)
    .sort()
    .join(' ')
const getLink = user => `/users/${user.id}`

export default [
  { key: 'name', name: 'Name', value: 'name', sortValue: 'lastName', link: getLink },
  { key: 'role', name: 'Role', value: getRole },
  {
    key: 'categories',
    name: 'Categories',
    value: Categories,
    sortValue: getSortAndSearchValue,
    searchValue: getSortAndSearchValue,
  },
]
