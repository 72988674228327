import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const FlyoutContainer = ({ isVisible, children }) => (
  <div className={classNames('c-flyout-container', { 'is-open': isVisible })} data-control="flyout">
    {children}
  </div>
)

FlyoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool,
}

FlyoutContainer.defaultProps = {
  isVisible: false,
}

export default FlyoutContainer
