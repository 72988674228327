import { connect } from 'react-redux'
import moment from 'moment'

import { DATE_FORMAT_DEFAULT } from 'src/utils/dateRange'

import { createError } from 'src/store/notifications/actions'
import { selectCycles, selectCycleStatus } from 'src/modules/Cycles/store'
import { getDate } from 'src/utils/dateUtils'
import { createSspsaTask } from '../store'

import Form from './Form'

const getNextWednesday = () => {
  const dayOfWeek = 3
  const today = moment().isoWeekday()

  if (today <= dayOfWeek) {
    return moment().isoWeekday(dayOfWeek).format(DATE_FORMAT_DEFAULT)
  }
  return moment().add(1, 'weeks').isoWeekday(dayOfWeek).format(DATE_FORMAT_DEFAULT)
}

export const mapStateToProps = state => {
  const cycles =
    selectCycles(state)?.filter(cycle => moment(cycle.endDate).isAfter(getDate())) || []
  const isCycleLoading = selectCycleStatus(state) || false
  const nextWednesday = getNextWednesday()

  return {
    cycles,
    startDate: nextWednesday,
    isCycleLoading,
  }
}

export const mapDispatchToProps = dispatch => ({
  generateSspsaByDate: (businessUnit, comment, startDate, endDate, cycleId) => {
    const generateSspsaTask = {
      businessUnit,
      comment,
      startDate,
      endDate,
      cycleId,
    }
    dispatch(createSspsaTask(generateSspsaTask))
  },
  showError: message => dispatch(createError(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
