export const arrayMoveUp = (array, index) => {
  if (index <= 0 || index >= array.length) {
    return array
  }

  return [...array.slice(0, index - 1), array[index], array[index - 1], ...array.slice(index + 1)]
}

export const arrayMoveDown = (array, index) => {
  if (index < 0 || index >= array.length - 1) {
    return array
  }

  return [...array.slice(0, index), array[index + 1], array[index], ...array.slice(index + 2)]
}

export const arrayRemove = (array, index) => {
  if (index < 0 || index > array.length - 1) {
    return array
  }

  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export const arrayAdd = (array, index, item) => {
  if (index < 0) {
    return array
  }

  return [...array.slice(0, index), item, ...array.slice(index)]
}

export const arrayUpdatePosition = (array, from, to) => {
  if (from < 0 || from > array.length - 1 || to < 0 || to > array.length - 1) {
    return array
  }

  const arrItemRemoved = arrayRemove(array, from)
  const arrItemAdded = arrayAdd(arrItemRemoved, to, array[from])

  return arrItemAdded
}
