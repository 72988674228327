import React from 'react'
import PropTypes from 'prop-types'
import { List, ListItem, FilledButton } from '@jsluna/react'

const Controls = ({ cancel, reset, resetText, submitText, disabled }) => (
  <List type="matrix">
    <ListItem type="matrix">
      <FilledButton type="submit" disabled={disabled}>
        <span data-control="submit">{submitText}</span>
      </FilledButton>
    </ListItem>
    {cancel && (
      <ListItem type="matrix">
        <FilledButton color="dark" data-control="cancel" onClick={cancel} disabled={disabled}>
          <span>Cancel</span>
        </FilledButton>
      </ListItem>
    )}
    {reset && (
      <ListItem type="matrix">
        <FilledButton color="dark" onClick={reset} disabled={disabled}>
          <span data-control="reset">{resetText}</span>
        </FilledButton>
      </ListItem>
    )}
  </List>
)

Controls.propTypes = {
  disabled: PropTypes.bool,
  cancel: PropTypes.func,
  reset: PropTypes.func,
  submitText: PropTypes.string,
  resetText: PropTypes.string,
}

Controls.defaultProps = {
  cancel: null,
  disabled: false,
  reset: null,
  submitText: 'Save',
  resetText: 'Reset',
}

export default Controls
