import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { selectCycleInfo } from './store'
import Header from './Header'
import TabsWrap from './Tabs'

export const Component = ({ id, user, cycle, storeId, businessUnit, children }) => {
  const { title, group } = cycle || {}
  const groupTitle = group?.title
  return (
    <div>
      <Header {...{ id, title, businessUnit, groupTitle }} />
      {id && <TabsWrap {...{ id, user, cycle, storeId, businessUnit }} />}
      {React.cloneElement(children, {
        cycle,
        id,
        storeId,
        user,
        businessUnit,
      })}
    </div>
  )
}

Component.propTypes = {
  user: PropTypes.shape({}).isRequired,
  id: PropTypes.string,
  storeId: PropTypes.string,
  cycle: PropTypes.shape({ title: PropTypes.string }),
  businessUnit: PropTypes.oneOf(['food', 'non-food']).isRequired,
  children: PropTypes.node.isRequired,
}

Component.defaultProps = {
  id: null,
  storeId: null,
  cycle: {},
}

const mapStateToProps = (state, ownProps) => ({
  cycle: selectCycleInfo(state, ownProps.id),
})

export default connect(mapStateToProps)(Component)
