import Api from 'src/api/Base'

class GetCycleStorePSAPdf extends Api {
  requestUrl = `/api/Cycle/${this.getParams().cycleId}/Store/${
    this.getParams().storeId
  }/ExportStorePsa?startDate=${this.getParams().startDate}&endDate=${this.getParams().endDate}`

  responseType = 'blob'
}

export default GetCycleStorePSAPdf
