import moment from 'moment-timezone'
import { connect } from 'react-redux'

import { DATETIME_FORMAT } from 'src/utils/datePeriod'

import { selectChangeControlById } from '../store'
import Summary from './Summary'

const capitalise = string => string.charAt(0).toUpperCase() + string.slice(1)

const formatDateTime = datetime => moment(datetime).tz('Europe/London').format(DATETIME_FORMAT)

const changeControlStatusToStatusCardStatus = {
  accepted: 'success',
  approved: 'info',
  submitted: 'warning',
  draft: 'warning',
  rejected: 'danger',
  withdrawn: undefined,
}

export const mapStateToProps = (state, { changeControlId }) => {
  const {
    id,
    approvedAt,
    approvedBy,
    approveComment,
    createdAt,
    createdBy,
    changeControlReasonDescription,
    changeControlReasonCode,
    resolutionComment,
    resolvedAt,
    resolvedBy,
    spaceTitle,
    status,
  } = selectChangeControlById(state, changeControlId)

  const statusCardStatus = changeControlStatusToStatusCardStatus[status]

  const messages = [`Created by ${createdBy} on ${formatDateTime(createdAt)}`]

  if (approvedAt && approvedBy && ['approved', 'accepted', 'rejected'].includes(status)) {
    messages.push(`Approved by ${approvedBy} on ${formatDateTime(approvedAt)}`)
  }

  if (approveComment) messages.push(`Comment: ${approveComment}`)

  if (resolvedAt && resolvedBy && status !== 'submitted') {
    messages.push(`${capitalise(status)} by ${resolvedBy} on ${formatDateTime(resolvedAt)}`)

    if (resolutionComment) messages.push(`Comment: ${resolutionComment}`)
  }
  const reason = { changeControlReasonCode, changeControlReasonDescription }

  return {
    id,
    messages,
    reason,
    spaceTitle,
    status: capitalise(status),
    statusCardStatus,
  }
}

export default connect(mapStateToProps)(Summary)
