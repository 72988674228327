import React from 'react'
import PropTypes from 'prop-types'
import Cell from './Cell'

function Row({ cells }) {
  return (
    <tr className="ln-c-table__row c-users-table__row">
      {cells.map(({ key, displayValue, label, link }) => (
        <Cell key={key} dataLabel={label} link={link}>
          {displayValue}
        </Cell>
      ))}
    </tr>
  )
}

Row.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default Row
