import Api from '../Base'

class GetSpaceAssignmentWithInstances extends Api {
  requestUrl = this.getParams().changeControlId
    ? `/api/SpaceAssignment/${this.getParams().id}/ChangeControl/${
        this.getParams().changeControlId
      }/spaceinstances`
    : `/api/SpaceAssignment/${this.getParams().id}/spaceinstances`
}

export default GetSpaceAssignmentWithInstances
