export default function validate({
  id,
  title,
  endDate,
  changeControlStatus,
  shipperLockStatus,
  groupId,
  startDate,
}) {
  const errors = {}

  if (!title) {
    errors.title = 'Title is required'
  }

  if (!endDate) {
    errors.endDate = 'End Date is required'
  }

  if (!changeControlStatus) {
    errors.changeControlStatus = 'Change Control Status is required'
  }

  if (!shipperLockStatus) {
    errors.shipperLockStatus = 'Shipper Lock Status is required'
  }

  if (!id && !groupId) {
    errors.groupId = 'Group name is required'
  }

  if (endDate < startDate) {
    errors.endDate = 'End Date should be greater than start date'
  }

  return errors
}
