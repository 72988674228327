import Api from '../Base'

class GetSpaceAssignmentById extends Api {
  requestUrl = this.getParams().changeControlId
    ? `/api/SpaceAssignment/${this.getParams().id}/changecontrol/${
        this.getParams().changeControlId
      }`
    : `/api/SpaceAssignment/${this.getParams().id}`
}

export default GetSpaceAssignmentById
