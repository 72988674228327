export const log = (...input) => {
  if (process && process.env && process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(...input)
  }
}

export const logError = (...input) => {
  // eslint-disable-next-line no-console
  console.error(...input)
}
