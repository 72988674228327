const partition = (array, fn) => {
  const result = [[], []]

  array.forEach(item => {
    const key = fn(item) ? 0 : 1
    result[key].push(item)
  })

  return result
}

export default partition
