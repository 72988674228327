import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Heading3, Modal, FilledButton } from '@jsluna/react'
import { setHasOverlay } from '@jsluna/utils'

const WithConfirmation = ComposedComponent => {
  const wrappedComponentName =
    ComposedComponent.displayName || ComposedComponent.name || 'Component'

  class ButtonWithConfirmation extends Component {
    constructor(props) {
      super(props)

      this.state = {
        modalOpen: false,
      }

      this.handleClose = this.handleClose.bind(this)
      this.handleOpen = this.handleOpen.bind(this)
      this.handleConfirm = this.handleConfirm.bind(this)
    }

    handleOpen() {
      this.setState({ modalOpen: true })
    }

    handleClose() {
      setHasOverlay(false)
      this.setState({ modalOpen: false })
    }

    handleConfirm() {
      const { onClick } = this.props
      onClick()
      this.handleClose()
    }

    render() {
      const { message, title } = this.props
      const { modalOpen } = this.state
      const props = {
        ...this.props,
        onClick: this.handleOpen,
      }

      return (
        <>
          <ComposedComponent {...props} key="modal-button" />
          <Modal
            open={modalOpen}
            handleClose={this.handleClose}
            data-control="modal"
            key="modal-component"
            className="ln-u-text-align-left"
          >
            <Heading3>{title}</Heading3>
            <p>{message}</p>
            <div>
              <FilledButton data-control="modal-confirm" onClick={this.handleConfirm}>
                Confirm
              </FilledButton>
              <FilledButton
                color="dark"
                data-control="modal-cancel"
                onClick={this.handleClose}
                className="ln-u-push-left"
              >
                Cancel
              </FilledButton>
            </div>
          </Modal>
        </>
      )
    }
  }

  ButtonWithConfirmation.displayName = `ButtonWithConfirmation(${wrappedComponentName})`

  ButtonWithConfirmation.propTypes = {
    onClick: PropTypes.func.isRequired,
    message: PropTypes.string,
    title: PropTypes.string,
  }

  ButtonWithConfirmation.defaultProps = {
    message: 'Are you sure you want to delete this?',
    title: 'Please confirm this action',
  }

  return ButtonWithConfirmation
}

export default WithConfirmation
