import React from 'react'
import PropTypes from 'prop-types'
import { Field, withFormik } from 'formik'

import Quantity from './Quantity'

const Search = ({ onSearchChange, quantity, handleSubmit }) => (
  <form onSubmit={handleSubmit} className="ln-o-matrix-list ln-u-soft-bottom">
    <div className="ln-o-matrix-list__item">
      <Field
        name="search"
        component="input"
        className="ln-c-text-input"
        placeholder="Search"
        type="search"
        onChange={onSearchChange}
      />
    </div>
    <div className="ln-o-matrix-list__item">
      <Quantity quantity={quantity} />
    </div>
  </form>
)

Search.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
}

const wrappedSearch = withFormik({ handleSubmit: () => null })(Search)

export default wrappedSearch
