import React from 'react'
import { Router, browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

import createRoutes from './routes'

const createRouter = store => (
  <Router history={syncHistoryWithStore(browserHistory, store)}>{createRoutes(store)}</Router>
)

export default createRouter
