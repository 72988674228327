import { push } from 'react-router-redux'

import { ALL_ROLES_BY_ID } from 'src/constants/roles'

import { selectSelfRoleId } from 'src/store/data/selectors'

function redirect(path) {
  return (dispatch, getState) => {
    if (path === '/') {
      const roleId = selectSelfRoleId(getState())
      const role = ALL_ROLES_BY_ID[roleId]

      if (role && role.landingRoute) {
        dispatch(push(role.landingRoute))
        return
      }
    }

    dispatch(push(path))
  }
}

export default redirect
