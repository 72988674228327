import React from 'react'
import PropTypes from 'prop-types'
import { Field, withFormik, FieldArray } from 'formik'

import {
  Card,
  Heading2,
  Form,
  FormGroup,
  GridWrapper,
  GridItem,
  Section,
  FilledButton,
} from '@jsluna/react'

import { SelectField, TextInputField } from '@jsluna/form/extensions/formik'

import { STORAGE } from 'src/constants/spaceTypes'
import { getLayoutTypeLabel } from 'src/utils/layoutTypes'

import { Controls } from 'src/components/Form'
import Cell from 'src/components/RowCell'
import HeaderCell from 'src/components/HeaderCell'

import handleSubmit from './handleSubmit'
import validate from './validate'
import AddLayoutRow from '../AddLayoutRow'

export const STORAGE_OPTIONS = STORAGE.map(entry => ({
  ...entry,
  value: entry.id,
}))

const getLayout = (type, layouts) =>
  layouts.find(({ name, layoutType }) => name === type || layoutType === type)

const getLayoutsToAdd = (allLayouts, layouts) => {
  const availableLayouts = allLayouts.reduce((acc, cv) => {
    const added = layouts.some(({ layoutType }) => layoutType === cv.name)

    return added ? acc : [...acc, cv]
  }, [])

  return availableLayouts
}

const Component = ({
  allLayouts,
  initialValues: { layouts: savedLayouts },
  cancel,
  handleSubmit: onSubmit,
  values: { id, originalTitle, layouts },
  errors,
}) => {
  const layoutsToAdd = getLayoutsToAdd(allLayouts, layouts)
  const hasLayoutsToAdd = layoutsToAdd.length > 0
  const isSavedLayout = layoutType => !!getLayout(layoutType, savedLayouts)
  return (
    <Form onSubmit={onSubmit}>
      <Section>
        <Heading2>
          <span data-control="space-type-title">
            {id ? `Edit Space Type ${originalTitle}` : 'Create Space Type'}
          </span>
        </Heading2>
        <Card>
          <FormGroup name="space-type-fields">
            <GridWrapper matrix>
              <GridItem size="1/2@sm">
                <Field
                  component={TextInputField}
                  label="Title"
                  name="title"
                  validationFirst={false}
                  required
                />
              </GridItem>
              <GridItem size="1/2@sm">
                <Field
                  component={SelectField}
                  label="Storage"
                  name="storage"
                  options={STORAGE_OPTIONS}
                  validationFirst={false}
                  required
                />
              </GridItem>
              <GridItem size="1/3@sm">
                <Field
                  component={TextInputField}
                  label="Default Shelves"
                  name="shelvesDefault"
                  validationFirst={false}
                  required
                />
              </GridItem>
              <GridItem size="1/3@sm">
                <Field
                  component={TextInputField}
                  label="Min Shelves"
                  name="shelvesMin"
                  validationFirst={false}
                  required
                />
              </GridItem>
              <GridItem size="1/3@sm">
                <Field
                  component={TextInputField}
                  label="Max Shelves"
                  name="shelvesMax"
                  validationFirst={false}
                  required
                />
              </GridItem>
            </GridWrapper>
          </FormGroup>

          <FormGroup name="space-type-layout-types">
            <div className="ln-c-table-container ln-u-push-bottom">
              <table className="ln-c-table ln-c-table--fixed ln-u-text-align-center ln-u-border-bottom">
                <thead className="ln-c-table__header">
                  <tr className="ln-c-table__header-row">
                    <HeaderCell>LayoutType</HeaderCell>
                    <HeaderCell>Default Capacity</HeaderCell>
                    <HeaderCell>Min Capacity</HeaderCell>
                    <HeaderCell columnSpan="2">Max Capacity</HeaderCell>
                  </tr>
                </thead>

                <FieldArray
                  name="layouts"
                  render={arrayHelpers => (
                    <tbody className="ln-c-table__body">
                      {layouts.map((layout, index) => {
                        const { layoutType } = layout
                        const { hasCapacity } = getLayout(layoutType, allLayouts) || {
                          hasCapacity: false,
                        }

                        return (
                          <tr
                            key={`layout-type-${layoutType}`}
                            data-control={`layout-type-${layoutType}`}
                          >
                            <Cell>{getLayoutTypeLabel(layoutType)}</Cell>
                            <Cell>
                              <Field
                                component={TextInputField}
                                name={`layouts[${index}].defaultCapacity`}
                                disabled={!hasCapacity}
                                required
                                error={errors[`layouts[${index}].defaultCapacity`]}
                              />
                            </Cell>
                            <Cell>
                              <Field
                                component={TextInputField}
                                name={`layouts[${index}].minCapacity`}
                                disabled={!hasCapacity}
                                required
                                error={errors[`layouts[${index}].minCapacity`]}
                              />
                            </Cell>
                            <Cell>
                              <Field
                                component={TextInputField}
                                name={`layouts[${index}].maxCapacity`}
                                disabled={!hasCapacity}
                                required
                                error={errors[`layouts[${index}].maxCapacity`]}
                              />
                            </Cell>
                            <Cell className="c-table__cell--align-top">
                              <FilledButton
                                data-control="remove-layout"
                                fullWidth
                                color="dark"
                                disabled={isSavedLayout(layoutType)}
                                onClick={() => {
                                  arrayHelpers.remove(index)
                                }}
                              >
                                Remove
                              </FilledButton>
                            </Cell>
                          </tr>
                        )
                      })}
                      {hasLayoutsToAdd && (
                        <AddLayoutRow
                          layouts={layoutsToAdd}
                          allLayouts={allLayouts}
                          addLayout={layout => {
                            arrayHelpers.push(layout)
                          }}
                        />
                      )}
                    </tbody>
                  )}
                />
              </table>
            </div>
          </FormGroup>

          <Controls cancel={cancel} submitText="Save" />
        </Card>
      </Section>
    </Form>
  )
}

Component.propTypes = {
  allLayouts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      hasCapacity: PropTypes.bool,
      id: PropTypes.number,
    })
  ).isRequired,
  initialValues: PropTypes.shape({
    layouts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }).isRequired,
  cancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    id: PropTypes.string,
    originalTitle: PropTypes.string,
    layouts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        layoutType: PropTypes.string,
      })
    ),
  }).isRequired,
  errors: PropTypes.shape({}).isRequired,
}

const wrappedForm = withFormik({ handleSubmit, validate })(Component)

export default wrappedForm
