/* eslint-disable no-bitwise */
const hexToRgb = hex => {
  const i = parseInt(hex.substr(1), 16)
  const r = (i >> 16) & 255
  const g = (i >> 8) & 255
  const b = i & 255
  return `${r},${g},${b}`
}

export default hexToRgb
