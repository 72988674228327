import sortNatural from 'src/utils/sortNatural'
import { buildReducer, bindSelector, bindRootSelector } from 'src/store/modules'
import { createSelector } from '@reduxjs/toolkit'
import uiReducer from './reducer'
import fetchSpacePlanCyclesAssignmentsReducer, {
  fetchSpacePlanCyclesAssignments,
} from './reducer/spacePlanAssignments'
import {
  groupsBySpacePlanSlice,
  fetchGroupsBySpacePlan,
  actionClearData,
} from './reducer/GroupsBySpacePlan'

export const NAMESPACE = 'SpacePlanner'

const selectModule = state => state.modules.SpacePlanner.GroupDetail
const selectGroupsBySpacePlan = createSelector(selectModule, module => {
  const groupList = module?.data
  if (groupList && groupList.length > 0) {
    const sortedGroupList = sortNatural([...groupList], 'title')
    return sortedGroupList
  }
  return []
})

const selectSelf = state => state.modules.SpacePlanner.SpacePlanCyclesAssignments
const selectSpacePlanCyclesAssignments = createSelector(selectSelf, module => module.data)
const selectSpacePlanAssignmentsCycleDetails = createSelector(
  selectSpacePlanCyclesAssignments,
  spacePlan => spacePlan.cycleDetails
)
const selectSpacePlanDetails = createSelector(
  selectSpacePlanCyclesAssignments,
  spacePlan => spacePlan.spaceDetails || []
)
const selectSpacePlanCyclesAssignmentsStatus = createSelector(selectSelf, module => module)

const selectGroupSelected = createSelector(
  selectSelf,
  selectGroupsBySpacePlan,
  (planner, groupList) => {
    return groupList.find(group => group.id === planner.SelectedGroup)
  }
)

const selectUi = bindSelector(NAMESPACE)(state => state.ui)
const selectRoot = bindRootSelector(NAMESPACE)

export default buildReducer(NAMESPACE, {
  ui: uiReducer,
  [groupsBySpacePlanSlice.name]: groupsBySpacePlanSlice.reducer,
  ...fetchSpacePlanCyclesAssignmentsReducer,
})

export {
  selectRoot,
  selectUi,
  selectGroupsBySpacePlan,
  fetchGroupsBySpacePlan,
  selectSpacePlanCyclesAssignments,
  fetchSpacePlanCyclesAssignments,
  selectSpacePlanAssignmentsCycleDetails,
  selectSpacePlanCyclesAssignmentsStatus,
  actionClearData,
  selectSpacePlanDetails,
  selectGroupSelected,
}
