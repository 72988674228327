import React from 'react'
import PropTypes from 'prop-types'

const buildString = (quantity, displayMessage) => {
  switch (quantity) {
    case 0:
      return displayMessage ? 'No results' : ''
    case 1:
      return `${quantity} result`
    default:
      return `${quantity} results`
  }
}

const Quantity = ({ quantity, displayMessage }) =>
  displayMessage ? <div>{buildString(quantity, displayMessage)} found</div> : ''

Quantity.propTypes = {
  quantity: PropTypes.number,
  displayMessage: PropTypes.bool,
}

Quantity.defaultProps = {
  quantity: 0,
  displayMessage: true,
}

export default Quantity
