/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { buildStages } from 'src/utils/stages'
import { DATE_FORMAT } from 'src/utils/datePeriod'
import { getDifferenceText } from 'src/utils/dateDifference'
import now from 'src/utils/now'

function TimelineTable({ entity, timeline }) {
  const { stages } = buildStages({ entity, timeline })
  const tasks = stages.filter(s => s.hasTask)
  const today = moment(now())

  return (
    <div className="ln-c-table-container">
      <table className="ln-c-table ln-c-table--responsive@sm">
        <thead className="ln-c-table__header">
          <tr className="ln-c-table__row ln-c-table__header-row">
            <th className="ln-c-table__header-cell">Stage</th>
            <th className="ln-c-table__header-cell">Due Date</th>
            <th className="ln-c-table__header-cell">Completed</th>
          </tr>
        </thead>
        <tbody className="ln-c-table__body">
          {tasks.map((stage, index) => {
            const {
              task: { dueDate, isComplete },
              description,
            } = stage
            const daysUntilToday = getDifferenceText(dueDate, today)

            return (
              <tr key={index} className="ln-c-table__row">
                <td className="ln-c-table__cell" data-label="Event">
                  {description}
                </td>
                <td
                  className="ln-c-table__cell ln-c-table__cell--label-nowrap"
                  data-label="Due Date"
                >
                  {`${moment(dueDate).format(DATE_FORMAT)} (${daysUntilToday})`}
                </td>
                <td className="ln-c-table__cell" data-label="Completed">
                  {isComplete ? 'Yes' : 'No'}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

TimelineTable.propTypes = {
  timeline: PropTypes.shape({}).isRequired,
  entity: PropTypes.shape({}).isRequired,
}

export default TimelineTable
