import { numOfDaysBetweenInclusive } from 'src/utils/day'

const toInt = number => parseInt(Number(number).toFixed(0), 10)
const clamp = (number, min = 0, max = 100) => {
  if (number < min) return min
  if (number > max) return max
  return number
}

const calculateCoverage = ({ gaps = [], startDate, endDate }) => {
  const totalDuration = numOfDaysBetweenInclusive(startDate, endDate)
  const emptyDuration = gaps.reduce(
    (total, gap) => total + numOfDaysBetweenInclusive(gap.startDate, gap.endDate),
    0
  )
  const assignedDuration = totalDuration - emptyDuration
  const percentage = (assignedDuration / totalDuration) * 100

  return {
    emptyDuration,
    percentage: clamp(toInt(percentage)),
  }
}

export default calculateCoverage
