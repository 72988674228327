import { connect } from 'react-redux'

import { CHANGE_CONTROL_UNLOCKED_ID } from 'src/constants/cycles'
import { hasRequiredLayoutType, isPalletOrStackLayout } from 'src/utils/layoutTypes'

import { isDraft } from 'src/utils/changeControls'

import { hasRequiredPermission, hasRequiredCategory } from 'src/utils/permissions'

import { selectSelf, selectAllowedCategories } from 'src/store/data/selectors'

import {
  CREATE_SHELVES_PRODUCTS,
  CREATE_SHELVES_PRODUCTS_ALL,
  DELETE_SHELVES_PRODUCTS_ALL,
  DELETE_SHELVES_PRODUCTS_OWN,
  DELETE_SHELVES_PRODUCTS_OWN_CATEGORY,
  UPDATE_SHELVES_PRODUCTS_POS_ALL,
  UPDATE_SHELVES_PRODUCTS_POS_OWN_CATEGORY,
} from 'src/constants/permissions'

import {
  selectSpaceAssignmentWithSpaceInstances,
  selectSpaceInstanceById,
} from 'src/modules/SpaceAssignment/store/selectors'
import {
  addShelfArtifact,
  deleteProducts,
  deleteShelf,
  moveShelfDown,
  moveShelfUp,
  updateShelf,
  refreshSpaceInstances,
} from '../../store/actions'
import ShelfControls from './ShelfControls'
import { selectUi } from '../../store'

const isNotEmpty = list => list.length > 0

const isSameUser = (user1, user2) => user1.id === user2.id

const canDelete = user => product =>
  hasRequiredPermission(user, DELETE_SHELVES_PRODUCTS_ALL) ||
  (hasRequiredPermission(user, DELETE_SHELVES_PRODUCTS_OWN_CATEGORY) &&
    hasRequiredCategory(user, product.category.id)) ||
  (hasRequiredPermission(user, DELETE_SHELVES_PRODUCTS_OWN) && product.createdBy.id === user.id)

export const mapStateToProps = (state, { shelf }) => {
  const { categories, shelfProducts: products = [], palletOrStackType, spaceInstanceId } = shelf
  const { shelves, layoutType, clusterDistributions: clusters } = selectSpaceInstanceById(
    state,
    spaceInstanceId
  )
  const user = selectSelf(state)
  const { viewChangeControl } = selectUi(state)

  const isChangeControlView = !!viewChangeControl
  const canCreateProduct = hasRequiredPermission(user, CREATE_SHELVES_PRODUCTS)

  const canAddProduct = isNotEmpty(categories) && canCreateProduct

  const canAddDeleteArtifact = hasRequiredPermission(user, CREATE_SHELVES_PRODUCTS)

  const allowedCategories = selectAllowedCategories(state, categories)
  const canCreateAllProducts = hasRequiredPermission(user, CREATE_SHELVES_PRODUCTS_ALL)
  const canAddProductForCategory = isNotEmpty(allowedCategories) || canCreateAllProducts

  const availableCategories = canCreateAllProducts ? categories : allowedCategories

  const {
    changeControlStatus = CHANGE_CONTROL_UNLOCKED_ID,
    pendingChangeControl,
    startDate: assignmentStartDate,
    endDate: assignmentEndDate,
  } = selectSpaceAssignmentWithSpaceInstances(state)

  const isLockedForChangeControl = changeControlStatus !== CHANGE_CONTROL_UNLOCKED_ID

  const canAddDeleteInChangeControl =
    isChangeControlView &&
    isDraft(pendingChangeControl) &&
    isSameUser(user, pendingChangeControl.createdBy)

  const disableAddProduct =
    !canAddProductForCategory ||
    (isLockedForChangeControl && !canAddDeleteInChangeControl) ||
    (isPalletOrStackLayout(layoutType) && !hasRequiredLayoutType(palletOrStackType))

  const canDeleteAllProducts = products.every(canDelete(user))
  const disableDeleteAllProducts =
    products.length === 0 ||
    !canDeleteAllProducts ||
    (isLockedForChangeControl && !canAddDeleteInChangeControl)

  const canEditPOSAdviceInChangeControl =
    isChangeControlView &&
    isDraft(pendingChangeControl) &&
    isSameUser(user, pendingChangeControl.createdBy)

  const canEditPOSAdviceOnlyInChangeControl =
    isLockedForChangeControl &&
    canEditPOSAdviceInChangeControl &&
    isNotEmpty(allowedCategories) &&
    hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_POS_OWN_CATEGORY)

  const canEditPOSAdvice =
    hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_POS_ALL) ||
    canEditPOSAdviceOnlyInChangeControl

  const disableEditPOSAdvice =
    !canEditPOSAdvice ||
    (isLockedForChangeControl && !canEditPOSAdviceInChangeControl) ||
    (isPalletOrStackLayout(layoutType) && !hasRequiredLayoutType(palletOrStackType))

  return {
    assignmentStartDate,
    assignmentEndDate,
    availableCategories,
    canAddDeleteArtifact,
    canAddProduct,
    canAddProductForCategory,
    canDeleteAllProducts,
    clusters,
    disableAddProduct,
    disableDeleteAllProducts,
    isLockedForChangeControl,
    layoutType,
    pendingChangeControl,
    shelves,
    user,
    canEditPOSAdvice,
    disableEditPOSAdvice,
  }
}

const mapDispatchToProps = {
  addShelfArtifact,
  deleteProducts,
  deleteShelf,
  moveShelfUp,
  moveShelfDown,
  refreshSpaceInstances,
  updateShelf,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShelfControls)
