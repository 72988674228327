import React from 'react'
import PropTypes from 'prop-types'
import { OutlinedButton, FilledButton } from '@jsluna/react'

import buildTitle from './buildTitle'

const ModalButton = props => {
  const {
    excludeSpaceWithNoMatchingCategories,
    excludeSpaceWithFullCoverage,
    businessUnitFilter,
  } = props
  const filteredProps = {
    ...props,
    'data-control': 'spaces-filter',
    className: 'ln-u-text-align-left ln-u-font-weight-medium u-nowrap',
  }

  delete filteredProps.excludeSpaceWithFullCoverage
  delete filteredProps.excludeSpaceWithNoMatchingCategories
  delete filteredProps.businessUnitFilter

  if (
    !excludeSpaceWithNoMatchingCategories &&
    !excludeSpaceWithFullCoverage &&
    businessUnitFilter === 'all'
  ) {
    return <OutlinedButton {...filteredProps}>Spaces</OutlinedButton>
  }

  return <FilledButton {...filteredProps}>{buildTitle(props)}</FilledButton>
}

ModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  excludeSpaceWithNoMatchingCategories: PropTypes.bool,
  excludeSpaceWithFullCoverage: PropTypes.bool,
  businessUnitFilter: PropTypes.string,
}

ModalButton.defaultProps = {
  excludeSpaceWithNoMatchingCategories: false,
  excludeSpaceWithFullCoverage: false,
  businessUnitFilter: 'all',
}

export default ModalButton
