import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

import { Heading2, Form, FormGroup, GridWrapper, GridItem } from '@jsluna/react'
import { SelectField } from '@jsluna/form/extensions/formik'

import { PALLET_AND_STACK_TYPE_OPTIONS } from 'src/constants/palletOrStackTypes'
import { getLayoutTypeLabel, isPalletOrStackLayout, isShelf } from 'src/utils/layoutTypes'

import { Controls } from 'src/components/Form'
import { Checkbox } from 'src/components/FormikComponents'

const FormComponent = ({ handleSubmit: onSubmit, isSubmitting, onClose, layoutType, values }) => (
  <Form onSubmit={onSubmit}>
    <Heading2>
      <span data-control="shelf-title">Edit {`${getLayoutTypeLabel(layoutType)}`}</span>
    </Heading2>
    <FormGroup name="shelf-fields">
      <GridWrapper matrix>
        {isPalletOrStackLayout(layoutType) && (
          <GridItem size="1/2" className="ln-u-hard-bottom">
            <Field
              component={SelectField}
              label={`Type of ${layoutType}`}
              name="shelf.palletOrStackType"
              data-control="palletOrStackType"
              options={PALLET_AND_STACK_TYPE_OPTIONS}
              validationFirst={false}
              value={values.shelf.palletOrStackType || ''}
            />
          </GridItem>
        )}
        {isShelf(layoutType) && (
          <GridItem size="1/1">
            <Field
              data-control="isRsb"
              component={Checkbox}
              label="Has Rear Support Bar (RSB)"
              name="shelf.isRsb"
            />
          </GridItem>
        )}
      </GridWrapper>
    </FormGroup>
    <Controls cancel={onClose} disabled={isSubmitting} submitText="Save" />
  </Form>
)

FormComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  layoutType: PropTypes.string.isRequired,
  values: PropTypes.shape({
    shelf: PropTypes.shape({
      palletOrStackType: PropTypes.string,
      isRsb: PropTypes.bool,
    }),
  }),
}

FormComponent.defaultProps = {
  values: {},
}

export default FormComponent
