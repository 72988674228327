import React from 'react'
import PropTypes from 'prop-types'

import buildShelfTitle from 'src/utils/shelfTitle'

import Cell from '../../RowCell'

const ShelfArtifact = ({ artifact, columnSpan, shelfPosition, shelvesLength, layoutType }) => {
  const { artifactType: type, description = {} } = artifact
  const showShelfTitle = shelfPosition >= 0 && shelvesLength >= 0 && layoutType

  const classNames =
    type === 'divider'
      ? 'ln-u-font-weight-bold ln-u-bg-color-red ln-u-color-white'
      : 'ln-u-font-weight-bold ln-u-bg-color-yellow'

  return (
    <tr
      className={`ln-c-table__row c-space-assignment-row ${classNames}`}
      data-control="shelf-artifact-row"
    >
      {showShelfTitle && (
        <Cell alignment="left" columnSpan={1} label="Shelf">
          {buildShelfTitle(shelfPosition, shelvesLength, false, layoutType)}
        </Cell>
      )}
      <Cell alignment="left" columnSpan={columnSpan - 1}>
        {(type === 'divider' && 'DIVIDER') || description}
      </Cell>
    </tr>
  )
}

ShelfArtifact.propTypes = {
  artifact: PropTypes.shape({
    id: PropTypes.string.isRequired,
    artifactType: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  columnSpan: PropTypes.number,
  shelfPosition: PropTypes.number,
  shelvesLength: PropTypes.number,
  layoutType: PropTypes.string,
}

ShelfArtifact.defaultProps = {
  columnSpan: 0,
  shelfPosition: 0,
  shelvesLength: 0,
  layoutType: null,
}

export default ShelfArtifact
