import React from 'react'
import PropTypes from 'prop-types'
import { Field, withFormik } from 'formik'
import { Form, FormGroup, GridWrapper, GridItem } from '@jsluna/react'
import { TextInputField } from '@jsluna/form/extensions/formik'

import { Controls } from 'src/components/Form'

import { DateField } from 'src/components/FormikComponents'

import Duration from './components/Duration'
import Previous from './components/Previous'
import Status from './components/Status'

import handleSubmit from './handleSubmit'
import validate from './validate'

const Component = ({
  handleSubmit: onSubmit,
  handleReset,
  cancel,
  duration,
  previous,
  maxDate,
  startDate,
  maxStartDate,
  statuses,
  isReadOnly,
  titleDisabled,
  startDateDisabled,
  endDateDisabled,
  statusDisabled,
}) => (
  <Form onSubmit={onSubmit} noValidate>
    <FormGroup
      name="space-plan-fields"
      className={isReadOnly ? '' : 'ln-c-form-group--with-separator'}
    >
      <GridWrapper matrix>
        <GridItem size={{ sm: '1/1', md: '2/3' }}>
          <Field
            component={TextInputField}
            label="Title"
            name="title"
            disabled={isReadOnly || titleDisabled}
            validationFirst={false}
            required
          />
        </GridItem>
        <GridItem size="1/2">
          <DateField
            disabled={isReadOnly || (previous && previous.id && true) || startDateDisabled}
            label="Start Date"
            maxDate={maxStartDate}
            name="startDate"
            required
          />
        </GridItem>
        <GridItem size="1/2">
          <DateField
            disabled={isReadOnly || endDateDisabled}
            label="End Date"
            minDate={startDate}
            maxDate={maxDate}
            name="endDate"
            required
          />
        </GridItem>
        {duration && <Duration {...{ duration }} />}
        <Previous {...{ previous }} />
        <Status {...{ statuses, isReadOnly, statusDisabled }} />
      </GridWrapper>
    </FormGroup>

    {!isReadOnly && <Controls cancel={cancel} reset={handleReset} />}
  </Form>
)

Component.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  previous: PropTypes.shape({ id: PropTypes.string }),
  duration: PropTypes.number,
  maxDate: PropTypes.string,
  startDate: PropTypes.string,
  maxStartDate: PropTypes.string,
  isReadOnly: PropTypes.bool,
  titleDisabled: PropTypes.bool,
  startDateDisabled: PropTypes.bool,
  endDateDisabled: PropTypes.bool,
  statusDisabled: PropTypes.bool,
}

Component.defaultProps = {
  previous: null,
  duration: null,
  maxDate: null,
  startDate: null,
  maxStartDate: null,
  isReadOnly: false,
  titleDisabled: false,
  startDateDisabled: false,
  endDateDisabled: false,
  statusDisabled: false,
}

const wrappedForm = withFormik({ handleSubmit, validate, validateOnMount: true })(Component)

export default wrappedForm
