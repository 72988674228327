import React from 'react'
import PropTypes from 'prop-types'
import { Fieldset, Form, FilledButton, Heading3 } from '@jsluna/react'
import { CheckboxGroup } from 'src/components/FormikComponents'

const CategoryFilter = ({ handleSubmit: onSubmit, categoryOptions }) => (
  <Form onSubmit={onSubmit}>
    <Heading3>Filter</Heading3>
    <Fieldset hard>
      <CheckboxGroup name="categories" options={categoryOptions} />
    </Fieldset>
    <FilledButton type="submit">Apply</FilledButton>
  </Form>
)

CategoryFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
}

CategoryFilter.defaultProps = {
  categoryOptions: [],
}

export default CategoryFilter
