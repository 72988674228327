import React from 'react'
import PropTypes from 'prop-types'
import { Select } from '@jsluna/react'

const GroupDropDown = props => {
  const { value, onChange, options } = props

  return (
    <Select
      name="group-dropdown"
      options={options}
      value={value}
      onChange={onChange}
      placeholder={false}
    />
  )
}

GroupDropDown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
}

GroupDropDown.defaultProps = {
  options: [],
}

export default GroupDropDown
