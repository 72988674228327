import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

import { Fieldset, Form, FormGroup, FilledButton, RadioButtonField } from '@jsluna/react'
import { Checkbox } from 'src/components/FormikComponents'

const BusinessUnitRadioButton = props => {
  const {
    field: { name, value: inputValue },
    form: { setFieldValue },
  } = props

  const changeStrategy = ({ target: { value } }) => {
    setFieldValue(name, value)
  }

  const options = [
    { value: 'all', label: 'All spaces' },
    { value: 'food', label: 'Food spaces' },
    { value: 'non-food', label: 'Non-food spaces' },
  ]

  return (
    <RadioButtonField
      label="Business Unit"
      hideLabel
      required
      name="businessUnitFilterRadio"
      validationFirst={false}
      onChange={changeStrategy}
      value={inputValue}
      options={options}
    />
  )
}

BusinessUnitRadioButton.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }).isRequired,
}

const SpaceFilter = ({ handleSubmit: onSubmit }) => (
  <Form onSubmit={onSubmit}>
    <Fieldset hard>
      <FormGroup className="u-nowrap" name="space-filter" hideLabel>
        <div className="ln-u-push-bottom ln-u-border-bottom">
          <Field
            label="business unit"
            name="spaces.businessUnitFilter"
            component={BusinessUnitRadioButton}
          />
        </div>
        <Field
          component={Checkbox}
          name="spaces.excludeSpaceWithNoMatchingCategories"
          label="With selected categories"
        />
        <Field
          component={Checkbox}
          name="spaces.excludeSpaceWithFullCoverage"
          label="With missing coverage"
        />
      </FormGroup>
    </Fieldset>
    <FilledButton type="submit">Apply</FilledButton>
  </Form>
)

SpaceFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

export default SpaceFilter
