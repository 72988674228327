import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
  clearDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { GetAdminConfigMessages } from 'src/api/RangeAndSpace'
import { createSelector } from 'reselect'

const NAMESPACE = 'AdminConfigMessages'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = (slice, thunkName) => `${slice}/${thunkName}`

const selectModule = state => state.modules[NAMESPACE]
const selectAllAdminConfigMessages = createSelector(
  selectModule,
  module =>
    module.data?.map(message => ({
      ...message,
    })) || []
)

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const fetchAdminConfigMessages = createAsyncThunk(
  buildThunkPrefix('fetchAdminConfigMessages'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getAdminConfigMessagesInstance = new GetAdminConfigMessages(store)

    const response = await handleCreateAsyncThunkResult(
      getAdminConfigMessagesInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchAdminConfigMessagesSlice = createSlice({
  name: NAMESPACE,
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchAdminConfigMessages.pending, pendingDataHandler)
    builder.addCase(fetchAdminConfigMessages.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchAdminConfigMessages.rejected, rejectedDataHandler)
    builder.addCase(actionClearData, clearDataHandler)
  },
})

export default { [NAMESPACE]: fetchAdminConfigMessagesSlice.reducer }
export { fetchAdminConfigMessages, selectAllAdminConfigMessages, actionClearData }
