import React from 'react'
import PropTypes from 'prop-types'
import { OutlinedButton, FilledButton } from '@jsluna/react'

import { ALL } from 'src/constants/spacePlanner'

import buildTitle from './buildTitle'

const FlyoutButton = props => {
  const { categoriesLength, categorySelectionType } = props

  const filteredProps = {
    ...props,
    'data-control': 'categories-filter',
    className: 'ln-u-text-align-left ln-u-font-weight-medium u-nowrap',
  }

  delete filteredProps.categoriesLength
  delete filteredProps.categorySelectionType

  if (categorySelectionType !== ALL) {
    return (
      <FilledButton {...filteredProps}>
        {buildTitle(categoriesLength, categorySelectionType)}
      </FilledButton>
    )
  }
  return <OutlinedButton {...filteredProps}>Categories</OutlinedButton>
}

FlyoutButton.propTypes = {
  categoriesLength: PropTypes.number,
  categorySelectionType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

FlyoutButton.defaultProps = {
  categoriesLength: 0,
}

export default FlyoutButton
