/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  List,
  ListItem,
  Heading5,
  FlagWrapper,
  FlagBody,
  FlagComponent,
  TextButton,
} from '@jsluna/react'

import buildShelfTitle from 'src/utils/shelfTitle'
import { categoriesTitle as buildCategoriesTitle } from 'src/utils/categoriesTitle'

import {
  getPalletOrStackType,
  isPalletOrStackLayout,
  getLayoutTypeLabel,
} from 'src/utils/layoutTypes'

import ShelfControls from './ShelfControls'
import ShelfDetails from './ShelfDetails'
import { updateShelf as updateShelfAction } from '../store/actions'

const ShelfTitle = ({
  position,
  shelf,
  shelves,
  minShelves,
  maxShelves,
  weeks,
  layoutType,
  updateShelf,
  canSetPalletOrStackType,
}) => {
  const [showDetails, setShowDetails] = useState(false)
  const shelfTitle = [
    getLayoutTypeLabel(layoutType),
    buildShelfTitle(position, shelves.length, shelf.isRsb, layoutType),
  ].join(' ')

  return (
    <div className="ln-u-soft-sm ln-u-border-bottom ln-u-bg-color-light">
      <FlagWrapper>
        <FlagBody>
          <List type="matrix">
            <ListItem type="matrix">
              <Heading5 className="ln-u-flush-bottom" data-control="shelf-title">
                {shelfTitle}
                {isPalletOrStackLayout(layoutType) && (
                  <>
                    {` - `}
                    <TextButton
                      className="ln-u-hard-left"
                      onClick={() => setShowDetails(true)}
                      disabled={!canSetPalletOrStackType}
                      data-control="set-stack-or-pallet-type"
                    >
                      {getPalletOrStackType(layoutType, shelf?.palletOrStackType)}
                    </TextButton>
                    <ShelfDetails
                      open={showDetails}
                      shelf={shelf}
                      updateShelf={updateShelf}
                      layoutType={layoutType}
                      onClose={() => setShowDetails(false)}
                    />
                  </>
                )}
              </Heading5>
            </ListItem>
          </List>
        </FlagBody>
        <FlagComponent nowrap>
          <List type="matrix">
            <ListItem type="matrix">{buildCategoriesTitle([...shelf.categories])}</ListItem>
            <ListItem type="matrix">
              <ShelfControls
                shelf={shelf}
                position={position}
                minShelves={minShelves}
                maxShelves={maxShelves}
                weeks={weeks}
              />
            </ListItem>
          </List>
        </FlagComponent>
      </FlagWrapper>
    </div>
  )
}

ShelfTitle.propTypes = {
  shelf: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    isRsb: PropTypes.bool.isRequired,
    palletOrStackType: PropTypes.string,
  }).isRequired,
  shelves: PropTypes.arrayOf(PropTypes.object).isRequired,
  position: PropTypes.number.isRequired,
  minShelves: PropTypes.number.isRequired,
  maxShelves: PropTypes.number.isRequired,
  weeks: PropTypes.arrayOf(PropTypes.number).isRequired,
  layoutType: PropTypes.string.isRequired,
  updateShelf: PropTypes.func,
  canSetPalletOrStackType: PropTypes.bool.isRequired,
}

ShelfTitle.defaultProps = {
  updateShelf: () => {},
}

const mapDispatchToProps = {
  updateShelf: updateShelfAction,
}

export default connect(null, mapDispatchToProps)(ShelfTitle)
