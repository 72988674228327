import pluralize from './pluralize'
import sortArray from './sortArray'

export const categoriesTitle = unsortedCategories => {
  const categories = sortArray(unsortedCategories, 'title').map(c => c.title)

  // eslint-disable-next-line default-case
  switch (categories.length) {
    case 0:
      return 'No Categories'
    case 1:
      return categories[0]
    case 2:
      return `${categories[0]} and ${categories[1]}`
  }

  const start = categories.slice(0, 2).join(', ')
  const count = categories.length - 2

  return `${start} and ${count} more ${pluralize(count, 'category', 'categories')}`
}

export default categoriesTitle
