import merge from './merge'

function reduceKeys(object = {}, metadata) {
  return Object.keys(object).reduce(
    (obj, key) => ({
      ...obj,
      [key]: metadata,
    }),
    {}
  )
}

function fromObject({ attributes, relationships }) {
  return {
    ...reduceKeys(attributes, { type: 'attributes' }),
    ...reduceKeys(relationships, { type: 'relationships' }),
  }
}

function fromCollection(data = []) {
  return (Array.isArray(data) ? data : [data]).reduce((obj, item) => {
    const { type } = item

    if (!item || !type) {
      return obj
    }

    const existing = obj[type]

    return {
      ...obj,
      [type]: {
        ...existing,
        ...fromObject(item),
      },
    }
  }, {})
}

function fromResponse({ data, included = [] }) {
  return merge(fromCollection(included), fromCollection(data))
}

export default fromResponse
