import { buildReducer } from 'src/store/modules'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  handleCreateAsyncThunkResult,
  actionClearDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { PostUserInfo } from 'src/api/User'

const NAMESPACE = 'Unassigned'

const actionClearData = actionClearDataHandler(NAMESPACE)

const createNewUser = createAsyncThunk(
  NAMESPACE,
  async (newUser, { getState, dispatch, rejectWithValue }) => {
    const store = getState()

    const postNewUserInstance = new PostUserInfo(store, {
      params: { ...newUser },
    })
    const response = await handleCreateAsyncThunkResult(
      postNewUserInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

export default buildReducer(NAMESPACE)
export { actionClearData, createNewUser }
