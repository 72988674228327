import React from 'react'
import loader from 'src/hoc/loader'
import PageNotFound from 'src/components/Errors/NotFound'

import { fetchApprovalsBySpacePlan } from '../store'
import Approvals from './Approvals'

const getData = async ({ id }, dispatch) => {
  await dispatch(fetchApprovalsBySpacePlan(id)).unwrap()
}

const options = {
  Custom404: (
    <PageNotFound
      title="Approval Not Found"
      message="The Approval you are looking for cannot be found."
    />
  ),
  extractProps: ({ params, authData, children }) => ({ ...params, ...authData, children }),
  shouldRefresh: ({ id: previousId }, { id }) => previousId !== id,
}

export default loader(getData, options)(Approvals)
