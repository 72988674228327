import React from 'react'
import PropTypes from 'prop-types'

import { Card, Heading2, List, ListItem } from '@jsluna/react'

import sortArray from 'src/utils/sortArray'

import Section from 'src/components/Section'

const Profile = ({ self }) => {
  const { name, email, categories = [], role } = self
  const sortedCategories = sortArray(categories.slice(), 'isPriceLock', false, 'title')
  return (
    <Section>
      <Heading2>Profile</Heading2>
      <Card>
        <List type="bare" spaced>
          <ListItem type="bare">
            <strong className="ln-u-soft-right">Name</strong>
            <div>{name}</div>
          </ListItem>
          <ListItem type="bare">
            <strong className="ln-u-soft-right">Email</strong>
            <div>{email}</div>
          </ListItem>
          <ListItem type="bare">
            <strong className="ln-u-soft-right">Role</strong>
            <div>{role ? role.title : 'Unnassigned'}</div>
          </ListItem>
          <ListItem type="bare">
            <strong className="ln-u-soft-right">Categories</strong>
            {categories.length ? (
              <List type="bare" items={sortedCategories.map(c => c.title)} />
            ) : (
              <div>No categories assigned</div>
            )}
          </ListItem>
        </List>
      </Card>
    </Section>
  )
}

Profile.propTypes = {
  self: PropTypes.shape({
    name: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.shape({ role: PropTypes.string, title: PropTypes.string }),
    categories: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
  }),
}

Profile.defaultProps = {
  self: {},
}

export default Profile
