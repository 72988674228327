import { combineReducers } from 'redux'

import changeControlReducer from 'src/modules/ChangeControls/store'
import changeControlsDetailReducer from 'src/modules/ChangeControlsDetail/store'
import cyclesDetailReducer from 'src/modules/CyclesDetail/store'
import cyclesSpacesReducer from 'src/modules/CyclesDetail/Spaces/store'
import cyclesReducer from 'src/modules/Cycles/store'
import groupDetailReducer from 'src/modules/GroupsDetail/store'
import importsReducer from 'src/modules/Imports/store'
import inlineProductSearchReducer from 'src/modules/InlineProductSearch/store'
import masterPsaReducer from 'src/modules/MasterPsa/store'
import messagesAdminReducer from 'src/modules/MessagesAdmin/store'
import nominationsDetailReducer from 'src/modules/NominationsDetail/store'
import nominationsReducer from 'src/modules/Nominations/store'
import nominationsUploadReducer from 'src/modules/NominationsUpload/store'
import productSearchReducer from 'src/modules/ProductSearch/store'
import rangeAndSpaceReducer from 'src/modules/RangeAndSpace/store/index'
import shelfProductsDetailReducer from 'src/modules/ShelfProductsDetail/store'
import shipperComplianceReducer from 'src/modules/ShipperCompliance/store'
import spaceAssignmentReducer from 'src/modules/SpaceAssignment/store'
import spacePlannerReducer from 'src/modules/SpacePlanner/store'
import spacesDetailReducer from 'src/modules/SpacesDetail/store'
import spacesPlansDetailReducer from 'src/modules/SpacePlansDetail/store'
import spacesPlansReducer from 'src/modules/SpacePlans/store'
import spacesReducer from 'src/modules/Spaces/store'
import spaceTypesDetailReducer from 'src/modules/SpaceTypesDetail/store'
import spaceTypesReducer from 'src/modules/SpaceTypes/store'
import spaceGroupsDetailReducer from 'src/modules/SpaceGroupsDetail/store'
import spaceGroupsReducer from 'src/modules/SpaceGroups/store'
import sspsaTypesReducer from 'src/modules/Sspsa/store'
import storePsa from 'src/modules/StorePsa/store'
import storeSearch from 'src/modules/StoreSearch/store'
import unassignedReducer from 'src/modules/Unassigned/store'
import userDetailReducer from 'src/modules/UserDetail/store'
import usersReducer from 'src/modules/Users/store'
import userSelfReducer from 'src/modules/UserSelf/store'
import groupsReducer from 'src/modules/Groups/store'
import posAdviceReducer from 'src/modules/SpaceAssignment/POS/store'
import featureTogglesReducer from 'src/modules/FeatureToggles/store'
import categoryDetailsReducer from 'src/modules/CategoryDetails/store'

import categoriesReducer from './asyncThunks/categories'
import spaceDetailsReducer from './asyncThunks/spaceDetails'
import adminConfigMessagesReducer from './asyncThunks/adminConfigMessages'
import rolesReducer from './asyncThunks/roles'

export default combineReducers({
  ...changeControlReducer,
  ...changeControlsDetailReducer,
  ...cyclesDetailReducer,
  ...cyclesSpacesReducer,
  ...cyclesReducer,
  ...featureTogglesReducer,
  ...groupDetailReducer,
  ...importsReducer,
  ...inlineProductSearchReducer,
  ...masterPsaReducer,
  ...messagesAdminReducer,
  ...nominationsDetailReducer,
  ...nominationsReducer,
  ...nominationsUploadReducer,
  ...productSearchReducer,
  ...rangeAndSpaceReducer,
  ...shelfProductsDetailReducer,
  ...shipperComplianceReducer,
  ...spaceAssignmentReducer,
  ...spacePlannerReducer,
  ...spacesDetailReducer,
  ...spacesPlansDetailReducer,
  ...spacesPlansReducer,
  ...spacesReducer,
  ...spaceTypesDetailReducer,
  ...spaceTypesReducer,
  ...spaceGroupsDetailReducer,
  ...spaceGroupsReducer,
  ...sspsaTypesReducer,
  ...storePsa,
  ...storeSearch,
  ...unassignedReducer,
  ...userDetailReducer,
  ...usersReducer,
  ...userSelfReducer,
  ...groupsReducer,
  ...categoriesReducer,
  ...spaceDetailsReducer,
  ...adminConfigMessagesReducer,
  ...posAdviceReducer,
  ...rolesReducer,
  ...categoryDetailsReducer,
})
