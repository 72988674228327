import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import loader from 'src/hoc/loader'
import { selectSpace } from 'src/modules/SpaceAssignment/store/selectors'
import hfssValidations from './hfssValidations'
import NominationsUpload from './components'
import { selectProductImports, selectStatus } from './store'
import { importProducts, updateStatus, uploadProducts, actionGetData } from './store/actions'

const mapStateToProps = state => {
  const props = {
    products: selectProductImports(state),
    status: selectStatus(state),
    space: selectSpace(state),
    productHfssFlag: state.productHfssFlag,
  }
  hfssValidations(props)
  return props
}

const mapDispatchToProps = (
  dispatch,
  { instanceId, handleClose, shelves, isLockedForChangeControl }
) =>
  bindActionCreators(
    {
      importProducts: data => importProducts(data, instanceId, shelves, isLockedForChangeControl),
      updateStatus,
      uploadProducts: async () => {
        await dispatch(uploadProducts())
        handleClose()
      },
    },
    dispatch
  )

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(NominationsUpload)

const getData = actionGetData

const options = {
  shouldRefresh: (previous, current) => previous.instanceId !== current.instanceId,
}

export default loader(getData, options)(connectedComponent)
