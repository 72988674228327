export default function validate({ startDate, endDate }) {
  const errors = {}

  if (!endDate) {
    errors.endDate = 'End Date is required'
  }

  if (!startDate) {
    errors.startDate = 'Start Date is required'
  }

  if (endDate < startDate) {
    errors.endDate = 'End Date cannot be before the Start Date'
  }

  return errors
}
