import moment from 'moment'
import memoise from 'memoizee'

import { SPACE_ASSIGNMENTS_TYPE } from 'src/constants/types'
import {
  TOUCHPOINT_COMPLETED,
  TOUCHPOINT_NOT_REQUIRED,
  TOUCHPOINT_REQUIRED,
  TOUCHPOINT_STATUS_DUE,
  TOUCHPOINT_STATUS_COMPLETED,
  TOUCHPOINT_STATUS_PENDING,
  TOUCHPOINT_STATUS_OVERDUE,
  TOUCHPOINTS_LABELS,
  TOUCHPOINTS_WITH_OPTIONS,
} from 'src/constants/shipperCompliance'

import { DATE_FORMAT } from './datePeriod'

export const COMPLETED_STATES = [TOUCHPOINT_COMPLETED, TOUCHPOINT_REQUIRED, TOUCHPOINT_NOT_REQUIRED]

export const getLink = ({ id }) => `/${SPACE_ASSIGNMENTS_TYPE}/${id}`
export const getFormatDate = memoise(date => moment(date).format(DATE_FORMAT))

export const getTouchPointLabel = (touchpoint = '') => TOUCHPOINTS_LABELS[touchpoint] || ''
export const getOptions = tp => TOUCHPOINTS_WITH_OPTIONS[tp]

export const getShipperComplianceTouchPoint = shipperCompliance => touchPoint =>
  shipperCompliance[touchPoint]

export const shipperComplianceIsCompleted = ({ state }) => COMPLETED_STATES.includes(state)
export const shipperComplianceIsPending = ({ timelineStatus }) =>
  timelineStatus === TOUCHPOINT_STATUS_PENDING
export const shipperComplianceIsOverdue = ({ timelineStatus }) =>
  timelineStatus === TOUCHPOINT_STATUS_OVERDUE

export const getStatusColor = timeLineStatus => {
  const bgColor = {
    [TOUCHPOINT_STATUS_OVERDUE]: 'red',
    [TOUCHPOINT_STATUS_DUE]: 'yellow',
    [TOUCHPOINT_STATUS_COMPLETED]: 'green',
    [TOUCHPOINT_STATUS_PENDING]: 'light',
  }[timeLineStatus]

  const foreColor = {
    [TOUCHPOINT_STATUS_COMPLETED]: 'white',
    [TOUCHPOINT_STATUS_OVERDUE]: 'white',
  }[timeLineStatus]

  return {
    bgColor: bgColor ? `ln-u-bg-color-${bgColor}` : '',
    foreColor: foreColor ? `ln-u-color-${foreColor}` : '',
  }
}
