import Api from '../Base'

class PatchTouchPointByShipperComplianceId extends Api {
  requestUrl = `/api/Cycle/ShipperCompliance/${this.getParams().id}`

  method = 'PATCH'

  formatRequestData = params => params.touchPointDetail
}

export default PatchTouchPointByShipperComplianceId
