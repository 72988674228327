import { PatchMoveShelf } from 'src/api/Shelf'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleCreateAsyncThunkResult } from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'MoveShelf'
const buildThunkPrefix = (slice, thunkName) => `${slice}/${thunkName}`

const actionUpdateMoveShelf = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'updateMoveShelf'),
  async ({ spaceInstanceId, newOrderShelveIds }, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const patchMoveShelfInstance = new PatchMoveShelf(store, {
      params: { spaceInstanceId, newOrderShelveIds },
    })
    const response = await handleCreateAsyncThunkResult(
      patchMoveShelfInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

// eslint-disable-next-line import/prefer-default-export
export { actionUpdateMoveShelf }
