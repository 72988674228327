import React from 'react'
import PropTypes from 'prop-types'
import { Card, Heading4, Section } from '@jsluna/react'
import Shelves from '../Shelves'

export const SpaceInstance = ({
  changeControl,
  instanceTitle,
  spaceInstance: { shelves },
  layoutType,
}) => {
  return shelves.length > 0 ? (
    <div data-control={`space-instance-${instanceTitle}`}>
      <Section>
        <Card>
          <Heading4>{instanceTitle}</Heading4>
          <Shelves changeControl={changeControl} shelves={shelves} layoutType={layoutType} />
        </Card>
      </Section>
    </div>
  ) : null
}

SpaceInstance.propTypes = {
  changeControl: PropTypes.shape({}).isRequired,
  instanceTitle: PropTypes.string.isRequired,
  shelves: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  space: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  spaceInstance: PropTypes.shape({
    shelves: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  layoutType: PropTypes.string.isRequired,
}

export default SpaceInstance
