import { buildCategoryOptions } from 'src/utils/buildCategoryOptions'
import { selectCategories } from 'src/store/modules/asyncThunks/categories'
import { selectUi } from '../store'

const mapStateToProps = state => {
  const categoryOptions = buildCategoryOptions([...selectCategories(state)])
  const initialCategories = categoryOptions.map(({ id }) => id)
  const {
    filter: { categories: filterCategories },
  } = selectUi(state)

  const categories = filterCategories || initialCategories

  return {
    categoryOptions,
    categories,
  }
}

export default mapStateToProps
