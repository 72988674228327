import { Checkbox as LunaCheckbox } from '@jsluna/react'
import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({ field: { name, value, ...field }, form: { errors, touched }, ...props }) => {
  const error = touched[name] && errors[name]

  return (
    <div className={classNames('ln-c-form-group', { 'has-error': !!error })}>
      <LunaCheckbox
        {...field}
        {...props}
        checked={value === true || value === 'true'}
        name={name}
      />
      {error && (
        <div className="ln-c-field-info ln-c-field-info--error" role="alert">
          {error}
        </div>
      )}
    </div>
  )
}

Checkbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
}

export default Checkbox
