import loader from 'src/hoc/loader'
import { actionGetData, resetSpaceMultiselect } from './store/actions'
import { actionClearData } from './store'
import ShipperCompliance from './ShipperCompliance'

const options = {
  shouldRefresh: (previous, current) => previous.cycleId !== current.cycleId,
  onUnmount: dispatch => {
    dispatch(actionClearData())
    if (!window.location.pathname.includes('/space-assignments/')) {
      dispatch(resetSpaceMultiselect())
    }
  },
}

export default loader(actionGetData, options)(ShipperCompliance)
