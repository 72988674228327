import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleCreateAsyncThunkResult } from 'src/utils/createAsyncThunkHandler'
import { MoveShelfProduct } from 'src/api/ShelfProduct'

const NAMESPACE = 'MoveShelf'
const buildThunkPrefix = (slice, thunkName) => `${slice}/${thunkName}`

const actionUpdateMoveShelfProduct = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'updateMoveShelfProduct'),
  async ({ id: shelfProductId, shelfProduct }, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const patchMoveShelfProductInstance = new MoveShelfProduct(store, {
      params: { shelfProductId, shelfProduct },
    })
    const response = await handleCreateAsyncThunkResult(
      patchMoveShelfProductInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

// eslint-disable-next-line import/prefer-default-export
export { actionUpdateMoveShelfProduct }
