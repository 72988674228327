import { connect } from 'react-redux'
import { withFormik } from 'formik'

import { saveCategoryFilter } from '../store/actions'
import mapStateToProps from './mapStateToProps'
import CategoryFilter from './CategoryFilter'

const mapDispatchToProps = (dispatch, { handleClose, handleCloseParent }) => ({
  submit: categories => {
    dispatch(saveCategoryFilter({ categories }))

    handleClose()
    handleCloseParent()
  },
})

const wrappedForm = withFormik({
  handleSubmit: ({ categories }, { props: { submit } }) => {
    submit(categories)
  },
})(CategoryFilter)

export default connect(mapStateToProps, mapDispatchToProps)(wrappedForm)
