import React from 'react'
import { ArrowRight } from '@jsluna/icons'

const StatusCardChevron = () => (
  <div className="ln-c-status-card__chevron">
    <ArrowRight />
  </div>
)

export default StatusCardChevron
