import React from 'react'
import { Delete } from '@jsluna/icons'
import PropTypes from 'prop-types'

import { Button } from '@jsluna/react'

import withConfirmation from 'src/hoc/withConfirmation'

const DeleteButton = withConfirmation(Button)

const ProductCell = ({ product: { description, sku }, onDelete }) => {
  return (
    sku && (
      <div className="c-product-cell">
        <p className="c-product-cell__name ln-u-flush-bottom" title={`(${sku}) ${description}`}>
          {sku} - {description}
        </p>
        <div className="c-product-cell__controls">
          <DeleteButton
            className="c-icon-button u-bg-none"
            data-control="delete-frozen"
            message={`Please confirm that you want to delete ${description} from this layout`}
            onClick={onDelete}
            title="Are you sure you want to delete this?"
          >
            <Delete />
          </DeleteButton>
        </div>
      </div>
    )
  )
}

ProductCell.propTypes = {
  product: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default ProductCell
