import { connect } from 'react-redux'
import {
  getDate,
  getPreviousWeeksAndDays,
  getWeekDay,
  getDateOfPreviousWeekDay,
  getDateOfCurrentWeekDay,
} from 'src/utils/dateUtils'

import moment from 'moment'
import Form from './Form'
import {
  selectPagination,
  actionGetData,
  selectStorePsaGroups,
  fetchExportsStoreSpecificPsa,
  updateStorePsaStartDate,
  updateStorePsaEndDate,
  selectStorePsaStartDate,
  selectStorePsaEndDate,
} from '../store'

const dayOftheWeek = 3

const getNextWednesday = cycleStartDate => {
  if (getWeekDay(cycleStartDate) < dayOftheWeek) {
    return getDateOfPreviousWeekDay(cycleStartDate, 1, 'weeks', dayOftheWeek)
  }
  return getDateOfCurrentWeekDay(cycleStartDate, dayOftheWeek)
}

export const getCycleStartDate = cycleStartDate => {
  const currentDay = getWeekDay(cycleStartDate) - dayOftheWeek

  if (getWeekDay(cycleStartDate) === dayOftheWeek) {
    return getDate(cycleStartDate)
  }
  if (getWeekDay(cycleStartDate) < dayOftheWeek) {
    return getPreviousWeeksAndDays(cycleStartDate, 1, 'week')
  }
  return getPreviousWeeksAndDays(cycleStartDate, currentDay, 'days')
}

export const getCycleEndDate = cycleEndDate => {
  return getDate(cycleEndDate)
}

export const mapStateToProps = (state, { storeId, location, storePSAFlag, cycleStartDate }) => {
  const nextWednesday = getNextWednesday(cycleStartDate)
  return {
    storeId,
    groups: selectStorePsaGroups(state),
    pagination: selectPagination(state),
    isFull: location !== undefined && location.query.full !== undefined,
    storePSAFlag,
    startDate: nextWednesday,
    selectedStartDate: selectStorePsaStartDate(state),
    selectedEndDate: selectStorePsaEndDate(state),
  }
}

export const mapDispatchToProps = (dispatch, { cycleId, storeId, businessUnit }) => ({
  getPage: number => dispatch(actionGetData({ cycleId, storeId, number, businessUnit })),
  downloadPsa: (startDate, endDate) =>
    dispatch(fetchExportsStoreSpecificPsa({ cycleId, storeId, businessUnit, startDate, endDate })),
  onChangeDate: dates => {
    const startDate = moment(dates.startDate).format('YYYY-MM-DD')
    const endDate = moment(dates.endDate).format('YYYY-MM-DD')
    dispatch(updateStorePsaStartDate(startDate))
    dispatch(updateStorePsaEndDate(endDate))
    dispatch(actionGetData({ cycleId, storeId, startDate, endDate, businessUnit }))
    window.history.pushState(null, null, `#${1}`)
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
