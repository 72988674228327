/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import PropTypes from 'prop-types'
import Cell from './Cell'

function Head({ type, columns }) {
  return (
    <thead className="ln-c-table__header" aria-label="Sort by">
      <tr className="ln-c-table__header-row">
        {columns.map(({ key, name }) => {
          if (type === 'link') {
            return <th key={key} />
          }

          return (
            <Cell key={key} sortKey={key} type={type}>
              {name}
            </Cell>
          )
        })}
      </tr>
    </thead>
  )
}

Head.propTypes = {
  type: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default Head
