import { connect } from 'react-redux'

import { selectSelf, selectSelfRoleId } from 'src/store/data/selectors'

import Header from './Header'
import allMenuItems from './allMenuItems'
import buildMenu from './buildMenu'

const mapStateToProps = state => {
  const user = selectSelf(state) || {}

  return {
    menu: buildMenu(allMenuItems, user, window?.PROMCOMM),
    role: user.role && user.role.title,
  }
}

const options = {
  areStatesEqual: (next, prev) => selectSelfRoleId(next) === selectSelfRoleId(prev),
}

export default connect(mapStateToProps, null, null, options)(Header)
