import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { GridWrapper, GridItem } from '@jsluna/react'

import sortNatural from 'src/utils/sortNatural'

import Checkbox from './Checkbox'

const breakPoints = {
  xs: '1/2',
  md: '1/3',
}

const CheckboxGroup = ({ input, options, meta, disabled: allDisabled }) => {
  const { name, onChange, value: inputValue } = input
  const hasError = m => m.touched && m.error

  const groups = Object.values(
    options.reduce((out, option) => {
      const { group } = option

      if (!out[group]) {
        // eslint-disable-next-line no-param-reassign
        out[group] = { title: group, entries: [] }
      }

      out[group].entries.push(option)
      return out
    }, {})
  )
  const sortedGroups = sortNatural(groups, g => g.title)

  return (
    <div className={classNames('ln-c-form-group', { 'has-error': hasError(meta) })}>
      {hasError(meta) && <p className="ln-c-field-info ln-c-field-info--error">{meta.error}</p>}
      {sortedGroups.map(({ title, entries }) => (
        <div
          key={`group-${title}`}
          data-control={`checkbox-group-${title}`}
          className="ln-u-push-bottom"
        >
          {title && <legend className="ln-c-label ln-u-h5">{title}</legend>}
          <GridWrapper gutterSize="sm">
            {entries.map(({ label, value, id, hidden = false, disabled }) => {
              const handleChange = event => {
                const arr = [...inputValue]
                if (event.target.checked) {
                  arr.push(value)
                } else {
                  arr.splice(arr.indexOf(value), 1)
                }
                return onChange(arr)
              }
              const checked = inputValue.includes(value)
              const cbId = `${name}[${id}]`

              return (
                <GridItem
                  size={breakPoints}
                  className={classNames({ 'ln-u-hidden': hidden })}
                  key={`checkbox-${id}`}
                >
                  <Checkbox
                    id={cbId}
                    input={{ name: cbId, onChange: handleChange }}
                    label={label}
                    checked={checked}
                    value={value}
                    disabled={allDisabled || disabled}
                  />
                </GridItem>
              )
            })}
          </GridWrapper>
        </div>
      ))}
    </div>
  )
}

CheckboxGroup.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      hidden: PropTypes.bool,
      group: PropTypes.string,
    })
  ).isRequired,
  meta: PropTypes.shape({ error: PropTypes.string }),
  disabled: PropTypes.bool,
}

CheckboxGroup.defaultProps = {
  meta: { error: '' },
  disabled: false,
}

export default CheckboxGroup
