import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Field, withFormik } from 'formik'
import { DateField } from 'src/components/FormikComponents'
import { Controls } from 'src/components/Form'

import { TextInputField } from '@jsluna/form/extensions/formik'
import { Form, GridWrapper, GridItem } from '@jsluna/react'
import { checkLayoutTypes, isClipStrip, isPallet, isShipper, isStack } from 'src/utils/layoutTypes'
import { hasRequiredRole } from 'src/utils/permissions'
import { PERM_ROLE_ID, COMMERCIAL_OPS_SUPER_USER_ID } from 'src/constants/roles'
import { CHANGE_CONTROL_UNLOCKED_ID } from 'src/constants/cycles'
import validate from './validate'
import handleSubmit from './handleSubmit'

const SpaceAssignmentClusterForm = ({
  handleSubmit: onSubmit,
  isReadOnly,
  disabled,
  user,
  layoutType,
  changeControlStatus,
  hasProducts,
}) => {
  const showShipperDescription = checkLayoutTypes([isPallet, isShipper, isStack])
  const showSuggestedLocation = checkLayoutTypes([isClipStrip, isPallet, isStack])
  const hasPermission =
    hasRequiredRole(user, PERM_ROLE_ID) || hasRequiredRole(user, COMMERCIAL_OPS_SUPER_USER_ID)
  const showOverrideDates =
    hasProducts && hasPermission && changeControlStatus === CHANGE_CONTROL_UNLOCKED_ID
  const breakpoints = { sm: '1/1', md: '1/3' }

  return (
    <Form onSubmit={onSubmit}>
      <div className={classNames('ln-c-fieldset', isReadOnly ? '' : 'ln-c-form-group')}>
        <GridWrapper>
          <GridItem size={breakpoints}>
            <Field
              label="Comment"
              name="comment"
              component={TextInputField}
              disabled={disabled.comment}
            />
          </GridItem>
          <GridItem size={breakpoints}>
            <Field
              label="POS Comment"
              name="posComment"
              component={TextInputField}
              disabled={disabled.posComment}
            />
          </GridItem>
          {showSuggestedLocation(layoutType) ? (
            <GridItem size={breakpoints}>
              <Field
                label="Suggested Location"
                name="suggestedLocation"
                component={TextInputField}
              />
            </GridItem>
          ) : (
            <GridItem size={breakpoints}>
              <Field
                label="Shelf Heights"
                name="shelfHeights"
                component={TextInputField}
                disabled={disabled.shelfHeights}
              />
            </GridItem>
          )}
          {showShipperDescription(layoutType) && (
            <GridItem size={breakpoints}>
              <Field
                label="Shipper Description"
                name="shipperDescription"
                component={TextInputField}
                disabled={disabled.shipperDescription}
              />
            </GridItem>
          )}
          {showOverrideDates && (
            <>
              <GridItem size="1/3@sm">
                <DateField label="Override Start Date" name="overrideStartDate" />
              </GridItem>
              <GridItem size="1/3@sm">
                <DateField label="Override End Date" name="overrideEndDate" />
              </GridItem>
            </>
          )}
        </GridWrapper>
        <Controls />
      </div>
    </Form>
  )
}

SpaceAssignmentClusterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  layoutType: PropTypes.string.isRequired,
  changeControlStatus: PropTypes.string.isRequired,
  hasProducts: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool,
  user: PropTypes.shape({}).isRequired,
  disabled: PropTypes.shape({
    comment: PropTypes.bool,
    posComment: PropTypes.bool,
    shipperDescription: PropTypes.bool,
    shelfHeights: PropTypes.bool,
  }).isRequired,
}

SpaceAssignmentClusterForm.defaultProps = {
  isReadOnly: false,
}

const wrappedForm = withFormik({ handleSubmit, validate })(SpaceAssignmentClusterForm)

export default wrappedForm
