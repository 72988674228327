import React from 'react'

import Timeline from 'src/components/Timeline'

import PropTypes from 'prop-types'
import { Card } from '@jsluna/react'

import buildTracks from './buildTracks'

const SpacePlannerTimeline = ({
  zoomIn,
  zoomOut,
  toggleOpen,
  toggleTrackOpen,
  redirect,
  timeline,
  startDate,
  endDate,
  returnTo,
}) => {
  const { tracks: timelineTracks } = timeline
  const tracks = buildTracks({
    tracks: timelineTracks,
    startDate,
    endDate,
    returnTo,
  })
  const clickElement = ({ link }) => link && redirect(link)
  return tracks && tracks.length ? (
    <Timeline
      key={tracks.id}
      {...timeline}
      tracks={tracks}
      clickElement={clickElement}
      zoomIn={zoomIn}
      zoomOut={zoomOut}
      toggleOpen={toggleOpen}
      toggleTrackOpen={toggleTrackOpen}
      enableSticky
    />
  ) : (
    <Card>No spaces found matching filter settings</Card>
  )
}

SpacePlannerTimeline.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  zoomIn: PropTypes.func.isRequired,
  zoomOut: PropTypes.func.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  toggleTrackOpen: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  timeline: PropTypes.shape({
    zoom: PropTypes.number.isRequired,
    isOpen: PropTypes.bool.isRequired,
    tracks: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  returnTo: PropTypes.string.isRequired,
}

export default SpacePlannerTimeline
