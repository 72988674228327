import React from 'react'
import PropTypes from 'prop-types'
import { hfssStatus } from 'src/functions/productHfssStatus'

import { Table } from '@jsluna/react'
import buildShelfTitle from 'src/utils/shelfTitle'
import { isNumber } from 'src/utils/validation'

const previewColumns = [
  {
    name: 'Shelf',
    accessor: ({ shelf }) => ({ value: isNumber(shelf) && buildShelfTitle(shelf - 1) }),
  },
  { name: 'SKU', accessor: 'sku' },
  { name: 'Description', accessor: 'description' },
  { name: 'HFSS', accessor: rowData => ({ value: hfssStatus(rowData.hfss) }) },
  { name: 'Comment', accessor: 'comment' },
  { name: 'Promo Mechanic', accessor: 'promoMechanic' },

  {
    name: 'Errors',
    accessor: ({ errors, status = '' }) => ({
      value: errors && errors.length > 0 ? errors.join(', ') : status,
    }),
  },
]

const ProductsList = ({ products = [] }) =>
  products.length > 0 && (
    <div className="ln-u-push-bottom">
      <Table columns={previewColumns} data={products} />
    </div>
  )

ProductsList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      shelf: PropTypes.string,
      sku: PropTypes.string,
      description: PropTypes.string,
      comment: PropTypes.string,
      hfss: PropTypes.string,
      promoMechanicValue: PropTypes.string,
      valid: PropTypes.bool,
      errors: PropTypes.arrayOf(PropTypes.string),
      status: PropTypes.string,
    })
  ).isRequired,
}

export default ProductsList
