import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { StatusCard } from '@jsluna/react'

import { CHANGE_CONTROL_ALL_IDS, CHANGE_CONTROL_LOCKED_ID } from 'src/constants/cycles'

import { selectSpaceDetails } from 'src/store/modules/asyncThunks/spaceDetails'
import { selectSpaceAssignment } from './store/reducer/spacePlanAssignment'
import Header from './Header'
import Details from './Details'
import Clusters from './Clusters'
import ChangeControl from './ChangeControl'

const SpaceAssignmentsDetail = ({
  user,
  id,
  assignment,
  query,
  space,
  changeControlStatus,
  pendingChangeControl,
  resolutionComment,
  resolutionName,
  viewChangeControl,
}) => (
  <div>
    {changeControlStatus === CHANGE_CONTROL_LOCKED_ID && resolutionComment && (
      <StatusCard
        className="ln-u-push-bottom"
        status="warning"
        data-control="amendments-requested-status-card"
      >
        <div className="ln-u-display-2 ln-u-push-bottom">AMENDMENTS REQUESTED</div>
        <div className="ln-u-display-1">
          Amendments were requested by {resolutionName} with the following comment:
          <blockquote>{resolutionComment}</blockquote>
        </div>
      </StatusCard>
    )}
    {changeControlStatus === CHANGE_CONTROL_LOCKED_ID && (
      <StatusCard
        className="ln-u-push-bottom"
        status="danger"
        data-control="cycle-locked-status-card"
      >
        <div className="ln-u-display-2 ln-u-push-bottom">THE CYCLE IS LOCKED</div>
        <div className="ln-u-display-1">
          Please follow the change control process. For food queries please contact
          promotional.cycles@sainsburys.co.uk and for non-food please contact
          nf.promocycles@sainsburys.co.uk
        </div>
      </StatusCard>
    )}
    <Header id={id} returnTo={query.rt} space={space} />
    <Details user={user} id={id} assignment={assignment} query={query} />
    {changeControlStatus === CHANGE_CONTROL_LOCKED_ID && (
      <ChangeControl
        className="ln-u-push-bottom"
        assignment={assignment}
        changeControl={pendingChangeControl || viewChangeControl}
      />
    )}
    {id && <Clusters id={id} />}
  </div>
)

SpaceAssignmentsDetail.propTypes = {
  user: PropTypes.shape({}).isRequired,
  id: PropTypes.string,
  assignment: PropTypes.shape({}),
  space: PropTypes.shape({
    title: PropTypes.string,
    spaceTitle: PropTypes.string,
  }).isRequired,
  query: PropTypes.shape({
    rt: PropTypes.string,
  }),
  changeControlStatus: PropTypes.oneOf(CHANGE_CONTROL_ALL_IDS),
  pendingChangeControl: PropTypes.shape({}),
  resolutionComment: PropTypes.string,
  resolutionName: PropTypes.string,
  viewChangeControl: PropTypes.shape({}),
}

SpaceAssignmentsDetail.defaultProps = {
  id: null,
  assignment: {},
  query: {},
  pendingChangeControl: null,
  viewChangeControl: null,
  changeControlStatus: null,
  resolutionComment: '',
  resolutionName: '',
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps.id) {
    const assignment = selectSpaceAssignment(state)
    const { changeControlStatus, pendingChangeControl, viewChangeControl } = assignment

    const resolutionComment = pendingChangeControl?.resolutionComment
    const resolutionName = pendingChangeControl?.resolvedByName

    return {
      assignment,
      changeControlStatus,
      pendingChangeControl,
      resolutionComment,
      resolutionName,
      space: assignment,
      viewChangeControl,
    }
  }
  return {
    space: selectSpaceDetails(state),
  }
}

export default connect(mapStateToProps)(SpaceAssignmentsDetail)
