import React from 'react'
import PropTypes from 'prop-types'

import { Heading5 } from '@jsluna/react'

import sortArray from 'src/utils/sortArray'

import Tags, { toTag } from 'src/components/Tags'

const ReadOnlyCategories = ({ categories }) => (
  <div>
    <Heading5>Assigned Categories</Heading5>
    <Tags items={sortArray([...categories], 'isPriceLock', false, 'title').map(toTag)} />
  </div>
)

ReadOnlyCategories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      theme: PropTypes.string.isRequired,
      active: PropTypes.bool,
    })
  ).isRequired,
}

export default ReadOnlyCategories
