import React from 'react'
import PropTypes from 'prop-types'
import Cell from 'src/components/RowCell'
import ChangeTypeBadge from 'src/components/ChangeTypeBadge'
import { hasChange } from 'src/utils/changeControls'

const ShelfProduct = ({ changeControl, shelf, shelfItem }) => {
  const { id: shelfId, title } = shelf
  const {
    changeType: productChangeType,
    differences: productDifferences,
    id: productId,
    sku: productSku,
    description: productDescription,
    hfss: hfssIndicator,
  } = shelfItem
  return (
    <tr
      id={`shelf-assigned-${productSku}`}
      className="ln-c-table__row"
      key={`${shelfId}-${productId}`}
      data-control={hasChange(changeControl)(shelfItem) ? 'changed-product-row' : 'product-row'}
    >
      <Cell label="Shelf">{title}</Cell>
      <Cell label="Change Type">
        {hasChange(changeControl)(shelfItem) && (
          <ChangeTypeBadge id={productId} type={productChangeType} />
        )}
      </Cell>
      <Cell label="SKU">{productSku}</Cell>
      <Cell label="Description">{productDescription}</Cell>
      <Cell label="HFSS" className="c-hfss">
        {hfssIndicator}
      </Cell>
      <Cell columnSpan={3}>
        <table className="ln-c-table ln-c-table--fixed ln-u-text-align-center">
          <tbody className="ln-c-table__body">
            {productDifferences.length > 0 ? (
              productDifferences.map(difference => {
                const { afterValue, beforeValue, id, label } = difference
                return (
                  <tr
                    key={`change-${id}-${shelfId}-${productId}`}
                    data-control={`changed-field-${id}-${productSku}`}
                  >
                    <Cell label="Changed Fields">{label}</Cell>
                    <Cell label="Old Value">{beforeValue}</Cell>
                    <Cell label="New Value">{afterValue}</Cell>
                  </tr>
                )
              })
            ) : (
              <tr key={`${shelfId}-${productId}`} data-control={`changed-field-na-${productSku}`}>
                <Cell label="Changed Fields">N/A</Cell>
                <Cell label="Old Value">N/A</Cell>
                <Cell label="New Value">N/A</Cell>
              </tr>
            )}
          </tbody>
        </table>
      </Cell>
    </tr>
  )
}

ShelfProduct.propTypes = {
  changeControl: PropTypes.shape({}).isRequired,
  shelf: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isRsb: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  shelfItem: PropTypes.shape({
    changeType: PropTypes.string,
    differences: PropTypes.arrayOf(PropTypes.shape({})),
    id: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    description: PropTypes.string,
    hfss: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
}
ShelfProduct.defaultProps = {}

export default ShelfProduct
