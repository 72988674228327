import { logError } from 'src/utils/log'

import { API_SUCCESS, API_FAILURE } from 'src/constants/api'

const onSuccess = (next, generatePayload) => response => {
  const payload = generatePayload({ response })
  next({ type: API_SUCCESS, payload })

  return Promise.resolve(response)
}

const onError = (next, generatePayload, { debug, ignoreNotFound } = {}) => error => {
  const message = error ? error.message : 'There was an error'
  const status = error && error.status

  if (ignoreNotFound && status === '404') {
    return Promise.reject(Error('Resource not found.'))
  }

  if (debug) {
    logError(error)
  }

  next({
    type: API_FAILURE,
    error: true,
    meta: { notification: true },
    payload: generatePayload({ message, status }),
  })

  return Promise.reject(error || message)
}

export { onSuccess, onError }
