import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'

import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import { GetChangeControlReasons } from 'src/api/ChangeControl'

const NAMESPACE = 'ChangeControlReasons'
const buildThunkPrefix = (slice, thunkName) => `${slice}/${thunkName}`

const selectSelf = state => state.modules[NAMESPACE]
const selectChangeControlReasons = createSelector(selectSelf, module => module?.data || [])

const fetchChangeControlReasons = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchChangeControlReasons'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getChangeControlReasonsInstance = new GetChangeControlReasons(store)

    const response = await handleCreateAsyncThunkResult(
      getChangeControlReasonsInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchChangeControlReasonsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchChangeControlReasons.pending, pendingDataHandler)
    builder.addCase(fetchChangeControlReasons.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchChangeControlReasons.rejected, rejectedDataHandler)
  },
})

export default { [NAMESPACE]: fetchChangeControlReasonsSlice.reducer }
export { fetchChangeControlReasons, selectChangeControlReasons }
