import PropTypes from 'prop-types'
import React from 'react'
import { FlagWrapper, FlagBody, FlagComponent, Heading5 } from '@jsluna/react'

const AccordionTitle = ({ title, description }) => (
  <FlagWrapper>
    <FlagBody>
      <Heading5 className="ln-u-flush-bottom">{title}</Heading5>
    </FlagBody>
    <FlagComponent className="ln-u-soft-right" nowrap>
      {description}
    </FlagComponent>
  </FlagWrapper>
)

AccordionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
}

AccordionTitle.defaultProps = {
  description: '',
}

export default AccordionTitle
