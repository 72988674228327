import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { UPDATE_GROUPS } from 'src/constants/permissions'
import { hasRequiredPermission } from 'src/utils/permissions'

import {
  actionCreateGroup,
  actionUpdateGroup,
  selectGroup,
  selectSpaces,
  selectSpacePlans,
  fetchSpaces,
} from '../store'
import Form from './Form'

export const mapStateToProps = (state, { user = {} }) => {
  const allSpaces = selectSpaces(state) || []
  const allSpacePlans = selectSpacePlans(state) || []
  const selectedGroup = selectGroup(state) || {}
  const isReadOnly = !hasRequiredPermission(user, UPDATE_GROUPS)
  const isNew = !selectedGroup?.id
  const group = {
    id: isNew ? null : selectedGroup.id,
    title: isNew ? '' : selectedGroup.title,
    groupSpaceIds: isNew ? [] : selectedGroup.spaces?.map(item => item.id),
    selectedSpaces: isNew ? [] : selectedGroup.spaces,
    spacePlanId: isNew ? '' : selectedGroup.spacePlan?.id,
  }
  return {
    group,
    allSpaceIds: [...allSpaces],
    spacePlans: [...allSpacePlans],
    isReadOnly,
  }
}

const returnAction = () => push('/groups')
const mapDispatchToProps = dispatch => ({
  submit: async group => {
    const action = group.id ? actionUpdateGroup(group) : actionCreateGroup(group)
    await dispatch(action)
    dispatch(returnAction())
  },
  onChange: async (spacePlanId, groupId) => {
    await dispatch(fetchSpaces({ spacePlanId, groupId }))
  },
  cancel: () => dispatch(returnAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
