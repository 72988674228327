import React from 'react'

import loader from 'src/hoc/loader'
import NotFound from 'src/components/Errors/NotFound'
import { fetchCycleById, fetchGroupTitles } from './store'
import CyclesDetail from './CyclesDetail'

const getData = async ({ id }, dispatch) => {
  if (id) {
    await dispatch(fetchCycleById(id)).unwrap()
  } else {
    await dispatch(fetchGroupTitles())
  }
}

const options = {
  Custom404: (
    <NotFound title="Cycle Not Found" message="The cycle you are looking for cannot be found." />
  ),
  extractProps: ({ params, authData, children }) => ({ ...params, ...authData, children }),
  shouldRefresh: ({ id: previousId }, { id }) => previousId !== id,
}

export default loader(getData, options)(CyclesDetail)
