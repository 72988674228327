import { CLOSE_QUICK_ADD_PRODUCT, QUICK_ADD_PRODUCT } from '../actions'

const reducer = (state = '', { type, payload } = {}) => {
  if (type === QUICK_ADD_PRODUCT) return payload

  if (type === CLOSE_QUICK_ADD_PRODUCT) return ''

  return state
}

export default reducer
