const isInputDisabled = (disabled, disableUpdate) => name => {
  const fieldDisabled = disabled[name]

  if (disableUpdate) {
    return true
  }

  return fieldDisabled
}

export default isInputDisabled
