import React from 'react'
import PropTypes from 'prop-types'

import Tags, { toTag } from 'src/components/Tags'

const Categories = ({ categories }) => (
  <div>
    <Tags items={categories.map(toTag)} />
  </div>
)

Categories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      theme: PropTypes.string.isRequired,
      active: PropTypes.bool,
    })
  ).isRequired,
}

export default Categories
