const buildClusterTracks = ({ clusterSpaceAssignmentsDetails = [], categoriesById }) => {
  const clustersOut = {}

  clusterSpaceAssignmentsDetails.forEach(clusterDetail => {
    const { id, title, spaceAssignmentDetails } = clusterDetail
    spaceAssignmentDetails?.forEach(assignment => {
      const { spaceInstancesIds, startDate, endDate, categories = [], shelfCoverage } = assignment

      spaceInstancesIds.forEach(spaceInstance => {
        if (!clustersOut[id]) {
          clustersOut[id] = {
            id: `clusters-${id}`,
            title,
            assignments: [],
          }
        }

        const categoriesWithSelected = categories.map(c => categoriesById[c.id])
        clustersOut[id].assignments.push({
          id: spaceInstance,
          assignmentId: assignment.id,
          categories: categoriesWithSelected,
          startDate,
          endDate,
          shelfCoverage,
        })
      })
    })
  })
  return Object.values(clustersOut)
}

export default buildClusterTracks
