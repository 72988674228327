import React from 'react'
import PropTypes from 'prop-types'
import { Field, withFormik } from 'formik'
import {
  Heading2,
  FilledButton,
  Form,
  FormGroup,
  GridWrapper,
  GridItem,
  List,
  ListItem,
} from '@jsluna/react'
import { TextInputField } from '@jsluna/form/extensions/formik'

import handleSubmit from './handleSubmit'
import validate from './validate'

const FormComponent = ({ handleSubmit: onSubmit, onClose, setFieldValue }) => (
  <Form onSubmit={onSubmit}>
    <Heading2>
      <span data-control="pos-title">Create Point Of Sale</span>
    </Heading2>
    <FormGroup name="pos-field">
      <GridWrapper matrix>
        <GridItem size="1/1" className="ln-u-hard-bottom">
          <Field
            component={TextInputField}
            data-control="description"
            label="Description"
            name="description"
            validationFirst={false}
            placeholder="Please add a description"
            autoFocus
          />
        </GridItem>
      </GridWrapper>
    </FormGroup>
    <List type="matrix">
      <ListItem type="matrix">
        <FilledButton
          data-control="Save"
          name="Save"
          value="Save"
          type="submit"
          onClick={() => {
            setFieldValue('isSubmitting', true)
          }}
        >
          Submit
        </FilledButton>
      </ListItem>
      <ListItem type="matrix">
        <FilledButton color="dark" data-control="cancel" onClick={onClose}>
          <span>Cancel</span>
        </FilledButton>
      </ListItem>
    </List>
  </Form>
)

FormComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({ description: PropTypes.string }),
}

FormComponent.defaultProps = {
  values: {},
}

const wrappedForm = withFormik({ validate, handleSubmit })(FormComponent)

export default wrappedForm
