import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { zoomIn, zoomOut, toggleOpen, toggleTrackOpen } from '../store/actions'
import Timeline from './Timeline'

const mapDispatchToProps = {
  zoomIn,
  zoomOut,
  toggleOpen,
  toggleTrackOpen,
  redirect: push,
}

export default connect(null, mapDispatchToProps)(Timeline)
