import { connect } from 'react-redux'

import loader from 'src/hoc/loader'

import { buildCategoryOptions } from 'src/utils/buildCategoryOptions'
import { fetchCategories, selectCategories } from 'src/store/modules/asyncThunks/categories'
import CategoriesSelect from './CategoriesSelect'

const getData = async (props, dispatch) => {
  await dispatch(fetchCategories())
}

const mapStateToProps = state => ({
  categories: buildCategoryOptions([...selectCategories(state)]),
})

const ConnectedComponent = connect(mapStateToProps)(CategoriesSelect)

export default loader(getData)(ConnectedComponent)
