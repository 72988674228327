import React from 'react'
import { Card, Heading3 } from '@jsluna/react'

import StoreSearch from 'src/modules/StoreSearch'

const StoreSearchComponent = () => (
  <Card>
    <Heading3>Store search</Heading3>
    <StoreSearch route="range-and-space" useStoreId />
  </Card>
)

export default StoreSearchComponent
