import { SORT_TABLE } from './actions'

const reducer = (state = {}, { type, payload = {} }) => {
  switch (type) {
    case SORT_TABLE: {
      const { key, ascending = false, table } = payload
      return {
        ...state,
        [table]: { key, ascending },
      }
    }
    default:
      return state
  }
}

export default reducer
