const validate = values => {
  const { title, businessUnit, spaceType, spaceGroup } = values

  const errors = {}

  if (!title) {
    errors.title = 'Title cannot be empty'
  }

  if (!businessUnit) {
    errors.businessUnit = 'A business unit must be selected'
  }

  if (!spaceType) {
    errors.spaceType = 'A space type must be selected'
  }

  if (!spaceGroup) {
    errors.spaceGroup = 'A space group must be selected'
  }

  return errors
}

export default validate
