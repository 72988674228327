import React from 'react'
import PropTypes from 'prop-types'

import EquipmentSeparatedLayout from './EquipmentSeparatedLayout'
import { buildFrozenEquipment, sortLayouts } from './buildEquipment'

const FrozenLayout = props => {
  const { layouts, rows, layoutType, spaceTitle, storePSAFlag } = props
  const sortedLayouts = sortLayouts([...layouts])
  const equipments = buildFrozenEquipment(sortedLayouts, layoutType)

  return (
    <>
      {equipments.map(equipment => {
        const { name: equipmentName } = equipment
        return (
          <div className="ln-u-push-top" key={`${spaceTitle}-${equipmentName}`}>
            <EquipmentSeparatedLayout
              equipment={equipment}
              rows={rows}
              spaceTitle={spaceTitle}
              storePSAFlag={storePSAFlag}
            />
          </div>
        )
      })}
    </>
  )
}

FrozenLayout.propTypes = {
  layouts: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      location: PropTypes.oneOf(['left', 'right', 'top', 'well']),
      position: PropTypes.number,
      shelfProductId: PropTypes.string,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      endDate: PropTypes.string.isRequired,
      facingsOnShelf: PropTypes.number,
      isShelfRsb: PropTypes.bool,
      shelf: PropTypes.number,
      shelfPosition: PropTypes.number,
      shelfFill: PropTypes.number,
      shelfFillType: PropTypes.string,
      sku: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      storeComment: PropTypes.string,
    })
  ).isRequired,
  layoutType: PropTypes.string.isRequired,
  storePSAFlag: PropTypes.bool.isRequired,
  spaceTitle: PropTypes.string.isRequired,
}

export default FrozenLayout
