import React from 'react'
import { Header as LunaHeader, HeaderLogo, HeaderMainBar } from '@jsluna/react'

const Header = () => (
  <LunaHeader>
    <HeaderMainBar size="lg">
      <HeaderLogo>
        <span className="ln-c-navigation-wrapper__title">PromComm</span>
      </HeaderLogo>
    </HeaderMainBar>
  </LunaHeader>
)

export default Header
