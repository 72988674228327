import Api from 'src/api/Base'

class UpdateCategoryById extends Api {
  requestUrl = `/api/category/${this.getParams().categoryId}`

  method = 'PATCH'

  formatRequestData = params => params.category
}

export default UpdateCategoryById
