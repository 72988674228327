import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Heading3, FilledButton, Checkbox } from '@jsluna/react'

import { SHELF_LAYOUT_TYPE } from 'src/constants/layoutTypes'
import buildShelfTitle from 'src/utils/shelfTitle'
import { hasRequiredCategory, hasRequiredPermission } from 'src/utils/permissions'

import {
  DELETE_SHELVES_PRODUCTS_ALL,
  DELETE_SHELVES_PRODUCTS_OWN,
  DELETE_SHELVES_PRODUCTS_OWN_CATEGORY,
} from 'src/constants/permissions'

import HeaderCell from 'src/components/HeaderCell'
import Cell from 'src/components/RowCell'

const canDelete = user => product =>
  hasRequiredPermission(user, DELETE_SHELVES_PRODUCTS_ALL) ||
  (hasRequiredPermission(user, DELETE_SHELVES_PRODUCTS_OWN_CATEGORY) &&
    hasRequiredCategory(user, product.category.id)) ||
  (hasRequiredPermission(user, DELETE_SHELVES_PRODUCTS_OWN) && product.createdBy.id === user.id)

const getClusters = clusters => clusters.map(({ title }) => title).join(', ')
class DeleteDialog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedShelfProducts: [],
    }

    this.toggleSelectAllShelfProducts = this.toggleSelectAllShelfProducts.bind(this)
    this.getDeletableProducts = this.getDeletableProducts.bind(this)
    this.shelfProductAdded = this.shelfProductAdded.bind(this)
  }

  getDeletableProducts() {
    const { similarShelfProducts, user } = this.props

    return similarShelfProducts.filter(canDelete(user))
  }

  toggleSelectAllShelfProducts(checked) {
    this.setState(() => ({
      selectedShelfProducts: checked ? this.getDeletableProducts() : [],
    }))
  }

  shelfProductAdded(shelfProduct) {
    const { selectedShelfProducts } = this.state
    const exists = selectedShelfProducts.find(({ id }) => id === shelfProduct.id)

    return !!exists
  }

  updateShelfProducts(shelfProduct) {
    const exists = this.shelfProductAdded(shelfProduct)

    if (exists) {
      this.setState(state => ({
        selectedShelfProducts: state.selectedShelfProducts.filter(
          ({ id }) => id !== shelfProduct.id
        ),
      }))
    } else {
      this.setState(state => ({
        selectedShelfProducts: [...state.selectedShelfProducts, shelfProduct],
      }))
    }
  }

  render() {
    const { handleClose, shelfProduct, deleteProducts, similarShelfProducts, user } = this.props
    const { selectedShelfProducts } = this.state

    return (
      <div>
        <Heading3>Are you sure you want to delete this product(s)?</Heading3>
        {similarShelfProducts.length > 0 && (
          <div data-control="similar-products-to-delete">
            <p>
              We have found this product, {shelfProduct.description}, to also appear in the
              following shelves. Please select the ones you would like to delete.
            </p>

            <div className="ln-c-table-container ln-u-push-bottom">
              <table className="ln-c-table ln-c-table--fixed ln-u-text-align-center ln-u-border-bottom">
                <thead className="ln-c-table__header">
                  <tr className="ln-c-table__header-row">
                    <HeaderCell className="c-header-cell__select">
                      <Checkbox
                        className="c-checkbox-compact"
                        name="select-all-shelf-products"
                        onChange={({ target: { checked } }) =>
                          this.toggleSelectAllShelfProducts(checked)
                        }
                      />
                    </HeaderCell>
                    <HeaderCell>Cluster</HeaderCell>
                    <HeaderCell>Shelf</HeaderCell>
                    <HeaderCell>Category</HeaderCell>
                  </tr>
                </thead>
                <tbody className="ln-c-table__body">
                  {similarShelfProducts.map(sp => (
                    <tr key={sp.id}>
                      <Cell>
                        <Checkbox
                          className="c-checkbox-compact"
                          name={`select-shelf-product-${sp.id}`}
                          checked={this.shelfProductAdded(sp)}
                          onChange={() => this.updateShelfProducts(sp)}
                          disabled={!canDelete(user)(sp)}
                        />
                      </Cell>
                      <Cell>{getClusters(sp.clusters)}</Cell>
                      <Cell>
                        {buildShelfTitle(
                          sp.shelf.position,
                          sp.shelf.shelfLength,
                          sp.shelf.isRsb,
                          SHELF_LAYOUT_TYPE
                        )}
                      </Cell>
                      <Cell>{sp.category.title}</Cell>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <p>Please confirm this action</p>
        <div>
          <FilledButton
            data-control="modal-confirm-delete-shelf-product"
            onClick={async () => {
              const productsToDelete = [shelfProduct, ...selectedShelfProducts]

              await deleteProducts(productsToDelete)

              handleClose()
            }}
          >
            Confirm
          </FilledButton>
          <FilledButton
            color="dark"
            data-control="modal-cancel-delete-shelf-product"
            onClick={handleClose}
            className="ln-u-push-left"
          >
            Cancel
          </FilledButton>
        </div>
      </div>
    )
  }
}

const shelfProductType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
})

DeleteDialog.propTypes = {
  deleteProducts: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  pendingChangeControl: PropTypes.shape({
    status: PropTypes.string,
  }),
  similarShelfProducts: PropTypes.arrayOf(shelfProductType),
  shelfProduct: shelfProductType.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
}

DeleteDialog.defaultProps = {
  pendingChangeControl: null,
  similarShelfProducts: [],
}

export default DeleteDialog
