import { connect } from 'react-redux'

import { selectSelf } from 'src/store/data/selectors'

import {
  selectSpaceAssignmentWithSpaceInstances,
  selectSpaceInstanceById,
} from 'src/modules/SpaceAssignment/store/selectors'
import { selectUi } from '../store'
import {
  addShelf,
  addShelfProductLayout,
  deleteShelfProductLayout,
  refreshSpaceInstances,
} from '../store/actions'

import Shelves from './Shelves'

const mapStateToProps = (state, { instanceId }) => {
  const { id: spaceAssignmentId, pendingChangeControl } = selectSpaceAssignmentWithSpaceInstances(
    state
  )
  const {
    layoutType,
    shelves = [],
    shelfProductLayouts,
    categories: spaceInstanceCategories,
  } = selectSpaceInstanceById(state, instanceId)
  const { quickAddProduct } = selectUi(state)
  const { categories: ownCategories } = selectSelf(state)
  return {
    layoutType,
    ownCategories,
    quickAddProduct,
    spaceInstanceCategories,
    shelfProductLayouts,
    pendingChangeControl,
    shelves,
    spaceAssignmentId,
  }
}

const mapDispatchToProps = (dispatch, { spaceAssignmentId }) => ({
  addShelf: (...args) => dispatch(addShelf(...args, spaceAssignmentId)),
  addShelfProductLayout: (shelfProductId, frozenProductLayout) =>
    dispatch(addShelfProductLayout(shelfProductId, frozenProductLayout, spaceAssignmentId)),
  deleteShelfProductLayout: layoutId =>
    dispatch(deleteShelfProductLayout(layoutId, spaceAssignmentId)),
  refreshSpaceInstances: async () => {
    await dispatch(refreshSpaceInstances(spaceAssignmentId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Shelves)
