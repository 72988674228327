import { connect } from 'react-redux'

import { hasRequiredPermission } from 'src/utils/permissions'

import { selectSelf, selectAllowedCategories } from 'src/store/data/selectors'

import {
  UPDATE_SHELVES_PALLET_OR_STACK_TYPE_ALL,
  UPDATE_SHELVES_PALLET_OR_STACK_TYPE_OWN_CATEGORY,
} from 'src/constants/permissions'

import { moveProductToShelf } from '../../store/actions'
import Shelf from './Shelf'

const mapStateToProps = (state, { shelf }) => {
  const { categories } = shelf
  const user = selectSelf(state)
  const allowedCategories = selectAllowedCategories(state, categories)

  const canSetPalletOrStackType =
    hasRequiredPermission(user, UPDATE_SHELVES_PALLET_OR_STACK_TYPE_ALL) ||
    (hasRequiredPermission(user, UPDATE_SHELVES_PALLET_OR_STACK_TYPE_OWN_CATEGORY) &&
      allowedCategories.length > 0)

  return {
    canSetPalletOrStackType,
  }
}

const mapDispatchToProps = (dispatch, { shelf: { spaceAssignmentId } }) => ({
  moveProductToShelf: (id, shelfId, toPosition) =>
    dispatch(moveProductToShelf(id, shelfId, toPosition, spaceAssignmentId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Shelf)
