import loader from 'src/hoc/loader'
import { fetchCategories } from 'src/store/modules/asyncThunks/categories'

import {
  actionClearData,
  fetchGroupsBySpacePlan,
  fetchSpacePlanCyclesAssignments,
  selectGroupsBySpacePlan,
  selectGroupSelected,
} from './store'
import { resetTracks, saveSpaceMultiselect, saveSpaceSearch } from './store/actions'
import SpacePlanner from './SpacePlanner'
import { changeSelectedGroup, clearSpacePlannerData } from './store/reducer/spacePlanAssignments'

const getData = async ({ id: spacePlanId }, dispatch, getState) => {
  let spacePlanApiInput = { spacePlanId }

  await dispatch(fetchGroupsBySpacePlan(spacePlanId))
  const state = getState()
  const groupList = selectGroupsBySpacePlan(state)
  if (groupList.length > 0) {
    const selectedGroup = selectGroupSelected(state)
    const selectedGroupId = selectedGroup?.id || groupList[0].id
    spacePlanApiInput = { spacePlanId, groupId: selectedGroupId }
    dispatch(changeSelectedGroup(selectedGroupId))
  }
  const actions = [fetchCategories(), fetchSpacePlanCyclesAssignments(spacePlanApiInput)]
  await Promise.all(actions.map(a => dispatch(a).unwrap()))
}

const options = {
  onUnmount: dispatch => {
    if (!window.location.pathname.includes('/space-assignments/')) {
      dispatch(clearSpacePlannerData())
      dispatch(saveSpaceSearch(''))
      dispatch(saveSpaceMultiselect([]))
    }
    dispatch(actionClearData())
    dispatch(resetTracks())
  },
}

export default loader(getData, options)(SpacePlanner)
