import { TOGGLE_OPEN } from '../actions'

const reducer = (state = false, { type } = {}) => {
  if (type === TOGGLE_OPEN) {
    return !state
  }

  return state
}

export default reducer
