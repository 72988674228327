import PropTypes from 'prop-types'
import React from 'react'
import { GridItem } from '@jsluna/react'
import { Link } from 'react-router'

import capitalise from 'src/utils/capitalise'

const PrevNextCycle = ({ cycle, cycleType, businessUnit }) => (
  <GridItem className="ln-u-1/3@md" size="1/1">
    <div className="ln-c-label ln-u-soft-right">{capitalise(cycleType)}</div>
    {cycle && cycle.id ? (
      <Link className="ln-c-standalone-link" to={`/cycles/${businessUnit}/${cycle.id}/details`}>
        {cycle.title}
      </Link>
    ) : (
      <span>There is no {cycleType} cycle.</span>
    )}
  </GridItem>
)

PrevNextCycle.propTypes = {
  cycle: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  cycleType: PropTypes.string.isRequired,
  businessUnit: PropTypes.oneOf(['food', 'non-food']).isRequired,
}

PrevNextCycle.defaultProps = {
  cycle: null,
}

export default PrevNextCycle
