const keyBy = (arr = [], key) => {
  if (typeof key === 'function') {
    return arr.reduce((acc, curr) => {
      acc[key(curr)] = curr
      return acc
    }, {})
  }

  return arr.reduce((acc, curr) => {
    acc[curr[key]] = curr
    return acc
  }, {})
}

export default keyBy
