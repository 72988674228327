const defaultEnv = 'local'

function getUrl(environment) {
  switch (environment) {
    case 'local':
      return 'http://localhost:5104'
    case 'proxy':
      return '/net'
    default:
      return `https://promcomm-api.int.${environment}.jspaas.uk`
  }
}

function getDebug(environment) {
  switch (environment) {
    case 'dev':
    case 'local':
      return true
    default:
      return false
  }
}

function configReducer({ environment = defaultEnv, version = '' } = {}) {
  const apiUrl = getUrl(environment)
  const debug = getDebug(environment)

  return {
    environment,
    apiUrl,
    debug,
    version,
  }
}

export default configReducer
