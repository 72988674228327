import { getDisplayText, getDuration, getDurationText } from 'src/utils/datePeriod'

import statuses from './statuses'

const getLink = spacePlan => `/space-plans/${spacePlan.id}`
const getStatusTitle = ({ status }) => statuses.get(status).title

export default [
  { key: 'title', name: 'Title', value: 'title', link: getLink },
  { key: 'dates', name: 'Dates', value: getDisplayText, sortValue: 'endDate' },
  { key: 'duration', name: 'Duration', value: getDurationText, sortValue: getDuration },
  { key: 'status', name: 'Status', value: getStatusTitle },
]
