import React from 'react'

import sortArray from 'src/utils/sortArray'

import Tags from 'src/components/Tags'

const buildItem = ({ id, title, theme, active }) => ({
  id,
  title,
  color: theme,
  ...(!active ? { opacity: 0.5 } : {}),
})

const buildItems = tagged => sortArray(tagged, 'title').map(buildItem)

const Tagged = tagged => (tagged.length ? <Tags items={buildItems(tagged)} /> : null)

Tagged.defaultProps = {
  tagged: [],
}

export default Tagged
