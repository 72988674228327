import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { combineAndSortShelfItems } from 'src/utils/shelfItems'

import HeaderCell from 'src/components/HeaderCell'
import ShelfArtifact from 'src/components/ShelfArtifacts'
import { SHELF_ARTIFACTS_TYPE, SHELVES_PRODUCTS_TYPE } from 'src/constants/types'
import Product from './Product'

const ShelfProducts = ({
  assignmentStartDate,
  assignmentEndDate,
  canDelete,
  canMove,
  canUpdate,
  deleteProducts,
  deleteShelfArtifact,
  disableUpdateDeleteProduct,
  hasChange,
  hasPendingChange,
  isChangeControlView,
  isDraftChangeControl,
  isLockedForChangeControl,
  moveProduct,
  moveProductToShelf,
  moveShelfArtifact,
  refresh,
  shelf,
  updateShelfArtifact,
  weeks,
  shelves,
  spaceAssignment,
  spaceInstanceId,
  pendingChangeControl,
}) => {
  const {
    categories,
    id: shelfId,
    removedProducts = [],
    shelfProducts: products = [],
    shelfArtifacts = [],
  } = shelf
  const productsToDisplay = isChangeControlView ? products.concat(removedProducts) : products

  const index = shelves.findIndex(({ id }) => id === shelf.id)
  const isTopShelf = index === 0
  const isBottomShelf = index === shelves.length - 1

  const shelfItems = combineAndSortShelfItems(productsToDisplay, shelfArtifacts)

  return (
    <div className="ln-c-table-container">
      <table className="ln-c-table ln-c-table--fixed ln-u-text-align-center ln-u-border-bottom">
        <thead className="ln-c-table__header">
          <tr className="ln-c-table__row">
            <HeaderCell className="c-space-assignment-header-cell-sku">SKU</HeaderCell>
            <HeaderCell className="c-space-assignment-header-cell-dept-com">Dept Com</HeaderCell>
            {isChangeControlView && (
              <HeaderCell className="c-space-assignment-header-cell-change" />
            )}
            <HeaderCell className="c-space-assignment-header-cell-description">
              Description
            </HeaderCell>
            <HeaderCell className="c-space-assignment-header-cell-hfss">HFSS</HeaderCell>
            <HeaderCell className="c-space-assignment-header-cell-cluster" />
            <HeaderCell className="c-space-assignment-header-cell-promo-type">PromoType</HeaderCell>
            <HeaderCell className="c-space-assignment-header-cell-shelf-fill">
              Shelf Fill
            </HeaderCell>
            <HeaderCell className="c-space-assignment-header-cell-facings-on-shelf">
              Facings
            </HeaderCell>
            <HeaderCell className="c-space-assignment-header-cell-dates">Dates</HeaderCell>
            <HeaderCell className="c-space-assignment-header-cell-pos-advice">
              POS Advice
            </HeaderCell>
            <HeaderCell className="c-space-assignment-header-cell-created-by">
              Created By
            </HeaderCell>
            <HeaderCell className="c-space-assignment-header-cell-price-point">
              Price Point
            </HeaderCell>
            <HeaderCell className="c-space-assignment-header-cell-controls" />
          </tr>
        </thead>
        <tbody className="ln-c-table__body" data-control="shelf-assigned-products">
          {shelfItems.map((shelfItem, position) => {
            const { type } = shelfItem
            return (
              <Fragment key={shelfItem.id}>
                {type === SHELVES_PRODUCTS_TYPE && (
                  <Product
                    assignmentStartDate={assignmentStartDate}
                    assignmentEndDate={assignmentEndDate}
                    canDelete={canDelete}
                    canMove={canMove(shelfItem)}
                    canUpdate={canUpdate}
                    categories={categories}
                    deleteProducts={deleteProducts}
                    disableUpdateDelete={disableUpdateDeleteProduct(shelfItem)}
                    hasChange={hasChange(shelfItem)}
                    hasPendingChange={hasPendingChange(shelfItem)}
                    isChangeControlView={isChangeControlView}
                    isDraftChangeControl={isDraftChangeControl}
                    isLockedForChangeControl={isLockedForChangeControl}
                    isTopShelf={isTopShelf}
                    isBottomShelf={isBottomShelf}
                    moveProduct={moveProduct}
                    moveProductToShelf={moveProductToShelf}
                    overrideEndDate={shelfItem.overrideEndDate}
                    overrideStartDate={shelfItem.overrideStartDate}
                    pendingChangeControl={pendingChangeControl}
                    position={position}
                    itemsOnShelf={shelfItems.length}
                    refresh={refresh}
                    shelfId={shelfId}
                    shelfProduct={shelfItem}
                    weeks={weeks}
                    shelves={shelves}
                    spaceAssignment={spaceAssignment}
                    spaceInstanceId={spaceInstanceId}
                  />
                )}

                {type === SHELF_ARTIFACTS_TYPE && (
                  <ShelfArtifact
                    artifact={shelfItem}
                    columnSpan={isChangeControlView ? 14 : 13}
                    deleteShelfArtifact={deleteShelfArtifact}
                    itemsOnShelf={shelfItems.length}
                    moveShelfArtifact={moveShelfArtifact}
                    shelfPosition={position}
                    updateShelfArtifact={updateShelfArtifact}
                  />
                )}
              </Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

ShelfProducts.propTypes = {
  assignmentStartDate: PropTypes.string,
  assignmentEndDate: PropTypes.string,
  canDelete: PropTypes.func.isRequired,
  canMove: PropTypes.func.isRequired,
  canUpdate: PropTypes.func.isRequired,
  deleteProducts: PropTypes.func.isRequired,
  deleteShelfArtifact: PropTypes.func.isRequired,
  disableUpdateDeleteProduct: PropTypes.func.isRequired,
  hasChange: PropTypes.func.isRequired,
  hasPendingChange: PropTypes.func.isRequired,
  isChangeControlView: PropTypes.bool.isRequired,
  isDraftChangeControl: PropTypes.bool.isRequired,
  isLockedForChangeControl: PropTypes.bool.isRequired,
  moveProduct: PropTypes.func.isRequired,
  moveProductToShelf: PropTypes.func.isRequired,
  moveShelfArtifact: PropTypes.func.isRequired,
  pendingChangeControl: PropTypes.shape({}),
  refresh: PropTypes.func.isRequired,
  shelf: PropTypes.shape({
    id: PropTypes.string.isRequired,
    shelfArtifacts: PropTypes.arrayOf(
      PropTypes.shape({
        artifactType: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
        description: PropTypes.string,
      })
    ),
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ).isRequired,
    removedProducts: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      })
    ),
    shelfProducts: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string,
        id: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  updateShelfArtifact: PropTypes.func.isRequired,
  weeks: PropTypes.arrayOf(PropTypes.number).isRequired,
  shelves: PropTypes.arrayOf(PropTypes.shape({})),
  spaceAssignment: PropTypes.shape({}),
  spaceInstanceId: PropTypes.string.isRequired,
}

ShelfProducts.defaultProps = {
  assignmentStartDate: null,
  assignmentEndDate: null,
  shelves: [],
  spaceAssignment: null,
  pendingChangeControl: null,
}

export default ShelfProducts
