import React from 'react'
import PropTypes from 'prop-types'

import { Heading3, FilledButton } from '@jsluna/react'
import capitalise from 'src/utils/capitalise'

const DeleteDialog = ({ artifactId, deleteShelfArtifact, handleClose, type }) => (
  <div>
    <Heading3>Are you sure you want to delete this {capitalise(type)}</Heading3>
    <p>Please confirm this action</p>
    <div>
      <FilledButton
        data-control="modal-confirm-delete-shelf-artifact"
        onClick={async () => {
          await deleteShelfArtifact(artifactId)
          handleClose()
        }}
      >
        Confirm
      </FilledButton>
      <FilledButton
        color="dark"
        data-control="modal-cancel-delete-shelf-artifact"
        onClick={handleClose}
        className="ln-u-push-left"
      >
        Cancel
      </FilledButton>
    </div>
  </div>
)

DeleteDialog.propTypes = {
  artifactId: PropTypes.string.isRequired,
  deleteShelfArtifact: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default DeleteDialog
