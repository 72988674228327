import React from 'react'

import loader from 'src/hoc/loader'
import NotFound from 'src/components/Errors/NotFound'
import { fetchAdminConfigMessages } from 'src/store/modules/asyncThunks/adminConfigMessages'
import { fetchPendingSspsaTasks, actionClearData } from './store'
import Sspsa from './Sspsa'
import { fetchAvailablePsaPreview } from './store/availablePsaPreview'
import { fetchMostRecentSspsaList } from './store/mostRecentSspsaList'

const getData = async (props, dispatch) => {
  await dispatch(fetchPendingSspsaTasks())
  await dispatch(fetchAvailablePsaPreview())
  await dispatch(fetchAdminConfigMessages())
  await dispatch(fetchMostRecentSspsaList())
}
const options = {
  Custom404: (
    <NotFound title="SSPSA Not Found" message="The SSPSA you are looking for cannot be found." />
  ),
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(Sspsa)
