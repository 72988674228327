import loader from 'src/hoc/loader'

import { actionGetData } from './store'
import Form from './Form'

const getData = actionGetData
const options = {
  shouldRefresh: (previous, current) => previous.id !== current.id,
}

export default loader(getData, options)(Form)
