import { createSelector } from 'reselect'
import { fetchShelfProductById, shelfProductByIdSlice } from './reducer/shelfProduct'

const NAMESPACE = 'ShelfProducts'

const selectModule = state => state.modules[NAMESPACE]
const selectShelfProduct = createSelector(selectModule, module => module.data)

const actionGetData = async ({ id }, dispatch) => {
  if (id) await dispatch(fetchShelfProductById(id))
}

export default { [NAMESPACE]: shelfProductByIdSlice.reducer }

export { selectShelfProduct, actionGetData }
