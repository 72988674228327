import { connect } from 'react-redux'

import redirectAction from 'src/store/data/actions/redirect'

const Homepage = ({ redirect }) => {
  redirect('/')

  return null
}

export default connect(null, { redirect: redirectAction })(Homepage)
