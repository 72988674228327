const SORT_TABLE = 'SORT_TABLE'

function sortTable(table) {
  return payload => ({
    type: SORT_TABLE,
    payload: { ...payload, table },
  })
}

export { SORT_TABLE, sortTable }
