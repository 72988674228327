import React from 'react'
import { Card, ProgressIndicator, ProgressSpinner } from '@jsluna/react'

const LoadingCard = () => (
  <Card>
    <ProgressIndicator loading preventFocus>
      <ProgressSpinner className="ln-u-push-right-sm" />
    </ProgressIndicator>
  </Card>
)

export default LoadingCard
