import shortid from 'shortid'
import now from 'src/utils/now'

const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'

const DEFAULT_TYPE = 'info'
function createNotification(body, isError = false, time = now(), type = DEFAULT_TYPE) {
  const payload = {
    id: shortid.generate(),
    isError,
    body,
    time,
    type,
  }

  return { type: CREATE_NOTIFICATION, payload }
}

function dismissNotification(id) {
  return { type: DELETE_NOTIFICATION, payload: { id } }
}

function createError(message) {
  return createNotification(message, true, now(), 'danger')
}

export {
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  createNotification,
  dismissNotification,
  createError,
}
