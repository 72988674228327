import loader from 'src/hoc/loader'

import { fetchSpaces } from './store'
import Spaces from './Spaces'

const getData = async (props, dispatch) => {
  await dispatch(fetchSpaces())
}

export default loader(getData)(Spaces)
