import stubsMap from './stubsMap'

const findStub = async (endpoint, method) => {
  const found = stubsMap.find(mapObject => {
    return mapObject.method === method && endpoint.match(mapObject.regex)
  })

  if (found && found.import) {
    return Promise.resolve(await import(`${found.import}`)).then(module => {
      return module.default
    })
  }

  return false
}

export default findStub
