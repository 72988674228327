import { maskObject } from 'src/utils/mask'

import { actionCreateShelfProduct, actionUpdateShelfProduct } from '../reducer/shelfProduct'

const toNumber = value => {
  if (value === undefined) {
    return undefined
  }

  if (value === null || value === '') {
    return null
  }
  return Number.parseFloat(value)
}

const toBoolean = value => {
  if (value === undefined) {
    return undefined
  }
  if (value === null || value === '') {
    return null
  }
  return value === true || value === 'true'
}

const nonFoodFieldsMask = {
  percentSoldPromoPrice: true,
  percentSoldClearancePrice: true,
  salesForecastPromoCycle: true,
  salesForecastLine: true,
  soaPerUnit: true,
  clearancePrice: true,
  bulkSupport: true,
  salesPhaseWeeks: true,
  forecastPurchaseQuantity: true,
  stockValueForPromo: true,
  nppInStoreDate: true,
  nppRequired: true,
  stockInShipperFdsu: true,
  weeks: true,
  specialBuy: true,
}

const handleSubmit = async (values, { props }) => {
  const {
    categories,
    disabled,
    dispatch,
    handleClose,
    id: shelfProductId,
    onUpdate,
    shelfId,
  } = props

  const {
    bulkSupport,
    category: categoryId,
    clearancePrice,
    clusters,
    facingsOnShelf,
    forecastPurchaseQuantity,
    nppRequired,
    oldProduct,
    originalRetailPrice,
    packSize,
    percentSoldClearancePrice,
    percentSoldPromoPrice,
    percentUpliftAisle,
    percentUpliftPlinth,
    pricePoint,
    product: { id: productId },
    profitPpp,
    promoCaseSize,
    promoMechanic,
    salesForecastLine,
    salesForecastPromoCycle,
    salesPhaseWeeks,
    shelfFill,
    soaPerUnit,
    specialBuy,
    stockInShipperFdsu,
    stockValueForPromo,
    ubw,
    wigig,
    comment,
    overrideStartDate,
    overrideEndDate,
    promoMechanicValue,
    shelfFillType,
    storeComment,
    depClass,
    status,
    nppInStoreDate,
  } = values

  const { businessUnit } = categories.find(category => category.id === categoryId)
  const mask = businessUnit === 'non-food' ? disabled : Object.assign(disabled, nonFoodFieldsMask)

  const shelfProduct = maskObject(
    {
      shelfFill: toNumber(shelfFill),
      packSize: toNumber(packSize),
      percentUpliftAisle: toNumber(percentUpliftAisle),
      percentUpliftPlinth: toNumber(percentUpliftPlinth),
      wigig: toBoolean(wigig),
      pricePoint: toNumber(pricePoint),
      promoCaseSize: toNumber(promoCaseSize),
      shelfId: shelfId || values.shelfId,
      categoryId,
      productId,
      promoMechanicId: promoMechanic,
      clusters,
      facingsOnShelf: toNumber(facingsOnShelf),
      ubw: toNumber(ubw),
      originalRetailPrice: toNumber(originalRetailPrice),
      clearancePrice: toNumber(clearancePrice),
      specialBuy: toBoolean(specialBuy),
      percentSoldPromoPrice: toNumber(percentSoldPromoPrice),
      percentSoldClearancePrice: toNumber(percentSoldClearancePrice),
      ...(salesPhaseWeeks ? { salesPhaseWeeks: salesPhaseWeeks.map(toNumber).toString() } : {}),
      soaPerUnit: toNumber(soaPerUnit),
      bulkSupport: toNumber(bulkSupport),
      salesForecastPromoCycle: toNumber(salesForecastPromoCycle),
      salesForecastLine: toNumber(salesForecastLine),
      forecastPurchaseQuantity: toNumber(forecastPurchaseQuantity),
      stockValueForPromo: toNumber(stockValueForPromo),
      nppRequired: toBoolean(nppRequired),
      nppInStoreDate,
      stockInShipperFdsu: toBoolean(stockInShipperFdsu),
      profitPpp: toNumber(profitPpp),
      comment,
      overrideStartDate,
      overrideEndDate,
      promoMechanicValue,
      shelfFillType,
      storeComment,
      depClass,
      status,
    },
    mask
  )

  if (oldProduct && oldProduct.id) {
    shelfProduct.oldProductId = oldProduct.id
  } else {
    delete shelfProduct.oldProduct
  }

  const action = shelfProductId
    ? actionUpdateShelfProduct({ shelfProductId, shelfProduct })
    : actionCreateShelfProduct(shelfProduct)

  try {
    const response = await dispatch(action)
    if (onUpdate) {
      await onUpdate(response)
    }
  } finally {
    if (handleClose) {
      handleClose()
    }
  }
}
export default handleSubmit
