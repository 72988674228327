import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FilledButton, Heading3 } from '@jsluna/react'

import CheckboxGroup from 'src/components/CheckboxGroup'

class CategoriesSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: [],
    }
    this.onChange = this.onChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  handleSave() {
    const { save, handleClose } = this.props
    const { value } = this.state
    save(value)
    handleClose()
  }

  handleCancel() {
    const { handleClose } = this.props
    handleClose()
  }

  onChange(value) {
    this.setState({ value })
  }

  render() {
    const { categories, layoutType } = this.props
    const { value } = this.state
    const input = {
      value,
      onChange: this.onChange,
      name: 'categories',
    }

    return (
      <div>
        <Heading3>Select categories for all {layoutType}</Heading3>
        <div className="ln-u-push-bottom">
          <CheckboxGroup options={categories} input={input} />
        </div>
        <div>
          <FilledButton data-control="save-categories-select" onClick={this.handleSave}>
            Save
          </FilledButton>
          <FilledButton
            color="dark"
            data-control="cancel-categories-select"
            onClick={this.handleCancel}
            className="ln-u-push-left"
          >
            Cancel
          </FilledButton>
        </div>
      </div>
    )
  }
}

CategoriesSelect.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
    })
  ).isRequired,
  handleClose: PropTypes.func.isRequired,
  layoutType: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
}

export default CategoriesSelect
