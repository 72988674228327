import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default function Cell({ children, sortedBy, sortKey, sortAction }) {
  const isActive = sortKey === sortedBy.key
  const handleSortClick = e => {
    e.preventDefault()
    sortAction({
      key: sortKey,
      ascending: isActive ? !sortedBy.ascending : false,
    })
  }

  const buttonClasses = classNames(
    'ln-c-table__sort-button',
    isActive && 'is-active',
    sortedBy.ascending && isActive && 'is-ascending'
  )

  return (
    <th className="ln-c-table__header-cell ln-c-table__header-cell--sortable">
      <button onClick={handleSortClick} className={buttonClasses} type="button">
        <span className="ln-c-table__sort-text" aria-label="Sort by">
          {children}
        </span>
      </button>
    </th>
  )
}

Cell.propTypes = {
  children: PropTypes.string.isRequired,
  sortedBy: PropTypes.shape({
    key: PropTypes.string,
    ascending: PropTypes.bool,
  }).isRequired,
  sortKey: PropTypes.string.isRequired,
  sortAction: PropTypes.func.isRequired,
}
