import shelfProductSpecification from './shelfProduct'
import changeControlSpecification from './changeControl'

const entityTypeToSpecification = {
  'shelves-products': shelfProductSpecification,
  'future-shelves-products': shelfProductSpecification,
  'change-controls': changeControlSpecification,
}

// eslint-disable-next-line import/prefer-default-export
export const getSpecification = entityType => entityTypeToSpecification[entityType]
