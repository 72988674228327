import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, withFormik } from 'formik'
import {
  Heading2,
  FilledButton,
  Form,
  FormGroup,
  GridWrapper,
  GridItem,
  List,
  ListItem,
  AutocompleteField,
} from '@jsluna/react'
import { TextInputField } from '@jsluna/form/extensions/formik'

import { CheckboxGroup } from 'src/components/FormikComponents'
import sortArray from 'src/utils/sortArray'
import createPosName from 'src/utils/createPOSName'
import handleSubmit from './handleSubmit'
import validate from './validate'
import createFilter from './createFilter'

const FormComponent = ({
  posAdviceList,
  shelfProductsWithPOS,
  handleSubmit: onSubmit,
  onClose,
  setFieldValue,
  posAdviceWithProducts,
  posId,
  errors,
}) => {
  const [enablePOSTypeText, setEnablePOSTypeText] = useState(false)
  const productToOption = ({ id, title, pointOfSaleAdvice }) => ({
    id,
    label: title,
    value: id,
    disabled: !!pointOfSaleAdvice && pointOfSaleAdvice?.id !== posId,
  })
  const productOptions = sortArray([...shelfProductsWithPOS], 'title').map(productToOption)

  const posToOptions = ({ id, type, paCode, description }) => ({
    id,
    label: createPosName({ type, paCode, description }),
    value: id,
  })

  const sortPOS = posArray => sortArray(posArray.map(posToOptions), 'label', false) || []
  const posList = sortPOS(posAdviceList)
  posList.push({ value: 'new', label: 'Create new' })
  const selectedPOS = posId && posAdviceWithProducts && posToOptions(posAdviceWithProducts)

  const onChangePOS = e => {
    const selectedPOSOption = posAdviceList.find(pos => pos.id === e?.id)
    if (e?.value === 'new') {
      setEnablePOSTypeText(true)
      setFieldValue('posType', '')
      setFieldValue('paCode', '')
      setFieldValue('description', '')
      setFieldValue('posId', 'new')
    } else {
      setFieldValue('posId', selectedPOSOption?.id)
      setEnablePOSTypeText(false)
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      <Heading2>
        <span data-control="pos-title">Assign Point Of Sale</span>
      </Heading2>
      <FormGroup name="pos-field">
        <GridWrapper>
          <GridItem size="1@sm">
            <AutocompleteField
              label="POS"
              name="posId"
              options={posList}
              placeholder={selectedPOS?.label || 'Please select'}
              onSelect={onChangePOS}
              validationFirst={false}
              disabled={!!posId}
              error={errors?.posId}
              filter={createFilter}
            />
          </GridItem>
          {enablePOSTypeText && (
            <>
              <GridItem size="1/3@sm">
                <Field
                  label="New POS Type"
                  name="posType"
                  type="string"
                  component={TextInputField}
                  validationFirst={false}
                />
              </GridItem>
              <GridItem size="1/3@sm">
                <Field
                  label="PA code"
                  name="paCode"
                  type="string"
                  component={TextInputField}
                  validationFirst={false}
                />
              </GridItem>
              <GridItem size="1/3@sm">
                <Field
                  label="Description"
                  name="description"
                  type="string"
                  component={TextInputField}
                  validationFirst={false}
                />
              </GridItem>
            </>
          )}

          <GridItem size="1/1">
            <CheckboxGroup
              label="Shelf Product List"
              name="shelfProductList"
              options={productOptions}
              disabled={false}
            />
          </GridItem>
        </GridWrapper>
      </FormGroup>
      <List type="matrix">
        <ListItem type="matrix">
          <FilledButton data-control="Save" name="Save" value="Save" type="submit">
            Submit
          </FilledButton>
        </ListItem>
        <ListItem type="matrix">
          <FilledButton color="dark" data-control="cancel" onClick={onClose}>
            <span>Cancel</span>
          </FilledButton>
        </ListItem>
      </List>
    </Form>
  )
}

FormComponent.propTypes = {
  shelfProductsWithPOS: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
    })
  ),
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({ description: PropTypes.string }),
  posAdviceList: PropTypes.arrayOf(
    PropTypes.shape({ description: PropTypes.string, id: PropTypes.string })
  ).isRequired,
  posAdviceWithProducts: PropTypes.shape({
    id: PropTypes.string,
    shelfProductList: PropTypes.arrayOf(PropTypes.string),
  }),
  posId: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.string),
}

FormComponent.defaultProps = {
  values: {},
  shelfProductsWithPOS: [],
  posAdviceWithProducts: {},
  posId: '',
  errors: {},
}

const wrappedForm = withFormik({ validate, handleSubmit })(FormComponent)

export default wrappedForm
