import React from 'react'

import Section from 'src/components/Section'
import loader from 'src/hoc/loader'
import { fetchCategories } from 'src/store/modules/asyncThunks/categories'
import { fetchRoles } from 'src/store/modules/asyncThunks/roles'
import { actionClearData } from './store'
import Form from './Form'

const Unassigned = () => (
  <Section>
    <Form />
  </Section>
)

const getData = async (props, dispatch) => {
  const actions = [fetchRoles(), fetchCategories()]
  await Promise.all(actions.map(a => dispatch(a)))
}

const options = {
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(Unassigned)

export { default as Submitted } from './Submitted'
