import { CHANGE_CONTROL_STATUS_DRAFT } from 'src/constants/changeControlStatus'

import {
  mandatoryWithChangeControl,
  mandatoryWithChangeControlNonFood,
} from 'src/constants/mandatoryChangeControlFields'

import { PROMO_MECHANIC_NOT_DEFINED_ID } from 'src/constants/shelfProductDetails'
import { isToDecimalPlaces } from 'src/utils/validation'

const mustBeInteger = [
  'facingsOnShelf',
  'packSize',
  'percentSoldClearancePrice',
  'percentSoldPromoPrice',
  'promoCaseSize',
]

const mustBeToTwoDecimalPlaces = [
  'shelfFill',
  'bulkSupport',
  'clearancePrice',
  'forecastPurchaseQuantity',
  'originalRetailPrice',
  'percentUpliftAisle',
  'percentUpliftPlinth',
  'pricePoint',
  'salesForecastLine',
  'salesForecastPromoCycle',
  'soaPerUnit',
  'stockValueForPromo',
  'profitPpp',
]

const mustBeToOneDecimalPlaces = ['ubw']

const mandatoryMessage = 'This field is mandatory'

const isInteger = input => {
  const string = String(input)
  const number = Math.floor(Number(string))
  return !Number.isNaN(number) && Number.isFinite(number) && String(number) === string
}

const getCategory = (categoryId, categories) => categories.find(c => c.id === categoryId)

const validate = (values, props) => {
  const errors = {}
  const { categories, pendingChangeControl, disabled } = props
  const {
    category,
    endDate,
    nppInStoreDate,
    nppRequired,
    pricePoint,
    product,
    promoMechanic,
    salesPhaseWeeks,
    startDate,
    shelfFill,
  } = values
  const isToTwoDecimalPlaces = isToDecimalPlaces(2)
  const isToOneDecimalPlace = isToDecimalPlaces(1)
  const isNotDisabled = key => !disabled[key]
  const businessUnit = values.category ? getCategory(values.category, categories).businessUnit : ''
  const isNonFood = businessUnit === 'non-food'

  if (pendingChangeControl && pendingChangeControl.status === CHANGE_CONTROL_STATUS_DRAFT) {
    mandatoryWithChangeControl.forEach(field => {
      if (!values[field] && isNotDisabled(field)) {
        errors[field] = mandatoryMessage
      }
    })

    if (isNonFood) {
      mandatoryWithChangeControlNonFood.forEach(field => {
        if (!values[field] && isNotDisabled(field)) {
          errors[field] = mandatoryMessage
        }
      })

      if (salesPhaseWeeks && isNotDisabled('salesPhaseWeeks') && Array.isArray(salesPhaseWeeks)) {
        salesPhaseWeeks.forEach((salesPhaseWeek, i) => {
          if (!salesPhaseWeek) {
            errors[`salesPhaseWeeks[${i}]`] = mandatoryMessage
          }
        })
      }

      if (nppRequired && !nppInStoreDate) {
        errors.nppInStoreDate = mandatoryMessage
      }
    }
  }

  if (!category) {
    errors.category = 'A category must be selected'
  }

  if (!promoMechanic) {
    errors.promoMechanic = 'A promo mechanic must be selected'
  }

  if (!(product && product.id)) {
    errors.product = 'A product must be selected'
  }

  if (promoMechanic !== PROMO_MECHANIC_NOT_DEFINED_ID && !pricePoint) {
    errors.pricePoint = 'A price point must be entered'
  }

  if (promoMechanic !== PROMO_MECHANIC_NOT_DEFINED_ID && pricePoint && pricePoint <= 0) {
    errors.pricePoint = 'A price point must be greater than zero'
  }

  if (!shelfFill) {
    errors.shelfFill = 'A shelf fill value must be entered'
  }

  if (shelfFill && shelfFill <= 0) {
    errors.shelfFill = 'A shelf fill must be greater than zero'
  }

  if (endDate < startDate) {
    errors.endDate = 'End Date cannot be before the Start Date'
  }

  mustBeToTwoDecimalPlaces.forEach(field => {
    const value = values[field]

    if (value && isNotDisabled(field) && !isToTwoDecimalPlaces(value)) {
      errors[field] = 'This field can only have two decimal places'
    }
  })

  mustBeToOneDecimalPlaces.forEach(field => {
    const value = values[field]

    if (value && isNotDisabled(field) && !isToOneDecimalPlace(value)) {
      errors[field] = 'This field can only have one decimal place'
    }
  })

  mustBeInteger.forEach(field => {
    const value = values[field]

    if (value && isNotDisabled(field) && !isInteger(value)) {
      errors[field] = 'This field must be a whole number'
    }
  })

  if (salesPhaseWeeks && isNotDisabled('salesPhaseWeeks') && Array.isArray(salesPhaseWeeks)) {
    salesPhaseWeeks.forEach((salesPhaseWeek, i) => {
      if (salesPhaseWeek && !isInteger(salesPhaseWeek)) {
        errors[`salesPhaseWeeks[${i}]`] = 'This field must be a whole number'
      }
    })
  }

  return errors
}

export default validate
