import { fetchExportsAllChangeControls, fetchExportChangeControlSkuDetails } from './index'

const CHANGE_CONTROL_FILTER = 'change-controls-set-filter'

const setChangeControlsFilter = (filterType, value) => ({
  type: CHANGE_CONTROL_FILTER,
  payload: {
    filterType,
    value,
  },
})

const getExportsChangeControls = cycleId => dispatch =>
  dispatch(fetchExportsAllChangeControls({ cycleId }))
const getExportsChangeControlSkuDetails = cycleId => dispatch =>
  dispatch(fetchExportChangeControlSkuDetails({ cycleId }))

export {
  CHANGE_CONTROL_FILTER,
  setChangeControlsFilter,
  getExportsChangeControls,
  getExportsChangeControlSkuDetails,
}
