import loader from 'src/hoc/loader'

import { actionGetSummaryData, actionClearData } from './store'
import Summaries from './Summaries'

const options = {
  shouldRefresh: (previous, current) => previous.cycleId !== current.cycleId,
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(actionGetSummaryData, options)(Summaries)
