import { STORAGE_BY_ID } from 'src/constants/spaceTypes'

const getLink = spaceType => `/spaces/types/${spaceType.id}`

const getStorage = spaceType => STORAGE_BY_ID[spaceType.storage].label

const columns = [
  { key: 'title', name: 'Title', value: 'title', link: getLink },
  { key: 'storage', name: 'Storage', value: getStorage },
  { key: 'shelvesDefault', name: 'Shelves Default', value: 'shelvesDefault' },
  { key: 'shelvesMin', name: 'Shelves Min', value: 'shelvesMin' },
  { key: 'shelvesMax', name: 'Shelves Max', value: 'shelvesMax' },
]

export default columns
