import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import memoise from 'memoizee'

import { Heading4, Heading6, FlagWrapper, FlagBody, FlagComponent } from '@jsluna/react'
import { ArrowUp, ArrowDown } from '@jsluna/icons'
import buildShelfTitle from 'src/utils/shelfTitle'
import capitalise from 'src/utils/capitalise'
import pluralize from 'src/utils/pluralize'
import {
  getPalletOrStackType,
  isPalletOrStackLayout,
  isProduceTypeLayout,
  getProduceTableRows,
} from 'src/utils/layoutTypes'

import Cell from 'src/components/RowCell'
import HeaderCell from 'src/components/HeaderCell'
import ShelfArtifact from 'src/components/ShelfArtifacts'

import ProduceTable from 'src/modules/PsaProduceTable'

const toBoolString = bool => (bool ? 'Yes' : 'No')
const toDateString = memoise((date, format = 'D-MMM') => moment(date).format(format), { length: 2 })
const buildTitle = ({ spaceTitle, clusters }) => `${spaceTitle} - ${clusters}`

const buildShelfFill = ({ shelfFill, shelfFillType }) =>
  shelfFill && shelfFillType ? `${shelfFill} ${pluralize(shelfFill, shelfFillType)}` : ''

const getClassNames = shelf =>
  classNames('ln-c-table__row c-psa-row', {
    'ln-u-bg-color-grey-10': shelf % 2 === 0,
    'ln-u-bg-color-white': shelf % 2 === 1,
  })

const MasterPsaEntry = ({
  clusters,
  isLockedForChangeControl,
  moveProduct,
  rows,
  spaceTitle,
  spaceAssignmentComment,
  spaceAssignmentId,
  spaceInstanceComment,
  spaceInstanceId,
  spaceInstancePosComment,
  spaceInstanceShelfHeights,
  spaceInstanceSuggestedLocation,
  spaceInstanceLayoutType,
}) => {
  const hasRows = rows.length > 0

  return (
    <div className="c-psa-entry" data-id={spaceInstanceId}>
      <FlagWrapper>
        <FlagBody alignment="top">
          <Heading4>{buildTitle({ spaceTitle, clusters })}</Heading4>
          <div>
            {spaceAssignmentComment}{' '}
            {spaceInstanceComment === '' ? '' : `(${spaceInstanceComment})`}
          </div>

          <div>{spaceInstancePosComment}</div>
        </FlagBody>
        <FlagComponent alignment="top" nowrap>
          <Link
            className="ln-c-button ln-c-button--text"
            target="_blank"
            data-control="view-space-assignment"
            to={`/space-assignments/${spaceAssignmentId}`}
          >
            View Space Assignment
          </Link>{' '}
        </FlagComponent>
      </FlagWrapper>

      {hasRows ? (
        <div className="ln-c-table-container">
          <table className="ln-c-table ln-c-table--fixed ln-u-text-align-center ln-u-border-bottom">
            <thead className="ln-c-table__header">
              <tr className="ln-c-table__header-row">
                <HeaderCell className="c-master-psa-header-cell-shelf">
                  {capitalise(spaceInstanceLayoutType)}
                </HeaderCell>
                {isPalletOrStackLayout(spaceInstanceLayoutType) && (
                  <HeaderCell className="c-master-psa-header-cell-pallet-or-stack-type">
                    {`${capitalise(spaceInstanceLayoutType)} Type`}
                  </HeaderCell>
                )}
                <HeaderCell className="c-master-psa-header-cell-move" />
                <HeaderCell className="c-master-psa-header-cell-sku">SKU</HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-dept-comm">Dept/Comm</HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-buyer">Buyer</HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-description">
                  Description
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-mechanic-type">
                  Mechanic Type
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-mechanic-value">
                  Mechanic Value
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-shelf-fill">Shelf Fill</HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-pack-size">Pack Size</HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-dates">Dates</HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-price-point">
                  Price Point
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-wigig">WIGIG/Range Out</HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-promo-case-size">
                  Promo Case Size
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-rms-uplift-aisle">
                  Uplift Aisle
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-rms-uplift-plinth">
                  Uplift Plinth
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-facings-on-shelf">
                  Facings on shelf
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-ubw">UBW</HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-original-retail-price">
                  Original Retail Price
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-clearance-price">
                  Clearance Price
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-special-buy">
                  Special buy
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-percent-sold-promo-price">
                  % Sold at Promo Price
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-percent-sold-clearance-price">
                  % Sold at Clearance Price
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-sales-forecast-promo-cycle">
                  Forecasted Sales for Cycle
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-sales-forecast-line">
                  Forecasted Sales for Line
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-soa-per-unit">
                  SOA per Unit
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-bulk-support">
                  Bulk Support
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-forecast-purchase-quantity">
                  Forecast Purchase Quantity
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-npp-required">
                  NPP Required
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-npp-in-store-date">
                  NPP In Store Date
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-stock-in-shipper-fdsu">
                  Stock in Shipper/FSDU
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-stock-value-promo">
                  Stock Value Bought for Promo (Full Retail)
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-sales-phase-weeks">
                  Sales Phasing Weeks
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-jda-actioned">
                  JDA Actioned
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-store-comment">
                  Store Comment
                </HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-clusters">Clusters</HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-pos-type">POS Type</HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-pa-code">PA Code</HeaderCell>
                <HeaderCell className="c-master-psa-header-cell-pos-description">
                  POS Description
                </HeaderCell>
              </tr>
            </thead>
            <tbody className="ln-c-table__body">
              {rows.map((row, i) => {
                const { type } = row

                if (type === 'shelves-products') {
                  const {
                    startDate,
                    endDate,
                    overrideStartDate,
                    overrideEndDate,
                    buyer,
                    shelf,
                  } = row
                  const productStartDate = overrideStartDate || startDate
                  const productEndDate = overrideEndDate || endDate
                  const shelfTitle = buildShelfTitle(
                    row.shelfIndex,
                    row.shelvesLength,
                    row.isShelfRsb,
                    spaceInstanceLayoutType
                  )

                  return (
                    <tr key={row.shelfProductId} className={getClassNames(row.shelfIndex)}>
                      <Cell>{shelfTitle}</Cell>
                      {isPalletOrStackLayout(spaceInstanceLayoutType) && (
                        <Cell label="pallet-or-stack-type">
                          {getPalletOrStackType(spaceInstanceLayoutType, shelf.palletOrStackType)}
                        </Cell>
                      )}
                      <Cell>
                        {!row.isFirstOnShelf && (
                          <button
                            className="c-icon-button u-bg-none"
                            onClick={() =>
                              moveProduct(
                                row.shelfProductId,
                                row.shelf,
                                row.shelfItemIndex,
                                row.shelfItemIndex - 1
                              )
                            }
                            data-control="move-up"
                            type="button"
                            disabled={isLockedForChangeControl}
                          >
                            <ArrowUp />
                          </button>
                        )}
                        {!row.isLastOnShelf && (
                          <button
                            className="c-icon-button u-bg-none"
                            onClick={() =>
                              moveProduct(
                                row.shelfProductId,
                                row.shelf,
                                row.shelfItemIndex,
                                row.shelfItemIndex + 1
                              )
                            }
                            data-control="move-down"
                            type="button"
                            disabled={isLockedForChangeControl}
                          >
                            <ArrowDown />
                          </button>
                        )}
                      </Cell>
                      <Cell id={`sku-${i}`}>
                        {row.sku}
                        {row.oldProduct && ` (${row.oldProduct.sku || ''})`}
                      </Cell>
                      <Cell>
                        {row.deptComm}
                        {row.oldProduct && ` (${row.oldProduct.deptComm || ''})`}
                      </Cell>
                      <Cell>{buyer}</Cell>
                      <Cell>{row.description}</Cell>
                      <Cell>{row.mechanic}</Cell>
                      <Cell>{row.mechanicText}</Cell>
                      <Cell>{buildShelfFill(row)}</Cell>
                      <Cell>{row.packSize}</Cell>
                      <Cell>
                        {toDateString(productStartDate)} - {toDateString(productEndDate)}
                      </Cell>
                      <Cell>{row.pricePoint}</Cell>
                      <Cell>{toBoolString(row.wigig)}</Cell>
                      <Cell>{row.promoCaseSize}</Cell>
                      <Cell>{row.percentUpliftAisle}</Cell>
                      <Cell>{row.percentUpliftPlinth}</Cell>
                      <Cell>{row.facingsOnShelf}</Cell>
                      <Cell>{row.ubw}</Cell>
                      <Cell>{row.originalRetailPrice}</Cell>
                      <Cell>{row.clearancePrice}</Cell>
                      <Cell>{toBoolString(row.specialBuy)}</Cell>
                      <Cell>{row.percentSoldPromoPrice}</Cell>
                      <Cell>{row.percentSoldClearancePrice}</Cell>
                      <Cell>{row.salesForecastPromoCycle}</Cell>
                      <Cell>{row.salesForecastLine}</Cell>
                      <Cell>{row.soaPerUnit}</Cell>
                      <Cell>{row.bulkSupport}</Cell>
                      <Cell>{row.forecastPurchaseQuantity}</Cell>
                      <Cell>{toBoolString(row.nppRequired)}</Cell>
                      <Cell>{row.nppInStoreDate}</Cell>
                      <Cell>{toBoolString(row.stockInShipperFdsu)}</Cell>
                      <Cell>{row.stockValueForPromo}</Cell>
                      <Cell id={`sales-phase-weeks-${i}`}>{row.salesPhaseWeeks || ''}</Cell>
                      <Cell>{toBoolString(row.jdaActioned)}</Cell>
                      <Cell>{row.storeComment}</Cell>
                      <Cell>{row.clusters}</Cell>
                      <Cell label="pos-type">{row.pointOfSaleAdvice?.type}</Cell>
                      <Cell label="pa-code">{row.pointOfSaleAdvice?.paCode}</Cell>
                      <Cell label="pos-description">{row.pointOfSaleAdvice?.posDescription}</Cell>
                    </tr>
                  )
                }

                return (
                  <ShelfArtifact
                    key={row.id}
                    artifact={row}
                    columnSpan={39}
                    shelfPosition={row.shelfIndex}
                    shelfIsRsb={row.isShelfRsb}
                    shelvesLength={row.shelvesLength}
                    layoutType={spaceInstanceLayoutType}
                  />
                )
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p>There are currently no products in this space.</p>
      )}

      {isProduceTypeLayout(spaceInstanceLayoutType) && rows.length > 0 && (
        <>
          <Heading6 className="ln-u-push-top">
            {`Promotional Space Advice Commencing ${toDateString(
              rows[0].startDate,
              'D-MMM-YY'
            )} - PRODUCE TABLE PLAN`}
          </Heading6>
          <div className="ln-u-push-bottom-sm">{spaceInstancePosComment}</div>
          <div className="ln-c-table-container" data-control="produce-table">
            <ProduceTable
              productRows={rows}
              rows={getProduceTableRows(spaceInstanceLayoutType)}
              columns={4}
            />
          </div>
        </>
      )}

      <div className="ln-u-push-top">{spaceInstanceShelfHeights}</div>
      {spaceInstanceSuggestedLocation && (
        <div data-control="suggested-location" className="ln-push-top">
          <div className="ln-c-label ln-u-soft-right">Suggested Location </div>
          <span>{spaceInstanceSuggestedLocation}</span>
        </div>
      )}
    </div>
  )
}

MasterPsaEntry.propTypes = {
  clusters: PropTypes.string.isRequired,
  isLockedForChangeControl: PropTypes.bool.isRequired,
  moveProduct: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      clusters: PropTypes.string,
      description: PropTypes.string,
      endDate: PropTypes.string.isRequired,
      isFirstOnShelf: PropTypes.bool,
      isLastOnShelf: PropTypes.bool,
      isShelfRsb: PropTypes.bool.isRequired,
      mechanic: PropTypes.string,
      mechanicText: PropTypes.string,
      packSize: PropTypes.number,
      shelfFill: PropTypes.number,
      shelfFillType: PropTypes.string,
      shelfIndex: PropTypes.number.isRequired,
      shelfProductLayouts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          index: PropTypes.number,
          location: PropTypes.string,
          position: PropTypes.number,
          shelfProduct: PropTypes.shape({
            id: PropTypes.string,
          }),
        })
      ),
      shelvesLength: PropTypes.number.isRequired,
      sku: PropTypes.string,
      startDate: PropTypes.string.isRequired,
      pointOfSaleAdvice: PropTypes.shape({
        type: PropTypes.string,
        paCode: PropTypes.string,
        description: PropTypes.string,
      }),
    })
  ).isRequired,
  spaceInstanceId: PropTypes.string.isRequired,
  spaceTitle: PropTypes.string.isRequired,
  spaceAssignmentComment: PropTypes.string.isRequired,
  spaceAssignmentId: PropTypes.string.isRequired,
  spaceInstanceComment: PropTypes.string.isRequired,
  spaceInstancePosComment: PropTypes.string.isRequired,
  spaceInstanceShelfHeights: PropTypes.string.isRequired,
  spaceInstanceSuggestedLocation: PropTypes.string,
  spaceInstanceLayoutType: PropTypes.string,
}

MasterPsaEntry.defaultProps = {
  spaceInstanceLayoutType: null,
  spaceInstanceSuggestedLocation: null,
}

export default MasterPsaEntry
export { toDateString }
