import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetSpaceAssignmentByCycleId } from 'src/api/SpaceAssignment'

const NAMESPACE = 'CycleSpaceAssignments'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = thunkName => `${STORE_PATH}/${thunkName}`

const fetchSpaceAssignments = createAsyncThunk(
  buildThunkPrefix('fetchSpaceAssignments'),
  async (cycleId, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const fetchSpaceAssignmentInstance = new GetSpaceAssignmentByCycleId(store, {
      params: { id: cycleId },
    })

    const response = await handleCreateAsyncThunkResult(
      fetchSpaceAssignmentInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)
const spaceAssignmentsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSpaceAssignments.pending, pendingDataHandler)
    builder.addCase(fetchSpaceAssignments.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpaceAssignments.rejected, rejectedDataHandler)
  },
})

const cycleSpaceAssignmentReducer = spaceAssignmentsSlice.reducer

export { fetchSpaceAssignments, cycleSpaceAssignmentReducer, spaceAssignmentsSlice }
