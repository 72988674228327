import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Section, Card, List, ListItem, FlagWrapper, FlagBody, FlagComponent } from '@jsluna/react'

import sortNatural from 'src/utils/sortNatural'

import LoadingCard from 'src/components/LoadingCard'
import APIFailed from 'src/components/Errors/APIFailed'
import { SPACE_MULTISELECT } from 'src/constants/spacePlanner'
import MultiAutoCompleteField from './MultiAutoCompleteField'
import Search from './Search'
import QuickCategoryFilter from './QuickCategoryFilter'
import SpaceFilter from './SpaceFilter'
import SpaceFilterType from './SpaceFilterType'
import Timeline from './Timeline'
import { CoverageOverall } from './Coverage'
import { changeSelectedGroup } from './store/reducer/spacePlanAssignments'

import {
  selectRoot,
  selectGroupsBySpacePlan,
  fetchSpacePlanCyclesAssignments,
  selectSpacePlanCyclesAssignmentsStatus,
  selectSpacePlanDetails,
  selectGroupSelected,
} from './store'
import {
  selectTimeline,
  selectSpaceFilterType,
  selectSpaceMultiSelect,
  selectDates,
} from './store/selectors'
import {
  setSpaceFilterType as setSpaceFilterTypeAction,
  saveSpaceMultiselect,
  saveSpaceSearch,
} from './store/actions'

import GroupDropDown from './GroupDropDown'

const SpacePlanner = props => {
  const {
    returnTo,
    timeline,
    allSpaces,
    setSpaceFilterType,
    spaceFilterType,
    searchedSpaces,
    groupList,
    onChange,
    isSpacePlanAssignmentsLoading,
    spacePlanAssignmentsFetchError,
    spacePlanDuration,
    id: spacePlanId,
    selectedGroup,
  } = props
  const {
    filter: { categories, categorySelectionType, spaces },
  } = timeline
  const { startDate, endDate } = spacePlanDuration

  const showGroupInfo = groupList.length > 0

  const toOption = ({ id, title }) => ({ id, label: title, value: id })
  const sorted = sortNatural([...allSpaces], 'title')
  const options = sorted.map(toOption)

  const onGroupChange = event => {
    onChange(event.target.value, spacePlanId)
  }
  const groupToOptions = ({ id, title }) => ({ id, label: title, value: id })
  const groupOptions = groupList.map(groupToOptions)

  return (
    <Section>
      <Card className="ln-u-hard-bottom">
        <FlagWrapper>
          <FlagComponent alignment="top">
            <SpaceFilterType
              type={spaceFilterType}
              onChange={e => {
                setSpaceFilterType(e.target.value)
              }}
            />
          </FlagComponent>
          <FlagBody className="ln-u-soft-left ">
            <FlagWrapper>
              <FlagBody className=" ln-u-soft-right">
                {spaceFilterType === SPACE_MULTISELECT ? (
                  <MultiAutoCompleteField
                    options={options}
                    defaultSelectedOptions={searchedSpaces}
                  />
                ) : (
                  <Search placeholder="Search Spaces" data-control="space-search" />
                )}
                <List type="matrix" spaced className="ln-u-soft-top ">
                  <ListItem type="matrix">
                    <QuickCategoryFilter
                      categoriesLength={categories && categories.length}
                      categorySelectionType={categorySelectionType}
                    />
                  </ListItem>
                  <ListItem type="matrix">
                    <SpaceFilter {...spaces} />
                  </ListItem>
                  {showGroupInfo && (
                    <ListItem type="matrix" alignment="right">
                      <GroupDropDown
                        options={groupOptions}
                        value={selectedGroup?.id}
                        onChange={event => {
                          onGroupChange(event)
                        }}
                      />
                    </ListItem>
                  )}
                </List>
                {showGroupInfo && isSpacePlanAssignmentsLoading && <LoadingCard />}
              </FlagBody>
              <FlagComponent alignment="top">
                <CoverageOverall timeline={timeline} />
              </FlagComponent>
            </FlagWrapper>
          </FlagBody>
        </FlagWrapper>
      </Card>
      <Card className="ln-u-hard">
        {!isSpacePlanAssignmentsLoading &&
          (showGroupInfo && spacePlanAssignmentsFetchError?.status ? (
            <APIFailed />
          ) : (
            <Timeline
              timeline={timeline}
              startDate={startDate}
              endDate={endDate}
              returnTo={returnTo}
            />
          ))}
      </Card>
    </Section>
  )
}

SpacePlanner.propTypes = {
  allSpaces: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  spacePlanDuration: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }).isRequired,
  returnTo: PropTypes.string.isRequired,
  timeline: PropTypes.shape({
    numOfSpacesShown: PropTypes.number.isRequired,
    numOfSpacesTotal: PropTypes.number.isRequired,
    filter: PropTypes.shape({
      categories: PropTypes.arrayOf(PropTypes.string),
      categorySelectionType: PropTypes.string,
      spaces: PropTypes.shape({}),
    }),
  }).isRequired,
  spaceFilterType: PropTypes.string.isRequired,
  setSpaceFilterType: PropTypes.func.isRequired,
  searchedSpaces: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  groupList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  selectedGroup: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  isSpacePlanAssignmentsLoading: PropTypes.bool,
  spacePlanAssignmentsFetchError: PropTypes.shape({ status: PropTypes.number }),
  id: PropTypes.string.isRequired,
}

SpacePlanner.defaultProps = {
  searchedSpaces: [],
  groupList: [],
  selectedGroup: null,
  isSpacePlanAssignmentsLoading: false,
  spacePlanAssignmentsFetchError: null,
}

const mapStateToProps = (state, ownProps) => {
  const groupList = selectGroupsBySpacePlan(state) || []
  const getSpacePlanAssignmentsStatus = selectSpacePlanCyclesAssignmentsStatus(state) || []
  const isSpacePlanAssignmentsLoading = getSpacePlanAssignmentsStatus?.isFetching
  const spacePlanAssignmentsFetchError = getSpacePlanAssignmentsStatus?.error
  const selectedGroup = selectGroupSelected(state) || {}
  return {
    timeline: selectTimeline(state, ownProps),
    spacePlanDuration: selectDates(state),
    allSpaces: selectSpacePlanDetails(state),
    spaceFilterType: selectSpaceFilterType(state),
    searchedSpaces: selectSpaceMultiSelect(state),
    groupList,
    isSpacePlanAssignmentsLoading,
    spacePlanAssignmentsFetchError,
    selectedGroup,
  }
}

const mapDispatchToProps = dispatch => ({
  setSpaceFilterType: spaceFilterType => {
    dispatch(setSpaceFilterTypeAction(spaceFilterType))

    if (spaceFilterType === SPACE_MULTISELECT) {
      dispatch(saveSpaceSearch(''))
    } else {
      dispatch(saveSpaceMultiselect([]))
    }
  },
  onChange: async (groupId, spacePlanId) => {
    dispatch(saveSpaceSearch(''))
    dispatch(saveSpaceMultiselect([]))
    dispatch(changeSelectedGroup(groupId))
    await dispatch(fetchSpacePlanCyclesAssignments({ spacePlanId, groupId }))
  },
})

const options = {
  areStatesEqual: (next, prev) => selectRoot(next) === selectRoot(prev),
}

export default connect(mapStateToProps, mapDispatchToProps, null, options)(SpacePlanner)
