import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { Pagination as LunaPagination } from '@jsluna/react'
import Body from './Body'
import Head from './Head'
import Search from './Search'

const filterRows = (rows, pageSize, page = 1) =>
  rows.filter((_, index) => index >= (page - 1) * pageSize && index < page * pageSize)

const setPageUrl = page => {
  if (window.history.pushState) {
    window.history.pushState(null, null, `#${page}`)
  } else {
    window.location.hash = page.toString()
  }
}

const getPage = (rows, page, pageSize, e, setPagination, setPaginatedRows, pagination) => {
  e.preventDefault()

  if (pagination.page === page) return

  setPagination({ page, total: Math.ceil(rows.length / pageSize) })
  setPaginatedRows(filterRows(rows, pageSize, page))
  setPageUrl(page)
}

function Table({
  onSearchChange,
  type,
  columns,
  rows,
  searchEnabled,
  paginationEnabled,
  search,
  pageSize,
}) {
  const [pagination, setPagination] = useState({
    page: 1,
    total: Math.ceil(rows.length / pageSize),
  })
  const [paginatedRows, setPaginatedRows] = useState(
    paginationEnabled ? filterRows(rows, pageSize) : rows
  )
  const searchTerm = useRef(search)

  useEffect(() => {
    let filtered

    if (!paginationEnabled) {
      filtered = rows
    } else {
      const pageHash =
        window.location &&
        window.location?.hash !== '' &&
        parseInt(window.location.hash.substring(1), 10)

      let pageNumber = pageHash || pagination.page

      if (searchTerm.current !== search) {
        searchTerm.current = search
        pageNumber = 1
        setPageUrl(1)
      }

      setPagination({ page: pageNumber, total: Math.ceil(rows.length / pageSize) })
      filtered = filterRows(rows, pageSize, pageNumber)
    }

    setPaginatedRows(filtered)
  }, [rows])

  return (
    <div>
      {searchEnabled && <Search onSearchChange={onSearchChange} quantity={rows.length} />}
      <table className="ln-c-table ln-c-table--responsive@md ln-c-table--sorted">
        <Head type={type} columns={columns} />
        <Body rows={paginatedRows} />
      </table>
      {paginationEnabled && (
        <div className="c-pagination u-print-hidden">
          <LunaPagination
            current={pagination.page}
            total={pagination.total}
            onChange={(page, e) =>
              getPage(rows, page, pageSize, e, setPagination, setPaginatedRows, pagination)
            }
            showFirstAndLast
            showPages
          />
        </div>
      )}
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  onSearchChange: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  searchEnabled: PropTypes.bool,
  paginationEnabled: PropTypes.bool,
  search: PropTypes.string,
  type: PropTypes.string.isRequired,
  pageSize: PropTypes.number,
}

Table.defaultProps = {
  columns: [],
  rows: [],
  search: '',
  searchEnabled: false,
  paginationEnabled: false,
  pageSize: 20,
}

export default Table
