import PropTypes from 'prop-types'
import React from 'react'
import { saveAs } from 'file-saver'

const withExport = ComposedComponent => {
  const wrappedComponentName =
    ComposedComponent.displayName || ComposedComponent.name || 'Component'

  const WithExport = ({ getExportsData, ...rest }) => {
    return (
      <ComposedComponent
        {...rest}
        onClick={async () => {
          const { body, fileName } = (await getExportsData()) || {}
          if (body && fileName) saveAs(body, fileName)
        }}
      />
    )
  }

  WithExport.displayName = `WithExport(${wrappedComponentName})`

  WithExport.propTypes = {
    getExportsData: PropTypes.func.isRequired,
  }

  return WithExport
}

export default withExport
