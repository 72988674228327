import thunk from 'redux-thunk'
import { browserHistory } from 'react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'

import enableDevTools from 'src/utils/enableDevTools'
import api from './api/middleware'
import stubApi from './stubApi/middleware'
import unauthorised from './data/middleware/unauthorised'
import notifications from './notifications/middleware'
import rootReducer from './rootReducer'

const buildStore = initialState => {
  const routing = routerMiddleware(browserHistory)
  const middlewares = [thunk, api, unauthorised, notifications, routing]

  if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
    middlewares.splice(1, 0, stubApi)
  }

  const composeEnhancers =
    (enableDevTools(window?.PROMCOMM?.ENVIRONMENT) &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

  const middleware = composeEnhancers(applyMiddleware(...middlewares))

  const state = {
    ...initialState,
  }

  const store = createStore(rootReducer(), state, middleware)

  return store
}

export default buildStore
