import { SAVE_CATEGORY_FILTER } from '../../actions'

const initialState = null

const reducer = (state = initialState, { type, payload } = {}) => {
  if (type === SAVE_CATEGORY_FILTER) return payload.categories

  return state
}

export default reducer
