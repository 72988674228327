import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { UNASSIGNED_ROLE_ID } from 'src/constants/roles'
import PermissionDenied from 'src/components/Errors/PermissionDenied'
import Unassigned from 'src/modules/Unassigned'
import { selectSelfRoleId } from 'src/store/data/selectors'

export const FailureComponent = ({ roleId }) =>
  !roleId || roleId === UNASSIGNED_ROLE_ID ? <Unassigned /> : <PermissionDenied />

FailureComponent.propTypes = {
  roleId: PropTypes.string,
}

FailureComponent.defaultProps = {
  roleId: null,
}

const mapStateToProps = state => ({
  roleId: selectSelfRoleId(state),
})

export default connect(mapStateToProps)(FailureComponent)
