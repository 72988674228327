import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import { FilledButton, List, ListItem } from '@jsluna/react'
import { DateRangePicker } from 'src/components/FormikComponents'
import { withFormik } from 'formik'

import Pagination from 'src/components/Pagination'
import withExport from 'src/hoc/withExport'
import Psa from 'src/components/Psa'

const ChangeStoreButton = ({ cycleId, businessUnit }) => (
  <FilledButton color="dark" element={<Link to={`/cycles/${businessUnit}/${cycleId}/store-psa`} />}>
    Change store
  </FilledButton>
)

ChangeStoreButton.propTypes = {
  cycleId: PropTypes.string.isRequired,
  businessUnit: PropTypes.oneOf(['food', 'non-food']).isRequired,
}

const ExportButton = withExport(FilledButton)

const StorePsaComponent = ({
  cycleId,
  groups,
  getPage,
  pagination,
  isFull,
  businessUnit,
  downloadPsa,
  storePSAFlag,
  selectedStartDate,
  selectedEndDate,
  onChangeDate,
}) => {
  const hasGroups = !!groups.length

  return (
    <div>
      <div className="ln-u-soft-bottom-lg ln-u-display-flex ln-u-justify-content-flex-end">
        {!isFull ? (
          <List spaced type="inline">
            {businessUnit === 'food' && (
              <ListItem type="inline">
                <DateRangePicker
                  label="Date"
                  name="date"
                  startDate={selectedStartDate}
                  endDate={selectedEndDate}
                  onDatesChange={onChangeDate}
                  numberOfMonths={2}
                  required
                />
              </ListItem>
            )}
            <ListItem type="inline">
              <ChangeStoreButton cycleId={cycleId} businessUnit={businessUnit} />
            </ListItem>
            <ListItem type="inline">
              <ExportButton
                getExportsData={() => downloadPsa(selectedStartDate, selectedEndDate)}
                data-control="download-psa"
              >
                Download PDF
              </ExportButton>
            </ListItem>
            <ListItem type="inline">
              <Pagination getPage={getPage} pagination={pagination} />
            </ListItem>
          </List>
        ) : (
          <ChangeStoreButton cycleId={cycleId} businessUnit={businessUnit} />
        )}
      </div>
      {hasGroups ? (
        groups.map(entry => <Psa key={entry.id} storePSAFlag={storePSAFlag} {...entry} />)
      ) : (
        <p className="ln-u-flush-bottom">There are currently no PSA entries</p>
      )}
    </div>
  )
}

StorePsaComponent.propTypes = {
  cycleId: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  getPage: PropTypes.func.isRequired,
  downloadPsa: PropTypes.func.isRequired,
  pagination: PropTypes.shape({}).isRequired,
  isFull: PropTypes.bool.isRequired,
  businessUnit: PropTypes.oneOf(['food', 'non-food']).isRequired,
  storePSAFlag: PropTypes.bool.isRequired,
  selectedStartDate: PropTypes.string,
  selectedEndDate: PropTypes.string,
  onChangeDate: PropTypes.func,
}

StorePsaComponent.defaultProps = {
  selectedStartDate: null,
  selectedEndDate: null,
  onChangeDate: () => null,
}

const wrappedForm = withFormik({ handleSubmit: () => null })(StorePsaComponent)

export default wrappedForm
