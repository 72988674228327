import React from 'react'
import PropTypes from 'prop-types'

import { Heading6 } from '@jsluna/react'
import { getProduceTableRows } from 'src/utils/layoutTypes'
import formatDate from 'src/utils/formatDate'

import ProduceTable from '../PsaProduceTable'

import ShelfLayout from './ShelfLayout'

const ProduceLayout = props => {
  const { rows, posComment, layouts, layoutType, storePSAFlag } = props
  const shelfProducts = rows.map(shelfProduct => {
    return {
      shelfProductId: shelfProduct.id,
      ...shelfProduct,
    }
  })

  const shelfProductDetails = storePSAFlag ? shelfProducts : rows

  const rowsWithLayouts = shelfProductDetails.map(row => {
    const curr = row
    curr.shelfProductLayouts = layouts.map(
      ({ index: layoutIndex, position: layoutPosition, shelfProductId }) => {
        return {
          index: layoutIndex,
          position: layoutPosition,
          shelfProduct: {
            id: shelfProductId,
          },
        }
      }
    )

    return curr
  })

  return (
    <>
      <ShelfLayout {...props} />
      <Heading6 className="ln-u-push-top">
        {`Promotional Space Advice Commencing ${formatDate(
          rowsWithLayouts[0].startDate,
          'D-MMM'
        )} - PRODUCE TABLE PLAN`}
      </Heading6>
      <div className="ln-u-push-bottom-sm">{posComment}</div>
      <div className="ln-c-table-container" data-control="produce-table">
        <ProduceTable
          productRows={rowsWithLayouts}
          rows={getProduceTableRows(layoutType)}
          columns={4}
        />
      </div>
    </>
  )
}

ProduceLayout.propTypes = {
  layoutType: PropTypes.string.isRequired,
  layouts: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      position: PropTypes.number,
      shelfProductId: PropTypes.string,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      shelf: PropTypes.number,
      shelfPosition: PropTypes.number,
      sku: PropTypes.string.isRequired,
      description: PropTypes.string,
      shelfFill: PropTypes.number,
      shelfFillType: PropTypes.string,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      isShelfRsb: PropTypes.bool,
      facingsOnShelf: PropTypes.number,
      storeComment: PropTypes.string,
    })
  ).isRequired,
  space: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    produceTableLayout: PropTypes.bool,
    spaceType: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  shelvesLength: PropTypes.number.isRequired,
  posComment: PropTypes.string.isRequired,
  storePSAFlag: PropTypes.bool.isRequired,
}

export default ProduceLayout
