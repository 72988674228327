/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import PropTypes from 'prop-types'

const Background = ({ onClick }) => (
  <button
    className="c-split-button__background"
    data-control="split-button-background"
    onClick={onClick}
    type="button"
  />
)

Background.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default Background
