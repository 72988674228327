import GetCycles from 'src/api/Cycle/GetCycles'

class UpdateCycleById extends GetCycles {
  requestUrl = `/api/Cycle/${this.getParams().id}`

  method = 'PATCH'

  formatRequestData = params => params.cycle
}

export default UpdateCycleById
