export default function validate({ description, isSubmitting }) {
  const errors = {}

  if (!description.trim()) {
    if (isSubmitting) {
      errors.description = 'A description is required'
    }
  }

  return errors
}
