import React from 'react'
import PropTypes from 'prop-types'

import { Heading3, Heading4 } from '@jsluna/react'
import { LAYOUT_TYPES } from 'src/constants/layoutTypes'
import { checkLayoutTypes, isClipStrip, isPallet, isStack } from 'src/utils/layoutTypes'

import getComponentForLayoutType from './layouts'

const buildStorePSATitle = ({ spaceTitle, storeTitleComment }) =>
  storeTitleComment ? `${spaceTitle} - ${storeTitleComment}` : `${spaceTitle}`

const StorePsaEntry = props => {
  const {
    spaceTitle,
    storeTitleComment,
    rows,
    posComment,
    shelfHeights,
    suggestedLocation,
    index,
    psaGroupTitle,
    layoutType,
  } = props
  const Component = getComponentForLayoutType(layoutType)
  const showSuggestedLocation = checkLayoutTypes([isClipStrip, isPallet, isStack])
  return (
    <div className="c-psa-entry">
      {index === 0 && <Heading3>{psaGroupTitle}</Heading3>}

      <Heading4>{buildStorePSATitle({ spaceTitle, storeTitleComment })}</Heading4>
      <div>{posComment}</div>
      {rows.length > 0 ? <Component {...props} /> : <p>No products</p>}

      {showSuggestedLocation(layoutType) ? (
        <div data-control="suggested-location" className="ln-push-top">
          <div className="ln-c-label ln-u-soft-right">Suggested Location </div>
          <span>{suggestedLocation}</span>
        </div>
      ) : (
        <div data-control="shelf-heights" className="ln-u-push-top">
          {shelfHeights}
        </div>
      )}
    </div>
  )
}

StorePsaEntry.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      shelf: PropTypes.number,
      shelfPosition: PropTypes.number,
      sku: PropTypes.string.isRequired,
      description: PropTypes.string,
      shelfFill: PropTypes.number,
      shelfFillType: PropTypes.string,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      isShelfRsb: PropTypes.bool,
      facingsOnShelf: PropTypes.number,
      storeComment: PropTypes.string,
      pointOfSaleAdvice: PropTypes.shape({
        type: PropTypes.string,
        paCode: PropTypes.string,
        description: PropTypes.string,
      }),
    })
  ).isRequired,
  posComment: PropTypes.string.isRequired,
  shelfHeights: PropTypes.string,
  shelvesLength: PropTypes.number.isRequired,
  suggestedLocation: PropTypes.string,
  index: PropTypes.number.isRequired,
  psaGroupTitle: PropTypes.string.isRequired,
  layouts: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      position: PropTypes.number,
      shelfProductId: PropTypes.string,
    })
  ),
  layoutType: PropTypes.oneOf(LAYOUT_TYPES).isRequired,
  spaceTitle: PropTypes.string.isRequired,
  storeTitleComment: PropTypes.string.isRequired,
}

StorePsaEntry.defaultProps = {
  shelfHeights: '',
  suggestedLocation: '',
  layouts: [],
}

export default StorePsaEntry
