import { createSelector } from 'reselect'

import { selectUi } from '..'

const identity = v => v

const selectSpaceMultiSelect = createSelector(selectUi, state => state.multiselect)

const selectModule = state => state.modules.ShipperCompliance
const selectSpaceAssignmentWithShipperCompliances = createSelector(
  selectModule,
  module => module.ShipperComplianceDetails.data || []
)

const selectAllSpaces = createSelector(
  selectSpaceAssignmentWithShipperCompliances,
  spaceAssignments =>
    spaceAssignments.map(({ spaceId, spaceName }) => ({ id: spaceId, title: spaceName })) || []
)

const selectFilteredSpaceAssignments = state => {
  const spaceAssignments = selectSpaceAssignmentWithShipperCompliances(state)
  const searchSpaces = selectSpaceMultiSelect(state)

  const filterOnSpaceIds = s => {
    const searchedIds = searchSpaces.map(({ id }) => id)

    return searchedIds.includes(s.spaceId)
  }

  const filterOnSelectedSearch = ({ spaceId }) => {
    const filterSpaceBy = () => {
      if (searchSpaces.length) {
        return filterOnSpaceIds
      }

      return identity
    }

    const spacesFilteredOnSearch = spaceAssignments.filter(filterSpaceBy())
    const spacesIds = spacesFilteredOnSearch.map(space => space.spaceId)
    const intersection = spacesIds.filter(id => spaceId === id)

    if (intersection.length > 0) {
      return true
    }

    return false
  }

  const spaceAssignmentsFilteredOnSearch = spaceAssignments.filter(filterOnSelectedSearch)

  return spaceAssignmentsFilteredOnSearch
}

const selectSpaceAssignments = createSelector(
  selectFilteredSpaceAssignments,
  spaceAssignmentsFilteredOnSearch => ({
    spaceAssignments: spaceAssignmentsFilteredOnSearch,
  })
)

export { selectAllSpaces, selectSpaceAssignments, selectSpaceMultiSelect }
