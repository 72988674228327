import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { selectSpaceGroupByIdInfo } from '../store'

import Form from './Form'

export const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps
  const spaceGroup = selectSpaceGroupByIdInfo(state, id) || {}
  const { description = '', position = 0, title = '' } = spaceGroup
  return {
    ...spaceGroup,
    id,
    originalTitle: title,
    description,
    position: String(position),
    title,
  }
}

const returnAction = () => push('/spaces/groups')
const mapDispatchToProps = dispatch => ({
  afterSubmit: () => dispatch(returnAction()),
  cancel: () => dispatch(returnAction()),
  submit: action => dispatch(action),
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
