import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { SingleDatePicker } from '@jsluna/react'

import { matchMedia } from 'src/utils/window'
import { DATE_FORMAT } from 'src/utils/datePeriod'

import 'react-dates/initialize' // eslint-disable-line

export default class DatePicker extends Component {
  constructor(props) {
    super(props)

    this.state = { focused: false }
    this.onBlur = this.onBlur.bind(this)
    this.onFocus = this.onFocus.bind(this)
  }

  onBlur() {
    this.setState({ focused: false })
    const {
      field: { name },
      form: { setFieldTouched },
    } = this.props
    setFieldTouched(name, true, true)
  }

  onFocus() {
    this.setState({ focused: true })
  }

  render() {
    const {
      field: { name, value },
      form: { errors, touched, setFieldValue },
      format,
      displayFormat,
      minDate,
      maxDate,
      disabled,
      required,
      displayMonth,
      outOfRange,
      onCustomDateChange,
    } = this.props

    const { focused } = this.state
    const error = touched[name] && errors[name]

    const isOutsideRange = date =>
      !moment(date).isBetween(minDate || '1970-01-01', maxDate || '9999-12-31', 'day', '[]') ||
      outOfRange(date)

    const initialVisibleMonth = () =>
      (value && moment(value)) ||
      (minDate && moment(minDate)) ||
      (displayMonth && moment(displayMonth)) ||
      moment()
    const onFocusChange = event => (event.focused ? this.onFocus(event) : this.onBlur(event))
    const onDateChange = date => {
      const selectedDateByUser = moment(date).format(format)
      onCustomDateChange(selectedDateByUser)
      return date && setFieldValue(name, selectedDateByUser)
    }
    const withPortal = !matchMedia('(min-width: 640px)')

    return (
      <div className={classNames('ln-c-form-group', { 'has-error': error })}>
        {error && <p className="ln-c-field-info ln-c-field-info--error">{error}</p>}
        <div className="c-datepicker">
          <SingleDatePicker
            id={name}
            displayFormat={displayFormat}
            date={value ? moment(value) : null}
            isOutsideRange={isOutsideRange}
            onDateChange={onDateChange}
            onFocusChange={onFocusChange}
            numberOfMonths={1}
            keepOpenOnDateSelect={false}
            initialVisibleMonth={initialVisibleMonth}
            placeholder={displayMonth && moment(displayMonth).format(DATE_FORMAT)}
            withPortal={withPortal}
            focused={focused}
            disabled={disabled}
            required={required}
            block
            noBorder
          />
        </div>
      </div>
    )
  }
}

DatePicker.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  format: PropTypes.string,
  displayFormat: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  displayMonth: PropTypes.string,
  outOfRange: PropTypes.func,
  onCustomDateChange: PropTypes.func,
}

DatePicker.defaultProps = {
  disabled: undefined,
  required: undefined,
  format: 'YYYY-MM-DD',
  displayFormat: 'Do MMM Y',
  minDate: null,
  maxDate: null,
  displayMonth: null,
  outOfRange: () => false,
  onCustomDateChange: () => null,
}
