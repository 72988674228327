import React from 'react'
import { Link } from 'react-router'
import { Card, Heading2, FlagWrapper, FlagBody, FlagComponent } from '@jsluna/react'

import { CREATE_SPACE_PLANS } from 'src/constants/permissions'

import Section from 'src/components/Section'
import Table from 'src/components/Table'
import WithRequiredPermissions from 'src/components/WithRequiredPermissions'

import { selectSpacePlan } from './store'
import columns from './columns'

const SpacePlans = () => (
  <Section>
    <FlagWrapper>
      <FlagBody className="ln-u-soft-right">
        <Heading2 data-control="space-plans-title">Space Plans</Heading2>
      </FlagBody>
      <FlagComponent alignment="top" nowrap>
        <WithRequiredPermissions permission={CREATE_SPACE_PLANS}>
          <Link className="ln-c-button ln-c-button--outlined" to="/space-plans/create">
            Create
          </Link>
        </WithRequiredPermissions>
      </FlagComponent>
    </FlagWrapper>
    <Card>
      <Table type="spacePlans" searchEnabled columns={columns} dataSelector={selectSpacePlan} />
    </Card>
  </Section>
)

export default SpacePlans
