import Proptypes from 'prop-types'

const cycleType = Proptypes.shape({
  businessUnit: Proptypes.string.isRequired,
  changeControlStatus: Proptypes.string.isRequired,
  createdAt: Proptypes.string.isRequired,
  cycleNumber: Proptypes.number.isRequired,
  endDate: Proptypes.string.isRequired,
  id: Proptypes.string.isRequired,
  startDate: Proptypes.string.isRequired,
  status: Proptypes.string.isRequired,
  title: Proptypes.string.isRequired,
  type: Proptypes.string.isRequired,
}).isRequired

export default Proptypes.shape({
  ...cycleType,
  next: cycleType,
  previous: cycleType,
})
