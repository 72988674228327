import { SHELF_ARTIFACTS_TYPE } from 'src/constants/types'

import { arrayMoveUp, arrayMoveDown } from 'src/utils/arrayManipulation'
import { combineAndSortShelfItems } from 'src/utils/shelfItems'
import getPrecedingShelfProduct from 'src/utils/getPrecedingShelfProduct'

import {
  createChangeControl as createChangeControlAction,
  deleteChangeControlById,
} from 'src/modules/SpaceAssignment/ChangeControl/store'

import {
  selectShelfById,
  selectShelfProductById,
  selectSpaceInstanceById,
} from 'src/modules/SpaceAssignment/store/selectors'
import { createError } from 'src/store/notifications/actions'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { buildThunkPrefix, handleCreateAsyncThunkResult } from 'src/utils/createAsyncThunkHandler'
import { selectUi } from 'src/modules/SpaceAssignment/store/index'

import { PostShelf } from 'src/api/Shelf'
import { fetchStoresExport } from 'src/modules/Exports/store'
import { saveAs } from 'file-saver'
import {
  actionCreateSpaceInstance,
  actionDeleteSpaceInstance,
  fetchSpaceAssignmentWithInstances,
} from './reducer/spaceInstances'
import { actionUpdateShelf, actionDeleteShelf } from './reducer/shelf'
import {
  actionCreateShelfArtifact,
  actionUpdateShelfArtifact,
  actionDeleteShelfArtifact,
} from './reducer/shelfArtifact'
import actionUpdateMoveCluster from './reducer/moveCluster'
import { actionUpdateMoveShelf } from './reducer/moveShelf'
import { actionDeleteShelfProductsByIds } from '../../ShelfProductsDetail/reducer/shelfProduct'
import { actionUpdateMoveShelfProduct } from './reducer/moveShelfProduct'
import { fetchSpacePlanAssignment } from './reducer/spacePlanAssignment'
import {
  actionDeleteShelfProductLayout,
  actionCreateShelfProductLayout,
} from './reducer/shelfProductLayout'

const NAMESPACE = 'SpaceAssignment'

export const QUICK_ADD_PRODUCT = 'quick-add-product'
export const CLOSE_QUICK_ADD_PRODUCT = 'close-quick-add-product'

export const quickAddProduct = shelveId => ({ type: QUICK_ADD_PRODUCT, payload: shelveId })
export const closeQuickAddProduct = () => ({ type: CLOSE_QUICK_ADD_PRODUCT })

export const SET_VIEW_CHANGE_CONTROL = 'set-view-change-control'
export const setViewChangeControl = changeControlId => ({
  type: SET_VIEW_CHANGE_CONTROL,
  payload: changeControlId,
})

export const refreshSpacePlanAssignment = assignmentId => async (dispatch, getState) => {
  const state = getState()
  const { viewChangeControl: changeControlId } = selectUi(state)
  await dispatch(fetchSpacePlanAssignment({ id: assignmentId, changeControlId }))
}

export const refreshSpaceInstances = assignmentId => async (dispatch, getState) => {
  const state = getState()
  const { viewChangeControl: changeControlId } = selectUi(state)
  await dispatch(fetchSpaceAssignmentWithInstances({ id: assignmentId, changeControlId }))
}

export const createSpaceInstance = (assignmentId, layoutType) => async dispatch => {
  await dispatch(actionCreateSpaceInstance({ spaceAssignmentId: assignmentId, layoutType }))
  await dispatch(refreshSpaceInstances(assignmentId))
}

export const updateSpaceInstance = (instance, assignmentId) => async dispatch => {
  const moveClusterDetail = {
    spaceAssignmentId: assignmentId,
    newSpaceInstanceId: instance.id,
    movedClusterId: instance.clusterId,
  }

  await dispatch(actionUpdateMoveCluster(moveClusterDetail))
  await dispatch(refreshSpaceInstances(assignmentId))
}

export const deleteSpaceInstance = (spaceInstanceId, assignmentId) => async dispatch => {
  await dispatch(actionDeleteSpaceInstance(spaceInstanceId))
  await dispatch(refreshSpaceInstances(assignmentId))
}

export const addShelfProductLayout = (
  shelfProductId,
  { location, index, position, spaceInstanceId },
  spaceAssignmentId
) => async dispatch => {
  const data = {
    location,
    index,
    position,
    shelfProductId,
    spaceInstanceId,
  }

  await dispatch(actionCreateShelfProductLayout(data))
  await dispatch(refreshSpaceInstances(spaceAssignmentId))
}

export const deleteShelfProductLayout = (id, spaceAssignmentId) => async dispatch => {
  await dispatch(actionDeleteShelfProductLayout(id))
  await dispatch(refreshSpaceInstances(spaceAssignmentId))
}

export const updateShelf = (shelf, shouldRefreshSpaceInstance = true) => async dispatch => {
  const {
    id,
    spaceAssignmentId: assignmentId,
    spaceInstanceId,
    categories,
    isRsb,
    palletOrStackType,
  } = shelf

  const updatedShelfInfo = {
    id,
    spaceInstanceId,
    shelfCategoryIds: categories.map(category => category.id),
    isRsb,
    palletOrStackType,
  }

  await dispatch(actionUpdateShelf({ id, shelf: updatedShelfInfo }))
  if (shouldRefreshSpaceInstance) {
    await dispatch(refreshSpaceInstances(assignmentId))
    await dispatch(refreshSpacePlanAssignment(assignmentId))
  }
}

export const updateShelves = shelves => async dispatch => {
  await Promise.all(shelves.map(shelf => updateShelf(shelf, false)).map(dispatch))
  await dispatch(refreshSpaceInstances(shelves[0].spaceAssignmentId))
  await dispatch(refreshSpacePlanAssignment(shelves[0].spaceAssignmentId))
}

const actionCreateShelf = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'actionCreateShelf'),
  async (spaceInstanceId, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const postShelfInstance = new PostShelf(store, {
      params: { spaceInstanceId },
    })
    const response = await handleCreateAsyncThunkResult(
      postShelfInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

export const addShelf = (spaceInstanceId, spaceAssignmentId) => async dispatch => {
  await dispatch(actionCreateShelf(spaceInstanceId))
  await dispatch(refreshSpaceInstances(spaceAssignmentId))
}

export const deleteShelf = shelf => async dispatch => {
  const { id } = shelf

  await dispatch(actionDeleteShelf(id))
  await dispatch(refreshSpaceInstances(shelf.spaceAssignmentId))
}

export const moveShelfUp = (shelf, position) => async (dispatch, getState) => {
  const { spaceInstanceId, spaceAssignmentId } = shelf
  const state = getState()
  const { shelves } = selectSpaceInstanceById(state, spaceInstanceId)
  const newOrderShelveIds = arrayMoveUp([...shelves], position).map(shelve => {
    return shelve.id
  })
  const moveShelfDetail = {
    spaceInstanceId,
    newOrderShelveIds,
  }

  await dispatch(actionUpdateMoveShelf(moveShelfDetail))
  await dispatch(refreshSpaceInstances(spaceAssignmentId))
}

export const moveShelfDown = (shelf, position) => async (dispatch, getState) => {
  const { spaceInstanceId, spaceAssignmentId } = shelf
  const state = getState()
  const { shelves } = selectSpaceInstanceById(state, spaceInstanceId)
  const newOrderShelveIds = arrayMoveDown([...shelves], position).map(shelve => {
    return shelve.id
  })
  const moveShelfDetail = {
    spaceInstanceId,
    newOrderShelveIds,
  }

  await dispatch(actionUpdateMoveShelf(moveShelfDetail))
  await dispatch(refreshSpaceInstances(spaceAssignmentId))
}

const isShelfArtifact = ({ type } = {}) => type === SHELF_ARTIFACTS_TYPE

export const addShelfArtifact = (shelf, type, description) => async dispatch => {
  const artifact = {
    artifactType: type,
    position: 0,
    shelfId: shelf.id,
    ...(description ? { description } : {}),
  }
  await dispatch(actionCreateShelfArtifact(artifact))
  await dispatch(refreshSpaceInstances(shelf.spaceAssignmentId))
}

export const updateShelfArtifact = (artifact, description, shelf) => async dispatch => {
  const { id } = artifact
  const updatedArtifact = {
    description,
  }
  await dispatch(actionUpdateShelfArtifact({ id, artifact: updatedArtifact }))
  await dispatch(refreshSpaceInstances(shelf.spaceAssignmentId))
}

export const deleteShelfArtifact = (artifactId, shelf) => async dispatch => {
  await dispatch(actionDeleteShelfArtifact(artifactId))
  await dispatch(refreshSpaceInstances(shelf.spaceAssignmentId))
}

export const moveShelfArtifact = (artifact, toPosition, shelf) => async dispatch => {
  const { id } = artifact
  const updatedArtifact = {
    position: toPosition,
  }

  await dispatch(actionUpdateShelfArtifact({ id, artifact: updatedArtifact }))
  await dispatch(refreshSpaceInstances(shelf.spaceAssignmentId))
}

export const moveProduct = (
  id,
  shelf,
  fromPosition,
  toPosition,
  spaceAssignmentId
) => async dispatch => {
  const { shelfProducts: products, shelfArtifacts: artifacts = [] } = shelf
  const shelfItems = combineAndSortShelfItems(products, artifacts)

  const affectedShelfItem = shelfItems[toPosition]
  const shouldMoveShelfArtifact = isShelfArtifact(affectedShelfItem)

  if (shouldMoveShelfArtifact) {
    const { position: currentPosition } = affectedShelfItem
    const newArtifactPosition =
      fromPosition >= toPosition ? currentPosition + 1 : currentPosition - 1
    await dispatch(moveShelfArtifact(affectedShelfItem, newArtifactPosition, shelf))
  } else {
    const precedingShelfProductId = getPrecedingShelfProduct(shelfItems, fromPosition, toPosition)

    const shelfProduct = {
      precedingShelfProductId: precedingShelfProductId?.id,
    }

    await dispatch(actionUpdateMoveShelfProduct({ id, shelfProduct }))
  }
  await dispatch(refreshSpaceInstances(spaceAssignmentId))
}

export const moveProductToShelf = (id, shelfId, toPosition, spaceAssignmentId) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const shelf = selectShelfById(state, shelfId)
  const selectedShelfProduct = selectShelfProductById(state, id)
  const { category: shelfProductCategory } = selectedShelfProduct
  const { shelfProducts: products, categories: shelfCategories } = shelf
  const hasShelfCategory = shelfCategories.map(c => c.id).indexOf(shelfProductCategory.id) !== -1

  if (!hasShelfCategory) {
    dispatch(createError('Shelf product can only be moved to a shelf with same category'))
    return
  }

  let precedingShelfProduct = null

  if (toPosition === undefined) {
    precedingShelfProduct = getPrecedingShelfProduct(
      products,
      0,
      products.length ? products.length - 1 : 0
    )
  } else {
    precedingShelfProduct = getPrecedingShelfProduct(products, products.length, toPosition)
  }

  const shelfProduct = {
    precedingShelfProductId: precedingShelfProduct?.id,
    shelfId,
  }

  await dispatch(actionUpdateMoveShelfProduct({ id, shelfProduct }))
  await dispatch(refreshSpaceInstances(spaceAssignmentId))
}

export const deleteProducts = (products, spaceAssignmentId) => async dispatch => {
  const productsToDeleteByIds = {
    shelfProductIds: products.map(({ id }) => id),
  }

  await dispatch(actionDeleteShelfProductsByIds(productsToDeleteByIds))
  await dispatch(refreshSpaceInstances(spaceAssignmentId))
}

export const createChangeControl = ({
  changeControlDescription,
  changeControlReasonCode,
  spaceAssignment,
  afterInit,
}) => async dispatch => {
  const changeControlRequest = {
    description: changeControlDescription,
    changeControlReasonId: changeControlReasonCode,
    spaceAssignmentId: spaceAssignment.id,
  }

  const result = await dispatch(createChangeControlAction(changeControlRequest)).unwrap()
  dispatch(afterInit(spaceAssignment.id, result))
}

export const deleteChangeControl = id => async dispatch => {
  await dispatch(deleteChangeControlById(id))
}

export const exportStores = (startDate, endDate, spaceId, clusters) => async dispatch => {
  const clusterIds = Array.isArray(clusters) ? clusters : Array(1).fill(clusters)

  const data = await dispatch(
    fetchStoresExport({ startDate, endDate, spaceId, clusterIds })
  ).unwrap()

  const csvContents = new Blob(['\ufeff', data?.data], {
    type: 'application/octet-stream',
  })

  const fileName = decodeURI(
    data?.headers?.['content-disposition'].split(';')[1].split('filename=')[1].replace(/["']/g, '')
  ).replace(/[+]/g, ' ')

  saveAs(csvContents, fileName)
}
