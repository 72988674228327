import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { selectSelf } from 'src/store/data/selectors'
import { selectActiveCategories } from 'src/store/modules/asyncThunks/categories'
import { selectAllRoles } from 'src/store/modules/asyncThunks/roles'
import { createNewUser } from '../store'

import Form from './Form'

const mapStateToProps = state => ({
  activeCategories: selectActiveCategories(state),
  categories: [],
  roles: [...selectAllRoles(state)] || [],
  user: selectSelf(state),
})

const returnAction = () => push('/unassigned/submitted')
const mapDispatchToProps = dispatch => ({
  submit: async user => {
    const action = createNewUser(user)
    const response = await dispatch(action)
    if (response?.payload.successResult) dispatch(returnAction())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
