import { buildPeriodCells, buildWeekCells, buildCycleCells } from 'src/utils//timeline/header'
import partition from 'src/utils/partition'

const selectTimebar = ({ startDate, endDate, cycles, selectedGroup }) => {
  const start = new Date(startDate)
  const end = new Date(endDate)

  const timebar = [
    {
      id: 'week',
      title: 'Wednesday of Week',
      cells: buildWeekCells(start, end, 3),
    },
    {
      id: 'period',
      title: 'Period',
      cells: buildPeriodCells(start, end),
    },
  ]

  if (selectedGroup) {
    timebar.push({
      id: selectedGroup.id,
      title: `Cycles for ${selectedGroup.title}`,
      cells: buildCycleCells(cycles),
    })
  } else {
    const [foodCycles, nonFoodCycles] = partition(cycles, cycle => cycle.businessUnit === 'food')
    timebar.push(
      {
        id: 'food-cycle',
        title: 'Food Cycle',
        cells: buildCycleCells(foodCycles),
      },
      {
        id: 'non-food-cycle',
        title: 'Non-Food Cycle',
        cells: buildCycleCells(nonFoodCycles),
      }
    )
  }

  return timebar
}

export default selectTimebar
