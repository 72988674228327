import { connect } from 'react-redux'

import TemplateLinkContainer from 'src/components/TemplateLink'

import { selectNominationsTemplate } from './store'

const mapStateToProps = state => ({
  template: selectNominationsTemplate(state),
})

export default connect(mapStateToProps)(TemplateLinkContainer)
