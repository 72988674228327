import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { getDisplayText } from 'src/utils/datePeriod'
import pluralize from 'src/utils/pluralize'

const CoverageSpace = ({ gaps, coverage, shelfCoverage }) => {
  const { emptyDuration, percentage } = coverage
  const tooltipText = `${emptyDuration} ${pluralize(emptyDuration, 'day')} unassigned`

  return (
    (percentage < 100 || shelfCoverage < 100) && (
      <div className="c-planner-side-container">
        <div
          className={classNames('c-planner-side ln-u-margin-right*1/2', {
            'ln-u-bg-color-orange-dark': percentage < 100,
          })}
        >
          {percentage < 100 && (
            <>
              {`${percentage}%`}
              <div className="c-planner-side__tooltip">
                {tooltipText}
                <ul>
                  {gaps.map(gap => (
                    <li key={gap.id}>{getDisplayText(gap)}</li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
        <div
          className={classNames('c-planner-side ln-u-push-right*1/4', {
            'ln-u-bg-color-plum': shelfCoverage < 100,
          })}
        >
          {shelfCoverage < 100 && (
            <>
              {`${shelfCoverage}%`}
              <div className="c-planner-side__tooltip">Missing shelf allocations</div>
            </>
          )}
        </div>
      </div>
    )
  )
}

CoverageSpace.propTypes = {
  gaps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  coverage: PropTypes.shape({
    percentage: PropTypes.number.isRequired,
    emptyDuration: PropTypes.number.isRequired,
  }).isRequired,
  shelfCoverage: PropTypes.number.isRequired,
}

export default CoverageSpace
