import { hasRequiredPermission } from 'src/utils/permissions'

import {
  CREATE_SPACE_ASSIGNMENTS,
  READ_SPACE_PLANS,
  READ_SPACE_PLANS_APPROVALS,
  READ_SPACE_PLANS_APPROVALS_ALL,
  READ_SPACE_ASSIGNMENTS,
} from 'src/constants/permissions'

import { DRAFT_ID } from 'src/modules/SpacePlans/statuses'
import buildTabsWrapper from 'src/components/TabsWrapper'

const tabs = [
  {
    id: 'details',
    title: 'Details',
    link: ({ id }) => `/space-plans/${id}`,
    isVisible: ({ user }) => hasRequiredPermission(user, READ_SPACE_PLANS),
  },
  {
    id: 'planner',
    title: 'Planner',
    link: ({ id }) => `/space-plans/${id}/planner`,
    isVisible: ({ id, user, spacePlan }) => {
      if (!id) {
        return false
      }

      if (
        hasRequiredPermission(user, CREATE_SPACE_ASSIGNMENTS) ||
        (spacePlan.status !== DRAFT_ID && hasRequiredPermission(user, READ_SPACE_ASSIGNMENTS))
      ) {
        return true
      }

      return false
    },
  },
  {
    id: 'approvals',
    title: 'Approvals',
    link: ({ id }) => `/space-plans/${id}/approvals`,
    isVisible: ({ user, id, spacePlan }) => {
      if (!id || spacePlan.status === DRAFT_ID) {
        return false
      }
      return (
        hasRequiredPermission(user, READ_SPACE_PLANS_APPROVALS_ALL) ||
        hasRequiredPermission(user, READ_SPACE_PLANS_APPROVALS)
      )
    },
  },
  {
    id: 'cycles',
    title: 'Cycles',
    link: ({ id }) => `/space-plans/${id}/cycles`,
    isVisible: ({ id }) => !!id,
  },
  {
    id: 'timeline',
    title: 'Timeline',
    link: ({ id }) => `/space-plans/${id}/timeline`,
    isVisible: ({ id }) => !!id,
  },
]

export default buildTabsWrapper(tabs)
