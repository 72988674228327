import React from 'react'

const hfssStatus = hfssFlag => {
  const hfssIndicator = <span className="c-hfss">YES</span>
  return hfssFlag ? hfssIndicator : ''
}

const nonFood = product => {
  if (product.source === 'V10') return true
  return false
}

const hfssUncategorized = product => {
  if (product.source === 'V9' && product.hfss === 'YES') return 1
  return 0
}

const enableHfssRestriction = (spaceHfssFlag, productHfssFlag) => {
  if (spaceHfssFlag === '1' && productHfssFlag === 1) return true
  return false
}

const addHfssProperty = (object, value) => {
  Object.defineProperty(object, 'hfssFlag', {
    value,
    writable: true,
  })
  return object
}

const hfssErrorMessage = 'HFSS SKU cannot be uploaded in a HFSS restricted space'

export {
  addHfssProperty,
  enableHfssRestriction,
  hfssErrorMessage,
  hfssStatus,
  hfssUncategorized,
  nonFood,
}
