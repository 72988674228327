import Api from 'src/api/Base'

class GetSpaceAssignments extends Api {
  getPagination() {
    return `ascending=true&pageSize=${this.getParams().page.size}&pageNumber=${
      this.getParams().page.number
    }`
  }

  requestUrl =
    this.getParams().categoryIds || this.getParams().buyers || this.getParams().space
      ? `/api/Cycle/${this.getParams().id}/MasterPSASpaceAssignments?CategoryIds=${
          this.getParams().categoryIds
        }&Buyers=${this.getParams().buyers}&Space=${this.getParams().space}&${this.getPagination()}`
      : `/api/Cycle/${this.getParams().id}/MasterPSASpaceAssignments?${this.getPagination()}`
}
export default GetSpaceAssignments
