import React from 'react'
import { Route, IndexRoute } from 'react-router'

import { authorized } from 'src/hoc/auth'
import NominationsList from 'src/modules/Nominations'
import NominationsDetail from 'src/modules/NominationsDetail'

const route = (
  <Route name="Nominations" path="nominations" showInNav>
    <IndexRoute component={authorized(NominationsList)} />
    <Route path="create" component={authorized(NominationsDetail)} />
    <Route path=":id" component={authorized(NominationsDetail)} />
  </Route>
)

export default route
