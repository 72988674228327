/* eslint-disable no-return-assign */
export const setLocation = url => (window.location = url)

export const getLocation = () => window.location

export const setStorageItem = (key, value) => window.localStorage.setItem(key, value)

export const getStorageItem = key =>
  window.localStorage ? window.localStorage.getItem(key) : undefined

export const matchMedia = query => window.matchMedia(query).matches

export const postMessageToParent = (message, origin = '*') =>
  window.parent.postMessage(message, origin)

export const scroll = (x, y) => window.scroll(x, y)
