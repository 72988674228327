import { hasRequiredPermission } from 'src/utils/permissions'
import {
  UPDATE_SPACE_INSTANCES_COMMENT,
  UPDATE_SPACE_INSTANCES_OWN_CATEGORY,
  UPDATE_SPACE_INSTANCES_POS_COMMENT,
  UPDATE_SPACE_INSTANCES_SHELF_HEIGHTS,
  UPDATE_SPACE_INSTANCE_SHIPPER_DESCRIPTION,
} from 'src/constants/permissions'

const hasSpaceInstanceCategories = (userCategories, spaceInstanceCategories) => {
  const spaceInstanceCatIds = spaceInstanceCategories.map(category => category.id)
  const userCategoriesIds = userCategories.map(category => category.id)
  return spaceInstanceCatIds.some(v => userCategoriesIds.includes(v))
}

const hasShelfHeightsEnabled = (user, categories) => {
  if (hasRequiredPermission(user, UPDATE_SPACE_INSTANCES_SHELF_HEIGHTS)) {
    return (
      !hasRequiredPermission(user, UPDATE_SPACE_INSTANCES_OWN_CATEGORY) ||
      hasSpaceInstanceCategories(user.categories, categories)
    )
  }
  return false
}

const disabled = ({ user, instanceCategories }) => ({
  comment: !hasRequiredPermission(user, UPDATE_SPACE_INSTANCES_COMMENT),
  posComment: !hasRequiredPermission(user, UPDATE_SPACE_INSTANCES_POS_COMMENT),
  shelfHeights: !hasShelfHeightsEnabled(user, instanceCategories),
  shipperDescription: !hasRequiredPermission(user, UPDATE_SPACE_INSTANCE_SHIPPER_DESCRIPTION),
})

export default disabled
