import GetCycles from 'src/api/Cycle/GetCycles'

class PostCycle extends GetCycles {
  requestUrl = `/api/Cycle`

  method = 'POST'

  formatRequestData = params => params.cycle
}

export default PostCycle
