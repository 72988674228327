import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import { GetStoreDetailsByStoreNumber } from 'src/api/RangeAndSpace'
import { createSelector } from 'reselect'

const NAMESPACE = 'StoreDetails'
const buildThunkPrefix = (slice, thunkName) => `${slice}/${thunkName}`

const selectModule = state => state.modules.RangeAndSpace[NAMESPACE]
const selectStoreDetails = createSelector(selectModule, module => module.data || {})

const fetchStoreDetailsByStoreNumber = createAsyncThunk(
  buildThunkPrefix('fetchStoreDetails'),
  async (storeNumber, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getStoreDetailsByStoreNumberInstance = new GetStoreDetailsByStoreNumber(store, {
      params: { storeNumber },
    })

    const response = await handleCreateAsyncThunkResult(
      getStoreDetailsByStoreNumberInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchStoreDetailsByStoreNumberSlice = createSlice({
  name: NAMESPACE,
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchStoreDetailsByStoreNumber.pending, pendingDataHandler)
    builder.addCase(fetchStoreDetailsByStoreNumber.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchStoreDetailsByStoreNumber.rejected, rejectedDataHandler)
  },
})

export default { [NAMESPACE]: fetchStoreDetailsByStoreNumberSlice.reducer }
export { fetchStoreDetailsByStoreNumber, selectStoreDetails }
