import { addOneDayToDate } from 'src/utils/day'
import { getDisplayText } from 'src/utils/datePeriod'
import hexToRgb from 'src/utils/color'

import buildTitle from 'src/modules/SpaceAssignment/buildTitle'

const buildBackgroundColor = ({ theme, selected }) =>
  selected ? theme : `rgba(${hexToRgb(theme)},0.15)`

const buildStyle = ({ categories = [] }) => {
  let background

  if (categories.length === 0) {
    background = '#f00'
  } else if (categories.length === 1) {
    background = buildBackgroundColor(categories[0])
  } else {
    const spacing = 100 / categories.length
    const gradient = categories
      .map((category, index) => {
        const color = buildBackgroundColor(category)
        return `${color} ${spacing * index}%, ${color} ${spacing * (index + 1)}%`
      })
      .join(', ')
    background = `linear-gradient(-240deg, ${gradient})`
  }

  return {
    color: '#fff',
    background,
  }
}

const buildTooltip = ({ categories = [], title, startDate, endDate, shelfCoverage }) => {
  const parts = [
    categories.length
      ? `Assigned to ${categories.map(category => category.title).join(', ')}`
      : 'Unassigned',
    title && `"${title}"`,
    getDisplayText({ startDate, endDate }),
    shelfCoverage < 100 && 'One or more shelves do not have categories assigned',
  ].filter(p => !!p)

  return parts.join('\n')
}

const buildStatusIcon = ({ shelfCoverage }) => {
  if (shelfCoverage < 100) {
    return ['badge', 'badge--red']
  }
  return []
}

const buildClasses = ({ shelfCoverage }) =>
  buildStatusIcon({ shelfCoverage }).concat(['c-planner-element'])

const buildElement = (
  { id, startDate, endDate, categories, hasSelectedCategory, title, assignmentId, shelfCoverage },
  spaceId,
  returnTo
) => ({
  id: `${spaceId}-space-assignments-${id}`,
  start: new Date(startDate),
  end: addOneDayToDate(endDate),
  title: buildTitle({ categories, title }),
  tooltip: buildTooltip({ categories, title, startDate, endDate, shelfCoverage }),
  link: `/space-assignments/${assignmentId}?rt=${encodeURIComponent(returnTo)}`,
  classes: buildClasses({ shelfCoverage }),
  dataSet: { control: title },
  style: buildStyle({ categories, title, hasSelectedCategory }),
})

const buildEmptyElement = ({ id, startDate, endDate, shelfCoverage }, spaceId, returnTo) => {
  const start = new Date(startDate)
  const end = addOneDayToDate(endDate)
  return {
    id: `${spaceId}-empty-${id}`,
    start,
    end,
    title: '',
    tooltip: 'Create new space assignment',
    link: `/space-assignments/create?startDate=${startDate}&endDate=${endDate}&space=${spaceId}&rt=${encodeURIComponent(
      returnTo
    )}`,
    classes: buildClasses({ shelfCoverage }).concat(['c-planner-element--create']),
  }
}

const buildBlankElement = ({ id, startDate, endDate, shelfCoverage }, spaceId) => {
  const start = new Date(startDate)
  const end = addOneDayToDate(endDate)
  return {
    id: `${spaceId}-blank-${id}`,
    start,
    end,
    title: 'Blank',
    tooltip: 'This space does not exist for this time period',
    classes: buildClasses({ shelfCoverage }).concat(['c-planner-element--blank']),
  }
}

export { buildElement, buildBlankElement, buildEmptyElement, buildTooltip, buildStyle }
