import sortArray from './sortArray'

export const buildInstanceTitle = ({ id: autoId }) => (instance, index) => ({
  ...instance,
  title: `Group ${index + 1}${instance.id === autoId ? ' (Default)' : ''}`,
})

export const sortInstanceKey = ({ id: autoId }) => ({ id, createdAt }) =>
  `${id === autoId ? 0 : 1}${createdAt}`

export const formatInstances = (spaceInstances, autoSpaceInstance) => {
  const sortedInstances = sortArray(spaceInstances, sortInstanceKey(autoSpaceInstance))
  return sortedInstances.map(buildInstanceTitle(autoSpaceInstance))
}
