import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'

import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

import {
  DeleteSpaceAssignment,
  GetSpaceAssignmentById,
  PostSpaceAssignment,
  UpdateSpaceAssignmentById,
} from 'src/api/SpaceAssignment'

const NAMESPACE = 'SpacePlanAssignment'
const buildThunkPrefix = (slice, thunkName) => `${slice}/${thunkName}`

const selectSelf = state => state.modules[NAMESPACE]
const selectSpaceAssignment = createSelector(selectSelf, module => module.data || {})

const fetchSpacePlanAssignment = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchSpaceAssignment'),
  async ({ id, changeControlId }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getSpaceAssignmentInstance = new GetSpaceAssignmentById(store, {
      params: { id, changeControlId },
    })

    const response = await handleCreateAsyncThunkResult(
      getSpaceAssignmentInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchSpacePlanAssignmentSlice = createSlice({
  name: NAMESPACE,
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchSpacePlanAssignment.pending, pendingDataHandler)
    builder.addCase(fetchSpacePlanAssignment.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpacePlanAssignment.rejected, rejectedDataHandler)
  },
})

const actionUpdateSpaceAssignmentById = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'actionUpdateSpaceAssignmentById'),
  async ({ id, assignment }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()

    const patchSpaceAssignmentInstance = new UpdateSpaceAssignmentById(store, {
      params: { id, ...assignment },
    })

    const response = await handleCreateAsyncThunkResult(
      patchSpaceAssignmentInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionCreateSpaceAssignment = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'createSpaceAssignment'),
  async (assignment, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const postSpaceAssignmentInstance = new PostSpaceAssignment(store, {
      params: {
        ...assignment,
      },
    })
    const response = await handleCreateAsyncThunkResult(
      postSpaceAssignmentInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionDeleteSpaceAssignment = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'deleteSpaceAssignment'),
  async (id, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const deleteSpaceAssignmentInstance = new DeleteSpaceAssignment(store, {
      params: {
        id,
      },
    })
    const response = await handleCreateAsyncThunkResult(
      deleteSpaceAssignmentInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

export default { [NAMESPACE]: fetchSpacePlanAssignmentSlice.reducer }
export {
  fetchSpacePlanAssignment,
  selectSpaceAssignment,
  actionUpdateSpaceAssignmentById,
  actionCreateSpaceAssignment,
  actionDeleteSpaceAssignment,
}
