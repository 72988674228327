import Proptypes from 'prop-types'

const masterPSAentryType = Proptypes.shape({
  clusters: Proptypes.string,
  rows: Proptypes.arrayOf(Proptypes.shape),
  space: Proptypes.shape,
  spaceAssignmentComment: Proptypes.string,
  spaceAssignmentId: Proptypes.string,
  spaceInstanceComment: Proptypes.string,
  spaceInstanceId: Proptypes.string,
  spaceInstancePosComment: Proptypes.string,
  spaceInstanceShelfHeights: Proptypes.string,
  spaceInstanceSuggestedLocation: Proptypes.string,
})

export default masterPSAentryType
