import PropTypes from 'prop-types'
import React from 'react'
import { Field } from 'formik'

import DatePicker from './DatePicker'

const DateField = ({
  label,
  name,
  disabled,
  required,
  minDate,
  maxDate,
  displayMonth,
  outOfRange,
  onCustomDateChange,
}) => (
  <div data-control={name}>
    <label htmlFor={name} className="ln-c-label">
      {label}
      <span className="ln-u-visually-hidden">format YYYY-MM-DD</span>
    </label>
    <Field
      className="ln-c-text-input"
      name={name}
      component={DatePicker}
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      required={required}
      displayMonth={displayMonth}
      outOfRange={outOfRange}
      onCustomDateChange={onCustomDateChange}
    />
  </div>
)

DateField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  displayMonth: PropTypes.string,
  outOfRange: PropTypes.func,
  onCustomDateChange: PropTypes.func,
}

DateField.defaultProps = {
  label: null,
  name: null,
  disabled: false,
  required: false,
  minDate: null,
  maxDate: null,
  displayMonth: null,
  outOfRange: () => false,
  onCustomDateChange: () => null,
}

export default DateField
