import { withFormik } from 'formik'

import withModal from 'src/hoc/withModal'

import Form from './Form'
import handleSubmit from './handleSubmit'

const wrappedForm = withFormik({ handleSubmit })(Form)

export default withModal(wrappedForm)
