import Api from 'src/api/Base'

class PatchUserInfo extends Api {
  requestUrl = `/api/user/${this.getParams().id}`

  method = 'PATCH'

  formatRequestData = ({ user, rejected }) => ({ ...user, rejected })
}

export default PatchUserInfo
