import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

export const Component = () => {
  return <span>N/A</span>
}

Component.propTypes = {
  approval: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({}).isRequired,
}

const mapDispatchToProps = {
  returnAction: () => push('/space-plans'),
}

export default connect(undefined, mapDispatchToProps)(Component)
