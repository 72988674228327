import Api from 'src/api/Base'

class SkuCsvImport extends Api {
  requestUrl = `/api/import/sku/csv`

  method = 'POST'

  formatRequestData = params => params.formData
}

export default SkuCsvImport
