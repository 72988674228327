import Api from 'src/api/Base'

class PostSpaceGroup extends Api {
  requestUrl = `/api/SpaceGroup`

  method = 'POST'

  formatRequestData = params => params.spaceGroup
}
export default PostSpaceGroup
