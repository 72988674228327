import React from 'react'
import { Route, IndexRoute } from 'react-router'

import { authorized } from 'src/hoc/auth'
import SpaceAssignment from 'src/modules/SpaceAssignment'
import POS from 'src/modules/SpaceAssignment/POS'

const route = (
  <Route name="SpaceAssignments" path="space-assignments">
    <Route path="create">
      <IndexRoute component={authorized(SpaceAssignment)} />
    </Route>
    <Route path=":id">
      <IndexRoute component={authorized(SpaceAssignment)} />
      <Route path="change-controls/:changeControlId" component={authorized(SpaceAssignment)} />
      <Route path="shelf/:shelfId/pos" component={authorized(POS)} />
    </Route>
  </Route>
)

export default route
