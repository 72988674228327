import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Heading3, Tabs, TabLink, TabPanel } from '@jsluna/react'

import {
  FROZEN_LAYOUT_TYPE,
  FROZEN_HALF_HEIGHT_LAYOUT_TYPE,
  FROZEN_FULL_HEIGHT_LAYOUT_TYPE,
  WELL_LAYOUT_TYPE,
} from 'src/constants/layoutTypes'

import FrozenContainer from './FrozenContainer'

const tabs = [
  { name: 'Frozen Full Height', key: 'frozen-full-height' },
  { name: 'Frozen Half Height & Well', key: 'frozen-half-height-and-well' },
]

const FrozenLayout = ({
  addFrozenPosition,
  deleteFrozenPosition,
  instanceId,
  layoutType,
  shelfProducts,
  shelfProductLayouts,
}) => {
  if (layoutType === FROZEN_LAYOUT_TYPE) {
    const [activeTab, setActiveTab] = useState('frozen-full-height')

    return (
      <div>
        <Heading3 data-control="frozen-layout-title">Frozen Table</Heading3>
        <Tabs>
          {tabs.map(tab => (
            <TabLink
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
              active={activeTab === tab.key}
            >
              {tab.name}
            </TabLink>
          ))}
        </Tabs>
        <TabPanel>
          {activeTab === 'frozen-full-height' ? (
            <FrozenContainer
              addFrozenPosition={addFrozenPosition}
              className="ln-u-push-top"
              columns={2}
              columnNames={['Left Door', 'Right Door']}
              deleteFrozenPosition={deleteFrozenPosition}
              instanceId={instanceId}
              locations={['left', 'right']}
              rows={6}
              shelfProducts={shelfProducts}
              shelfProductLayouts={shelfProductLayouts}
            />
          ) : (
            <div>
              <FrozenContainer
                addFrozenPosition={addFrozenPosition}
                className="ln-u-push-top"
                columns={1}
                deleteFrozenPosition={deleteFrozenPosition}
                instanceId={instanceId}
                locations={['top']}
                rows={3}
                shelfProducts={shelfProducts}
                shelfProductLayouts={shelfProductLayouts}
                title="Half Height"
              />
              <FrozenContainer
                addFrozenPosition={addFrozenPosition}
                className="ln-u-push-top"
                columns={1}
                deleteFrozenPosition={deleteFrozenPosition}
                instanceId={instanceId}
                locations={['well']}
                rows={1}
                shelfProducts={shelfProducts}
                shelfProductLayouts={shelfProductLayouts}
                title="Well"
              />
            </div>
          )}
        </TabPanel>
      </div>
    )
  }
  if (layoutType === WELL_LAYOUT_TYPE) {
    return (
      <div>
        <Heading3 data-control="frozen-layout-title">Frozen Table</Heading3>
        <FrozenContainer
          addFrozenPosition={addFrozenPosition}
          columns={1}
          deleteFrozenPosition={deleteFrozenPosition}
          instanceId={instanceId}
          locations={['well']}
          rows={1}
          shelfProducts={shelfProducts}
          shelfProductLayouts={shelfProductLayouts}
          title="Well"
        />
      </div>
    )
  }
  if (layoutType === FROZEN_FULL_HEIGHT_LAYOUT_TYPE) {
    return (
      <div>
        <Heading3 data-control="frozen-layout-title">Frozen Full Height Table</Heading3>
        <FrozenContainer
          addFrozenPosition={addFrozenPosition}
          className="ln-u-push-top"
          columns={2}
          columnNames={['Left Door', 'Right Door']}
          deleteFrozenPosition={deleteFrozenPosition}
          instanceId={instanceId}
          locations={['left', 'right']}
          rows={6}
          shelfProducts={shelfProducts}
          shelfProductLayouts={shelfProductLayouts}
        />
      </div>
    )
  }
  if (layoutType === FROZEN_HALF_HEIGHT_LAYOUT_TYPE) {
    return (
      <div>
        <Heading3 data-control="frozen-layout-title">Frozen Half Height Table</Heading3>
        <div>
          <FrozenContainer
            addFrozenPosition={addFrozenPosition}
            className="ln-u-push-top"
            columns={1}
            deleteFrozenPosition={deleteFrozenPosition}
            instanceId={instanceId}
            locations={['top']}
            rows={3}
            shelfProducts={shelfProducts}
            shelfProductLayouts={shelfProductLayouts}
            title="Half Height"
          />
          <FrozenContainer
            addFrozenPosition={addFrozenPosition}
            className="ln-u-push-top"
            columns={1}
            deleteFrozenPosition={deleteFrozenPosition}
            instanceId={instanceId}
            locations={['well']}
            rows={1}
            shelfProducts={shelfProducts}
            shelfProductLayouts={shelfProductLayouts}
            title="Well"
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      <Heading3 data-control="frozen-layout-type-error">Layout Type Not Supported</Heading3>
    </div>
  )
}

FrozenLayout.propTypes = {
  addFrozenPosition: PropTypes.func,
  deleteFrozenPosition: PropTypes.func,
  instanceId: PropTypes.string.isRequired,
  layoutType: PropTypes.string.isRequired,
  shelfProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shelfProductLayouts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

FrozenLayout.defaultProps = {
  addFrozenPosition: () => {},
  deleteFrozenPosition: () => {},
}

export default FrozenLayout
