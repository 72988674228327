import partition from 'src/utils/partition'
import sortNatural from 'src/utils/sortNatural'

import { selectCategories } from 'src/store/modules/asyncThunks/categories'
import { SPACE_MULTISELECT, SPACE_SEARCH } from 'src/constants/spacePlanner'
import buildCategories from './buildCategories'
import buildTrack from './buildTrack'
import buildAssignmentsForSpace from './buildAssignments'

import { selectGroupSelected, selectSpacePlanCyclesAssignments } from '..'
import {
  selectDates,
  selectFilter,
  selectSearch,
  selectSpaceMultiSelect,
  selectSpaceFilterType,
  selectUiTracks,
} from '.'

const identity = v => v

const getCoverage = ({ tracks }) => {
  if (!tracks.length) {
    return 0
  }
  const totalPercentage = tracks.reduce(
    (total, { coverage: { percentage } }) => total + percentage,
    0
  )
  const averagePercentage = totalPercentage / tracks.length
  return Math.floor(averagePercentage)
}

const mapAssignmentsBySpace = spaceAssignments =>
  spaceAssignments.reduce((acc, spaceAssignment) => {
    acc[spaceAssignment.id] = Object.values(spaceAssignment.spaceAssignmentDetails)
    return acc
  }, {})

const mapValidCyclesBySpace = (spaces, cycles) => {
  const bySpace = {}
  spaces.forEach(space => {
    cycles.forEach(cycle => {
      if (
        cycle.startDate < space.availabilityEndDate &&
        cycle.endDate > space.availabilityStartDate
      ) {
        if (!bySpace[space.id]) {
          bySpace[space.id] = {}
        }
        bySpace[space.id][cycle.id] = cycle
      }
    })
  })

  return bySpace
}
const selectTracks = state => {
  const { spaceDetails, cycleDetails: cycleList, id: spacePlanId } =
    selectSpacePlanCyclesAssignments(state) || {}
  const { startDate, endDate } = selectDates(state)
  const { categories, spaces } = selectFilter(state)
  const spaceFilterType = selectSpaceFilterType(state)
  const search = selectSearch(state)
  const searchSpaces = selectSpaceMultiSelect(state)
  const { categoriesById } = buildCategories(selectCategories(state), categories)

  const uiTracks = selectUiTracks(state)
  const assignmentsBySpaceId = mapAssignmentsBySpace(spaceDetails)

  const selectedGroup = selectGroupSelected(state)
  const isGroupActive = selectedGroup
  const validCyclesByGroup = isGroupActive && mapValidCyclesBySpace(spaceDetails, cycleList)

  const [foodCycles, nonFoodCycles] = partition(cycleList, cycle => cycle.businessUnit === 'food')

  const cyclesByBusinessUnit = {
    food: {
      cycles: foodCycles,
      validCycles: mapValidCyclesBySpace(spaceDetails, foodCycles),
    },
    'non-food': {
      cycles: nonFoodCycles,
      validCycles: mapValidCyclesBySpace(spaceDetails, nonFoodCycles),
    },
  }

  const filterOnSearchString = s => s.title.toLowerCase().includes(search.toLowerCase())
  const filterOnSpaceIds = s => {
    const searchedIds = searchSpaces.map(({ id }) => id)
    return searchedIds.includes(s.id)
  }

  const filterOnSelectedCategory = ({ hasSelectedCategory }) => hasSelectedCategory
  const filterOnCoverage = ({ coverage, shelfCoverage }) =>
    coverage.percentage < 100 || shelfCoverage < 100
  const filterOnBusinessUnit = ({ businessUnit }) => {
    if (spaces.businessUnitFilter === 'food' && businessUnit === 'food') return true
    if (spaces.businessUnitFilter === 'non-food' && businessUnit === 'non-food') return true
    if (spaces.businessUnitFilter === 'all') return true
    return false
  }

  const filterSpaceBy = () => {
    if (spaceFilterType === SPACE_MULTISELECT && searchSpaces.length) {
      return filterOnSpaceIds
    }

    if (spaceFilterType === SPACE_SEARCH && search) {
      return filterOnSearchString
    }

    return identity
  }

  const spacesFilteredOnSearch = spaceDetails?.filter(filterSpaceBy())

  const spacesFilteredOnCategory = spacesFilteredOnSearch
    .map(space => {
      const { assignments, hasSelectedCategory } = buildAssignmentsForSpace(
        Object.values(assignmentsBySpaceId[space.id] || {}),
        categoriesById
      )
      return { ...space, assignments, hasSelectedCategory }
    })
    .filter(spaces.excludeSpaceWithNoMatchingCategories ? filterOnSelectedCategory : identity)

  const spacesFilteredOnBusinessUnit = spacesFilteredOnCategory.filter(filterOnBusinessUnit)

  const unsortedTracks = spacesFilteredOnBusinessUnit
    .map(space =>
      buildTrack(
        {
          ...space,
          startDate,
          endDate,
          cycles: isGroupActive ? [...cycleList] : cyclesByBusinessUnit[space.businessUnit].cycles,
          validCycles: isGroupActive
            ? validCyclesByGroup[space.id]
            : cyclesByBusinessUnit[space.businessUnit].validCycles[space.id],
          categoriesById,
          spacePlanId,
        },
        uiTracks
      )
    )
    .filter(spaces.excludeSpaceWithFullCoverage ? filterOnCoverage : identity)

  const tracks = sortNatural(unsortedTracks, 'title')

  const coverage = getCoverage({ tracks })

  return {
    tracks,
    numOfSpacesShown: tracks.length,
    numOfSpacesTotal: spaceDetails.length,
    coverage,
  }
}

export default selectTracks
