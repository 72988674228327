import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { FROZEN_LAYOUT_TYPE } from 'src/constants/layoutTypes'

import { createError } from 'src/store/notifications/actions'

import { selectSpaceTypeLayouts, selectSpaceTypeInfo } from '../store'

import Form from './Form'

export const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps
  const spaceTypeInfo = id ? selectSpaceTypeInfo(state) : {}
  const allSpaceTypeLayouts = selectSpaceTypeLayouts(state) || []
  const allLayouts = allSpaceTypeLayouts.filter(l => l.name !== FROZEN_LAYOUT_TYPE)

  const {
    shelvesDefault = 0,
    shelvesMax = 0,
    shelvesMin = 0,
    title = '',
    spaceTypeLayouts = [],
  } = spaceTypeInfo

  return {
    ...spaceTypeInfo,
    id,
    layouts: spaceTypeLayouts.map(
      ({
        defaultCapacity = 0,
        minCapacity = 0,
        maxCapacity = 0,
        layoutType,
        id: spaceTypeLayoutId,
      }) => ({
        id: spaceTypeLayoutId,
        layoutType,
        defaultCapacity: String(defaultCapacity),
        minCapacity: String(minCapacity),
        maxCapacity: String(maxCapacity),
      })
    ),
    allLayouts,
    originalTitle: title,
    shelvesDefault: String(shelvesDefault),
    shelvesMax: String(shelvesMax),
    shelvesMin: String(shelvesMin),
    title,
  }
}

const returnAction = () => push('/spaces/types')
const mapDispatchToProps = dispatch => ({
  afterSubmit: () => dispatch(returnAction()),
  cancel: () => dispatch(returnAction()),
  submit: action => dispatch(action),
  showError: message => dispatch(createError(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
