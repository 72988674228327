import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const cellClassName = 'ln-c-table__cell c-table__cell--compact'

const RowCell = ({ alignment, className, children, columnSpan, id, label }) => (
  <td
    colSpan={columnSpan}
    className={classNames(`${cellClassName}`, className, `ln-u-text-align-${alignment}`)}
    data-id={id}
    data-label={label}
  >
    {children}
  </td>
)

RowCell.propTypes = {
  alignment: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  columnSpan: PropTypes.number,
  id: PropTypes.string,
  label: PropTypes.string,
}

RowCell.defaultProps = {
  alignment: 'left',
  className: undefined,
  children: undefined,
  columnSpan: undefined,
  id: '',
  label: undefined,
}

export default RowCell
