import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const headerClassName =
  'ln-c-table__header-cell c-table__cell--align-bottom c-table__cell--compact ln-u-text-align-left'

const HeaderCell = ({ children, className }) => (
  <th className={classNames(headerClassName, className)}>{children}</th>
)

HeaderCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

HeaderCell.defaultProps = {
  children: undefined,
  className: undefined,
}

export default HeaderCell
