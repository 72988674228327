const getSpaces = ({ spaces = [] }) =>
  spaces
    .map(space => space.title)
    .sort()
    .join(', ')

const getSortAndSearchValue = ({ spaces = [] }) =>
  spaces
    .map(space => space.title)
    .sort()
    .join(' ')

const getLink = group => `/groups/${group.id}`
const getSpacePlanTitle = group => group.spacePlan.title
const getSpacePlanEndDate = group => group.spacePlan.endDate

export default [
  { key: 'title', name: 'Title', value: 'title', sortValue: 'title', link: getLink },
  {
    key: 'spaceplan',
    name: 'Space Plan',
    sortValue: getSpacePlanEndDate,
    value: getSpacePlanTitle,
  },
  {
    key: 'spaces',
    name: 'Spaces',
    value: getSpaces,
    sortValue: getSortAndSearchValue,
    searchValue: getSortAndSearchValue,
  },
]
