import {
  CYCLES_ROUTE,
  UNASSIGNED_ROUTE,
  NO_ACCESS_ROUTE,
  ROOT_ROUTE,
  SPACE_PLANS_ROUTE,
  USERS_ROUTE,
  NOMINATIONS_ROUTE,
  EXPORTS_ROUTE,
  RANGE_AND_SPACE_ROUTE,
  SSPSA_ROUTE,
  GROUPS_ROUTE,
} from './routes'

export const NO_ACCESS_ROLE_ID = 'ccb6b097-126f-4eb6-ac87-d93b069d0932'
export const PERM_ROLE_ID = '3cf69eef-c0f3-4e4e-8d2b-dfaf38acf516'
export const MERCH_ROLE_ID = '2d0b308e-af5c-4761-b67d-613d839c6e12'
export const MARKETING_PROPOSITION_MANAGER_ROLE_ID = '78adf428-cf96-49b7-93d0-c792f2535403'
export const CATEGORY_MANAGER_ROLE_ID = '3d0150a7-34ea-43f1-b557-102e83b8458f'
export const CATEGORY_PLANNER_ROLE_ID = 'd4d02aac-52cf-4c77-a888-5c42b158ab43'
export const CATEGORY_BUYER_ROLE_ID = 'ca9b6fb9-a5cd-488d-9c72-10528876059b'
export const NON_FOOD_BUYER_AND_MERCHANDISER_ROLE_ID = '6f66b82b-a761-4a8a-8022-b3827ae015be'
export const NON_FOOD_SENIOR_MANAGER_BUYER_AND_MERCHANDISER_ROLE_ID =
  'f1e08d60-b877-4a2b-be51-7a00e73e524b'
export const CATEGORY_ASSISTANT_ROLE_ID = '608690ae-f5bb-45e8-a6b9-cff1fab1b07b'
export const SUPPLY_CHAIN_ANALYST_ROLE_ID = 'b6cbbec2-1e26-41e8-9fe9-815a831911f1'
export const SUPPLY_CHAIN_MNGR_ROLE_ID = '4ea404b9-89c8-42d5-bd1d-55d6bc583672'
export const READ_ONLY_ROLE_ID = 'f634b7ea-4e3c-413d-86bb-d237379d3a7c'
export const UNASSIGNED_ROLE_ID = '030e5c51-f8c5-42ab-8d38-4088c662459e'
export const NON_FOOD_ROLE_ID = 'bfb805a0-574e-4af8-b8ce-bbb00f286cc0'
export const SHIPPER_COMPLIANCE_CONTROLLER_ROLE_ID = '721d21f5-ddd6-495c-89d3-c0498ced493c'
export const STRATEGY_PLANNER_ROLE_ID = 'b838896b-f38a-4045-948c-a6b403ecb8b2'
export const DISPLAY_PLANNER_ROLE_ID = '2e94b16a-c5cf-4c82-a594-5931b969e258'
export const TRADING_OPS_DELIVERY_MANAGER_ID = '1176bb29-39be-45a6-9b50-103b253df197'
export const COMMERCIAL_OPS_SUPER_USER_ID = '1610f933-6b7a-45ab-b54e-a78139729985'

export const UNASSIGNED_ROLE = {
  id: UNASSIGNED_ROLE_ID,
  title: 'Unassigned',
  shortName: 'Unassigned',
  routes: [UNASSIGNED_ROUTE],
  landingRoute: UNASSIGNED_ROUTE,
}

export const NO_ACCESS_ROLE = {
  id: NO_ACCESS_ROLE_ID,
  title: 'No Access',
  shortName: 'NA',
  routes: [NO_ACCESS_ROUTE],
  landingRoute: NO_ACCESS_ROUTE,
}

export const PERM_ROLE = {
  id: PERM_ROLE_ID,
  title: 'Promotions & Events Ranging Manager',
  shortName: 'PERM',
  routes: [ROOT_ROUTE, USERS_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: SPACE_PLANS_ROUTE,
}

export const MERCH_ROLE = {
  id: MERCH_ROLE_ID,
  title: 'Merchandiser',
  shortName: 'Merch',
  routes: [ROOT_ROUTE, USERS_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: SPACE_PLANS_ROUTE,
}

export const NON_FOOD_ROLE = {
  id: NON_FOOD_ROLE_ID,
  title: 'Non-Food User',
  shortName: 'NF',
  routes: [ROOT_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: CYCLES_ROUTE.replace(':businessUnit', 'non-food'),
}

export const MARKETING_PROPOSITION_MANAGER_ROLE = {
  id: MARKETING_PROPOSITION_MANAGER_ROLE_ID,
  title: 'Marketing Proposition Manager',
  shortName: 'MPM',
  routes: [ROOT_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: SPACE_PLANS_ROUTE,
}

export const CATEGORY_PLANNER_ROLE = {
  id: CATEGORY_PLANNER_ROLE_ID,
  title: 'Category Planner',
  shortName: 'CP',
  routes: [ROOT_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: CYCLES_ROUTE.replace(':businessUnit', 'food'),
}

export const NON_FOOD_SENIOR_MANAGER_BUYER_AND_MERCHANDISER = {
  id: NON_FOOD_SENIOR_MANAGER_BUYER_AND_MERCHANDISER_ROLE_ID,
  title: 'Non Food Senior / Manager Buyer & Merchandiser',
  shortName: 'NFSMBM',
  routes: [ROOT_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: CYCLES_ROUTE.replace(':businessUnit', 'non-food'),
}

export const CATEGORY_BUYER_ROLE = {
  id: CATEGORY_BUYER_ROLE_ID,
  title: 'Category Buyer',
  shortName: 'CB',
  routes: [ROOT_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: CYCLES_ROUTE.replace(':businessUnit', 'food'),
}

export const NON_FOOD_BUYER_AND_MERCHANDISER = {
  id: NON_FOOD_BUYER_AND_MERCHANDISER_ROLE_ID,
  title: 'Non Food Buyer & Merchandiser',
  shortName: 'NFBM',
  routes: [ROOT_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: CYCLES_ROUTE.replace(':businessUnit', 'non-food'),
}

export const CATEGORY_ASSISTANT_ROLE = {
  id: CATEGORY_ASSISTANT_ROLE_ID,
  title: 'Category Assistant',
  shortName: 'CA',
  routes: [ROOT_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: NOMINATIONS_ROUTE,
}

export const SUPPLY_CHAIN_ANALYST_ROLE = {
  id: SUPPLY_CHAIN_ANALYST_ROLE_ID,
  title: 'Supply Chain Analyst',
  shortName: 'SCA',
  routes: [ROOT_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: SPACE_PLANS_ROUTE,
}

export const SUPPLY_CHAIN_MNGR_ROLE = {
  id: SUPPLY_CHAIN_MNGR_ROLE_ID,
  title: 'Supply Chain Manager',
  shortName: 'SCM',
  routes: [ROOT_ROUTE, EXPORTS_ROUTE, GROUPS_ROUTE],
  landingRoute: SPACE_PLANS_ROUTE,
}

export const READ_ONLY_ROLE = {
  id: READ_ONLY_ROLE_ID,
  title: 'Read Only',
  shortName: 'RO',
  routes: [ROOT_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: SPACE_PLANS_ROUTE,
}

export const SHIPPER_COMPLIANCE_CONTROLLER_ROLE = {
  id: SHIPPER_COMPLIANCE_CONTROLLER_ROLE_ID,
  title: 'Shipper Compliance Controller',
  shortName: 'SCC',
  routes: [ROOT_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: SPACE_PLANS_ROUTE,
}

export const STRATEGY_PLANNER_ROLE = {
  id: STRATEGY_PLANNER_ROLE_ID,
  title: 'Strategy Planner',
  shortName: 'SP',
  routes: [ROOT_ROUTE, USERS_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: SPACE_PLANS_ROUTE,
}

export const DISPLAY_PLANNER_ROLE = {
  id: DISPLAY_PLANNER_ROLE_ID,
  title: 'Display Planner',
  shortName: 'DP',
  routes: [
    ROOT_ROUTE,
    USERS_ROUTE,
    EXPORTS_ROUTE,
    SSPSA_ROUTE,
    RANGE_AND_SPACE_ROUTE,
    GROUPS_ROUTE,
  ],
  landingRoute: SPACE_PLANS_ROUTE,
}

export const TRADING_OPS_DELIVERY_MANAGER = {
  id: TRADING_OPS_DELIVERY_MANAGER_ID,
  title: 'Trading Ops Delivery Manager',
  shortName: 'TODM',
  routes: [ROOT_ROUTE, USERS_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: SPACE_PLANS_ROUTE,
}

export const COMMERCIAL_OPS_SUPER_USER = {
  id: COMMERCIAL_OPS_SUPER_USER_ID,
  title: 'Commercial Ops Super User',
  shortName: 'COSU',
  routes: [ROOT_ROUTE, USERS_ROUTE, EXPORTS_ROUTE, RANGE_AND_SPACE_ROUTE, GROUPS_ROUTE],
  landingRoute: SPACE_PLANS_ROUTE,
}

export const ALL_ROLES_BY_ID = {
  [UNASSIGNED_ROLE_ID]: UNASSIGNED_ROLE,
  [NO_ACCESS_ROLE_ID]: NO_ACCESS_ROLE,
  [READ_ONLY_ROLE_ID]: READ_ONLY_ROLE,
  [PERM_ROLE_ID]: PERM_ROLE,
  [MERCH_ROLE_ID]: MERCH_ROLE,
  [MARKETING_PROPOSITION_MANAGER_ROLE_ID]: MARKETING_PROPOSITION_MANAGER_ROLE,
  [CATEGORY_PLANNER_ROLE_ID]: CATEGORY_PLANNER_ROLE,
  [CATEGORY_BUYER_ROLE_ID]: CATEGORY_BUYER_ROLE,
  [CATEGORY_ASSISTANT_ROLE_ID]: CATEGORY_ASSISTANT_ROLE,
  [SUPPLY_CHAIN_ANALYST_ROLE_ID]: SUPPLY_CHAIN_ANALYST_ROLE,
  [SUPPLY_CHAIN_MNGR_ROLE_ID]: SUPPLY_CHAIN_MNGR_ROLE,
  [NON_FOOD_ROLE_ID]: NON_FOOD_ROLE,
  [NON_FOOD_BUYER_AND_MERCHANDISER_ROLE_ID]: NON_FOOD_BUYER_AND_MERCHANDISER,
  [NON_FOOD_SENIOR_MANAGER_BUYER_AND_MERCHANDISER_ROLE_ID]: NON_FOOD_SENIOR_MANAGER_BUYER_AND_MERCHANDISER,
  [SHIPPER_COMPLIANCE_CONTROLLER_ROLE_ID]: SHIPPER_COMPLIANCE_CONTROLLER_ROLE,
  [STRATEGY_PLANNER_ROLE_ID]: STRATEGY_PLANNER_ROLE,
  [DISPLAY_PLANNER_ROLE_ID]: DISPLAY_PLANNER_ROLE,
  [TRADING_OPS_DELIVERY_MANAGER_ID]: TRADING_OPS_DELIVERY_MANAGER,
  [COMMERCIAL_OPS_SUPER_USER_ID]: COMMERCIAL_OPS_SUPER_USER,
}
