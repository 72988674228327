export default function handleSubmit(
  { startDate, endDate, title, status },
  { props: { id, previous, submit } }
) {
  const spacePlan = { endDate, title, status }

  if (previous && previous.endDate) {
    spacePlan.previousId = previous.id
  } else {
    spacePlan.startDate = startDate
  }

  return submit(spacePlan, id)
}
