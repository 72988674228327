import React from 'react'
import PropTypes from 'prop-types'

import restructureData from 'src/utils/timelines/restructureData'

import TrackKeys from '.'

const TrackKey = ({ track, toggleOpen, clickTrackButton }) => {
  const { title, tracks, isOpen, hasButton, sideComponent, elements } = track

  const resArrElements = elements.length ? restructureData(elements) : []

  const isExpandable = isOpen !== undefined

  const buildSideComponent = () => {
    if (sideComponent) {
      return React.cloneElement(sideComponent)
    }
    if (hasButton && clickTrackButton) {
      const handleClick = () => clickTrackButton(track)
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      return <button className="pt-track-key__side-button" onClick={handleClick} type="button" />
    }

    return null
  }

  return (
    <li className="pt-track-key">
      <div className={`pt-track-key__entry pt-track-key-entry-height--${resArrElements.length}`}>
        {isExpandable && (
          <button
            title="Expand track"
            className={`pt-track-key__toggle ${
              isOpen ? 'pt-track-key__toggle--close' : 'pt-track-key__toggle--open'
            }`}
            onClick={() => toggleOpen(track)}
            type="button"
          >
            {isOpen ? 'Close' : 'Open'}
          </button>
        )}
        <span className="pt-track-key__title">{title}</span>
        {buildSideComponent()}
      </div>
      {isOpen && tracks && tracks.length > 0 && (
        <TrackKeys tracks={tracks} toggleOpen={toggleOpen} />
      )}
    </li>
  )
}

TrackKey.propTypes = {
  track: PropTypes.shape({
    title: PropTypes.string.isRequired,
    tracks: PropTypes.arrayOf(PropTypes.shape({})),
    elements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isOpen: PropTypes.bool,
    hasButton: PropTypes.bool,
    sideComponent: PropTypes.element,
  }),
  toggleOpen: PropTypes.func,
  clickTrackButton: PropTypes.func,
}

TrackKey.defaultProps = {
  track: {
    tracks: [],
    isOpen: undefined,
    hasButton: false,
    sideComponent: undefined,
  },
  toggleOpen: undefined,
  clickTrackButton: undefined,
}

export default TrackKey
