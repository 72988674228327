import { connect } from 'react-redux'
import moment from 'moment'

import { hasRequiredPermission } from 'src/utils/permissions'

import loader from 'src/hoc/loader'

import {
  CHANGE_CONTROLS_4WK_ONLY,
  UPDATE_CHANGE_CONTROLS_AMEND,
  UPDATE_CHANGE_CONTROLS_AMEND_OWN_CATEGORY,
  UPDATE_CHANGE_CONTROLS_AMEND_ALL,
} from 'src/constants/permissions'

import { selectSelf } from 'src/store/data/selectors'

import { selectChangeControl, fetchChangeControl } from './store'
import ChangeControlDetail from './ChangeControlDetail'

export const getData = async ({ id }, dispatch) => {
  await dispatch(fetchChangeControl(id)).unwrap()
}

export const getChanges = (shelfProducts, changeControlId) =>
  shelfProducts.reduce((acc, shelfProduct) => {
    let add = acc.add || 0
    let remove = acc.remove || 0
    let modify = acc.modify || 0
    let move = acc.move || 0
    if (
      shelfProduct.changeType === 'add' &&
      shelfProduct.addedInChangeControl.id === changeControlId
    ) {
      add += 1
    }

    if (
      shelfProduct.changeType === 'remove' &&
      shelfProduct.removedInChangeControl.id === changeControlId
    ) {
      remove += 1
    }

    if (
      shelfProduct.changeType === 'modify' &&
      shelfProduct.supersedes &&
      shelfProduct.addedInChangeControl.id === changeControlId
    ) {
      modify += 1
    }

    if (
      shelfProduct.changeType === 'move' &&
      shelfProduct.supersedes &&
      shelfProduct.addedInChangeControl.id === changeControlId
    ) {
      move += 1
    }

    return {
      add,
      remove,
      modify,
      move,
    }
  }, {})

export const mapStateToProps = (state, { id }) => {
  const {
    createdBy,
    spaceAssignment: { spaceInstances },
    spaceAssignment,
    status,
    cycle: { id: cycleId, businessUnit, startDate: cycleStartDate },
  } = selectChangeControl(state, id)

  const shelfProducts = spaceInstances.reduce((shelfProductAccumulator, spaceInstance) => {
    shelfProductAccumulator.push(...spaceInstance.shelves.map(shelf => shelf.shelfProducts).flat())
    return shelfProductAccumulator
  }, [])
  const removedProducts = spaceInstances.reduce((shelfProductAccumulator, spaceInstance) => {
    shelfProductAccumulator.push(
      ...spaceInstance.shelves.map(shelf => shelf.removedProducts).flat()
    )
    return shelfProductAccumulator
  }, [])
  const allshelfProducts = shelfProducts.concat(removedProducts)
  const comment = ''
  const user = selectSelf(state)
  const isOwnChangeControl = user.id === createdBy
  const isSubmitted = status === 'submitted'
  const isApproved = status === 'approved'
  const changes = getChanges(allshelfProducts, id)
  const changeControlCategories = [
    ...new Set(
      allshelfProducts.map(shelfProduct => {
        return shelfProduct.category
      })
    ),
  ]
  const canAmendOrReject =
    isSubmitted ||
    (isApproved &&
      (hasRequiredPermission(user, UPDATE_CHANGE_CONTROLS_AMEND) ||
        hasRequiredPermission(user, UPDATE_CHANGE_CONTROLS_AMEND_OWN_CATEGORY) ||
        hasRequiredPermission(user, UPDATE_CHANGE_CONTROLS_AMEND_ALL)))
  const is4WeeksToLive = moment().isSameOrAfter(moment(cycleStartDate).subtract(4, 'weeks'))
  const has4WKOnlyPermission = hasRequiredPermission(user, CHANGE_CONTROLS_4WK_ONLY)

  return {
    actionType: '',
    businessUnit,
    changes,
    comment,
    cycleId,
    has4WKOnlyPermission,
    is4WeeksToLive,
    isApproved,
    isOwnChangeControl,
    isSubmitted,
    spaceAssignment,
    status,
    changeControlCategories,
    changeControlOwnerId: createdBy,
    canAmendOrReject,
  }
}

export const wrappedComponent = connect(mapStateToProps)(ChangeControlDetail)

const options = {
  extractProps: ({ params }) => params,
  shouldRefresh: (previous, current) => previous.id !== current.id,
}

export default loader(getData, options)(wrappedComponent)
