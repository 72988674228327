import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Heading2, StatusCard, TabLink, Tabs, TabPanel } from '@jsluna/react'
import { CREATE_GENERATE_SSPSA_TASK_TYPE } from 'src/constants/permissions'
import WithRequiredPermissions from 'src/components/WithRequiredPermissions'
import Section from 'src/components/Section'
import { fetchCycles } from 'src/modules/Cycles/store'
import { selectAllAdminConfigMessages } from 'src/store/modules/asyncThunks/adminConfigMessages'
import { SSPSA_PREVIEW_VERSIONS, SSPSA_VERSION_IN_PROGRESS } from 'src/constants/sspsaVersion'
import { selectSelf } from 'src/store/data/selectors'
import {
  selectCreateSspsaStatus,
  selectPendingSspsas,
  selectAvailablePsaPreview,
  selectMostRecentSspsaList,
  actionDeleteSspsaTask,
} from './store'
import Form from './Form'
import SspsaVersion from './SspsaVersion'
import SspsaList from './SspsaList'
import { fetchMostRecentSspsaList } from './store/mostRecentSspsaList'
import { fetchAvailablePsaPreview } from './store/availablePsaPreview'

const tabs = [
  { name: 'Food', key: 'food', type: 'food' },
  { name: 'Non Food', key: 'non-food', type: 'gm' },
]

const SspsaComponent = ({
  pendingRequests,
  isSSPSAGenarationSuccessFullResult,
  onChangeBusinessUnit,
  availableSspsas,
  messages,
  mostRecentSspsaList,
  deleteSspsaTask,
  user,
}) => {
  const [activeTab, setActiveTab] = useState('food')
  const [activeType, setActiveType] = useState('food')

  useEffect(() => {
    onChangeBusinessUnit(activeTab)
  }, [])

  return (
    <Section>
      <Heading2>
        <span data-control="sspsa-title">SSPSA</span>
      </Heading2>
      {isSSPSAGenarationSuccessFullResult || pendingRequests.length ? (
        <StatusCard className="ln-u-push-bottom" status="warning">
          <div className="ln-u-display-1">
            THERE IS CURRENTLY A PENDING SSPSA. PLEASE WAIT FOR THIS TO FINISH
          </div>
        </StatusCard>
      ) : null}
      <Tabs>
        {tabs.map(tab => (
          <TabLink
            key={tab.key}
            onClick={() => {
              setActiveTab(tab.key)
              onChangeBusinessUnit(tab.key)
              setActiveType(tab.type)
            }}
            active={activeTab === tab.key}
            data-control={`${tab.key}-tab`}
          >
            {tab.name}
          </TabLink>
        ))}
      </Tabs>
      <TabPanel data-control={`${activeTab}-form`}>
        <Card>
          <WithRequiredPermissions permission={CREATE_GENERATE_SSPSA_TASK_TYPE}>
            <Form
              businessUnit={activeTab}
              hasPendingRequests={isSSPSAGenarationSuccessFullResult || pendingRequests.length > 0}
            />
          </WithRequiredPermissions>
        </Card>
      </TabPanel>
      <Tabs />
      <div className="ln-u-display-flex">
        <TabPanel data-control={`${activeTab}-preview`} className="ln-u-margin-right*3">
          <Card>
            {SSPSA_PREVIEW_VERSIONS.map(version => {
              const sspsas =
                availableSspsas?.filter(
                  item =>
                    item.businessUnit === activeTab &&
                    (version.includes(item.week) ||
                      (item.sspsaTaskStatus === SSPSA_VERSION_IN_PROGRESS &&
                        version === SSPSA_VERSION_IN_PROGRESS))
                ) || undefined

              return (
                <SspsaVersion
                  version={version}
                  sspsas={sspsas}
                  messages={messages}
                  type={activeType}
                />
              )
            })}
          </Card>
        </TabPanel>
        <TabPanel data-control={`${activeTab}-sspsa-list`} className="ln-u-flex-grow">
          <Card>
            <SspsaList
              mostRecentSspsaList={mostRecentSspsaList}
              businessUnit={activeTab}
              deleteSspsaTask={deleteSspsaTask}
              user={user}
            />
          </Card>
        </TabPanel>
      </div>
    </Section>
  )
}

export const mapStateToProps = state => {
  const pendingRequests = selectPendingSspsas(state)
  const isSSPSAGenarationSuccessFullResult = selectCreateSspsaStatus(state)
  const availableSspsas = selectAvailablePsaPreview(state)
  const messages = selectAllAdminConfigMessages(state)
  const mostRecentSspsaList = selectMostRecentSspsaList(state)
  const user = selectSelf(state)
  return {
    pendingRequests,
    isSSPSAGenarationSuccessFullResult,
    availableSspsas,
    messages,
    mostRecentSspsaList,
    user,
  }
}

export const mapDispatchToProps = dispatch => ({
  onChangeBusinessUnit: businessUnit => {
    dispatch(fetchCycles(businessUnit))
  },
  deleteSspsaTask: async id => {
    const action = actionDeleteSspsaTask(id)
    await dispatch(action)
    await dispatch(fetchMostRecentSspsaList())
    await dispatch(fetchAvailablePsaPreview())
  },
})
SspsaComponent.defaultProps = {
  availableSspsas: [],
  messages: [],
  mostRecentSspsaList: [],
}
SspsaComponent.propTypes = {
  pendingRequests: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSSPSAGenarationSuccessFullResult: PropTypes.bool.isRequired,
  onChangeBusinessUnit: PropTypes.func.isRequired,
  availableSspsas: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      week: PropTypes.string.isRequired,
      areSspsaGeneratedForThisPeriod: PropTypes.bool.isRequired,
    }).isRequired
  ),
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  mostRecentSspsaList: PropTypes.arrayOf(
    PropTypes.shape({
      cycleNameWithGroup: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
    }).isRequired
  ),
  deleteSspsaTask: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
}
export default connect(mapStateToProps, mapDispatchToProps)(SspsaComponent)
