import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Card, FlagBody, FlagComponent, FlagWrapper, Heading2 } from '@jsluna/react'

import { CREATE_CYCLES } from 'src/constants/permissions'
import Section from 'src/components/Section'
import Table from 'src/components/Table'
import WithRequiredPermissions from 'src/components/WithRequiredPermissions'

import { selectCycles } from './store'
import columns from './columns'

const businessUnitToTitle = {
  food: 'Food',
  'non-food': 'Non-Food',
}

const Cycles = ({ businessUnit }) => {
  return (
    <Section>
      <FlagWrapper>
        <FlagBody>
          <Heading2 className="ln-u-soft-right" data-control="cycles-title">
            {businessUnitToTitle[businessUnit]} Cycles
          </Heading2>
        </FlagBody>
        <FlagComponent alignment="top" nowrap>
          <WithRequiredPermissions permission={CREATE_CYCLES}>
            <Link
              className="ln-c-button ln-c-button--outlined"
              to={`/cycles/${businessUnit}/create`}
            >
              Create
            </Link>
          </WithRequiredPermissions>
        </FlagComponent>
      </FlagWrapper>
      <Card>
        <Table type="cycles" searchEnabled columns={columns} dataSelector={selectCycles} />
      </Card>
    </Section>
  )
}

Cycles.propTypes = {
  businessUnit: PropTypes.oneOf(['food', 'non-food']).isRequired,
}

export default Cycles
