import React from 'react'

import loader from 'src/hoc/loader'
import NotFound from 'src/components/Errors/NotFound'

import { actionClearData, fetchCategoryById } from './store'
import Form from './Form'

const getData = async ({ id }, dispatch) => {
  if (!id) {
    return
  }

  await dispatch(fetchCategoryById(id))
}

const options = {
  Custom404: (
    <NotFound
      title="Category Not Found"
      message="The category you are looking for cannot be found."
    />
  ),
  extractProps: ({ params }) => params,
  shouldRefresh: ({ id: previousId }, { id }) => previousId !== id,
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(Form)
