import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleCreateAsyncThunkResult, buildThunkPrefix } from 'src/utils/createAsyncThunkHandler'
import { PatchTouchPointByShipperComplianceId } from 'src/api/ShipperCompliance'

const NAMESPACE = 'UpdateTouchPointByShipperComplianceId'

const actionUpdateTouchPointByShipperComplianceId = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'actionUpdateTouchPointByShipperComplianceId'),
  async ({ id, touchPointDetail }, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const patchTouchPointByShipperComplianceIdInstance = new PatchTouchPointByShipperComplianceId(
      store,
      {
        params: {
          id,
          touchPointDetail,
        },
      }
    )
    const response = await handleCreateAsyncThunkResult(
      patchTouchPointByShipperComplianceIdInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)
// eslint-disable-next-line import/prefer-default-export
export { actionUpdateTouchPointByShipperComplianceId }
