import { combineReducers } from 'redux'

import keyBy from 'src/utils/keyBy'

import { UPDATE_PRODUCT, ADD_PRODUCTS, UPDATE_STATUS, RESET_IMPORTS } from './actions'

const DEFAULT_IMPORTS = {}
const importsReducer = (state = DEFAULT_IMPORTS, { type, payload } = {}) => {
  switch (type) {
    case RESET_IMPORTS:
      return DEFAULT_IMPORTS
    case ADD_PRODUCTS:
      return keyBy(payload, 'uuid')
    case UPDATE_PRODUCT:
      return {
        ...state,
        [payload.uuid]: payload,
      }
    default:
      return state
  }
}

const DEFAULT_STATUS = { message: 'Ready', isValid: false, isUploading: false, isFinished: false }
const statusReducer = (state = DEFAULT_STATUS, { type, payload } = {}) => {
  switch (type) {
    case RESET_IMPORTS:
      return DEFAULT_STATUS
    case UPDATE_STATUS:
      return typeof payload === 'string'
        ? {
            ...state,
            message: payload,
          }
        : {
            ...state,
            ...payload,
          }
    default:
      return state
  }
}

export default combineReducers({
  imports: importsReducer,
  status: statusReducer,
})
