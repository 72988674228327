import React from 'react'
import PropTypes from 'prop-types'
import { Section } from '@jsluna/react'

const SectionAnimated = ({ className, children }) => (
  <Section className={className}>
    <div className="c-animate-entry">{children}</div>
  </Section>
)

SectionAnimated.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

SectionAnimated.defaultProps = {
  className: null,
}

export default SectionAnimated
