import React from 'react'
import { connect } from 'react-redux'

import loader from 'src/hoc/loader'
import { selectSpaceAssignmentWithSpaceInstances } from 'src/modules/SpaceAssignment/store/selectors'
import APIFailed from 'src/components/Errors/APIFailed'
import { fetchPromoMechanics } from '../store/reducer/promoMechanics'
import { createSpaceInstance, updateSpaceInstance, deleteSpaceInstance } from '../store/actions'

import SpaceInstances from './SpaceInstances'
import { fetchSpaceAssignmentWithInstances } from '../store/reducer/spaceInstances'
import { actionClearData, selectUi } from '../store'

const getData = async ({ id }, dispatch, getState) => {
  const state = getState()
  const { viewChangeControl: changeControlId } = selectUi(state)
  await Promise.all([
    id
      ? dispatch(fetchSpaceAssignmentWithInstances({ id, changeControlId })).unwrap()
      : Promise.resolve({}),
    dispatch(fetchPromoMechanics()),
  ])
}
const options = {
  Custom404: <APIFailed />,
  onUnmount: dispatch => actionClearData(dispatch),
}
const mapStateToProps = state => ({
  assignment: selectSpaceAssignmentWithSpaceInstances(state),
})

const mapDispatchToProps = {
  createSpaceInstance,
  updateSpaceInstance,
  deleteSpaceInstance,
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SpaceInstances)

export default loader(getData, options)(connectedComponent)
