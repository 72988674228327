import React from 'react'
import PropTypes from 'prop-types'
import { Heading3, FilledButton } from '@jsluna/react'

const DeleteDialog = ({ changeControlId, deleteChangeControl, handleClose }) => (
  <div>
    <Heading3>Are you sure you want to cancel this draft change control?</Heading3>
    <p>Please confirm this action</p>
    <div>
      <FilledButton
        data-control="modal-confirm-delete-change-control"
        onClick={async () => {
          await deleteChangeControl(changeControlId)

          handleClose()
        }}
      >
        Confirm
      </FilledButton>
      <FilledButton
        color="dark"
        data-control="modal-cancel-delete-change-control"
        onClick={handleClose}
        className="ln-u-push-left"
      >
        Cancel
      </FilledButton>
    </div>
  </div>
)

DeleteDialog.propTypes = {
  changeControlId: PropTypes.string.isRequired,
  deleteChangeControl: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default DeleteDialog
