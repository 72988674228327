import { ZOOM_IN, ZOOM_OUT } from '../actions'

const defaultZoom = 15

const reducer = (state = defaultZoom, { type } = {}) => {
  switch (type) {
    case ZOOM_IN:
      return state + 1
    case ZOOM_OUT:
      return state - 1
    default:
      return state
  }
}

export default reducer
