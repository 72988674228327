import Api from '../Base'

class DeleteShelfProductsByIds extends Api {
  requestUrl = `/api/ShelfProduct`

  method = 'DELETE'

  formatRequestData = params => params.productsToDeleteByIds
}

export default DeleteShelfProductsByIds
