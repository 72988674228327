import { connect } from 'react-redux'

import { selectSelf } from 'src/store/data/selectors'
import { selectSpaceAssignmentWithSpaceInstances } from 'src/modules/SpaceAssignment/store/selectors'
import { closeQuickAddProduct, refreshSpaceInstances } from '../../store/actions'
import { selectPromoMechanics } from '../../store/reducer/promoMechanics'
import AddProductRow from './AddProductRow'
import buildDisabled from './disabled'

const mapStateToProps = (state, { categories = [] }) => {
  const promoMechanics = selectPromoMechanics(state)
  const user = selectSelf(state)

  const { space } = selectSpaceAssignmentWithSpaceInstances(state)

  const values = {
    categories,
    category: '',
    comment: '',
    disabled: buildDisabled({ user }),
    product: {
      description: '',
      sku: '',
    },
    promoMechanic: '',
    promoMechanicValue: '',
    promoMechanics,
    pricePoint: '',
    space,
  }

  if (categories.length === 1) {
    values.category = categories[0].id
  }

  return values
}

const mapDispatchToProps = (dispatch, { shelf }) => ({
  submit: async action => {
    await dispatch(action)
    dispatch(closeQuickAddProduct())
    await dispatch(refreshSpaceInstances(shelf.spaceAssignmentId))
  },
  cancel: () => dispatch(closeQuickAddProduct()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProductRow)
