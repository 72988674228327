import React from 'react'
import { Route, IndexRoute } from 'react-router'

import { authorized } from 'src/hoc/auth'

import CyclesList from 'src/modules/Cycles'
import CyclesDetail from 'src/modules/CyclesDetail'
import DetailsTab from 'src/modules/CyclesDetail/Details'
import NominationsTab from 'src/modules/CyclesDetail/Nominations'
import MasterPsaTab from 'src/modules/CyclesDetail/MasterPsa'
import ChangeControlTab from 'src/modules/CyclesDetail/ChangeControls'
import StorePsaTab from 'src/modules/CyclesDetail/StorePsa'
import ShipperComplianceTab from 'src/modules/CyclesDetail/ShipperCompliance'
import SSPSACommentsTab from 'src/modules/CyclesDetail/SpaceComments'
import SpacesTab from 'src/modules/CyclesDetail/Spaces'

const route = (
  <Route name="Cycles" path="/cycles/:businessUnit">
    <IndexRoute component={authorized(CyclesList)} />
    <Route path="create" component={authorized(CyclesDetail)}>
      <IndexRoute component={authorized(DetailsTab)} />
    </Route>
    <Route path=":id" component={authorized(CyclesDetail)}>
      <IndexRoute component={authorized(SpacesTab)} />
      <Route path="details" component={authorized(DetailsTab)} />
      <Route path="nominations" component={authorized(NominationsTab)} />
      <Route path="master-psa" component={authorized(MasterPsaTab)} />
      <Route path="store-psa" component={authorized(StorePsaTab)} />
      <Route path="store-psa/:storeId" component={authorized(StorePsaTab)} />
      <Route path="change-controls" component={authorized(ChangeControlTab)} />
      <Route path="shipper-compliance" component={authorized(ShipperComplianceTab)} />
      <Route path="sspsa-comments" component={authorized(SSPSACommentsTab)} />
    </Route>
  </Route>
)

export default route
