import { createSelector } from 'reselect'
import { actionClearDataHandler } from 'src/utils/createAsyncThunkHandler'

const selectSelf = state => state.modules.CyclesDetail?.SspsaCommentsByCycleId
const selectSpaceAssignmentWithSspsaComments = createSelector(selectSelf, module => module?.data)

export const NAMESPACE = 'CycleSpaces'

const actionClearData = actionClearDataHandler(NAMESPACE)

export { selectSpaceAssignmentWithSspsaComments, actionClearData }
