import { combineReducers } from 'redux'
import merge from 'src/store/api/utils/merge'

const BOUND_SELECTOR_SYMBOL = '@@boundSelectorKey'
const MODULES_BASE = 'modules'
const DATA_KEY = 'data'
const META_KEY = 'meta'
const DEFAULT_STATE = {}
const UPDATE_DATA = namespace => `${MODULES_BASE}/${namespace}/UPDATE_DATA`
const CLEAR_DATA = namespace => `${MODULES_BASE}/${namespace}/CLEAR_DATA`

const selectModule = (state, namespace) =>
  state[MODULES_BASE][namespace.replace(`${MODULES_BASE}/`, '')]
const selectModuleRoot = state => state
const selectModuleData = state => state[DATA_KEY]
const selectModuleMeta = state => state[META_KEY]
const selectModulePagination = state => state[META_KEY].pagination || { number: 1 }

const bindSelector = namespace => selector => (state, ...args) => {
  const moduleState = selectModule(state, namespace)
  return selector(moduleState, ...args, BOUND_SELECTOR_SYMBOL)
}

const bindRootSelector = namespace => bindSelector(namespace)(selectModuleRoot)

const isBoundSelector = args => args[args.length - 1] === BOUND_SELECTOR_SYMBOL

const buildReducer = (namespace, additionalReducers = {}) => {
  const dataReducer = (state = DEFAULT_STATE, { type, payload }) => {
    switch (type) {
      case UPDATE_DATA(namespace):
        return merge(state, payload)
      case CLEAR_DATA(namespace):
        return DEFAULT_STATE
      default:
        return state
    }
  }

  const metaReducer = (state = DEFAULT_STATE, { type, meta }) => {
    if (type === UPDATE_DATA(namespace) && meta) {
      return meta
    }

    return state
  }

  return {
    [namespace]: combineReducers({
      [DATA_KEY]: dataReducer,
      [META_KEY]: metaReducer,
      ...additionalReducers,
    }),
  }
}

export {
  BOUND_SELECTOR_SYMBOL,
  MODULES_BASE,
  UPDATE_DATA,
  CLEAR_DATA,
  selectModuleData,
  selectModuleMeta,
  selectModulePagination,
  buildReducer,
  bindSelector,
  bindRootSelector,
  isBoundSelector,
}
