import moment from 'moment-timezone'

import keyBy from './keyBy'
import templateString from './templateString'
import nested from './nested'

import { addOneDayToDate } from './day'

import now from './now'

const CONDITION_TYPE_STATUS = 'status'
const CONDITION_TYPE_DATE = 'date'
const CONDITION_TYPE_DATE_END_OF = 'date-end-of'
const CONDITION_TYPE_TASK_COMPLETE = 'task-complete'
const ACTIONED_BY_TYPE_ROLE = 'role'
const DEADLINE_TYPE_DAYS_BEFORE_DATE = 'days-before-date'

export const stageHasTasksForRole = (stage, roleId) =>
  !!(
    stage &&
    stage.hasTask &&
    stage.task.actionedBy &&
    stage.task.actionedBy.type === ACTIONED_BY_TYPE_ROLE &&
    stage.task.actionedBy.role === roleId
  )

export const buildStages = ({ entity, timeline }) => {
  const { statusChanges = [] } = entity
  const { stages: inputStages = [] } = timeline
  const statusChangesById = keyBy(statusChanges, 'status')

  const buildDate = path => new Date(nested(entity, path))

  const buildDateEndOf = path => addOneDayToDate(nested(entity, path))

  const buildTaskLink = template => template && templateString(template, entity)

  const buildTaskDueDate = deadline => {
    if (!deadline) {
      return null
    }
    switch (deadline.type) {
      case DEADLINE_TYPE_DAYS_BEFORE_DATE: {
        const deadlineDate = nested(entity, deadline.date)
        const { daysBefore } = deadline
        return moment.tz(deadlineDate, 'Etc/UTC').subtract(daysBefore, 'days').toDate()
      }
      default:
        return null
    }
  }

  const buildTaskCompleted = (task, dueDate) => {
    const { completeCondition = {} } = task
    if (completeCondition.type === CONDITION_TYPE_STATUS) {
      const statusChange = statusChangesById[completeCondition.status]
      if (statusChange) {
        const { changedAt = null, changedBy } = statusChange
        const completedDate = changedAt && new Date(changedAt)
        return {
          isComplete: true,
          completedDate,
          completedBy: { ...changedBy },
          wasLate: completedDate > dueDate,
        }
      }
    }
    return { isComplete: false }
  }

  const buildTaskTitle = ({ pendingTemplate, completedTemplate }, isComplete) => {
    if (!isComplete && pendingTemplate) {
      return templateString(pendingTemplate, entity)
    }

    if (isComplete && completedTemplate) {
      return templateString(completedTemplate, entity)
    }

    return ''
  }

  const buildTask = stage => {
    const { task = null } = stage

    if (!task) {
      return null
    }

    const hasDeadline = !!task.deadline
    const dueDate = buildTaskDueDate(task.deadline)
    const { actionedBy } = task
    const completed = buildTaskCompleted(task, dueDate)
    const isOverdue = !completed.isComplete && now() > dueDate
    const title = buildTaskTitle(task, completed.isComplete)
    const link = buildTaskLink(task.link)

    return {
      hasDeadline,
      isOverdue,
      title,
      ...(link ? { link } : {}),
      ...(dueDate ? { dueDate } : {}),
      ...completed,
      ...(actionedBy ? { actionedBy } : {}),
    }
  }

  const buildExit = (previousStage, exitCondition = {}, task) => {
    const canExit = !previousStage || previousStage.hasExited
    const { type } = exitCondition
    switch (type) {
      case CONDITION_TYPE_TASK_COMPLETE: {
        const date = task.isComplete ? task.completedDate : task.dueDate
        const hasExited = canExit && task.isComplete
        return { hasExited, date }
      }
      case CONDITION_TYPE_DATE: {
        const date = buildDate(exitCondition.date)
        const hasExited = canExit && now() >= date
        return { hasExited, date }
      }
      case CONDITION_TYPE_DATE_END_OF: {
        const date = buildDateEndOf(exitCondition.date)
        const hasExited = canExit && now() >= date
        return { hasExited, date }
      }
      default:
        return { hasExited: false }
    }
  }

  const findCurrentStage = stages => stages.find(({ hasExited }) => !hasExited) || null

  const buildStage = (stage, previousStage) => {
    const { id, title, description, exitCondition } = stage
    const task = buildTask(stage)
    const hasTask = !!task
    const { hasExited, date: endDate } = buildExit(previousStage, exitCondition, task)

    const startDate = stage.startDate ? buildDate(stage.startDate) : previousStage.endDate

    return {
      id,
      title,
      description,
      hasExited,
      isCurrent: false,
      hasTask,
      ...(task ? { task } : {}),
      startDate,
      endDate,
    }
  }

  let previousStage = null
  const stages = inputStages.map(inputStage => {
    const stage = buildStage(inputStage, previousStage)
    previousStage = stage
    return stage
  })

  const firstStage = stages.length ? stages[0] : null
  const lastStage = stages.length ? stages[stages.length - 1] : null

  const currentStage = findCurrentStage(stages)
  if (currentStage) {
    currentStage.isCurrent = true
  }

  const hasTasksForRole = roleId => stageHasTasksForRole(currentStage, roleId)

  return {
    stages,
    currentStage,
    firstStage,
    lastStage,
    hasTasksForRole,
    timeline,
  }
}
