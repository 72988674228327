import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import {
  clearDataHandler,
  fullFilledDataHandler,
  handleCreateAsyncThunkResult,
  initialState,
  pendingDataHandler,
  rejectedDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import GetFeatureToggles from 'src/api/FeatureToggles'

const NAMESPACE = 'FeatureToggles'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = thunkName => `${STORE_PATH}/${thunkName}`

const selectModule = state => state.modules.FeatureToggles
const selectAllFeatureToggles = createSelector(selectModule, module =>
  module.data ? module.data : []
)
const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)
const selectFeatureEnabled = (state, featureId) =>
  createSelector(
    selectAllFeatureToggles,
    features => features.find(feature => feature.id === featureId)?.enabled || false
  )(state)

const fetchFeatureToggles = createAsyncThunk(
  buildThunkPrefix('fetchFeatureToggles'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const fetchFeatureTogglesInstance = new GetFeatureToggles(store)

    return handleCreateAsyncThunkResult(fetchFeatureTogglesInstance, dispatch, rejectWithValue)
  }
)

const featureTogglesSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchFeatureToggles.pending, pendingDataHandler)
    builder.addCase(fetchFeatureToggles.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchFeatureToggles.rejected, rejectedDataHandler)
    builder.addCase(actionClearData, clearDataHandler)
  },
})

export { actionClearData, fetchFeatureToggles, selectAllFeatureToggles, selectFeatureEnabled }

export default { [NAMESPACE]: featureTogglesSlice.reducer }
