import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  TextButton,
  FlagWrapper,
  FlagBody,
  FlagComponent,
  GridWrapper,
  GridItem,
  Heading4,
} from '@jsluna/react'

import pluralize from 'src/utils/pluralize'
import { hasRequiredPermission } from 'src/utils/permissions'

import LabelledText from 'src/components/Form/LabelledText'

import withConfirmation from 'src/hoc/withConfirmation'
import withExport from 'src/hoc/withExportLegacy'

import { UPDATE_SPACE_INSTANCES } from 'src/constants/permissions'
import { selectSelf } from 'src/store/data/selectors'

import { selectUi } from '../store'
import { exportStores } from '../store/actions'

import Cluster from './Cluster'
import Form from './Form'
import Shelves from '../Shelves'

import buildDisabled from './disabled'

const ExportButton = withExport(TextButton)

const SpaceInstance = props => {
  const {
    allInstances,
    deleteInstance,
    disabled,
    getStoresExport,
    instance,
    isAuto,
    isReadOnly,
    space,
    updateInstance,
    viewChangeControl,
    weeks,
    startDate,
    endDate,
    spaceAssignmentId,
    changeControlStatus,
    hasProducts,
  } = props
  const { clusterDistributions, title, id, totalStoreCount = 0 } = instance
  const {
    spaceType: { maxShelves, minShelves },
    id: spaceId,
  } = space
  const otherInstances = allInstances.filter(i => i.id !== id)
  const showDelete = clusterDistributions.length === 0
  const DeleteButton = withConfirmation(TextButton)
  const breakpoints = {
    md: '1/1',
    lg: '1/2',
  }

  const clusterIds = clusterDistributions.map(cluster => cluster.id)
  const exportStoresList = getStoresExport(startDate, endDate, spaceId)
  return (
    <div data-control={`space-instance-${title}`}>
      <FlagWrapper>
        <FlagComponent nowrap>
          <Heading4>
            <span data-control="space-instance-title">
              {title}
              {` - `}
              <ExportButton
                data-control="export-stores-list"
                className="ln-u-hard-left"
                getExportsData={() => exportStoresList(clusterIds)}
              >
                {totalStoreCount} {pluralize(totalStoreCount, 'store', 'stores')}
              </ExportButton>
            </span>
          </Heading4>
        </FlagComponent>
        <FlagBody alignment="top">
          {showDelete && !isAuto && (
            <div data-control="space-instance-delete-button" className="ln-u-push-left">
              <DeleteButton
                title="Are you sure you want to delete this?"
                message={`Please confirm that you want to delete ${title}.`}
                className="ln-u-color-red"
                onClick={() => deleteInstance(id)}
              >
                Delete Group
              </DeleteButton>
            </div>
          )}
        </FlagBody>
      </FlagWrapper>
      <div className="ln-u-push-bottom">
        {clusterDistributions.length > 0 ? (
          <GridWrapper>
            {clusterDistributions.map(cluster => (
              <GridItem size={breakpoints} className="c-cluster ln-u-push-bottom" key={cluster.id}>
                <Cluster
                  cluster={cluster}
                  instances={otherInstances}
                  updateInstance={updateInstance}
                  storesList={cluster.count}
                  exportStoresList={exportStoresList}
                />
              </GridItem>
            ))}
          </GridWrapper>
        ) : (
          <p className="ln-u-push-top">No clusters assigned to group</p>
        )}
      </div>
      <div>
        {isReadOnly ? (
          <GridWrapper>
            <GridItem size={breakpoints}>
              <LabelledText label="POS Comment" text={instance?.posComment || ''} />
            </GridItem>
            <GridItem size={breakpoints}>
              <LabelledText label="Comment" text={instance?.comment || ''} />
            </GridItem>
            <GridItem size={breakpoints}>
              <LabelledText label="Shelf Heights" text={instance?.shelfHeights || ''} />
            </GridItem>
            <GridItem size={breakpoints}>
              <LabelledText label="Shipper Description" text={instance?.shipperDescription || ''} />
            </GridItem>
            <GridItem size={breakpoints}>
              <LabelledText label="Suggested Location" text={instance?.suggestedLocation || ''} />
            </GridItem>
          </GridWrapper>
        ) : (
          <Form
            spaceInstance={instance}
            disabled={disabled}
            spaceAssignmentId={spaceAssignmentId}
            changeControlStatus={changeControlStatus}
            hasProducts={hasProducts}
          />
        )}
      </div>

      <Shelves
        changeControlId={viewChangeControl}
        instanceId={id}
        minShelves={minShelves}
        maxShelves={maxShelves}
        weeks={weeks}
        spaceType={space.spaceType}
        spaceAssignmentId={spaceAssignmentId}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {
    id,
    instance: { categories: spaceInstanceCategories },
  } = ownProps
  const user = selectSelf(state)
  const isReadOnly = !hasRequiredPermission(user, UPDATE_SPACE_INSTANCES)
  const { viewChangeControl } = selectUi(state)

  return {
    id,
    isReadOnly,
    disabled: buildDisabled({ user, spaceInstanceCategories }),
    viewChangeControl,
  }
}

const mapDispatchToProps = dispatch => ({
  getStoresExport: (startDate, endDate, spaceId) => async clusters =>
    dispatch(exportStores(startDate, endDate, spaceId, clusters)),
})

SpaceInstance.propTypes = {
  spaceAssignmentId: PropTypes.string.isRequired,
  instance: PropTypes.shape({
    id: PropTypes.string,
    comment: PropTypes.string,
    posComment: PropTypes.string,
    clusterDistributions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    totalStoreCount: PropTypes.number,
    shelfHeights: PropTypes.string,
    suggestedLocation: PropTypes.string,
    shipperDescription: PropTypes.string,
    title: PropTypes.string.isRequired,
    spaceInstanceCategories: PropTypes.arrayOf(PropTypes.shape),
  }).isRequired,
  isAuto: PropTypes.bool.isRequired,
  allInstances: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  updateInstance: PropTypes.func.isRequired,
  deleteInstance: PropTypes.func.isRequired,
  space: PropTypes.shape({
    id: PropTypes.string.isRequired,
    spaceType: PropTypes.shape({
      id: PropTypes.string,
      maxShelves: PropTypes.number,
      minShelves: PropTypes.number,
    }).isRequired,
  }).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  weeks: PropTypes.arrayOf(PropTypes.number).isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  disabled: PropTypes.shape({
    posComment: PropTypes.bool,
    comment: PropTypes.bool,
    shelfHeights: PropTypes.bool,
  }).isRequired,
  viewChangeControl: PropTypes.string,
  getStoresExport: PropTypes.func.isRequired,
  changeControlStatus: PropTypes.string.isRequired,
  hasProducts: PropTypes.bool.isRequired,
}

SpaceInstance.defaultProps = {
  viewChangeControl: '',
}

export default connect(mapStateToProps, mapDispatchToProps)(SpaceInstance)
