import React from 'react'
import PropTypes from 'prop-types'

import ShelfTitle from '../ShelfTitle'
import ShelfProducts from '../ShelfProducts'
import AddProductRow from '../AddProductRow'
import ShelfQuickAddProduct from '../ShelfQuickAddProduct'

import withDrop from './withDrop'

const Shelf = ({
  shelf,
  shelves,
  position,
  layoutType,
  minShelves,
  maxShelves,
  quickAddProduct,
  weeks,
  spaceType,
  canSetPalletOrStackType,
}) => (
  <div key={shelf.id} data-control={`shelf-${position}`}>
    <ShelfTitle
      shelf={shelf}
      shelves={shelves}
      position={position}
      minShelves={minShelves}
      maxShelves={maxShelves}
      weeks={weeks}
      spaceType={spaceType}
      layoutType={layoutType}
      canSetPalletOrStackType={canSetPalletOrStackType}
    />
    <ShelfProducts shelf={shelf} weeks={weeks} />
    <div className="ln-u-padding-ends">
      {quickAddProduct === shelf.id ? (
        <AddProductRow categories={shelf.categories} shelf={shelf} />
      ) : (
        <ShelfQuickAddProduct layoutType={layoutType} shelf={shelf} />
      )}
    </div>
  </div>
)

Shelf.propTypes = {
  shelf: PropTypes.shape({
    id: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  shelves: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  position: PropTypes.number.isRequired,
  minShelves: PropTypes.number.isRequired,
  maxShelves: PropTypes.number.isRequired,
  quickAddProduct: PropTypes.string.isRequired,
  weeks: PropTypes.arrayOf(PropTypes.number).isRequired,
  spaceType: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  layoutType: PropTypes.string.isRequired,
  canSetPalletOrStackType: PropTypes.bool.isRequired,
}

export default withDrop(Shelf)
