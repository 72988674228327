import React from 'react'
import PropTypes from 'prop-types'
import { Edit } from '@jsluna/icons'

const EditButton = ({ onClick }) => (
  <button
    className="c-icon-button u-bg-none"
    data-control="edit-shelf-product"
    onClick={onClick}
    type="button"
  >
    <Edit />
  </button>
)

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default EditButton
