export const defaultCompare = (a, b) => a === b
export const compareById = (a, b) => (a === null && b === null) || (a && b && a.id === b.id)

export const defaultFormat = value => (value ? String(value) : 'Not set')
export const formatYesNo = value => (value ? 'Yes' : 'No')

const defaultCompareAndFormat = {
  compare: defaultCompare,
  format: defaultFormat,
}

export const addDefaultCompareAndFormat = input => {
  const output = {}

  Object.keys(input).forEach(key => {
    output[key] = {
      ...defaultCompareAndFormat,
      ...input[key],
    }
  })

  return output
}

export const getDifferences = (before, after, spec) => {
  const differences = []
  const shouldCompare = !!(before && after)

  Object.keys(spec).forEach(fieldId => {
    const fieldSpec = spec[fieldId]
    const beforeValue = before ? before[fieldId] : null
    const afterValue = after ? after[fieldId] : null
    const isDifferent = shouldCompare ? !fieldSpec.compare(beforeValue, afterValue) : true

    if (isDifferent) {
      differences.push({
        id: fieldId,
        label: fieldSpec.label,
        beforeValue: before ? fieldSpec.format(beforeValue) : '',
        afterValue: after ? fieldSpec.format(afterValue) : '',
        group: fieldSpec.group,
      })
    }
  })

  return differences
}

export const groupBy = (collection, groupByKey) => {
  const groups = {}

  collection.forEach(item => {
    const groupKeyValue = item[groupByKey]
    const group = groups[groupKeyValue]
    if (group) {
      group.push(item)
    } else {
      groups[groupKeyValue] = [item]
    }
  })

  return groups
}
