import { createSelector } from 'reselect'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  handleCreateAsyncThunkResult,
  buildThunkPrefix,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import { GetPromoMechanics } from 'src/api/ShelfProduct'

const PROMOMECHANICS = 'PromoMechanics'

const selectModule = state => state.modules.PromoMechanics
const selectPromoMechanics = createSelector(selectModule, module => module?.data || [])

const fetchPromoMechanics = createAsyncThunk(
  buildThunkPrefix(PROMOMECHANICS, 'fetchPromoMechanics'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getPromoMechanicsInstance = new GetPromoMechanics(store)

    const response = await handleCreateAsyncThunkResult(
      getPromoMechanicsInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const promoMechanicsSlice = createSlice({
  name: PROMOMECHANICS,
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchPromoMechanics.pending, pendingDataHandler)
    builder.addCase(fetchPromoMechanics.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchPromoMechanics.rejected, rejectedDataHandler)
  },
})

export { fetchPromoMechanics, selectPromoMechanics }
export default { [PROMOMECHANICS]: promoMechanicsSlice.reducer }
