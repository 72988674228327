import { createSelector } from 'reselect'
import { selectSpaceAssignmentsByCycleId, selectUi } from '..'

const identity = v => v

export const selectFilter = createSelector(selectUi, state => state.filter)
export const selectSpaceMultiSelect = createSelector(selectUi, state => state.multiselect)

const findUniqueSets = spaceAssignments =>
  spaceAssignments.reduce((acc, { spaceId: id, spaceTitle: title }) => {
    if (id) {
      acc[id] = { id, title }
    }
    return acc
  }, {})

const selectFilteredSpaceAssignments = state => {
  const { categories: selectedCategories, categorySelectionType } = selectFilter(state)
  const spaceAssignments = selectSpaceAssignmentsByCycleId(state)
  const searchSpaces = selectSpaceMultiSelect(state)

  // Finding all spaces
  const spacesById = findUniqueSets(spaceAssignments)
  const allSpaces = Object.values(spacesById)

  const filterOnSpaceIds = s => {
    const searchedIds = searchSpaces.map(({ id }) => id)

    return searchedIds.includes(s.id)
  }

  // Filtering functions
  const filterOnSelectedSearch = space => {
    const filterSpaceBy = () => {
      if (searchSpaces.length) {
        return filterOnSpaceIds
      }

      return identity
    }

    const spacesFilteredOnSearch = allSpaces.filter(filterSpaceBy())
    const spacesIds = spacesFilteredOnSearch.map(({ id }) => id)
    const intersection = spacesIds.filter(id => space.spaceId === id)

    if (intersection.length > 0) {
      return true
    }

    return false
  }

  const filterOnSelectedCategory = (categories, chosenCategories) => {
    const categoryIds = categories.map(({ id }) => id)
    const intersection = categoryIds.filter(id => chosenCategories.includes(id))

    if (intersection.length > 0) {
      return true
    }

    return false
  }

  const filterOnBusinessUnit = ({ categories }) => {
    const categoryBusinessUnits = categories.map(({ businessUnit }) => businessUnit)
    if (categorySelectionType === 'food' && categoryBusinessUnits.includes('food')) return true
    if (categorySelectionType === 'non-food' && categoryBusinessUnits.includes('non-food'))
      return true
    if (categorySelectionType === 'none' && categoryBusinessUnits.length === 0) return true
    if (['all', 'user', 'custom'].includes(categorySelectionType)) return true
    return false
  }
  const spaceAssignmentsFilteredOnSearch = spaceAssignments.filter(filterOnSelectedSearch)

  const spaceAssignmentFilteredOnCategory =
    selectedCategories && selectedCategories.length > 0
      ? spaceAssignmentsFilteredOnSearch.filter(spaceAssignment =>
          filterOnSelectedCategory(spaceAssignment.categories, selectedCategories)
            ? spaceAssignment
            : false
        )
      : spaceAssignmentsFilteredOnSearch

  const filteredSpaceAssignments = spaceAssignmentFilteredOnCategory.filter(filterOnBusinessUnit)
  return {
    selectedCategories,
    categorySelectionType,
    filteredSpaceAssignments,
  }
}

export const selectSpaceAssignments = createSelector(
  selectFilteredSpaceAssignments,
  ({ selectedCategories, categorySelectionType, filteredSpaceAssignments }) => ({
    categories: selectedCategories,
    categorySelectionType,
    spaceAssignments: filteredSpaceAssignments,
  })
)
