import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export const toTag = ({ id, title, theme, active }) => ({
  id,
  title,
  color: theme,
  ...(!active ? { opacity: 0.5 } : {}),
})

const Tag = ({ id, title, color, opacity }) => {
  const style = {
    ...(color ? { color } : {}),
    ...(opacity !== undefined ? { opacity } : {}),
  }
  return (
    <li key={id} className="c-tag-list__item">
      <span className="c-tag" style={style}>
        {title}
      </span>
    </li>
  )
}

Tag.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  opacity: PropTypes.number,
}

Tag.defaultProps = {
  color: undefined,
  opacity: undefined,
}

const Tags = ({ items = [], noWrap }) => (
  <ol className={classNames('c-tag-list', noWrap && 'c-tag-list--no-wrap')}>{items.map(Tag)}</ol>
)

Tags.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(Tag.propTypes)).isRequired,
  noWrap: PropTypes.bool,
}

Tags.defaultProps = {
  noWrap: false,
}

export default Tags
