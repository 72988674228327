import { connect } from 'react-redux'

import { selectAllAdminConfigMessages } from 'src/store/modules/asyncThunks/adminConfigMessages'
import Form from './Form'

const mapStateToProps = state => ({
  messages: selectAllAdminConfigMessages(state),
})

const mapDispatchToProps = dispatch => ({
  submit: action => dispatch(action),
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
