import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SplitButton from './SplitButton'

class SplitButtonWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isExpanded: false,
    }

    this.toggleExpanded = this.toggleExpanded.bind(this)
  }

  toggleExpanded(isExpanded) {
    this.setState(() => ({ isExpanded: !isExpanded }))
  }

  render() {
    const { toggleExpanded } = this
    const { options } = this.props
    const { isExpanded } = this.state

    return <SplitButton isExpanded={isExpanded} toggleExpanded={toggleExpanded} options={options} />
  }
}

SplitButtonWrapper.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      type: PropTypes.string,
    })
  ).isRequired,
}

export default SplitButtonWrapper
