import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Card, Heading2, Tabs, TabLink, TabPanel } from '@jsluna/react'
import { SSPSA_VERSIONS, SSPSA_VERSION_PREVIEW } from 'src/constants/sspsaVersion'

import { connect } from 'react-redux'

import Section from 'src/components/Section'

import { selectAllAdminConfigMessages } from 'src/store/modules/asyncThunks/adminConfigMessages'
import { selectAllAvailableSspsas } from './store'
import SspsaVersion from './SspsaVersion'
import { selectStoreDetails } from './store/storeDetails'

const tabs = [
  { name: 'Food', key: 'food', type: 'food' },
  { name: 'Non Food', key: 'non-food', type: 'gm' },
]

const RangeAndSpaceComponent = ({ availableSspsas, store, messages }) => {
  const { storeNumber, name: storeName } = store
  const [activeTab, setActiveTab] = useState('food')
  const [activeType, setActiveType] = useState('food')
  return (
    <Section>
      <Heading2>
        <span>
          Range and space for ({storeNumber}) {storeName}
        </span>
      </Heading2>
      <Tabs>
        {tabs.map(tab => (
          <TabLink
            key={tab.key}
            onClick={() => {
              setActiveTab(tab.key)
              setActiveType(tab.type)
            }}
            active={activeTab === tab.key}
            data-control={`${tab.key}-tab`}
          >
            {tab.name}
          </TabLink>
        ))}
      </Tabs>
      <TabPanel data-control={`${activeTab}-panel`}>
        <Card>
          {SSPSA_VERSIONS.map(version => {
            const sspsas =
              availableSspsas?.filter(
                item => item.businessUnit === activeTab && version.includes(item.week)
              ) || undefined

            if (version === SSPSA_VERSION_PREVIEW && sspsas.length === 0) {
              return null
            }

            return (
              <SspsaVersion
                key={`${storeName}-${version}`}
                storeNumber={storeNumber}
                storeName={storeName}
                version={version}
                sspsas={sspsas}
                messages={messages}
                type={activeType}
              />
            )
          })}
        </Card>
      </TabPanel>
    </Section>
  )
}

RangeAndSpaceComponent.propTypes = {
  store: PropTypes.shape({
    name: PropTypes.string.isRequired,
    storeNumber: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  availableSspsas: PropTypes.arrayOf(
    PropTypes.shape({
      generatedSspsas: PropTypes.arrayOf(
        PropTypes.shape({
          generatedAt: PropTypes.string,
          cycleId: PropTypes.string,
        })
      ),
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      week: PropTypes.string.isRequired,
      areSspsaGeneratedForThisPeriod: PropTypes.bool.isRequired,
    }).isRequired
  ),
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
}

RangeAndSpaceComponent.defaultProps = {
  availableSspsas: [],
  messages: [],
}

export const mapStateToProps = state => ({
  availableSspsas: selectAllAvailableSspsas(state),
  store: selectStoreDetails(state),
  messages: selectAllAdminConfigMessages(state),
})

export default connect(mapStateToProps)(RangeAndSpaceComponent)

export { RangeAndSpaceComponent }
