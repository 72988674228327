import { selectSelfCategories } from 'src/store/data/selectors'

import { ALL, NONE, USER, FOOD, NON_FOOD, CUSTOM } from 'src/constants/spacePlanner'
import { selectCategories as selectAllCategories } from 'src/store/modules/asyncThunks/categories'

const CATEGORY_FORM_NAME = 'cycleSpacesCategoryFilter'
const SPACE_FORM_NAME = 'cycleSpacesSpaceFilter'
const SAVE_CATEGORY_FILTER = 'cycle-spaces-save-category-filter'
const SAVE_SPACE_FILTER = 'cycle-spaces-save-space-filter'
const SAVE_SPACE_MULTISELECT = 'cycle-spaces-save-space-multiselect'

const saveCategoryFilter = payload => ({
  type: SAVE_CATEGORY_FILTER,
  payload: { categorySelectionType: CUSTOM, ...payload },
})

const saveSpaceFilter = payload => ({
  type: SAVE_SPACE_FILTER,
  payload,
})

const filterByBusinessUnit = (businessUnit, categories) =>
  categories.filter(category => category.businessUnit === businessUnit)

const saveSpaceMultiselect = payload => ({
  type: SAVE_SPACE_MULTISELECT,
  payload,
})

const filterInAllCategories = () => (dispatch, getState) => {
  const categories = selectAllCategories(getState()).map(category => category.id)
  return dispatch(saveCategoryFilter({ categories, categorySelectionType: ALL }))
}

const filterOutAllCategories = () => dispatch =>
  dispatch(saveCategoryFilter({ categories: [], categorySelectionType: NONE }))

const filterInAllUserCategories = () => (dispatch, getState) => {
  const categories = selectSelfCategories(getState()).map(category => category.id)
  return dispatch(saveCategoryFilter({ categories, categorySelectionType: USER }))
}

const filterInFoodCategories = () => (dispatch, getState) => {
  const allCategories = selectAllCategories(getState())
  const categories = filterByBusinessUnit(FOOD, allCategories).map(category => category.id)
  return dispatch(saveCategoryFilter({ categories, categorySelectionType: FOOD }))
}

const filterInNonFoodCategories = () => (dispatch, getState) => {
  const allCategories = selectAllCategories(getState())
  const categories = filterByBusinessUnit(NON_FOOD, allCategories).map(category => category.id)
  return dispatch(saveCategoryFilter({ categories, categorySelectionType: NON_FOOD }))
}

export {
  SAVE_CATEGORY_FILTER,
  SAVE_SPACE_FILTER,
  SAVE_SPACE_MULTISELECT,
  CATEGORY_FORM_NAME,
  SPACE_FORM_NAME,
  saveCategoryFilter,
  saveSpaceFilter,
  saveSpaceMultiselect,
  filterInAllCategories,
  filterOutAllCategories,
  filterInFoodCategories,
  filterInNonFoodCategories,
  filterInAllUserCategories,
}
