export const hasRequiredCategory = (user = {}, required) => {
  const { categories = [] } = user
  const categoryIds = categories.map(c => c.id)

  return categoryIds.includes(required)
}

export const hasPermission = (
  user,
  requiredPermission,
  entityCategories = [],
  entityOwnerId = null
) => {
  const permissions = user?.role?.permissions || []

  const requiredPermissions = Array.isArray(requiredPermission)
    ? requiredPermission
    : [requiredPermission]

  // eslint-disable-next-line no-restricted-syntax
  for (const permission of requiredPermissions) {
    if (permissions.includes(`${permission}-all`)) {
      return true
    }

    const userCategories = user?.categories || []
    const userCategoryIds = userCategories.map(c => c.id)
    const entityCategoryIds = new Set(entityCategories.map(c => c.id))

    const hasCategory = userCategoryIds.some(cid => entityCategoryIds.has(cid))

    if (permissions.includes(`${permission}-own-category`) && hasCategory) {
      return true
    }

    if (permissions.includes(`${permission}-own`) && entityOwnerId) {
      return user.id === entityOwnerId
    }

    if (permissions.includes(permission) && hasCategory) {
      return true
    }
  }
  return false
}

export const hasRequiredPermission = (user = {}, required) => {
  const { role } = user
  const { permissions = [] } = role || {}
  return !required || permissions.includes(required)
}

export const hasRequiredRole = (user = {}, required) => {
  const { role } = user
  const { id } = role || {}

  if (!required) {
    return true
  }

  if (Array.isArray(required)) {
    return required.includes(id)
  }

  return required === id
}
