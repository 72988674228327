import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Field, withFormik } from 'formik'

import { Form, FormGroup, GridWrapper, GridItem } from '@jsluna/react'
import { SelectField } from '@jsluna/form/extensions/formik'

import sortNatural from 'src/utils/sortNatural'
import { getDisplayText } from 'src/utils/datePeriod'

import { Controls } from 'src/components/Form'
import { DateField } from 'src/components/FormikComponents'

import LoadingCard from 'src/components/LoadingCard'
import handleSubmit from './handleSubmit'
import validate from './validate'

const Component = ({
  cycles,
  handleSubmit: onSubmit,
  maxDate,
  setFieldValue,
  onChangeBusinessUnit,
  isCycleLoading,
  isSubmitting,
}) => {
  const [enableOptions, setEnableOptions] = useState(true)
  const [businessUnit, setBusinessUnit] = useState('food')
  const exportTypeOption = [
    { label: 'Master PSA', value: 'master-psa' },
    { label: 'Space Allocation Report', value: 'space-allocation-report' },
    { label: 'POS Advice', value: 'pos-advice' },
  ]

  const filteredCycle = sortNatural([...cycles], 'endDate', true)
  const toOption = val => ({
    label: `${val.title} ${val.groupName} ${getDisplayText(val)}`,
    value: val.id,
  })

  const onChange = ({ target: { value } }) => {
    const { startDate = '', endDate = '' } = filteredCycle.find(cycle => cycle.id === value)

    setFieldValue('startDate', startDate)
    setFieldValue('endDate', endDate)
  }

  const onChangeExportType = ({ target: { value } }) => {
    setFieldValue('exportType', value)
    if (value === 'pos-advice') {
      setEnableOptions(false)
    } else {
      setEnableOptions(true)
    }
  }

  const businessUnitOnChange = ({ target: { value } }) => {
    setFieldValue('startDate', new Date().toISOString().slice(0, 10))
    setFieldValue('endDate', '')
    setBusinessUnit(value)
    onChangeBusinessUnit(value)
  }

  const businessUnitOptions = [
    { value: 'food', label: 'Food Cycles' },
    { value: 'non-food', label: 'Non-food Cycles' },
  ]

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup name="exports-fields">
        <GridWrapper matrix>
          <GridItem size="1/4">
            <Field
              name="exportType"
              label="Export Type"
              component={SelectField}
              options={exportTypeOption}
              defaultValue="master-psa"
              onChange={onChangeExportType}
            />
          </GridItem>
          {enableOptions && (
            <>
              <GridItem size="1/4">
                <Field
                  name="cycleType"
                  label="Cycle Type"
                  component={SelectField}
                  options={businessUnitOptions}
                  defaultValue={businessUnit}
                  onChange={businessUnitOnChange}
                />
              </GridItem>
              {!isCycleLoading ? (
                <>
                  <GridItem size="1/2">
                    <Field
                      name="cycleFilterType"
                      label="Cycle"
                      component={SelectField}
                      options={filteredCycle.map(toOption)}
                      onChange={onChange}
                    />
                  </GridItem>

                  <GridItem size="1/4">
                    <DateField label="Start Date" name="startDate" required />
                  </GridItem>
                  <GridItem size="1/4">
                    <DateField label="End Date" maxDate={maxDate} name="endDate" required />
                  </GridItem>
                </>
              ) : (
                <LoadingCard />
              )}
            </>
          )}
        </GridWrapper>
      </FormGroup>
      {!isCycleLoading && <Controls submitText="Export" disabled={isSubmitting} />}
    </Form>
  )
}

Component.propTypes = {
  cycles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  maxDate: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  onChangeBusinessUnit: PropTypes.func.isRequired,
  isCycleLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired, // From Formik, handleSubmit
}

Component.defaultProps = {
  maxDate: null,
  values: {},
  isCycleLoading: false,
}

const wrappedForm = withFormik({
  handleSubmit,
  validate,
  validateOnMount: true,
})(Component)

export default wrappedForm
