import React from 'react'
import PropTypes from 'prop-types'

const CoverageOverall = ({ timeline: { coverage } }) => (
  <div className="c-stat">
    <div className="c-stat__value" data-control="coverage-overall">{`${coverage}%`}</div>
    <div className="c-stat__label">Space Coverage</div>
  </div>
)

CoverageOverall.propTypes = {
  timeline: PropTypes.shape({
    coverage: PropTypes.number.isRequired,
  }).isRequired,
}

export default CoverageOverall
