import React from 'react'
import PropTypes from 'prop-types'
import { Field, withFormik } from 'formik'

import { Card, Heading2, Form, FormGroup, GridWrapper, GridItem, Section } from '@jsluna/react'
import { SelectField } from '@jsluna/form/extensions/formik'

import { buildCategoryOptions } from 'src/utils/buildCategoryOptions'
import sortArray from 'src/utils/sortArray'

import { Controls } from 'src/components/Form'
import { LabelledText, CheckboxGroup } from 'src/components/FormikComponents'

import handleSubmit from './handleSubmit'

const UserDetailForm = ({
  handleSubmit: onSubmit,
  roles,
  allCategories,
  cancel,
  handleReject,
  user,
}) => {
  const highlightInactive = category => ({
    ...category,
    title: category.active ? category.title : `${category.title} (inactive)`,
  })
  const allSortedCategories = sortArray(allCategories, 'isPriceLock', false, 'title')
  const roleToOption = ({ id, title }) => ({ id, label: title, value: id })
  const categoryOptions = buildCategoryOptions(allSortedCategories.map(highlightInactive))
  const roleOptions = sortArray(roles, 'title').map(roleToOption)

  return (
    <Form onSubmit={onSubmit}>
      <Heading2>Edit User</Heading2>
      <Section>
        <Card>
          <FormGroup name="user-detail-fields">
            <GridWrapper matrix>
              <GridItem size="1/1" data-control="user-name">
                <Field label="Name" component={LabelledText} name="user.name" />
              </GridItem>
              <GridItem size="1/1" data-control="user-email">
                <Field label="Email" name="user.email" component={LabelledText} />
              </GridItem>
              <GridItem size="1/2@sm">
                <Field
                  name="user.roleId"
                  label="Role"
                  component={SelectField}
                  options={roleOptions}
                />
              </GridItem>
              <GridItem size="1/1">
                <CheckboxGroup
                  name="user.userCategories"
                  label="Categories"
                  options={categoryOptions}
                />
              </GridItem>
            </GridWrapper>
          </FormGroup>
          <Controls
            submitText="Save"
            resetText="Reject"
            cancel={cancel}
            reset={() => handleReject(user)}
          />
        </Card>
      </Section>
    </Form>
  )
}

UserDetailForm.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    roleId: PropTypes.string,
    userCategories: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  allCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      businessUnit: PropTypes.oneOf(['food', 'non-food']).isRequired,
      isPriceLock: PropTypes.bool.isRequired,
    })
  ),
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  cancel: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
}

UserDetailForm.defaultProps = {
  allCategories: [],
  roles: [],
}

const wrappedForm = withFormik({ handleSubmit })(UserDetailForm)

export default wrappedForm
