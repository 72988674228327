import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = props => {
  const {
    id,
    input: { name, onChange },
    label,
    checked,
    value,
    disabled,
  } = props
  return (
    <div className="ln-c-form-option ln-c-form-option--checkbox">
      <input
        className="ln-c-form-option__input"
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className="ln-c-form-option__label" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  id: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}

Checkbox.defaultProps = {
  id: undefined,
  checked: false,
  disabled: false,
}

export default Checkbox
