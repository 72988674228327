import { selectUi } from '../store'

const mapStateToProps = state => {
  const {
    filter: { spaces },
  } = selectUi(state)

  return {
    spaces: {
      excludeSpaceWithNoMatchingCategories: false,
      excludeSpaceWithFullCoverage: false,
      ...spaces,
    },
  }
}

export default mapStateToProps
