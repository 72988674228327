import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  NotificationItem,
  ProgressIndicator,
  ProgressSpinner,
  ProgressMessage,
} from '@jsluna/react'

import selectLoading from 'src/store/ui/loading/selectors'

function getMessage(count) {
  return `Loading (${count} request${count !== 1 ? 's' : ''})`
}

function Wrapper({ message }) {
  return (
    <NotificationItem open>
      <ProgressIndicator loading preventFocus>
        <ProgressSpinner className="ln-u-push-right-sm" />
        <ProgressMessage>{message}</ProgressMessage>
      </ProgressIndicator>
    </NotificationItem>
  )
}

Wrapper.propTypes = {
  message: PropTypes.string.isRequired,
}

const Container = ({ loading }) =>
  loading.inProgress ? <Wrapper message={getMessage(loading.count)} /> : null

Container.propTypes = {
  loading: PropTypes.shape({ count: PropTypes.number, inProgress: PropTypes.bool }).isRequired,
}

const mapStateToProps = state => ({
  loading: selectLoading(state),
})

export default connect(mapStateToProps)(Container)
