import React from 'react'
import PropTypes from 'prop-types'

import ShipperCompliance from 'src/modules/ShipperCompliance'

const Component = ({ id: cycleId }) => <ShipperCompliance cycleId={cycleId} />

Component.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Component
