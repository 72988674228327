import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Card, GridWrapper, GridItem, Heading4 } from '@jsluna/react'

import FrozenCell from './FrozenCell'

const FrozenContainer = ({
  addFrozenPosition,
  className,
  columns,
  columnNames,
  deleteFrozenPosition,
  instanceId,
  locations,
  rows,
  shelfProducts,
  shelfProductLayouts,
  title,
}) => (
  <div className={`${classNames('ln-u-push-bottom', className)}`}>
    {title && <Heading4>{title}</Heading4>}
    <GridWrapper>
      {[...Array(columns)].map((column, columnIndex) => {
        const location = locations[columnIndex]
        return (
          // eslint-disable-next-line react/no-array-index-key
          <GridItem size={`1/${columns}`} key={`frozen-layout-column-${location}-${columnIndex}`}>
            {columnNames.length > 0 && <Heading4>{columnNames[columnIndex]}</Heading4>}
            <Card className="ln-u-bg-color-light ln-u-soft-sm">
              {[...Array(rows)].map((row, rowIndex) => (
                <FrozenCell
                  // eslint-disable-next-line react/no-array-index-key
                  key={`frozen-layout-cell-${columnIndex}-${rowIndex}`}
                  addFrozenPosition={addFrozenPosition}
                  deleteFrozenPosition={deleteFrozenPosition}
                  index={rowIndex}
                  instanceId={instanceId}
                  location={location}
                  shelfProducts={shelfProducts}
                  shelfProductLayouts={shelfProductLayouts}
                />
              ))}
            </Card>
          </GridItem>
        )
      })}
    </GridWrapper>
  </div>
)

FrozenContainer.propTypes = {
  addFrozenPosition: PropTypes.func,
  className: PropTypes.string,
  columns: PropTypes.number,
  columnNames: PropTypes.arrayOf(PropTypes.string),
  deleteFrozenPosition: PropTypes.func,
  instanceId: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.number,
  shelfProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  shelfProductLayouts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string,
}

FrozenContainer.defaultProps = {
  addFrozenPosition: () => {},
  className: null,
  columns: 1,
  columnNames: [],
  deleteFrozenPosition: () => {},
  rows: 1,
  title: '',
}

export default FrozenContainer
