import React from 'react'
import PropTypes from 'prop-types'
import { Field, withFormik } from 'formik'
import { Form, FormGroup, GridWrapper, GridItem, Heading2, Section, Card } from '@jsluna/react'
import { Controls } from 'src/components/Form'
import sortArray from 'src/utils/sortArray'
import { SelectField, TextInputField } from '@jsluna/form/extensions/formik'
import CheckboxGroup from './components/CheckboxGroup'
import handleSubmit from './handleSubmit'
import validate from './validate'
import ReadOnlySelectedSpace from './ReadOnlySelectedSpaces'

const Component = ({
  handleSubmit: onSubmit,
  group,
  handleReset,
  allSpaceIds,
  spacePlans,
  cancel,
  onChange,
  handleChange,
  values,
  setFieldValue,
  isReadOnly,
}) => {
  const spaceToOption = ({ id, title, selected, availableForSelection }) => ({
    id,
    label: title,
    value: id,
    selected,
    availableForSelection,
  })
  const onChangeValue = e => {
    onChange(e.target.value, group.id)
    handleChange(e)
    setFieldValue('group.groupSpaceIds', [])
    setFieldValue('search', '')
  }
  const spaceOptions = sortArray(allSpaceIds, 'title').map(spaceToOption)
  const spacePlanToOption = ({ id, title }) => ({ id, label: title, value: id })
  const spacePlanOptions = sortArray(spacePlans, 'title').map(spacePlanToOption)
  const disabledSpacePlan = group.spacePlanId !== ''

  return (
    <Form onSubmit={onSubmit} noValidate>
      <Heading2>{group.id ? 'Edit Group' : 'Create Group'}</Heading2>
      <Section>
        <Card>
          <FormGroup name="groups-fields" className="ln-c-form-group--with-separator">
            <GridWrapper matrix>
              <GridItem size="1/2@sm">
                <Field
                  name="group.spacePlanId"
                  label="Space Plan"
                  component={SelectField}
                  options={spacePlanOptions}
                  onChange={onChangeValue}
                  required
                  disabled={disabledSpacePlan || isReadOnly}
                />
              </GridItem>
              <GridItem size={{ sm: '1/1', md: '2/3' }}>
                <Field
                  component={TextInputField}
                  label="Title"
                  name="group.title"
                  validationFirst={false}
                  disabled={isReadOnly}
                  required
                />
              </GridItem>
              <GridItem size="1/1">
                {isReadOnly ? (
                  <ReadOnlySelectedSpace selectedSpaces={group.selectedSpaces} />
                ) : (
                  <CheckboxGroup
                    searchEnabled
                    name="group.groupSpaceIds"
                    selectedSpaces={group.groupSpaceIds}
                    label="Spaces"
                    options={spaceOptions}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    searchName={values.search}
                    disabled={isReadOnly}
                  />
                )}
              </GridItem>
            </GridWrapper>
          </FormGroup>
        </Card>
      </Section>
      {!isReadOnly && <Controls submitText="Save" reset={handleReset} cancel={cancel} />}
    </Form>
  )
}

Component.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  allSpaceIds: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  spacePlans: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  group: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    spacePlanId: PropTypes.string,
    groupSpaceIds: PropTypes.arrayOf(PropTypes.string),
    isLocked: PropTypes.bool,
    selectedSpaces: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  isReadOnly: PropTypes.bool,
}

Component.defaultProps = {
  allSpaceIds: [],
  spacePlans: [],
  isReadOnly: false,
}

const mapPropsToValues = props => ({
  ...props,
  search: '',
})
const wrappedForm = withFormik({
  mapPropsToValues,
  handleSubmit,
  validate,
})(Component)

export default wrappedForm
