import 'whatwg-fetch'
import { v4 as uuid } from 'uuid'
import ApplicationError from './ApplicationError'

const acceptableTypes = /application\/[^ ;]*(json|octet-stream|pdf)/

function buildError(correlationId, body, response = {}) {
  const apiError = (body && Array.isArray(body.errors) && body.errors[0]) || {}
  const {
    title = 'Unknown',
    status = response.status,
    detail = 'There was an unknown error',
  } = apiError

  return new ApplicationError(`Error ID ${correlationId} - ${status} ${title}. ${detail}`, {
    title,
    status,
    detail,
  })
}

function getFileName(str) {
  const filenameRegex = /filename=(.*)/
  const matches = filenameRegex.exec(str)

  if (matches !== null && matches[1]) {
    return matches[1]
  }

  return ''
}

const parseResponse = correlationId => response => {
  const contentType = response.headers.get('content-type')
  const disposition = response.headers.get('content-disposition')

  if (response.status === 204) {
    return {}
  }

  if (!contentType || !acceptableTypes.test(contentType)) {
    throw new Error(`Response was not of acceptable content. Content is ${contentType}`)
  }

  const responseType =
    contentType === 'application/octet-stream' || contentType === 'application/pdf'
      ? 'blob'
      : 'json'

  return response[responseType]().then(body => {
    if (!response.ok) {
      throw buildError(correlationId, body, response)
    }

    if (disposition && disposition.includes('attachment')) {
      return {
        body,
        fileName: getFileName(disposition),
      }
    }

    return body
  })
}

function fetchJson(url, token, options = {}, body) {
  const headers = token ? { Authorization: `Bearer ${token}`, ...options.headers } : {}

  const correlationId = uuid()

  headers['X-Correlation-ID'] = correlationId

  return Promise.resolve(fetch(url, { ...options, headers, body })).then(
    parseResponse(correlationId)
  )
}

export default fetchJson
