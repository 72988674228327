import PropTypes from 'prop-types'
import React from 'react'
import { Card } from '@jsluna/react'

import Form from './Form'

const Component = ({ id, user, businessUnit }) => (
  <Card>
    <Form id={id} businessUnit={businessUnit} user={user} />
  </Card>
)

Component.propTypes = {
  id: PropTypes.string,
  businessUnit: PropTypes.oneOf(['food', 'non-food']).isRequired,
  user: PropTypes.shape({}).isRequired,
}

Component.defaultProps = {
  id: null,
}

export default Component
