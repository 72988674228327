import { compareById, formatYesNo, addDefaultCompareAndFormat } from 'src/utils/difference'
import compareByPOSInfo from 'src/utils/compareByPOSInfo'
import formatDate from 'src/utils/formatDate'
import createPosName from 'src/utils/createPOSName'

const compareSalesPhaseWeeks = (a, b) => {
  if (a === null && b === null) return true
  if (a === null || b === null) return false
  if (a.length !== b.length) return false
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false
  }
  return true
}

const compareClusters = (a, b) => {
  if (a.length !== b.length) return false
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < a.length; i++) {
    if (a[i].id !== b[i].id) return false
  }
  return true
}

const formatByTitle = object => object.title || object.id

const formatSalesPhaseWeeks = weeks => {
  if (weeks) {
    return weeks.map(week => week || 'not set').join(', ')
  }

  return 'Not set'
}

export const formatClusters = clusters => {
  const clusterTitles = clusters.map(cluster => cluster.title || cluster.id)

  if (clusterTitles.length === 0) {
    return 'All Clusters in Group'
  }
  if (clusterTitles.length === 1) {
    return clusterTitles[0]
  }

  const offset = clusterTitles.length - 1
  const start = clusterTitles.slice(0, offset).join(', ')
  return `${start} and ${clusterTitles[offset]}`
}

const shelfFillTypeLabels = {
  case: 'Case Fill',
  unit: 'Unit Fill',
}
const formatShelfFillType = shelfFillType => shelfFillTypeLabels[shelfFillType] || 'Not set'

const formatByPOSAdvice = pointOfSaleAdvice => createPosName(pointOfSaleAdvice || {}) || 'Not set'

const specification = {
  sku: {
    label: 'SKU',
    group: 'SKU',
  },
  comment: {
    label: 'Comment',
    group: 'SKU',
  },
  storeComment: {
    label: 'Store Comment',
    group: 'SKU',
  },
  oldProductTitle: {
    label: 'Old SKU',
    group: 'Old SKU',
  },
  shelfFill: {
    label: 'Shelf Fill',
    group: 'Supply Chain',
  },
  shelfFillType: {
    label: 'Shelf Fill Type',
    format: formatShelfFillType,
    group: 'Supply Chain',
  },
  promoCaseSize: {
    label: 'Promo Case Size',
    group: 'Supply Chain',
  },
  percentUpliftAisle: {
    label: 'RMS % Uplift for Aisle',
    group: 'Supply Chain',
  },
  percentUpliftPlinth: {
    label: 'RMS % Uplift for Plinth',
    group: 'Supply Chain',
  },
  category: {
    label: 'Category',
    compare: compareById,
    format: formatByTitle,
    group: 'Promotion',
  },
  promoMechanicType: {
    label: 'Promotional Mechanic Type',
    group: 'Promotion',
  },
  promoMechanicValue: {
    label: 'Promotional Mechanic Value',
    group: 'Promotion',
  },
  packSize: {
    label: 'Pack Size',
    group: 'Promotion',
  },
  pricePoint: {
    label: 'Price Point',
    group: 'Promotion',
  },
  facingsOnShelf: {
    label: 'Facings on Shelf',
    group: 'Promotion',
  },
  ubw: {
    label: 'UBW',
    group: 'Promotion',
  },
  profitPpp: {
    label: 'Profit (PPP)',
    group: 'Promotion',
  },
  originalRetailPrice: {
    label: 'Original Retail Price',
    group: 'Promotion',
  },
  clearancePrice: {
    label: 'Clearance Price',
    group: 'Promotion',
  },
  wigig: {
    label: 'WIGIG/Range Out',
    format: formatYesNo,
    group: 'Promotion',
  },
  specialBuy: {
    label: 'Special buy',
    format: formatYesNo,
    group: 'Promotion',
  },
  percentSoldPromoPrice: {
    label: '% Sold at Promo Price',
    group: 'Non-Food Financials',
  },
  percentSoldClearancePrice: {
    label: '% Sold at Clearance Price',
    group: 'Non-Food Financials',
  },
  salesForecastPromoCycle: {
    label: 'Forecasted Sales for Cycle',
    group: 'Non-Food Financials',
  },
  salesForecastLine: {
    label: 'Forecasted Sales for Line',
    group: 'Non-Food Financials',
  },
  soaPerUnit: {
    label: 'SOA per Unit',
    group: 'Non-Food Financials',
  },
  bulkSupport: {
    label: 'Bulk Support',
    group: 'Non-Food Financials',
  },
  salesPhaseWeeks: {
    label: 'Sales Phasing',
    group: 'Non-Food Financials',
    compare: compareSalesPhaseWeeks,
    format: formatSalesPhaseWeeks,
  },
  forecastPurchaseQuantity: {
    label: 'Forecast Purchase Quantity',
    group: 'Non-Food Stock',
  },
  nppRequired: {
    label: 'NPP Required',
    group: 'Non-Food Stock',
    format: formatYesNo,
  },
  nppInStoreDate: {
    label: 'NPP In Store Date',
    group: 'Non-Food Stock',
    format: formatDate,
  },
  stockInShipperFdsu: {
    label: 'Stock in Shipper/FSDU',
    group: 'Non-Food Stock',
    format: formatYesNo,
  },
  stockValueForPromo: {
    label: 'Stock Value Bought for Promo (Full Retail)',
    group: 'Non-Food Stock',
  },
  clusters: {
    label: 'Clusters',
    group: 'Clusters',
    compare: compareClusters,
    format: formatClusters,
  },
  jdaActioned: {
    label: 'JDA Actioned',
    format: formatYesNo,
    group: 'Other',
  },
  pointOfSaleAdvice: {
    label: 'POS Advice',
    compare: compareByPOSInfo,
    format: formatByPOSAdvice,
  },
}

export default addDefaultCompareAndFormat(specification)
