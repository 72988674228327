export const STORE_BUILT_STACK = {
  id: 'store-built-stack',
  value: 'store-built-stack',
  label: 'STORE-BUILT STACK',
}

export const PREFILLED_SHIPPER = {
  id: 'prefilled-shipper',
  value: 'prefilled-shipper',
  label: 'PRE-FILLED SHIPPER',
}

export const PART_PREFILLED_SHIPPER = {
  id: 'part-prefilled-shipper',
  value: 'part-prefilled-shipper',
  label: 'PART PRE-FILLED SHIPPER',
}

export const FLATPACK_SHIPPER_MERCHANDISED_BY_SUPPLIER = {
  id: 'flatpack-shipper-merchandised-by-supplier',
  value: 'flatpack-shipper-merchandised-by-supplier',
  label: 'FLATPACK SHIPPER – MERCHANDISED BY SUPPLIER',
}

export const FLATPACK_SHIPPER_MERCHANDISED_BY_STORE = {
  id: 'flatpack-shipper-merchandised-by-store',
  value: 'flatpack-shipper-merchandised-by-store',
  label: 'FLATPACK SHIPPER – MERCHANDISED BY STORE',
}

export const PREFILLED_PALLET = {
  id: 'prefilled-pallet',
  value: 'prefilled-pallet',
  label: 'PRE-FILLED PALLET',
}

export const STORE_BUILT_PALLET = {
  id: 'store-built-pallet',
  value: 'store-built-pallet',
  label: 'STORE-BUILT PALLET',
}

export const HALF_PALLET = {
  id: 'half-pallet',
  value: 'half-pallet',
  label: 'HALF-PALLET',
}

export const QUARTER_PALLET = {
  id: 'quarter-pallet',
  value: 'quarter-pallet',
  label: 'QUARTER-PALLET',
}

export const PALLET_AND_STACK_TYPE_OPTIONS = [
  STORE_BUILT_STACK,
  PREFILLED_SHIPPER,
  PART_PREFILLED_SHIPPER,
  FLATPACK_SHIPPER_MERCHANDISED_BY_SUPPLIER,
  FLATPACK_SHIPPER_MERCHANDISED_BY_STORE,
  PREFILLED_PALLET,
  STORE_BUILT_PALLET,
  HALF_PALLET,
  QUARTER_PALLET,
]
