import Api from 'src/api/Base'

class GetGroups extends Api {
  requestUrl = `/api/Group`

  formatResponseData = response =>
    response.data.map(group => ({
      ...group,
    }))
}

export default GetGroups
