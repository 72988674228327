import { checkLayoutTypes, isClipStrip, isPallet, isShipper, isStack } from 'src/utils/layoutTypes'

export default function handleSubmit(
  {
    comment = '',
    layoutType,
    posComment = '',
    shelfHeights = '',
    shipperDescription = '',
    suggestedLocation = '',
    overrideStartDate = '',
    overrideEndDate = '',
  },
  { props: { id, submit } }
) {
  const sentShipperDescription = checkLayoutTypes([isPallet, isShipper, isStack])
  const sentSuggestedLocation = checkLayoutTypes([isClipStrip, isPallet, isStack])

  const newShipperDescription = shipperDescription || null
  const newShelfHeights = shelfHeights || null
  const newSuggestedLocation = suggestedLocation || null

  const spaceInstance = {
    comment,
    posComment,
    overrideStartDate,
    overrideEndDate,
    ...(sentShipperDescription(layoutType) ? { shipperDescription: newShipperDescription } : {}),
    ...(!sentSuggestedLocation(layoutType) && !isShipper(layoutType)
      ? { shelfHeights: newShelfHeights }
      : {}),
    ...(sentSuggestedLocation(layoutType) ? { suggestedLocation: newSuggestedLocation } : {}),
  }

  return submit(spaceInstance, id)
}
