import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Modal } from '@jsluna/react'

const WithModal = ComposedComponent => {
  const wrappedComponentName =
    ComposedComponent.displayName || ComposedComponent.name || 'Component'

  class ModalWrapper extends Component {
    constructor(props) {
      super(props)

      this.state = {
        isOpen: false,
      }

      this.handleClose = this.handleClose.bind(this)
      this.handleOpen = this.handleOpen.bind(this)
    }

    componentDidUpdate(prevProps) {
      const { open: currentOpen } = this.props
      const { open: previousOpen } = prevProps

      if (previousOpen && previousOpen !== currentOpen) {
        this.handleClose()
      }
    }

    static getDerivedStateFromProps(props) {
      if (props.open) {
        return { isOpen: true }
      }

      return null
    }

    handleOpen() {
      this.setState({ isOpen: true })
    }

    handleClose() {
      this.setState({ isOpen: false }, () => {
        const { onClose } = this.props
        if (onClose) {
          onClose()
        }
      })
    }

    render() {
      const { element, open } = this.props
      const { isOpen } = this.state
      const buttonProps = {
        ...this.props,
        key: 'modal-button',
        onClick: this.handleOpen,
      }
      const componentProps = {
        ...this.props,
        children: null,
        handleClose: this.handleClose,
      }

      const modalRoot = document.getElementById('modal-root')

      return (
        <>
          {open === undefined && element && React.createElement(element, buttonProps)}
          {isOpen &&
            ReactDOM.createPortal(
              <Modal
                open={isOpen}
                handleClose={this.handleClose}
                data-control="modal"
                className="ln-u-text-align-left c-modal--wide u-reset-wrap"
              >
                <ComposedComponent {...componentProps} />
              </Modal>,
              modalRoot
            )}
        </>
      )
    }
  }

  ModalWrapper.displayName = `ModalWrapper(${wrappedComponentName})`

  ModalWrapper.propTypes = {
    element: PropTypes.PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func]),
    open: PropTypes.bool,
    onClose: PropTypes.func,
  }

  ModalWrapper.defaultProps = {
    element: undefined,
    open: undefined,
    onClose: undefined,
  }

  return ModalWrapper
}

export default WithModal
