import Api from 'src/api/Base'

class PatchSpaceTypeById extends Api {
  requestUrl = `/api/spaceType/${this.getParams().spaceTypeId}`

  method = 'PATCH'

  formatRequestData = params => params.spaceType
}
export default PatchSpaceTypeById
