import { saveAs } from 'file-saver'

const handleSubmit = async (
  { startDate, endDate, exportType = 'master-psa' },
  { props: { getExportsMasterPsa, getExportsSpaceAllocation, getExportsPosAdvice } }
) => {
  let exportDetail

  if (exportType === 'master-psa') {
    exportDetail = await getExportsMasterPsa(startDate, endDate)
  }
  if (exportType === 'space-allocation-report') {
    exportDetail = await getExportsSpaceAllocation(startDate, endDate)
  }
  if (exportType === 'pos-advice') {
    exportDetail = await getExportsPosAdvice()
  }

  const csvContents = new Blob(['\ufeff', exportDetail?.data], {
    type: 'application/octet-stream',
  })
  const fileName = exportDetail?.headers?.['content-disposition']
    .split(';')[1]
    .split('filename=')[1]
    .replace(/["']/g, '')

  saveAs(csvContents, fileName)
}

export default handleSubmit
