import React from 'react'
import classNames from 'classnames'

import { CoverageSpace } from '../Coverage'
import buildElements from './buildElements'

const buildSubTracks = (tracks = [], spaceId, returnTo) =>
  tracks.map(({ id, title, assignments }) => ({
    id,
    title,
    elements: buildElements({ assignments, spaceId, returnTo }),
  }))

const buildTitle = (title, hfssFlag) => {
  const hfss = hfssFlag === '1'
  return (
    <div className={classNames({ 'ln-u-color-black': !hfss }, { 'ln-u-color-red': hfss })}>
      {title}
    </div>
  )
}

const buildTrack = ({
  assignments,
  blanks,
  gaps,
  coverage,
  shelfCoverage,
  id,
  title,
  hfssFlag,
  startDate,
  endDate,
  returnTo,
  tracks,
  isOpen,
  context,
}) => ({
  id: `track-${id}`,
  title: buildTitle(title, hfssFlag),
  tracks: buildSubTracks(tracks, id, returnTo),
  isOpen,
  context,
  elements: buildElements({
    assignments,
    blanks,
    gaps,
    spaceId: id,
    startDate,
    endDate,
    returnTo,
  }),
  sideComponent: (
    <CoverageSpace
      startDate={startDate}
      endDate={endDate}
      gaps={gaps}
      coverage={coverage}
      shelfCoverage={shelfCoverage}
    />
  ),
})

const buildTracks = ({ tracks, startDate, endDate, returnTo }) =>
  tracks.map(track => buildTrack({ ...track, startDate, endDate, returnTo }))

export default buildTracks
