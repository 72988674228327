import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  FilledButton,
  GridWrapper,
  GridItem,
  Accordion,
  FlagWrapper,
  FlagBody,
  FlagComponent,
  Heading4,
} from '@jsluna/react'
import pluralize from 'src/utils/pluralize'
import sortArray from 'src/utils/sortArray'
import getFinancialYear from 'src/utils/date'

import { UPDATE_SPACE_PLANS } from 'src/constants/permissions'
import { APPROVED_ID } from 'src/modules/Nominations/statuses'
import WithRequiredPermissions from 'src/components/WithRequiredPermissions'
import { actionGenerateCycleCategoriesByCycleId, fetchNominations } from '../store'
import RefreshNominationsDialog from './RefreshNominationsDialog'
import StatusCard from './StatusCard'

const Cycle = ({
  cycle: { id, title, startDate, nominations },
  generateCycleCategories,
  dispatchRefreshNominations,
}) => {
  const totalNominations = nominations.length
  const numberOfApprovals = nominations.filter(n => n.status === APPROVED_ID).length

  const sortedNominations = sortArray(
    [...nominations],
    'isCategoryPriceLocked',
    false,
    'categoryTitle'
  )
  return (
    <Accordion
      className="c-accordion-nominations"
      title={
        <FlagWrapper className="ln-u-soft-ends-sm">
          <FlagBody>
            <Heading4 className="ln-u-flush-bottom">
              {title} ({getFinancialYear({ date: startDate })})
            </Heading4>
          </FlagBody>
          <FlagComponent className="ln-u-push-right-lg">
            <div data-control="cycle-summary">
              {`${numberOfApprovals} of ${totalNominations} ${pluralize(
                totalNominations,
                'category',
                'categories'
              )} ready for Blitz`}
            </div>
          </FlagComponent>
        </FlagWrapper>
      }
      standalone
    >
      <WithRequiredPermissions permission={UPDATE_SPACE_PLANS}>
        <RefreshNominationsDialog
          cycleId={id}
          generateCycleCategories={generateCycleCategories}
          refreshNominations={dispatchRefreshNominations}
          element={({ onClick }) => (
            <FilledButton
              data-control="accept"
              name="accept"
              value="accept"
              onClick={onClick}
              className="ln-u-push-bottom"
            >
              Refresh Nomination Cycle Categories
            </FilledButton>
          )}
        />
      </WithRequiredPermissions>
      <GridWrapper matrix>
        {sortedNominations
          .filter(nomination => !!nomination)
          .map(nomination => (
            <GridItem size={{ md: '1/2' }} key={nomination.nominationId}>
              <StatusCard nomination={nomination} />
            </GridItem>
          ))}
      </GridWrapper>
    </Accordion>
  )
}

Cycle.propTypes = {
  cycle: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    nominations: PropTypes.arrayOf(PropTypes.shape({})),
    startDate: PropTypes.string,
  }).isRequired,
  generateCycleCategories: PropTypes.func.isRequired,
  dispatchRefreshNominations: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch, { cycle: { id: cycleId } }) => ({
  generateCycleCategories: async () => {
    await dispatch(actionGenerateCycleCategoriesByCycleId({ cycleId }))
  },
  dispatchRefreshNominations: () => {
    dispatch(fetchNominations())
  },
})

export default connect(null, mapDispatchToProps)(Cycle)
