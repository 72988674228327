import React from 'react'
import { Card, Heading2 } from '@jsluna/react'

import Section from 'src/components/Section'
import Table from 'src/components/Table'

import { selectUsers } from './store'
import columns from './columns'

const Users = () => (
  <Section>
    <Heading2>
      <span data-control="users-title">Users</span>
    </Heading2>
    <Card>
      <Table
        type="users"
        searchEnabled
        paginationEnabled
        columns={columns}
        dataSelector={selectUsers}
      />
    </Card>
  </Section>
)

export default Users
