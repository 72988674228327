import {
  CHANGE_CONTROL_ALL_IDS,
  CHANGE_CONTROL_LOCKED_ID,
  CHANGE_CONTROL_UNLOCKED_ID,
} from 'src/constants/cycles'

import createStatus from 'src/utils/statuses'

const CHANGE_CONTROL_UNLOCKED = {
  id: CHANGE_CONTROL_UNLOCKED_ID,
  title: 'Unlocked',
  transition: [CHANGE_CONTROL_UNLOCKED_ID, CHANGE_CONTROL_LOCKED_ID],
}

const CHANGE_CONTROL_LOCKED = {
  id: CHANGE_CONTROL_LOCKED_ID,
  title: 'Locked',
  transition: [CHANGE_CONTROL_LOCKED_ID, CHANGE_CONTROL_UNLOCKED_ID],
}

export const CHANGE_CONTROL_ALL_BY_ID = {
  [CHANGE_CONTROL_LOCKED_ID]: CHANGE_CONTROL_LOCKED,
  [CHANGE_CONTROL_UNLOCKED_ID]: CHANGE_CONTROL_UNLOCKED,
}

export const CHANGE_CONTROL_ALL = CHANGE_CONTROL_ALL_IDS.map(id => CHANGE_CONTROL_ALL_BY_ID[id])

export default createStatus(CHANGE_CONTROL_ALL)
