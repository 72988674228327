const toInt = number => parseInt(Number(number).toFixed(0), 10)
const clamp = (number, min = 0, max = 100) => {
  if (number < min) return min
  if (number > max) return max
  return number
}

const calculateShelfCoverage = assignments => {
  const percentage =
    assignments.reduce((total, assignment) => total + assignment.shelfCoverage, 0) /
    assignments.length
  return clamp(toInt(percentage))
}

export default calculateShelfCoverage
