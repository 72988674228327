import React from 'react'
import PropTypes from 'prop-types'
import { Card, Heading3 } from '@jsluna/react'

import { connect } from 'react-redux'
import Section from 'src/components/Section'
import TimelineTable from 'src/components/TimelineTable'
import { selectTimelineBySpacePlan } from '../store'

const Component = ({ spacePlanTimeline }) => {
  const { startDate, timeline } = spacePlanTimeline

  return (
    startDate !== undefined &&
    timeline.stages && (
      <Section>
        <Heading3 className="ln-u-visually-hidden">Timeline</Heading3>
        <Card>
          <TimelineTable timeline={timeline} entity={spacePlanTimeline} />
        </Card>
      </Section>
    )
  )
}

Component.propTypes = {
  spacePlanTimeline: PropTypes.shape({
    startDate: PropTypes.string,
    timeline: PropTypes.shape({
      stages: PropTypes.shape({}),
    }),
  }).isRequired,
}

const mapStateToProps = state => {
  const getSpacePlanTimeline = selectTimelineBySpacePlan(state) || {}
  return { spacePlanTimeline: getSpacePlanTimeline }
}

export default connect(mapStateToProps)(Component)
