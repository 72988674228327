import React, { useEffect, useState } from 'react'
import { Route, IndexRoute } from 'react-router'

import login, { loginStoreColleague } from 'src/store/api/actions/login'
import createLoginHandler from 'src/utils/loginHandler'
import { loggedIn, loggedInStoreColleague } from 'src/hoc/auth'

// Pages
import changeControlsRoute from 'src/pages/change-controls'
import cyclesRoute from 'src/pages/cycles'
import importsRoute from 'src/pages/imports'
import nominationsRoute from 'src/pages/nominations'
import messagesAdmin from 'src/pages/messages-admin'
import profileRoute from 'src/pages/profile'
import exportsRoute from 'src/pages/exports'
import rangeAndSpace from 'src/pages/range-and-space'
import spaceAssignmentsRoute from 'src/pages/space-assignments'
import spacePlansRoute from 'src/pages/space-plans'
import spacesRoute from 'src/pages/spaces'
import sspsaRoute from 'src/pages/sspsa'
import usersRoute from 'src/pages/users'
import groupsRoute from 'src/pages/groups'
import categoryRoute from 'src/pages/categories'

// Modules
import App from 'src/modules/App'
import StoreColleagueApp from 'src/modules/App/StoreColleague'
import Maintenance from 'src/modules/App/Maintenance'
import Homepage from 'src/components/Homepage'
import NoAccess from 'src/components/Errors/NoAccess'
import NotFound from 'src/components/Errors/NotFound'
import Unassigned, { Submitted } from 'src/modules/Unassigned'
import Unauthorised from 'src/components/Errors/Unauthorised'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'

function createRoutes(store) {
  const { instance, accounts, inProgress } = useMsal()

  const [isStoreColleague, setIsStoreColleague] = useState(false)

  const loginEnter = createLoginHandler(
    args => store.dispatch(login(args)),
    false,
    instance,
    accounts,
    inProgress
  )

  const loginStoreColleagueEnter = createLoginHandler(
    args => store.dispatch(loginStoreColleague(args)),
    true,
    instance,
    accounts,
    inProgress
  )

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      if (isStoreColleague) {
        loginStoreColleagueEnter({ location: window.location })
      } else {
        loginEnter({ location: window.location })
      }
    }
  }, [isStoreColleague, inProgress])

  if (window?.PROMCOMM?.MAINTENANCE === 'true') {
    return <Route name="Maintenance" path="*" component={Maintenance} />
  }

  return (
    <Route path="/">
      <Route component={StoreColleagueApp}>
        <Route
          name="LoginStoreColleague"
          path="login-store-colleague"
          onEnter={() => setIsStoreColleague(true)}
        />
        <Route component={loggedInStoreColleague(({ children }) => children)}>
          {rangeAndSpace}
        </Route>
      </Route>
      <Route component={App}>
        <Route name="Login" path="login" onEnter={() => setIsStoreColleague(false)} />
        <Route component={loggedIn(({ children }) => children)}>
          <IndexRoute component={Homepage} />
          {spacePlansRoute}
          {cyclesRoute}
          {messagesAdmin}
          {nominationsRoute}
          {spacesRoute}
          {usersRoute}
          {importsRoute}
          {profileRoute}
          {spaceAssignmentsRoute}
          {sspsaRoute}
          {exportsRoute}
          {changeControlsRoute}
          {groupsRoute}
          {categoryRoute}
          <Route name="No Access" path="no-access" component={NoAccess} />
          <Route name="Unauthorised" path="unauthorised" component={Unauthorised} />
          <Route path="unassigned" component={Unassigned} />
          <Route path="unassigned/submitted" component={Submitted} />
          <Route name="Not Found" path="*" component={NotFound} />
        </Route>
      </Route>
      <Route name="Not Found" path="*" component={NotFound} />
    </Route>
  )
}

export default createRoutes
