import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import {
  DeleteSpaceInstance,
  GetSpaceAssignmentWithInstances,
  PatchSpaceInstance,
  PostSpaceInstance,
} from 'src/api/SpaceInstance'

const NAMESPACE = 'SpaceAssignmentWithSpaceInstances'
const buildThunkPrefix = (slice, thunkName) => `${slice}/${thunkName}`

const fetchSpaceAssignmentWithInstances = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchSpaceAssignmentWithInstances'),
  async ({ id, changeControlId }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getSpaceInstancesInstance = new GetSpaceAssignmentWithInstances(store, {
      params: { id, changeControlId },
    })

    const response = await handleCreateAsyncThunkResult(
      getSpaceInstancesInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchSpaceAssignmentWithInstancesSlice = createSlice({
  name: NAMESPACE,
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchSpaceAssignmentWithInstances.pending, pendingDataHandler)
    builder.addCase(fetchSpaceAssignmentWithInstances.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpaceAssignmentWithInstances.rejected, rejectedDataHandler)
  },
})

const actionUpdateSpaceInstance = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'updateSpaceInstance'),
  async ({ id, spaceInstance }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const patchSpaceInstanceInfoInstance = new PatchSpaceInstance(store, {
      params: { id, ...spaceInstance },
    })

    const response = await handleCreateAsyncThunkResult(
      patchSpaceInstanceInfoInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionCreateSpaceInstance = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'createSpaceInstance'),
  async ({ spaceAssignmentId, layoutType }, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const postSpaceInstanceInstance = new PostSpaceInstance(store, {
      params: {
        spaceAssignmentId,
        layoutType,
      },
    })
    const response = await handleCreateAsyncThunkResult(
      postSpaceInstanceInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionDeleteSpaceInstance = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'deleteSpaceInstance'),
  async (id, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const deleteSpaceInstanceInstance = new DeleteSpaceInstance(store, {
      params: {
        id,
      },
    })
    const response = await handleCreateAsyncThunkResult(
      deleteSpaceInstanceInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

export default { [NAMESPACE]: fetchSpaceAssignmentWithInstancesSlice.reducer }
export {
  fetchSpaceAssignmentWithInstances,
  actionUpdateSpaceInstance,
  actionCreateSpaceInstance,
  actionDeleteSpaceInstance,
}
