import Api from 'src/api/Base'

class PatchGroup extends Api {
  requestUrl = `/api/Group/${this.getParams().groupId}`

  method = 'PATCH'

  formatRequestData = params => params.group
}
export default PatchGroup
