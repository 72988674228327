import intersect from 'src/utils/intersect'

import { createSelector } from 'reselect'

const selectModule = state => state.modules.UserSelf
const selectSelf = createSelector(selectModule, module => module.data)
const selectSelfRoleId = createSelector(selectSelf, self => self && self.role && self.role.id)
const selectSelfCategories = createSelector(selectSelf, self => self.categories)
const selectAllowedCategories = createSelector(
  selectSelfCategories,
  (state, categories = []) => categories,
  (selfCats, cats) => (cats.length ? intersect(selfCats, cats, (a, b) => a.id === b.id) : selfCats)
)

export { selectSelf, selectSelfRoleId, selectSelfCategories, selectAllowedCategories }
