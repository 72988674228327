import { withFormik } from 'formik'

import withModal from 'src/hoc/withModal'

import Form from './Form'
import handleSubmit from './handleSubmit'
import validate from './validate'

export const mapPropsToValues = props => {
  const {
    artifact: { description },
  } = props

  return { isSubmitting: false, description }
}

const wrappedComponent = withFormik({ mapPropsToValues, validate, handleSubmit })(Form)

export default withModal(wrappedComponent)
