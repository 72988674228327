/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import buildShelfTitle from 'src/utils/shelfTitle'
import capitalise from 'src/utils/capitalise'
import { combineAndSortShelfItems, groupByShelfNumber } from 'src/utils/shelfItems'
import formatDate from 'src/utils/formatDate'
import { getPalletOrStackType, isPalletOrStackLayout, isShipper } from 'src/utils/layoutTypes'
import pluralize from 'src/utils/pluralize'

import createPosName from 'src/utils/createPOSName'
import Cell from '../RowCell'
import HeaderCell from '../HeaderCell'
import ShelfArtifact from './ShelfArtifacts'

const buildShelfFill = ({ shelfFill, shelfFillType }) =>
  shelfFill && shelfFillType ? `${shelfFill} ${pluralize(shelfFill, shelfFillType)}` : ''

const isLastRowInShelf = (rows, row, index) =>
  index + 1 < rows.length && row.shelf !== rows[index + 1].shelf

const getClassNames = shelf =>
  classNames('ln-c-table__row c-psa-row', {
    'ln-u-bg-color-grey-10': shelf % 2 === 0,
    'ln-u-bg-color-white': shelf % 2 === 1,
  })

const ShelfLayout = ({
  rows,
  artifacts,
  shelvesLength,
  layoutType,
  storePSAFlag,
  disablePOSAdviceColumn,
}) => {
  const shelfProductList = rows.map(shelfProduct => {
    return {
      shelf: shelfProduct.shelfPosition,
      shelfProductId: shelfProduct.id,
      ...shelfProduct,
    }
  })

  const shelfArtifactList = artifacts.map(artifact => {
    return {
      shelf: artifact.shelfPosition,
      ...artifact,
    }
  })
  const groupedShelfItems = storePSAFlag
    ? groupByShelfNumber(shelfProductList, shelfArtifactList)
    : groupByShelfNumber(rows, artifacts)

  const legacyClassName = disablePOSAdviceColumn ? '-legacy' : ''

  return (
    <div className="ln-c-table-container">
      <table className="ln-c-table c-psa-table ln-c-table--fixed ln-u-text-align-center ln-u-border-bottom">
        <thead className="ln-c-table__header">
          <tr className="ln-c-table__header-row">
            <HeaderCell className={`c-psa-header-cell-shelf${legacyClassName}`}>
              {capitalise(layoutType)}
            </HeaderCell>
            <HeaderCell className="c-psa-header-cell-sku">SKU</HeaderCell>
            <HeaderCell className={`c-psa-header-cell-description${legacyClassName}`}>
              Description
            </HeaderCell>
            <HeaderCell className="c-psa-header-cell-shelf-fill">Shelf Fill</HeaderCell>
            <HeaderCell className={`c-psa-header-cell-start-date${legacyClassName}`}>
              Start
            </HeaderCell>
            <HeaderCell className={`c-psa-header-cell-end-date${legacyClassName}`}>End</HeaderCell>
            <HeaderCell className={`c-psa-header-cell-facings${legacyClassName}`}>
              Facings
            </HeaderCell>
            {!disablePOSAdviceColumn && (
              <HeaderCell className="c-psa-header-cell-point-of-sale-advice">POS Advice</HeaderCell>
            )}
            <HeaderCell className={`c-psa-header-cell-store-comment${legacyClassName}`}>
              Store Comment
            </HeaderCell>
          </tr>
        </thead>
        <tbody className="ln-c-table__body">
          {groupedShelfItems.map(shelf => {
            const { products: shelfProducts, artifacts: shelfArtifacts } = shelf
            const sortedRows = combineAndSortShelfItems(shelfProducts, shelfArtifacts)

            // Get the pallet/stack type for the current shelf
            const shelfPalletStackType =
              shelfProducts[0]?.palletOrStackType || getPalletOrStackType(layoutType, null)

            // Add a row at the start for the Stack/Pallet header, if not use the original array
            const completeRows = isPalletOrStackLayout(layoutType)
              ? [{ isPalletStackTypeRow: shelfPalletStackType }, ...sortedRows]
              : sortedRows

            return completeRows.map((row, rIndex) => {
              if (row?.isPalletStackTypeRow) {
                return (
                  <Fragment key={`${rIndex}-${row.isPalletStackTypeRow}`}>
                    <tr className="ln-c-table__row c-psa-row ln-u-bg-color-white">
                      <Cell
                        columnSpan={disablePOSAdviceColumn ? 8 : 9}
                        className="c-psa-cell-stack-pallet-type"
                      >
                        {getPalletOrStackType(layoutType, row.isPalletStackTypeRow)}
                      </Cell>
                    </tr>
                  </Fragment>
                )
              }

              if (row?.shelfProductId) {
                return (
                  <Fragment key={row.shelfProductId}>
                    <tr className={getClassNames(row.shelf)}>
                      <Cell className="c-psa-cell-shelf">
                        {buildShelfTitle(row.shelf, shelvesLength, row.isShelfRsb, layoutType)}
                      </Cell>
                      <Cell className="c-psa-cell-sku">
                        {row.sku}
                        {row.oldSku && ` (${row.oldSku})`}
                      </Cell>
                      <Cell className="c-psa-cell-description">{row.description}</Cell>
                      <Cell className="c-psa-cell-shelf-fill">{buildShelfFill(row)}</Cell>
                      <Cell className="c-psa-cell-start-date">
                        {formatDate(row.startDate, 'D-MMM')}
                      </Cell>
                      <Cell className="c-psa-cell-end-date">
                        {formatDate(row.endDate, 'D-MMM')}
                      </Cell>
                      <Cell className="c-psa-cell-facings">{row.facingsOnShelf}</Cell>
                      {!disablePOSAdviceColumn && (
                        <Cell className="c-psa-cell-point-of-sale-advice">
                          {createPosName(row.pointOfSaleAdvice || {})}
                        </Cell>
                      )}
                      <Cell className="c-psa-cell-store-comment">{row.storeComment}</Cell>
                    </tr>
                    {isLastRowInShelf(completeRows, row, rIndex) &&
                      (isPalletOrStackLayout(layoutType) || isShipper(layoutType)) && (
                        <tr className="ln-c-table__row c-psa-row ln-u-bg-color-white">
                          <td colSpan="9" className="ln-c-table__cell c-psa-cell-spacer" />
                        </tr>
                      )}
                  </Fragment>
                )
              }

              const { artifactType, position, shelf: shelfPosition } = row

              return (
                <Fragment key={`row-${rIndex}-${artifactType}-${position}`}>
                  <ShelfArtifact
                    artifact={row}
                    columnSpan={disablePOSAdviceColumn ? 8 : 9}
                    shelfPosition={shelfPosition}
                    shelvesLength={shelvesLength}
                    layoutType={layoutType}
                  />
                </Fragment>
              )
            })
          })}
        </tbody>
      </table>
    </div>
  )
}

ShelfLayout.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      shelf: PropTypes.number,
      shelfPosition: PropTypes.number,
      sku: PropTypes.string.isRequired,
      description: PropTypes.string,
      shelfFill: PropTypes.number,
      shelfFillType: PropTypes.string,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      isShelfRsb: PropTypes.bool,
      facingsOnShelf: PropTypes.number,
      storeComment: PropTypes.string,
      pointOfSaleAdvice: PropTypes.shape({
        type: PropTypes.string,
        paCode: PropTypes.string,
        description: PropTypes.string,
      }),
    })
  ).isRequired,
  artifacts: PropTypes.arrayOf(
    PropTypes.shape({
      artifactType: PropTypes.string.isRequired,
      shelf: PropTypes.number,
      position: PropTypes.number.isRequired,
      description: PropTypes.string,
    })
  ),
  shelvesLength: PropTypes.number.isRequired,
  layoutType: PropTypes.string.isRequired,
  storePSAFlag: PropTypes.bool.isRequired,
  disablePOSAdviceColumn: PropTypes.bool.isRequired,
}

ShelfLayout.defaultProps = {
  artifacts: [],
}

export default ShelfLayout
