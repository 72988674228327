import React from 'react'
import PropTypes from 'prop-types'
import { Container, SiteLayout } from '@jsluna/react'

import Header from 'src/modules/App/Header'
import Activities from 'src/modules/App/Activities'

import ErrorBoundary from './ErrorBoundary'

const App = ({ children }) => (
  <SiteLayout>
    <Header />
    <main>
      <div className="ln-u-soft-top ln-c-header-body">
        {children && (
          <Container soft size="lg">
            <ErrorBoundary>{children}</ErrorBoundary>
          </Container>
        )}
      </div>
    </main>
    <Activities />
  </SiteLayout>
)

App.propTypes = {
  children: PropTypes.element,
}

App.defaultProps = {
  children: null,
}

export default App
