import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Heading3, FilledButton, Checkbox } from '@jsluna/react'

const SubmitDialog = ({ changeControlId, submitChangeControl, handleClose }) => {
  const [isChecked, setIsChecked] = useState(false)
  const onChange = ({ target: { checked } }) => setIsChecked(checked)
  const label = 'Please confirm that you have engaged the relevant supply chain colleague'

  return (
    <div>
      <Heading3>Submit Change Control</Heading3>
      <div className="ln-u-push-bottom">
        <Checkbox
          name="check-supply-chain-engaged"
          label={label}
          onChange={onChange}
          data-control="check-supply-chain-engaged"
        />
      </div>
      <div>
        <FilledButton
          data-control="modal-confirm-submit-change-control"
          disabled={!isChecked}
          onClick={async () => {
            await submitChangeControl(changeControlId)

            handleClose()
          }}
        >
          Confirm
        </FilledButton>
        <FilledButton
          color="dark"
          data-control="modal-cancel-submit-change-control"
          onClick={handleClose}
          className="ln-u-push-left"
        >
          {' '}
          Cancel
        </FilledButton>
      </div>
    </div>
  )
}

SubmitDialog.propTypes = {
  changeControlId: PropTypes.string.isRequired,
  submitChangeControl: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default SubmitDialog
