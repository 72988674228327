import { CYCLES_CHOOSE_TAB } from './actions'

const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CYCLES_CHOOSE_TAB: {
      const { cycleId, tabId } = action.payload
      return {
        ...state,
        [cycleId]: {
          ...state[cycleId],
          tabId,
        },
      }
    }
    default:
      return state
  }
}

export default reducer
