import React from 'react'

import NominationsUpload from 'src/modules/NominationsUpload'
import withModal from 'src/hoc/withModal'

import ModalButton from './ModalButton'

const NominationsUploadModal = withModal(NominationsUpload)

export default props => <NominationsUploadModal {...props} element={ModalButton} />
