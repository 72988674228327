import { SHELVES_PRODUCTS_TYPE } from 'src/constants/types'

const getPrecedingShelfProduct = (shelfItems, fromPosition, toPosition) => {
  let precedingShelfProduct = null

  if (!toPosition) {
    return precedingShelfProduct
  }

  if (fromPosition < toPosition) {
    precedingShelfProduct = shelfItems[toPosition]
  } else {
    precedingShelfProduct = shelfItems.slice(0, toPosition).reduceRight((acc, shelfItem, index) => {
      return !acc && shelfItem.type === SHELVES_PRODUCTS_TYPE ? shelfItems[index] : acc
    }, null)
  }

  return precedingShelfProduct
    ? { id: precedingShelfProduct.id, type: SHELVES_PRODUCTS_TYPE }
    : null
}

export default getPrecedingShelfProduct
