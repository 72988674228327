import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { selectSpacePlansInfo } from './store'
import Header from './Header'
import TabsWrap from './Tabs'

export const Component = ({ id, user, spacePlan, children }) => {
  const { url, status, generating, title, type } = spacePlan

  return (
    <div>
      <Header {...{ id, type, status, title, user, generating, url }} />
      {id && <TabsWrap {...{ id, spacePlan, user }} />}
      {React.cloneElement(children, { spacePlan, id, user })}
    </div>
  )
}

Component.propTypes = {
  user: PropTypes.shape({}).isRequired,
  id: PropTypes.string,
  spacePlan: PropTypes.shape({
    url: PropTypes.string,
    status: PropTypes.string,
    generating: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
}

Component.defaultProps = {
  id: null,
  spacePlan: {},
}

const mapStateToProps = (state, ownProps) => ({
  spacePlan: selectSpacePlansInfo(state, ownProps.id) || {},
})

export default connect(mapStateToProps)(Component)
