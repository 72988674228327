import React from 'react'
import PropTypes from 'prop-types'
import { TextButton } from '@jsluna/react'
import CategoryFilter from '../CategoryFilter'

const CategoryFlyout = ({
  handleClose,
  filterInAllCategories,
  filterOutAllCategories,
  filterInFoodCategories,
  filterInNonFoodCategories,
  filterInAllUserCategories,
}) => {
  const withHandleClose = f => () => {
    f()
    handleClose()
  }

  return (
    <div>
      <div className="ln-u-font-weight-medium u-nowrap">
        <div>
          <TextButton
            className="ln-u-font-weight-medium ln-u-margin-bottom"
            data-control="select-all"
            onClick={withHandleClose(filterInAllCategories)}
          >
            Select all
          </TextButton>
        </div>
        <div>
          <TextButton
            className="ln-u-font-weight-medium ln-u-margin-bottom"
            data-control="select-food"
            onClick={withHandleClose(filterInFoodCategories)}
          >
            Select food
          </TextButton>
        </div>
        <div>
          <TextButton
            className="ln-u-font-weight-medium ln-u-margin-bottom"
            data-control="select-non-food"
            onClick={withHandleClose(filterInNonFoodCategories)}
          >
            Select non-food
          </TextButton>
        </div>
        <div>
          <TextButton
            className="ln-u-font-weight-medium ln-u-margin-bottom"
            data-control="select-none"
            onClick={withHandleClose(filterOutAllCategories)}
          >
            Select none
          </TextButton>
        </div>
        <div>
          <TextButton
            className="ln-u-font-weight-medium ln-u-margin-bottom"
            data-control="select-mine"
            onClick={withHandleClose(filterInAllUserCategories)}
          >
            Select mine
          </TextButton>
        </div>
      </div>
      <CategoryFilter handleCloseParent={() => handleClose()} />
    </div>
  )
}

CategoryFlyout.propTypes = {
  handleClose: PropTypes.func.isRequired,
  filterInAllCategories: PropTypes.func.isRequired,
  filterOutAllCategories: PropTypes.func.isRequired,
  filterInAllUserCategories: PropTypes.func.isRequired,
  filterInFoodCategories: PropTypes.func.isRequired,
  filterInNonFoodCategories: PropTypes.func.isRequired,
}

export default CategoryFlyout
