import { TOGGLE_TRACK_OPEN, RESET_TRACKS } from '../actions'

const DEFAULT_STATE = {}

const reducer = (state = DEFAULT_STATE, { type, payload } = {}) => {
  if (type === TOGGLE_TRACK_OPEN) {
    const { trackId } = payload
    const { isOpen } = state[trackId] || {}

    return {
      ...state,
      [trackId]: {
        isOpen: !isOpen,
      },
    }
  }

  if (type === RESET_TRACKS) {
    return DEFAULT_STATE
  }

  return state
}

export default reducer
