import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  FlagWrapper,
  FlagBody,
  FlagComponent,
  OutlinedButton,
  SelectField,
  TextButton,
} from '@jsluna/react'

import sortArray from 'src/utils/sortArray'

import ProductCell from './ProductCell'

const filterAndSortShelfProductLayouts = (shelfProductLayouts, location, index) => {
  const filteredAndSortedShelfProductLayouts = shelfProductLayouts.filter(shelfProductLayout => {
    return shelfProductLayout.location === location && shelfProductLayout.index === index
  })

  filteredAndSortedShelfProductLayouts.sort((a, b) => {
    return a.position - b.position
  })

  return filteredAndSortedShelfProductLayouts
}

const FrozenCell = ({
  addFrozenPosition,
  deleteFrozenPosition,
  index,
  instanceId,
  location,
  shelfProducts,
  shelfProductLayouts,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [isSaving, setSaveMode] = useState(false)

  const options = sortArray(
    shelfProducts.map(({ id, description, sku }) => ({
      value: id,
      label: `${description} (${sku})`,
    })),
    'label'
  )

  const relevantShelfProductLayouts = filterAndSortShelfProductLayouts(
    shelfProductLayouts,
    location,
    index
  )
  const addProductToTable = async event => {
    const shelfProduct = shelfProducts.find(sp => sp.id === event.target.value)

    const layout = {
      location,
      index,
      position:
        relevantShelfProductLayouts.length > 0
          ? relevantShelfProductLayouts[relevantShelfProductLayouts.length - 1].position + 1
          : 0,
      spaceInstanceId: instanceId,
    }
    setSaveMode(true)
    await addFrozenPosition(shelfProduct.id, layout)
    setEditMode(false)
    setSaveMode(false)
  }

  const deleteProductFromTable = async layoutId => {
    setSaveMode(true)
    await deleteFrozenPosition(layoutId, false)
    setEditMode(false)
    setSaveMode(false)
  }

  return (
    <div className="c-frozen-cell ln-u-bg-color-grey-10">
      <div className="c-frozen-cell__products">
        <div className="c-product-cells">
          {relevantShelfProductLayouts.map((shelfProductLayout, shelfIndex) => {
            const { id, ...product } = shelfProductLayout
            const onDelete = () => deleteProductFromTable(id)

            return (
              <ProductCell
                // eslint-disable-next-line react/no-array-index-key
                key={`Product-${shelfIndex}`}
                product={product}
                onDelete={onDelete}
              />
            )
          })}
        </div>
      </div>
      {editMode ? (
        <div className="c-frozen-cell__controls">
          <FlagWrapper>
            <FlagBody>
              <SelectField
                className="ln-u-push-right ln-u-flush-bottom"
                disabled={isSaving}
                name="select-shelf-product"
                onChange={addProductToTable}
                options={options}
              />
            </FlagBody>
            <FlagComponent>
              <OutlinedButton
                fullWidth
                data-control="cancel-add-frozen"
                disabled={isSaving}
                onClick={() => setEditMode(false)}
              >
                Cancel
              </OutlinedButton>
            </FlagComponent>
          </FlagWrapper>
        </div>
      ) : (
        <div className="c-frozen-cell__controls">
          <TextButton
            className="ln-u-push-sides-sm"
            data-control="add-frozen"
            onClick={() => setEditMode(true)}
          >
            Add Frozen Product
          </TextButton>
        </div>
      )}
    </div>
  )
}

FrozenCell.propTypes = {
  addFrozenPosition: PropTypes.func,
  deleteFrozenPosition: PropTypes.func,
  index: PropTypes.number,
  instanceId: PropTypes.string.isRequired,
  location: PropTypes.string,
  shelfProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  shelfProductLayouts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      product: PropTypes.shape({}),
    })
  ),
}

FrozenCell.defaultProps = {
  addFrozenPosition: () => {},
  deleteFrozenPosition: () => {},
  index: null,
  location: null,
  shelfProductLayouts: [
    {
      id: null,
      product: null,
    },
  ],
}

export default FrozenCell
