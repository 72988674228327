import { createSelector } from 'reselect'

import { buildReducer, bindSelector } from 'src/store/modules'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  handleCreateAsyncThunkResult,
  buildThunkPrefix,
  actionClearDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { POSAdviceListImport, JdaImport, SkuCsvImport, SkuGzImport } from 'src/api/Imports'
import resultsReducer from './reducer'

const NAMESPACE = 'Imports'
const RESULTS = 'results'

const selectImportResponse = bindSelector(NAMESPACE)(state => state[RESULTS])
const selectLastImportResponse = createSelector(
  state => state,
  selectImportResponse,
  (state, importResponse) => ({
    ...importResponse,
    result: importResponse.resultId,
  })
)

const importPOSAdviceListFile = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'importPOSAdviceListFile'),
  async ({ formData }, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const GetChangeControlsByCycleIdInstance = new POSAdviceListImport(store, {
      params: { formData },
    })
    const response = await handleCreateAsyncThunkResult(
      GetChangeControlsByCycleIdInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const importJdaFile = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'importJdaFile'),
  async ({ formData, isJda }, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const PostJdaImportInstance = new JdaImport(store, {
      params: { formData, isJda },
    })
    const response = await handleCreateAsyncThunkResult(
      PostJdaImportInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const importSkuCsvFile = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'importSkuCsvFile'),
  async ({ formData }, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const PostSkuImportInstance = new SkuCsvImport(store, {
      params: { formData },
    })
    const response = await handleCreateAsyncThunkResult(
      PostSkuImportInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const importSkuGzFile = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'importSkuGzFile'),
  async ({ formData }, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const PostSkuImportInstance = new SkuGzImport(store, {
      params: { formData },
    })
    const response = await handleCreateAsyncThunkResult(
      PostSkuImportInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)
const actionClearData = actionClearDataHandler(NAMESPACE)

export default buildReducer(NAMESPACE, { [RESULTS]: resultsReducer })
export {
  selectLastImportResponse,
  actionClearData,
  importPOSAdviceListFile,
  importJdaFile,
  importSkuCsvFile,
  importSkuGzFile,
}
