import { actionCreateSpaceGroup, actionUpdateSpaceGroupById } from '../store'

const handleSubmit = async (values, { props: { afterSubmit, submit } }) => {
  const { id: spaceGroupId, description, position, title } = values
  const spaceGroup = {
    description,
    position: parseInt(position, 10),
    title,
    id: spaceGroupId,
  }

  const action = spaceGroupId
    ? actionUpdateSpaceGroupById({ spaceGroupId, spaceGroup })
    : actionCreateSpaceGroup(spaceGroup)

  await submit(action)
  afterSubmit()
}

export default handleSubmit
