import React from 'react'
import { Heading2 } from '@jsluna/react'

import Section from 'src/components/Section'

import { getLocation } from 'src/utils/window'

const SessionExpiry = () => (
  <Section>
    <Heading2>Your session may have expired</Heading2>
    <p>Please refresh your browser or press the button below to login again.</p>
    <a href={getLocation().href} className="ln-c-button ln-c-button--filled">
      Refresh
    </a>
  </Section>
)

export default SessionExpiry
