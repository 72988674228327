import { CREATE_NOTIFICATION, DELETE_NOTIFICATION } from './actions'

const reducer = (state = {}, { type, payload = {} }) => {
  switch (type) {
    case CREATE_NOTIFICATION:
      return { ...state, [payload.id]: payload }
    case DELETE_NOTIFICATION: {
      const newState = { ...state }
      delete newState[payload.id]
      return newState
    }
    default:
      return state
  }
}

export default reducer
