import React from 'react'
import { Route, IndexRoute } from 'react-router'

import { authorized } from 'src/hoc/auth'
import UsersList from 'src/modules/Users'
import UsersDetail from 'src/modules/UserDetail'

const route = (
  <Route name="Users" path="users" showInNav>
    <IndexRoute component={authorized(UsersList)} />
    <Route path=":id" component={authorized(UsersDetail)} />
  </Route>
)

export default route
