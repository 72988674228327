import React from 'react'
import { Heading2 } from '@jsluna/react'

import Section from 'src/components/Section'

const NoAccess = () => (
  <Section>
    <Heading2>No Access</Heading2>
    <p>You do not currently have access to PromComm.</p>
  </Section>
)

export default NoAccess
