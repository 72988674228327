import { createChangeControl } from '../store/actions'

const handleSubmit = async (
  { changeControlReasonCode, changeControlDescription },
  { props: { assignment: spaceAssignment, submit, afterInit } }
) => {
  const action = createChangeControl({
    changeControlDescription,
    changeControlReasonCode,
    spaceAssignment,
    afterInit,
  })

  await submit(action)
}
export default handleSubmit
