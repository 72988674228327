import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Field } from 'formik'
import {
  Accordion,
  GridWrapper,
  GridItem,
  Form,
  ListItem,
  List,
  Heading3,
  StatusCard,
} from '@jsluna/react'
import { SelectField, TextInputField } from '@jsluna/form/extensions/formik'
import { hasRequiredRole } from 'src/utils/permissions'
import nested from 'src/utils/nested'

import { DateField, Checkbox, LabelledText } from 'src/components/FormikComponents'
import { Controls, ProductSearch } from 'src/components/Form'

import {
  hfssStatus,
  enableHfssRestriction,
  hfssErrorMessage,
} from 'src/functions/productHfssStatus'

import {
  CATEGORY_BUYER_ROLE_ID,
  CATEGORY_PLANNER_ROLE_ID,
  NON_FOOD_BUYER_AND_MERCHANDISER_ROLE_ID,
  NON_FOOD_SENIOR_MANAGER_BUYER_AND_MERCHANDISER_ROLE_ID,
  PERM_ROLE_ID,
  SUPPLY_CHAIN_ANALYST_ROLE_ID,
  COMMERCIAL_OPS_SUPER_USER_ID,
} from 'src/constants/roles'

import {
  AP,
  PP,
  PROMO_TYPE,
  SHELF_FILL_TYPE_OPTIONS,
  TPR,
  STUNT_MECHANIC,
  HALF_PRICE_MECHANIC,
  SAVE_POUND_MECHANIC,
  SAVE_PRC_MECHANIC,
  INTRO_OFFER,
} from 'src/constants/shelfProductDetails'
import isDisabled from './isInputDisabled'
import Clustering from './Clustering'
import AccordionTitle from './AccordionTitle'
import PendingChangeControlCard from './PendingChangeControlCard'

const toOption = ({ title, id, code }) => ({ id, value: id, label: title || code })
const getWeekLabel = week => `Week ${week}${week === 1 ? ' (Part Week)' : ''}`
const getCategory = (categoryId, categories) => categories.find(c => c.id === categoryId)

const hasErrors = (errors, touched, formSubmitted) => fields =>
  fields.some(field => (touched[field] || formSubmitted) && errors[field])

const isTemporaryPriceReductionMechanic = promoMechanicTitle =>
  [
    STUNT_MECHANIC,
    TPR,
    HALF_PRICE_MECHANIC,
    SAVE_POUND_MECHANIC,
    SAVE_PRC_MECHANIC,
    INTRO_OFFER,
  ].some(x => x === promoMechanicTitle)

const ShelfProductsDetail = props => {
  const {
    assignmentStartDate,
    assignmentEndDate,
    availableClusters,
    cancel,
    categories,
    disableUpdate,
    disabled,
    errors,
    handleSubmit,
    hasPendingChange,
    id,
    isLockedForChangeControl,
    isSubmitting,
    pendingChangeControl,
    promoMechanics,
    space,
    submitCount,
    touched,
    user,
    initialPromoMechanic,
    values,
  } = props

  const changeControlProcess = isLockedForChangeControl || pendingChangeControl
  const spaceTypeIsPPorAP = space?.spaceType.title === PP || space?.spaceType.title === AP
  const [initialPromoType] = promoMechanics.filter(obj => obj.id === initialPromoMechanic)
  const initialPromoTypeTitle = initialPromoType ? initialPromoType.title : ''
  const changeControlForTPR =
    changeControlProcess &&
    spaceTypeIsPPorAP &&
    isTemporaryPriceReductionMechanic(initialPromoTypeTitle)
  const [alert, setAlert] = useState(false)
  const promoTypeChange = ({ target: { name, value } }) => {
    if (name === PROMO_TYPE) {
      const [promoType] = promoMechanics.filter(obj => obj.id === value)
      const nonTPRChange =
        changeControlForTPR && !isTemporaryPriceReductionMechanic(promoType.title)
      setAlert(nonTPRChange)
    }
  }

  const businessUnit = values.category ? getCategory(values.category, categories).businessUnit : ''
  const isNonFood = businessUnit === 'non-food'
  const showChangeControlBanner = isLockedForChangeControl && !pendingChangeControl
  const isInputDisabled = isDisabled(disabled, disableUpdate)
  const hasErrorsInSection = hasErrors(errors, touched, submitCount)
  const size = isNonFood ? '1/4@sm' : '1/2@sm'

  const { product } = values
  let disableSave = false
  let productHfssIndicator = product?.hfss === 'YES' ? product?.hfss : hfssStatus(product?.hfssFlag)

  if (enableHfssRestriction(space.hfssFlag, product?.hfssFlag || Number(product?.hfss === 'YES'))) {
    productHfssIndicator = `YES [${hfssErrorMessage}]`
    disableSave = true
  }
  const hasPermission =
    hasRequiredRole(user, PERM_ROLE_ID) || hasRequiredRole(user, COMMERCIAL_OPS_SUPER_USER_ID)
  return (
    <div className="u-reset-wrap">
      <Heading3>
        <span data-control="shelf-products-details-title">
          {id ? 'Edit Product Nomination' : 'Create Product Nomination'}
        </span>
      </Heading3>

      <Form onSubmit={handleSubmit} onChange={promoTypeChange}>
        {showChangeControlBanner && (
          <StatusCard className="ln-u-push-bottom" status="danger" data-control="cycle-locked-card">
            The cycle is locked. Please follow the change control process. For food queries please
            contact promotional.cycles@sainsburys.co.uk and for non-food please contact
            nf.promocycles@sainsburys.co.uk
          </StatusCard>
        )}

        {isLockedForChangeControl && hasPendingChange && (
          <PendingChangeControlCard
            businessUnit={businessUnit}
            cycleId={pendingChangeControl.cycle.id}
            data-control="pending-request-card"
          />
        )}

        <Accordion
          className={classNames('c-accordion-shelf-product', {
            'has-errors': hasErrorsInSection(['product']),
          })}
          data-control="shelf-products-details-accordion-sku"
          title={<AccordionTitle title="SKU" description={values.product.sku} />}
          titleElement="div"
          defaultOpen={!values.product.sku && !isLockedForChangeControl}
        >
          <GridWrapper className="ln-u-push-bottom">
            <GridItem size="1/2@sm" className={classNames({ 'ln-u-push-bottom': !id })}>
              {id ? (
                <Field label="SKU" name="product.sku" component={LabelledText} />
              ) : (
                <Field name="product" component={ProductSearch} disabled={false} />
              )}
            </GridItem>
            <GridItem size="1/1">
              <Field label="Description" name="product.description" component={LabelledText} />
              <Field label="Dept/Comm" name="product.deptComm" component={LabelledText} />
              <Field label="Status" name="product.skuStatus" component={LabelledText} />
              <div className="c-hfss">
                <Field label="HFSS" value={productHfssIndicator} component={LabelledText} />
              </div>
            </GridItem>
            <GridItem size="1/1">
              <Field
                label="Comment"
                name="comment"
                component={TextInputField}
                validationFirst={false}
                disabled={isInputDisabled('comment')}
                value={values.comment || ''}
              />
            </GridItem>
            <GridItem size="1/1">
              <Field
                label="Store Comment"
                name="storeComment"
                component={TextInputField}
                validationFirst={false}
                disabled={isInputDisabled('storeComment')}
                value={values.storeComment || ''}
              />
            </GridItem>
            <GridItem size="1/2@sm">
              <DateField
                label="Override Start Date"
                name="overrideStartDate"
                displayMonth={assignmentStartDate}
                disabled={!hasPermission}
              />
            </GridItem>
            <GridItem size="1/2@sm">
              <DateField
                label="Override End Date"
                name="overrideEndDate"
                displayMonth={assignmentEndDate}
                disabled={!hasPermission}
              />
            </GridItem>
          </GridWrapper>
        </Accordion>

        {isNonFood && (
          <Accordion
            className="c-accordion-shelf-product"
            data-control="shelf-products-details-accordion-old-sku"
            title={<AccordionTitle title="Old SKU" />}
            titleElement="div"
            defaultOpen={false}
          >
            <GridWrapper className="ln-u-push-bottom">
              <GridItem size="1/2@sm" className="ln-u-push-bottom">
                <Field name="oldProduct" component={ProductSearch} disabled={false} />
              </GridItem>
              <GridItem size="1/1">
                <Field label="Description" name="oldProduct.description" component={LabelledText} />
                <Field label="Dept/Comm" name="oldProduct.deptComm" component={LabelledText} />
                <Field label="Status" name="oldProduct.skuStatus" component={LabelledText} />
              </GridItem>
            </GridWrapper>
          </Accordion>
        )}

        <Accordion
          data-control="shelf-products-details-accordion-supply-chain"
          className={classNames('c-accordion-shelf-product', {
            'has-errors': hasErrorsInSection([
              'shelfFill',
              'shelfFillType',
              'promoCaseSize',
              'percentUpliftAisle',
              'percentUpliftPlinth',
            ]),
          })}
          title={<AccordionTitle title="Supply Chain" />}
          titleElement="div"
          defaultOpen={
            hasRequiredRole(user, SUPPLY_CHAIN_ANALYST_ROLE_ID) && !isLockedForChangeControl
          }
        >
          <GridWrapper>
            <GridItem size="1/3@sm">
              <Field
                label="Shelf Fill"
                name="shelfFill"
                type="number"
                component={TextInputField}
                validationFirst={false}
                disabled={isInputDisabled('shelfFill')}
                value={values.shelfFill?.toString() || ''}
              />
            </GridItem>
            <GridItem size="1/3@sm">
              <Field
                label="Shelf Fill Type"
                name="shelfFillType"
                component={SelectField}
                options={SHELF_FILL_TYPE_OPTIONS}
                placeholder={false}
                validationFirst={false}
                disabled={isInputDisabled('shelfFillType')}
              />
            </GridItem>
            <GridItem size="1/3@sm">
              <Field
                label="Promo Case Size"
                name="promoCaseSize"
                type="number"
                component={TextInputField}
                validationFirst={false}
                disabled={isInputDisabled('promoCaseSize')}
                value={values.promoCaseSize?.toString() || ''}
              />
            </GridItem>
            <GridItem size="1/2@sm">
              <Field
                label="RMS % Uplift for Aisle"
                name="percentUpliftAisle"
                type="number"
                component={TextInputField}
                validationFirst={false}
                disabled={isInputDisabled('percentUpliftAisle')}
                value={values.percentUpliftAisle?.toString() || ''}
              />
            </GridItem>
            <GridItem size="1/2@sm">
              <Field
                label="RMS % Uplift for Plinth"
                name="percentUpliftPlinth"
                type="number"
                component={TextInputField}
                validationFirst={false}
                disabled={isInputDisabled('percentUpliftPlinth')}
                value={values.percentUpliftPlinth?.toString() || ''}
              />
            </GridItem>
          </GridWrapper>
        </Accordion>

        <Accordion
          data-control="shelf-products-details-accordion-promotion"
          className={classNames('c-accordion-shelf-product', {
            'has-errors': hasErrorsInSection([
              'category',
              'promoMechanic',
              'promoMechanicValue',
              'packSize',
              'pricePoint',
              'facingsOnShelf',
              'ubw',
              'profitPpp',
              'originalRetailPrice',
              'clearancePrice',
              'profitPpp',
            ]),
          })}
          title={<AccordionTitle title="Promotion" />}
          titleElement="div"
          defaultOpen={
            hasRequiredRole(user, [
              CATEGORY_BUYER_ROLE_ID,
              CATEGORY_PLANNER_ROLE_ID,
              NON_FOOD_BUYER_AND_MERCHANDISER_ROLE_ID,
              NON_FOOD_SENIOR_MANAGER_BUYER_AND_MERCHANDISER_ROLE_ID,
            ]) && !isLockedForChangeControl
          }
        >
          <GridWrapper>
            {alert && (
              <GridItem size="1/1">
                <StatusCard
                  className="ln-u-push-bottom"
                  status="warning"
                  data-control="invalid-TPR-change"
                >
                  You are attempting to change a SKU on Plinth from TPR to non TPR. If this is a
                  Special Offers plinth, legally all SKUs must be a TPR or a WIGIG (no white tickets
                  are allowed on Special Offers Plinths). Please review your change request.
                </StatusCard>
              </GridItem>
            )}
            <GridItem size="1/1">
              <Field
                label="Category"
                name="category"
                component={SelectField}
                options={categories.map(toOption)}
                placeholder={categories.length > 1}
                validationFirst={false}
                disabled={isInputDisabled('category')}
              />
            </GridItem>
            <GridItem size="1/2@sm">
              <Field
                label="Promotional Mechanic Type"
                name="promoMechanic"
                component={SelectField}
                options={promoMechanics.map(toOption)}
                validationFirst={false}
                disabled={isInputDisabled('promoMechanic')}
              />
            </GridItem>
            <GridItem size="1/2@sm">
              <Field
                label="Promotional Mechanic Value"
                name="promoMechanicValue"
                component={TextInputField}
                validationFirst={false}
                disabled={isInputDisabled('promoMechanicValue')}
                value={values.promoMechanicValue || ''}
              />
            </GridItem>
            <GridItem size="1/4@sm">
              <Field
                label="Pack Size"
                name="packSize"
                type="number"
                component={TextInputField}
                validationFirst={false}
                disabled={isInputDisabled('packSize')}
                value={values.packSize?.toString() || ''}
              />
            </GridItem>
            <GridItem size="1/4@sm">
              <Field
                label="Price Point"
                name="pricePoint"
                type="number"
                component={TextInputField}
                validationFirst={false}
                disabled={isInputDisabled('pricePoint')}
                value={values.pricePoint?.toString() || ''}
              />
            </GridItem>
            <GridItem size="1/4@sm">
              <Field
                label="Facings on Shelf"
                name="facingsOnShelf"
                type="number"
                component={TextInputField}
                validationFirst={false}
                disabled={isInputDisabled('facingsOnShelf')}
                value={values.facingsOnShelf?.toString() || ''}
              />
            </GridItem>
            <GridItem size="1/4@sm">
              <Field
                label="UBW"
                name="ubw"
                type="number"
                component={TextInputField}
                validationFirst={false}
                disabled={isInputDisabled('ubw')}
                value={values.ubw?.toString() || ''}
              />
            </GridItem>
            <GridItem size={size}>
              <Field
                label="Profit (PPP)"
                name="profitPpp"
                type="number"
                component={TextInputField}
                validationFirst={false}
                disabled={isInputDisabled('profitPpp')}
                value={values.profitPpp?.toString() || ''}
              />
            </GridItem>
            <GridItem size={size}>
              <Field
                label="Original Retail Price"
                name="originalRetailPrice"
                type="number"
                component={TextInputField}
                validationFirst={false}
                disabled={isInputDisabled('originalRetailPrice')}
                value={values.originalRetailPrice?.toString() || ''}
              />
            </GridItem>
            {isNonFood && (
              <GridItem size="1/2@sm">
                <Field
                  label="Clearance Price"
                  name="clearancePrice"
                  type="number"
                  component={TextInputField}
                  validationFirst={false}
                  disabled={isInputDisabled('clearancePrice')}
                  value={values.clearancePrice?.toString() || ''}
                />
              </GridItem>
            )}
            <GridItem size="1/4@sm">
              <Field
                component={Checkbox}
                label="WIGIG/Range Out"
                name="wigig"
                disabled={isInputDisabled('wigig')}
              />
            </GridItem>
            <GridItem size="1/4@sm">
              <Field
                component={Checkbox}
                label="Special buy"
                name="specialBuy"
                disabled={isInputDisabled('specialBuy') || !isNonFood}
              />
            </GridItem>
          </GridWrapper>
        </Accordion>

        {isNonFood && (
          <Accordion
            data-control="shelf-products-details-accordion-financials"
            className={classNames('c-accordion-shelf-product', {
              'has-errors': hasErrorsInSection([
                'percentSoldPromoPrice',
                'percentSoldClearancePrice',
                'salesForecastPromoCycle',
                'salesForecastLine',
                'soaPerUnit',
                'bulkSupport',
                [...values.salesPhaseWeeks.map((_, i) => `sales-phase-week-${i + 1}`)],
              ]),
            })}
            title={<AccordionTitle title="Non-Food Financials" />}
            titleElement="div"
          >
            <GridWrapper>
              <GridItem size="1/3@sm">
                <Field
                  label="% Sold at Promo Price"
                  name="percentSoldPromoPrice"
                  type="number"
                  component={TextInputField}
                  validationFirst={false}
                  disabled={isInputDisabled('percentSoldPromoPrice')}
                  value={values.percentSoldPromoPrice?.toString() || ''}
                />
              </GridItem>
              <GridItem size="1/3@sm">
                <Field
                  label="% Sold at Clearance Price"
                  name="percentSoldClearancePrice"
                  type="number"
                  component={TextInputField}
                  validationFirst={false}
                  disabled={isInputDisabled('percentSoldClearancePrice')}
                  value={values.percentSoldClearancePrice?.toString() || ''}
                />
              </GridItem>
              <GridItem size="1/3@sm">
                <Field
                  label="Forecasted Sales for Cycle"
                  name="salesForecastPromoCycle"
                  type="number"
                  component={TextInputField}
                  validationFirst={false}
                  disabled={isInputDisabled('salesForecastPromoCycle')}
                  value={values.salesForecastPromoCycle?.toString() || ''}
                />
              </GridItem>
              <GridItem size="1/3@sm">
                <Field
                  label="Forecasted Sales for Line"
                  name="salesForecastLine"
                  type="number"
                  component={TextInputField}
                  validationFirst={false}
                  disabled={isInputDisabled('salesForecastLine')}
                  value={values.salesForecastLine?.toString() || ''}
                />
              </GridItem>
              <GridItem size="1/3@sm">
                <Field
                  label="SOA per Unit"
                  name="soaPerUnit"
                  type="number"
                  component={TextInputField}
                  validationFirst={false}
                  disabled={isInputDisabled('soaPerUnit')}
                  value={values.soaPerUnit?.toString() || ''}
                />
              </GridItem>
              <GridItem size="1/3@sm">
                <Field
                  label="Bulk Support"
                  name="bulkSupport"
                  type="number"
                  component={TextInputField}
                  validationFirst={false}
                  disabled={isInputDisabled('bulkSupport')}
                  value={values.bulkSupport?.toString() || ''}
                />
              </GridItem>
            </GridWrapper>
            <GridWrapper>
              <GridItem size="1">
                <span className="ln-c-label">Sales Phasing</span>
              </GridItem>
              <GridItem size="1">
                <List type="matrix">
                  {values.salesPhaseWeeks.map((_, i) => (
                    <ListItem
                      type="matrix"
                      key={`sales-phase-week-${i + 1}`}
                      className="c-list-item-sales-phase-week"
                    >
                      <Field
                        label={getWeekLabel(i + 1)}
                        name={`salesPhaseWeeks[${i}]`}
                        type="number"
                        component={TextInputField}
                        disabled={isInputDisabled('salesPhaseWeeks')}
                        touched={(nested(touched, `salesPhaseWeeks.${i}`) || false).toString()}
                        error={submitCount > 0 && errors[`salesPhaseWeeks[${i}]`]}
                        validationFirst={false}
                        value={values.salesPhaseWeeks[i]?.toString() || ''}
                      />
                    </ListItem>
                  ))}
                </List>
              </GridItem>
            </GridWrapper>
          </Accordion>
        )}

        {isNonFood && (
          <Accordion
            data-control="shelf-products-details-accordion-stock"
            className={classNames('c-accordion-shelf-product', {
              'has-errors': hasErrorsInSection([
                'forecastPurchaseQuantity',
                'stockValueForPromo',
                'nppInStoreDate',
              ]),
            })}
            title={<AccordionTitle title="Non-Food Stock" />}
            titleElement="div"
          >
            <GridWrapper>
              <GridItem size="1/3@sm">
                <Field
                  label="Forecast Purchase Quantity"
                  name="forecastPurchaseQuantity"
                  type="number"
                  component={TextInputField}
                  validationFirst={false}
                  disabled={isInputDisabled('forecastPurchaseQuantity')}
                  value={values.forecastPurchaseQuantity?.toString() || ''}
                />
              </GridItem>
              <GridItem size="1/3@sm">
                <Field
                  label="Stock Value Bought for Promo (Full Retail)"
                  name="stockValueForPromo"
                  type="number"
                  component={TextInputField}
                  validationFirst={false}
                  disabled={isInputDisabled('stockValueForPromo')}
                  value={values.stockValueForPromo?.toString() || ''}
                />
              </GridItem>
              <GridItem size="1/3@sm">
                <DateField
                  label="NPP In Store Date"
                  name="nppInStoreDate"
                  disabled={isInputDisabled('nppInStoreDate')}
                />
              </GridItem>
              <GridItem size="1/3@sm">
                <Field
                  component={Checkbox}
                  label="NPP Required"
                  name="nppRequired"
                  disabled={isInputDisabled('nppRequired')}
                />
              </GridItem>
              <GridItem size="1/2@sm">
                <Field
                  component={Checkbox}
                  label="Stock in Shipper/FSDU"
                  name="stockInShipperFdsu"
                  disabled={isInputDisabled('stockInShipperFdsu')}
                />
              </GridItem>
            </GridWrapper>
          </Accordion>
        )}

        <Accordion
          className="ln-u-push-bottom c-accordion-shelf-product"
          data-control="shelf-products-details-accordion-clusters"
          title={
            <AccordionTitle
              title="Clusters"
              description={
                values.clusters.length > 0 ? 'Specific Clusters' : 'All Clusters in Group'
              }
            />
          }
          titleElement="div"
        >
          <Field
            label="Clustering"
            name="clusters"
            component={Clustering}
            clusters={availableClusters}
            disabled={isInputDisabled('clusters')}
          />
        </Accordion>
        <Controls cancel={cancel} disabled={disableSave || disableUpdate || isSubmitting} />
      </Form>
    </div>
  )
}

ShelfProductsDetail.propTypes = {
  assignmentStartDate: PropTypes.string,
  assignmentEndDate: PropTypes.string,
  availableClusters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  cancel: PropTypes.func,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  disableUpdate: PropTypes.bool,
  disabled: PropTypes.shape({
    category: PropTypes.bool.isRequired,
    clusters: PropTypes.bool.isRequired,
    comment: PropTypes.bool.isRequired,
    packSize: PropTypes.bool.isRequired,
    promoMechanic: PropTypes.bool.isRequired,
    promoMechanicValue: PropTypes.bool.isRequired,
    shelfFill: PropTypes.bool.isRequired,
    shelfFillType: PropTypes.bool.isRequired,
    storeComment: PropTypes.bool.isRequired,
  }).isRequired,
  errors: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasPendingChange: PropTypes.bool,
  id: PropTypes.string,
  isLockedForChangeControl: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  pendingChangeControl: PropTypes.shape({
    cycle: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  promoMechanics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  submitCount: PropTypes.number,
  touched: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,
  initialPromoMechanic: PropTypes.string,
  values: PropTypes.shape({
    overrideStartDate: PropTypes.string,
    overrideEndDate: PropTypes.string,
    bulkSupport: PropTypes.number,
    category: PropTypes.string,
    clearancePrice: PropTypes.number,
    clusters: PropTypes.arrayOf(PropTypes.string),
    comment: PropTypes.string,
    facingsOnShelf: PropTypes.number,
    forecastPurchaseQuantity: PropTypes.number,
    nppRequired: PropTypes.bool,
    originalRetailPrice: PropTypes.number,
    packSize: PropTypes.number,
    percentSoldClearancePrice: PropTypes.number,
    percentSoldPromoPrice: PropTypes.number,
    percentUpliftAisle: PropTypes.number,
    percentUpliftPlinth: PropTypes.number,
    pricePoint: PropTypes.number,
    product: PropTypes.shape({
      sku: PropTypes.string,
      hfssFlag: PropTypes.number,
      hfss: PropTypes.string,
    }),
    oldProduct: PropTypes.shape({
      sku: PropTypes.string,
    }),
    profitPpp: PropTypes.number,
    promoCaseSize: PropTypes.number,
    promoMechanic: PropTypes.string,
    promoMechanicValue: PropTypes.string,
    salesForecastLine: PropTypes.number,
    salesForecastPromoCycle: PropTypes.number,
    salesPhaseWeeks: PropTypes.arrayOf(PropTypes.number),
    shelfFill: PropTypes.number,
    soaPerUnit: PropTypes.number,
    specialBuy: PropTypes.bool,
    stockInShipperFdsu: PropTypes.bool,
    stockValueForPromo: PropTypes.number,
    storeComment: PropTypes.string,
    ubw: PropTypes.number,
    wigig: PropTypes.bool,
  }),
  space: PropTypes.shape({
    hfssFlag: PropTypes.string,
    spaceType: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
}

ShelfProductsDetail.defaultProps = {
  assignmentStartDate: null,
  assignmentEndDate: null,
  id: null,
  cancel: null,
  disableUpdate: false,
  hasPendingChange: false,
  isLockedForChangeControl: false,
  isSubmitting: false,
  values: {},
  pendingChangeControl: null,
  submitCount: 0,
  initialPromoMechanic: '',
}

export default ShelfProductsDetail
