export const SHIPPER_LOCK_UNLOCKED_ID = 'unlocked'
export const SHIPPER_LOCK_LOCKED_ID = 'locked'
export const SHIPPER_LOCK_ALL_IDS = [SHIPPER_LOCK_LOCKED_ID, SHIPPER_LOCK_UNLOCKED_ID]

export const GUIDELINE_COMPLIANT = 'guidelineCompliant'
export const TRANSIT_TRIAL_REQUIRED = 'transitTrialRequired'
export const TRANSIT_TRIAL_COMPLETED = 'transitTrialCompleted'
export const PRICE_CHECK = 'priceCheck'
export const ARTWORK = 'artwork'
export const SHIPPER_SKU = 'shipperSku'

export const TOUCHPOINTS = [
  TRANSIT_TRIAL_REQUIRED,
  TRANSIT_TRIAL_COMPLETED,
  GUIDELINE_COMPLIANT,
  PRICE_CHECK,
  ARTWORK,
  SHIPPER_SKU,
]

export const TOUCHPOINTS_LABELS = {
  [TRANSIT_TRIAL_REQUIRED]: 'Transit Trial Required',
  [TRANSIT_TRIAL_COMPLETED]: 'Transit Trial Completed',
  [GUIDELINE_COMPLIANT]: 'Guideline Compliant',
  [PRICE_CHECK]: 'Price Check',
  [ARTWORK]: 'Artwork',
  [SHIPPER_SKU]: 'Shipper SKU',
}

export const TOUCHPOINT_COMPLETED = 'completed'
export const TOUCHPOINT_NOT_COMPLETED = 'notCompleted'
export const TOUCHPOINT_REQUIRED = 'required'
export const TOUCHPOINT_NOT_REQUIRED = 'notRequired'

export const TOUCHPOINT_STATUS_COMPLETED = 'completed'
export const TOUCHPOINT_STATUS_DUE = 'due'
export const TOUCHPOINT_STATUS_OVERDUE = 'overdue'
export const TOUCHPOINT_STATUS_PENDING = 'pending'

export const TOUCHPOINTS_WITH_OPTIONS = {
  [TRANSIT_TRIAL_REQUIRED]: [
    { value: TOUCHPOINT_REQUIRED, label: 'Yes' },
    { value: TOUCHPOINT_NOT_REQUIRED, label: 'No' },
  ],
}

export const ASCENDING = 'ascending'
export const DESCENDING = 'descending'

export const COLUMNS = {
  title: 'Theme',
  spaceTitle: 'Space Name',
  clusterGroupTitle: 'Cluster Group',
  hfssTag: 'HFSS',
  categories: 'Categories',
  startDate: 'Start Date',
  endDate: 'End Date',
  shelfTitle: 'Shelf Title',
  palletStackEnum: 'Shipper Type',
  shipperDescription: 'Shipper Description',
}
