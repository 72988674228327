import { createAsyncThunk } from '@reduxjs/toolkit'
import { DeleteShelfArtifact, PatchShelfArtifact, PostShelfArtifact } from 'src/api/ShelfArtifact'
import { handleCreateAsyncThunkResult } from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'ShelfArtifact'
const buildThunkPrefix = (slice, thunkName) => `${slice}/${thunkName}`

const actionCreateShelfArtifact = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'createShelfArtifact'),
  async (artifact, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const postShelfArtifactInstance = new PostShelfArtifact(store, {
      params: artifact,
    })
    const response = await handleCreateAsyncThunkResult(
      postShelfArtifactInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionUpdateShelfArtifact = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'updateShelfArtifact'),
  async ({ id, artifact }, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const patchShelfArtifactInstance = new PatchShelfArtifact(store, {
      params: {
        id,
        artifact,
      },
    })
    const response = await handleCreateAsyncThunkResult(
      patchShelfArtifactInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)
const actionDeleteShelfArtifact = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'deleteShelfArtifact'),
  async (id, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const deleteShelfArtifactInstance = new DeleteShelfArtifact(store, {
      params: { id },
    })
    const response = await handleCreateAsyncThunkResult(
      deleteShelfArtifactInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

export { actionCreateShelfArtifact, actionUpdateShelfArtifact, actionDeleteShelfArtifact }
