import React from 'react'

import { withFlyout } from 'src/components/Flyout'

import Form from './Form'
import FlyoutButton from './FlyoutButton'

const Flyout = withFlyout(Form)

export default props => <Flyout element={FlyoutButton} {...props} />
