import { connect } from 'react-redux'

import { selectSelf } from 'src/store/data/selectors'
import Profile from './Profile'

export const mapStateToProps = state => ({
  self: selectSelf(state),
})

export default connect(mapStateToProps)(Profile)
