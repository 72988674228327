import React from 'react'
import PropTypes from 'prop-types'
import {
  FilledButton,
  Card,
  GridWrapper,
  GridItem,
  FlagWrapper,
  FlagBody,
  FlagComponent,
  Section,
  Heading3,
} from '@jsluna/react'

import { formatInstances } from 'src/utils/instances'

import { CREATE_SPACE_INSTANCES } from 'src/constants/permissions'

import WithRequiredPermissions from 'src/components/WithRequiredPermissions'

import SpaceInstance from './SpaceInstance'
import LayoutTypesDialog from './LayoutTypesDialog'

const SpaceInstances = props => {
  const { assignment, createSpaceInstance, updateSpaceInstance, deleteSpaceInstance } = props
  const {
    spaceInstances = [],
    weeks,
    id: assignmentId,
    space,
    changeControlStatus,
    hasProducts,
  } = assignment
  const autoSpaceInstance = spaceInstances.find(spaceInstance => spaceInstance.isAutoSpaceInstance)
  const instances = formatInstances([...spaceInstances], autoSpaceInstance)
  const {
    spaceType: { layouts },
  } = space
  const hasMultipleLayouts = layouts.length > 1
  const create = layoutType => createSpaceInstance(assignmentId, layoutType)
  const deleteInstance = instanceId => deleteSpaceInstance(instanceId, assignmentId)
  const updateInstance = instance => updateSpaceInstance(instance, assignmentId)
  const breakpoints = {
    sm: '1/1',
    md: '1/1',
  }

  return (
    <div>
      <FlagWrapper>
        <FlagBody>
          <Heading3>Clusters</Heading3>
        </FlagBody>
        <FlagComponent nowrap>
          <WithRequiredPermissions permission={CREATE_SPACE_INSTANCES}>
            {hasMultipleLayouts ? (
              <LayoutTypesDialog
                data-control="select-layout-dialog"
                layouts={layouts}
                createSpaceInstance={create}
                element={({ onClick }) => (
                  <FilledButton
                    className="ln-u-push-bottom"
                    onClick={onClick}
                    data-control="add-space-instance"
                  >
                    Add Cluster Group
                  </FilledButton>
                )}
              />
            ) : (
              <FilledButton
                data-control="add-space-instance"
                className="ln-u-push-bottom"
                onClick={() => create(layouts[0].layoutType)}
              >
                Add Cluster Group
              </FilledButton>
            )}
          </WithRequiredPermissions>
        </FlagComponent>
      </FlagWrapper>
      <GridWrapper matrix>
        {instances.map(instance => (
          <GridItem size={breakpoints} key={instance.id}>
            <Section>
              <Card>
                <SpaceInstance
                  isAuto={instance.id === autoSpaceInstance.id}
                  instance={instance}
                  allInstances={instances}
                  updateInstance={updateInstance}
                  deleteInstance={deleteInstance}
                  space={space}
                  weeks={weeks}
                  startDate={assignment.startDate}
                  endDate={assignment.endDate}
                  spaceAssignmentId={assignmentId}
                  changeControlStatus={changeControlStatus}
                  hasProducts={hasProducts}
                />
              </Card>
            </Section>
          </GridItem>
        ))}
      </GridWrapper>
    </div>
  )
}

SpaceInstances.propTypes = {
  assignment: PropTypes.shape({
    id: PropTypes.string,
    spaceInstances: PropTypes.arrayOf(PropTypes.shape({})),
    autoSpaceInstance: PropTypes.shape({
      id: PropTypes.string,
    }),
    space: PropTypes.shape({
      spaceType: PropTypes.shape({
        layouts: PropTypes.arrayOf(
          PropTypes.shape({
            layoutType: PropTypes.string,
          })
        ),
      }),
    }),
    weeks: PropTypes.arrayOf(PropTypes.number),
    changeControlStatus: PropTypes.string,
    hasProducts: PropTypes.bool,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  createSpaceInstance: PropTypes.func.isRequired,
  updateSpaceInstance: PropTypes.func.isRequired,
  deleteSpaceInstance: PropTypes.func.isRequired,
  changeControlStatus: PropTypes.string.isRequired,
}

export default SpaceInstances
