import React from 'react'
import ReactDOM from 'react-dom'
import { NotificationList } from '@jsluna/react'

import Notifications from 'src/modules/App/Notifications'
import Loading from 'src/modules/App/Loading'

function Activities() {
  const activitiesRoot = document.getElementById('activities-root')

  return ReactDOM.createPortal(
    <NotificationList open>
      <Notifications />
      <Loading />
    </NotificationList>,
    activitiesRoot
  )
}

export default Activities
