import React from 'react'
import { saveAs } from 'file-saver'
import { TextButton } from '@jsluna/react'

import { columns } from '../columns'

const csvHeaders = columns.map(({ name }) => `"${name}"`).join(',')
const csvExamples = columns.map(({ example = '' }) => `"${example}"`).join(',')
const csvContents = [csvHeaders, csvExamples].join('\r\n')
const csvFile = new Blob([csvContents], { type: 'text/csv;charset=utf-8' })

const TemplateDownload = () => (
  <TextButton onClick={() => saveAs(csvFile, 'nominations_download.csv')}>
    Download Template
  </TextButton>
)

export default TemplateDownload
