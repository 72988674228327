import React from 'react'
import { Card, Heading2 } from '@jsluna/react'

import Section from 'src/components/Section'

import Form from './Form'

const Exports = () => (
  <Section>
    <Heading2>
      <span data-control="exports-title">Exports</span>
    </Heading2>
    <Card>
      <Form />
    </Card>
  </Section>
)

export default Exports
