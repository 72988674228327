import React from 'react'
import loader from 'src/hoc/loader'
import PageNotFound from 'src/components/Errors/NotFound'

import { fetchTimelineBySpacePlan } from '../store'
import Timeline from './Timeline'

const getData = async ({ id }, dispatch) => {
  await dispatch(fetchTimelineBySpacePlan(id)).unwrap()
}

const options = {
  Custom404: (
    <PageNotFound
      title="Timeline Not Found"
      message="The Timeline you are looking for cannot be found."
    />
  ),
  extractProps: ({ params, authData, children }) => ({ ...params, ...authData, children }),
  shouldRefresh: ({ id: previousId }, { id }) => previousId !== id,
}

export default loader(getData, options)(Timeline)
