import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RichTextEditor from 'react-rte'

class RichTextBox extends Component {
  state = {
    value: RichTextEditor.createEmptyValue(),
  }

  onChange = value => {
    const { onChange } = this.props
    this.setState({ value })
    if (onChange) {
      onChange(value.toString('html'))
    }
  }

  render() {
    const { value } = this.state
    const toolbarConfig = {
      display: [
        'INLINE_STYLE_BUTTONS',
        'BLOCK_TYPE_BUTTONS',
        'BLOCK_TYPE_DROPDOWN',
        'HISTORY_BUTTONS',
      ],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Strikethrough', style: 'STRIKETHROUGH' },
        { label: 'Monospace', style: 'CODE' },
        // { label: 'Underline', style: 'UNDERLINE' },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
        { label: 'Blockquote', style: 'blockquote' },
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' },
        { label: 'Code Block', style: 'code-block' },
      ],
    }

    return (
      <RichTextEditor
        autoFocus
        toolbarConfig={toolbarConfig}
        value={value}
        onChange={this.onChange}
        placeholder="Please add a comment"
        toolbarClassName="rich-text-toolbar"
      />
    )
  }
}
export default RichTextBox

RichTextBox.propTypes = {
  onChange: PropTypes.func.isRequired,
}
