import loader from 'src/hoc/loader'

import { actionClearData, fetchNominations, fetchNominationTemplate } from './store'
import Nominations from './Nominations'

const getData = async (props, dispatch) => {
  await dispatch(fetchNominations())
  await dispatch(fetchNominationTemplate())
}

const options = {
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(Nominations)
