import React from 'react'
import PropTypes from 'prop-types'

import { getDayMonth } from 'src/utils/timelines/formatDate'
import createClasses from 'src/utils/timelines/classes'

const buildDataAttributes = (attributes = {}) => {
  const value = {}
  Object.keys(attributes).forEach(name => {
    value[`data-${name}`] = attributes[name]
  })
  return value
}

const Basic = ({ title, start, end, style, classes, dataSet, tooltip }) => (
  <div
    className={createClasses('pt-element', classes)}
    style={style}
    {...buildDataAttributes(dataSet)}
  >
    <div className="pt-element__content" aria-hidden="true">
      <span className="pt-element__title">{title}</span>
    </div>
    <div className="pt-element__tooltip">
      {tooltip ? (
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: tooltip.split('\n').join('<br>') }} />
      ) : (
        <div>
          <div>{title}</div>
          <div>
            <strong>Start</strong> {getDayMonth(start)}
          </div>
          <div>
            <strong>End</strong> {getDayMonth(end)}
          </div>
        </div>
      )}
    </div>
  </div>
)

Basic.propTypes = {
  title: PropTypes.string.isRequired,
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  style: PropTypes.shape({}),
  classes: PropTypes.arrayOf(PropTypes.string.isRequired),
  dataSet: PropTypes.shape({}),
  tooltip: PropTypes.string,
}

Basic.defaultProps = {
  style: undefined,
  classes: [],
  dataSet: undefined,
  tooltip: '',
}

export default Basic
