function merge(state = {}, incoming = {}) {
  const merged = Object.keys(incoming).reduce((obj, key) => {
    const oldData = state[key] || {}
    const newData = incoming[key] || {}

    return { ...obj, [key]: { ...oldData, ...newData } }
  }, {})

  return { ...state, ...merged }
}

export default merge
