import React from 'react'
import PropTypes from 'prop-types'

import sortArray from 'src/utils/sortArray'

import withModal from 'src/hoc/withModal'
import WithRequiredPermissions from 'src/components/WithRequiredPermissions'
import { UPDATE_SHELVES_CATEGORIES } from 'src/constants/permissions'

import ModalLoader from './ModalLoader'

const CategoriesModal = withModal(ModalLoader)

const ShelfCategories = ({ shelf, updateShelf, open, onClose }) => {
  const selectedCategories = sortArray([...shelf.categories], 'isPriceLock', false, 'shortTitle')

  const save = newCategories =>
    updateShelf({
      ...shelf,
      categories: newCategories.map(id => ({ id })),
    })

  return (
    <div>
      <ul className="ln-u-visually-hidden" data-control="shelf-assigned-categories">
        {selectedCategories.map(category => (
          <li key={category.id}>{category.title}</li>
        ))}
      </ul>
      <WithRequiredPermissions permission={UPDATE_SHELVES_CATEGORIES}>
        <div data-control="shelf-categories-edit">
          <CategoriesModal
            shelfId={shelf.id}
            selectedCategories={selectedCategories}
            save={save}
            open={open}
            onClose={onClose}
          />
        </div>
      </WithRequiredPermissions>
    </div>
  )
}

const categoryType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
})

ShelfCategories.propTypes = {
  shelf: PropTypes.shape({
    id: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(categoryType).isRequired,
  }).isRequired,
  updateShelf: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default ShelfCategories
