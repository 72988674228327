import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Flyout from '..'

const withFlyout = ComposedComponent => {
  const wrappedComponentName =
    ComposedComponent.displayName || ComposedComponent.name || 'Component'

  const FlyoutWrapped = props => {
    const [isVisible, setIsVisible] = useState(false)
    const ESCAPE = 27
    const { element, onClose, ...restProps } = props
    const toggleOpen = () => {
      setIsVisible(visible => !visible)
    }

    const handleClose = () => {
      setIsVisible(false)

      if (onClose) onClose()
    }

    const handleEscape = event => {
      if (event.keyCode === ESCAPE) {
        handleClose()
      }
    }

    const buttonProps = {
      ...restProps,
      'aria-haspopup': true,
      'aria-expanded': isVisible,
      key: 'modal-button',
      onClick: toggleOpen,
    }

    const componentProps = {
      ...props,
      children: null,
      handleClose,
    }

    useEffect(() => {
      document.addEventListener('keydown', handleEscape, false)

      return () => {
        document.removeEventListener('keydown', handleEscape, false)
      }
    }, [])

    return (
      <Flyout.Container isVisible={isVisible}>
        {React.createElement(element, buttonProps)}
        <Flyout.Wrapper isVisible={isVisible}>
          <Flyout.Background onClick={handleClose} />
          <Flyout.Component>
            <ComposedComponent {...componentProps} />
          </Flyout.Component>
        </Flyout.Wrapper>
      </Flyout.Container>
    )
  }

  FlyoutWrapped.displayName = `Flyout(${wrappedComponentName})`

  FlyoutWrapped.propTypes = {
    onClose: PropTypes.func,
    element: PropTypes.PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func])
      .isRequired,
    className: PropTypes.string,
  }

  FlyoutWrapped.defaultProps = {
    onClose: null,
    className: null,
  }

  return FlyoutWrapped
}

export default withFlyout
