import React from 'react'
import PropTypes from 'prop-types'

const Tooltip = props => {
  const { message } = props
  return <div className="c-tooltip">{message}</div>
}

Tooltip.propTypes = {
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
}

export default Tooltip
