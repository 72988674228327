import loader from 'src/hoc/loader'
import { actionClearData, fetchSpaceTypes } from './store'
import SpaceTypes from './SpaceTypes'

const getData = async (_, dispatch) => {
  await dispatch(fetchSpaceTypes())
}

const options = {
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(SpaceTypes)
