import { IMPORT_UPLOAD_REQUEST, IMPORT_UPLOAD_SUCCESS, IMPORT_UPLOAD_FAILURE } from './actions'

const initialState = {
  uploading: false,
  err: undefined,
  resultId: undefined,
}

const reducer = (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case IMPORT_UPLOAD_REQUEST:
      return {
        ...state,
        uploading: true,
        err: undefined,
        resultId: undefined,
      }
    case IMPORT_UPLOAD_SUCCESS:
      return {
        ...state,
        uploading: false,
        resultId: action.payload.id,
      }
    case IMPORT_UPLOAD_FAILURE:
      return {
        ...state,
        uploading: false,
        err: action.payload,
      }
    default:
      return state
  }
}

export default reducer
