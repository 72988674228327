import React from 'react'
import { Card, Heading3 } from '@jsluna/react'

import Section from 'src/components/Section'
import Table from 'src/components/Table'

import { selectAllSpaces } from './store'
import columns from './columns'

const Spaces = () => (
  <div data-control="spaces-list">
    <Section>
      <Card>
        <Heading3 className="ln-u-visually-hidden">Spaces List</Heading3>
        <Table type="spaces" searchEnabled columns={columns} dataSelector={selectAllSpaces} />
      </Card>
    </Section>
  </div>
)

export default Spaces
