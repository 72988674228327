import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { handleCreateAsyncThunkResult } from 'src/utils/createAsyncThunkHandler'
import {
  GetExportsMasterPsa,
  GetExportSpaceAllocation,
  GetExportPosAdvice,
  PostExportStores,
} from 'src/api/Export'

const NAMESPACE = 'Exports'
const STORE_PATH = `modules/${NAMESPACE}`

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const fetchExportMastrePsa = createAsyncThunk(
  NAMESPACE,
  async ({ startDate, endDate }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getExportMastrePsaInstance = new GetExportsMasterPsa(store, {
      params: { startDate, endDate },
    })

    const response = await handleCreateAsyncThunkResult(
      getExportMastrePsaInstance,
      dispatch,
      rejectWithValue,
      false
    )

    return response
  }
)

const fetchExportSpaceAllocation = createAsyncThunk(
  NAMESPACE,
  async ({ startDate, endDate }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getExportSpaceAllocationInstance = new GetExportSpaceAllocation(store, {
      params: { startDate, endDate },
    })

    const response = await handleCreateAsyncThunkResult(
      getExportSpaceAllocationInstance,
      dispatch,
      rejectWithValue,
      false
    )

    return response
  }
)

const fetchExportPosAdvice = createAsyncThunk(
  NAMESPACE,
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getExportPosAdviceInstance = new GetExportPosAdvice(store)

    const response = await handleCreateAsyncThunkResult(
      getExportPosAdviceInstance,
      dispatch,
      rejectWithValue,
      false
    )

    return response
  }
)

const fetchStoresExport = createAsyncThunk(
  NAMESPACE,
  async ({ startDate, endDate, spaceId, clusterIds }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()

    const getExportStoresInstance = new PostExportStores(store, {
      params: { startDate, endDate, spaceId, clusterIds },
    })

    const response = await handleCreateAsyncThunkResult(
      getExportStoresInstance,
      dispatch,
      rejectWithValue,
      false
    )

    return response
  }
)

export {
  actionClearData,
  fetchExportMastrePsa,
  fetchExportSpaceAllocation,
  fetchExportPosAdvice,
  fetchStoresExport,
}
