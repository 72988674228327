import { PublicClientApplication } from '@azure/msal-browser'

const msalConfig = {
  auth: {
    clientId: window.PROMCOMM.AZURE_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/e11fd634-26b5-47f4-8b8c-908e466e9bdf',
    redirectUri: `${window.location.origin}/login`,
  },
}

const msalInstance = new PublicClientApplication(msalConfig)

export default msalInstance
