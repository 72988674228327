import React from 'react'
import PropTypes from 'prop-types'

import StorePsaEntry from './StorePsaEntry'

const Psa = ({ psaEntries, title, storePSAFlag, spaces, disablePOSAdviceColumn }) => {
  return storePSAFlag
    ? spaces.map((space, index) => {
        const { title: spaceTitle, storeTitleComment, spaceInstances } = space
        return spaceInstances.map(spaceInstance => {
          const { products, shelfArtifacts, shelfProductLayouts } = spaceInstance
          return (
            <StorePsaEntry
              key={spaceInstance.id}
              index={index}
              rows={products}
              spaceTitle={spaceTitle}
              storeTitleComment={storeTitleComment}
              psaGroupTitle={title}
              storePSAFlag={storePSAFlag}
              artifacts={shelfArtifacts}
              layouts={shelfProductLayouts}
              disablePOSAdviceColumn={disablePOSAdviceColumn}
              {...spaceInstance}
            />
          )
        })
      })
    : psaEntries.map((psaEntry, index) => {
        const { space, title: storeTitleComment } = psaEntry
        return (
          <StorePsaEntry
            key={psaEntry.id}
            index={index}
            psaGroupTitle={title}
            spaceTitle={space.title}
            storeTitleComment={storeTitleComment}
            storePSAFlag={false}
            disablePOSAdviceColumn={disablePOSAdviceColumn}
            {...psaEntry}
          />
        )
      })
}

Psa.propTypes = {
  psaEntries: PropTypes.arrayOf(PropTypes.shape({})),
  spaces: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
  storePSAFlag: PropTypes.bool.isRequired,
  disablePOSAdviceColumn: PropTypes.bool,
}

Psa.defaultProps = {
  psaEntries: [{}],
  storePSAFlag: false,
  spaces: [{}],
  disablePOSAdviceColumn: false,
}

export default Psa
