import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { selectCategoryByIdInfo } from '../store'

import Form from './Form'

export const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps
  const category = (id && selectCategoryByIdInfo(state, id)) || {}
  const {
    title = '',
    shortTitle = '',
    theme = '',
    businessUnit,
    isPriceLock = false,
    active = false,
  } = category

  return {
    ...category,
    id,
    title,
    shortTitle,
    businessUnit,
    theme,
    isPriceLock,
    active,
  }
}

const returnAction = () => push('/categories')
const mapDispatchToProps = dispatch => ({
  afterSubmit: () => dispatch(returnAction()),
  cancel: () => dispatch(returnAction()),
  submit: action => dispatch(action),
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
