const getLink = category => `/categories/${category.id}`

const getBusinessUnit = ({ businessUnit }) => {
  if (businessUnit === 'food') return 'Food'
  return 'Non-Food'
}

const getIsActive = ({ active }) => {
  if (active) return 'Yes'
  return 'No'
}

const getIsPriceLocked = ({ isPriceLock }) => {
  if (isPriceLock) return 'Yes'
  return 'No'
}

export default [
  { key: 'title', name: 'Title', value: 'title', link: getLink },
  { key: 'short_title', name: 'Short Title', value: 'shortTitle' },
  { key: 'businessUnit', name: 'Business Unit', value: getBusinessUnit },
  {
    key: 'isPriceLock',
    name: 'Price Locked',
    value: getIsPriceLocked,
    sortValue: getIsPriceLocked,
  },
  { key: 'active', name: 'Active', value: getIsActive, sortValue: getIsActive },
]
