const readFile = file => {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      resolve(new Uint8Array(reader.result))
    }

    reader.onerror = () => {
      reader.abort()
      reject(reader.error)
    }

    reader.readAsArrayBuffer(file)
  })
}

export default readFile
