import React from 'react'
import PropTypes from 'prop-types'
import { TextButton } from '@jsluna/react'

const ModalButton = ({ onClick }) => (
  <TextButton className="ln-u-font-weight-medium u-nowrap" onClick={onClick}>
    Select custom
  </TextButton>
)

ModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default ModalButton
