import createStatus from 'src/utils/statuses'

export const UNLOCKED_ID = 'unlocked'
export const LOCKED_ID = 'locked'

const UNLOCKED = {
  id: UNLOCKED_ID,
  title: 'Unlocked',
  transition: [UNLOCKED_ID, LOCKED_ID],
}

const LOCKED = {
  id: LOCKED_ID,
  title: 'Locked',
  transition: [LOCKED_ID],
}

export const ALL_IDS = [UNLOCKED_ID, LOCKED_ID]

export const ALL_BY_ID = {
  [UNLOCKED_ID]: UNLOCKED,
  [LOCKED_ID]: LOCKED,
}

export const ALL = ALL_IDS.map(id => ALL_BY_ID[id])

export default createStatus(ALL)
