import React from 'react'
import loader from 'src/hoc/loader'
import NotFound from 'src/components/Errors/NotFound'
import RangeAndSpace from './RangeAndSpace'
import actionGetData from './store/actions'

const getData = async (props, dispatch) => {
  const {
    params: { storeNumber },
  } = props

  await dispatch(actionGetData(storeNumber))
}

const options = {
  Custom404: (
    <NotFound
      title="Store Not Found"
      message="The store you are looking for cannot be found."
      linkUrl="/range-and-space"
      linkTitle="Back to Range and Space"
    />
  ),
}
export default loader(getData, options)(RangeAndSpace)
