import keyBy from 'src/utils/keyBy'

const mapWithSelected = (categories, selected) =>
  categories.map(category => ({ ...category, selected }))

const buildCategories = (allCategories, filterCategories) => {
  const defaultSelected = !filterCategories
  const categories = mapWithSelected(allCategories, defaultSelected)
  const categoriesById = keyBy(categories, 'id')

  if (filterCategories) {
    filterCategories.forEach(id => {
      if (categoriesById[id]) {
        categoriesById[id].selected = true
      }
    })
  }

  return {
    categories,
    categoriesById,
  }
}

export default buildCategories
