import { SHELF_LAYOUT_TYPE } from 'src/constants/layoutTypes'
import { isPalletOrStackLayout, isShipper } from './layoutTypes'

const buildShelfTitle = (position, shelvesLength, rsb = false, layoutType = SHELF_LAYOUT_TYPE) => {
  let letter = String.fromCharCode(65 + position)

  if (isPalletOrStackLayout(layoutType) || isShipper(layoutType)) {
    return letter
  }

  if (shelvesLength && shelvesLength > 1) {
    if (position === 0) {
      letter += '(TOP)'
    }
    if (position === shelvesLength - 1) {
      letter += '(BASE)'
    }
  }

  if (rsb) {
    letter += ' RSB'
  }

  return letter
}

export default buildShelfTitle
