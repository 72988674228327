import React from 'react'
import PropTypes from 'prop-types'

import { Heading3, FilledButton } from '@jsluna/react'

const DeleteDialog = ({ sspsaTaskId: id, handleClose, deleteSspsaTask }) => (
  <div>
    <Heading3>Are you sure you want to remove this SSPSA Task</Heading3>
    <p>Please confirm this action</p>
    <div>
      <FilledButton
        data-control="modal-confirm-delete-sspsa-task"
        onClick={async () => {
          handleClose()
          await deleteSspsaTask({ id })
        }}
      >
        Confirm
      </FilledButton>
      <FilledButton
        color="dark"
        data-control="modal-cancel-delete-sspsa-task"
        onClick={handleClose}
        className="ln-u-push-left"
      >
        Cancel
      </FilledButton>
    </div>
  </div>
)

DeleteDialog.propTypes = {
  deleteSspsaTask: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  sspsaTaskId: PropTypes.string.isRequired,
}

export default DeleteDialog
