import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Form, GridWrapper, GridItem, FilledButton } from '@jsluna/react'
import { Upload as UploadIcon } from '@jsluna/icons'

import readFile from 'src/utils/readFile'

import TemplateDownload from './TemplateDownload'

class UploadForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      file: undefined,
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onChangeFile = this.onChangeFile.bind(this)
  }

  onChangeFile(e) {
    const file = e.target.files[0]
    this.setState({ file })
  }

  async onSubmit(e) {
    e.preventDefault()
    const { file } = this.state
    const { onStatusChange, onUpload } = this.props

    try {
      onStatusChange('Uploading...')
      const data = await readFile(file)
      onStatusChange('Uploading complete')
      onUpload(data)
    } catch (error) {
      onStatusChange(`Error: ${error.message}`, true)
    }
  }

  render() {
    const { file } = this.state
    const fileName = file && file.name

    return (
      <Form onSubmit={this.onSubmit} className="ln-u-push-bottom">
        <GridWrapper matrix>
          <GridItem size="2/3@md">
            <input
              className="c-file-upload__input"
              type="file"
              id="upload-file"
              onChange={this.onChangeFile}
            />
            <label
              htmlFor="upload-file"
              className="ln-c-button ln-c-button--outlined ln-c-button--full c-file-upload__button"
            >
              {fileName || 'Choose a file'}
            </label>
          </GridItem>
          <GridItem size="1/3@md">
            <FilledButton type="submit" disabled={!file} fullWidth>
              Upload <UploadIcon className="ln-u-padding-left" />
            </FilledButton>
          </GridItem>
          <GridItem size="1/1">
            <TemplateDownload />
          </GridItem>
        </GridWrapper>
      </Form>
    )
  }
}

UploadForm.propTypes = {
  onStatusChange: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
}

export default UploadForm
