export const FROZEN_SPACE_TYPE = {
  id: 'frozen',
  label: 'Frozen',
}

export const AMBIENT_SPACE_TYPE = {
  id: 'ambient',
  label: 'Ambient',
}

export const PERISHABLE_SPACE_TYPE = {
  id: 'perishable',
  label: 'Perishable',
}

export const STORAGE_IDS = [FROZEN_SPACE_TYPE.id, AMBIENT_SPACE_TYPE.id, PERISHABLE_SPACE_TYPE.id]

export const STORAGE_BY_ID = {
  [FROZEN_SPACE_TYPE.id]: FROZEN_SPACE_TYPE,
  [AMBIENT_SPACE_TYPE.id]: AMBIENT_SPACE_TYPE,
  [PERISHABLE_SPACE_TYPE.id]: PERISHABLE_SPACE_TYPE,
}

export const STORAGE = STORAGE_IDS.map(id => STORAGE_BY_ID[id])

export const PALLET_SPACE_TYPES = new Set(['8d188328-a0a1-410d-9da5-653ee827c069'])
export const STACK_SPACE_TYPES = new Set([
  'bc71bb28-52e9-4d96-9163-17824abc84af',
  'b5aa13c8-89a5-4a57-9303-4f413dd3f75c',
  'bc80f094-8a81-4b40-8ec4-f8ad6de3a50e',
])
