import selectPath from './selectPath'

const identity = item => item

const sortNatural = (array = [], selector = identity, descending = false) => {
  const selectString = typeof selector === 'string' ? item => selectPath(item, selector) : selector

  const compare = (a, b) => {
    const aStr = selectString(a)
    const bStr = selectString(b)
    const c = aStr.localeCompare(bStr, undefined, { numeric: true, sensitivity: 'base' })

    if (c > 0) {
      return descending ? -1 : 1
    }
    if (c < 0) {
      return descending ? 1 : -1
    }
    return 0
  }

  return array.sort(compare)
}

export default sortNatural
