import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { List, ListItem } from '@jsluna/react'

import statuses, { APPROVED_ID } from '../statuses'

const Status = ({ nomination }) => {
  const { lastUpdatedByUsername, lastUpdatedDate = '', status } = nomination
  const { title: statusTitle } = statuses.get(status)

  if (status === APPROVED_ID && lastUpdatedByUsername !== null && lastUpdatedDate !== '') {
    return (
      <List type="bare">
        <ListItem type="bare">
          {statusTitle} by {lastUpdatedByUsername}
        </ListItem>
        <ListItem type="bare">{moment(lastUpdatedDate).format('Do MMM YYYY')}</ListItem>
      </List>
    )
  }

  return <div>{statusTitle}</div>
}

Status.propTypes = {
  nomination: PropTypes.shape({
    lastUpdatedByUsername: PropTypes.string,
    lastUpdatedDate: PropTypes.string,
    status: PropTypes.string,
  }),
}

Status.defaultProps = {
  nomination: {},
}

export default Status
