import { fetchShipperCompliances } from '.'
import { actionUpdateTouchPointByShipperComplianceId } from './updateTouchPointByShipperComplianceId'

const SAVE_SPACE_MULTISELECT = 'cycle-shipper-compliance-save-space-multiselect'
const RESET_SPACE_MULTISELECT = 'cycle-shipper-compliance-reset-space-multiselect'

const saveSpaceMultiselect = payload => ({
  type: SAVE_SPACE_MULTISELECT,
  payload,
})

const resetSpaceMultiselect = payload => ({
  type: RESET_SPACE_MULTISELECT,
  payload,
})

const actionGetData = async ({ cycleId }, dispatch) => {
  await dispatch(
    fetchShipperCompliances({
      cycleId,
    })
  )
}

const updateTouchPointByShipperComplianceId = (
  cycleId,
  shipperComplianceId,
  touchPointDetail
) => async dispatch => {
  await dispatch(
    actionUpdateTouchPointByShipperComplianceId({ id: shipperComplianceId, touchPointDetail })
  )
  actionGetData({ cycleId }, dispatch)
}

export {
  SAVE_SPACE_MULTISELECT,
  RESET_SPACE_MULTISELECT,
  actionGetData,
  updateTouchPointByShipperComplianceId,
  saveSpaceMultiselect,
  resetSpaceMultiselect,
}
