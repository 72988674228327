import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { GetSpacePlans } from 'src/api/SpacePlan'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  clearDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'SpacePlans'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = slice => `${STORE_PATH}/${slice}`

const selectModule = state => state.modules.SpacePlans
const selectSpacePlan = createSelector(selectModule, module => module.data)

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)
const fetchSpacePlans = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchSpacePlans'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getSpacePlansInstance = new GetSpacePlans(store)

    const response = await handleCreateAsyncThunkResult(
      getSpacePlansInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const spacePlansSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSpacePlans.pending, pendingDataHandler)
    builder.addCase(fetchSpacePlans.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpacePlans.rejected, rejectedDataHandler)
    builder.addCase(actionClearData, clearDataHandler)
  },
})
export default { [NAMESPACE]: spacePlansSlice.reducer }

export { fetchSpacePlans, selectSpacePlan, actionClearData }
