import React from 'react'
import { DropTarget } from 'react-dnd'

const withDrop = ComposedComponent => {
  const wrappedComponentName =
    ComposedComponent.displayName || ComposedComponent.name || 'Component'

  const targetSpec = {
    drop: (props, monitor) => {
      const {
        shelf: {
          id: targetRowShelfId,
          shelfProducts: products,
          spaceInstanceId: targetSpaceInstanceId,
        },
        moveProductToShelf,
      } = props

      const {
        shelfProductId,
        shelfId: draggedRowShelfId,
        spaceInstanceId: draggedSpaceInstanceId,
      } = monitor.getItem()

      const isSameShelf = draggedRowShelfId === targetRowShelfId
      const isSameSpaceInstance = draggedSpaceInstanceId === targetSpaceInstanceId

      if (!isSameSpaceInstance || products.length) {
        return
      }

      if (!isSameShelf) {
        moveProductToShelf(shelfProductId, targetRowShelfId, 0)
      }
    },
  }

  const targetCollect = connect => ({
    connectDropTarget: connect.dropTarget(),
  })

  const DropTargetItem = DropTarget('shelf-product-row', targetSpec, targetCollect)
  const DropComponentWrapper = ({ connectDropTarget, ...props }) =>
    connectDropTarget(
      <div>
        <ComposedComponent {...props} />
      </div>
    )

  DropComponentWrapper.displayName = `DropComponentWrapper(${wrappedComponentName})`

  return DropTargetItem(DropComponentWrapper)
}

export default withDrop
