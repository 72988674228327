export const TPR = 'TPR'
export const AP = 'Ambient Plinth'
export const PP = 'Perishable Plinth'
export const PROMO_TYPE = 'promoMechanic'
export const STUNT_MECHANIC = 'STUNT'
export const HALF_PRICE_MECHANIC = 'Half Price'
export const SAVE_PRC_MECHANIC = 'Save %X'
export const SAVE_POUND_MECHANIC = 'Save £X'
export const INTRO_OFFER = 'Intro Offer'

export const SHELF_FILL_TYPE_CASE = 'case'
export const SHELF_FILL_TYPE_UNIT = 'unit'

export const SHELF_FILL_TYPE_DEFAULT = SHELF_FILL_TYPE_CASE

export const SHELF_FILL_TYPE_OPTIONS = [
  { id: SHELF_FILL_TYPE_CASE, value: SHELF_FILL_TYPE_CASE, label: 'Case Fill' },
  { id: SHELF_FILL_TYPE_UNIT, value: SHELF_FILL_TYPE_UNIT, label: 'Unit Fill' },
]

export const PROMO_MECHANIC_NOT_DEFINED_ID = '31c93636-9181-4d76-90d1-9fb30f339cbf'
