import {
  nonFood,
  addHfssProperty,
  enableHfssRestriction,
  hfssErrorMessage,
  hfssUncategorized,
} from 'src/functions/productHfssStatus'

const hfssValidations = props => {
  const { products, space } = props

  products.forEach(product => {
    const productHfssFlag = hfssUncategorized(product)

    const hfssRestriction =
      !nonFood(product) && enableHfssRestriction(space.hfssFlag, productHfssFlag)
    const { errors } = product
    const hfssError = !!errors?.find(error => error === hfssErrorMessage)

    if (hfssRestriction && !hfssError) errors?.push(hfssErrorMessage)
    addHfssProperty(product, productHfssFlag)

    return product
  })
}

export default hfssValidations
