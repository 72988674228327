import { connect } from 'react-redux'
import {
  READ_POS,
  UPDATE_SHELVES_PRODUCTS_POS_ALL,
  UPDATE_SHELVES_PRODUCTS_POS_OWN_CATEGORY,
} from 'src/constants/permissions'
import { hasRequiredPermission, hasPermission } from 'src/utils/permissions'
import loader from 'src/hoc/loader'

import { isDraft, isPending } from 'src/utils/changeControls'
import { CHANGE_CONTROL_UNLOCKED_ID } from 'src/constants/cycles'
import POSAdvice from './POSAdvice'
import {
  fetchPOSAdviceList,
  selectShelfProductsWithPOS,
  fetchShelfProductsWithPOS,
  actionUpdateShelfProductsWithPOS,
} from './store'
import {
  fetchSpacePlanAssignment,
  selectSpaceAssignment,
} from '../store/reducer/spacePlanAssignment'

const getData = async ({ id, shelfId, user }, dispatch) => {
  await dispatch(fetchShelfProductsWithPOS(shelfId)).unwrap()
  await dispatch(fetchSpacePlanAssignment({ id })).unwrap()
  const canReadPOS = hasRequiredPermission(user, READ_POS)
  if (canReadPOS) await dispatch(fetchPOSAdviceList())
}

const options = {
  extractProps: ({ params, authData, location: { query } }) => ({ ...params, ...authData, query }),
}

const isSameUser = (user1, user2) => user1.id === user2?.id

export const mapStateToProps = (state, { user }) => {
  const spaceAssignment = selectSpaceAssignment(state)
  const { pendingChangeControl: changeControl, changeControlStatus } = spaceAssignment

  const isUnLockedForChangeControl = changeControlStatus === CHANGE_CONTROL_UNLOCKED_ID
  const isDraftChangeControl = isDraft(changeControl)

  const isChangeControlCreator = changeControl ? isSameUser(user, changeControl.createdBy) : false

  const shelfProductsWithPOS = selectShelfProductsWithPOS(state)
  const shelfProductCategories = [
    ...new Set(
      shelfProductsWithPOS?.map(shelfProduct => {
        return shelfProduct.category
      })
    ),
  ]
  const hasPermissionToUpdateInChangeControl =
    hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_POS_ALL) ||
    hasPermission(
      user,
      [UPDATE_SHELVES_PRODUCTS_POS_OWN_CATEGORY],
      shelfProductCategories,
      changeControl?.createdBy
    )

  const canUpdateInChangeControl =
    isDraftChangeControl && isChangeControlCreator && hasPermissionToUpdateInChangeControl

  const canUpdateShelvesProductsWithPOS =
    (hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_POS_ALL) && isUnLockedForChangeControl) ||
    canUpdateInChangeControl

  const canCreateShelvesProductsWithPOS = changeControl
    ? canUpdateInChangeControl
    : hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_POS_ALL)

  const changeControlCreatedByOtherUser =
    (changeControl && !isChangeControlCreator && changeControl.createdBy.name) || ''

  return {
    shelfProductsWithPOS,
    canUpdateShelvesProductsWithPOS,
    isPendingChangeControl: isPending(changeControl),
    changeControlCreatedByOtherUser,
    canCreateShelvesProductsWithPOS,
  }
}
const mapDispatchToProps = {
  actionUpdateShelfProductsWithPOS,
  refreshPOSWithShelfProducts: fetchShelfProductsWithPOS,
}
const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(POSAdvice)

export default loader(getData, options)(ConnectedComponent)
