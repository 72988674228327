export const maskObject = (object, mask) => {
  if (!object || !mask) {
    return object
  }

  return Object.keys(object).reduce((output, key) => {
    if (!mask[key]) {
      // eslint-disable-next-line no-param-reassign
      output[key] = object[key]
    }

    return output
  }, {})
}

export default maskObject
