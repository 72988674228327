const buildAssignment = (assignment, categoriesById) => {
  let hasSelectedCategory = false
  const categories = assignment.categories.map(c => {
    const category = categoriesById[c.id]
    if (category && category.selected) {
      hasSelectedCategory = true
    }
    return category
  })
  return {
    ...assignment,
    assignmentId: assignment.id,
    categories,
    hasSelectedCategory,
  }
}

const buildAssignments = (inputAssignments, categoriesById) => {
  let hasSelectedCategory = false
  const assignments = inputAssignments.map(inputAssignment => {
    const assignment = buildAssignment(inputAssignment, categoriesById)
    if (assignment.hasSelectedCategory) {
      hasSelectedCategory = true
    }
    return assignment
  })
  return {
    assignments,
    hasSelectedCategory,
  }
}

export default buildAssignments
