import React, { Component } from 'react'

import Cycle from './Cycle'

class CycleContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }))
  }

  render() {
    const { isOpen } = this.state
    return <Cycle {...this.props} handleClick={this.handleClick} isOpen={isOpen} />
  }
}

export default CycleContainer
