import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'

import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import GetPOSAdvices from 'src/api/POSAdvice'
import {
  GetShelfProductsWithPOS,
  PatchShelfProductsWithPOS,
  PostShelfProductsWithPOS,
} from 'src/api/Shelf'

const NAMESPACE = 'POSAdvice'
const SHELF_NAMESPACE = 'ShelfProductsWithPOS'
const buildThunkPrefix = (slice, thunkName) => `${slice}/${thunkName}`

const selectSelf = state => state.modules
const selectPOSAdviceList = createSelector(selectSelf, module => module[NAMESPACE]?.data || [])

const selectShelfProductsWithPOS = createSelector(
  selectSelf,
  module => module[SHELF_NAMESPACE]?.data || []
)

const fetchPOSAdviceList = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchPOSAdviceList'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getPOSWithProductssInstance = new GetPOSAdvices(store)

    const response = await handleCreateAsyncThunkResult(
      getPOSWithProductssInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchPOSAdviceListSlice = createSlice({
  name: NAMESPACE,
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchPOSAdviceList.pending, pendingDataHandler)
    builder.addCase(fetchPOSAdviceList.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchPOSAdviceList.rejected, rejectedDataHandler)
  },
})

const fetchShelfProductsWithPOS = createAsyncThunk(
  buildThunkPrefix(SHELF_NAMESPACE, 'fetchPOSAdviceList'),
  async (id, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getPOSWithProductssInstance = new GetShelfProductsWithPOS(store, {
      params: { id },
    })

    const response = await handleCreateAsyncThunkResult(
      getPOSWithProductssInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchShelfProductsWithPOSSlice = createSlice({
  name: NAMESPACE,
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchShelfProductsWithPOS.pending, pendingDataHandler)
    builder.addCase(fetchShelfProductsWithPOS.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchShelfProductsWithPOS.rejected, rejectedDataHandler)
  },
})

const actionUpdateShelfProductsWithPOS = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'updateShelfProductsWithPOS'),
  async ({ shelfId, ...shelfProductsWithPOS }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const patchPOSWithProductsInfoInstance = new PatchShelfProductsWithPOS(store, {
      params: { shelfId, ...shelfProductsWithPOS },
    })

    const response = await handleCreateAsyncThunkResult(
      patchPOSWithProductsInfoInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionAddShelfProductsWithPOS = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'postShelfProductsWithPOS'),
  async ({ shelfId, ...shelfProductsWithPOS }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const postPOSWithProductsInfoInstance = new PostShelfProductsWithPOS(store, {
      params: { shelfId, ...shelfProductsWithPOS },
    })

    const response = await handleCreateAsyncThunkResult(
      postPOSWithProductsInfoInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

export default {
  [NAMESPACE]: fetchPOSAdviceListSlice.reducer,
  [SHELF_NAMESPACE]: fetchShelfProductsWithPOSSlice.reducer,
}
export {
  fetchPOSAdviceList,
  selectPOSAdviceList,
  fetchShelfProductsWithPOS,
  selectShelfProductsWithPOS,
  actionUpdateShelfProductsWithPOS,
  actionAddShelfProductsWithPOS,
}
