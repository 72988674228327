import { SPACE_MULTISELECT } from 'src/constants/spacePlanner'
import { SAVE_SPACE_FILTER_TYPE } from '../actions'

const initialState = SPACE_MULTISELECT

const reducer = (state = initialState, { type, payload }) => {
  if (type === SAVE_SPACE_FILTER_TYPE) return payload

  return state
}

export default reducer
