import { LOADING } from 'src/constants/api'

const loading = (state = { inProgress: false, count: 0 }, { type, payload } = {}) => {
  switch (type) {
    case LOADING: {
      const { count } = state
      const newCount = Math.max(0, count + (payload ? 1 : -1))
      return { count: newCount, inProgress: newCount > 0 }
    }
    default:
      return state
  }
}

export default loading
