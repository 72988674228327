import { PatchMoveCluster } from 'src/api/Shelf'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleCreateAsyncThunkResult } from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'MoveCluster'
const buildThunkPrefix = (slice, thunkName) => `${slice}/${thunkName}`

const actionUpdateMoveCluster = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'updateMoveCluster'),
  async (
    { spaceAssignmentId, newSpaceInstanceId, movedClusterId },
    { getState, rejectWithValue, dispatch }
  ) => {
    const store = getState()
    const patchMoveClusterInstance = new PatchMoveCluster(store, {
      params: { spaceAssignmentId, newSpaceInstanceId, movedClusterId },
    })
    const response = await handleCreateAsyncThunkResult(
      patchMoveClusterInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

export default actionUpdateMoveCluster
