import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Field, withFormik } from 'formik'
import { Form, FormGroup, GridWrapper, GridItem } from '@jsluna/react'
import { SelectField, TextInputField } from '@jsluna/form/extensions/formik'

import { CHANGE_CONTROL_ALL } from 'src/modules/Cycles/changeControlStatuses'
import { SHIPPER_LOCK_ALL } from 'src/modules/ShipperCompliance/shipperLockStatuses'
import { Controls } from 'src/components/Form'
import { DateField } from 'src/components/FormikComponents'

import LoadingCard from 'src/components/LoadingCard'
import APIFailed from 'src/components/Errors/APIFailed'
import sortArray from 'src/utils/sortArray'
import Duration from './components/Duration'
import PrevNextCycle from './components/PrevNextCycle'
import Group from './components/Group'
import Status from './components/Status'
import handleSubmit from './handleSubmit'
import validate from './validate'

const CHANGE_CONTROL_STATUS_OPTIONS = CHANGE_CONTROL_ALL.map(({ id: value, title: label }) => ({
  label,
  value,
}))
const SHIPPER_LOCK_STATUS_OPTIONS = SHIPPER_LOCK_ALL.map(({ id: value, title: label }) => ({
  label,
  value,
}))

const Component = ({
  cancel,
  duration,
  handleReset,
  handleSubmit: onSubmit,
  isReadOnly,
  isLocked,
  isShipperLocked,
  maxEndDate,
  maxStartDate,
  next,
  previous,
  startDate,
  spacePlanStatus,
  groupList,
  setFieldValue,
  onChange,
  id: cycleId,
  group,
  values,
  isPreviousCycleLoading,
  previousCycleFetchError,
  spacePlanStartDate,
  spacePlanEndDate,
  businessUnit,
}) => {
  const groupToOptions = ({ id, title }) => ({ id, label: title, value: id })
  const onChangeGroup = e => {
    onChange(e.target.value)
    setFieldValue('groupId', e.target.value)
  }
  const sortGroups = groupArray => sortArray(groupArray.map(groupToOptions), 'label', false) || []

  useEffect(() => {
    setFieldValue('startDate', startDate)
  }, [previous])

  const showGroupInfo = group?.id || groupList.length > 0
  const hideCycleCreation = showGroupInfo && !cycleId && !values.groupId
  return (
    <Form onSubmit={onSubmit} noValidate>
      <FormGroup name="cycle-fields">
        <GridWrapper matrix>
          {showGroupInfo &&
            (cycleId ? (
              <Group group={group} />
            ) : (
              <>
                <GridItem size="1/2@sm">
                  <Field
                    name="groupId"
                    label="Group"
                    component={SelectField}
                    options={sortGroups(groupList)}
                    onChange={onChangeGroup}
                    required
                  />
                </GridItem>
                <br />
              </>
            ))}
          {!hideCycleCreation && isPreviousCycleLoading && <LoadingCard />}
          {!hideCycleCreation && !isPreviousCycleLoading && (
            <>
              {previousCycleFetchError?.status ? (
                <GridItem size="1">
                  <APIFailed />
                </GridItem>
              ) : (
                <>
                  <PrevNextCycle
                    cycle={previous}
                    cycleType="previous"
                    businessUnit={businessUnit}
                  />
                  <PrevNextCycle cycle={next} cycleType="next" businessUnit={businessUnit} />
                  <Status status={spacePlanStatus} />
                  <GridItem size={{ sm: '1/1', md: '1/3' }}>
                    <Field
                      component={TextInputField}
                      disabled={isReadOnly || isLocked}
                      label="Title"
                      name="title"
                      required
                    />
                  </GridItem>
                  <GridItem size={{ sm: '1/1', md: '1/3' }}>
                    <Field
                      component={SelectField}
                      disabled={isReadOnly || !isLocked}
                      label="Change Control Status"
                      name="changeControlStatus"
                      options={CHANGE_CONTROL_STATUS_OPTIONS}
                      required
                    />
                  </GridItem>
                  <GridItem size={{ sm: '1/1', md: '1/3' }}>
                    <Field
                      component={SelectField}
                      disabled={isReadOnly || !isLocked || isShipperLocked}
                      label="Shipper Lock Status"
                      name="shipperLockStatus"
                      options={SHIPPER_LOCK_STATUS_OPTIONS}
                      required
                    />
                  </GridItem>
                  <GridItem size="1/3">
                    <DateField
                      disabled={isReadOnly || isLocked || (previous && previous.id && true)}
                      label="Start Date"
                      maxDate={maxStartDate || spacePlanEndDate}
                      minDate={spacePlanStartDate}
                      name="startDate"
                    />
                  </GridItem>
                  <GridItem size="1/3">
                    <DateField
                      disabled={isReadOnly || isLocked}
                      label="End Date"
                      maxDate={maxEndDate}
                      minDate={startDate}
                      name="endDate"
                      required
                    />
                  </GridItem>
                  {duration && <Duration duration={duration} />}
                </>
              )}
            </>
          )}
        </GridWrapper>
      </FormGroup>
      {!isReadOnly && <Controls cancel={cancel} reset={handleReset} />}
    </Form>
  )
}

Component.propTypes = {
  cancel: PropTypes.func.isRequired,
  duration: PropTypes.number,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  isLocked: PropTypes.bool,
  isShipperLocked: PropTypes.bool,
  maxEndDate: PropTypes.string,
  maxStartDate: PropTypes.string,
  spacePlanStartDate: PropTypes.string,
  spacePlanEndDate: PropTypes.string,
  next: PropTypes.shape({ id: PropTypes.string }),
  previous: PropTypes.shape({ id: PropTypes.string }),
  group: PropTypes.shape({ id: PropTypes.string, title: PropTypes.string }),
  startDate: PropTypes.string,
  spacePlanStatus: PropTypes.string,
  id: PropTypes.string,
  isPreviousCycleLoading: PropTypes.bool,
  groupList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, title: PropTypes.string })),
  values: PropTypes.shape({ groupId: PropTypes.string }),
  previousCycleFetchError: PropTypes.shape({ status: PropTypes.number }),
  businessUnit: PropTypes.oneOf(['food', 'non-food']).isRequired,
}

Component.defaultProps = {
  duration: null,
  isReadOnly: false,
  isLocked: false,
  isShipperLocked: false,
  maxEndDate: null,
  maxStartDate: null,
  next: null,
  previous: null,
  group: null,
  startDate: null,
  spacePlanStatus: null,
  id: '',
  groupList: [],
  isPreviousCycleLoading: false,
  values: {},
  previousCycleFetchError: null,
  spacePlanStartDate: null,
  spacePlanEndDate: null,
}

const wrappedForm = withFormik({ handleSubmit, validate })(Component)

export default wrappedForm
