import React from 'react'
import PropTypes from 'prop-types'

import { Heading3, FilledButton } from '@jsluna/react'

const DeleteDialog = ({ shelfId, handleClose, posAdvice, detachPOSFromProducts, refresh }) => (
  <div>
    <Heading3>Are you sure you want to remove this POS advice from the shelf products</Heading3>
    <p>Please confirm this action</p>
    <div>
      <FilledButton
        data-control="modal-confirm-delete-shelf-pos"
        onClick={async () => {
          await detachPOSFromProducts({
            shelfId,
            pointOfSaleId: posAdvice.id,
          })
          await refresh(shelfId)
          handleClose()
        }}
      >
        Confirm
      </FilledButton>
      <FilledButton
        color="dark"
        data-control="modal-cancel-delete-shelf-pos"
        onClick={handleClose}
        className="ln-u-push-left"
      >
        Cancel
      </FilledButton>
    </div>
  </div>
)

DeleteDialog.propTypes = {
  posAdvice: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  detachPOSFromProducts: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  shelfId: PropTypes.string.isRequired,
}

export default DeleteDialog
