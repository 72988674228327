import Moment from 'moment'
import { extendMoment } from 'moment-range/dist/moment-range'
import { toDate, fromDate, QUARTERS_IN_YEAR, PERIODS_IN_YEAR } from 'sainsburys-date'

import { CHANGE_CONTROL_LOCKED_ID } from 'src/constants/cycles'

import { addOneDayToDate } from '../day'

const moment = extendMoment(Moment)

const buildQuarterCells = (start, end) => {
  const dates = []
  let date = start
  let { year, quarter } = fromDate(start)

  while (date <= end) {
    date = toDate({ year, quarter })
    if (dates.length) {
      dates[dates.length - 1].end = date
    }
    dates.push({
      id: `q${quarter}-${year}`,
      title: `Q${quarter} ${year}`,
      start: date,
    })
    quarter += 1
    if (quarter > QUARTERS_IN_YEAR) {
      quarter = 1
      year += 1
    }
  }

  return dates.slice(0, -1)
}

const buildPeriodCells = (start, end) => {
  const dates = []
  let date = start
  let { year, period } = fromDate(start)

  while (date <= end) {
    date = toDate({ year, period })
    if (dates.length) {
      dates[dates.length - 1].end = date
    }
    dates.push({
      id: `p${period}-${year}`,
      title: `P${period}`,
      start: date,
    })
    period += 1
    if (period > PERIODS_IN_YEAR) {
      period = 1
      year += 1
    }
  }

  return dates.slice(0, -1)
}

const pad = num => (num <= 9 ? `0${num}` : num)

const buildDateCells = (start, end) => {
  const dates = []
  let date = start
  let month = date.getMonth()
  let year = date.getFullYear()
  while (date <= end) {
    date = new Date(`${year}-${pad(month + 1)}-01`)
    month += 1
    if (month >= 12) {
      year += 1
      month = 0
    }
    if (dates.length) {
      dates[dates.length - 1].end = date
    }
    dates.push({
      id: `m${year}-${pad(month)}`,
      title: moment(date).format("MMM 'YY"),
      start: date,
    })
  }
  return dates.slice(0, -1)
}
const rangeWeeks = (start, end, day) => {
  const startDate = moment(start).day(day - 7)
  const endDate = moment(end).day(day)
  return Array.from(moment.range(startDate, endDate).by('week'))
}

const buildWeekCells = (start, end, day = 0) => {
  const weeks = rangeWeeks(start, end, day)

  return weeks.map(week => ({
    id: `w${week.format('YYYY-ww')}`,
    title: week.format('D MMM'),
    start: week.toDate(),
    end: week.add(1, 'week').toDate(),
  }))
}
const buildWeekInPeriodCells = (start, end, day = 0) => {
  const weeks = rangeWeeks(start, end, day)

  return weeks.map(weekDate => ({
    id: `wip-${weekDate.format('YYYY-ww')}-${fromDate(weekDate).week}`,
    title: `WIP${fromDate(weekDate).week}`,
    start: weekDate.toDate(),
    end: weekDate.add(1, 'week').toDate(),
  }))
}

const buildWeekInYearCells = (start, end, day = 0) => {
  const weeks = rangeWeeks(start, end, day)

  return weeks.map(week => ({
    id: `wiy-${week.format('YYYY-ww')}`,
    title: `WIY${week.format('w')}`,
    start: week.toDate(),
    end: week.add(1, 'week').toDate(),
  }))
}

const buildCycleCells = cycles =>
  cycles.map(cycle => ({
    id: `c${cycle.id}`,
    title:
      cycle.changeControlStatus === CHANGE_CONTROL_LOCKED_ID
        ? `${cycle.title} (Locked)`
        : cycle.title,
    start: new Date(cycle.startDate),
    end: addOneDayToDate(cycle.endDate),
  }))

const buildTimebar = ({ start, end }) => {
  const quarterCells = buildQuarterCells(start, end)
  const periodCells = buildPeriodCells(start, end)
  const dateCells = buildDateCells(start, end)

  return [
    {
      id: 'quarters',
      title: 'Quarter',
      cells: quarterCells,
    },
    {
      id: 'periods',
      title: 'Period',
      cells: periodCells,
    },
    {
      id: 'months',
      title: 'Month',
      useAsGrid: true,
      cells: dateCells,
    },
  ]
}

export {
  buildTimebar,
  buildPeriodCells,
  buildWeekCells,
  buildWeekInPeriodCells,
  buildWeekInYearCells,
  buildCycleCells,
}
