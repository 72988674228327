import { NOMINATIONS_TYPE, SPACE_PLANS_TYPE } from 'src/constants/types'

const nameBuilders = {
  [NOMINATIONS_TYPE]: id => `nomination/clones/Nomination ${id}`,
  [SPACE_PLANS_TYPE]: id => `space-plan/clones/Space Plan ${id}`,
}

const sites = {
  local: 'promcommprivatelocal',
  dev: 'promcommdev',
  stg: 'promcommprivatestage',
  prd: 'promcommprivate',
}

const buildUri = ({ id, type, environment, createdAt } = {}) => {
  const nameBuilder = nameBuilders[type]
  const site = sites[environment]

  if (!nameBuilder || !id || !site) {
    return null
  }

  const path = `ms-excel:ofe|ofc|u|https://jsainsbury.sharepoint.com/sites/${site}/Shared Documents`
  const fileName = nameBuilder(id)

  const cutoffDate = new Date('2022-06-08T00:00:00')
  const createdDateTime = new Date(createdAt)

  let filePath = ''
  if (createdDateTime >= cutoffDate) {
    filePath = `${path}/${fileName}.xlsm`
  } else {
    filePath = `${path}/${fileName}.xlsx`
  }

  return filePath
}

export default buildUri
