import { compose } from 'redux'
import { connect } from 'react-redux'
import createAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect'
import { routerActions } from 'react-router-redux'

import { concatenatePaths } from 'src/utils/paths'

import { selectToken, selectSelf } from 'src/store/data/selectors'
import { isAllowedRoute } from 'src/utils/routing'

import Failure from 'src/modules/Failure'

const authorized = compose(
  createAuthWrapper({
    authenticatedSelector: (state, { routes = [] }) => {
      const user = selectSelf(state)
      const path = concatenatePaths(routes.map(r => r.path))

      const isAssignedRole = !!user && user.role
      const isAuthorized = isAllowedRoute(user, path)

      return isAssignedRole && isAuthorized
    },
    authenticatingSelector: state => !selectSelf(state),
    FailureComponent: Failure,
  }),
  connect(state => ({ authData: { user: selectSelf(state) } }))
)

const authorizedStoreColleague = compose(
  createAuthWrapper({
    authenticatedSelector: state => !!selectToken(state),
    FailureComponent: Failure,
  }),
  connect(state => ({ authData: { token: selectToken(state) } }))
)

const loggedIn = connectedReduxRedirect({
  authenticatedSelector: state => !!selectToken(state),
  redirectPath: '/login',
  redirectAction: routerActions.replace,
})

const loggedInStoreColleague = connectedReduxRedirect({
  authenticatedSelector: state => !!selectToken(state),
  redirectPath: '/login-store-colleague',
  redirectAction: routerActions.replace,
})

export { authorized, authorizedStoreColleague, loggedIn, loggedInStoreColleague }
