import {
  UPDATE_SHELVES_PRODUCTS_CATEGORY,
  UPDATE_SHELVES_PRODUCTS_PROMO_MECHANIC,
  UPDATE_SHELVES_PRODUCTS_COMMENT,
  UPDATE_SHELVES_PRODUCTS_PRICE_POINT,
} from 'src/constants/permissions'

import { hasRequiredPermission } from 'src/utils/permissions'

const buildDisabled = ({ user }) => ({
  category: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_CATEGORY),
  promoMechanic: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_PROMO_MECHANIC),
  promoMechanicValue: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_PROMO_MECHANIC),
  comment: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_COMMENT),
  pricePoint: !hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_PRICE_POINT),
})

export default buildDisabled
