import Api from 'src/api/Base'

class GetLatestCycleByGroup extends Api {
  requestUrl = this.getParams().groupId
    ? `/api/Cycle/latest?businessUnit=${this.getParams().businessUnit}&groupId=${
        this.getParams().groupId
      }`
    : `/api/Cycle/latest?businessUnit=${this.getParams().businessUnit}`
}
export default GetLatestCycleByGroup
