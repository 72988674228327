import { columns } from './columns'

const validateProduct = async (
  product,
  {
    lookupBusinessUnit,
    lookupCategory,
    lookupSku,
    lookupPromoMechanic,
    spaceInstance = {},
    isLockedForChangeControl,
  }
) => {
  const results = await Promise.all(
    columns.map(column =>
      column.parse(product, {
        ...column,
        lookupBusinessUnit,
        lookupCategory,
        lookupSku,
        lookupPromoMechanic,
        spaceInstance,
        isLockedForChangeControl,
      })
    )
  )

  const output = results.reduce(
    (out, result = {}) => ({
      ...out,
      ...result,
      errors: [...out.errors, ...(result.errors || [])],
    }),
    {
      errors: [],
    }
  )

  output.valid = output.errors.length === 0

  return {
    ...product,
    ...output,
  }
}

export default validateProduct
