import React from 'react'
import PropTypes from 'prop-types'
import { FilledButton, Heading3 } from '@jsluna/react'

import withModal from 'src/hoc/withModal'

const DeleteDialog = ({ title, confirm, cancel }) => (
  <div>
    <Heading3>{title}</Heading3>
    <p>Please confirm this action</p>
    <div>
      <FilledButton data-control="modal-confirm" onClick={confirm}>
        Confirm
      </FilledButton>
      <FilledButton
        color="dark"
        data-control="modal-cancel"
        onClick={cancel}
        className="ln-u-push-left"
      >
        Cancel
      </FilledButton>
    </div>
  </div>
)

DeleteDialog.propTypes = {
  title: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}

export default withModal(DeleteDialog)
