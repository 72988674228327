import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  clearDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

import GetRoles from 'src/api/Role'

const STORE_PATH = `modules`
const NAMESPACE = 'Roles'
const buildThunkPrefix = (slice, thunkName) => `${STORE_PATH}/${slice}/${thunkName}`

const selectSelf = state => state.modules[NAMESPACE]
const selectAllRoles = createSelector(selectSelf, module => module.data)
const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const fetchRoles = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchRoles'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getRolesInstance = new GetRoles(store)

    const response = await handleCreateAsyncThunkResult(getRolesInstance, dispatch, rejectWithValue)
    return response
  }
)

const rolesSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchRoles.pending, pendingDataHandler)
    builder.addCase(fetchRoles.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchRoles.rejected, rejectedDataHandler)
    builder.addCase(actionClearData, clearDataHandler)
  },
})

export default { [NAMESPACE]: rolesSlice.reducer }
export { fetchRoles, selectAllRoles }
