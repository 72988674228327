import React from 'react'
import { Provider } from 'react-redux'
import { render } from 'react-dom'

import 'src/scss/main.scss'
import 'src/assets/images/favicon.ico'

import { EventType } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { push } from 'react-router-redux'
import msalInstance from 'src/msalAuthentication'
import createStore from './store'
import createRouter from './router'

const mountElement = document.getElementById('root')

const getAttr = (name, def = undefined) => mountElement && (mountElement.getAttribute(name) || def)

const config = {
  environment: getAttr('data-env'),
  version: getAttr('data-ver'),
}

const store = createStore({ config })

const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback(
  event => {
    // set active account after redirect
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const { account } = event.payload
      msalInstance.setActiveAccount(account)
    }
  },
  () => {
    store.dispatch(push('/unauthorised'))
  }
)

msalInstance
  .handleRedirectPromise()
  .then(() => {
    // Check if user signed in
    const account = msalInstance.getActiveAccount()
    if (!account) {
      // redirect anonymous user to login page
      msalInstance.loginRedirect()
    }
  })
  .catch(() => {
    store.dispatch(push('/unauthorised'))
  })

const App = () => {
  return <Provider store={store}>{createRouter(store)}</Provider>
}

render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>,
  mountElement
)

export default App
