import getFinancialYear from 'src/utils/date'
import { getDisplayText, getDuration, getDurationText } from 'src/utils/datePeriod'

import changeControlStatuses from './changeControlStatuses'
import shipperLockStatuses from './shipperLockStatuses'
import statuses from './statuses'

const getLink = cycle => `/cycles/${cycle.businessUnit}/${cycle.id}`
const getStatus = ({ status }) => status && statuses.get(status).title
const getChangeControlStatus = ({ changeControlStatus }) =>
  changeControlStatus && changeControlStatuses.get(changeControlStatus).title
const getShipperLockStatus = ({ shipperLockStatus }) =>
  shipperLockStatus && shipperLockStatuses.get(shipperLockStatus).title
const getYear = ({ startDate }) => getFinancialYear({ date: startDate })
const getGroupName = ({ groupName }) => groupName

const columns = [
  { key: 'title', name: 'Title', value: 'title', link: getLink },
  { key: 'groupName', name: 'Group Name', value: getGroupName },
  { key: 'dates', name: 'Dates', value: getDisplayText, sortValue: 'endDate' },
  { key: 'year', name: 'Year', value: getYear },
  { key: 'duration', name: 'Duration', value: getDurationText, sortValue: getDuration },
  { key: 'status', name: 'Space Plan Status', value: getStatus },
  { key: 'changeControlStatus', name: 'Change Control Status', value: getChangeControlStatus },
  { key: 'shipperLockStatus', name: 'Shipper Lock Status', value: getShipperLockStatus },
]

export default columns
