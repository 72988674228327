import React from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray, withFormik } from 'formik'

import { Form, FormGroup, GridWrapper, GridItem } from '@jsluna/react'

import { RANGE_AND_SPACE_MESSAGE_LABELS } from 'src/constants/messages'

import { TextInputField } from '@jsluna/form/extensions/formik'

import { Controls } from 'src/components/Form'
import { Checkbox } from 'src/components/FormikComponents'

import handleSubmit from './handleSubmit'

const FormComponent = ({ handleSubmit: onSubmit, values: { messages } }) => {
  return (
    <Form onSubmit={onSubmit}>
      <FormGroup name="messages-fields">
        <FieldArray
          name="messages"
          render={() => (
            <>
              {messages.map(({ name }, index) => (
                <GridWrapper matrix key={name}>
                  <GridItem size="1/2">
                    <Field
                      component={TextInputField}
                      data-control={`messages-text-input-${index}`}
                      label={RANGE_AND_SPACE_MESSAGE_LABELS[name]}
                      name={`messages[${index}].value`}
                      required
                    />
                  </GridItem>
                  <GridItem size="1/2" className="ln-u-padding-top*3">
                    <Field
                      component={Checkbox}
                      data-control={`messages-checkbox-${index}`}
                      label="Enable Message"
                      name={`messages[${index}].enabled`}
                    />
                  </GridItem>
                </GridWrapper>
              ))}
            </>
          )}
        />
      </FormGroup>
      <Controls submitText="Submit" />
    </Form>
  )
}

FormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        enabled: PropTypes.bool,
        id: PropTypes.string,
        type: PropTypes.string,
      })
    ),
  }).isRequired,
}

const wrappedForm = withFormik({ handleSubmit })(FormComponent)

export default wrappedForm
