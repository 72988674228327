import { connect } from 'react-redux'
import { withFormik } from 'formik'
import { selectSelf } from 'src/store/data/selectors'
import {
  selectSpaceAssignmentWithSpaceInstances,
  selectSpaceInstanceClustersByShelfId,
} from 'src/modules/SpaceAssignment/store/selectors'
import { SHELF_FILL_TYPE_DEFAULT } from 'src/constants/shelfProductDetails'
import { selectShelfProduct } from '../store'
import Form from './Form'
import validate from './validate'
import handleSubmit from './handleSubmit'
import buildDisabled from './disabled'
import { selectPromoMechanics } from '../../SpaceAssignment/store/reducer/promoMechanics'

export const mapStateToProps = (
  state,
  { handleClose, id, categories, availableClusters, weeks = [], shelf }
) => {
  const promoMechanics = selectPromoMechanics(state)
  const user = selectSelf(state)
  const isNew = !id

  const { ...shelfProduct } = isNew
    ? {
        category: '',
        clusters: [],
        comment: '',
        nppInStoreDate: null,
        nppRequired: false,
        overrideStartDate: null,
        overrideEndDate: null,
        promoMechanic: '',
        promoMechanicValue: '',
        salesPhaseWeeks: [...Array(weeks.length)].map(() => null),
        shelfFillType: SHELF_FILL_TYPE_DEFAULT,
        specialBuy: false,
        stockInShipperFdsu: false,
        storeComment: '',
        wigig: false,
      }
    : selectShelfProduct(state, id)

  const { space } = selectSpaceAssignmentWithSpaceInstances(state)
  const productId = shelfProduct.product && shelfProduct.product.id
  const product = productId ? shelfProduct.product : {}

  const oldProductId = shelfProduct.oldProduct && shelfProduct.oldProduct.id
  const oldProduct = oldProductId ? shelfProduct.oldProduct : {}

  const categoryId = (shelfProduct.category && shelfProduct.category.id) || null
  let category = ''
  if (categoryId) {
    category = categoryId

    if (!categories.find(c => c.id === categoryId)) {
      categories.push(shelfProduct.category)
    }
  }

  if (!categoryId && categories.length === 1) {
    category = categories[0].id
  }
  const initialPromoMechanic = (shelfProduct.promoMechanicId && shelfProduct.promoMechanicId) || ''
  return {
    shelf: shelfProduct.shelf || shelf,
    promoMechanics,
    categories,
    availableClusters:
      availableClusters || selectSpaceInstanceClustersByShelfId(state, shelfProduct.shelfId),
    user,
    disabled: buildDisabled({ user }),
    shelfProduct,
    product,
    oldProduct,
    category,
    cancel: handleClose,
    weeks,
    space,
    initialPromoMechanic,
  }
}

export const mapPropsToValues = props => {
  const { shelfProduct, product, oldProduct, category, weeks, space } = props

  const promoMechanic = (shelfProduct.promoMechanicId && shelfProduct.promoMechanicId) || ''
  const clusters = (shelfProduct.clusterIds && [...shelfProduct.clusterIds]) || []
  const salesPhaseWeeks =
    typeof shelfProduct.salesPhaseWeeks === 'string'
      ? shelfProduct.salesPhaseWeeks?.split(',').map(Number)
      : shelfProduct.salesPhaseWeeks || [...Array(weeks.length)].map(() => null)

  return {
    shelfFillType: SHELF_FILL_TYPE_DEFAULT,
    ...shelfProduct,
    salesPhaseWeeks,
    product,
    oldProduct,
    promoMechanic,
    clusters,
    category,
    space,
    wigig: Boolean(shelfProduct.wigig),
    specialBuy: Boolean(shelfProduct.specialBuy),
  }
}

const wrappedForm = withFormik({ mapPropsToValues, handleSubmit, validate })(Form)

export default connect(mapStateToProps)(wrappedForm)
