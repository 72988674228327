import Api from '../Base'

class ApproveSpacePlanById extends Api {
  requestUrl = `/api/SpacePlan/${this.getParams().id}/Approve`

  method = 'PATCH'

  formatRequestData = params => params.spacePlan
}

export default ApproveSpacePlanById
