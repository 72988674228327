import { addOneDayToYMD } from 'src/utils/day'
import sortArray from 'src/utils/sortArray'

function divideGaps({ gaps, ranges }) {
  const dates = []

  if (ranges.length === 0) {
    return gaps
  }

  const sortedGaps = sortArray(gaps, 'startDate')
  const sortedRanges = sortArray(ranges, 'startDate')

  sortedGaps.forEach(gap => {
    let { startDate } = gap

    sortedRanges.forEach(range => {
      if (startDate >= range.startDate && startDate <= range.endDate) {
        if (gap.endDate <= range.endDate) {
          dates.push({ startDate, endDate: gap.endDate })
        } else {
          dates.push({ startDate, endDate: range.endDate })
          startDate = addOneDayToYMD(range.endDate)
        }
      }
    })
  })

  return dates
}

export default divideGaps
