import { buildReducer, bindSelector } from 'src/store/modules'
import { actionClearDataHandler } from 'src/utils/createAsyncThunkHandler'
import spaceInstancesReducer from 'src/modules/SpaceAssignment/store/reducer/spaceInstances'
import spacePlanAssignmentReducer from 'src/modules/SpaceAssignment/store/reducer/spacePlanAssignment'
import changeControlReasonsReducer from 'src/modules/SpaceAssignment/store/reducer/changeControlReasons'
import promoMechanicsReducer from './reducer/promoMechanics'
import changeControlReducer from '../ChangeControl/store'

import modalReducer from './modal'
import uiReducer from './reducer'

const NAMESPACE = 'SpaceAssignment'

const selectUi = bindSelector(NAMESPACE)(state => state.ui)

const actionClearData = actionClearDataHandler(NAMESPACE)

export default {
  ...buildReducer(NAMESPACE, {
    ui: uiReducer,
    [changeControlReducer.name]: changeControlReducer.reducer,
  }),
  ...modalReducer,
  ...spacePlanAssignmentReducer,
  ...changeControlReasonsReducer,
  ...spaceInstancesReducer,
  ...promoMechanicsReducer,
}

export { selectUi, actionClearData }
