export const FOOD_RANGE_AND_SPACE_MESSAGE_CURRENT = 'food-range-and-space-current'
export const FOOD_RANGE_AND_SPACE_MESSAGE_PREVIOUS = 'food-range-and-space-previous'
export const FOOD_RANGE_AND_SPACE_MESSAGE_UPCOMING = 'food-range-and-space-upcoming'
export const FOOD_RANGE_AND_SPACE_MESSAGE_PREVIEW = 'food-range-and-space-preview'
export const NON_FOOD_RANGE_AND_SPACE_MESSAGE_CURRENT = 'gm-range-and-space-current'
export const NON_FOOD_RANGE_AND_SPACE_MESSAGE_PREVIOUS = 'gm-range-and-space-previous'
export const NON_FOOD_RANGE_AND_SPACE_MESSAGE_UPCOMING = 'gm-range-and-space-upcoming'
export const NON_FOOD_RANGE_AND_SPACE_MESSAGE_PREVIEW = 'gm-range-and-space-preview'

export const RANGE_AND_SPACE_MESSAGE_LABELS = {
  [FOOD_RANGE_AND_SPACE_MESSAGE_CURRENT]: 'Food Current SSPSA availability message',
  [FOOD_RANGE_AND_SPACE_MESSAGE_PREVIOUS]: 'Food Previous SSPSA availability message',
  [FOOD_RANGE_AND_SPACE_MESSAGE_UPCOMING]: 'Food Upcoming SSPSA availability message',
  [FOOD_RANGE_AND_SPACE_MESSAGE_PREVIEW]: 'Food Preview SSPSA availability message',
  [NON_FOOD_RANGE_AND_SPACE_MESSAGE_CURRENT]: 'GM Current SSPSA availability message',
  [NON_FOOD_RANGE_AND_SPACE_MESSAGE_PREVIOUS]: 'GM Previous SSPSA availability message',
  [NON_FOOD_RANGE_AND_SPACE_MESSAGE_UPCOMING]: 'GM Upcoming SSPSA availability message',
  [NON_FOOD_RANGE_AND_SPACE_MESSAGE_PREVIEW]: 'GM Preview SSPSA availability message',
}
