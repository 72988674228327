import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { SHELF_DIVIDER } from 'src/constants/shelfArtifacts'
import { getLayoutTypeLabel, isShipper } from 'src/utils/layoutTypes'
import { hasPendingChange } from 'src/utils/changeControls'
import { hasRequiredPermission } from 'src/utils/permissions'

import {
  ADD_SHELVES,
  DELETE_SHELVES,
  UPDATE_SHELVES_CATEGORIES,
  UPDATE_SHELVES_ORDER,
} from 'src/constants/permissions'

import SplitButton from 'src/components/SplitButton'

import ShelfCategories from '../ShelfCategories'
import ShelfPos from '../ShelfPos'
import ShelfDetails from '../ShelfDetails'
import DeleteDialog from './DeleteDialog'
import ShelfProductsEditor from '../ShelfProductsEditor'

class ShelfControls extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddPos: false,
      showCategories: false,
      showDeleteShelf: false,
      showDeleteAllProducts: false,
      showAddProduct: false,
      showDetails: false,
    }
  }

  render() {
    const {
      addShelfArtifact,
      assignmentStartDate,
      assignmentEndDate,
      availableCategories,
      canAddDeleteArtifact,
      canAddProduct,
      canDeleteAllProducts,
      clusters,
      deleteProducts,
      deleteShelf,
      disableAddProduct,
      disableDeleteAllProducts,
      isLockedForChangeControl,
      moveShelfUp,
      moveShelfDown,
      minShelves,
      pendingChangeControl,
      position,
      refreshSpaceInstances,
      shelf,
      shelves,
      layoutType,
      updateShelf,
      user,
      weeks,
      canEditPOSAdvice,
    } = this.props
    const {
      showAddPos,
      showCategories,
      showDeleteShelf,
      showDeleteAllProducts,
      showDetails,
      showAddProduct,
    } = this.state

    const options = []

    if (hasRequiredPermission(user, UPDATE_SHELVES_CATEGORIES)) {
      options.push({
        label: 'Edit Categories',
        onClick: () => this.setState({ showCategories: true }),
        'data-control': 'shelf-categories-edit',
      })
    }

    if (hasRequiredPermission(user, ADD_SHELVES) && !isShipper(layoutType)) {
      options.push({
        label: `Edit ${getLayoutTypeLabel(layoutType)}`,
        onClick: () => this.setState({ showDetails: true }),
        'data-control': 'shelf-details-edit',
      })
    }

    if (hasRequiredPermission(user, UPDATE_SHELVES_ORDER)) {
      options.push({
        label: 'Move Up',
        onClick: () => moveShelfUp(shelf, position),
        'data-control': 'move-shelf-up',
        disabled: position === 0 || isLockedForChangeControl,
      })
      options.push({
        label: 'Move Down',
        onClick: () => moveShelfDown(shelf, position),
        'data-control': 'move-shelf-down',
        disabled: position === shelves.length - 1 || isLockedForChangeControl,
      })
    }

    if (canAddProduct) {
      options.push({
        label: 'Add Product',
        onClick: () => this.setState(() => ({ showAddProduct: true })),
        'data-control': 'shelf-products-add',
        disabled: disableAddProduct,
      })
    }

    if (canAddDeleteArtifact) {
      options.push({
        label: 'Add Divider',
        onClick: () => addShelfArtifact(shelf, SHELF_DIVIDER),
        'data-control': 'shelf-divider-add',
      })

      options.push({
        label: 'Add POS Comment',
        onClick: () => this.setState({ showAddPos: true }),
        'data-control': 'shelf-pos-add',
      })
    }

    const goToEditPOSPage = () => {
      window.location.href = `/space-assignments/${shelf.spaceAssignmentId}/shelf/${shelf?.id}/pos?rt=${window.location.pathname}`
    }

    if (shelf.shelfProducts.length > 0 && canEditPOSAdvice) {
      options.push({
        label: `Edit POS Advice`,
        onClick: goToEditPOSPage,
        link: `/space-assignments/${shelf.spaceAssignmentId}/shelf/${shelf?.id}/pos?rt=${window.location.pathname}`,
        'data-control': 'shelf-product-pos-edit',
      })
    }

    if (shelf.shelfProducts.length > 0 && canDeleteAllProducts) {
      if (options.length > 1) {
        options.push({
          type: 'divider',
        })
      }

      options.push({
        label: 'Delete All Products',
        onClick: () => this.setState({ showDeleteAllProducts: true }),
        'data-control': 'delete-shelf-products',
        className: 'ln-u-color-red',
        disabled: disableDeleteAllProducts,
      })
    }

    if (hasRequiredPermission(user, DELETE_SHELVES)) {
      options.push({
        label: `Delete ${getLayoutTypeLabel(layoutType)}`,
        onClick: () => this.setState({ showDeleteShelf: true }),
        'data-control': 'delete-shelf',
        className: 'ln-u-color-red',
        disabled:
          shelves.length <= minShelves || shelf.shelfProducts.length > 0 || !!pendingChangeControl,
      })
    }

    if (options.length === 0) {
      return null
    }

    return (
      <>
        <SplitButton options={options} data-control="shelf-controls" />

        <ShelfCategories
          open={showCategories}
          onClose={() => this.setState({ showCategories: false })}
          shelf={shelf}
          updateShelf={updateShelf}
        />
        <ShelfDetails
          open={showDetails}
          onClose={() => this.setState({ showDetails: false })}
          shelf={shelf}
          updateShelf={updateShelf}
          layoutType={layoutType}
        />
        <ShelfPos
          open={showAddPos}
          onClose={() => this.setState({ showAddPos: false })}
          shelf={shelf}
          addShelfArtifact={addShelfArtifact}
        />

        <DeleteDialog
          confirm={() => deleteShelf(shelf)}
          cancel={() => this.setState({ showDeleteShelf: false })}
          onClose={() => this.setState({ showDeleteShelf: false })}
          open={showDeleteShelf}
          title="Are you sure you want to delete this shelf?"
        />

        <DeleteDialog
          confirm={async () => {
            const productsToDelete = shelf.shelfProducts.filter(
              product => !hasPendingChange(pendingChangeControl)(product)
            )

            if (productsToDelete.length > 0) {
              await deleteProducts(productsToDelete, shelf.spaceAssignmentId)
            }

            this.setState({ showDeleteAllProducts: false })
          }}
          cancel={() => this.setState({ showDeleteAllProducts: false })}
          onClose={() => this.setState({ showDeleteAllProducts: false })}
          open={showDeleteAllProducts}
          title="Are you sure you want to delete these products?"
        />

        <div data-control="shelf-products-edit">
          <ShelfProductsEditor
            assignmentStartDate={assignmentStartDate}
            assignmentEndDate={assignmentEndDate}
            availableClusters={clusters}
            cancel={() => this.setState({ showAddProduct: false })}
            categories={availableCategories}
            isLockedForChangeControl={isLockedForChangeControl}
            onClose={() => this.setState({ showAddProduct: false })}
            open={showAddProduct}
            onUpdate={() => refreshSpaceInstances(shelf.spaceAssignmentId)}
            pendingChangeControl={pendingChangeControl}
            shelfId={shelf.id}
            weeks={weeks}
            shelf={shelf}
          />
        </div>
      </>
    )
  }
}

ShelfControls.propTypes = {
  addShelfArtifact: PropTypes.func.isRequired,
  assignmentStartDate: PropTypes.string,
  assignmentEndDate: PropTypes.string,
  availableCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  canAddDeleteArtifact: PropTypes.bool.isRequired,
  canAddProduct: PropTypes.bool.isRequired,
  canEditPOSAdvice: PropTypes.bool.isRequired,
  canDeleteAllProducts: PropTypes.bool.isRequired,
  clusters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  deleteProducts: PropTypes.func.isRequired,
  deleteShelf: PropTypes.func.isRequired,
  disableAddProduct: PropTypes.bool.isRequired,
  disableDeleteAllProducts: PropTypes.bool.isRequired,
  isLockedForChangeControl: PropTypes.bool.isRequired,
  layoutType: PropTypes.string.isRequired,
  moveShelfUp: PropTypes.func.isRequired,
  moveShelfDown: PropTypes.func.isRequired,
  minShelves: PropTypes.number.isRequired,
  pendingChangeControl: PropTypes.shape({}),
  position: PropTypes.number.isRequired,
  refreshSpaceInstances: PropTypes.func.isRequired,
  shelf: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    shelfProducts: PropTypes.arrayOf(PropTypes.shape({})),
    spaceAssignmentId: PropTypes.string.isRequired,
    id: PropTypes.string,
  }).isRequired,
  shelves: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateShelf: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.shape({ role: PropTypes.string }),
    categories: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
  }).isRequired,
  weeks: PropTypes.arrayOf(PropTypes.number).isRequired,
}

ShelfControls.defaultProps = {
  assignmentStartDate: null,
  assignmentEndDate: null,
  pendingChangeControl: null,
}

export default ShelfControls
