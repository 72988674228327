/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import PropTypes from 'prop-types'

const FlyoutBackground = ({ onClick }) => (
  <button
    className="c-flyout-background"
    data-control="flyout-background"
    onClick={onClick}
    type="button"
  />
)

FlyoutBackground.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default FlyoutBackground
