import moment from 'moment'

import { CHANGE_CONTROL_UNLOCKED_ID } from 'src/constants/cycles'
import { SHIPPER_LOCK_UNLOCKED_ID } from 'src/constants/shipperCompliance'

import { DATE_FORMAT_DEFAULT } from 'src/utils/dateRange'
import { addOneDayToYMD } from 'src/utils/day'
import { getDuration } from 'src/utils/datePeriod'
import { hasRequiredPermission } from 'src/utils/permissions'
import statuses, { UNLOCKED_ID, LOCKED_ID } from 'src/modules/Cycles/statuses'
import { UPDATE_CYCLES } from 'src/constants/permissions'

import { selectCycleInfo, selectPreviousCycleInfo, selectGroupTitleList } from '../../store'

const getStartDate = (previous = {}) =>
  previous && previous.endDate && addOneDayToYMD(previous.endDate)

const getMaxDate = (next, startDate) => {
  if (next && next.endDate) {
    return moment(next.endDate).subtract(1, 'days').format(DATE_FORMAT_DEFAULT)
  }

  return moment(startDate).add(6, 'weeks').format(DATE_FORMAT_DEFAULT)
}

const initialValuesForUpdate = state => {
  const cycle = selectCycleInfo(state)
  const { startDate, endDate } = cycle
  return {
    startDate,
    endDate,
    next: cycle.nextCycle,
    previous: cycle.previousCycle,
    title: cycle.title,
    spacePlanStatus: cycle.status,
    changeControlStatus: cycle.changeControlStatus,
    shipperLockStatus: cycle.shipperLockStatus,
    group: cycle.group,
  }
}

const initialValuesForCreate = state => {
  const previousCycleInfo = selectPreviousCycleInfo(state)
  const previous = previousCycleInfo?.data
  const isPreviousCycleLoading = previousCycleInfo?.isFetching
  const previousCycleFetchError = previousCycleInfo?.error
  const spacePlanStartDate = previous?.spacePlan?.startDate
  const startDate =
    getStartDate(previous) || spacePlanStartDate || moment().format(DATE_FORMAT_DEFAULT)
  return {
    title: '',
    groupId: '',
    previous,
    startDate,
    spacePlanStatus: UNLOCKED_ID,
    changeControlStatus: CHANGE_CONTROL_UNLOCKED_ID,
    shipperLockStatus: SHIPPER_LOCK_UNLOCKED_ID,
    isPreviousCycleLoading,
    previousCycleFetchError,
    spacePlanStartDate,
    spacePlanEndDate: previous?.spacePlan?.endDate,
  }
}

const mapStateToProps = (
  state,
  { id, user, businessUnit, startDate: propsStartDate, endDate: propsEndDate }
) => {
  const {
    next,
    previous,
    title,
    startDate: initialStartDate,
    endDate: initialEndDate,
    spacePlanStatus: status,
    changeControlStatus,
    shipperLockStatus,
    groupId,
    isPreviousCycleLoading,
    group,
    previousCycleFetchError,
    spacePlanStartDate,
    spacePlanEndDate,
  } = id ? initialValuesForUpdate(state, id) : initialValuesForCreate(state, businessUnit)

  const startDate = propsStartDate || initialStartDate
  const endDate = propsEndDate || initialEndDate
  const maxEndDate = getMaxDate(next, startDate)
  const duration = getDuration({ endDate, startDate })
  const isLocked = status === LOCKED_ID
  const isShipperLocked = shipperLockStatus === LOCKED_ID
  const isReadOnly = !hasRequiredPermission(user, UPDATE_CYCLES)
  const groupList = selectGroupTitleList(state)

  const props = {
    businessUnit,
    duration,
    next,
    previous,
    spacePlanStatus: statuses.get(status || UNLOCKED_ID).title,
    title,
    startDate: moment(startDate).format(DATE_FORMAT_DEFAULT),
    maxStartDate: endDate && moment(endDate).format(DATE_FORMAT_DEFAULT),
    endDate: id ? moment(endDate).format(DATE_FORMAT_DEFAULT) : null,
    maxEndDate,
    isLocked,
    isShipperLocked,
    isReadOnly,
    changeControlStatus,
    shipperLockStatus,
    groupId,
    group,
    groupList,
    isPreviousCycleLoading,
    previousCycleFetchError,
    spacePlanStartDate,
    spacePlanEndDate,
  }

  return props
}

export default mapStateToProps
