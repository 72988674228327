const handleSubmit = async (
  { title, refCode, spaceType, spaceGroup, businessUnit, storeDistribution, isDefunct, isHfss },
  { props: { afterSubmit, id, submit } }
) => {
  const space = {
    title,
    refCode,
    spaceTypeId: spaceType,
    spaceGroupId: spaceGroup,
    businessUnit,
    storeDistribution,
    isDefunct,
    isHfss,
  }

  await submit(id, space)
  afterSubmit()
}

export default handleSubmit
