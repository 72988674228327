import React from 'react'
import PropTypes from 'prop-types'

import loader from 'src/hoc/loader'

import SearchBox from './SearchBox'
import { actionClearData, fetchStores } from './store'

const StoreSearch = ({ route, useStoreId }) => {
  const input = {
    name: 'store',
    label: 'Store search',
    hideLabel: true,
    value: '',
    placeholder: 'Search by store number or name',
  }

  return <SearchBox input={input} route={route} useStoreId={useStoreId} />
}

StoreSearch.propTypes = {
  route: PropTypes.string.isRequired,
  useStoreId: PropTypes.bool,
}

StoreSearch.defaultProps = {
  useStoreId: false,
}

const getData = async (props, dispatch) => {
  dispatch(actionClearData())
  await dispatch(fetchStores())
}

const options = {
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(StoreSearch)
