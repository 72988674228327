import { SET_TOKEN } from 'src/constants/api'

const initialState = null

const reducer = (state = initialState, { type, payload } = {}) => {
  if (type === SET_TOKEN) {
    return payload
  }
  return state
}

export default reducer
