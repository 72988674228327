import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Form, FormGroup, GridWrapper, GridItem } from '@jsluna/react'
import { SelectField } from '@jsluna/form/extensions/formik'
import { Field, withFormik } from 'formik'
import moment from 'moment'
import { Controls } from 'src/components/Form'
import { DateRangePicker } from 'src/components/FormikComponents'
import sortNatural from 'src/utils/sortNatural'
import { getDisplayText } from 'src/utils/datePeriod'
import LoadingCard from 'src/components/LoadingCard'
import { DATE_FORMAT_DEFAULT } from 'src/utils/dateRange'
import RichTextBox from './RichTextBox'

let commentData
let cycleIdData
let startDateData
let endDateData

export const handleSubmit = async (
  values,
  { props: { generateSspsaByDate, showError, businessUnit } }
) => {
  const {
    cycleId = cycleIdData,
    inputStartDate = startDateData,
    endDate = endDateData,
    comment = commentData,
  } = values

  if (!businessUnit) {
    showError('Please select a business unit')
    return
  }

  if (!cycleId) {
    showError('Please select a cycle')
    return
  }

  if (inputStartDate > endDate) {
    showError('Start date must be before end date')
    return
  }

  await generateSspsaByDate(businessUnit, comment, inputStartDate, endDate, cycleId)
}

const FormComponent = props => {
  const {
    isSubmitting,
    handleSubmit: onSubmit,
    hasPendingRequests,
    businessUnit,
    isCycleLoading,
    cycles,
  } = props

  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [editorData, setValue] = useState('')

  commentData = editorData

  useEffect(() => {
    commentData = undefined
    cycleIdData = undefined
  }, [businessUnit])

  const filteredCycle = sortNatural([...cycles], 'endDate', true)
  const toOption = val => ({
    label: `${val.title} ${val.groupName} ${getDisplayText(val)}`,
    value: val.id,
  })

  const onCycleChange = ({ target: { value } }) => {
    const { startDate: cycleStartDate = '', endDate: cycleEndDate = '' } = filteredCycle.find(
      cycle => cycle.id === value
    )

    cycleIdData = value

    if (businessUnit === 'non-food') {
      setStartDate(cycleStartDate)
      startDateData = cycleStartDate
      setEndDate(cycleEndDate)
      endDateData = cycleEndDate
    }

    if (businessUnit === 'food') {
      setStartDate(cycleStartDate)
      startDateData = cycleStartDate
      setEndDate(moment(cycleStartDate).add(6, 'days').format(DATE_FORMAT_DEFAULT))
      endDateData = moment(cycleStartDate).add(6, 'days').format(DATE_FORMAT_DEFAULT)
    }
  }

  const onDateChange = ({ startDate: newStartDate, endDate: newEndDate }) => {
    const formattedStartDate = moment(newStartDate).format(DATE_FORMAT_DEFAULT) ?? null
    const formattedEndDate = newEndDate
      ? moment(newEndDate).format(DATE_FORMAT_DEFAULT) ?? null
      : null

    setStartDate(formattedStartDate)
    startDateData = formattedStartDate
    setEndDate(formattedEndDate)
    endDateData = formattedEndDate
  }

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup name="effective-date-fields">
        <GridWrapper matrix>
          {!isCycleLoading ? (
            <>
              <GridItem size="1/2">
                <Field
                  label="Cycle"
                  name="cycleId"
                  component={SelectField}
                  options={filteredCycle.map(toOption)}
                  onChange={onCycleChange}
                />
              </GridItem>
              <GridItem size="1/2">
                <DateRangePicker
                  label="Date"
                  name="date"
                  startDate={startDate}
                  endDate={endDate}
                  onDatesChange={onDateChange}
                  required
                />
              </GridItem>
            </>
          ) : (
            <LoadingCard />
          )}
          <GridItem size="1/1" className="ln-u-hard-bottom">
            <h6>Front sheet comment</h6>
            <RichTextBox onChange={value => setValue(value)} />
          </GridItem>
        </GridWrapper>
      </FormGroup>
      <br />
      <Controls disabled={hasPendingRequests || isSubmitting} submitText="Generate SSPSA" />
    </Form>
  )
}

FormComponent.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasPendingRequests: PropTypes.bool.isRequired,
  businessUnit: PropTypes.string.isRequired,
  isCycleLoading: PropTypes.bool,
  cycles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
}

FormComponent.defaultProps = {
  isCycleLoading: false,
}

const wrappedForm = withFormik({ handleSubmit })(FormComponent)

export default wrappedForm
