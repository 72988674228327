import moment from 'moment-timezone'

const FORMAT = 'YYYY-MM-DD'
const UTC_TIMEZONE = 'Etc/UTC'

const toUtc = dateStr => {
  if (typeof dateStr !== 'string') {
    throw new Error(`Not a string: ${dateStr}`)
  }
  return moment.tz(dateStr, UTC_TIMEZONE)
}

export const addOneDay = dateStr => toUtc(dateStr).add(1, 'days')

export const addOneDayToDate = dateStr => addOneDay(dateStr).toDate()

export const addOneDayToYMD = dateStr => addOneDay(dateStr).format(FORMAT)

export const subtractOneDay = dateStr => {
  if (typeof dateStr !== 'string') {
    throw new Error(`Not a string: ${dateStr}`)
  }
  return toUtc(dateStr).subtract(1, 'days')
}

export const subtractOneDayToDate = dateStr => subtractOneDay(dateStr).toDate()

export const subtractOneDayToYMD = dateStr => subtractOneDay(dateStr).format(FORMAT)

export const numOfDaysBetweenInclusive = (startDate, endDate) =>
  addOneDay(endDate).diff(toUtc(startDate), 'days')
