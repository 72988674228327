import sortArray from './sortArray'

export const buildCategoryOption = ({ id, title, businessUnit }) => ({
  id,
  label: title,
  value: id,
  group: businessUnit === 'food' ? 'Food' : 'Non-Food',
})

export const buildCategoryOptions = categories =>
  sortArray(
    categories.filter(x => x.isPriceLock === false && x.active),
    'title'
  ).map(buildCategoryOption)
