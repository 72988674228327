import { push } from 'react-router-redux'
import { API_FAILURE } from 'src/constants/api'

const middleware = ({ dispatch }) => next => action => {
  const { type, error, payload: { status } = {} } = action || {}

  if (type === API_FAILURE && error && (String(status) === '403' || String(status) === '401')) {
    dispatch(push('/unauthorised'))
  }

  next(action)
}

export default middleware
