import React from 'react'
import PropTypes from 'prop-types'

import EquipmentSeparatedLayout from './FrozenLayout/EquipmentSeparatedLayout'
import { buildWellEquipment, sortLayouts } from './FrozenLayout/buildEquipment'

const WellLayout = props => {
  const { layouts: allLayouts, rows, spaceTitle, storePSAFlag } = props

  const layouts = allLayouts
    .filter(layout => layout.location === 'well' && layout.index === 0)
    .sort((a, b) => (a.position > b.position ? 1 : -1))

  const sortedLayouts = sortLayouts([...layouts])
  const equipments = buildWellEquipment(sortedLayouts)
  return (
    <>
      {equipments.map(equipment => {
        const { name: equipmentName } = equipment
        return (
          <div className="ln-u-push-top" key={`${spaceTitle}-${equipmentName}`}>
            <EquipmentSeparatedLayout
              equipment={equipment}
              rows={rows}
              spaceTitle={spaceTitle}
              storePSAFlag={storePSAFlag}
            />
          </div>
        )
      })}
    </>
  )
}

WellLayout.propTypes = {
  layouts: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      location: PropTypes.oneOf(['left', 'right', 'top', 'well']),
      position: PropTypes.number,
      shelfProductId: PropTypes.string,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      endDate: PropTypes.string.isRequired,
      facingsOnShelf: PropTypes.number,
      isShelfRsb: PropTypes.bool,
      shelf: PropTypes.number,
      shelfPosition: PropTypes.number,
      shelfFill: PropTypes.number,
      shelfFillType: PropTypes.string,
      sku: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      storeComment: PropTypes.string,
    })
  ).isRequired,
  spaceTitle: PropTypes.string.isRequired,
  storePSAFlag: PropTypes.bool.isRequired,
}

export default WellLayout
