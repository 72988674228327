import { selectSelfCategories } from 'src/store/data/selectors'

import { ALL, NONE, USER, FOOD, NON_FOOD, CUSTOM } from 'src/constants/spacePlanner'
import { selectCategories as selectAllCategories } from 'src/store/modules/asyncThunks/categories'
import fetchSpacePlanClustersAssignment from './reducer/spacePlanClustersAssignment'

const CATEGORY_FORM_NAME = 'spacePlannerCategoryFilter'
const SPACE_FORM_NAME = 'spacePlannerSpaceFilter'
const ZOOM_IN = 'space-planner-zoom-in'
const ZOOM_OUT = 'space-planner-zoom-out'
const TOGGLE_OPEN = 'space-planner-toggle-open'
const TOGGLE_TRACK_OPEN = 'space-planner-toggle-track-open'
const RESET_TRACKS = 'space-planner-reset-tracks'
const SAVE_CATEGORY_FILTER = 'space-planner-save-category-filter'
const SAVE_SPACE_FILTER = 'space-planner-save-space-filter'
const SAVE_SPACE_FILTER_TYPE = 'space-planner-save-space-filter-type'
const SAVE_SPACE_SEARCH = 'space-planner-save-space-search'
const SAVE_SPACE_MULTISELECT = 'space-planner-save-space-multiselect'

const zoomIn = () => ({ type: ZOOM_IN })
const zoomOut = () => ({ type: ZOOM_OUT })
const toggleOpen = () => ({ type: TOGGLE_OPEN })
const resetTracks = () => ({ type: RESET_TRACKS })

const saveCategoryFilter = payload => ({
  type: SAVE_CATEGORY_FILTER,
  payload: { categorySelectionType: CUSTOM, ...payload },
})

const saveSpaceFilter = payload => ({
  type: SAVE_SPACE_FILTER,
  payload,
})

const setSpaceFilterType = payload => ({
  type: SAVE_SPACE_FILTER_TYPE,
  payload,
})

const filterByBusinessUnit = (businessUnit, categories) =>
  categories.filter(category => category.businessUnit === businessUnit)

const saveSpaceMultiselect = payload => ({
  type: SAVE_SPACE_MULTISELECT,
  payload,
})

const saveSpaceSearch = payload => ({
  type: SAVE_SPACE_SEARCH,
  payload,
})

const filterInAllCategories = () => (dispatch, getState) => {
  const categories = selectAllCategories(getState()).map(category => category.id)
  return dispatch(saveCategoryFilter({ categories, categorySelectionType: ALL }))
}

const filterOutAllCategories = () => dispatch =>
  dispatch(saveCategoryFilter({ categories: [], categorySelectionType: NONE }))

const filterInAllUserCategories = () => (dispatch, getState) => {
  const categories = selectSelfCategories(getState()).map(category => category.id)
  return dispatch(saveCategoryFilter({ categories, categorySelectionType: USER }))
}

const filterInFoodCategories = () => (dispatch, getState) => {
  const allCategories = selectAllCategories(getState())
  const categories = filterByBusinessUnit(FOOD, allCategories).map(category => category.id)
  return dispatch(saveCategoryFilter({ categories, categorySelectionType: FOOD }))
}

const filterInNonFoodCategories = () => (dispatch, getState) => {
  const allCategories = selectAllCategories(getState())
  const categories = filterByBusinessUnit(NON_FOOD, allCategories).map(category => category.id)
  return dispatch(saveCategoryFilter({ categories, categorySelectionType: NON_FOOD }))
}

const toggleTrackOpen = ({ id: trackId, isOpen, context = {} }) => async dispatch => {
  if (!isOpen) {
    const { spaceId, spacePlanId } = context
    await dispatch(fetchSpacePlanClustersAssignment({ spacePlanId, spaceId }))
  }

  dispatch({
    type: TOGGLE_TRACK_OPEN,
    payload: {
      trackId,
      isOpen: !isOpen,
    },
  })
}

export {
  ZOOM_IN,
  ZOOM_OUT,
  TOGGLE_OPEN,
  TOGGLE_TRACK_OPEN,
  SAVE_CATEGORY_FILTER,
  SAVE_SPACE_FILTER,
  SAVE_SPACE_FILTER_TYPE,
  SAVE_SPACE_SEARCH,
  SAVE_SPACE_MULTISELECT,
  CATEGORY_FORM_NAME,
  SPACE_FORM_NAME,
  RESET_TRACKS,
  zoomIn,
  zoomOut,
  toggleOpen,
  toggleTrackOpen,
  saveCategoryFilter,
  saveSpaceFilter,
  setSpaceFilterType,
  saveSpaceSearch,
  saveSpaceMultiselect,
  resetTracks,
  filterInAllCategories,
  filterOutAllCategories,
  filterInFoodCategories,
  filterInNonFoodCategories,
  filterInAllUserCategories,
}
