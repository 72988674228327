import React from 'react'
import PropTypes from 'prop-types'
import { FilledButton } from '@jsluna/react'

const UploadButton = ({ onClick, isValid, isUploading, isFinished }) => {
  let buttonText = 'Create Products'

  if (isUploading) {
    buttonText = 'Creating products...'
  }

  if (!isValid) {
    buttonText = 'Products not valid'
  }

  if (isFinished) {
    buttonText = 'Products created'
  }

  return (
    <FilledButton onClick={onClick} disabled={!isValid || isUploading || isFinished}>
      {buttonText}
    </FilledButton>
  )
}

UploadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isUploading: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool.isRequired,
}

export default UploadButton
