import React from 'react'
import PropTypes from 'prop-types'
import { Heading5, GridWrapper, GridItem } from '@jsluna/react'

import ProduceCell from './ProduceCell'

const ProduceTable = ({
  addProducePosition,
  columns,
  deleteProducePosition,
  instanceId,
  produceLabel,
  rows,
  shelfProductLayouts,
  shelfProducts,
  spaceInstanceCategories,
  userCategories,
}) => (
  <div className="ln-u-push-top ">
    <Heading5>{produceLabel} Table</Heading5>

    {[...Array(rows)].map((row, rowIndex) => (
      // eslint-disable-next-line react/no-array-index-key
      <GridWrapper key={`produce-row-${rowIndex + 1}`}>
        {[...Array(columns)].map((column, columnIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <GridItem size={`1/${columns}`} key={`produce-item-${rowIndex + 1}-${columnIndex + 1}`}>
            <ProduceCell
              addProducePosition={addProducePosition}
              deleteProducePosition={deleteProducePosition}
              index={rowIndex + 1}
              instanceId={instanceId}
              position={columnIndex + 1}
              shelfProductLayouts={shelfProductLayouts}
              shelfProducts={shelfProducts}
              spaceInstanceCategories={spaceInstanceCategories}
              userCategories={userCategories}
            />
          </GridItem>
        ))}
      </GridWrapper>
    ))}
  </div>
)

ProduceTable.propTypes = {
  addProducePosition: PropTypes.func,
  columns: PropTypes.number,
  deleteProducePosition: PropTypes.func,
  instanceId: PropTypes.string.isRequired,
  produceLabel: PropTypes.string.isRequired,
  rows: PropTypes.number,
  shelfProductLayouts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shelfProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  spaceInstanceCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  userCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
}

ProduceTable.defaultProps = {
  rows: 1,
  columns: 1,
  addProducePosition: () => {},
  deleteProducePosition: () => {},
}

export default ProduceTable
