export default function validate({ comment, actionType }) {
  const errors = {}

  if (!comment) {
    if (actionType === 'reject') {
      errors.comment = 'Comments are required on a rejected request'
    } else if (actionType === 'amend') {
      errors.comment = 'Comments are required on a withdraw and amend request'
    }
  }

  return errors
}
