const validate = values => {
  const { title, businessUnit } = values

  const errors = {}

  if (!title) {
    errors.title = 'Title cannot be empty'
  }

  if (!businessUnit) {
    errors.businessUnit = 'A business unit must be selected'
  }

  return errors
}

export default validate
