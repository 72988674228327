import { connect } from 'react-redux'
import { withFormik } from 'formik'
import { push } from 'react-router-redux'

import { hasRequiredPermission } from 'src/utils/permissions'

import statuses, { DRAFT_ID, IN_REVIEW_ID } from 'src/modules/Nominations/statuses'
import { UPDATE_NOMINATIONS, UPDATE_NOMINATIONS_DOCUMENT } from 'src/constants/permissions'
import { actionUpdateSharepointNomination } from '../store'

import Form from './Form'

export const handleSubmit = ({ status }, { props: { submit } }) => submit({ status })

export const mapStateToProps = (state, { nomination, user }) => {
  const statusTransitions = statuses.transitions(nomination.status)
  const { status } = nomination || {}
  const after = refStatus => statuses.isAfter(status, refStatus)
  const isReadOnly = !hasRequiredPermission(user, UPDATE_NOMINATIONS) || after(IN_REVIEW_ID)
  const showUrl = after(DRAFT_ID) || hasRequiredPermission(user, UPDATE_NOMINATIONS_DOCUMENT)

  return {
    nomination,
    statuses: statusTransitions,
    isReadOnly,
    showUrl,
    status,
  }
}

const mapDispatchToProps = (dispatch, { nomination }) => {
  const returnAction = push('/nominations')
  return {
    submit: async props => {
      await dispatch(actionUpdateSharepointNomination({ id: nomination.id, status: props.status }))
      return dispatch(returnAction)
    },
    cancel: () => dispatch(returnAction),
  }
}

const wrappedForm = withFormik({ handleSubmit })(Form)

export default connect(mapStateToProps, mapDispatchToProps)(wrappedForm)
