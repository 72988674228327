import loader from 'src/hoc/loader'

import { actionClearData, fetchCycles } from './store'
import Cycles from './Cycles'

const getData = async (props, dispatch) => {
  await dispatch(fetchCycles(props.businessUnit || 'food'))
}

const options = {
  onUnmount: dispatch => dispatch(actionClearData()),
  extractProps: ({ params }) => ({ ...params }),
  shouldRefresh: (previous, current) => previous.businessUnit !== current.businessUnit,
}

export default loader(getData, options)(Cycles)
