import React from 'react'
import PropTypes from 'prop-types'
import NotFound from 'src/components/Errors/NotFound'
import Unauthorised from 'src/components/Errors/Unauthorised'
import Generic from 'src/components/Errors/Generic'
import SessionExpiry from 'src/components/Errors/SessionExpiry'

function createErrorHandler({ Custom400, Custom401, Custom403, Custom404 }) {
  const ErrorHandler = ({ error }) => {
    const { status, title, message } = error

    if (String(status) === '404') {
      return Custom404 || <NotFound title={title} message={message} />
    }

    if (String(status) === '403') {
      return Custom403 || <Unauthorised />
    }

    if (String(status) === '401') {
      return Custom401 || <SessionExpiry />
    }

    if (String(status) === '400') {
      return Custom400 || <NotFound title={title} message={message} />
    }

    return <Generic message={message} />
  }

  ErrorHandler.propTypes = {
    error: PropTypes.shape({
      status: PropTypes.number,
      title: PropTypes.string,
      message: PropTypes.string,
    }).isRequired,
  }

  return ErrorHandler
}

export default createErrorHandler
