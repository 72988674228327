import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Card, Heading3, FlagWrapper, FlagBody, FlagComponent, Table } from '@jsluna/react'

import sortNatural from 'src/utils/sortNatural'
import QuickCategoryFilter from './QuickCategoryFilter'
import MultiAutoCompleteField from './MultiAutoCompleteField'
import { filterInAllUserCategories } from './store/actions'
import { selectSpaceAssignments, selectSpaceMultiSelect } from './store/selectors'
import columns from './columns'
import { selectSpaceAssignmentsByCycleId } from './store'

class Spaces extends Component {
  componentDidMount() {
    const { filteredUserCategories } = this.props
    filteredUserCategories()
  }

  render() {
    const {
      allSpaces,
      categories,
      categorySelectionType,
      spaceAssignments,
      searchedSpaces,
    } = this.props

    const toOption = ({ id, title }) => ({ id, label: title, value: id })
    const sorted = sortNatural(allSpaces, 'title')
    const options = sorted.map(toOption)

    return (
      <Card>
        <Heading3 data-control="spaces-title">Spaces</Heading3>

        <FlagWrapper className="ln-u-margin-bottom">
          <FlagComponent alignment="top">
            <QuickCategoryFilter
              categoriesLength={categories && categories.length}
              categorySelectionType={categorySelectionType}
            />
          </FlagComponent>
          <FlagBody className="ln-u-soft-left">
            <MultiAutoCompleteField options={options} defaultSelectedOptions={searchedSpaces} />
          </FlagBody>
        </FlagWrapper>

        <Table type="cycleSpaces" sortable columns={columns} data={spaceAssignments} />
      </Card>
    )
  }
}

const findUniqueSets = spaceAssignments =>
  spaceAssignments.reduce((acc, { spaceId: id, spaceTitle: title }) => {
    if (id) {
      acc[id] = { id, title }
    }
    return acc
  }, {})

const mapStateToProps = state => {
  const { categories, categorySelectionType, spaceAssignments } = selectSpaceAssignments(state)
  const spaceAssignmentsWithCycleId = selectSpaceAssignmentsByCycleId(state)

  const spaceByIds = findUniqueSets(spaceAssignmentsWithCycleId)
  const allSpaces = Object.values(spaceByIds)
  const searchedSpaces = selectSpaceMultiSelect(state)

  return {
    allSpaces,
    categories,
    categorySelectionType,
    searchedSpaces,
    spaceAssignments,
  }
}

const mapDispatchToProps = dispatch => ({
  filteredUserCategories: () => dispatch(filterInAllUserCategories()),
})

Spaces.propTypes = {
  allSpaces: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
  categorySelectionType: PropTypes.string.isRequired,
  spaceAssignments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  searchedSpaces: PropTypes.arrayOf(PropTypes.shape({})),
  filteredUserCategories: PropTypes.func.isRequired,
}

Spaces.defaultProps = {
  categories: [],
  searchedSpaces: [],
}

export default connect(mapStateToProps, mapDispatchToProps)(Spaces)
