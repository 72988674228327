import React from 'react'
import PropTypes from 'prop-types'
import { Card, Heading3 } from '@jsluna/react'

import Section from 'src/components/Section'

import Form from './Form'

const Details = ({ id, user }) => (
  <Section>
    <Heading3 className="ln-u-visually-hidden">Details</Heading3>
    <Card>
      <Form id={id} user={user} />
    </Card>
  </Section>
)

Details.propTypes = {
  id: PropTypes.string,
  user: PropTypes.shape({}).isRequired,
}

Details.defaultProps = {
  id: null,
}

export default Details
