import React from 'react'
import { Card, Heading2 } from '@jsluna/react'

import Section from 'src/components/Section'

import Form from './Form'

const MessagesAdmin = () => {
  return (
    <Section>
      <Heading2>
        <span>Admin Config</span>
      </Heading2>
      <Card>
        <Form />
      </Card>
    </Section>
  )
}

export default MessagesAdmin
