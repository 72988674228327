import React from 'react'

import loader from 'src/hoc/loader'
import NotFound from 'src/components/Errors/NotFound'
import { fetchSpaceDetails } from 'src/store/modules/asyncThunks/spaceDetails'
import { fetchSpaceTypes } from 'src/modules/SpaceTypes/store'
import { fetchSpaceGroups } from 'src/modules/SpaceGroups/store'
import { actionClearData } from './store'
import Form from './Form'

const getData = async ({ id }, dispatch) => {
  const promises = [
    dispatch(fetchSpaceTypes()),
    dispatch(fetchSpaceGroups()),
    id && dispatch(fetchSpaceDetails(id)),
  ].filter(v => !!v)
  await Promise.all(promises)
}

const options = {
  Custom404: (
    <NotFound title="Space Not Found" message="The space you are looking for cannot be found." />
  ),
  extractProps: ({ params }) => params,
  shouldRefresh: ({ id: previousId }, { id }) => previousId !== id,
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(Form)
