import React from 'react'
import PropTypes from 'prop-types'
import { Delete } from '@jsluna/icons'

const DeleteButton = ({ onClick, type }) => {
  const classNames =
    type === 'divider' ? 'c-icon-button u-bg-none ln-u-color-white' : 'c-icon-button u-bg-none'

  return (
    <button
      className={classNames}
      data-control="remove-shelf-artifact"
      onClick={onClick}
      type="button"
    >
      <Delete />
    </button>
  )
}

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default DeleteButton
