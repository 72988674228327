import moment from 'moment'

import { addOneDay } from './day'

export const DATE_ABBREVIATED = 'Do MMM'
export const DATE_FORMAT = 'Do MMM YYYY'
export const DATETIME_FORMAT = 'Do MMM YYYY h:mma'

export const getDisplayText = (period = {}) => {
  const { endDate, previous } = period
  const end = moment(endDate)

  const start =
    (period.startDate && moment(period.startDate)) ||
    (previous && previous.endDate && addOneDay(previous.endDate))

  if (start) {
    const startFormat =
      start.year() < end.year() - 1 ||
      (start.dayOfYear() <= end.dayOfYear() && start.year() !== end.year())
        ? DATE_FORMAT
        : 'Do MMM'

    return `${start.format(startFormat)} - ${end.format(DATE_FORMAT)}`
  }

  return `Beginning of time - ${end.format(DATE_FORMAT)}`
}

export const getDuration = (period = {}) => {
  const { endDate, previous } = period
  const startDate =
    (period.startDate && moment(period.startDate).subtract(1, 'days')) ||
    (previous && previous.endDate)

  if (startDate && endDate) {
    const duration = moment(endDate).diff(startDate, 'days')
    return duration > 0 ? duration : null
  }

  return null
}

export const getDurationText = (period = {}) => {
  const days = getDuration(period) || 0
  return `${days} day${days === 1 ? '' : 's'}`
}
