import fetchJson from 'src/utils/fetchJson'
import { selectToken } from 'src/store/data/selectors'
import { selectConfig } from 'src/store/config/selectors'
import { LOADING, API_REQUEST } from 'src/constants/api'
import { onSuccess, onError } from './handlers'

const request = store => next => action => {
  const { type, payload } = action

  if (type !== API_REQUEST) {
    next(action)
    return null
  }

  const {
    endpoint,
    authenticated = false,
    ignoreNotFound = false,
    method = 'GET',
    body: rawBody,
  } = payload

  const generatePayload = (contents = {}) => ({
    endpoint,
    authenticated,
    method,
    ...contents,
  })

  const state = store.getState()
  const { apiUrl, debug } = selectConfig(state)
  const url = `${apiUrl}/${endpoint}`

  let body
  const headers = {}

  if (rawBody) {
    if (rawBody instanceof FormData) {
      body = rawBody
    } else {
      headers['Content-Type'] = 'application/vnd.api+json'
      body = JSON.stringify(rawBody)
    }
  }

  const options = {
    method,
    headers,
    mode: 'cors',
  }

  const token = authenticated ? selectToken(state) : undefined

  const promise = fetchJson(url, token, options, body)

  store.dispatch({ type: LOADING, payload: true })

  return promise
    .then(onSuccess(next, generatePayload))
    .catch(onError(next, generatePayload, { debug, ignoreNotFound }))
    .finally(() => store.dispatch({ type: LOADING, payload: false }))
}

export default request
