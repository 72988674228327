import React from 'react'
import PropTypes from 'prop-types'
import { Heading3, FilledButton } from '@jsluna/react'

const RefreshNominationsDialog = ({
  cycleId,
  generateCycleCategories,
  handleClose,
  refreshNominations,
}) => (
  <div>
    <Heading3>Refresh Nomination Cycle Categories</Heading3>
    <p>Please confirm to proceed.</p>
    <div>
      <FilledButton
        data-control="modal-confirm-refresh-nomination-cycle-categories"
        onClick={async () => {
          handleClose()
          await generateCycleCategories(cycleId)
          refreshNominations()
        }}
      >
        Confirm
      </FilledButton>
      <FilledButton
        color="dark"
        data-control="modal-cancel-refresh-nomination-cycle-categories"
        onClick={handleClose}
        className="ln-u-push-left"
      >
        Cancel
      </FilledButton>
    </div>
  </div>
)

RefreshNominationsDialog.propTypes = {
  cycleId: PropTypes.string.isRequired,
  generateCycleCategories: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  refreshNominations: PropTypes.func.isRequired,
}

export default RefreshNominationsDialog
