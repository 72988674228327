import buildBlanks from './buildBlanks'
import buildGaps from './buildGaps'
import buildClusterTracks from './buildClusterTracks'
import calculateCoverage from '../utils/calculateCoverage'
import calculateShelfCoverage from '../utils/calculateShelfCoverage'

const buildTrack = (trackData, uiTracks) => {
  const {
    id,
    title,
    hfssFlag,
    assignments,
    hasSelectedCategory,
    cycles,
    startDate,
    endDate,
    validCycles,
    categoriesById,
    clusterSpaceAssignmentsDetails,
    spacePlanId,
  } = trackData

  const blanks = buildBlanks(cycles, validCycles)
  const gaps = buildGaps({
    assignments,
    blanks,
    startDate,
    endDate,
    cycles,
  })
  const coverage = calculateCoverage({ gaps, startDate, endDate })
  const shelfCoverage = calculateShelfCoverage(assignments)
  const { isOpen = false } = uiTracks[`track-${id}`] || {}
  const clusterTracks = isOpen
    ? buildClusterTracks({ clusterSpaceAssignmentsDetails, categoriesById })
    : []

  const context = {
    spaceId: id,
    assignments: assignments.map(a => a.id),
    spacePlanId,
  }

  return {
    id,
    title,
    hfssFlag,
    assignments,
    blanks,
    gaps,
    coverage,
    shelfCoverage,
    hasSelectedCategory,
    tracks: clusterTracks,
    isOpen,
    context,
  }
}

export default buildTrack
