import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { TextButton } from '@jsluna/react'

import {
  PALLET_LAYOUT_TYPE,
  SHELF_LAYOUT_TYPE,
  SHIPPER_LAYOUT_TYPE,
  STACK_LAYOUT_TYPE,
} from 'src/constants/layoutTypes'

import withModal from 'src/hoc/withModal'
import WithRequiredPermissions from 'src/components/WithRequiredPermissions'
import { UPDATE_SHELVES_CATEGORIES } from 'src/constants/permissions'

import ModalLoader from './ModalLoader'
import { updateShelves as updateShelvesAction } from '../../store/actions'

const CategoriesModal = withModal(ModalLoader)
const layoutTypePlurals = {
  [PALLET_LAYOUT_TYPE]: 'pallets',
  [SHELF_LAYOUT_TYPE]: 'shelves',
  [SHIPPER_LAYOUT_TYPE]: 'shippers',
  [STACK_LAYOUT_TYPE]: 'stacks',
}

const ShelfCategoriesBulk = ({ shelves, updateShelves, layoutType, spaceAssignmentId }) => {
  const save = newCategoryIds => {
    updateShelves(
      shelves.map(shelf => ({
        ...shelf,
        categories: [...newCategoryIds.map(id => ({ id }))],
        spaceAssignmentId,
      }))
    )
  }

  return (
    <WithRequiredPermissions permission={UPDATE_SHELVES_CATEGORIES}>
      <div data-control="shelf-categories-bulk-edit">
        <CategoriesModal
          save={save}
          layoutType={layoutTypePlurals[layoutType]}
          element={({ onClick }) => (
            <TextButton onClick={onClick}>Edit all {layoutTypePlurals[layoutType]}</TextButton>
          )}
        />
      </div>
    </WithRequiredPermissions>
  )
}

ShelfCategoriesBulk.propTypes = {
  shelves: PropTypes.arrayOf(
    PropTypes.shape({
      categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    })
  ).isRequired,
  updateShelves: PropTypes.func.isRequired,
  layoutType: PropTypes.string.isRequired,
  spaceAssignmentId: PropTypes.string.isRequired,
}

const mapDispatchToProps = {
  updateShelves: updateShelvesAction,
}

export default connect(null, mapDispatchToProps)(ShelfCategoriesBulk)
