import React from 'react'
import PropTypes from 'prop-types'
import { TextButton } from '@jsluna/react'

const ModalButton = ({ onClick, canAddProduct, disableUpload }) =>
  canAddProduct && (
    <span data-control="upload-nominations">
      <TextButton onClick={onClick} disabled={disableUpload}>
        Upload Nominations
      </TextButton>
    </span>
  )

ModalButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  canAddProduct: PropTypes.bool.isRequired,
  disableUpload: PropTypes.bool.isRequired,
}

export default ModalButton
