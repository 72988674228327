import React from 'react'
import PropTypes from 'prop-types'
import { Field, withFormik } from 'formik'

import {
  Fieldset,
  Form,
  FormGroup,
  GridWrapper,
  GridItem,
  List,
  ListItem,
  FilledButton,
} from '@jsluna/react'
import { SelectField, TextInputField } from '@jsluna/form/extensions/formik'

import { getLayoutTypeLabel } from 'src/utils/layoutTypes'

import { Controls } from 'src/components/Form'
import { Checkbox, CheckboxGroup, DateField } from 'src/components/FormikComponents'

import DeleteDialog from './DeleteDialog'
import ReadOnlyCategories from './ReadOnlyCategories'
import handleSubmit from './handleSubmit'
import validate from './validate'

const toOption = input => ({ id: input, value: input, label: getLayoutTypeLabel(input) })

const SpaceAssignmentDetailsForm = ({
  categoryOptions,
  canDelete,
  deleteSpaceAssignment,
  disabled,
  endDate,
  handleSubmit: onSubmit,
  hasProducts,
  id,
  isNewAssignment,
  selectedCategories,
  layoutTypes,
}) => (
  <Form onSubmit={onSubmit}>
    <FormGroup name="space-assignment-details">
      <Fieldset hard>
        <GridWrapper>
          <GridItem size="1/2">
            <DateField
              label="Start Date"
              name="startDate"
              maxDate={endDate}
              disabled={disabled.startDate}
            />
          </GridItem>
          <GridItem size="1/2">
            <DateField label="End Date" name="endDate" disabled={disabled.endDate} />
          </GridItem>
          <GridItem size={isNewAssignment ? '1/2' : '1/1'}>
            <Field
              label="Theme"
              name="title"
              component={TextInputField}
              validationFirst={false}
              disabled={disabled.title}
            />
          </GridItem>
          {isNewAssignment && (
            <GridItem size="1/2">
              <Field
                label="Layout"
                name="layoutType"
                component={SelectField}
                placeholder={false}
                options={layoutTypes.map(toOption)}
                validationFirst={false}
                disabled={disabled.layoutType}
              />
            </GridItem>
          )}
          <GridItem size="1/1">
            <div className="ln-c-form-group">
              <Field
                component={Checkbox}
                label="Proposition POS"
                name="isProposition"
                disabled={disabled.proposition}
              />
            </div>
          </GridItem>
          <GridItem size="1/2">
            <Field
              label="Comment"
              name="comment"
              component={TextInputField}
              validationFirst={false}
              disabled={disabled.comment}
            />
          </GridItem>
          <GridItem size="1/2">
            <Field
              label="Store Title Comment"
              name="storeTitleComment"
              component={TextInputField}
              validationFirst={false}
              disabled={disabled.storeTitleComment}
            />
          </GridItem>
          <GridItem size="1/1" data-control="categories">
            {isNewAssignment ? (
              <CheckboxGroup
                label="Categories"
                name="categories"
                options={categoryOptions}
                disabled={disabled.categories}
              />
            ) : (
              <ReadOnlyCategories categories={selectedCategories} />
            )}
          </GridItem>
        </GridWrapper>
      </Fieldset>
    </FormGroup>

    <List type="matrix">
      <ListItem type="matrix">
        <Controls />
      </ListItem>
      {canDelete && (
        <ListItem type="matrix">
          <DeleteDialog
            spaceAssignmentId={id}
            deleteSpaceAssignment={deleteSpaceAssignment}
            element={({ onClick }) => (
              <FilledButton
                color="dark"
                onClick={onClick}
                data-control="controls-delete"
                disabled={hasProducts}
              >
                Delete
              </FilledButton>
            )}
          />
        </ListItem>
      )}
    </List>
  </Form>
)

SpaceAssignmentDetailsForm.propTypes = {
  categoryOptions: PropTypes.arrayOf(PropTypes.shape({})),
  canDelete: PropTypes.bool.isRequired,
  deleteSpaceAssignment: PropTypes.func,
  disabled: PropTypes.shape({
    categories: PropTypes.bool,
    comment: PropTypes.bool,
    endDate: PropTypes.bool,
    startDate: PropTypes.bool,
    storeTitleComment: PropTypes.bool,
    title: PropTypes.bool,
    proposition: PropTypes.bool,
    layoutType: PropTypes.bool,
  }).isRequired,
  endDate: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  hasProducts: PropTypes.bool.isRequired,
  id: PropTypes.string,
  isNewAssignment: PropTypes.bool.isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.shape({})),
  layoutTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
}

SpaceAssignmentDetailsForm.defaultProps = {
  categoryOptions: [],
  deleteSpaceAssignment: () => {},
  id: null,
  endDate: null,
  selectedCategories: [],
}

const wrappedForm = withFormik({ handleSubmit, validate })(SpaceAssignmentDetailsForm)

export default wrappedForm
