import Background from './components/Background'
import Component from './components/Component'
import Container from './components/Container'
import Wrapper from './components/Wrapper'

const Flyout = {
  Background,
  Component,
  Container,
  Wrapper,
}

export default Flyout
export { default as withFlyout } from './hoc/withFlyout'
