import { createNotification } from 'src/store/notifications/actions'
import {
  actionClearData,
  importPOSAdviceListFile,
  importJdaFile,
  importSkuCsvFile,
  importSkuGzFile,
} from '.'
import {
  TYPE_POS_ADVICE,
  TYPE_SSC,
  TYPE_SSC_NON_JDA,
  TYPE_SKU_RMS_10,
  TYPE_SKU_RMS_9,
} from '../Form'

export const IMPORT_UPLOAD_REQUEST = 'import-upload-request'
export const IMPORT_UPLOAD_SUCCESS = 'import-upload-success'
export const IMPORT_UPLOAD_FAILURE = 'import-upload-failure'

const FILE_KEY = 'file'

const uploadRequest = () => ({ type: IMPORT_UPLOAD_REQUEST })
const uploadSuccess = id => ({ type: IMPORT_UPLOAD_SUCCESS, payload: { id } })
const uploadFailure = error => ({ type: IMPORT_UPLOAD_FAILURE, payload: error })

export const uploadFormData = (type, file) => async dispatch => {
  const formData = new FormData()
  formData.append(FILE_KEY, file)

  dispatch(uploadRequest())
  dispatch(actionClearData())

  try {
    if (type === TYPE_POS_ADVICE) {
      const response = await dispatch(importPOSAdviceListFile({ formData })).unwrap()
      dispatch(createNotification(response.successResult, false))
      dispatch(uploadSuccess(TYPE_POS_ADVICE))
      return response
    }

    if (type === TYPE_SSC || type === TYPE_SSC_NON_JDA) {
      const isJda = type === TYPE_SSC
      const response = await dispatch(importJdaFile({ formData, isJda })).unwrap()
      dispatch(createNotification(response.successResult, false))
      dispatch(uploadSuccess(type))
      return response
    }

    if (type === TYPE_SKU_RMS_10) {
      const response = await dispatch(importSkuCsvFile({ formData })).unwrap()
      dispatch(createNotification(response.successResult, false))
      dispatch(uploadSuccess(type))
      return response
    }

    if (type === TYPE_SKU_RMS_9) {
      const response = await dispatch(importSkuGzFile({ formData })).unwrap()
      dispatch(createNotification(response.successResult, false))
      dispatch(uploadSuccess(type))
      return response
    }

    return null
  } catch (error) {
    dispatch(uploadFailure(error))
    return null
  }
}
