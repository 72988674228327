import React from 'react'
import PropTypes from 'prop-types'
import { Select } from '@jsluna/react'

import { SPACE_FILTER_TYPE_OPTIONS } from 'src/constants/spacePlanner'

const SpaceFilterType = props => {
  const { type, onChange } = props

  return (
    <Select
      name="space-filter-type"
      options={SPACE_FILTER_TYPE_OPTIONS}
      value={type}
      onChange={onChange}
      placeholder={false}
    />
  )
}

SpaceFilterType.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SpaceFilterType
