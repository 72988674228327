import { actionCreateSpaceType, actionUpdateSpaceTypeById } from '../store'

const setCapacity = (key, capacity) => (capacity !== '' ? { [key]: parseInt(capacity, 10) } : {})

const handleSubmit = async (values, { props: { afterSubmit, submit, showError } }) => {
  const {
    id: spaceTypeId,
    shelvesDefault,
    shelvesMax,
    shelvesMin,
    storage,
    title,
    layouts = [],
  } = values
  const spaceType = {
    shelvesDefault: parseInt(shelvesDefault, 10),
    shelvesMax: parseInt(shelvesMax, 10),
    shelvesMin: parseInt(shelvesMin, 10),
    storage,
    title,
    id: spaceTypeId,
  }

  if (!layouts.length) {
    showError('A layout has to be added to this space type')

    return
  }

  spaceType.spaceTypeLayouts = layouts.map(
    ({ id, layoutType, defaultCapacity, minCapacity, maxCapacity }) => ({
      id,
      layoutType,
      ...setCapacity('defaultCapacity', defaultCapacity),
      ...setCapacity('minCapacity', minCapacity),
      ...setCapacity('maxCapacity', maxCapacity),
    })
  )
  const action = spaceTypeId
    ? actionUpdateSpaceTypeById({ spaceTypeId, spaceType })
    : actionCreateSpaceType(spaceType)

  await submit(action)
  afterSubmit()
}

export default handleSubmit
