import pluralize from 'src/utils/pluralize'

import {
  FROZEN_LAYOUT_TYPE,
  FROZEN_FULL_HEIGHT_LAYOUT_TYPE,
  FROZEN_HALF_HEIGHT_LAYOUT_TYPE,
} from 'src/constants/layoutTypes'

const desiredLocationOrder = ['left', 'right', 'top', 'well']

const buildShelfFill = ({ shelfFill, shelfFillType }) =>
  shelfFill && shelfFillType ? `${shelfFill} ${pluralize(shelfFill, shelfFillType)}` : ''

const sortLayouts = layouts => {
  return layouts.sort((a, b) => {
    // Group allLayouts by location (left, right, top and well)
    if (desiredLocationOrder.indexOf(a.location) > desiredLocationOrder.indexOf(b.location))
      return 1
    if (desiredLocationOrder.indexOf(a.location) < desiredLocationOrder.indexOf(b.location))
      return -1

    // Then group allLayouts by the "row" they sit on
    if (a.index > b.index) return 1
    if (a.index < b.index) return -1

    // Then group allLayouts by their index position on the shelf
    if (a.position > b.position) return 1
    if (a.position < b.position) return -1

    return 0
  })
}

const buildFrozenFullHeightEquipment = layouts => {
  const frozenEquipment = {
    name: 'full-height',
    disclaimerMessage: 'NB: This applies to store with Frozen Full Height doors for',
    locations: [
      {
        name: 'left',
        description: 'Left Hand Door',
        rowLabeling: 'alphabetical',
        layouts: [],
      },
      {
        name: 'right',
        description: 'Right Hand Door',
        rowLabeling: 'alphabetical',
        layouts: [],
      },
    ],
  }

  layouts.forEach(layout => {
    const { location } = layout
    const fullHeightLeftLocations = frozenEquipment.locations.find(loc => loc.name === 'left')
    const fullHeightRightLocations = frozenEquipment.locations.find(loc => loc.name === 'right')

    if (location === 'left') {
      fullHeightLeftLocations.layouts.push(layout)
    } else if (location === 'right') {
      fullHeightRightLocations.layouts.push(layout)
    }
  })

  return frozenEquipment
}

const buildHalfHeightEquipment = layouts => {
  const frozenEquipment = {
    name: 'half-height-and-wells',
    disclaimerMessage: 'NB: This applies to stores with Half Height Glass doors with wells for ',
    locations: [
      {
        name: 'top',
        description: 'Half Height Glass Door',
        rowLabeling: 'alphabetical',
        layouts: [],
      },
      {
        name: 'well',
        description: 'Well From Left to Right',
        rowLabeling: 'numerical',
        layouts: [],
      },
    ],
  }

  layouts.forEach(layout => {
    const { location } = layout
    const halfHeightTopLocations = frozenEquipment.locations.find(loc => loc.name === 'top')
    const halfHeightWellLocations = frozenEquipment.locations.find(loc => loc.name === 'well')

    if (location === 'top') {
      halfHeightTopLocations.layouts.push(layout)
    } else if (location === 'well') {
      halfHeightWellLocations.layouts.push(layout)
    }
  })

  return frozenEquipment
}

const buildFrozenEquipment = (layouts, layoutType = 'frozen') => {
  const buildFullHeight = [FROZEN_LAYOUT_TYPE, FROZEN_FULL_HEIGHT_LAYOUT_TYPE].includes(layoutType)
  const buildHalfHeight = [FROZEN_LAYOUT_TYPE, FROZEN_HALF_HEIGHT_LAYOUT_TYPE].includes(layoutType)
  const frozenEquipment = [
    ...(buildFullHeight ? [buildFrozenFullHeightEquipment(layouts)] : []),
    ...(buildHalfHeight ? [buildHalfHeightEquipment(layouts)] : []),
  ]

  return frozenEquipment
}

const buildWellEquipment = layouts => {
  const wellEquipment = [
    {
      name: 'well-only',
      disclaimerMessage: 'NB: This applies to stores with Wells for',
      locations: [
        {
          name: 'well',
          description: 'Well From Left to Right',
          rowLabeling: 'numerical',
          layouts: [],
        },
      ],
    },
  ]

  layouts.forEach(layout => {
    const { location } = layout
    const wellOnly = wellEquipment.find(group => group.name === 'well-only')
    const wellOnlyLocations = wellOnly.locations.find(loc => loc.name === 'well')

    if (location === 'well') {
      wellOnlyLocations.layouts.push(layout)
    }
  })

  return wellEquipment
}

export {
  buildShelfFill,
  buildFrozenEquipment,
  buildFrozenFullHeightEquipment,
  buildHalfHeightEquipment,
  buildWellEquipment,
  sortLayouts,
}
