import { SAVE_SPACE_MULTISELECT } from '../actions'

const initialState = []

const reducer = (state = initialState, { type, payload }) => {
  if (type === SAVE_SPACE_MULTISELECT) return payload

  return state
}

export default reducer
