import { RESET_SPACE_MULTISELECT, SAVE_SPACE_MULTISELECT } from '../actions'

const initialState = []

const reducer = (state = initialState, { type, payload }) => {
  if (type === SAVE_SPACE_MULTISELECT) {
    return payload
  }
  if (type === RESET_SPACE_MULTISELECT) {
    return initialState
  }

  return state
}

export default reducer
