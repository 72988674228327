import { connect } from 'react-redux'
import moment from 'moment'

import { DATE_FORMAT_DEFAULT } from 'src/utils/dateRange'

import Form from './Form'
import { selectCycleStatus, fetchCycles, selectCycles } from '../../Cycles/store'
import { fetchExportMastrePsa, fetchExportSpaceAllocation, fetchExportPosAdvice } from '../store'

export const mapStateToProps = (
  state,
  { startDate: initialStartDate, endDate: initialEndDate } = {}
) => {
  const cycles = selectCycles(state) || []
  const isCycleLoading = selectCycleStatus(state) || false
  const startDate = initialStartDate || moment().format(DATE_FORMAT_DEFAULT)
  const endDate = initialEndDate
  const maxDate = moment(startDate).add(1, 'years').format(DATE_FORMAT_DEFAULT)

  return {
    cycles,
    startDate,
    endDate,
    maxDate,
    isCycleLoading,
  }
}

export const mapDispatchToProps = dispatch => ({
  getExportsMasterPsa: (startDate, endDate) =>
    dispatch(fetchExportMastrePsa({ startDate, endDate })).unwrap(),
  getExportsSpaceAllocation: (startDate, endDate) =>
    dispatch(fetchExportSpaceAllocation({ startDate, endDate })).unwrap(),
  getExportsPosAdvice: () => dispatch(fetchExportPosAdvice()).unwrap(),
  onChangeBusinessUnit: businessUnit => {
    dispatch(fetchCycles(businessUnit))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
