import Api from '../Base'

class MoveShelfProduct extends Api {
  requestUrl = `/api/shelfProduct/${this.getParams().shelfProductId}/MoveShelfProduct`

  method = 'PATCH'

  formatRequestData = params => params.shelfProduct
}

export default MoveShelfProduct
