import React from 'react'
import PropTypes from 'prop-types'
import sortArray from 'src/utils/sortArray'
import Tags from 'src/components/Tags'

const buildItem = ({ id, title, theme, active }) => ({
  id,
  title,
  color: theme,
  ...(!active ? { opacity: 0.5 } : {}),
})

const buildItems = categories =>
  sortArray(Object.values(categories), 'isPriceLock', false, 'title').map(buildItem)

const Categories = ({ categories }) =>
  categories.length ? <Tags items={buildItems(categories)} /> : 'None'

Categories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
}

Categories.defaultProps = {
  categories: [],
}

export default Categories
