import React from 'react'
import { Card, Heading2 } from '@jsluna/react'

import Section from 'src/components/Section'

const ADMIN_EMAIL = 'promotionevents.team@sainsburys.co.uk'

const Submitted = () => (
  <Section>
    <Card>
      <Heading2>
        <span data-control="user-request-submitted">Request Submitted</span>
      </Heading2>
      <p>An administrator will review your request and provide access shortly.</p>
      <p>
        Please contact <strong>{ADMIN_EMAIL}</strong> if you have any queries.
      </p>
    </Card>
  </Section>
)

export default Submitted
