import PropTypes from 'prop-types'
import React from 'react'
import { FilledButton, SearchInput, Select, MultiAutocompleteField } from '@jsluna/react'
import { Search } from '@jsluna/icons'

const SpacesFilter = ({
  category,
  categoryOptions,
  deptBuyerOptions,
  applyFilter,
  setFilter,
  resetFilter,
  children,
  space,
  buyers,
}) => {
  const buildOnChange = filterType => e => setFilter(filterType, e.target.value)
  const onChangeCategories = buildOnChange('category')
  const onChangeSpace = buildOnChange('space')

  const applyFilterOnEnter = event => {
    const ENTER = 13
    if (event.keyCode === ENTER) applyFilter()
  }

  return (
    <div className="ln-u-soft-bottom c-spaces-filter">
      <div className="ln-u-soft-right">
        <Select
          name="categories"
          options={categoryOptions}
          onChange={async e => {
            await onChangeCategories(e)
            applyFilter()
          }}
          placeholder="All"
          value={category}
          data-control="filter-categories"
        />
      </div>
      {deptBuyerOptions && (
        <div className="c-spaces-filter__dept-buyers-filter ln-u-soft-right">
          <MultiAutocompleteField
            key={buyers}
            defaultSelectedOptions={buyers}
            className="ln-u-flush"
            name="filter-dept-buyer"
            data-control="filter-dept-buyer"
            label=""
            placeholder="Filter Buyers"
            options={deptBuyerOptions}
            onSelect={async values => {
              await setFilter('buyers', values)
              applyFilter()
            }}
          />
        </div>
      )}

      <div className="c-spaces-filter__space-filter ln-u-soft-right">
        <SearchInput
          name="search-by-space"
          placeholder="Search by Space"
          hasIcon
          icon={<Search />}
          onChange={onChangeSpace}
          onKeyDown={applyFilterOnEnter}
          data-control="filter-space"
          value={space}
        />
      </div>
      <div className="ln-u-soft-right">
        <FilledButton onClick={applyFilter}>Apply</FilledButton>
      </div>
      <div className="ln-u-soft-right">
        <FilledButton color="dark" onClick={resetFilter}>
          Reset
        </FilledButton>
      </div>
      {children}
    </div>
  )
}

SpacesFilter.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  space: PropTypes.string.isRequired,
  buyers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  deptBuyerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
  setFilter: PropTypes.func.isRequired,
  children: PropTypes.element,
}

SpacesFilter.defaultProps = {
  children: null,
  deptBuyerOptions: null,
  buyers: null,
}

export default SpacesFilter
