import { createSelector } from 'reselect'

import { buildReducer } from 'src/store/modules'
import storeDetailsReducer from './storeDetails'
import availablePSAReducer from './availablePSA'

const NAMESPACE = 'RangeAndSpace'

const selectModule = state => state.modules[NAMESPACE]
const selectAllAvailableSspsas = createSelector(
  selectModule,
  module => module?.AvailablePSA?.data || []
)

export default buildReducer(NAMESPACE, {
  ...storeDetailsReducer,
  ...availablePSAReducer,
})
export { selectAllAvailableSspsas }
