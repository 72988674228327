const buildTitle = ({ categories = [], title }) => {
  if (categories.length === 0) {
    return `${title || 'Unassigned'}`
  }

  const categoryTitles =
    categories.length >= 3 ? 'Mixed' : categories.map(category => category.title).join(', ')

  return `${title ? `${title} (${categoryTitles})` : categoryTitles}`
}

export default buildTitle
