import { createSelector } from 'reselect'

export const selectData = state => state.data
export const selectToken = createSelector(selectData, state => state.token?.mainToken)

export {
  selectSelf,
  selectSelfCategories,
  selectSelfRoleId,
  selectAllowedCategories,
} from 'src/store/modules/self'
