import Api from 'src/api/Base'

class PatchSpaceGroupById extends Api {
  requestUrl = `/api/spaceGroup/${this.getParams().spaceGroupId}`

  method = 'PATCH'

  formatRequestData = params => params.spaceGroup
}
export default PatchSpaceGroupById
