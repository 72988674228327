export default function validate({ posId, paCode, posType, description }) {
  const errors = {}
  if (!posId) {
    errors.posId = 'POS option needs to be selected'
  }
  if (posId === 'new' && !posType?.trim() && !paCode?.trim() && !description?.trim()) {
    errors.posType = 'POS type is required'
  }
  return errors
}
