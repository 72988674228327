import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Heading3, FilledButton } from '@jsluna/react'
import { Select } from '@jsluna/form'

import sortNatural from 'src/utils/sortNatural'
import { getLayoutTypeLabel } from 'src/utils/layoutTypes'

const toOption = ({ layoutType }) => ({
  id: layoutType,
  label: getLayoutTypeLabel(layoutType),
  value: layoutType,
})

const LayoutTypes = ({ layouts, createSpaceInstance, handleClose }) => {
  const layoutOptions = sortNatural(layouts.map(toOption), 'label')
  const [selectedLayout = { value: '' }] = layoutOptions
  const [layoutType, setLayoutType] = useState(selectedLayout.value)
  const handleChange = ({ target: { value } }) => setLayoutType(value)

  return (
    <div>
      <Heading3>Select a layout</Heading3>

      <div className="ln-u-push-bottom">
        <Select
          name="layoutType"
          placeholder="Please select a layout"
          options={layoutOptions}
          value={layoutType}
          onChange={handleChange}
        />
      </div>

      <div>
        <FilledButton
          data-control="modal-confirm-create-cluster"
          disabled={!layoutType}
          onClick={async () => {
            await createSpaceInstance(layoutType)

            handleClose()
          }}
        >
          Confirm
        </FilledButton>
        <FilledButton
          color="dark"
          data-control="modal-cancel-create-cluster"
          onClick={handleClose}
          className="ln-u-push-left"
        >
          {' '}
          Cancel
        </FilledButton>
      </div>
    </div>
  )
}

LayoutTypes.propTypes = {
  layouts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  createSpaceInstance: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default LayoutTypes
