import Api from 'src/api/Base'

class JdaImport extends Api {
  requestUrl = `/api/import/jda?isJda=${this.getParams().isJda}`

  method = 'POST'

  formatRequestData = params => params.formData
}
export default JdaImport
