import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import {
  Card,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
} from '@jsluna/react'

import { PALLET_AND_STACK_TYPE_OPTIONS } from 'src/constants/palletOrStackTypes'
import { ASCENDING, COLUMNS, DESCENDING, TOUCHPOINTS } from 'src/constants/shipperCompliance'

import buildShelfTitle from 'src/utils/shelfTitle'
import sortNatural from 'src/utils/sortNatural'
import {
  getFormatDate,
  getLink,
  getShipperComplianceTouchPoint,
  getTouchPointLabel,
} from 'src/utils/shipperCompliance'
import { getLayoutTypeLabel } from 'src/utils/layoutTypes'
import { formatInstances } from 'src/utils/instances'

import { hasRequiredPermission } from 'src/utils/permissions'
import { UPDATE_SHIPPER_COMPLIANCES } from 'src/constants/permissions'

import { selectSelf } from 'src/store/data/selectors'
import { selectSpaceAssignments, selectSpaceMultiSelect, selectAllSpaces } from './store/selectors'
import { updateTouchPointByShipperComplianceId } from './store/actions'

import Categories from './Categories'
import TouchPoint from './TouchPoint'
import DueDate from './DueDate'
import MultiAutoCompleteField from './MultiAutoCompleteField'

const getHfssTag = spaceAssignment => (spaceAssignment?.hfss === '1' ? 'YES' : '')

const ShipperCompliance = ({
  spaceAssignments,
  allSpaces,
  searchedSpaces,
  isReadOnly,
  updateShipperComplianceTouchPoint,
}) => {
  const toOption = ({ id, title }) => ({ id, label: title, value: id })

  const distinctSpaces = allSpaces.reduce((uniqueSpaces, space) => {
    if (!uniqueSpaces.some(obj => obj.id === space.id)) {
      uniqueSpaces.push(space)
    }
    return uniqueSpaces
  }, [])
  const sorted = sortNatural(distinctSpaces, 'title')
  const options = sorted.map(toOption)

  const [sortedBy, setSortedBy] = React.useState({
    column: 'startDate',
    direction: ASCENDING,
  })

  const sortShipperComplainceTable = sortedByValue => (a, b) => {
    const key = sortedByValue.column
    const direction = sortedByValue.direction === ASCENDING ? 1 : -1
    let valueA = a[key]
    let valueB = b[key]
    if (key === 'categories') {
      const objectA = a[key][0]
      const objectB = b[key][0]
      valueA = objectA ? objectA.title : ''
      valueB = objectB ? objectB.title : ''
    }
    if (valueA > valueB) {
      return direction
    }
    if (valueA < valueB) {
      return -direction
    }
    return 0
  }

  const spaceAssigmentDetails = () => {
    const formattedSpaceAssigmentDetails = []

    spaceAssignments.forEach(spaceAssignment => {
      const {
        title = '',
        spaceName: spaceTitle,
        startDate,
        endDate,
        shipperCompliances = [],
        spaceInstances = [],
      } = spaceAssignment
      const spaceAssignmentLink = getLink(spaceAssignment)
      const hfssTag = getHfssTag(spaceAssignment)
      shipperCompliances.forEach(shipperCompliance => {
        const { id: shipperComplianceId, shelfId, spaceInstanceId } = shipperCompliance

        const spaceInstanceDetail = spaceInstances.find(s => s.id === spaceInstanceId)
        const shelfItemDetail = spaceInstanceDetail.shelves.find(s => s.id === shelfId)

        const { shipperDescription, layoutType: spaceInstanceLayoutType } = spaceInstanceDetail
        const { categories, palletOrStackType, isRsb } = shelfItemDetail

        const autoSpaceInstance = spaceInstances.find(
          spaceInstance => spaceInstance.isAutoSpaceInstance
        )
        const instances = formatInstances([...spaceInstances], autoSpaceInstance)
        const currentInstance = instances.find(instance => instance.id === spaceInstanceId)
        const clusterGroupTitle = currentInstance.title
        const { shelves } = currentInstance
        const { position } = shelves.find(shelf => shelf.id === shelfId)
        const shelfTitle = [
          getLayoutTypeLabel(spaceInstanceLayoutType),
          buildShelfTitle(position, shelves.length, isRsb, spaceInstanceLayoutType),
        ].join(' ')
        const palletStackEnum = PALLET_AND_STACK_TYPE_OPTIONS.find(option => {
          return option.id === palletOrStackType
        })

        formattedSpaceAssigmentDetails.push({
          spaceAssignment,
          spaceAssignmentLink,
          title,
          spaceTitle,
          hfssTag,
          startDate,
          endDate,
          shipperComplianceId,
          clusterGroupTitle: clusterGroupTitle || 'N/A',
          categories,
          shelfTitle: shelfTitle || 'N/A',
          palletStackEnum: palletStackEnum ? palletStackEnum.label : 'N/A',
          shipperDescription: shipperDescription || 'N/A',
          shipperCompliance,
        })
      })
    })
    return formattedSpaceAssigmentDetails
  }

  return (
    <Card>
      <MultiAutoCompleteField options={options} defaultSelectedOptions={searchedSpaces} />
      <TableContainer responsive>
        <TableHeader>
          <TableHeaderRow>
            {Object.keys(COLUMNS).map(column => (
              <TableHeaderCell
                key={column}
                sortDirection={sortedBy.column === column ? sortedBy.direction : undefined}
                onSort={ascending =>
                  setSortedBy({
                    column,
                    direction: ascending ? DESCENDING : ASCENDING,
                  })
                }
              >
                {COLUMNS[column]}
              </TableHeaderCell>
            ))}
            {TOUCHPOINTS.map(column => (
              <TableHeaderCell
                className="c-shipper-compliance-header-cell-touchpoint"
                key={`touchpoint-header-${column}`}
              >
                {getTouchPointLabel(column)}
              </TableHeaderCell>
            ))}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {spaceAssigmentDetails()
            .sort(sortShipperComplainceTable(sortedBy))
            .map(assignment => {
              const {
                spaceAssignmentLink,
                title,
                spaceTitle,
                hfssTag,
                startDate,
                endDate,
                shipperComplianceId,
                clusterGroupTitle,
                categories,
                shelfTitle,
                palletStackEnum,
                shipperDescription,
                shipperCompliance,
              } = assignment
              const onChange = (touchpoint, state) => {
                updateShipperComplianceTouchPoint(shipperComplianceId, {
                  id: shipperComplianceId,
                  touchPoint: {
                    type: touchpoint,
                    state,
                  },
                })
              }
              const getTouchPoint = getShipperComplianceTouchPoint(shipperCompliance.touchPoint)
              return (
                <TableRow key={shipperComplianceId}>
                  <TableCell>
                    <Link to={spaceAssignmentLink} className="ln-c-table__link">
                      {title}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link to={spaceAssignmentLink} className="ln-c-table__link">
                      {spaceTitle}
                    </Link>
                  </TableCell>
                  <TableCell>{clusterGroupTitle}</TableCell>
                  <TableCell className="c-shipper-compliance-cell-hfss ln-u-font-weight-bold ln-u-color-red">
                    {hfssTag}
                  </TableCell>
                  <TableCell>
                    <Categories categories={categories} />
                  </TableCell>
                  <TableCell>{getFormatDate(startDate)}</TableCell>
                  <TableCell>{getFormatDate(endDate)}</TableCell>
                  <TableCell>{shelfTitle}</TableCell>
                  <TableCell>{palletStackEnum}</TableCell>
                  <TableCell>{shipperDescription}</TableCell>
                  {TOUCHPOINTS.map((touchpointType, i) => {
                    const touchpoint = getTouchPoint(touchpointType)
                    const prevTouchpoint = i === 0 ? null : getTouchPoint(TOUCHPOINTS[i - 1])
                    return (
                      <TableCell
                        key={`touchpoint-${touchpointType}`}
                        className="c-shipper-compliance-cell-touchpoint"
                        data-control={`touchpoint-${touchpointType}-${shipperComplianceId}`}
                      >
                        <TouchPoint
                          id={shipperComplianceId}
                          touchpoint={touchpoint}
                          prevTouchpoint={prevTouchpoint}
                          touchpointType={touchpointType}
                          isReadOnly={isReadOnly}
                          onChange={onChange}
                        />
                        <DueDate touchpoint={touchpoint} />
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
        </TableBody>
      </TableContainer>
    </Card>
  )
}

ShipperCompliance.propTypes = {
  spaceAssignments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  allSpaces: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  searchedSpaces: PropTypes.arrayOf(PropTypes.shape({})),
  isReadOnly: PropTypes.bool.isRequired,
  updateShipperComplianceTouchPoint: PropTypes.func.isRequired,
}

ShipperCompliance.defaultProps = {
  searchedSpaces: [],
}

const mapStateToProps = state => {
  const { spaceAssignments } = selectSpaceAssignments(state) || []
  const allSpaces = selectAllSpaces(state)
  const searchedSpaces = selectSpaceMultiSelect(state)
  return {
    spaceAssignments,
    allSpaces,
    searchedSpaces,
    isReadOnly: !hasRequiredPermission(selectSelf(state), UPDATE_SHIPPER_COMPLIANCES),
  }
}

const mapDispatchToProps = (dispatch, { cycleId }) => ({
  updateShipperComplianceTouchPoint: (shipperComplianceId, touchPointDetail) =>
    dispatch(updateTouchPointByShipperComplianceId(cycleId, shipperComplianceId, touchPointDetail)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShipperCompliance)
