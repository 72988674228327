import {
  BrowserAuthError,
  BrowserAuthErrorMessage,
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser'
import LoginScope from 'src/utils/loginScope'

function createLoginHandler(callback, isStoreColleagueView, instance, accounts, inProgress) {
  return function loginHandler({ location }) {
    const params = new URL(location).searchParams
    let redirect = params.get('redirect')

    if (!redirect) {
      redirect = isStoreColleagueView ? '/range-and-space' : '/space-plans'
    }

    const accessTokenRequest = {
      scopes: [LoginScope()],
      account: Array.isArray(accounts) ? accounts[0] ?? null : null,
      redirectUri: isStoreColleagueView
        ? `${location.origin}/login-store-colleague`
        : `${location.origin}/login`,
    }

    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(accessTokenResponse => {
          // Acquire token silent success
          const { accessToken } = accessTokenResponse

          callback({
            redirect,
            accessToken: { mainToken: accessToken },
          })
        })
        .catch(error => {
          if (
            error instanceof BrowserAuthError &&
            error.errorCode === BrowserAuthErrorMessage.noAccountError.code
          ) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest)
          }
        })
    }

    return null
  }
}

export default createLoginHandler
