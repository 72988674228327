import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Card, GridWrapper, GridItem } from '@jsluna/react'

import sortArray from 'src/utils/sortArray'

import { selectNominations } from 'src/modules/CyclesDetail/store'

import StatusCard from './StatusCard'

const NominationsComponent = ({ nominations, cyclesVisible, spacePlansVisible }) =>
  nominations.length ? (
    <div style={{ padding: '1rem' }}>
      <GridWrapper equalHeight matrix>
        {sortArray(nominations, 'isPriceLock', false, 'categoryTitle').map(nomination => (
          <GridItem key={nomination.id} size="1/2@sm">
            <StatusCard
              nomination={nomination}
              cycleVisible={cyclesVisible}
              spacePlanVisible={spacePlansVisible}
            />
          </GridItem>
        ))}
      </GridWrapper>
    </div>
  ) : (
    <Card>
      <GridWrapper equalHeight matrix>
        <GridItem size="1/2@sm">
          <div className="ln-c-status-card__content">
            There are no nominations currently available
          </div>
        </GridItem>
      </GridWrapper>
    </Card>
  )

const mapStateToProps = state => {
  const nominations = selectNominations(state).slice()

  return { nominations }
}

NominationsComponent.propTypes = {
  nominations: PropTypes.arrayOf(PropTypes.object),
  cyclesVisible: PropTypes.bool,
  spacePlansVisible: PropTypes.bool,
}

NominationsComponent.defaultProps = {
  nominations: {},
  cyclesVisible: false,
  spacePlansVisible: false,
}

export default connect(mapStateToProps)(NominationsComponent)

export { NominationsComponent }
