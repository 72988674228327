import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FlagWrapper, FlagBody, FlagComponent, Heading2 } from '@jsluna/react'

import { UPDATE_NOMINATIONS_TEMPLATE } from 'src/constants/permissions'
import WithRequiredPermissions from 'src/components/WithRequiredPermissions'

import { selectCyclesForNominations } from './store'
import Cycle from './Cycle'
import TemplateLink from './TemplateLink'

const Nominations = ({ cycles }) => (
  <div>
    <FlagWrapper>
      <FlagBody>
        <Heading2 className="ln-u-soft-right">Nominations</Heading2>
      </FlagBody>
      <FlagComponent alignment="top" nowrap>
        <WithRequiredPermissions permission={UPDATE_NOMINATIONS_TEMPLATE}>
          <TemplateLink />
        </WithRequiredPermissions>
      </FlagComponent>
    </FlagWrapper>
    <div>
      {cycles.length > 0 ? (
        cycles.map(cycle => <Cycle key={cycle.id} cycle={cycle} />)
      ) : (
        <div>There are no nominations to show.</div>
      )}
    </div>
  </div>
)

Nominations.propTypes = {
  cycles: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapStateToProps = state => ({
  cycles: selectCyclesForNominations(state) || [],
})

export default connect(mapStateToProps)(Nominations)
