import trimLeading from 'src/utils/trimLeading'

import { fetchAvailablePsa } from 'src/modules/RangeAndSpace/store/availablePSA'
import { fetchAdminConfigMessages } from 'src/store/modules/asyncThunks/adminConfigMessages'
import { fetchStoreDetailsByStoreNumber } from './storeDetails'

const actionGetData = storeNumber => async dispatch => {
  await Promise.all([
    dispatch(fetchAvailablePsa(trimLeading(storeNumber, '0'))),
    dispatch(fetchStoreDetailsByStoreNumber(trimLeading(storeNumber, '0'))),
    dispatch(fetchAdminConfigMessages()),
  ])
}

export default actionGetData
