import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'
import { GetCategories } from 'src/api/Category'
import { createSelector } from 'reselect'

const { createAsyncThunk, createSlice } = require('@reduxjs/toolkit')

const STORE_PATH = `modules`

const CATEGORIES_NAME = 'Categories'
const buildThunkPrefix = (slice, thunkName) => `${STORE_PATH}/${slice}/${thunkName}`

const selectSelf = state => state.modules[CATEGORIES_NAME]
const selectCategories = createSelector(selectSelf, module => module.data)
const selectActiveCategories = createSelector(selectCategories, categories =>
  categories.filter(category => category.active)
)

const fetchCategories = createAsyncThunk(
  buildThunkPrefix(CATEGORIES_NAME, 'fetchCategories'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getCategoriesInstance = new GetCategories(store)
    const response = await handleCreateAsyncThunkResult(
      getCategoriesInstance,
      dispatch,
      rejectWithValue
    )

    return response
  }
)

const categoriesSlice = createSlice({
  name: CATEGORIES_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCategories.pending, pendingDataHandler)
    builder.addCase(fetchCategories.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchCategories.rejected, rejectedDataHandler)
  },
})

export default { [CATEGORIES_NAME]: categoriesSlice.reducer }

export { fetchCategories, selectCategories, selectActiveCategories }
