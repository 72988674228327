import { buildGaps, divideGaps } from '../utils/gaps'

const buildTrackGaps = props => {
  const { assignments, blanks, startDate, endDate, cycles = [] } = props
  const assignmentsAndBlanks = [...assignments, ...blanks]
  const assignmentsAndBlanksGaps = buildGaps({ ranges: assignmentsAndBlanks, startDate, endDate })
  const cycleGaps = buildGaps({ ranges: cycles, startDate, endDate })
  const gaps = divideGaps({ ranges: [...cycleGaps, ...cycles], gaps: assignmentsAndBlanksGaps })

  return gaps.map((gap, index) => ({
    id: `index-${index}`,
    startDate: gap.startDate,
    endDate: gap.endDate,
  }))
}

export default buildTrackGaps
