import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { GridWrapper, GridItem, Checkbox } from '@jsluna/react'

import sortNatural from 'src/utils/sortNatural'

const breakPoints = {
  xs: '1/2',
  md: '1/3',
}

const groupOptions = options => {
  const groups = Object.values(
    options.reduce((out, option) => {
      const { group } = option

      if (!out[group]) {
        // eslint-disable-next-line no-param-reassign
        out[group] = { title: group, entries: [] }
      }

      out[group].entries.push(option)
      return out
    }, {})
  )

  return groups
}

const CheckboxGroup = props => {
  const {
    field: { name, value: inputValue },
    form: { errors, touched, setFieldValue },
    options,
    disabled: allDisabled,
  } = props

  const error = touched[name] && errors[name]
  const groups = groupOptions(options, 'group')
  const sortedGroups = sortNatural(groups, g => g.title)
  const handleChange = (arr, value) => event => {
    if (event.target.checked) {
      arr.push(value)
    } else {
      arr.splice(arr.indexOf(value), 1)
    }

    setFieldValue(name, arr)
  }

  return (
    <div className={classNames('ln-c-form-group', { 'has-error': !!error })}>
      {error && <p className="ln-c-field-info ln-c-field-info--error">{error}</p>}
      {sortedGroups.map(({ title, entries }) => (
        <div
          key={`group-${title}`}
          data-control={`checkbox-group-${title}`}
          className="ln-u-push-bottom"
        >
          {title && <legend className="ln-c-label ln-u-h5">{title}</legend>}
          <GridWrapper gutterSize="sm">
            {entries.map(({ label, value, id, hidden = false, disabled }) => {
              const checked = inputValue.includes(value)
              const cbId = `${name}[${id}]`

              return (
                <GridItem
                  size={breakPoints}
                  className={classNames({ 'ln-u-hidden': hidden })}
                  key={`checkbox-${id}`}
                >
                  <Checkbox
                    id={cbId}
                    name={name}
                    label={label}
                    value={value}
                    checked={checked}
                    onChange={handleChange([...inputValue], value)}
                    disabled={allDisabled || disabled}
                  />
                </GridItem>
              )
            })}
          </GridWrapper>
        </div>
      ))}
    </div>
  )
}

CheckboxGroup.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    setFieldValue: PropTypes.func,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
}

CheckboxGroup.defaultProps = {
  disabled: false,
}

export default CheckboxGroup
