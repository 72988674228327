import Api from 'src/api/Base'

class PostCategory extends Api {
  requestUrl = `/api/Category`

  method = 'POST'

  formatRequestData = params => params.category
}

export default PostCategory
