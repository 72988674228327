import React from 'react'
import PropTypes from 'prop-types'

import { ListItem, FilledButton } from '@jsluna/react'

import capitalise from 'src/utils/capitalise'

import WithPermission from 'src/components/WithPermission'

const DetailButton = ({
  buttonType,
  changeControlCategories,
  changeControlOwnerId,
  disabled,
  permission,
  setFieldValue,
  shouldDisplay,
  isOwnChangeControl,
}) => {
  let isAmend
  if (buttonType === 'amend') {
    isAmend = isOwnChangeControl ? 'Withdraw and Amend' : 'Request Amendments'
  }

  return (
    <WithPermission
      permission={permission}
      entityCategories={changeControlCategories}
      entityOwnerId={changeControlOwnerId}
      shouldDisplay={shouldDisplay}
    >
      <ListItem type="matrix">
        <FilledButton
          data-control={buttonType}
          disabled={disabled}
          name={buttonType}
          value={buttonType}
          type="submit"
          onClick={() => {
            setFieldValue('actionType', buttonType)
          }}
        >
          {isAmend || capitalise(buttonType)}
        </FilledButton>
      </ListItem>
    </WithPermission>
  )
}

DetailButton.propTypes = {
  buttonType: PropTypes.string.isRequired,
  changeControlCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  changeControlOwnerId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isOwnChangeControl: PropTypes.bool,
  permission: PropTypes.arrayOf(PropTypes.string),
  setFieldValue: PropTypes.func.isRequired,
  shouldDisplay: PropTypes.bool.isRequired,
}

DetailButton.defaultProps = {
  disabled: false,
  isOwnChangeControl: null,
  permission: [],
}

export default DetailButton
