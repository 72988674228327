import Api from 'src/api/Base'

class GetGroupTitles extends Api {
  requestUrl = `/api/Group/AvailableTitles`

  formatResponseData = response =>
    response.data.map(group => ({
      ...group,
    }))
}

export default GetGroupTitles
