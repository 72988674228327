import React from 'react'
import { Link } from 'react-router'
import hfssStatus from 'src/functions/spaceHfssStatus'

const getTitle = ({ title, jdaName }) => title || jdaName
const getLink = space => `/spaces/${space.id}`
const getSpaceTypeTitle = ({ spaceType = {} }) => spaceType.title
const getSpaceGroupTitle = ({ spaceGroup = {} }) => spaceGroup.title
const getSpaceGroupPosition = ({ spaceGroup = {} }) => spaceGroup.position
const getSpaceTypeLink = space =>
  space.spaceType ? (
    <Link to={`/spaces/types/${space.spaceType.id}`}>{space.spaceType.title}</Link>
  ) : (
    'None'
  )
const getBusinessUnit = ({ businessUnit }) => {
  if (businessUnit === 'food') return 'Food'
  return 'Non-Food'
}
const getHfssIndicator = space => hfssStatus(space.hfssFlag)

const getIsDefunct = ({ isDefunct }) => {
  if (isDefunct) return 'Yes'
  return 'No'
}

const columns = [
  {
    key: 'title',
    name: 'Title',
    value: getTitle,
    link: getLink,
  },
  { key: 'jdaId', name: 'JDA ID', value: 'jdaId' },
  { key: 'jdaName', name: 'JDA Name', value: 'jdaName' },
  {
    key: 'hfss',
    name: 'HFSS',
    value: getHfssIndicator,
  },
  {
    key: 'spaceType',
    name: 'Space Type',
    value: getSpaceTypeLink,
    sortValue: getSpaceTypeTitle,
    searchValue: getSpaceTypeTitle,
  },
  {
    key: 'spaceGroup',
    name: 'Space Group',
    value: getSpaceGroupTitle,
    sortValue: getSpaceGroupTitle,
    searchValue: getSpaceGroupTitle,
  },
  {
    key: 'spaceGroupPosition',
    name: 'Space Group Position',
    value: getSpaceGroupPosition,
    sortValue: getSpaceGroupPosition,
  },
  { key: 'refCode', name: 'Ticketing Code', value: 'refCode' },
  { key: 'businessUnit', name: 'Business Unit', value: getBusinessUnit },
  { key: 'isDefunct', name: 'Is Defunct', value: getIsDefunct, sortValue: getIsDefunct },
]

export default columns
