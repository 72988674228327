import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Search } from '@jsluna/icons'
import { SearchInput } from '@jsluna/react'

import { saveSpaceSearch } from './store/actions'

const SearchForm = props => {
  const { 'data-control': dataControl, handleFilterApply, placeholder } = props

  const applyFilterOnEnter = event => {
    const ENTER = 13
    if (event.keyCode === ENTER) handleFilterApply(event.target.value)
  }

  return (
    <SearchInput
      name={dataControl}
      placeholder={placeholder}
      hasIcon
      icon={<Search />}
      onKeyDown={applyFilterOnEnter}
      data-control={dataControl}
    />
  )
}

const mapDispatchToProps = dispatch => ({
  handleFilterApply: value => {
    dispatch(saveSpaceSearch(value))
  },
})

SearchForm.defaultProps = {
  'data-control': '',
  placeholder: '',
}

SearchForm.propTypes = {
  'data-control': PropTypes.string,
  handleFilterApply: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default connect(null, mapDispatchToProps)(SearchForm)
