import { connect } from 'react-redux'

import { fetchCategories, selectCategories } from 'src/store/modules/asyncThunks/categories'
import loader from 'src/hoc/loader'

import CategoriesSelect from './CategoriesSelect'

const getData = async (props, dispatch) => {
  await dispatch(fetchCategories())
}

const options = {
  shouldRefresh: (previous, current) => previous.shelfId !== current.shelfId,
}

const mapStateToProps = state => ({
  categories: selectCategories(state),
})

const ConnectedComponent = connect(mapStateToProps)(CategoriesSelect)

export default loader(getData, options)(ConnectedComponent)
