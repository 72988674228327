import React from 'react'
import PropTypes from 'prop-types'
import { Field, withFormik } from 'formik'
import { Card, Heading2, Form, FormGroup, GridWrapper, GridItem, Section } from '@jsluna/react'
import { SelectField, TextInputField } from '@jsluna/form/extensions/formik'

import { Controls, LabelledText } from 'src/components/Form'

import { Checkbox } from 'src/components/FormikComponents'
import handleSubmit from './handleSubmit'
import validate from './validate'

const businessUnitOptions = [
  {
    label: 'Food',
    value: 'food',
  },
  {
    label: 'Non-Food',
    value: 'non-food',
  },
]
const Component = ({
  cancel,
  handleSubmit: onSubmit,
  isSubmitting,
  jdaId,
  jdaName,
  spaceTypeOptions,
  spaceGroupOptions,
  disableHfssButton,
}) => (
  <Form onSubmit={onSubmit}>
    <Heading2>
      <span data-control="space-title">Edit Space</span>
    </Heading2>
    <Section>
      <Card>
        <FormGroup name="space-fields">
          <GridWrapper matrix>
            <GridItem size="1/1">
              <LabelledText label="JDA ID" text={jdaId} />
            </GridItem>
            <GridItem size="1/1">
              <LabelledText label="JDA Name" text={jdaName} />
            </GridItem>
            <GridItem size="1/2@sm">
              <Field label="Title" name="title" component={TextInputField} />
            </GridItem>
            <GridItem size="1/2@sm">
              <Field
                label="Business unit"
                name="businessUnit"
                component={SelectField}
                options={businessUnitOptions}
              />
            </GridItem>
            <GridItem size="1/4@sm">
              <Field
                label="Type"
                name="spaceType"
                component={SelectField}
                options={spaceTypeOptions}
              />
            </GridItem>
            <GridItem size="1/4@sm">
              <Field
                label="Group"
                name="spaceGroup"
                component={SelectField}
                options={spaceGroupOptions}
              />
            </GridItem>
            <GridItem size="1/2@sm">
              <Field label="Ticketing reference code" name="refCode" component={TextInputField} />
            </GridItem>
            <GridItem size="1/4@sm">
              <div className="ln-c-form-group">
                <Field component={Checkbox} label="Defunct" name="isDefunct" />
              </div>
            </GridItem>
            <GridItem size="1/4@sm">
              <div className="ln-c-form-group">
                <Field
                  component={Checkbox}
                  label="HFSS Restricted"
                  name="isHfss"
                  disabled={disableHfssButton}
                />
              </div>
            </GridItem>
          </GridWrapper>
        </FormGroup>
        <Controls submitText="Save" cancel={cancel} disabled={isSubmitting} />
      </Card>
    </Section>
  </Form>
)

Component.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  jdaId: PropTypes.string.isRequired,
  jdaName: PropTypes.string.isRequired,
  spaceTypeOptions: PropTypes.arrayOf(PropTypes.shape({})),
  spaceGroupOptions: PropTypes.arrayOf(PropTypes.shape({})),
  disableHfssButton: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
}

Component.defaultProps = {
  spaceTypeOptions: [],
  spaceGroupOptions: [],
}

const wrappedForm = withFormik({ handleSubmit, validate })(Component)

export default wrappedForm
