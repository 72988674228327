import { connect } from 'react-redux'

import { createNotification } from 'src/store/notifications/actions'

import { selectSelf } from 'src/store/data/selectors'
import {
  actionCreateSpaceInstance,
  actionUpdateSpaceInstance,
} from '../../store/reducer/spaceInstances'

import Form from './Form'

import { refreshSpaceInstances } from '../../store/actions'

const mapStateToProps = (state, { spaceInstance }) => {
  const user = selectSelf(state)
  const {
    id,
    comment,
    layoutType,
    posComment,
    shelfHeights = '',
    shipperDescription = '',
    suggestedLocation = '',
    overrideStartDate = '',
    overrideEndDate = '',
  } = spaceInstance

  return {
    id,
    comment,
    layoutType,
    posComment,
    shelfHeights,
    shipperDescription,
    suggestedLocation,
    overrideStartDate,
    overrideEndDate,
    user,
  }
}

const mapDispatchToProps = (dispatch, { spaceAssignmentId }) => ({
  submit: async (spaceInstance, id) => {
    const action = id
      ? actionUpdateSpaceInstance({ id, spaceInstance })
      : actionCreateSpaceInstance(spaceInstance)
    await dispatch(action)
    await dispatch(refreshSpaceInstances(spaceAssignmentId))
    dispatch(createNotification('Space instance saved'))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
