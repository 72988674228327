import { createError } from 'src/store/notifications/actions'
import { LOADING } from 'src/constants/api'
import { createAction } from '@reduxjs/toolkit'

const buildThunkPrefix = (slice, STORE_PATH) => `${STORE_PATH}/${slice}`

const handleCreateAsyncThunkResult = async (
  fn,
  dispatch,
  rejectWithValue,
  responseWithoutHeader = true
) => {
  try {
    dispatch({ type: LOADING, payload: true })
    const response = await fn.call(dispatch)
    return responseWithoutHeader && response.data ? response.data : response
  } catch (error) {
    const errorList =
      (Array.isArray(error.response?.data?.errors) && error.response?.data?.errors.join(', ')) ||
      (typeof error.response?.data === 'string' && error.response?.data)
    dispatch(createError(error.response?.data?.detail || errorList || error.message))
    return rejectWithValue(error.response)
  } finally {
    dispatch({ type: LOADING, payload: false })
  }
}

const initialState = {
  data: null,
  isFetching: false,
  error: null,
}

const pendingDataHandler = state => {
  if (!state.isFetching) {
    return {
      ...state,
      isFetching: true,
      error: null,
    }
  }
  return { ...state }
}

const fullFilledDataHandler = (state, action) => {
  if (state.isFetching) {
    return {
      ...state,
      isFetching: false,
      data: action.payload,
      error: null,
    }
  }
  return { ...state }
}

const rejectedDataHandler = (state, action) => {
  if (state.isFetching) {
    return {
      ...state,
      isFetching: false,
      error: action.payload,
      data: null,
    }
  }
  return { ...state }
}

const clearDataHandler = () => ({
  ...initialState,
})

const actionClearDataHandler = nameSpace => {
  const STORE_PATH = `modules/${nameSpace}`
  return createAction(`${STORE_PATH}/CLEAR_DATA`)
}

export {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  clearDataHandler,
  initialState,
  buildThunkPrefix,
  actionClearDataHandler,
}
