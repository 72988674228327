import { GetSpacePlanClustersAssignment } from 'src/api/SpacePlan'
import { handleCreateAsyncThunkResult } from 'src/utils/createAsyncThunkHandler'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { createSpaceDetailWithCluter } from './spacePlanAssignments'

const NAMESPACE = 'SpacePlanClusterAssignments'

const fetchSpacePlanClustersAssignment = createAsyncThunk(
  NAMESPACE,
  async ({ spacePlanId, spaceId }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getClustersAssignmentInstance = new GetSpacePlanClustersAssignment(store, {
      params: { spacePlanId, spaceId },
    })

    const response = await handleCreateAsyncThunkResult(
      getClustersAssignmentInstance,
      dispatch,
      rejectWithValue
    )

    dispatch(createSpaceDetailWithCluter(response))
  }
)

export default fetchSpacePlanClustersAssignment
