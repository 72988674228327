import React from 'react'
import PropTypes from 'prop-types'
import { RadioButtonField, CheckboxField } from '@jsluna/react'

const Clustering = ({
  field: { name, value: selectedClusters },
  form: { setFieldValue },
  clusters,
  disabled,
}) => {
  const strategyOptions = [
    { value: 'all', label: 'All Clusters in Group' },
    { value: 'specific', label: 'Specific Clusters' },
  ]
  const clusterStrategy = selectedClusters.length === 0 ? 'all' : 'specific'
  const groupOptions = clusters.map(({ id, title }) => ({
    id,
    value: id,
    label: title,
    checked: selectedClusters.includes(id),
  }))
  const changeStrategy = arr => ({ target: { value } }) => {
    if (value === 'specific') {
      setFieldValue(
        name,
        arr.map(({ id }) => id)
      )
    } else {
      setFieldValue(name, [])
    }
  }
  const setClusters = arr => ({ target: { value } }) => {
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    } else {
      arr.push(value)
    }

    setFieldValue(name, arr)
  }

  return (
    <div>
      <RadioButtonField
        name="clusteringStrategy"
        label="Clustering Strategy"
        options={strategyOptions}
        value={clusterStrategy}
        onChange={changeStrategy(clusters)}
        disabled={disabled}
        hideLabel
      />

      <CheckboxField
        disabled={disabled || selectedClusters.length === 0}
        name="clusteringOptions"
        fullWidth
        options={groupOptions}
        onChange={setClusters(selectedClusters)}
      />
    </div>
  )
}

Clustering.propTypes = {
  clusters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
}

Clustering.defaultProps = {
  disabled: false,
}

export default Clustering
