import { connect } from 'react-redux'
import { withFormik } from 'formik'

import SpaceFilter from './SpaceFilter'
import { saveSpaceFilter } from '../store/actions'
import mapStateToProps from './mapStateToProps'

const mapDispatchToProps = (dispatch, { handleClose }) => ({
  submit: spaces => {
    dispatch(saveSpaceFilter(spaces))
    handleClose()
  },
})

const wrappedForm = withFormik({
  handleSubmit: ({ spaces }, { props: { submit } }) => {
    submit(spaces)
  },
})(SpaceFilter)

export default connect(mapStateToProps, mapDispatchToProps)(wrappedForm)
