import { STORAGE } from 'src/constants/spaceTypes'

const isPositiveInteger = str => {
  const number = Math.floor(Number(str))
  return !Number.isNaN(parseFloat(number)) && Number.isFinite(number) && number >= 0
}

const isValidStorage = value => STORAGE.some(({ id }) => value === id)
const isEmpty = str => !str || str.trim().length === 0
const getLayout = (type, layouts) =>
  layouts.find(({ name, layoutType }) => name === type || layoutType === type)

const isValidCapacity = allLayouts => (layoutType, capacity) => {
  const { hasCapacity } = getLayout(layoutType, allLayouts)

  return !isPositiveInteger(capacity) || (hasCapacity && isEmpty(capacity))
}

function validate(values) {
  const errors = {}
  const { title, storage, shelvesDefault, shelvesMin, shelvesMax, layouts, allLayouts } = values

  if (isEmpty(title)) {
    errors.title = 'Title cannot be empty'
  }

  if (!isValidStorage(storage)) {
    errors.storage = 'Storage must be selected'
  }

  if (!isPositiveInteger(shelvesDefault)) {
    errors.shelvesDefault = 'Default Shelves must be a number'
  }

  if (!isPositiveInteger(shelvesMin)) {
    errors.shelvesMin = 'Min Shelves must be a number'
  }

  if (!isPositiveInteger(shelvesMax)) {
    errors.shelvesMax = 'Max Shelves must be a number'
  }

  if (layouts.length) {
    const isValid = isValidCapacity(allLayouts)

    layouts.forEach((layout, index) => {
      if (isValid(layout.layoutType, layout.defaultCapacity)) {
        errors[`layouts[${index}].defaultCapacity`] = 'Default Capacity must be a number'
      }

      if (isValid(layout.layoutType, layout.minCapacity)) {
        errors[`layouts[${index}].minCapacity`] = 'Min Capacity must be a number'
      }

      if (isValid(layout.layoutType, layout.maxCapacity)) {
        errors[`layouts[${index}].maxCapacity`] = 'Max Capacity must be a number'
      }
    })
  }

  return errors
}

export default validate
