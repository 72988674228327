import React from 'react'
import PropTypes from 'prop-types'

import Products from './Products'

const ShelfProducts = ({ shelf, weeks }) => {
  const {
    shelfProducts: products = [],
    shelfArtifacts: artifacts = [],
    removedProducts = [],
  } = shelf

  return products.length > 0 || removedProducts.length > 0 || artifacts.length > 0 ? (
    <Products shelf={shelf} weeks={weeks} />
  ) : (
    <div
      className="ln-u-soft-sides-sm ln-u-padding-ends*1/2 ln-u-border-bottom"
      data-control="shelf-assigned-products"
    >
      No products
    </div>
  )
}

ShelfProducts.propTypes = {
  shelf: PropTypes.shape({
    shelfArtifacts: PropTypes.arrayOf(
      PropTypes.shape({
        artifactType: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
        description: PropTypes.string,
      })
    ),
    shelfProducts: PropTypes.arrayOf(
      PropTypes.shape({
        product: PropTypes.shape({
          sku: PropTypes.string,
        }),
      })
    ).isRequired,
    removedProducts: PropTypes.arrayOf(
      PropTypes.shape({
        sku: PropTypes.string,
      })
    ),
  }).isRequired,
  weeks: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default ShelfProducts
