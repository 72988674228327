import Api from '../Base'

class PatchShelf extends Api {
  requestUrl = `/api/shelf/${this.getParams().id}`

  method = 'PATCH'

  formatRequestData = params => params.shelf
}

export default PatchShelf
