import { SAVE_SPACE_FILTER } from '../../actions'

const initialState = {
  excludeSpaceWithNoMatchingCategories: false,
  excludeSpaceWithFullCoverage: false,
  businessUnitFilter: 'all',
}

const reducer = (state = initialState, { type, payload }) => {
  if (type === SAVE_SPACE_FILTER) {
    return { ...initialState, ...payload }
  }

  return state
}

export default reducer
