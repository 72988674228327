import React from 'react'
import { Heading2 } from '@jsluna/react'

import Section from 'src/components/Section'

const ADMIN_EMAIL = 'promotionevents.team@sainsburys.co.uk'

const PermissionDenied = () => (
  <Section>
    <Heading2>Permission denied</Heading2>
    <p>Sorry, you do not have permission to view this page.</p>
    <p>
      Please contact the administrator if you believe you should have access:&nbsp;
      <strong>{ADMIN_EMAIL}</strong>
    </p>
  </Section>
)

export default PermissionDenied
