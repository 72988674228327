import { createSelector } from 'reselect'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PostCategory, GetCategoryById, UpdateCategoryById } from 'src/api/Category'
import {
  buildThunkPrefix,
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  clearDataHandler,
  initialState,
  actionClearDataHandler,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'CategoryDetail'

const selectModules = state => state.modules.CategoryDetail
const selectCategoryByIdInfo = createSelector(selectModules, module => module.data)

const actionClearData = actionClearDataHandler(NAMESPACE)

const actionCreateCategory = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'actionCreateCategory'),
  async (category, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const postCategoryInstance = new PostCategory(store, {
      params: { category },
    })

    const response = await handleCreateAsyncThunkResult(
      postCategoryInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionUpdateCategoryById = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'actionUpdateCategoryById'),
  async ({ categoryId, category }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()

    const patchCategoryInstance = new UpdateCategoryById(store, {
      params: { categoryId, category },
    })

    const response = await handleCreateAsyncThunkResult(
      patchCategoryInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchCategoryById = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'fetchCategoryById'),
  async (id, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const getCategoryInstance = new GetCategoryById(store, {
      params: { id },
    })
    const response = await handleCreateAsyncThunkResult(
      getCategoryInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const categoryByIdSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCategoryById.pending, pendingDataHandler)
    builder.addCase(fetchCategoryById.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchCategoryById.rejected, rejectedDataHandler)
    builder.addCase(actionClearData, clearDataHandler)
  },
})

export {
  selectCategoryByIdInfo,
  actionClearData,
  actionCreateCategory,
  actionUpdateCategoryById,
  fetchCategoryById,
}

export default { [NAMESPACE]: categoryByIdSlice.reducer }
