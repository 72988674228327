import {
  SHIPPER_LOCK_ALL_IDS,
  SHIPPER_LOCK_UNLOCKED_ID,
  SHIPPER_LOCK_LOCKED_ID,
} from 'src/constants/shipperCompliance'

import createStatus from 'src/utils/statuses'

const SHIPPER_LOCK_UNLOCKED = {
  id: SHIPPER_LOCK_UNLOCKED_ID,
  title: 'Unlocked',
  transition: [SHIPPER_LOCK_UNLOCKED_ID, SHIPPER_LOCK_LOCKED_ID],
}

const SHIPPER_LOCK_LOCKED = {
  id: SHIPPER_LOCK_LOCKED_ID,
  title: 'Locked',
  transition: [SHIPPER_LOCK_LOCKED_ID, SHIPPER_LOCK_UNLOCKED_ID],
}

export const SHIPPER_LOCK_CONTROL_ALL_BY_ID = {
  [SHIPPER_LOCK_LOCKED_ID]: SHIPPER_LOCK_LOCKED,
  [SHIPPER_LOCK_UNLOCKED_ID]: SHIPPER_LOCK_UNLOCKED,
}

export const SHIPPER_LOCK_ALL = SHIPPER_LOCK_ALL_IDS.map(id => SHIPPER_LOCK_CONTROL_ALL_BY_ID[id])

export default createStatus(SHIPPER_LOCK_ALL)
