import React from 'react'
import { GridWrapper, GridItem, Checkbox } from '@jsluna/react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const breakPoints = {
  xs: '1/2',
  md: '1/3',
}

const ReadOnlySelectedSpace = ({ selectedSpaces }) => {
  return (
    <>
      <div className="ln-c-label ln-u-soft-right">Spaces</div>
      <GridWrapper gutterSize="sm">
        {selectedSpaces.map(({ title, value, id, hidden = false }) => {
          return (
            <GridItem
              size={breakPoints}
              className={classNames({ 'ln-u-hidden': hidden })}
              key={`checkbox-${id}`}
            >
              <Checkbox id={id} name={title} label={title} value={value} checked disabled />
            </GridItem>
          )
        })}
      </GridWrapper>
    </>
  )
}

ReadOnlySelectedSpace.propTypes = {
  selectedSpaces: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
}
ReadOnlySelectedSpace.defaultProps = {
  selectedSpaces: [],
}
export default ReadOnlySelectedSpace
