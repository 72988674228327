import React from 'react'
import { Route, IndexRoute } from 'react-router'
import { authorized } from 'src/hoc/auth'
import Categories from 'src/modules/Categories'
import CategoryDetail from 'src/modules/CategoryDetails'

const route = (
  <Route name="Categories" path="categories" showInNav>
    <IndexRoute component={authorized(Categories)} />
    <Route path="create" component={authorized(CategoryDetail)}>
      <IndexRoute component={authorized(CategoryDetail)} />
    </Route>
    <Route path=":id" component={authorized(CategoryDetail)} />
  </Route>
)

export default route
