import Api from 'src/api/Base'

class UpdateChangeControl extends Api {
  requestUrl = `/api/ChangeControl/${this.getParams().id}`

  method = 'PATCH'

  formatRequestData = params => params.payload
}

export default UpdateChangeControl
