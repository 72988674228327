import { combineReducers } from 'redux'
import { routerReducer as routing } from 'react-router-redux'

import api from './api/reducer'
import config from './config/reducer'
import data from './data/reducer'
import notifications from './notifications/reducer'
import ui from './ui/reducer'

import { MODULES_BASE } from './modules'
import modulesReducer from './modules/reducer'

export default () =>
  combineReducers({
    api,
    config,
    data,
    notifications,
    routing,
    ui,
    [MODULES_BASE]: modulesReducer,
  })

export const selectApi = state => state.api && state.api.data
export const selectConfig = state => state.config
export const selectData = state => state.data
export const selectUi = state => state.ui
