import Api from '../Base'

class PostSpacePlan extends Api {
  requestUrl = `/api/SpacePlan`

  method = 'POST'

  formatRequestData = params => params.spacePlan
}

export default PostSpacePlan
