import React from 'react'
import PropTypes from 'prop-types'
import { Field, withFormik } from 'formik'
import { Card, Heading2, Form, FormGroup, GridWrapper, GridItem, Section } from '@jsluna/react'
import { TextInputField } from '@jsluna/form/extensions/formik'

import { Controls } from 'src/components/Form'

import handleSubmit from './handleSubmit'
import validate from './validate'

const Component = ({
  handleSubmit: onSubmit,
  isSubmitting,
  values: { id, originalTitle },
  cancel,
}) => (
  <Form onSubmit={onSubmit}>
    <Section>
      <Heading2>
        <span data-control="space-group-title">
          {id ? `Edit Space Group ${originalTitle}` : 'Create Space Group'}
        </span>
      </Heading2>
      <Card>
        <FormGroup name="space-group-fields">
          <GridWrapper matrix>
            <GridItem size="1/2@sm">
              <Field
                component={TextInputField}
                label="Position"
                name="position"
                validationFirst={false}
                required
              />
            </GridItem>

            <GridItem size="1/2@sm">
              <Field
                component={TextInputField}
                label="Title"
                name="title"
                validationFirst={false}
                required
              />
            </GridItem>

            <GridItem size="">
              <Field
                component={TextInputField}
                label="Description"
                name="description"
                validationFirst={false}
                required
              />
            </GridItem>
          </GridWrapper>
        </FormGroup>
        <Controls cancel={cancel} disabled={isSubmitting} submitText="Save" />
      </Card>
    </Section>
  </Form>
)

Component.propTypes = {
  cancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    id: PropTypes.string,
    originalTitle: PropTypes.string,
  }).isRequired,
}

const wrappedForm = withFormik({ handleSubmit, validate })(Component)

export default wrappedForm
