import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Heading2 } from '@jsluna/react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
    this.prevPath = null
  }

  componentDidMount() {
    this.prevPath = window.location.pathname
  }

  componentDidUpdate() {
    const { hasError } = this.state
    if (hasError && window.location.pathname !== this.prevPath) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasError: false })
    }
    this.prevPath = window.location.pathname
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
      error: error.message || 'Unknown Error',
    })
  }

  render() {
    const { hasError, error } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <div>
          <Heading2>Something went wrong.</Heading2>
          <p>An error occurred. Please report this to the engineering team.</p>
          <pre style={{ overflow: 'auto' }}>{`"${error}"`}</pre>
        </div>
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ErrorBoundary
