import React from 'react'
import PropTypes from 'prop-types'
import { Field, withFormik } from 'formik'

import { Card, Form, FormGroup, GridWrapper, GridItem, Heading2 } from '@jsluna/react'
import { SelectField } from '@jsluna/form/extensions/formik'

import sortArray from 'src/utils/sortArray'

import { NO_ACCESS_ROLE_ID, UNASSIGNED_ROLE_ID } from 'src/constants/roles'

import { Controls } from 'src/components/Form'
import { CheckboxGroup } from 'src/components/FormikComponents'

import handleSubmit from './handleSubmit'

const UnassignedForm = ({ handleSubmit: onSubmit, activeCategories, roles }) => {
  const toOption = ({ id, title }) => ({ id, label: title, value: id })
  const categoryOptions = sortArray(activeCategories, 'isPriceLock', false, 'title').map(toOption)
  const roleOptions = sortArray(roles, 'title')
    .map(toOption)
    .filter(({ id }) => id !== UNASSIGNED_ROLE_ID && id !== NO_ACCESS_ROLE_ID)

  return (
    <Card>
      <Heading2 data-control="unassigned-title">No role assigned</Heading2>
      <p>
        You have been registered but are awaiting role assignment, or have had your role removed by
        the administrator.
      </p>
      <p>Please complete the below form to request a role.</p>
      <Form onSubmit={onSubmit}>
        <FormGroup name="unassigned">
          <GridWrapper matrix>
            <GridItem size="1/1">
              <CheckboxGroup label="Categories" name="categories" options={categoryOptions} />
            </GridItem>
            <GridItem size="1/2@sm">
              <Field name="role" label="Role" component={SelectField} options={roleOptions} />
            </GridItem>
          </GridWrapper>
        </FormGroup>
        <Controls submitText="Request Access" />
      </Form>
    </Card>
  )
}

UnassignedForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  activeCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
}

UnassignedForm.defaultProps = {
  activeCategories: [],
  roles: [],
}

const wrappedForm = withFormik({ handleSubmit })(UnassignedForm)

export default wrappedForm
