import { combineReducers } from 'redux'

import filter from './filter'
import isOpen from './isOpen'
import search from './search'
import multiselect from './multiselect'
import spaceFilterType from './spaceFilterType'
import tracks from './tracks'
import zoom from './zoom'

export default combineReducers({
  filter,
  isOpen,
  search,
  multiselect,
  spaceFilterType,
  tracks,
  zoom,
})
