import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { actionUpdateCycle, actionCreateCycle, fetchLatestCycleByGroup } from '../../store'

import Form from './Form'
import mapStateToProps from './mapStateToProps'

const returnAction = businessUnit => push(`/cycles/${businessUnit}`)

const mapDispatchToProps = (dispatch, { businessUnit }) => ({
  submit: async (cycle, id) => {
    const action = id ? actionUpdateCycle({ id, cycle }) : actionCreateCycle(cycle)
    await dispatch(action)
    dispatch(returnAction(businessUnit))
  },
  onChange: async groupId => {
    await dispatch(fetchLatestCycleByGroup({ businessUnit, groupId })).unwrap()
  },
  cancel: () => dispatch(returnAction(businessUnit)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
