import Api from 'src/api/Base'

class GetSpacePlanCyclesAssignments extends Api {
  requestUrl = this.getParams().groupId
    ? `/api/spaceplan/${this.getParams().spacePlanId}/CycleSpaceAssignments?groupId=${
        this.getParams().groupId
      }`
    : `/api/spaceplan/${this.getParams().spacePlanId}/CycleSpaceAssignments`
}

export default GetSpacePlanCyclesAssignments
