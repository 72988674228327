import React from 'react'
import PropTypes from 'prop-types'
import ShelfArtifact from 'src/components/ShelfArtifacts'
import { combineAndSortShelfItems } from 'src/utils/shelfItems'
import { hasChange } from 'src/utils/changeControls'
import { getDifferences } from 'src/utils/difference'

import { getSpecification } from 'src/specification'
import ShelfProduct from './ShelfProduct'

const getProductsToDisplay = (changeControl, shelf) => {
  const removedProducts = shelf.removedProducts.length ? shelf.removedProducts : []
  const combinedProducts = shelf.shelfProducts.concat(removedProducts)

  return combinedProducts.map(product => {
    return {
      ...product,
      differences:
        hasChange(changeControl)(product) && product.changeType === 'modify'
          ? getDifferences(product.supersedes, product, getSpecification('shelves-products'))
          : [],
    }
  })
}

const Shelf = ({ changeControl, shelf }) => {
  const { shelfArtifacts } = shelf
  const productsToDisplay = getProductsToDisplay(changeControl, shelf)
  const shelfItemsToDisplay = combineAndSortShelfItems(productsToDisplay, shelfArtifacts)
  return shelfItemsToDisplay.map(shelfItem =>
    shelfItem.type === 'shelf-product' ? (
      <ShelfProduct
        key={shelfItem.id}
        shelfItem={shelfItem}
        shelf={shelf}
        changeControl={changeControl}
      />
    ) : (
      <ShelfArtifact
        key={shelfItem.id}
        columnSpan={7}
        artifact={shelfItem}
        shelfTitle={shelf.title}
        shelfPosition={shelf.position}
      />
    )
  )
}

Shelf.propTypes = {
  changeControl: PropTypes.shape({}),
  shelf: PropTypes.shape({}).isRequired,
  shelfProducts: PropTypes.arrayOf(PropTypes.string),
  shelfArtifacts: PropTypes.arrayOf(PropTypes.string),
}

Shelf.defaultProps = {
  changeControl: null,
}

export default Shelf
