import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Info } from '@jsluna/icons'
import Tooltip from 'src/components/Tooltip'

const InfoBadge = ({ className, message }) => (
  <div className={classNames('c-info-badge', className)}>
    <Info className="c-info-badge__icon" viewBox="-5 -3 34 34" />
    <Tooltip message={message} />
  </div>
)

InfoBadge.propTypes = {
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string,
}

InfoBadge.defaultProps = {
  className: 'ln-u-bg-color-plum',
}

export default InfoBadge
