import { CHANGE_CONTROL_FILTER } from '../actions'

const initialState = {
  category: '',
  changeControlType: '',
  cluster: '',
  reasonCode: '',
  space: '',
  status: '',
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_CONTROL_FILTER: {
      return {
        ...state,
        [payload.filterType]: payload.value,
      }
    }

    default:
      return state
  }
}

export default reducer
