import {
  FROZEN_LAYOUT_TYPE,
  FROZEN_FULL_HEIGHT_LAYOUT_TYPE,
  FROZEN_HALF_HEIGHT_LAYOUT_TYPE,
  PRODUCE_LAYOUT_TYPE,
  PRODUCE_3X4_LAYOUT_TYPE,
  PRODUCE_4X4_LAYOUT_TYPE,
  WELL_LAYOUT_TYPE,
} from 'src/constants/layoutTypes'

import FrozenLayout from './FrozenLayout'
import ProduceLayout from './ProduceLayout'
import ShelfLayout from './ShelfLayout'
import WellLayout from './WellLayout'

const layoutTypeToComponentMapping = {
  [FROZEN_LAYOUT_TYPE]: FrozenLayout,
  [FROZEN_FULL_HEIGHT_LAYOUT_TYPE]: FrozenLayout,
  [FROZEN_HALF_HEIGHT_LAYOUT_TYPE]: FrozenLayout,
  [PRODUCE_LAYOUT_TYPE]: ProduceLayout,
  [PRODUCE_3X4_LAYOUT_TYPE]: ProduceLayout,
  [PRODUCE_4X4_LAYOUT_TYPE]: ProduceLayout,
  [WELL_LAYOUT_TYPE]: WellLayout,
}

export default function getComponentForLayoutType(layoutType) {
  const Component = layoutTypeToComponentMapping[layoutType]
  return Component || ShelfLayout
}
