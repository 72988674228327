import { SAVE_SPACE_SEARCH } from '../actions'

const initialState = ''

const reducer = (state = initialState, { type, payload }) => {
  if (type === SAVE_SPACE_SEARCH) return payload && payload.trim()

  return state
}

export default reducer
