import loader from 'src/hoc/loader'
import { actionClearData, fetchSpaceGroups } from './store'
import SpaceGroups from './SpaceGroups'

const getData = async (_, dispatch) => {
  await dispatch(fetchSpaceGroups())
}

const options = {
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(SpaceGroups)
