import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Card, Heading3 } from '@jsluna/react'
import { goBack, push } from 'react-router-redux'

import { createNotification } from 'src/store/notifications/actions'

import Section from 'src/components/Section'

import mapStateToProps from './mapStateToProps'
import Form from './Form'
import ReadOnlyDetails from './ReadOnly'
import {
  actionDeleteSpaceAssignment,
  actionCreateSpaceAssignment,
  actionUpdateSpaceAssignmentById,
  fetchSpacePlanAssignment,
} from '../store/reducer/spacePlanAssignment'

const returnAction = rt => {
  if (rt) {
    return push(rt)
  }

  return push('/space-plans/')
}

const mapDispatchToProps = (dispatch, { query }) => ({
  submit: async (assignment, id) => {
    const action = id
      ? actionUpdateSpaceAssignmentById({ id, assignment })
      : actionCreateSpaceAssignment(assignment)
    await dispatch(action).unwrap()
    if (id) {
      dispatch(createNotification('Space assignment saved'))
      dispatch(fetchSpacePlanAssignment({ id }))
    } else {
      dispatch(goBack())
    }
  },
  deleteSpaceAssignment: async id => {
    const { rt = '' } = query
    await dispatch(actionDeleteSpaceAssignment(id))

    dispatch(returnAction(rt))
  },
})

const Details = ({ isReadOnly, ...props }) => (
  <Section>
    <Heading3>Details</Heading3>
    <Card>{isReadOnly ? <ReadOnlyDetails {...props} /> : <Form {...props} />}</Card>
  </Section>
)

Details.propTypes = {
  isReadOnly: PropTypes.bool,
}

Details.defaultProps = {
  isReadOnly: true,
}

export default connect(mapStateToProps, mapDispatchToProps)(Details)
