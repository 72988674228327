import React from 'react'
import PropTypes from 'prop-types'

const Marker = ({ x, modifier, children, visible, highlighted }) => (
  <div
    className={`pt-marker pt-marker--${modifier} ${visible ? 'pt-is-visible' : ''} ${
      highlighted ? 'pt-is-highlighted' : ''
    }`}
    style={{ left: `${x}px` }}
  >
    <div className="pt-marker__label">
      <div className="pt-marker__content">{children}</div>
    </div>
  </div>
)

Marker.propTypes = {
  x: PropTypes.number.isRequired,
  modifier: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  highlighted: PropTypes.bool,
  children: PropTypes.node,
}

Marker.defaultProps = {
  visible: false,
  highlighted: false,
  children: undefined,
}

export default Marker
