import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  GridWrapper,
  GridItem,
  FlagWrapper,
  FlagBody,
  FlagComponent,
  ProgressSpinner,
  TextInputField,
  FilledButton,
} from '@jsluna/react'

class SearchBox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      query: props.input.value || '',
      searching: false,
      hasSearched: false,
    }

    this.onChange = this.onChange.bind(this)
    this.search = this.search.bind(this)
    this.onSelect = this.onSelect.bind(this)
  }

  onChange(event) {
    const { value } = event.target
    this.setState({ query: value })
  }

  onSelect(item) {
    const {
      input: { onSelect },
      clearResults,
    } = this.props

    onSelect(item)
    clearResults()
  }

  async search(event) {
    event.preventDefault()

    const { getResults } = this.props
    const { query } = this.state

    this.setState({ searching: true })

    await getResults(query)

    this.setState({
      searching: false,
      hasSearched: true,
    })
  }

  render() {
    const { query, searching, hasSearched } = this.state

    const {
      input: { name, label, hideLabel = false, disabled, onSelect, placeholder = '' },
      meta: { error, touched },
      results,
      resultFormatter,
      limit,
    } = this.props

    const slicedResults = limit >= 0 ? results.slice(0, limit) : results
    const hasResults = slicedResults.length > 0

    const renderResult = result => (
      <GridItem key={result.id} size="1/3@sm">
        <div data-control={`search-result-${name}`} data-id={result.id}>
          {onSelect ? (
            <FilledButton color="dark" onClick={() => this.onSelect(result)}>
              {resultFormatter(result)}
            </FilledButton>
          ) : (
            resultFormatter(result)
          )}
        </div>
      </GridItem>
    )

    return (
      <div>
        <form onSubmit={this.search} data-control={`search-form-${name}`}>
          <FlagWrapper className="ln-u-push-bottom">
            <FlagBody alignment="bottom">
              <TextInputField
                label={label}
                hideLabel={hideLabel}
                name={name}
                value={query}
                onChange={this.onChange}
                className="ln-u-flush-bottom"
                error={touched ? error : null}
                validationFirst
                disabled={disabled}
                placeholder={placeholder}
              />
            </FlagBody>
            <FlagComponent alignment="bottom">
              <div data-control={`search-button-${name}`}>
                <FilledButton
                  color="dark"
                  type="submit"
                  className="ln-u-push-left c-search-button"
                  disabled={disabled}
                >
                  {searching ? (
                    <ProgressSpinner className="u-flush-right" color="light" size="small" />
                  ) : (
                    'Search'
                  )}
                </FilledButton>
              </div>
            </FlagComponent>
          </FlagWrapper>
        </form>
        <div data-control={`search-results-${name}`}>
          {hasSearched &&
            (hasResults ? (
              <GridWrapper matrix>{slicedResults.map(renderResult)}</GridWrapper>
            ) : (
              <div>No results to show</div>
            ))}
        </div>
      </div>
    )
  }
}

SearchBox.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    hideLabel: PropTypes.bool,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  getResults: PropTypes.func.isRequired,
  clearResults: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  resultFormatter: PropTypes.func,
  limit: PropTypes.number,
}

SearchBox.defaultProps = {
  meta: {},
  resultFormatter: result => result.id,
  limit: -1,
}

export default SearchBox
