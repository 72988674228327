import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { MultiAutocompleteField } from '@jsluna/react'

import { saveSpaceMultiselect } from './store/actions'

export class MultiAutoCompleteComponent extends PureComponent {
  render() {
    const { options, applyFilter, defaultSelectedOptions } = this.props

    return (
      <MultiAutocompleteField
        key={defaultSelectedOptions}
        defaultSelectedOptions={defaultSelectedOptions}
        className="ln-u-flush"
        name="multi-space-select"
        data-control="multi-space-select"
        label=""
        placeholder="Filter Spaces"
        options={options}
        onSelect={applyFilter}
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({
  applyFilter: value => {
    dispatch(saveSpaceMultiselect(value))
  },
})

MultiAutoCompleteComponent.propTypes = {
  defaultSelectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  applyFilter: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(MultiAutoCompleteComponent)
