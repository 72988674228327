import { NONE, USER, FOOD, NON_FOOD, CUSTOM } from 'src/constants/spacePlanner'

const titles = {
  [FOOD]: 'Showing food categories',
  [NONE]: 'Showing no categories',
  [NON_FOOD]: 'Showing non-food categories',
  [USER]: 'Showing your categories',
}

const buildTitle = (categoriesLength, categorySelectionType) => {
  if (categorySelectionType === CUSTOM) {
    const categories = categoriesLength !== 1 ? 'categories' : 'category'
    return `Showing ${categoriesLength} ${categories}`
  }
  return titles[categorySelectionType]
}

export default buildTitle
