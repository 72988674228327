import moment from 'moment'
import sainsburysDate from 'sainsburys-date'

import { DATE_FORMAT_DEFAULT } from 'src/utils/dateRange'
import { addOneDayToYMD } from 'src/utils/day'
import { getDuration } from 'src/utils/datePeriod'
import { hasRequiredPermission } from 'src/utils/permissions'

import statuses, { DRAFT_ID } from 'src/modules/SpacePlans/statuses'

import { UPDATE_SPACE_PLANS } from 'src/constants/permissions'

import { selectPreviousSpacePlanInfo, selectSpacePlansInfo } from '../../store'

const getStartDate = (prev = {}) => prev && prev.endDate && addOneDayToYMD(prev.endDate)

const getMaxDate = (next, startDate) => {
  if (next && next.endDate) {
    return moment(next.endDate).subtract(1, 'days').format(DATE_FORMAT_DEFAULT)
  }
  return moment(startDate).add(1, 'years').format(DATE_FORMAT_DEFAULT)
}

const initialValuesForUpdate = (state, id) => {
  const { title, previous, status, startDate, endDate, next } = selectSpacePlansInfo(state, id, 1)
  return {
    title,
    previous,
    status,
    startDate,
    endDate,
    next,
  }
}

const initialValuesForCreate = state => {
  const previous = selectPreviousSpacePlanInfo(state, 1)
  const startDate = getStartDate(previous) || moment().format(DATE_FORMAT_DEFAULT)

  const { year, quarter } = sainsburysDate.convert(startDate)
  const yearTitle = `${year}/${String(year + 1).slice(-2)}`

  return {
    title: `Q${quarter} ${yearTitle}`,
    previous,
    startDate,
    status: DRAFT_ID,
  }
}

const mapStateToProps = (
  state,
  { id, user = {}, startDate: propsStartDate, endDate: propsEndDate }
) => {
  const isNew = !id
  const {
    next,
    title,
    previous,
    startDate: initialStartDate,
    endDate: initialEndDate,
    status,
  } = isNew ? initialValuesForCreate(state) : initialValuesForUpdate(state, id)

  const startDate = propsStartDate || initialStartDate
  const endDate = propsEndDate || initialEndDate
  const maxDate = getMaxDate(next, startDate)
  const duration = getDuration({ endDate, startDate })
  const after = refStatus => statuses.isAfter(status, refStatus)
  const filteredStatuses = statuses
    .transitions(status)
    .filter(ts => !previous || statuses.isEqualOrBefore(ts.id, previous.status))
  const isReadOnly = !hasRequiredPermission(user, UPDATE_SPACE_PLANS)

  return {
    duration,
    previous,
    title,
    startDate: moment(startDate).format(DATE_FORMAT_DEFAULT),
    endDate: isNew ? null : moment(endDate).format(DATE_FORMAT_DEFAULT),
    status,
    maxDate,
    maxStartDate: endDate && moment(endDate).format(DATE_FORMAT_DEFAULT),
    statuses: filteredStatuses,
    titleDisabled: after(DRAFT_ID),
    startDateDisabled: after(DRAFT_ID),
    endDateDisabled: after(DRAFT_ID),
    statusDisabled: after(DRAFT_ID) || isNew,
    isReadOnly,
  }
}

export default mapStateToProps
