import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CloseButton, NotificationItem, StatusCard } from '@jsluna/react'

import { selectAllNotifications } from 'src/store/notifications/selectors'
import { dismissNotification } from 'src/store/notifications/actions'

const Notifications = ({ notifications, dismiss }) =>
  notifications.map(notification => (
    <NotificationItem key={notification.id} notification open>
      <StatusCard status={notification.type}>
        <CloseButton onClick={() => dismiss(notification.id)} />
        <div>
          <time>{new Date(notification.time).toLocaleTimeString()}</time>
        </div>
        {notification.body}
      </StatusCard>
    </NotificationItem>
  ))

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      time: PropTypes.instanceOf(Date).isRequired,
      body: PropTypes.string.isRequired,
      isError: PropTypes.bool,
    })
  ),
  dismiss: PropTypes.func.isRequired,
}

Notifications.defaultProps = {
  notifications: [],
}

const mapStateToProps = store => ({
  notifications: selectAllNotifications(store),
})

const mapDispatchToProps = {
  dismiss: dismissNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
