import React from 'react'
import PropTypes from 'prop-types'
import { Heading3, FilledButton } from '@jsluna/react'

const PropositionDialog = ({ acceptChangeControl, handleClose }) => (
  <div>
    <Heading3>Accept Change Control</Heading3>
    <p>
      This is a Proposition Space Assignment, please confirm that you have engaged with the
      Marketing Team before accepting
    </p>
    <div>
      <FilledButton
        data-control="modal-confirm-accept-change-control"
        onClick={acceptChangeControl}
      >
        Confirm
      </FilledButton>
      <FilledButton
        color="dark"
        data-control="modal-cancel-accept-change-control"
        onClick={handleClose}
        className="ln-u-push-left"
      >
        Cancel
      </FilledButton>
    </div>
  </div>
)

PropositionDialog.propTypes = {
  acceptChangeControl: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default PropositionDialog
