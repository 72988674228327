import React from 'react'
import { Route } from 'react-router'

import { authorized } from 'src/hoc/auth'

import ChangeControlsDetail from 'src/modules/ChangeControlsDetail'

const route = <Route path="/change-controls/:id" component={authorized(ChangeControlsDetail)} />

export default route
