import React from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import classNames from 'classnames'

const withDragDrop = ComposedComponent => {
  const wrappedComponentName =
    ComposedComponent.displayName || ComposedComponent.name || 'Component'

  const sourceSpec = {
    beginDrag: props => {
      const {
        shelfProduct: { id: shelfProductId, category: shelfProductCategory },
        position,
        shelfId,
        spaceInstanceId,
      } = props

      return {
        shelfProductId,
        shelfProductCategory,
        position,
        shelfId,
        spaceInstanceId,
      }
    },

    canDrag: props => {
      const { canMove } = props
      return canMove
    },
  }

  const targetSpec = {
    drop: (props, monitor) => {
      const {
        moveProduct,
        position: targetProductPosition,
        shelfId: targetRowShelfId,
        moveProductToShelf,
        shelfProduct: { changeType },
        spaceInstanceId: targetSpaceInstanceId,
      } = props

      const {
        position: draggedProductPosition,
        shelfProductId,
        shelfId: draggedRowShelfId,
        spaceInstanceId: draggedSpaceInstanceId,
      } = monitor.getItem()
      const isSameRow = draggedProductPosition === targetProductPosition
      const isSameShelf = draggedRowShelfId === targetRowShelfId
      const isSameSpaceInstance = draggedSpaceInstanceId === targetSpaceInstanceId

      if (!isSameSpaceInstance || changeType === 'remove') {
        return
      }

      if (!isSameRow && isSameShelf) {
        moveProduct(shelfProductId, draggedProductPosition, targetProductPosition)
      }

      if (!isSameShelf) {
        moveProductToShelf(shelfProductId, targetRowShelfId, targetProductPosition)
      }
    },
  }

  const sourceCollect = (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })

  const targetCollect = connect => ({
    connectDropTarget: connect.dropTarget(),
  })

  const DragSourceItem = DragSource('shelf-product-row', sourceSpec, sourceCollect)
  const DropTargetItem = DropTarget('shelf-product-row', targetSpec, targetCollect)

  const DragDropComponentWrapper = ({ connectDragSource, connectDropTarget, ...props }) => {
    const {
      canMove,
      hasChange,
      shelfProduct: { changeType, sku },
    } = props

    return connectDragSource(
      connectDropTarget(
        <tr
          className={classNames('ln-c-table__row c-space-assignment-row ln-u-bg-color-grey-10', {
            'c-space-assignment-row-draggable': canMove,
          })}
          data-control="product-row"
          data-sku={sku}
          {...(hasChange ? { 'data-change-type': changeType } : {})}
          data-change-type={changeType}
        >
          <ComposedComponent {...props} />
        </tr>
      )
    )
  }
  DragDropComponentWrapper.displayName = `DragDropComponentWrapper(${wrappedComponentName})`

  return DropTargetItem(DragSourceItem(DragDropComponentWrapper))
}
export default withDragDrop
