import { connect } from 'react-redux'
import { sortTable } from 'src/store/ui/tables/actions'
import { selectTableByType } from 'src/store/ui/tables/selectors'

import HeadCell from './HeadCell'

const mapStateToProps = (state, { type }) => ({
  sortedBy: selectTableByType(type)(state),
})

const mapDispatchToProps = (dispatch, { type }) => ({
  sortAction: payload => dispatch(sortTable(type)(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HeadCell)
