export default function validate({ title, endDate, startDate }) {
  const errors = {}
  if (!title) {
    errors.title = 'Title is required'
  }

  if (!endDate) {
    errors.endDate = 'End Date is required'
  }

  if (!startDate) {
    errors.startDate = 'Start Date is required'
  }

  return errors
}
