import { connect } from 'react-redux'

import { CHANGE_CONTROL_UNLOCKED_ID } from 'src/constants/cycles'

import { hasRequiredLayoutType, isPalletOrStackLayout } from 'src/utils/layoutTypes'
import { isDraft } from 'src/utils/changeControls'

import { hasRequiredPermission } from 'src/utils/permissions'

import { selectSelf, selectAllowedCategories } from 'src/store/data/selectors'

import { CREATE_SHELVES_PRODUCTS, CREATE_SHELVES_PRODUCTS_ALL } from 'src/constants/permissions'

import { selectSpaceAssignmentWithSpaceInstances } from 'src/modules/SpaceAssignment/store/selectors'
import ShelvesUpload from './ShelvesUpload'
import { selectUi } from '../../store'

const isNotEmpty = list => list.length > 0
const isSameUser = (user1, user2) => user1.id === user2.id

const mapStateToProps = (state, { layoutType, shelves }) => {
  const categories = [].concat(...shelves.map(shelf => shelf.categories))
  const palletOrStackTypes = [].concat(...shelves.map(shelf => shelf.palletOrStackType))
  const user = selectSelf(state)
  const { viewChangeControl } = selectUi(state)

  const isChangeControlView = !!viewChangeControl
  const canCreateProduct = hasRequiredPermission(user, CREATE_SHELVES_PRODUCTS)
  const canAddProduct = isNotEmpty(categories) && canCreateProduct
  const allowedCategories = selectAllowedCategories(state, categories) || []
  const canCreateAllProducts = hasRequiredPermission(user, CREATE_SHELVES_PRODUCTS_ALL)
  const canAddProductForCategory = isNotEmpty(allowedCategories) || canCreateAllProducts

  const {
    changeControlStatus = CHANGE_CONTROL_UNLOCKED_ID,
    pendingChangeControl,
  } = selectSpaceAssignmentWithSpaceInstances(state)
  const isLockedForChangeControl = changeControlStatus !== CHANGE_CONTROL_UNLOCKED_ID
  const isDraftChangeControl = isDraft(pendingChangeControl)

  const canUploadInChangeControl =
    isChangeControlView && isDraftChangeControl && isSameUser(user, pendingChangeControl.createdBy)

  const hasSetPalletOrStackTypes = values => values.every(hasRequiredLayoutType)

  const disableUpload =
    !canAddProductForCategory ||
    (isLockedForChangeControl && !canUploadInChangeControl) ||
    (isPalletOrStackLayout(layoutType) && !hasSetPalletOrStackTypes(palletOrStackTypes))

  return {
    canAddProduct,
    disableUpload,
    isLockedForChangeControl,
    pendingChangeControl,
  }
}

export default connect(mapStateToProps)(ShelvesUpload)
