import { createError, createNotification } from './actions'

const middleware = ({ dispatch }) => next => action => {
  const { payload = {}, error, meta: { notification } = {} } = action

  if (notification) {
    let newAction

    if (error || notification.isError) {
      const message = notification.message || payload.message || 'An error occurred.'
      newAction = createError(message)
    } else {
      newAction = createNotification(notification.message)
    }

    dispatch(newAction)
  }

  next(action)
}

export default middleware
