import {
  CHANGE_CONTROL_STATUS_APPROVED,
  CHANGE_CONTROL_STATUS_DRAFT,
  CHANGE_CONTROL_STATUS_SUBMITTED,
} from 'src/constants/changeControlStatus'

import {
  CHANGE_TYPE_ADD,
  CHANGE_TYPE_MODIFY,
  CHANGE_TYPE_MOVE,
  CHANGE_TYPE_REMOVE,
} from 'src/constants/changeTypes'

export const hasChange = changeControl => ({
  changeType,
  addedInChangeControl,
  removedInChangeControl,
}) => {
  let changeExists = false

  if (!changeControl) {
    return changeExists
  }

  const { id: changeControlId } = changeControl
  const hasAddModifyMoveChange =
    [CHANGE_TYPE_ADD, CHANGE_TYPE_MODIFY, CHANGE_TYPE_MOVE].includes(changeType) &&
    addedInChangeControl &&
    addedInChangeControl.id === changeControlId

  const hasRemoveChange =
    changeType === CHANGE_TYPE_REMOVE &&
    removedInChangeControl &&
    removedInChangeControl.id === changeControlId

  changeExists = hasAddModifyMoveChange || hasRemoveChange

  return changeExists
}

export const hasPendingChange = changeControl => product => {
  const productHasChange = hasChange(changeControl)(product)

  if (!productHasChange) {
    return false
  }

  const { removedInChangeControl, changeType } = product
  const changeControlPendingStatuses = new Set([
    CHANGE_CONTROL_STATUS_DRAFT,
    CHANGE_CONTROL_STATUS_SUBMITTED,
    CHANGE_CONTROL_STATUS_APPROVED,
  ])

  const hasPendingRemoveChange =
    changeType === CHANGE_TYPE_REMOVE &&
    changeControlPendingStatuses.has(removedInChangeControl.status)

  return productHasChange && hasPendingRemoveChange
}

const isStatus = statuses => changeControl =>
  changeControl ? statuses.includes(changeControl.status) : false

export const isDraft = isStatus([CHANGE_CONTROL_STATUS_DRAFT])
export const isPending = isStatus([
  CHANGE_CONTROL_STATUS_APPROVED,
  CHANGE_CONTROL_STATUS_DRAFT,
  CHANGE_CONTROL_STATUS_SUBMITTED,
])
