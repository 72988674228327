import selectPath from './selectPath'

const identity = item => item

const normaliseValue = value => {
  if (typeof value === 'string') {
    return String(value).toLowerCase()
  }

  if (value instanceof Date && !Number.isNaN(value.valueOf())) {
    return value.valueOf()
  }

  return value
}

const sortArray = (array = [], selector = identity, descending = false, secondSelector = '') => {
  const selectValue = typeof selector === 'string' ? item => selectPath(item, selector) : selector
  const secondSelectValue =
    typeof secondSelector === 'string' ? item => selectPath(item, secondSelector) : secondSelector

  const compare = (a, b) => {
    const aValue = normaliseValue(selectValue(a))
    const bValue = normaliseValue(selectValue(b))

    const aSecondValue = normaliseValue(secondSelectValue(a))
    const bSecondValue = normaliseValue(secondSelectValue(b))

    if (aValue > bValue) {
      return descending ? -1 : 1
    }
    if (aValue < bValue) {
      return descending ? 1 : -1
    }
    if (aSecondValue > bSecondValue) {
      return descending ? -1 : 1
    }
    if (aSecondValue < bSecondValue) {
      return descending ? 1 : -1
    }
    return 0
  }

  return array.sort(compare)
}

export default sortArray
