import React from 'react'
import PropTypes from 'prop-types'

import { ListItem, FilledButton } from '@jsluna/react'

import WithPermission from 'src/components/WithPermission'

import PropositionDialog from '../PropositionDialog'

const AcceptButton = ({
  changeControlCategories,
  changeControlOwnerId,
  disabled,
  isProposition,
  permission,
  setFieldValue,
  shouldDisplay,
  submitForm,
}) => (
  <WithPermission
    permission={permission}
    entityCategories={changeControlCategories}
    entityOwnerId={changeControlOwnerId}
    shouldDisplay={shouldDisplay}
  >
    <ListItem type="matrix">
      {isProposition ? (
        <PropositionDialog
          acceptChangeControl={() => {
            setFieldValue('actionType', 'accept')
            submitForm()
          }}
          element={({ onClick }) => (
            <FilledButton
              data-control="accept"
              disabled={disabled}
              name="accept"
              value="accept"
              onClick={onClick}
            >
              Accept
            </FilledButton>
          )}
        />
      ) : (
        <FilledButton
          data-control="accept"
          disabled={disabled}
          name="accept"
          value="accept"
          type="submit"
          onClick={() => {
            setFieldValue('actionType', 'accept')
          }}
        >
          Accept
        </FilledButton>
      )}
    </ListItem>
  </WithPermission>
)

AcceptButton.propTypes = {
  changeControlCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  changeControlOwnerId: PropTypes.string.isRequired,
  isProposition: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  permission: PropTypes.arrayOf(PropTypes.string),
  setFieldValue: PropTypes.func.isRequired,
  shouldDisplay: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
}

AcceptButton.defaultProps = {
  disabled: false,
  permission: [],
}
export default AcceptButton
