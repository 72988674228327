import { combineReducers, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  PostSpaceType,
  PatchSpaceTypeById,
  GetSpaceTypeById,
  GetSpaceTypeLayouts,
} from 'src/api/Space'
import {
  buildThunkPrefix,
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  clearDataHandler,
  initialState,
  actionClearDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { createSelector } from 'reselect'

const NAMESPACE = 'SpaceTypesDetail'
const SPACETYPEINFO = 'SpaceTypeInfo'
const SPACETYPELAYOUTS = 'SpaceTypeLayouts'

const selectModules = state => state.modules.SpaceTypesDetail
const selectSpaceTypeInfo = createSelector(selectModules, module => module.SpaceTypeInfo.data)
const selectSpaceTypeLayouts = createSelector(selectModules, module => module.SpaceTypeLayouts.data)
const actionClearData = actionClearDataHandler(NAMESPACE)

const actionCreateSpaceType = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'actionCreateSpaceType'),
  async (spaceType, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const postSpaceTypeInstance = new PostSpaceType(store, {
      params: { spaceType },
    })

    const response = await handleCreateAsyncThunkResult(
      postSpaceTypeInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const actionUpdateSpaceTypeById = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'actionUpdateSpaceTypeById'),
  async ({ spaceTypeId, spaceType }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()

    const patchSpaceTypeInstance = new PatchSpaceTypeById(store, {
      params: { spaceTypeId, spaceType },
    })

    const response = await handleCreateAsyncThunkResult(
      patchSpaceTypeInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchSpaceTypeById = createAsyncThunk(
  buildThunkPrefix(SPACETYPEINFO, 'fetchSpaceTypeById'),
  async (id, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const getSpaceTypInstance = new GetSpaceTypeById(store, {
      params: { id },
    })
    const response = await handleCreateAsyncThunkResult(
      getSpaceTypInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const fetchSpaceTypeLayouts = createAsyncThunk(
  buildThunkPrefix(SPACETYPELAYOUTS, 'fetchSpaceTypeLayouts'),
  async (id, { getState, rejectWithValue, dispatch }) => {
    const store = getState()
    const GetSpaceTypeLayoutsInstance = new GetSpaceTypeLayouts(store)
    const response = await handleCreateAsyncThunkResult(
      GetSpaceTypeLayoutsInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const spaceTypeSlice = createSlice({
  name: SPACETYPEINFO,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSpaceTypeById.pending, pendingDataHandler)
    builder.addCase(fetchSpaceTypeById.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpaceTypeById.rejected, rejectedDataHandler)
    builder.addCase(actionClearData, clearDataHandler)
  },
})

const spaceTypeLayoutsSlice = createSlice({
  name: SPACETYPELAYOUTS,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchSpaceTypeLayouts.pending, pendingDataHandler)
    builder.addCase(fetchSpaceTypeLayouts.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpaceTypeLayouts.rejected, rejectedDataHandler)
    builder.addCase(actionClearData, clearDataHandler)
  },
})

export {
  actionClearData,
  actionCreateSpaceType,
  actionUpdateSpaceTypeById,
  fetchSpaceTypeById,
  selectSpaceTypeInfo,
  fetchSpaceTypeLayouts,
  selectSpaceTypeLayouts,
}

export default {
  [NAMESPACE]: combineReducers({
    [spaceTypeSlice.name]: spaceTypeSlice.reducer,
    [spaceTypeLayoutsSlice.name]: spaceTypeLayoutsSlice.reducer,
  }),
}
