import React, { Component } from 'react'
import PropTypes from 'prop-types'

class FlyoutComponent extends Component {
  constructor(props) {
    super(props)

    this.selfRef = React.createRef()
    this.state = {
      offset: 0,
    }
  }

  componentDidMount() {
    const { right } = this.selfRef.current.getBoundingClientRect()
    const { innerWidth } = window

    if (right > innerWidth) {
      const offset = Math.floor(innerWidth - right)
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ offset })
    }
  }

  render() {
    const { children } = this.props
    const { offset } = this.state

    const style = {
      left: `${offset}px`,
    }

    return (
      <div className="c-flyout ln-u-border" ref={this.selfRef} style={style}>
        <div className="c-flyout__inner ln-u-push">{children}</div>
      </div>
    )
  }
}

FlyoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FlyoutComponent
