import React from 'react'
import PropTypes from 'prop-types'
import { Heading2 } from '@jsluna/react'

const Header = ({ id, title }) => (
  <Heading2>
    <span data-control="space-plans-detail-title">{id ? title : 'Create Space Plan'}</span>
  </Heading2>
)

Header.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
}

Header.defaultProps = {
  id: null,
  title: null,
}

export default Header
