import React from 'react'
import PropTypes from 'prop-types'

import { Select, FlagWrapper, FlagBody, FlagComponent, TextButton } from '@jsluna/react'
import pluralize from 'src/utils/pluralize'

import { UPDATE_SPACE_ASSIGNMENTS } from 'src/constants/permissions'

import WithRequiredPermissions from 'src/components/WithRequiredPermissions'
import withExport from 'src/hoc/withExportLegacy'

const ExportButton = withExport(TextButton)

const Cluster = ({ updateInstance, instances, cluster, storesList, exportStoresList }) => {
  const { title } = cluster
  const options = instances.map(instance => ({
    value: instance.id,
    label: instance.title,
  }))

  const onChange = async event => {
    const selectedInstanceId = event.target.value
    const instance = {
      id: selectedInstanceId,
      clusterId: cluster.id,
    }

    await updateInstance(instance)
  }

  return (
    <div data-control={`cluster-${title}`} data-type="cluster">
      <FlagWrapper>
        <FlagBody>
          <span data-control="cluster-title">
            {title}
            {` - `}

            <ExportButton
              data-control="export-stores-list"
              className="ln-u-hard-left"
              getExportsData={() => exportStoresList(cluster.id)}
            >
              {storesList} {pluralize(storesList, 'store', 'stores')}
            </ExportButton>
          </span>
        </FlagBody>
        <FlagComponent>
          <WithRequiredPermissions permission={UPDATE_SPACE_ASSIGNMENTS}>
            <Select
              data-control="move-cluster-select"
              name="destination"
              placeholder="Move to"
              className="c-cluster__select ln-u-flush-bottom"
              onChange={onChange}
              disabled={!options.length}
              options={options}
            />
          </WithRequiredPermissions>
        </FlagComponent>
      </FlagWrapper>
    </div>
  )
}

Cluster.propTypes = {
  cluster: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  storesList: PropTypes.number.isRequired,
  updateInstance: PropTypes.func.isRequired,
  instances: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  exportStoresList: PropTypes.func.isRequired,
}

export default Cluster
