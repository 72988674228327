import React from 'react'
import PropTypes from 'prop-types'
import buildShelfTitle from 'src/utils/shelfTitle'
import { ArrowDown, ArrowUp } from '@jsluna/icons'
import Cell from '../RowCell'
import DeleteButton from './DeleteButton'
import DeleteDialog from './DeleteDialog'
import EditButton from './EditButton'
import EditDialog from './EditDialog'

const ShelfArtifact = ({
  artifact,
  columnSpan,
  deleteShelfArtifact,
  itemsOnShelf,
  moveShelfArtifact,
  shelfPosition,
  shelvesLength,
  layoutType,
  shelfIsRsb,
  updateShelfArtifact,
  shelfTitle,
}) => {
  const { id, artifactType: type, description = '', position: artifactPosition } = artifact
  const isFirstProduct = shelfPosition === 0
  const isLastProduct = shelfPosition === itemsOnShelf - 1
  const canMoveUp = !isFirstProduct
  const canMoveDown = !isLastProduct
  const showControls = deleteShelfArtifact || moveShelfArtifact || updateShelfArtifact
  const showShelfTitle = shelfTitle
    ? shelfPosition >= 0
    : shelfPosition >= 0 && shelvesLength >= 0 && layoutType
  const classNames =
    type === 'divider' || type === 'DIVIDER'
      ? 'ln-u-font-weight-bold ln-u-bg-color-red ln-u-color-white'
      : 'ln-u-font-weight-bold ln-u-bg-color-yellow'
  const colSpanValue = showControls ? columnSpan - 1 : columnSpan
  return (
    <tr
      id={`shelf-assigned-${id}`}
      className={`ln-c-table__row c-space-assignment-row ${classNames}`}
      data-control="shelf-artifact-row"
    >
      {showShelfTitle && (
        <Cell alignment="left" columnSpan={1} label="Shelf">
          {shelfTitle || buildShelfTitle(shelfPosition, shelvesLength, shelfIsRsb, layoutType)}
        </Cell>
      )}
      <Cell alignment="left" columnSpan={colSpanValue}>
        {(type === 'divider' && 'DIVIDER') || description}
      </Cell>
      {showControls && (
        <Cell alignment="right" columnSpan={1}>
          {canMoveUp && (
            <button
              className="c-icon-button u-bg-none"
              data-control="move-up"
              onClick={() => {
                moveShelfArtifact(artifact, artifactPosition - 1)
              }}
              type="button"
            >
              <ArrowUp className={classNames} />
            </button>
          )}
          {canMoveDown && (
            <button
              className="c-icon-button u-bg-none"
              data-control="move-down"
              onClick={() => {
                moveShelfArtifact(artifact, artifactPosition + 1)
              }}
              type="button"
            >
              <ArrowDown className={classNames} />
            </button>
          )}
          {type === 'pos' && 'POS' && (
            <EditDialog
              artifact={artifact}
              element={EditButton}
              updateShelfArtifact={updateShelfArtifact}
            />
          )}
          {deleteShelfArtifact && (
            <DeleteDialog
              artifactId={id}
              deleteShelfArtifact={deleteShelfArtifact}
              element={DeleteButton}
              type={type}
            />
          )}
        </Cell>
      )}
    </tr>
  )
}

ShelfArtifact.propTypes = {
  artifact: PropTypes.shape({
    id: PropTypes.string,
    artifactType: PropTypes.string.isRequired,
    description: PropTypes.string,
    position: PropTypes.number.isRequired,
  }).isRequired,
  shelvesLength: PropTypes.number,
  layoutType: PropTypes.string,
  shelfIsRsb: PropTypes.bool,
  columnSpan: PropTypes.number,
  deleteShelfArtifact: PropTypes.func,
  itemsOnShelf: PropTypes.number,
  moveShelfArtifact: PropTypes.func,
  shelfPosition: PropTypes.number,
  updateShelfArtifact: PropTypes.func,
  shelfTitle: PropTypes.string,
}

ShelfArtifact.defaultProps = {
  columnSpan: 13,
  deleteShelfArtifact: null,
  itemsOnShelf: null,
  moveShelfArtifact: null,
  shelfPosition: null,
  updateShelfArtifact: null,
  shelvesLength: null,
  layoutType: null,
  shelfIsRsb: null,
  shelfTitle: null,
}

export default ShelfArtifact
