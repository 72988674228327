import Api from '../Base'

class PatchShelfProduct extends Api {
  requestUrl = `/api/shelfProduct/${this.getParams().shelfProductId}`

  method = 'PATCH'

  formatRequestData = params => params.shelfProduct
}

export default PatchShelfProduct
