export default function validate({ group: { title, spacePlanId } }) {
  const group = {}
  const errors = {}
  if (!title) {
    group.title = 'Title is required'
  }
  if (!spacePlanId) {
    group.spacePlanId = 'Space plan is required'
  }
  if (Object.keys(group).length !== 0) {
    errors.group = group
  }
  return errors
}
