import React from 'react'
import PropTypes from 'prop-types'

import { Field, withFormik } from 'formik'
import { Form, GridWrapper, GridItem, ListItem } from '@jsluna/react'
import { TextInputField, SelectField } from '@jsluna/form/extensions/formik'

import { Controls } from 'src/components/Form'

import ProductSearch from 'src/modules/InlineProductSearch'
import handleSubmit from './handleSubmit'
import validate from './validate'

const toOption = ({ title, id }) => ({ id, value: id, label: title })

const AddProductRow = ({
  cancel,
  categories,
  disabled,
  isSubmitting,
  promoMechanics,
  handleSubmit: onSubmit,
}) => (
  <ListItem
    className="ln-u-bg-color-white ln-u-soft-sides-sm ln-u-padding-ends*2 ln-u-border-bottom"
    type="bare"
  >
    <Form onSubmit={onSubmit}>
      <div data-control="new-product-row">
        <GridWrapper>
          <GridItem size="1/3">
            <Field
              label="SKU"
              component={ProductSearch}
              name="product.sku"
              validationFirst={false}
            />
          </GridItem>
          <GridItem size="1/3">
            <Field
              label="Description"
              name="product.description"
              component={TextInputField}
              validationFirst={false}
              disabled
            />
          </GridItem>
          <GridItem size="1/3">
            <Field
              label="Comment"
              name="comment"
              component={TextInputField}
              validationFirst={false}
              disabled={disabled.comment}
            />
          </GridItem>
          <GridItem size="1/3">
            <Field
              label="Promo Mechanic"
              name="promoMechanic"
              component={SelectField}
              options={promoMechanics.map(toOption)}
              validationFirst={false}
              disabled={disabled.promoMechanic}
            />
          </GridItem>
          <GridItem size="1/6">
            <Field
              label="Promo Mechanic Value"
              name="promoMechanicValue"
              component={TextInputField}
              validationFirst={false}
              disabled={disabled.promoMechanicValue}
            />
          </GridItem>
          <GridItem size="1/6">
            <Field
              label="Price Point"
              name="pricePoint"
              component={TextInputField}
              validationFirst={false}
              disabled={disabled.pricePoint}
            />
          </GridItem>
          <GridItem size="1/3">
            <Field
              label="Category"
              name="category"
              component={SelectField}
              options={categories.map(toOption)}
              placeholder={categories.length > 1}
              validationFirst={false}
              disabled={disabled.category}
            />
          </GridItem>
          <GridItem size="1/2">
            <Controls submitText="Save" cancel={cancel} disabled={isSubmitting} />
          </GridItem>
        </GridWrapper>
      </div>
    </Form>
  </ListItem>
)

AddProductRow.propTypes = {
  cancel: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  disabled: PropTypes.shape({
    category: PropTypes.bool.isRequired,
    comment: PropTypes.bool.isRequired,
    pricePoint: PropTypes.bool.isRequired,
    promoMechanic: PropTypes.bool.isRequired,
    promoMechanicValue: PropTypes.bool.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  promoMechanics: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
}

const wrappedForm = withFormik({ handleSubmit, validate })(AddProductRow)

export default wrappedForm
