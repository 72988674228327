import { combineReducers } from 'redux'

import categories from './categories'
import categorySelectionType from './categorySelectionType'
import spaces from './spaces'

export default combineReducers({
  categories,
  categorySelectionType,
  spaces,
})
