import React from 'react'
import PropTypes from 'prop-types'

function LabelledText({ label, text, value }) {
  return (
    <div>
      <div className="ln-c-label ln-u-soft-right">{label}</div>
      {value || text}
    </div>
  )
}

LabelledText.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
}

LabelledText.defaultProps = {
  label: '',
  text: '',
  value: '',
}

export default LabelledText
