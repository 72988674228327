export const concatenatePaths = paths => {
  return paths.reduce((output, path) => {
    if (!path) {
      return output
    }

    if (path.substring(0, 1) === '/') {
      return path
    }

    if (output === '/') {
      return `/${path}`
    }

    return `${output}/${path}`
  }, '')
}

export const flattenNestedPaths = ({ parent, path }) =>
  (parent ? [...flattenNestedPaths(parent), path] : [path]).filter(route => route)
