import React from 'react'

import loader from 'src/hoc/loader'
import NotFound from 'src/components/Errors/NotFound'

import { fetchCategories } from 'src/store/modules/asyncThunks/categories'
import { fetchRoles } from 'src/store/modules/asyncThunks/roles'
import { actionClearData, fetchUserInfo, fetchUserRequestedInfo, isNewUserRequested } from './store'

import Form from './Form'

const getData = async ({ id }, dispatch) => {
  const isNewRequest =
    window.location &&
    window?.location?.search !== '' &&
    window?.location?.search.includes('?isNewRequest=true')

  const actions = [fetchRoles(), fetchCategories()]
  if (!isNewRequest) {
    actions.push(fetchUserInfo(id))
  } else {
    dispatch(isNewUserRequested(true))
    actions.push(fetchUserRequestedInfo(id))
  }
  await Promise.all(actions.map(a => dispatch(a).unwrap()))
}

const options = {
  Custom404: (
    <NotFound title="User Not Found" message="The user you are looking for cannot be found." />
  ),
  extractProps: ({ params }) => params,
  shouldRefresh: ({ id: previousId }, { id }) => previousId !== id,
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(Form)
