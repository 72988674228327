import loader from 'src/hoc/loader'
import {
  fetchAdminConfigMessages,
  actionClearData,
} from 'src/store/modules/asyncThunks/adminConfigMessages'

import MessagesAdmin from './MessagesAdmin'

const getData = async (props, dispatch) => {
  await dispatch(fetchAdminConfigMessages())
}

const options = {
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(MessagesAdmin)
