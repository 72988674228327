import { createSelector } from 'reselect'

import {
  selectUi,
  selectSpacePlanAssignmentsCycleDetails,
  selectSpacePlanCyclesAssignments,
  selectGroupSelected,
} from '..'

import buildTimebar from './buildTimebar'
import buildScale from './buildScale'
import selectTracks from './selectTracks'

export const selectDates = createSelector(
  selectSpacePlanCyclesAssignments,
  ({ cyclesMinStartDate: startDate, cyclesMaxEndDate: endDate } = {}) => ({ startDate, endDate })
)

const selectZoom = createSelector(selectUi, planner => planner.zoom)
const selectIsOpen = createSelector(selectUi, planner => planner.isOpen)
const selectTimebar = createSelector(
  selectSpacePlanAssignmentsCycleDetails,
  selectDates,
  selectGroupSelected,
  (cycles, { startDate, endDate }, selectedGroup) =>
    buildTimebar({ startDate, endDate, cycles, selectedGroup })
)
const selectScale = createSelector(selectZoom, selectDates, (zoom, { startDate, endDate }) =>
  buildScale({ startDate, endDate, zoom })
)
export const selectFilter = createSelector(selectUi, planner => planner.filter)
export const selectSearch = createSelector(selectUi, planner => planner.search)
export const selectSpaceMultiSelect = createSelector(selectUi, planner => planner.multiselect)
export const selectSpaceFilterType = createSelector(selectUi, planner => planner.spaceFilterType)
export const selectUiTracks = createSelector(selectUi, planner => planner.tracks)

export const selectTimeline = createSelector(
  selectZoom,
  selectIsOpen,
  selectTracks,
  selectTimebar,
  selectScale,
  selectFilter,
  (zoom, isOpen, tracks, timebar, scale, filter) => ({
    zoom,
    isOpen,
    scale,
    timebar,
    filter,
    ...tracks,
  })
)
