function enableDevTools(environment) {
  switch (environment) {
    case 'local':
      return true
    case 'dev':
      return true
    default:
      return false
  }
}

export default enableDevTools
