import React from 'react'

import loader from 'src/hoc/loader'
import NotFound from 'src/components/Errors/NotFound'

import { actionClearData, fetchSpaceTypeById, fetchSpaceTypeLayouts } from './store'
import Form from './Form'

const getData = async ({ id }, dispatch) => {
  const actions = [fetchSpaceTypeLayouts()]

  if (id) {
    actions.push(fetchSpaceTypeById(id))
  }

  await Promise.all(actions.map(a => dispatch(a).unwrap()))
}

const options = {
  Custom404: (
    <NotFound
      title="Space Type Not Found"
      message="The space type you are looking for cannot be found."
    />
  ),
  extractProps: ({ params }) => params,
  shouldRefresh: ({ id: previousId }, { id }) => previousId !== id,
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(Form)
