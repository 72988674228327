import { combineReducers } from 'redux'

import { SHELVES_PRODUCTS_TYPE, USER_REQUESTS_TYPE } from 'src/constants/types'
import { API_SUCCESS } from 'src/constants/api'

import merge from './utils/merge'
import generateSchema from './utils/schema'

const SEED_SCHEMA = {
  [USER_REQUESTS_TYPE]: {
    categories: {
      type: 'relationships',
    },
  },
  [SHELVES_PRODUCTS_TYPE]: {
    clusters: {
      type: 'relationships',
    },
    precedingShelfProduct: {
      type: 'relationships',
    },
  },
}

function schema(state = SEED_SCHEMA, { type, payload = {} } = {}) {
  const { response = {} } = payload
  switch (type) {
    case API_SUCCESS:
      return merge(state, generateSchema(response))
    default:
      return state
  }
}

export default combineReducers({ schema })
