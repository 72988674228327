import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ReactReduxContext } from 'react-redux'

import { log } from 'src/utils/log'

import LoadingCard from 'src/components/LoadingCard'
import createErrorHandler from 'src/hoc/errorHandler'

export default (loaderPromise, options = {}) => ComposedComponent => {
  const wrappedComponentName =
    ComposedComponent.displayName || ComposedComponent.name || 'Component'

  const {
    ErrorComponent = createErrorHandler(options),
    LoadingComponent = LoadingCard,
    extractProps = props => {
      const { store, ...restProps } = props

      return restProps
    },
    shouldRefresh = () => false,
    onUnmount,
  } = options

  class LoaderComponent extends Component {
    constructor(props) {
      super(props)
      this.state = { isReady: false, error: null }
    }

    componentDidMount() {
      this.promises = []
      this.process()
    }

    componentDidUpdate(prevProps) {
      if (shouldRefresh(extractProps(prevProps), extractProps(this.props))) {
        this.process(this.props)
      }
    }

    componentWillUnmount() {
      if (onUnmount) {
        const { store } = this.props

        onUnmount(store.dispatch)
      }
    }

    process(props = this.props) {
      this.setState({ isReady: false })

      const extractedProps = extractProps(props)
      const promise = loaderPromise(extractedProps, props.store.dispatch, props.store.getState)
        .then(data => this.setState({ isReady: true, data }))
        .catch(error => {
          log(error)
          this.setState({ error })
        })

      this.promises.push(promise)
    }

    render() {
      const { error, isReady, data } = this.state
      const extractedProps = extractProps(this.props, data)

      if (error) {
        return <ErrorComponent error={error} {...extractedProps} />
      }

      if (isReady) {
        return <ComposedComponent {...extractedProps} />
      }

      return LoadingComponent ? <LoadingComponent /> : null
    }
  }

  LoaderComponent.displayName = `Loader(${wrappedComponentName})`

  LoaderComponent.propTypes = {
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired,
    }).isRequired,
  }

  const LoaderComponentWithContext = props => (
    <ReactReduxContext.Consumer>
      {({ store }) => <LoaderComponent store={store} {...props} />}
    </ReactReduxContext.Consumer>
  )

  return LoaderComponentWithContext
}
