import React from 'react'
import PropTypes from 'prop-types'
import { Heading3, FilledButton } from '@jsluna/react'

const DeleteDialog = ({ spaceAssignmentId, deleteSpaceAssignment, handleClose }) => (
  <div>
    <Heading3>Are you sure you want to delete this space assignment?</Heading3>
    <p>Please confirm this action</p>
    <div>
      <FilledButton
        data-control="modal-confirm-delete-space-assignment"
        onClick={async () => {
          await deleteSpaceAssignment(spaceAssignmentId)

          handleClose()
        }}
      >
        Confirm
      </FilledButton>
      <FilledButton
        color="dark"
        data-control="modal-cancel-delete-space-assignment"
        onClick={handleClose}
        className="ln-u-push-left"
      >
        Cancel
      </FilledButton>
    </div>
  </div>
)

DeleteDialog.propTypes = {
  spaceAssignmentId: PropTypes.string.isRequired,
  deleteSpaceAssignment: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default DeleteDialog
