import { GetGroupsBySpacePlan } from 'src/api/SpacePlan'
import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const NAMESPACE = 'SpacePlanner'
const GROUP_DETAIL = 'GroupDetail'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = slice => `${STORE_PATH}/${slice}`

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const fetchGroupsBySpacePlan = createAsyncThunk(
  buildThunkPrefix(GROUP_DETAIL, 'fetchGroupsBySpacePlan'),
  async (id, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getGroupsBySpacePlanInstance = new GetGroupsBySpacePlan(store, {
      params: { id },
    })

    const response = await handleCreateAsyncThunkResult(
      getGroupsBySpacePlanInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

const groupsBySpacePlanSlice = createSlice({
  name: GROUP_DETAIL,
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchGroupsBySpacePlan.pending, pendingDataHandler)
    builder.addCase(fetchGroupsBySpacePlan.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchGroupsBySpacePlan.rejected, rejectedDataHandler)
  },
})

export { groupsBySpacePlanSlice, fetchGroupsBySpacePlan, actionClearData }
