import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

import SearchBox from 'src/components/SearchBox'

import { selectFilteredStores, actionGetStores, actionClearData } from './store'

const NO_LIMIT = -1

const mapStateToProps = (state, { route, useStoreId }) => ({
  results: selectFilteredStores(state),
  resultFormatter: store => (
    <Link
      to={`/${route}/${useStoreId ? store.storeNumber : store.id}`}
      className="ln-c-button ln-c-button--filled ln-c-button--full"
    >
      {`${store.name} - ${store.storeNumber}`}
    </Link>
  ),
  limit: NO_LIMIT,
})

const mapDispatchToProps = {
  getResults: actionGetStores,
  clearResults: actionClearData,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox)
