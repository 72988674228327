import { connect } from 'react-redux'

import {
  filterInAllCategories,
  filterOutAllCategories,
  filterInFoodCategories,
  filterInNonFoodCategories,
  filterInAllUserCategories,
} from '../store/actions'
import CategoryFlyout from './CategoryFlyout'

const mapDispatchToProps = {
  filterInAllCategories,
  filterOutAllCategories,
  filterInFoodCategories,
  filterInNonFoodCategories,
  filterInAllUserCategories,
}

export default connect(null, mapDispatchToProps)(CategoryFlyout)
