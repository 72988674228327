import Api from 'src/api/Base'

class PostSspsaTask extends Api {
  requestUrl = `/api/SspsaTask`

  method = 'POST'

  formatRequestData = params => params.generateSspsaTask
}

export default PostSspsaTask
