import React from 'react'
import PropTypes from 'prop-types'
import { Delete } from '@jsluna/icons'

const DeleteButton = ({ disabled, onClick }) => (
  <button
    className="c-icon-button u-bg-none"
    data-control="remove-shelf-product"
    disabled={disabled}
    onClick={onClick}
    type="button"
  >
    <Delete />
  </button>
)

DeleteButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default DeleteButton
