import { buildReducer } from 'src/store/modules'

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  handleCreateAsyncThunkResult,
  buildThunkPrefix,
  actionClearDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import PatchAdminConfigMessages from 'src/api/MessagesAdmin'

const NAMESPACE = 'Messages'

const actionClearData = actionClearDataHandler(NAMESPACE)

const actionUpdateAdminConfigMessages = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'actionUpdateAdminConfigMessages'),
  async ({ adminConfigMessages }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const patchAdminConfigMessagesInstance = new PatchAdminConfigMessages(store, {
      params: { adminConfigMessages },
    })

    const response = await handleCreateAsyncThunkResult(
      patchAdminConfigMessagesInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

export default buildReducer(NAMESPACE)
export { actionClearData, actionUpdateAdminConfigMessages }
