export const ALL = 'all'
export const CUSTOM = 'custom'
export const FOOD = 'food'
export const NONE = 'none'
export const NON_FOOD = 'non-food'
export const USER = 'user'

export const SPACE_MULTISELECT = 'space-multiselect'
export const SPACE_SEARCH = 'space-search'

export const FORM_NAME = 'spacePlan'

export const SPACE_FILTER_TYPE_OPTIONS = [
  {
    id: SPACE_SEARCH,
    value: SPACE_SEARCH,
    label: 'Search',
  },
  {
    id: SPACE_MULTISELECT,
    value: SPACE_MULTISELECT,
    label: 'Filter',
  },
]
