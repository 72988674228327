import React from 'react'
import PropTypes from 'prop-types'

const FlyoutWrapper = ({ isVisible, children }) => (
  <div className="c-flyout-wrapper">{isVisible && children}</div>
)

FlyoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool,
}

FlyoutWrapper.defaultProps = {
  isVisible: false,
}

export default FlyoutWrapper
