import React from 'react'
import PropTypes from 'prop-types'

import buildUri from './buildUri'

const DocumentLink = ({ generating, url, id, type, createdAt }) => {
  const linkText = {
    generatingMessage: (
      <div>
        Generating,
        <br />
        please try again later
      </div>
    ),
    hasUrlMessage: 'Open in Browser',
    hasExcelMessage: 'Open in Excel',
    notFoundMessage: 'No Document link found',
  }
  const environment = window?.PROMCOMM?.ENVIRONMENT || ''
  const clientUri = buildUri({ id, type, environment, createdAt })

  return (
    <div className="ln-o-flag__component ln-o-flag__component--nowrap">
      <div className="ln-o-matrix-list">
        <div className="ln-o-matrix-list__item">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href={generating || !url ? '#' : clientUri}
            rel="noopener noreferrer"
            className="ln-c-button ln-c-button--filled"
            data-control="excel-link"
          >
            {
              linkText[
                (generating && 'generatingMessage') ||
                  (url && 'hasExcelMessage') ||
                  'notFoundMessage'
              ]
            }
          </a>
        </div>
        <div className="ln-o-matrix-list__item">
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="ln-c-button ln-c-button--filled ln-c-button--dark"
            data-control="browser-link"
          >
            {
              linkText[
                (generating && 'generatingMessage') || (url && 'hasUrlMessage') || 'notFoundMessage'
              ]
            }
          </a>
        </div>
      </div>
    </div>
  )
}

DocumentLink.propTypes = {
  generating: PropTypes.bool,
  url: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  createdAt: PropTypes.string,
}

DocumentLink.defaultProps = {
  generating: false,
  url: null,
  id: null,
  type: null,
  createdAt: null,
}

export default DocumentLink
