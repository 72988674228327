import Api from '../Base'

class UpdateSpacePlanById extends Api {
  requestUrl = `/api/SpacePlan/${this.getParams().id}`

  method = 'PATCH'

  formatRequestData = params => params.spacePlan
}

export default UpdateSpacePlanById
