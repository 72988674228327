import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  GridWrapper,
  GridItem,
  Select,
  FilledButton,
  Card,
  Heading3,
  FlagComponent,
  FlagWrapper,
  FlagBody,
} from '@jsluna/react'

import sortNatural from 'src/utils/sortNatural'
import { hasRequiredPermission } from 'src/utils/permissions'

import withExport from 'src/hoc/withExport'

import { selectSelfRoleId, selectSelf } from 'src/store/modules/self'
import { PERM_ROLE } from 'src/constants/roles'
import { EXPORT_CHANGE_CONTROLS } from 'src/constants/permissions'

import {
  selectFilterApplied,
  selectFilteredChangeControls,
  selectChangeControls,
  selectFilters,
} from './store'
import {
  setChangeControlsFilter,
  getExportsChangeControls,
  getExportsChangeControlSkuDetails,
} from './store/actions'

import Summary from './Summary'

const sortRankingDefault = {
  submitted: 1,
  approved: 2,
  accepted: 3,
  withdrawn: 4,
  rejected: 5,
  draft: 6,
}

const sortRankingPERM = {
  ...sortRankingDefault,
  approved: 1,
  submitted: 2,
}

const createSortByStatusAndTime = sortRanking => changeControls => {
  const sortedByDateTime = sortNatural([...changeControls], 'createdAt')

  return sortedByDateTime.sort((a, b) => sortRanking[a.status] - sortRanking[b.status])
}

const toOption = ({ id, title }) => ({ id, label: title, value: title.toLowerCase() })

const ExportButton = withExport(FilledButton)

const Summaries = props => {
  const {
    cycleId,
    filterApplied,
    filters: { reasonCodeFilters, spaceFilters, statusFilters },
    filteredChangeControls,
    changeControls,
    setFilter,
    exportsChangeControls,
    exportsChangeControlSkuDetails,
    canExport,
  } = props
  const changeControlsToDisplay = filterApplied ? filteredChangeControls : changeControls
  const noResultsMessage = filterApplied
    ? 'There are no change controls matching your criteria'
    : 'There are no change controls'

  const onChange = filterType => ({ target: { value } }) => setFilter(filterType, value)

  return (
    <Card>
      <FlagWrapper>
        <FlagBody>
          <Heading3 data-control="space-plans-title">Change Controls List</Heading3>
        </FlagBody>
        <FlagComponent>
          {canExport && (
            <>
              <ExportButton
                getExportsData={exportsChangeControlSkuDetails}
                data-control="export-sku-change-details"
                className="ln-u-soft-left ln-u-push-right-lg"
              >
                Export SKU change details
              </ExportButton>
              <ExportButton
                getExportsData={exportsChangeControls}
                data-control="export-change-controls"
                className="ln-u-soft-left"
              >
                Export All
              </ExportButton>
            </>
          )}
        </FlagComponent>
      </FlagWrapper>

      <GridWrapper className="ln-u-soft-left ln-u-push-bottom">
        <GridItem size="2/12">
          <div className="ln-c-label ln-u-soft-right">In Space</div>
          <Select
            name="select-space"
            options={spaceFilters.map(toOption)}
            onChange={onChange('space')}
            placeholder="Any"
          />
        </GridItem>

        <GridItem size="2/12">
          <div className="ln-c-label">With Status</div>
          <Select
            name="select-status"
            options={statusFilters.map(toOption)}
            onChange={onChange('status')}
            placeholder="Any"
          />
        </GridItem>

        <GridItem size="2/12">
          <div className="ln-c-label ln-u-soft-right">With Reason Code</div>
          <Select
            name="select-reason-code"
            options={reasonCodeFilters.map(toOption)}
            onChange={onChange('reasonCode')}
            placeholder="Any"
          />
        </GridItem>
      </GridWrapper>

      {changeControlsToDisplay.length ? (
        changeControlsToDisplay.map(({ id }) => (
          <Summary key={id} cycleId={cycleId} changeControlId={id} />
        ))
      ) : (
        <p className="ln-u-soft-left ln-u-flush-bottom">{noResultsMessage}</p>
      )}
    </Card>
  )
}

Summaries.propTypes = {
  canExport: PropTypes.bool,
  filterApplied: PropTypes.bool,
  filters: PropTypes.shape({
    reasonCodeFilters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    statusFilters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    spaceFilters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
  }),
  changeControls: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  filteredChangeControls: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  cycleId: PropTypes.string.isRequired,
  setFilter: PropTypes.func,
  exportsChangeControls: PropTypes.func,
  exportsChangeControlSkuDetails: PropTypes.func,
}

Summaries.defaultProps = {
  canExport: false,
  filterApplied: false,
  filters: {
    reasonCodeFilters: [],
    statusFilters: [],
    spaceFilters: [],
  },
  filteredChangeControls: [{ id: '' }],
  setFilter: () => {},
  exportsChangeControls: () => {},
  exportsChangeControlSkuDetails: () => {},
}

const mapStateToProps = state => {
  const selfRoleId = selectSelfRoleId(state)
  const user = selectSelf(state)
  const sortRanking = selfRoleId === PERM_ROLE.id ? sortRankingPERM : sortRankingDefault
  const sortByStatusAndTime = createSortByStatusAndTime(sortRanking)

  const filters = selectFilters(state) || {}
  const unsortedChangeControls = selectChangeControls(state) || []
  const changeControls = sortByStatusAndTime(unsortedChangeControls)
  const filterApplied = selectFilterApplied(state)
  const filteredChangeControls = filterApplied
    ? sortByStatusAndTime(selectFilteredChangeControls(state))
    : []
  const canExport = hasRequiredPermission(user, EXPORT_CHANGE_CONTROLS)

  return {
    changeControls,
    filters,
    filterApplied,
    filteredChangeControls,
    canExport,
  }
}

const mapDispatchToProps = (dispatch, { cycleId }) => ({
  setFilter: (filterType, value) => dispatch(setChangeControlsFilter(filterType, value)),
  exportsChangeControls: () => dispatch(getExportsChangeControls(cycleId)),
  exportsChangeControlSkuDetails: () => dispatch(getExportsChangeControlSkuDetails(cycleId)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Summaries)
