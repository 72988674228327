import { buildReducer } from 'src/store/modules'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { PatchSpaceDetailById } from 'src/api/Space'
import {
  handleCreateAsyncThunkResult,
  buildThunkPrefix,
  actionClearDataHandler,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'SpacesDetail'

const actionClearData = actionClearDataHandler(NAMESPACE)

const actionUpdateSpaceById = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'actionUpdateSpaceById'),
  async ({ id, space }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()

    const patchSpaceInstance = new PatchSpaceDetailById(store, {
      params: { id, space },
    })

    const response = await handleCreateAsyncThunkResult(
      patchSpaceInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)

export default buildReducer(NAMESPACE)
export { actionUpdateSpaceById, actionClearData }
