import PropTypes from 'prop-types'
import React from 'react'
import { GridItem } from '@jsluna/react'

const Status = ({ status }) => (
  <GridItem size="1/1">
    <div className="ln-c-label ln-u-soft-right">Space Plan Status</div>
    <span data-control="space-plan-status">{status}</span>
  </GridItem>
)

Status.propTypes = {
  status: PropTypes.string,
}

Status.defaultProps = {
  status: null,
}

export default Status
