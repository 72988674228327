import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { hasRequiredPermission } from 'src/utils/permissions'
import { CREATE_GENERATE_SSPSA_TASK_TYPE } from 'src/constants/permissions'
import { Heading4 } from '@jsluna/react'
import HeaderCell from 'src/components/HeaderCell'
import Cell from 'src/components/RowCell'
import moment from 'moment'
import DeleteButton from './DeleteButton'
import DeleteDialog from './DeleteDialog'

export const Component = ({ mostRecentSspsaList, businessUnit, user, deleteSspsaTask }) => {
  const filteredSspsaList = mostRecentSspsaList?.filter(
    sspsa => sspsa?.businessUnit === businessUnit
  )
  const canDeleteSspsaTask = hasRequiredPermission(user, CREATE_GENERATE_SSPSA_TASK_TYPE)

  return (
    <div>
      <Heading4>Most recent SSPSA tasks</Heading4>
      <div className="ln-c-table-container">
        <table className="ln-c-table ln-c-table ln-u-text-align-center ln-u-border-bottom">
          <thead className="ln-c-table__header">
            <tr className="ln-c-table__row">
              <HeaderCell className="c-sspsa-header-cell-sspsa-list">Cycle title</HeaderCell>
              <HeaderCell className="c-sspsa-header-cell-sspsa-list">Status</HeaderCell>
              <HeaderCell className="c-sspsa-header-cell-sspsa-list">Effective date</HeaderCell>
              <HeaderCell className="c-sspsa-header-cell-sspsa-list">End date</HeaderCell>
              <HeaderCell className="c-sspsa-header-cell-sspsa-list">Created At</HeaderCell>
              <HeaderCell className="c-sspsa-header-cell-sspsa-list">Created By</HeaderCell>
            </tr>
          </thead>
          <tbody>
            {filteredSspsaList.map(sspsaTask => (
              <tr
                data-control="sspsa-row"
                key={sspsaTask.id}
                className="c-sspsa-row"
                data-pos={sspsaTask.id}
              >
                <Cell>{sspsaTask.cycleNameWithGroup}</Cell>
                <Cell>{sspsaTask.status}</Cell>
                <Cell>{sspsaTask.effectiveDate}</Cell>
                <Cell>{sspsaTask.endDate}</Cell>
                <Cell>{moment(sspsaTask.createdAt).format('YYYY-MM-DD hh:mm a')}</Cell>
                <Cell>{sspsaTask.createdBy}</Cell>
                <Cell>
                  {canDeleteSspsaTask && sspsaTask.status !== 'in-progress' && (
                    <>
                      <DeleteDialog
                        sspsaTaskId={sspsaTask.sspsaTaskId}
                        deleteSspsaTask={deleteSspsaTask}
                        element={DeleteButton}
                        disabled={false}
                      />
                    </>
                  )}
                </Cell>
              </tr>
            ))}
            {filteredSspsaList.length === 0 && <Cell>No SSPSA tasks available</Cell>}
          </tbody>
        </table>
      </div>
    </div>
  )
}

Component.propTypes = {
  mostRecentSspsaList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      cycleNameWithGroup: PropTypes.string,
      status: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
    })
  ),
  deleteSspsaTask: PropTypes.func.isRequired,
  businessUnit: PropTypes.string,
  user: PropTypes.shape({}).isRequired,
}

Component.defaultProps = {
  businessUnit: '',
  mostRecentSspsaList: [],
}

export default connect(null)(Component)
