import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

import Quantity from './Quantity'

const Search = ({ onSearchChange, quantity, displayMessage, disabled }) => (
  <div className="ln-o-matrix-list ln-u-soft-bottom">
    <div className="ln-o-matrix-list__item">
      <Field
        name="search"
        component="input"
        className="ln-c-text-input"
        placeholder="Search"
        type="search"
        onChange={onSearchChange}
        disabled={disabled}
      />
    </div>
    <div className="ln-o-matrix-list__item">
      <Quantity quantity={quantity} displayMessage={displayMessage} />
    </div>
  </div>
)

Search.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  displayMessage: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default Search
