import { connect } from 'react-redux'
import { push } from 'react-router-redux'

import { createSpacePlan, updateSpacePlanById, approveSpacePlanById } from '../../store'

import Form from './Form'
import mapStateToProps from './mapStateToProps'

const returnAction = () => push('/space-plans')

const updateSpacePlanInfoById = ({ spacePlan, id }) => {
  const action =
    spacePlan.status === 'approved'
      ? approveSpacePlanById({ id, spacePlan })
      : updateSpacePlanById({ id, spacePlan })
  return action
}

const mapDispatchToProps = dispatch => ({
  submit: async (spacePlan, id) => {
    const action = id ? updateSpacePlanInfoById({ spacePlan, id }) : createSpacePlan(spacePlan)

    await dispatch(action)
    dispatch(returnAction())
  },
  cancel: () => dispatch(returnAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
