import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { FilledButton } from '@jsluna/react'
import { TextInput, Select } from '@jsluna/form'

import { getLayoutTypeLabel } from 'src/utils/layoutTypes'
import sortNatural from 'src/utils/sortNatural'

import Cell from 'src/components/RowCell'

const toOption = ({ id, name }) => ({
  id,
  label: getLayoutTypeLabel(name),
  value: name,
})

const getLayout = (type, layouts) =>
  layouts.find(({ name, layoutType }) => name === type || layoutType === type)

const AddLayoutRow = ({ layouts, allLayouts, addLayout }) => {
  const defaultLayout = {
    layoutType: '',
    defaultCapacity: '',
    minCapacity: '',
    maxCapacity: '',
  }
  const [layout, setLayout] = useState(defaultLayout)
  const { layoutType, defaultCapacity, minCapacity, maxCapacity } = layout
  const { hasCapacity } = getLayout(layoutType, allLayouts) || {
    hasCapacity: false,
  }

  const handleChange = e => {
    const { name, value } = e.target

    setLayout(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  return (
    <tr data-control="add-layout-row">
      <Cell>
        <Select
          name="layoutType"
          placeholder="Please select a layout"
          options={sortNatural([...layouts].map(toOption), 'label')}
          value={layoutType}
          onChange={handleChange}
        />
      </Cell>
      <Cell>
        <TextInput
          name="defaultCapacity"
          value={defaultCapacity}
          onChange={handleChange}
          disabled={!hasCapacity}
        />
      </Cell>
      <Cell>
        <TextInput
          name="minCapacity"
          value={minCapacity}
          onChange={handleChange}
          disabled={!hasCapacity}
        />
      </Cell>
      <Cell>
        <TextInput
          name="maxCapacity"
          value={maxCapacity}
          onChange={handleChange}
          disabled={!hasCapacity}
        />
      </Cell>
      <Cell>
        <FilledButton
          data-control="add-layout"
          fullWidth
          onClick={() => {
            addLayout({ layoutType, defaultCapacity, minCapacity, maxCapacity })
            setLayout(defaultLayout)
          }}
          disabled={!layoutType}
        >
          Add
        </FilledButton>
      </Cell>
    </tr>
  )
}

AddLayoutRow.propTypes = {
  layouts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  allLayouts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      hasCapacity: PropTypes.bool,
      id: PropTypes.number,
    })
  ).isRequired,
  addLayout: PropTypes.func.isRequired,
}

export default AddLayoutRow
