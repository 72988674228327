import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  clearDataHandler,
  fullFilledDataHandler,
  handleCreateAsyncThunkResult,
  initialState,
  pendingDataHandler,
  rejectedDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { GetAvailablePreviewSspsas } from 'src/api/Sspsa'

const NAMESPACE = 'AvailablePsaPreview'
const STORE_PATH = `modules/${NAMESPACE}`
const buildThunkPrefix = thunkName => `${STORE_PATH}/${thunkName}`
const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const fetchAvailablePsaPreview = createAsyncThunk(
  buildThunkPrefix('fetchAvailablePsaPreview'),
  async (_, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const fetchAvailablePsaPreviewInstance = new GetAvailablePreviewSspsas(store)

    const response = await handleCreateAsyncThunkResult(
      fetchAvailablePsaPreviewInstance,
      dispatch,
      rejectWithValue
    )
    return response
  }
)
const availablePsaPreviewSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAvailablePsaPreview.pending, pendingDataHandler)
    builder.addCase(fetchAvailablePsaPreview.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchAvailablePsaPreview.rejected, rejectedDataHandler)
    builder.addCase(actionClearData, clearDataHandler)
  },
})

export { actionClearData, fetchAvailablePsaPreview, availablePsaPreviewSlice }

export default { [NAMESPACE]: availablePsaPreviewSlice.reducer }
