import Api from '../Base'

class PatchShelfArtifact extends Api {
  requestUrl = `/api/shelfArtifact/${this.getParams().id}`

  method = 'PATCH'

  formatRequestData = params => params.artifact
}

export default PatchShelfArtifact
