import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

import CheckboxGroup from './CheckboxGroup'

const FormikComponent = ({ options, label, name, disabled }) => (
  <div>
    {label && <div className="ln-c-label ln-u-soft-right">{label}</div>}
    <Field name={name} options={options} component={CheckboxGroup} disabled={disabled} />
  </div>
)

FormikComponent.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  disabled: PropTypes.bool,
}

FormikComponent.defaultProps = {
  label: undefined,
  disabled: false,
}

export default FormikComponent
