import { isAllowedRoute } from 'src/utils/routing'

const buildMenu = (allMenuItems = [], user, variables) => {
  const menu = []

  allMenuItems.forEach(menuItem => {
    if (menuItem.items) {
      const items = buildMenu(menuItem.items, user)
      if (items.length > 0) {
        menu.push({ ...menuItem, items })
      }
    } else if (isAllowedRoute(user, menuItem.pathForPermissions || menuItem.path)) {
      menu.push(menuItem)
    }
  })

  if (variables?.TOGGLE_GROUP_MANAGEMENT === 'false') {
    const index = menu.findIndex(x => x.title === 'Groups')
    if (index > 0) {
      menu.splice(index, 1)
    }
  }

  return menu
}

export default buildMenu
