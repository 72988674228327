import { hasRequiredPermission } from 'src/utils/permissions'

import {
  UPDATE_SPACE_ASSIGNMENTS,
  UPDATE_SPACE_ASSIGNMENTS_START_DATE,
  UPDATE_SPACE_ASSIGNMENTS_END_DATE,
  UPDATE_SPACE_ASSIGNMENTS_TITLE,
  UPDATE_SPACE_ASSIGNMENTS_COMMENT,
  UPDATE_SPACE_ASSIGNMENTS_PROPOSITION,
  UPDATE_SPACE_ASSIGNMENTS_CATEGORIES,
  UPDATE_SPACE_ASSIGNMENTS_STORE_TITLE_COMMENT,
} from 'src/constants/permissions'

const disabled = ({ user, isNewAssignment }) => ({
  startDate: !hasRequiredPermission(user, UPDATE_SPACE_ASSIGNMENTS_START_DATE),
  endDate: !hasRequiredPermission(user, UPDATE_SPACE_ASSIGNMENTS_END_DATE),
  title: !hasRequiredPermission(user, UPDATE_SPACE_ASSIGNMENTS_TITLE),
  comment: !hasRequiredPermission(user, UPDATE_SPACE_ASSIGNMENTS_COMMENT),
  proposition: !hasRequiredPermission(user, UPDATE_SPACE_ASSIGNMENTS_PROPOSITION),
  categories: !hasRequiredPermission(user, UPDATE_SPACE_ASSIGNMENTS_CATEGORIES),
  storeTitleComment: !hasRequiredPermission(user, UPDATE_SPACE_ASSIGNMENTS_STORE_TITLE_COMMENT),
  layoutType: !(hasRequiredPermission(user, UPDATE_SPACE_ASSIGNMENTS) && isNewAssignment),
})

export default disabled
