import React from 'react'
import { Route, IndexRoute } from 'react-router'

import { authorized } from 'src/hoc/auth'
import SpacePlansList from 'src/modules/SpacePlans'
import SpacePlansDetail from 'src/modules/SpacePlansDetail'
import DetailsTab from 'src/modules/SpacePlansDetail/Details'
import PlannerTab from 'src/modules/SpacePlansDetail/Planner'
import ApprovalsTab from 'src/modules/SpacePlansDetail/Approvals'
import CyclesTab from 'src/modules/SpacePlansDetail/Cycles'
import TimelineTab from 'src/modules/SpacePlansDetail/Timeline'

const route = (
  <Route name="Space Plans" path="space-plans" showInNav>
    <IndexRoute component={authorized(SpacePlansList)} />
    <Route path="create" component={authorized(SpacePlansDetail)}>
      <IndexRoute component={authorized(DetailsTab)} />
    </Route>
    <Route path=":id" component={authorized(SpacePlansDetail)}>
      <IndexRoute component={authorized(DetailsTab)} />
      <Route path="planner" component={authorized(PlannerTab)} />
      <Route path="approvals" component={authorized(ApprovalsTab)} />
      <Route path="cycles" component={authorized(CyclesTab)} />
      <Route path="timeline" component={authorized(TimelineTab)} />
    </Route>
  </Route>
)

export default route
