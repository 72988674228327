import PropTypes from 'prop-types'
import React from 'react'
import { TextButton } from '@jsluna/react'

const QuickAddButton = ({ onClick, canAddProduct, disableAddProduct }) =>
  canAddProduct && (
    <span className="ln-u-padding" data-control="shelf-products-quick-add">
      <TextButton onClick={onClick} disabled={disableAddProduct}>
        Quick Add Product
      </TextButton>
    </span>
  )

QuickAddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  canAddProduct: PropTypes.bool.isRequired,
  disableAddProduct: PropTypes.bool.isRequired,
}

export default QuickAddButton
