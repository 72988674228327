import { connect } from 'react-redux'

import { CHANGE_CONTROL_UNLOCKED_ID } from 'src/constants/cycles'
import { hasRequiredLayoutType, isPalletOrStackLayout } from 'src/utils/layoutTypes'
import { hasRequiredPermission } from 'src/utils/permissions'
import { selectSelf, selectAllowedCategories } from 'src/store/data/selectors'
import { CREATE_SHELVES_PRODUCTS, CREATE_SHELVES_PRODUCTS_ALL } from 'src/constants/permissions'

import { selectSpaceAssignmentWithSpaceInstances } from 'src/modules/SpaceAssignment/store/selectors'
import { quickAddProduct } from '../../store/actions'
import QuickAddButton from './QuickAddButton'

const isNotEmpty = list => list.length > 0

const mapStateToProps = (state, { layoutType, shelf }) => {
  const { categories, palletOrStackType } = shelf
  const user = selectSelf(state)
  const canCreateProduct = hasRequiredPermission(user, CREATE_SHELVES_PRODUCTS)
  const canAddProduct = isNotEmpty(categories) && canCreateProduct

  const allowedCategories = selectAllowedCategories(state, categories)
  const canCreateAllProducts = hasRequiredPermission(user, CREATE_SHELVES_PRODUCTS_ALL)
  const canAddProductForCategory = isNotEmpty(allowedCategories) || canCreateAllProducts

  const {
    changeControlStatus = CHANGE_CONTROL_UNLOCKED_ID,
  } = selectSpaceAssignmentWithSpaceInstances(state)
  const isLockedForChangeControl = changeControlStatus !== CHANGE_CONTROL_UNLOCKED_ID

  const disableAddProduct =
    !canAddProductForCategory ||
    isLockedForChangeControl ||
    (isPalletOrStackLayout(layoutType) && !hasRequiredLayoutType(palletOrStackType))

  return {
    canAddProduct,
    disableAddProduct,
  }
}

const mapDispatchToProps = (dispatch, { shelf }) => ({
  onClick: () => dispatch(quickAddProduct(shelf.id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuickAddButton)
