import { connect } from 'react-redux'

import { CHANGE_CONTROL_UNLOCKED_ID } from 'src/constants/cycles'
import { isDraft, hasChange, hasPendingChange } from 'src/utils/changeControls'
import { hasRequiredPermission, hasRequiredCategory } from 'src/utils/permissions'

import {
  UPDATE_SHELVES_PRODUCTS_ALL,
  UPDATE_SHELVES_PRODUCTS_OWN,
  UPDATE_SHELVES_PRODUCTS_OWN_CATEGORY,
  DELETE_SHELVES_PRODUCTS_ALL,
  DELETE_SHELVES_PRODUCTS_OWN,
  DELETE_SHELVES_PRODUCTS_OWN_CATEGORY,
} from 'src/constants/permissions'

import { selectSelf } from 'src/store/data/selectors'

import {
  selectSpaceAssignmentWithSpaceInstances,
  selectSpaceInstanceById,
} from 'src/modules/SpaceAssignment/store/selectors'
import {
  deleteProducts as deleteProductsAction,
  deleteShelfArtifact as deleteShelfArtifactAction,
  updateShelfArtifact as updateShelfArtifactAction,
  refreshSpaceInstances,
  moveShelfArtifact,
  moveProduct,
  moveProductToShelf,
} from '../../../store/actions'
import { selectUi } from '../../../store'

import Products from './Products'

const isSameUser = (user1, user2) => user1.id === user2.id

const mapStateToProps = (state, { shelf }) => {
  const { spaceInstanceId } = shelf
  const user = selectSelf(state)

  const { viewChangeControl } = selectUi(state)
  const isChangeControlView = !!viewChangeControl
  const { shelves } = selectSpaceInstanceById(state, spaceInstanceId)

  const spaceAssignment = selectSpaceAssignmentWithSpaceInstances(state)
  const canUpdate = product =>
    hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_ALL) ||
    (hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_OWN_CATEGORY) &&
      hasRequiredCategory(user, product.category.id)) ||
    (hasRequiredPermission(user, UPDATE_SHELVES_PRODUCTS_OWN) && product.createdBy.id === user.id)

  const canDelete = product =>
    hasRequiredPermission(user, DELETE_SHELVES_PRODUCTS_ALL) ||
    (hasRequiredPermission(user, DELETE_SHELVES_PRODUCTS_OWN_CATEGORY) &&
      hasRequiredCategory(user, product.category.id)) ||
    (hasRequiredPermission(user, DELETE_SHELVES_PRODUCTS_OWN) && product.createdBy.id === user.id)

  const {
    changeControlStatus = CHANGE_CONTROL_UNLOCKED_ID,
    pendingChangeControl,
    startDate: assignmentStartDate,
    endDate: assignmentEndDate,
  } = spaceAssignment

  const isLockedForChangeControl = changeControlStatus !== CHANGE_CONTROL_UNLOCKED_ID
  const isDraftChangeControl = isDraft(pendingChangeControl)

  const canUpdateInChangeControl = () =>
    isChangeControlView && isDraftChangeControl && isSameUser(user, pendingChangeControl.createdBy)

  const canMove = product =>
    isLockedForChangeControl
      ? canUpdate(product) && canUpdateInChangeControl(product)
      : canUpdate(product)

  const disableUpdateDeleteProduct = product =>
    isLockedForChangeControl &&
    (hasPendingChange(pendingChangeControl)(product) || !canUpdateInChangeControl(product))

  return {
    assignmentStartDate,
    assignmentEndDate,
    canDelete,
    canMove,
    canUpdate,
    disableUpdateDeleteProduct,
    hasChange: hasChange(viewChangeControl ? { id: viewChangeControl } : null),
    hasPendingChange: hasPendingChange(pendingChangeControl),
    isChangeControlView,
    isDraftChangeControl,
    isLockedForChangeControl,
    pendingChangeControl,
    shelves,
    spaceAssignment,
    spaceInstanceId,
  }
}

const mapDispatchToProps = (dispatch, { shelf }) => ({
  deleteProducts: products => dispatch(deleteProductsAction(products, shelf.spaceAssignmentId)),
  deleteShelfArtifact: artifact => dispatch(deleteShelfArtifactAction(artifact, shelf)),
  moveProduct: (id, fromPosition, toPosition) =>
    dispatch(moveProduct(id, shelf, fromPosition, toPosition, shelf.spaceAssignmentId)),
  moveProductToShelf: (id, shelfId, toPosition) =>
    dispatch(moveProductToShelf(id, shelfId, toPosition, shelf.spaceAssignmentId)),
  moveShelfArtifact: (id, toPosition) => dispatch(moveShelfArtifact(id, toPosition, shelf)),
  updateShelfArtifact: (artifact, description) =>
    dispatch(updateShelfArtifactAction(artifact, description, shelf)),
  refresh: async () => {
    await dispatch(refreshSpaceInstances(shelf.spaceAssignmentId))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Products)
