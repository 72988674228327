/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import buildShelfTitle from 'src/utils/shelfTitle'
import formatDate from 'src/utils/formatDate'

import createPosName from 'src/utils/createPOSName'
import Cell from '../../RowCell'
import HeaderCell from '../../HeaderCell'

import { buildShelfFill } from './buildEquipment'

const EquipmentSeparatedLayout = props => {
  const {
    equipment: { disclaimerMessage, locations },
    rows,
    spaceTitle,
    storePSAFlag,
    disablePOSAdviceColumn,
  } = props
  const shelfProducts = rows.map(shelfProduct => {
    return {
      shelf: shelfProduct.shelfPosition,
      shelfProductId: shelfProduct.id,
      ...shelfProduct,
    }
  })

  const shelfProductDetails = storePSAFlag ? shelfProducts : rows
  const rowsByShelfProduct = shelfProductDetails.reduce((acc, row) => {
    acc[row.shelfProductId] = row
    return acc
  }, {})

  const getClassNames = shelf =>
    classNames('ln-c-table__row c-psa-row', {
      'ln-u-bg-color-grey-10': shelf % 2 === 0,
      'ln-u-bg-color-white': shelf % 2 === 1,
    })

  const legacyClassName = disablePOSAdviceColumn ? '-legacy' : ''

  return (
    <>
      <p className="ln-u-font-weight-bold">
        {disclaimerMessage} {spaceTitle}
      </p>
      <div className="ln-c-table-container">
        <table className="ln-c-table c-psa-table ln-c-table--fixed ln-u-text-align-center ln-u-border-bottom">
          <thead className="ln-c-table__header">
            <tr className="ln-c-table__header-row">
              <HeaderCell className={`c-psa-header-cell-shelf${legacyClassName}`}>
                Shelf Position
              </HeaderCell>
              <HeaderCell className="c-psa-header-cell-sku">SKU</HeaderCell>
              <HeaderCell className={`c-psa-header-cell-description${legacyClassName}`}>
                Description
              </HeaderCell>
              <HeaderCell className="c-psa-header-cell-shelf-fill">Shelf Fill</HeaderCell>
              <HeaderCell className={`c-psa-header-cell-start-date${legacyClassName}`}>
                Start
              </HeaderCell>
              <HeaderCell className={`c-psa-header-cell-end-date${legacyClassName}`}>
                End
              </HeaderCell>
              <HeaderCell className={`c-psa-header-cell-facings${legacyClassName}`}>
                Facings
              </HeaderCell>
              {!disablePOSAdviceColumn && (
                <HeaderCell className="c-psa-header-cell-point-of-sale-advice">
                  POS Advice
                </HeaderCell>
              )}
              <HeaderCell className={`c-psa-header-cell-store-comment${legacyClassName}`}>
                Store Comment
              </HeaderCell>
            </tr>
          </thead>
          <tbody className="ln-c-table__body">
            {locations.map(location => {
              const { description, layouts, name, rowLabeling } = location

              return (
                <Fragment key={name}>
                  <tr className="ln-c-table__row c-psa-row-divider">
                    <Cell
                      className="c-psa-cell-equipment-location"
                      columnSpan={disablePOSAdviceColumn ? 8 : 9}
                    >
                      {description}
                    </Cell>
                  </tr>

                  {layouts.length === 0 && (
                    <tr className="ln-c-table__row c-psa-row-no-products">
                      <Cell className="c-psa-cell" columnSpan={disablePOSAdviceColumn ? 8 : 9}>
                        Products have been added to the space but the Frozen merchandising has not
                        been completed
                      </Cell>
                    </tr>
                  )}

                  {layouts.map((layout, layoutIndex) => {
                    const { index: shelfIndex } = layout
                    const row = rowsByShelfProduct[layout.shelfProductId]
                    const maxNumShelves = layouts.reduce(
                      (max, l) => (l.index > max ? l.index : max),
                      layouts[0].index
                    )

                    if (!row) return null

                    const shelf =
                      rowLabeling === 'alphabetical'
                        ? buildShelfTitle(shelfIndex, maxNumShelves + 1)
                        : layoutIndex + 1

                    return (
                      <Fragment
                        key={`${name}-${row.shelfProductId}-${layout.position}-${layoutIndex}`}
                      >
                        <tr className={getClassNames(shelfIndex)}>
                          <Cell className="c-psa-cell-shelf">{shelf}</Cell>
                          <Cell className="c-psa-cell-sku">
                            {row.sku}
                            {row.oldSku && ` (${row.oldSku})`}
                          </Cell>
                          <Cell className="c-psa-cell-description">{row.description}</Cell>
                          <Cell className="c-psa-cell-shelf-fill">{buildShelfFill(row)}</Cell>
                          <Cell className="c-psa-cell-start-date">
                            {formatDate(row.startDate, 'D-MMM')}
                          </Cell>
                          <Cell className="c-psa-cell-end-date">
                            {formatDate(row.endDate, 'D-MMM')}
                          </Cell>
                          <Cell className="c-psa-cell-facings">{row.facingsOnShelf}</Cell>
                          {!disablePOSAdviceColumn && (
                            <Cell className="c-psa-cell-point-of-sale-advice">
                              {createPosName(row.pointOfSaleAdvice || {})}
                            </Cell>
                          )}
                          <Cell className="c-psa-cell-store-comment">{row.storeComment}</Cell>
                        </tr>
                      </Fragment>
                    )
                  })}
                </Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

EquipmentSeparatedLayout.propTypes = {
  equipment: PropTypes.shape({
    name: PropTypes.string.isRequired,
    disclaimerMessage: PropTypes.string.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        rowLabeling: PropTypes.string,
        layouts: PropTypes.arrayOf(
          PropTypes.shape({
            index: PropTypes.number,
            location: PropTypes.oneOf(['left', 'right', 'top', 'well']),
            position: PropTypes.number,
            shelfProductId: PropTypes.string,
          })
        ).isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      endDate: PropTypes.string.isRequired,
      facingsOnShelf: PropTypes.number,
      isShelfRsb: PropTypes.bool,
      shelf: PropTypes.number,
      shelfPosition: PropTypes.number,
      shelfFill: PropTypes.number,
      shelfFillType: PropTypes.string,
      sku: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      storeComment: PropTypes.string,
      pointOfSaleAdvice: PropTypes.shape({
        type: PropTypes.string,
        paCode: PropTypes.string,
        description: PropTypes.string,
      }),
    })
  ).isRequired,
  spaceTitle: PropTypes.string.isRequired,
  storePSAFlag: PropTypes.bool.isRequired,
  disablePOSAdviceColumn: PropTypes.bool.isRequired,
}

export default EquipmentSeparatedLayout
