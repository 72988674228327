import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Card } from '@jsluna/react'

import { CHANGE_CONTROL_UNLOCKED_ID } from 'src/constants/cycles'
import { buildCategoryOptions } from 'src/utils/buildCategoryOptions'
import sortNatural from 'src/utils/sortNatural'

import Pagination from 'src/components/Pagination'
import SpacesFilter from 'src/components/SpacesFilter'
import LoadingCard from 'src/components/LoadingCard'

import { masterPSAentryType, paginationType, selectOptionType } from 'src/types'

import { selectCategories } from 'src/store/modules/asyncThunks/categories'
import { YOUR_CATEGORIES_OPTION } from 'src/constants/categories'
import {
  selectDeptBuyers,
  selectFilters,
  selectMasterPsaEntries,
  selectMasterPsaEntriesStatus,
  selectPagination,
} from './store'
import {
  actionGetData,
  actionResetFilter,
  actionSetFilter,
  actionMoveProduct,
} from './store/actions'
import MasterPsaEntry from './MasterPsaEntry'

const toOption = ({ label, id, title }) => ({
  id,
  label: label || title,
  value: id,
})

const MasterPsaComponent = ({
  applyFilter,
  resetFilter,
  entries,
  getPage,
  pagination,
  moveProduct,
  cycle: { changeControlStatus },
  categoryOptions,
  category,
  setFilter,
  spaceTitle,
  deptBuyerOptions,
  buyers,
  isMasterPsaEntriesLoading,
}) => (
  <Card>
    <SpacesFilter
      applyFilter={applyFilter}
      category={category}
      categoryOptions={categoryOptions}
      deptBuyerOptions={deptBuyerOptions}
      resetFilter={resetFilter}
      setFilter={setFilter}
      space={spaceTitle}
      buyers={buyers}
    />

    <Pagination getPage={getPage} pagination={pagination} className="ln-u-push-bottom" />
    {isMasterPsaEntriesLoading && <LoadingCard />}
    {!isMasterPsaEntriesLoading &&
      (entries.length ? (
        entries.map(entry => (
          <MasterPsaEntry
            key={entry.spaceInstanceId}
            moveProduct={moveProduct}
            {...entry}
            isLockedForChangeControl={changeControlStatus !== CHANGE_CONTROL_UNLOCKED_ID}
          />
        ))
      ) : (
        <p className="ln-u-flush-bottom">There are currently no PSA entries</p>
      ))}
  </Card>
)

MasterPsaComponent.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  categoryOptions: PropTypes.arrayOf(selectOptionType).isRequired,
  cycle: PropTypes.shape({ changeControlStatus: PropTypes.string.isRequired }).isRequired,
  deptBuyerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  entries: PropTypes.arrayOf(masterPSAentryType).isRequired,
  getPage: PropTypes.func.isRequired,
  moveProduct: PropTypes.func.isRequired,
  pagination: paginationType.isRequired,
  resetFilter: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  spaceTitle: PropTypes.string.isRequired,
  buyers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  isMasterPsaEntriesLoading: PropTypes.bool.isRequired,
}

const getSortedOptions = (list, sortBy) => {
  const sortedNatural = sortNatural(list, sortBy)

  return sortedNatural.map(toOption)
}

const removeDuplicateCatBuyers = list => {
  const result = list.reduce((acc, cv) => {
    const isDuplicate = acc.some(({ title }) => title === cv.title)
    return isDuplicate ? acc : [...acc, cv]
  }, [])

  return result
}

const mapStateToProps = state => {
  const allCategories = buildCategoryOptions([...selectCategories(state)])
  const categoryOptions = [YOUR_CATEGORIES_OPTION, ...getSortedOptions(allCategories, 'label')]
  const deptBuyers = removeDuplicateCatBuyers(selectDeptBuyers(state))
  const deptBuyerOptions = getSortedOptions(deptBuyers, 'title')
  const { category, space, buyers } = selectFilters(state)

  return {
    entries: selectMasterPsaEntries(state),
    pagination: selectPagination(state),
    deptBuyerOptions,
    categoryOptions,
    category,
    space,
    buyers,
    isMasterPsaEntriesLoading: selectMasterPsaEntriesStatus(state),
  }
}

const mapDispatchToProps = (dispatch, { cycle }) => ({
  applyFilter: () => {
    dispatch(actionGetData({ cycle }))
    window.history.pushState(null, null, `#${1}`)
  },
  resetFilter: async () => {
    await dispatch(actionResetFilter())
    await dispatch(actionGetData({ cycle }))
    window.history.pushState(null, null, `#${1}`)
  },
  getPage: number => dispatch(actionGetData({ cycle, number })),
  moveProduct: (id, shelf, fromPosition, toPosition) =>
    dispatch(actionMoveProduct(id, shelf, fromPosition, toPosition, { cycle })),
  setFilter: (filterType, value) => dispatch(actionSetFilter(filterType, value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MasterPsaComponent)
export { MasterPsaComponent, removeDuplicateCatBuyers }
