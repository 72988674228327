import loader from 'src/hoc/loader'

import { hasRequiredPermission } from 'src/utils/permissions'
import { UPDATE_GROUPS } from 'src/constants/permissions'
import { fetchGroupInfo, fetchSpaces, fetchSpacePlans, actionClearData } from './store'
import Form from './Form'

const getData = async ({ id, user }, dispatch) => {
  const actions = [fetchSpacePlans()]
  if (id) {
    const data = await dispatch(fetchGroupInfo(id)).unwrap()
    const hasWriteAccess = hasRequiredPermission(user, UPDATE_GROUPS)
    if (hasWriteAccess) {
      actions.push(fetchSpaces({ spacePlanId: data?.spacePlan?.id, groupId: id }))
    }
  }
  await Promise.all(actions.map(a => dispatch(a)))
}
const options = {
  extractProps: ({ params, authData }) => ({ ...params, ...authData }),
  shouldRefresh: ({ id: previousId }, { id }) => previousId !== id,
  onUnmount: dispatch => dispatch(actionClearData()),
}
export default loader(getData, options)(Form)
