import { buildThunkPrefix } from 'src/utils/createAsyncThunkHandler'

import { createAsyncThunk } from '@reduxjs/toolkit'
import GetProductBySKU from 'src/api/Product'

const handleProductCreateAsyncThunkResult = async (
  fn,
  dispatch,
  rejectWithValue,
  responseWithoutHeader = true
) => {
  try {
    dispatch({ type: 'LOADING', payload: true })
    const response = await fn.call()
    return responseWithoutHeader && response.data ? response.data : response
  } catch (error) {
    return rejectWithValue(error.response)
  } finally {
    dispatch({ type: 'LOADING', payload: false })
  }
}

const fetchProductBySKU = NAMESPACE =>
  createAsyncThunk(
    buildThunkPrefix(NAMESPACE, 'fetchProductBySKU'),
    async (sku, { getState, dispatch, rejectWithValue }) => {
      const store = getState()
      const getProductBySKUInstance = new GetProductBySKU(store, { queryParams: sku })
      const response = await handleProductCreateAsyncThunkResult(
        getProductBySKUInstance,
        dispatch,
        rejectWithValue
      )
      return response
    }
  )

const productRejectedDataHandler = (state, action) => {
  if (state.isFetching) {
    return {
      ...state,
      isFetching: false,
      error: action.payload,
      data: {},
    }
  }
  return { ...state }
}

export { fetchProductBySKU, handleProductCreateAsyncThunkResult, productRejectedDataHandler }
