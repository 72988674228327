import moment from 'moment-timezone'

import { DATE_FORMAT } from './datePeriod'

const formatDate = (data, format = DATE_FORMAT, timezone) => {
  const momentDate = timezone ? moment.utc(data).tz(timezone) : moment(data)

  return data ? momentDate.format(format) : 'Not set'
}

export default formatDate
