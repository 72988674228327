const createStatus = statuses => {
  const ids = statuses.map(status => status.id)
  const byId = {}
  statuses.forEach(status => {
    byId[status.id] = status
  })

  const get = id => byId[id]

  const exists = id => !!get(id)

  const transitions = id => {
    const { transition } = get(id)
    return transition.map(get)
  }

  const isBefore = (id, refId) =>
    exists(id) && exists(refId) && ids.indexOf(id) < ids.indexOf(refId)

  const isEqualOrBefore = (id, refId) =>
    exists(id) && exists(refId) && ids.indexOf(id) <= ids.indexOf(refId)

  const isAfter = (id, refId) => exists(id) && exists(refId) && ids.indexOf(id) > ids.indexOf(refId)

  const isEqualOrAfter = (id, refId) =>
    exists(id) && exists(refId) && ids.indexOf(id) >= ids.indexOf(refId)

  return {
    get,
    transitions,
    exists,
    isAfter,
    isEqualOrAfter,
    isBefore,
    isEqualOrBefore,
  }
}

export default createStatus
