import React from 'react'
import { Heading3 } from '@jsluna/react'

import Section from 'src/components/Section'

const APIFailed = () => (
  <Section>
    <Heading3>Sorry, Something went wrong!</Heading3>
    <p>Please try again.</p>
  </Section>
)

export default APIFailed
