export const mandatoryWithChangeControl = [
  // Supply Chain
  'shelfFill',
  'shelfFillType',

  // Promotion
  'category',
]

export const mandatoryWithChangeControlNonFood = [
  // Supply Chain
  'shelfFill',
  'shelfFillType',
  'promoCaseSize',

  // Promotion
  'category',
  'promoMechanicValue',
  'packSize',
  'facingsOnShelf',
  'ubw',
  'profitPpp',
  'originalRetailPrice',
  'clearancePrice',

  // Non-Food Financials
  'percentSoldPromoPrice',
  'percentSoldClearancePrice',
  'salesForecastPromoCycle',
  'salesForecastLine',

  // Non-Food Stock
  'forecastPurchaseQuantity',
  'stockValueForPromo',
]
