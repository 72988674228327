import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, withFormik } from 'formik'
import { Card, Heading2, Form, FormGroup, GridWrapper, GridItem, Section } from '@jsluna/react'
import { TextInputField, SelectField } from '@jsluna/form/extensions/formik'

import { Controls } from 'src/components/Form'

import { Checkbox } from 'src/components/FormikComponents'
import handleSubmit from './handleSubmit'
import validate from './validate'

const businessUnitOptions = [
  { value: 'food', label: 'Food' },
  { value: 'non-food', label: 'Non-food' },
]

const Component = ({ handleSubmit: onSubmit, isSubmitting, id, theme, cancel, setFieldValue }) => {
  const [themeColor, setThemeColor] = useState(theme)
  return (
    <Form onSubmit={onSubmit}>
      <Heading2>
        <span data-control="category-title">{id ? `Edit Category` : 'Create Category'}</span>
      </Heading2>
      <Section>
        <Card>
          <FormGroup name="category-fields">
            <GridWrapper matrix>
              <GridItem size="1/4">
                <Field
                  component={TextInputField}
                  label="Title"
                  name="title"
                  validationFirst={false}
                  required
                />
              </GridItem>
              <GridItem size="1/4">
                <Field
                  component={TextInputField}
                  label="Short Title"
                  name="shortTitle"
                  validationFirst={false}
                  required
                />
              </GridItem>
              <GridItem size="1/2">
                <Field
                  label="Business unit"
                  name="businessUnit"
                  component={SelectField}
                  options={businessUnitOptions}
                />
              </GridItem>
              {id && (
                <GridItem size="1/4@sm">
                  <div className="ln-c-form-group">
                    <span>Theme </span>
                    <input
                      type="color"
                      name="theme"
                      value={themeColor}
                      onChange={e => {
                        setThemeColor(e.target.value)
                        setFieldValue('theme', e.target.value)
                      }}
                    />
                  </div>
                </GridItem>
              )}
              <GridItem size="1/4@sm">
                <div className="ln-c-form-group">
                  <Field component={Checkbox} label="Price Lock" name="isPriceLock" />
                </div>
              </GridItem>
              <GridItem size="1/4@sm">
                <div className="ln-c-form-group">
                  <Field component={Checkbox} label="Active" name="active" />
                </div>
              </GridItem>
            </GridWrapper>
          </FormGroup>
          <Controls cancel={cancel} disabled={isSubmitting} submitText="Save" />
        </Card>
      </Section>
    </Form>
  )
}

Component.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

Component.defaultProps = {
  id: undefined,
  theme: '',
}

const wrappedForm = withFormik({
  validate,
  handleSubmit,
})(Component)

export default wrappedForm
