import React from 'react'
import PropTypes from 'prop-types'

import { Card, Heading4 } from '@jsluna/react'

import partition from 'src/utils/partition'
import Section from 'src/components/Section'
import Table from 'src/components/Table'
import columns from 'src/modules/Cycles/columns'
import { connect } from 'react-redux'
import { selectCycleBySpacePlan } from '../store'

const CycleCard = ({ title, cycles }) => (
  <Card data-control={`${title.toLowerCase()}-cycles`}>
    <Heading4>{title}</Heading4>
    {cycles.length > 0 ? (
      <Table
        type="cycles"
        columns={columns}
        dataSelector={() => cycles}
        secondarySortColumn="groupName"
      />
    ) : (
      <p className="ln-u-flush-bottom">
        No {title.toLowerCase()} cycles currently fall within this space plan
      </p>
    )}
  </Card>
)

CycleCard.propTypes = {
  title: PropTypes.string.isRequired,
  cycles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const Cycles = ({ cycles }) => {
  const [foodCycles, nonFoodCycles] = partition(cycles, cycle => cycle.businessUnit === 'food')

  return (
    <Section>
      <CycleCard title="Food" cycles={foodCycles} />
      <CycleCard title="Non-Food" cycles={nonFoodCycles} />
    </Section>
  )
}

Cycles.propTypes = {
  cycles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const mapStateToProps = state => ({ cycles: selectCycleBySpacePlan(state) || [] })

export default connect(mapStateToProps)(Cycles)
