import React from 'react'
import PropTypes from 'prop-types'

import MasterPsa from 'src/modules/MasterPsa'

const Component = ({ cycle }) => <MasterPsa cycle={cycle} />

Component.propTypes = {
  cycle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default Component
