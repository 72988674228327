import React from 'react'
import PropTypes from 'prop-types'
import { Card, Heading3 } from '@jsluna/react'

import StorePsa from 'src/modules/StorePsa'
import StoreSearch from 'src/modules/StoreSearch'

const Component = ({ cycle, storeId }) => (
  <Card>
    {!storeId ? (
      <div>
        <Heading3>Store search</Heading3>
        <StoreSearch route={`cycles/${cycle.businessUnit}/${cycle.id}/store-psa`} />
      </div>
    ) : (
      <>
        <StorePsa
          cycleId={cycle.id}
          storeId={storeId}
          businessUnit={cycle.businessUnit}
          storePSAFlag
          cycleStartDate={cycle.startDate}
          cycleEndDate={cycle.endDate}
        />
      </>
    )}
  </Card>
)

Component.propTypes = {
  cycle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    businessUnit: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }).isRequired,
  storeId: PropTypes.string,
}

Component.defaultProps = {
  storeId: null,
}

export default Component
