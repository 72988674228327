import pickSubmitChangeControl from 'src/store/api/actions/updateChangeControl'
import redirectAction from 'src/store/data/actions/redirect'

const handleSubmit = async (values, { props }) => {
  const { actionType, comment } = values
  const { businessUnit, cycleId, dispatch, id } = props
  const redirect = `/cycles/${businessUnit}/${cycleId}/change-controls`

  const statuses = {
    accept: 'accepted',
    approve: 'approved',
    reject: 'rejected',
    withdraw: 'withdrawn',
    amend: 'draft',
  }

  const payload = {
    status: statuses[actionType],
  }

  if (actionType === 'approve') {
    payload.approveComment = comment || ''
  }

  if (actionType === 'accept' || actionType === 'reject' || actionType === 'amend') {
    payload.resolutionComment = comment || ''
  }

  await dispatch(pickSubmitChangeControl(id, payload))
  dispatch(redirectAction(redirect))
}

export default handleSubmit
