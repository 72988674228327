import PropTypes from 'prop-types'
import React from 'react'
import { saveAs } from 'file-saver'

const withExport = ComposedComponent => {
  const wrappedComponentName =
    ComposedComponent.displayName || ComposedComponent.name || 'Component'

  const WithExport = ({ getExportsData, ...rest }) => {
    return (
      <ComposedComponent
        {...rest}
        onClick={async () => {
          const exportDetail = await getExportsData()
          const fileContents = new Blob(['\ufeff', exportDetail?.payload?.data], {
            type: 'application/octet-stream',
          })
          const fileName = exportDetail?.payload?.headers['content-disposition']?.split('"')[1]

          if (fileContents && fileName) saveAs(fileContents, fileName)
        }}
      />
    )
  }

  WithExport.displayName = `WithExport(${wrappedComponentName})`

  WithExport.propTypes = {
    getExportsData: PropTypes.func.isRequired,
  }

  return WithExport
}

export default withExport
