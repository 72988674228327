import { addOneDayToDate } from 'src/utils/day'

const MIN_PIXEL_WIDTH = 640

const buildScale = ({ startDate, endDate, zoom }) => ({
  start: new Date(startDate),
  end: addOneDayToDate(endDate),
  zoom,
  zoomMin: 15,
  zoomMax: 30,
  minWidth: MIN_PIXEL_WIDTH,
})

export default buildScale
