import React from 'react'

import withModal from 'src/hoc/withModal'

import Form from './Form'
import ModalButton from './ModalButton'

const Modal = withModal(Form)

export default props => <Modal element={ModalButton} {...props} />
