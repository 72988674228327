import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  SelectField,
  FlagWrapper,
  FlagBody,
  FlagComponent,
  OutlinedButton,
  Card,
} from '@jsluna/react'
import { Delete } from '@jsluna/icons'

import sortArray from 'src/utils/sortArray'

import WithRequiredPermissions from 'src/components/WithRequiredPermissions'
import {
  UPDATE_SHELVES_PRODUCTS_PRODUCE_TABLE_POSITIONS_ALL,
  UPDATE_SHELVES_PRODUCTS_PRODUCE_TABLE_POSITIONS_OWN_CATEGORY,
} from 'src/constants/permissions'
import { SHELVES_PRODUCTS_TYPE } from 'src/constants/types'

class ProduceCell extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editMode: false,
      shelfProduct: null,
      isSaving: false,
    }

    this.addProductToTable = this.addProductToTable.bind(this)
    this.deleteProductFromTable = this.deleteProductFromTable.bind(this)
    this.toggleAdd = this.toggleAdd.bind(this)
  }

  static getDerivedStateFromProps(props) {
    const { index: currentIndex, position: currentPosition, shelfProductLayouts } = props

    let shelfProduct = null
    let layoutId = null
    shelfProductLayouts.forEach(shelfProductWithLayout => {
      const { id, index, location, position } = shelfProductWithLayout
      if (currentIndex === index && currentPosition === position && !location) {
        shelfProduct = shelfProductWithLayout
        layoutId = id
      }
    })

    return { shelfProduct, layoutId }
  }

  toggleAdd() {
    this.setState(({ editMode }) => ({ editMode: !editMode }))
  }

  hasAllSpaceInstanceCategories() {
    const { userCategories, spaceInstanceCategories } = this.props
    const spaceInstanceCatIds = spaceInstanceCategories.map(category => category.id)
    const userCategoriesIds = userCategories.map(category => category.id)
    return !spaceInstanceCatIds.some(v => !userCategoriesIds.includes(v))
  }

  async addProductToTable(event) {
    const { index, instanceId, position, shelfProducts, addProducePosition } = this.props
    const location = null

    const shelfProduct = shelfProducts.find(sp => sp.id === event.target.value)

    const layout = {
      location,
      index,
      position,
      spaceInstanceId: instanceId,
    }

    this.setState(() => ({ isSaving: true }))
    await addProducePosition(shelfProduct.id, layout)
    this.setState(() => ({ editMode: false, isSaving: false }))
  }

  async deleteProductFromTable(layoutId) {
    const { deleteProducePosition } = this.props

    this.setState(() => ({ isSaving: true }))
    await deleteProducePosition(layoutId, true)
    this.setState(() => ({ editMode: false, isSaving: false }))
  }

  render() {
    const { shelfProducts } = this.props
    const { editMode, layoutId, shelfProduct, isSaving } = this.state
    const options = sortArray(
      shelfProducts
        .filter(product => product.type === SHELVES_PRODUCTS_TYPE)
        .map(({ id, description, sku }) => ({
          value: id,
          label: `${description} (${sku})`,
        })),
      'label'
    )
    const deleteContainer = (
      <button
        type="button"
        className="c-produce-cell__delete-button ln-c-button u-bg-none"
        data-control="remove-produce"
        onClick={() => this.deleteProductFromTable(layoutId)}
        disabled={isSaving}
      >
        <Delete />
      </button>
    )

    const editContainer = editMode ? (
      <FlagWrapper>
        <FlagBody>
          <SelectField
            name="select-shelf-product"
            options={options}
            className="ln-u-push-right ln-u-flush-bottom"
            onChange={this.addProductToTable}
            disabled={isSaving}
          />
        </FlagBody>
        <FlagComponent>
          <OutlinedButton
            disabled={isSaving}
            fullWidth
            data-control="cancel-add-produce"
            onClick={this.toggleAdd}
          >
            Cancel
          </OutlinedButton>
        </FlagComponent>
      </FlagWrapper>
    ) : (
      !shelfProduct && (
        <OutlinedButton fullWidth data-control="add-produce" onClick={this.toggleAdd}>
          Add Produce
        </OutlinedButton>
      )
    )

    return (
      <Card className="c-produce-cell ln-u-soft ln-u-push-bottom ln-u-bg-color-grey-10 ">
        {shelfProduct && (
          <>
            <span className="ln-u-text-align-center" data-control="shelf-product-produce">
              {`${shelfProduct.description} (${shelfProduct.sku})`}
            </span>
            <WithRequiredPermissions
              permission={UPDATE_SHELVES_PRODUCTS_PRODUCE_TABLE_POSITIONS_ALL}
            >
              {deleteContainer}
            </WithRequiredPermissions>
            <WithRequiredPermissions
              permission={UPDATE_SHELVES_PRODUCTS_PRODUCE_TABLE_POSITIONS_OWN_CATEGORY}
            >
              {this.hasAllSpaceInstanceCategories() && deleteContainer}
            </WithRequiredPermissions>
          </>
        )}

        <WithRequiredPermissions permission={UPDATE_SHELVES_PRODUCTS_PRODUCE_TABLE_POSITIONS_ALL}>
          {editContainer}
        </WithRequiredPermissions>
        <WithRequiredPermissions
          permission={UPDATE_SHELVES_PRODUCTS_PRODUCE_TABLE_POSITIONS_OWN_CATEGORY}
        >
          {this.hasAllSpaceInstanceCategories() && editContainer}
        </WithRequiredPermissions>
      </Card>
    )
  }
}

ProduceCell.propTypes = {
  index: PropTypes.number,
  position: PropTypes.number,
  instanceId: PropTypes.string.isRequired,
  shelfProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  shelfProductLayouts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  spaceInstanceCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  userCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  addProducePosition: PropTypes.func,
  deleteProducePosition: PropTypes.func,
}

ProduceCell.defaultProps = {
  index: 0,
  position: 0,
  addProducePosition: () => {},
  deleteProducePosition: () => {},
}

export default ProduceCell
