import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  handleCreateAsyncThunkResult,
  pendingDataHandler,
  fullFilledDataHandler,
  rejectedDataHandler,
  initialState,
  clearDataHandler,
} from 'src/utils/createAsyncThunkHandler'
import { GetSpacePlanCyclesAssignments } from 'src/api/SpacePlan'

const NAMESPACE = 'SpacePlanCyclesAssignments'
const fetchSpacePlanCyclesAssignments = createAsyncThunk(
  NAMESPACE,
  async ({ spacePlanId, groupId }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const getCyclesInstance = new GetSpacePlanCyclesAssignments(store, {
      params: { spacePlanId, groupId },
    })

    const response = await handleCreateAsyncThunkResult(
      getCyclesInstance,
      dispatch,
      rejectWithValue
    )

    return response
  }
)

const fetchSpacePlanCyclesAssignmentsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    changeSelectedGroup: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.SelectedGroup = action.payload
    },
    clearSpacePlannerData: clearDataHandler,
    createSpaceDetailWithCluter(state, action) {
      const {
        data: { spaceDetails },
      } = state

      const { clusterSpaceAssignmentsDetails, id: clusterBySpaceId } = action.payload
      const spaceDetailWithCluterData = spaceDetails.map(spaceDetail => {
        if (spaceDetail.id === clusterBySpaceId) {
          return {
            ...spaceDetail,
            clusterSpaceAssignmentsDetails,
          }
        }
        return spaceDetail
      })
      return {
        ...state,
        data: { ...state.data, spaceDetails: spaceDetailWithCluterData },
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSpacePlanCyclesAssignments.pending, pendingDataHandler)
    builder.addCase(fetchSpacePlanCyclesAssignments.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchSpacePlanCyclesAssignments.rejected, rejectedDataHandler)
  },
})

export default { [NAMESPACE]: fetchSpacePlanCyclesAssignmentsSlice.reducer }
export const {
  changeSelectedGroup,
  clearSpacePlannerData,
  createSpaceDetailWithCluter,
} = fetchSpacePlanCyclesAssignmentsSlice.actions

export { fetchSpacePlanCyclesAssignments }
