import {
  CLIPSTRIP_LAYOUT_TYPE,
  FROZEN_LAYOUT_TYPE,
  FROZEN_FULL_HEIGHT_LAYOUT_TYPE,
  FROZEN_HALF_HEIGHT_LAYOUT_TYPE,
  LAYOUT_TYPE_LABELS,
  PALLET_LAYOUT_TYPE,
  PRODUCE_LAYOUT_TYPE,
  PRODUCE_3X4_LAYOUT_TYPE,
  PRODUCE_4X4_LAYOUT_TYPE,
  SHELF_LAYOUT_TYPE,
  SHIPPER_LAYOUT_TYPE,
  STACK_LAYOUT_TYPE,
  WELL_LAYOUT_TYPE,
} from 'src/constants/layoutTypes'

import { PALLET_AND_STACK_TYPE_OPTIONS } from 'src/constants/palletOrStackTypes'

import capitalise from './capitalise'

export const isLayoutType = layoutType => type => layoutType === type
export const checkLayoutTypes = isLayoutTypes => layoutType =>
  isLayoutTypes.reduce((acc, fn) => acc || fn(layoutType), false)

export const isClipStrip = isLayoutType(CLIPSTRIP_LAYOUT_TYPE)
export const isShelf = isLayoutType(SHELF_LAYOUT_TYPE)
export const isShipper = isLayoutType(SHIPPER_LAYOUT_TYPE)
export const isStack = isLayoutType(STACK_LAYOUT_TYPE)
export const isPallet = isLayoutType(PALLET_LAYOUT_TYPE)
export const isProduce = isLayoutType(PRODUCE_LAYOUT_TYPE)
export const isProduce3X4 = isLayoutType(PRODUCE_3X4_LAYOUT_TYPE)
export const isProduce4X4 = isLayoutType(PRODUCE_4X4_LAYOUT_TYPE)
export const isFrozen = isLayoutType(FROZEN_LAYOUT_TYPE)
export const isFrozenFullHeight = isLayoutType(FROZEN_FULL_HEIGHT_LAYOUT_TYPE)
export const isFrozenHalfHeight = isLayoutType(FROZEN_HALF_HEIGHT_LAYOUT_TYPE)
export const isFrozenWell = isLayoutType(WELL_LAYOUT_TYPE)

export const isPalletOrStackLayout = checkLayoutTypes([isPallet, isStack])
export const isProduceTypeLayout = checkLayoutTypes([isProduce, isProduce3X4, isProduce4X4])
export const isFrozenTypeLayout = checkLayoutTypes([
  isFrozen,
  isFrozenFullHeight,
  isFrozenHalfHeight,
  isFrozenWell,
])

export const getPalletOrStackType = (layoutType, palletOrStackType = null) => {
  const UNSET = `${capitalise(layoutType)} type not set`
  const { label = UNSET } =
    PALLET_AND_STACK_TYPE_OPTIONS.find(({ value }) => value === palletOrStackType) || {}

  return label
}

export const getLayoutTypeLabel = (layoutType = SHELF_LAYOUT_TYPE) => LAYOUT_TYPE_LABELS[layoutType]

export const getProduceTableRows = layoutType => {
  if (isProduceTypeLayout(layoutType)) {
    return layoutType === PRODUCE_4X4_LAYOUT_TYPE ? 4 : 3
  }
  return 0
}

export const hasRequiredLayoutType = palletOrStackType => {
  const palletAndStackTypeValues = PALLET_AND_STACK_TYPE_OPTIONS.map(({ value }) => value)

  return palletAndStackTypeValues.includes(palletOrStackType)
}
