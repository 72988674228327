import { API_REQUEST } from '../constants'
import findStub from './functions'

const stubRequest = () => next => async action => {
  const { type, payload: { endpoint, method } = { endpoint: null, method: null } } = action
  const stubPromise = await findStub(endpoint, method)

  if (type === API_REQUEST && stubPromise) {
    return stubPromise
  }

  return next(action)
}

export default stubRequest
