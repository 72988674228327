import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { connect } from 'react-redux'

import { Card, Heading4 } from '@jsluna/react'

import { SSPSA_FOOD_VERSION_MESSAGES } from 'src/constants/sspsaFoodVersion'

import { SSPSA_NONFOOD_VERSION_MESSAGES } from 'src/constants/sspsaNonFoodVersion'

import capitalise from 'src/utils/capitalise'
import { DATE_ABBREVIATED } from 'src/utils/datePeriod'

import Section from 'src/components/Section'

const formatDateTitle = date => moment(date).format(DATE_ABBREVIATED)
const getWeekNumber = date => {
  const initialDate = new Date('0001-01-01')
  const startDate = new Date('2023-03-05')
  const effectiveDate = new Date(date)

  const differenceInStartTime = startDate.getTime() - initialDate.getTime()
  const differenceStartInDays = Math.round(differenceInStartTime / (1000 * 3600 * 24))

  const differenceInEffectiveTime = effectiveDate.getTime() - initialDate.getTime()
  const differenceEffectiveInDays = Math.round(differenceInEffectiveTime / (1000 * 3600 * 24))
  const weekCount = Math.floor((differenceEffectiveInDays - differenceStartInDays) / 7)
  return `${(weekCount % 52) + 1} `
}
const getEndDate = (startDate, endDate) => {
  if (endDate) {
    return moment(endDate)
  }
  return moment(startDate).add(6, 'days')
}
const getSspsaTextTitle = ({ title, startDate, endDate, businessUnit }) => {
  if (businessUnit === 'food' && startDate >= '2023-01-25' && startDate) {
    return `Week ${getWeekNumber(startDate)} (${formatDateTitle(startDate)} - ${formatDateTitle(
      getEndDate(startDate, endDate)
    )})`
  }
  return `${title} (${formatDateTitle(startDate)} - ${formatDateTitle(endDate)})`
}
const getMessage = (messages = []) => (type = '', name = '') =>
  messages.find(message => message.name === `${type}-range-and-space-${name}`)

const getDefaultMessage = (type, version) => {
  let title = ''
  if (type === 'food') {
    title = SSPSA_FOOD_VERSION_MESSAGES[version]
  } else if (type === 'gm') {
    title = SSPSA_NONFOOD_VERSION_MESSAGES[version]
  }
  return title
}

const formatLink = message => {
  const regex = new RegExp(
    '(?:http(s?)://?([^.s]+)?[^.s]+.[^s]+)(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[A-Z0-9+&@#/%=~_|$])',
    'ig'
  )

  const newMessage = message.replace(regex, '<a target="_blank" href="$&">$&</a>')

  return newMessage !== message ? (
    // eslint-disable-next-line react/no-danger
    <span dangerouslySetInnerHTML={{ __html: newMessage }} />
  ) : (
    message
  )
}

export const Component = ({ sspsas, version, messages, type }) => {
  const available = sspsas?.filter(sspsa => sspsa?.areSspsaGeneratedForThisPeriod).length > 0

  const getVersionMessage = getMessage(messages)
  const configuredMessage = getVersionMessage(type, version)
  const defaultMessage = getDefaultMessage(
    type,
    version,
    sspsas?.some(sspsa => sspsa?.areSspsaGeneratedForThisPeriod)
  )
  const message = configuredMessage?.enabled
    ? configuredMessage.value || defaultMessage
    : defaultMessage
  return (
    <Section className="ln-u-hard-bottom ln-u-flex-shrink ln-u-display-flex ln-u-flex-auto">
      <Card className="ln-u-hard-top">
        <Heading4 className="ln-u-margin-bottom*1/2">
          {(available || message) && capitalise(version)}
        </Heading4>
        {sspsas?.map(sspsa => {
          return (
            available && (
              <>
                <p
                  data-control="sspsa-text"
                  className="ln-u-hard-top ln-u-flush-bottom ln-u-soft-left"
                >
                  {getSspsaTextTitle(sspsa)}
                </p>
              </>
            )
          )
        })}

        {!available && message && (
          <p
            className="ln-u-soft-left ln-u-flush-bottom"
            data-control={`sspsa-error-message-${version}`}
          >
            {formatLink(message)}
          </p>
        )}
      </Card>
    </Section>
  )
}

Component.propTypes = {
  sspsas: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      businessUnit: PropTypes.string,
      title: PropTypes.string.isRequired,
      areSspsaGeneratedForThisPeriod: PropTypes.bool.isRequired,
    })
  ),
  version: PropTypes.string,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  type: PropTypes.string,
}

Component.defaultProps = {
  sspsas: null,
  version: null,
  messages: [],
  type: '',
}

export default connect(null)(Component)
