import { addDefaultCompareAndFormat } from 'src/utils/difference'

const changeControlSpecification = addDefaultCompareAndFormat({
  status: {
    label: 'Status',
    group: 'Status',
  },
})

export default addDefaultCompareAndFormat(changeControlSpecification)
