import { connect } from 'react-redux'

import loader from 'src/hoc/loader'

import { selectSelf } from 'src/store/data/selectors'

import { fetchMatchingShelfProductsById, selectSimilarShelfProducts } from '../../../store/modal'
import DeleteDialog from './DeleteDialog'

const getData = async ({ shelfProduct }, dispatch) => {
  await dispatch(fetchMatchingShelfProductsById(shelfProduct.id))
}
const options = {
  shouldRefresh: (previous, current) => previous.shelfId !== current.shelfId,
}

const mapStateToProps = state => {
  const user = selectSelf(state)
  const similarShelfProducts = selectSimilarShelfProducts(state)

  return {
    user,
    similarShelfProducts,
  }
}

const ConnectedComponent = connect(mapStateToProps)(DeleteDialog)

export default loader(getData, options)(ConnectedComponent)
