import React from 'react'

import loader from 'src/hoc/loader'
import NotFound from 'src/components/Errors/NotFound'

import { actionClearData, fetchNominationDetails } from './store'
import NominationsDetail from './NominationsDetail'

const getData = async ({ id }, dispatch) => {
  await dispatch(fetchNominationDetails(id)).unwrap()
}

const options = {
  Custom404: (
    <NotFound
      title="Nomination Not Found"
      message="The nomination you are looking for cannot be found."
    />
  ),
  extractProps: ({ params, authData }) => ({ ...params, ...authData }),
  shouldRefresh: ({ id: previousId }, { id }) => previousId !== id,
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(NominationsDetail)
