export const combineAndSortShelfItems = (products, artifacts) => {
  const shelfItems = [...artifacts, ...products]

  shelfItems.sort((a, b) => {
    const indexOfA = products.indexOf(a)
    const indexOfB = products.indexOf(b)

    const positionOfA = indexOfA !== -1 ? indexOfA : a.position
    const positionOfB = indexOfB !== -1 ? indexOfB : b.position

    return positionOfA - positionOfB
  })

  return shelfItems
}

export const groupByShelfNumber = (products, artifacts) => {
  const shelfItems = [...artifacts, ...products]

  return shelfItems.reduce((acc, row) => {
    const { shelf } = row
    const result = acc

    // If the shelf has been separated yet, create a separate array
    if (!result[shelf]) {
      result[shelf] = {
        products: [],
        artifacts: [],
      }
    }

    // Push product or artifact into the corresponding array
    if (row?.shelfProductId) {
      result[shelf].products.push(row)
    } else {
      result[shelf].artifacts.push(row)
    }

    // Pass the object on to the next loop
    return result
  }, [])
}
