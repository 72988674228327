import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Pagination as LunaPagination } from '@jsluna/react'

const Pagination = ({ pagination: { number, totalPages }, getPage, className }) => (
  <div className={`${classNames('c-pagination u-print-hidden', className)}`}>
    <LunaPagination current={number} total={totalPages} onChange={getPage} showFirstAndLast />
  </div>
)

Pagination.propTypes = {
  pagination: PropTypes.shape({
    number: PropTypes.number,
    totalPages: PropTypes.number,
    size: PropTypes.number,
  }).isRequired,
  getPage: PropTypes.func.isRequired,
  className: PropTypes.string,
}

Pagination.defaultProps = {
  className: null,
}

export default Pagination
