import { selectActiveCategories } from 'src/store/modules/asyncThunks/categories'
import { buildCategoryOptions } from 'src/utils/buildCategoryOptions'
import { hasRequiredPermission } from 'src/utils/permissions'
import { UPDATE_SPACE_ASSIGNMENTS, DELETE_SPACE_ASSIGNMENTS } from 'src/constants/permissions'

import { selectSpaceDetails } from 'src/store/modules/asyncThunks/spaceDetails'
import { selectSpaceAssignment } from '../store/reducer/spacePlanAssignment'
import disabled from './disabled'

function getLayoutTypes(state, spaceId) {
  const space = selectSpaceDetails(state, spaceId)
  const layoutTypes = space.spaceType.layouts.map(l => l.layoutType)
  layoutTypes.sort()
  return layoutTypes
}

const mapStateToProps = (state, ownProps) => {
  const { id, query, user } = ownProps
  const { startDate: defaultStartDate, endDate: defaultEndDate, space } = query
  const isNewAssignment = !id

  const assignment = !isNewAssignment ? selectSpaceAssignment(state) : {}
  const activeCategories = selectActiveCategories(state)

  const layoutTypes = isNewAssignment ? getLayoutTypes(state, space) : []
  const layoutType = layoutTypes[0] || null

  const {
    startDate = defaultStartDate,
    endDate = defaultEndDate,
    title,
    comment,
    isProposition = false,
    assignedCategories = [],
    storeTitleComment,
    hasProducts = false,
  } = assignment

  const isReadOnly = !hasRequiredPermission(user, UPDATE_SPACE_ASSIGNMENTS)
  const canDelete = !isNewAssignment && hasRequiredPermission(user, DELETE_SPACE_ASSIGNMENTS)

  return {
    title,
    comment,
    isProposition,
    space: id ? assignment.id : space,
    categories: assignedCategories.map(c => c.id),
    startDate,
    endDate,
    isReadOnly,
    isNewAssignment,
    selectedCategories: assignedCategories,
    categoryOptions: buildCategoryOptions(activeCategories),
    disabled: disabled({ user, isNewAssignment }),
    storeTitleComment,
    id,
    canDelete,
    hasProducts,
    layoutType,
    layoutTypes,
  }
}

export default mapStateToProps
