export default function handleSubmit(
  {
    title,
    comment = '',
    isProposition,
    startDate,
    endDate,
    space,
    categories,
    storeTitleComment = '',
    layoutType,
  },
  { props: { id, submit } }
) {
  const assignment = {
    title,
    comment,
    isProposition,
    startDate,
    endDate,
    spaceId: space,
    categoryIds: categories,
    storeTitleComment,
    layoutType,
  }

  return submit(assignment, id)
}
