import { ALL_ROLES_BY_ID } from 'src/constants/roles'
import ROUTE_PERMISSIONS from 'src/constants/routePermissions'

const findRoutesForRole = (role = {}) => {
  const { id: roleId } = role || {}
  const roleConfig = ALL_ROLES_BY_ID[roleId]
  if (!roleConfig) {
    return false
  }
  const { routes = [] } = roleConfig
  return routes
}

const isIncludedByRole = (user, path) => {
  const routes = findRoutesForRole(user.role)
  return routes && routes.includes(path)
}

const isIncludedByPermissions = (user, path) => {
  const permissions = (user && user.role && user.role.permissions) || []
  const routePermissions = ROUTE_PERMISSIONS[path] || []
  return permissions.find(permission => routePermissions.includes(permission))
}

// eslint-disable-next-line import/prefer-default-export
export const isAllowedRoute = (user = {}, path) =>
  !!(isIncludedByRole(user, path) || isIncludedByPermissions(user, path))
