import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Card, Heading2 } from '@jsluna/react'

import Section from 'src/components/Section'

import { uploadFormData } from './store/actions'
import { selectLastImportResponse } from './store'
import Form from './Form'

const Imports = ({ upload, uploading }) => (
  <Section>
    <Heading2 data-control="imports-title">Imports</Heading2>
    <Card>
      <Form upload={upload} uploading={uploading} />
    </Card>
  </Section>
)

Imports.propTypes = {
  upload: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  const { uploading } = selectLastImportResponse(state)

  return { uploading }
}

const mapDispatchToProps = {
  upload: uploadFormData,
}

export default connect(mapStateToProps, mapDispatchToProps)(Imports)
