import Api from 'src/api/Base'

class GetCycleStorePSAEntries extends Api {
  requestUrl = `/api/Cycle/${this.getParams().cycleId}/Store/${
    this.getParams().storeId
  }/StorePsa?startDate=${this.getParams().storePsaStartDate}&endDate=${
    this.getParams().storePsaEndDate
  }&ascending=true&pageSize=${this.getParams().page.size}&pageNumber=${
    this.getParams().page.number
  }`
}

export default GetCycleStorePSAEntries
