import createStatus from 'src/utils/statuses'

export const DRAFT_ID = 'draft'
export const APPROVED_ID = 'approved'

const DRAFT = {
  id: DRAFT_ID,
  title: 'Draft',
  transition: [DRAFT_ID, APPROVED_ID],
}

const APPROVED = {
  id: APPROVED_ID,
  title: 'Approved',
  transition: [APPROVED_ID],
}

export const ALL_IDS = [DRAFT_ID, APPROVED_ID]

export const ALL_BY_ID = {
  [DRAFT_ID]: DRAFT,
  [APPROVED_ID]: APPROVED,
}

export const ALL = ALL_IDS.map(id => ALL_BY_ID[id])

export default createStatus(ALL)
