import React from 'react'
import PropTypes from 'prop-types'
import { Heading2 } from '@jsluna/react'

const businessUnitToTitle = {
  food: 'Food',
  'non-food': 'Non-Food',
}

const Header = ({ id, title, businessUnit, groupTitle }) => (
  <Heading2 className="u-print-hidden">
    <span data-control="cycles-detail-title">
      {id ? `${title} ${groupTitle}` : `Create ${businessUnitToTitle[businessUnit]} Cycle`}
    </span>
  </Heading2>
)

Header.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  groupTitle: PropTypes.string,
  businessUnit: PropTypes.oneOf(['food', 'non-food']).isRequired,
}

Header.defaultProps = {
  id: null,
  title: null,
  groupTitle: '',
}

export default Header
