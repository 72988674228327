import React from 'react'
import PropTypes from 'prop-types'
import { Heading2, FilledButton, FlagWrapper, FlagBody, FlagComponent } from '@jsluna/react'
import { Link } from 'react-router'

const Header = ({ id, space, returnTo }) => (
  <FlagWrapper className="ln-u-soft-bottom">
    <FlagBody alignment="top">
      <Heading2>
        <span data-control="space-assignments-details-title">
          {`${space.spaceTitle || space.title} - ${id ? 'Edit' : 'Create'} Space Assignment`}
        </span>
      </Heading2>
    </FlagBody>
    {returnTo && (
      <FlagComponent alignment="top" nowrap>
        <div className="ln-u-soft-left">
          <FilledButton color="dark" element={<Link to={{ pathname: returnTo }} />}>
            Back to the space planner
          </FilledButton>
        </div>
      </FlagComponent>
    )}
  </FlagWrapper>
)

Header.propTypes = {
  id: PropTypes.string,
  space: PropTypes.shape({
    title: PropTypes.string,
    spaceTitle: PropTypes.string,
  }).isRequired,
  returnTo: PropTypes.string,
}

Header.defaultProps = {
  id: null,
  returnTo: null,
}

export default Header
