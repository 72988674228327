import React from 'react'
import PropTypes from 'prop-types'

import { Card, Heading3 } from '@jsluna/react'

import SpacePlanner from 'src/modules/SpacePlanner'
import Section from 'src/components/Section'

const Planner = ({ spacePlan, location }) => {
  const { id, isCyclesPresent } = spacePlan
  return isCyclesPresent ? (
    <Section>
      <Heading3 className="ln-u-visually-hidden">Planner</Heading3>
      <SpacePlanner returnTo={location.pathname} id={id} />
    </Section>
  ) : (
    <Card>This space plan has no cycles</Card>
  )
}

Planner.propTypes = {
  spacePlan: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isCyclesPresent: PropTypes.bool,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default Planner
