export default function handleSubmit(
  { startDate, endDate, title, changeControlStatus, shipperLockStatus, groupId },
  { props: { id, businessUnit, previous, submit } }
) {
  const cycle = {
    endDate,
    title,
    businessUnit,
    changeControlStatus,
    shipperLockStatus,
    startDate,
  }

  if (groupId) {
    cycle.groupId = groupId
  }

  if (id) {
    cycle.id = id
  }

  if (previous && previous.id) {
    cycle.previousCycleId = previous.id
  }

  return submit(cycle, id)
}
