import React from 'react'
import PropTypes from 'prop-types'

import { TextButton, List, ListItem } from '@jsluna/react'

import {
  getLayoutTypeLabel,
  getProduceTableRows,
  isFrozenTypeLayout,
  isProduceTypeLayout,
} from 'src/utils/layoutTypes'

import withDragDropContext from 'src/hoc/withDragDropContext'
import WithRequiredPermissions from 'src/components/WithRequiredPermissions'
import { ADD_SHELVES } from 'src/constants/permissions'

import ShelfCategoriesBulk from './ShelfCategoriesBulk'
import ShelvesUpload from './ShelvesUpload'
import Shelf from './Shelf'
import ProduceTable from './ProduceTable'
import FrozenLayout from './FrozenLayout'

const getAllShelfProducts = shelves => {
  const allShelfProducts = shelves
    .map(shelf => shelf.shelfProducts)
    .reduce((acc, arr) => acc.concat(arr), [])

  return allShelfProducts
}

const Shelves = ({
  spaceAssignmentId,
  ownCategories,
  spaceInstanceCategories,
  shelves,
  layoutType,
  instanceId,
  addShelf,
  minShelves,
  maxShelves,
  quickAddProduct,
  weeks,
  refreshSpaceInstances,
  addShelfProductLayout,
  deleteShelfProductLayout,
  pendingChangeControl,
  shelfProductLayouts,
  spaceType,
  spaceType: { layouts: spaceTypeLayouts },
}) => (
  <div>
    <List type="matrix" className="ln-u-padding-bottom ln-u-text-align-right">
      <ListItem type="matrix">
        <WithRequiredPermissions permission={ADD_SHELVES}>
          <TextButton
            data-control="add-shelf"
            onClick={() => addShelf(instanceId)}
            disabled={shelves.length >= maxShelves}
          >
            Add {getLayoutTypeLabel(layoutType)}
          </TextButton>
        </WithRequiredPermissions>
      </ListItem>
      <ListItem type="matrix">
        <ShelfCategoriesBulk
          shelves={shelves}
          layoutType={layoutType}
          spaceAssignmentId={spaceAssignmentId}
        />
      </ListItem>
      <ListItem type="matrix">
        <ShelvesUpload
          instanceId={instanceId}
          layoutType={layoutType}
          onClose={refreshSpaceInstances}
          shelves={shelves}
          spaceAssignmentId={spaceAssignmentId}
        />
      </ListItem>
    </List>
    <div className="ln-c-striped">
      {shelves.map((shelf, position) => {
        const shelfToDisplay = { ...shelf, spaceAssignmentId }
        return (
          <Shelf
            key={shelf.id}
            shelf={shelfToDisplay}
            layoutType={layoutType}
            position={position}
            shelves={shelves}
            minShelves={minShelves}
            maxShelves={maxShelves}
            quickAddProduct={quickAddProduct}
            weeks={weeks}
            pendingChangeControl={pendingChangeControl}
            spaceType={spaceType}
          />
        )
      })}
    </div>
    {isFrozenTypeLayout(layoutType) && !isProduceTypeLayout(layoutType) && (
      <div className="ln-u-push-top">
        <FrozenLayout
          addFrozenPosition={addShelfProductLayout}
          deleteFrozenPosition={deleteShelfProductLayout}
          instanceId={instanceId}
          layoutType={layoutType}
          shelfProductLayouts={shelfProductLayouts}
          shelfProducts={getAllShelfProducts(shelves)}
          spaceAssignmentId={spaceAssignmentId}
        />
      </div>
    )}

    {isProduceTypeLayout(layoutType) && spaceTypeLayouts.length > 0 && (
      <ProduceTable
        addProducePosition={addShelfProductLayout}
        columns={4}
        deleteProducePosition={deleteShelfProductLayout}
        instanceId={instanceId}
        produceLabel={getLayoutTypeLabel(layoutType)}
        rows={getProduceTableRows(layoutType)}
        shelfProductLayouts={shelfProductLayouts}
        shelfProducts={getAllShelfProducts(shelves)}
        userCategories={ownCategories}
        spaceInstanceCategories={spaceInstanceCategories}
      />
    )}
  </div>
)

Shelves.propTypes = {
  addShelfProductLayout: PropTypes.func,
  deleteShelfProductLayout: PropTypes.func,
  layoutType: PropTypes.string,
  instanceId: PropTypes.string.isRequired,
  addShelf: PropTypes.func.isRequired,
  minShelves: PropTypes.number.isRequired,
  maxShelves: PropTypes.number.isRequired,
  quickAddProduct: PropTypes.string.isRequired,
  weeks: PropTypes.arrayOf(PropTypes.number).isRequired,
  refreshSpaceInstances: PropTypes.func.isRequired,
  pendingChangeControl: PropTypes.shape({}),
  shelfProductLayouts: PropTypes.arrayOf(PropTypes.shape({})),
  spaceAssignmentId: PropTypes.string.isRequired,
  shelves: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  spaceType: PropTypes.shape({
    id: PropTypes.string,
    layouts: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  spaceInstanceCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  ownCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
}

Shelves.defaultProps = {
  addShelfProductLayout: () => {},
  deleteShelfProductLayout: () => {},
  layoutType: null,
  pendingChangeControl: null,
  shelfProductLayouts: [],
}

export default withDragDropContext(Shelves)
