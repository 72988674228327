import React from 'react'
import PropTypes from 'prop-types'
import ChangeControls from 'src/modules/ChangeControls'

const Component = ({ cycle }) => <ChangeControls cycleId={cycle.id} />

Component.propTypes = {
  cycle: PropTypes.shape({ id: PropTypes.string }).isRequired,
}

export default Component
