import { connect } from 'react-redux'

import withModal from 'src/hoc/withModal'
import {
  selectPOSAdviceList,
  selectShelfProductsWithPOS,
  actionUpdateShelfProductsWithPOS,
  actionAddShelfProductsWithPOS,
  fetchShelfProductsWithPOS,
  fetchPOSAdviceList,
} from '../store'

import Form from './Form'

export const mapStateToProps = (state, { posAdviceWithProducts, createPOSFlag }) => {
  return {
    shelfProductList: posAdviceWithProducts?.shelfProductList || [],
    posId: posAdviceWithProducts?.id || '',
    posAdviceList: selectPOSAdviceList(state),
    shelfProductsWithPOS: selectShelfProductsWithPOS(state),
    createPOSFlag,
  }
}
const mapDispatchToProps = {
  actionUpdateShelfProductsWithPOS,
  actionAddShelfProductsWithPOS,
  refreshPOSWithShelfProducts: fetchShelfProductsWithPOS,
  refreshPOSAdviceList: fetchPOSAdviceList,
}

const wrappedComponent = connect(mapStateToProps, mapDispatchToProps)(Form)

export default withModal(wrappedComponent)
