const buildBlanks = (cycles, validCycles = {}) => {
  const blanks = []
  cycles.forEach(cycle => {
    if (!validCycles[cycle.id]) {
      blanks.push({
        id: `cycle-${cycle.id}`,
        startDate: cycle.startDate,
        endDate: cycle.endDate,
      })
    }
  })
  return blanks
}

export default buildBlanks
