import React from 'react'

import loader from 'src/hoc/loader'
import NotFound from 'src/components/Errors/NotFound'
import { fetchNominations } from '../store'

import Nominations from './Nominations'

const getData = async ({ id }, dispatch) => {
  await dispatch(fetchNominations(id))
}

const options = {
  Custom404: (
    <NotFound
      title="Nominations Not Found"
      message="The nomination you are looking for cannot be found."
    />
  ),
  shouldRefresh: () => false,
}

export default loader(getData, options)(Nominations)
