import loader from 'src/hoc/loader'

import { actionClearData, fetchUsers } from './store'
import Users from './Users'

const getData = async (props, dispatch) => {
  await dispatch(fetchUsers())
}

const options = {
  onUnmount: dispatch => dispatch(actionClearData()),
}

export default loader(getData, options)(Users)
