import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { List, ListItem, Button, FilledButton } from '@jsluna/react'
import { ArrowDown, ArrowUp } from '@jsluna/icons'

import Background from './Background'

const SplitButton = ({ isExpanded, toggleExpanded, options, ...rest }) => {
  const filteredOptions = options.filter(_ => _)
  const firstOption = filteredOptions[0] || {}
  const otherOptions = filteredOptions.slice(1)
  const dataControl = rest['data-control']

  const wrapOnClick = onClick => (...args) => {
    onClick(...args)
    toggleExpanded(isExpanded)
  }

  return (
    <div
      className={classNames('c-split-button', isExpanded && 'is-open')}
      data-control={dataControl}
    >
      {isExpanded && <Background onClick={toggleExpanded} />}
      <div className="c-split-button__button" data-control={firstOption['data-control']}>
        <FilledButton onClick={firstOption.onClick} disabled={firstOption.disabled}>
          {firstOption.label}
        </FilledButton>
      </div>
      {otherOptions.length > 0 && (
        <div className="c-split-button__toggle" data-control="toggle">
          <FilledButton aria-expanded={isExpanded} onClick={() => toggleExpanded(isExpanded)}>
            {isExpanded ? <ArrowUp /> : <ArrowDown />}
          </FilledButton>
        </div>
      )}
      {isExpanded && (
        <List role="menu" type="bare" className="c-split-button__menu">
          {otherOptions.map(option =>
            option.type === 'divider' ? (
              <div key="divider" role="presentation" className="c-split-button__menu-divider" />
            ) : (
              <ListItem
                role="presentation"
                className="c-split-button__menu-item"
                key={option.label}
                disabled={option.disabled}
              >
                <div data-control={option['data-control']}>
                  <Button
                    onClick={wrapOnClick(option.onClick)}
                    className={option.className}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </Button>
                </div>
              </ListItem>
            )
          )}
        </List>
      )}
    </div>
  )
}

SplitButton.propTypes = {
  toggleExpanded: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      type: PropTypes.string,
    })
  ).isRequired,
}

SplitButton.defaultProps = {
  isExpanded: false,
}

export default SplitButton
