import Api from 'src/api/Base'

class GetPsaPdfByStoreNumber extends Api {
  requestUrl = `/api/Psa/Cycle/${this.getParams().cycleId}/Store/${
    this.getParams().storeNumber
  }/isSegmentedVersion/${this.getParams().isSegmentedVersion}/DownloadPsaPdf`

  responseType = 'blob'
}

export default GetPsaPdfByStoreNumber
