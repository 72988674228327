import React from 'react'
import { Container, Heading2, SiteLayout } from '@jsluna/react'

import Header from 'src/modules/App/Header/StoreColleague'
import Activities from 'src/modules/App/Activities'

const App = () => {
  const message = window?.PROMCOMM?.MAINTENANCE_MESSAGE || ''
  return (
    <SiteLayout>
      <Header />
      <main>
        <div className="ln-u-soft-top ln-c-header-body">
          <Container soft size="lg">
            <Heading2>Maintenance</Heading2>
            <p>PromComm is currently undergoing maintenance.</p>
            <p>{message}</p>
          </Container>
        </div>
      </main>
      <Activities />
    </SiteLayout>
  )
}

export default App
