import { hasRequiredPermission } from 'src/utils/permissions'

import {
  READ_CHANGE_CONTROLS,
  READ_CYCLES,
  READ_MASTER_PSA,
  READ_NOMINATIONS,
  READ_PSA,
  READ_SPACE_ASSIGNMENTS,
} from 'src/constants/permissions'

import buildTabsWrapper from 'src/components/TabsWrapper'

const tabs = [
  {
    id: 'spaces',
    title: 'Spaces',
    link: ({ businessUnit, id }) => `/cycles/${businessUnit}/${id}`,
    isVisible: ({ id, user }) => !!id && hasRequiredPermission(user, READ_SPACE_ASSIGNMENTS),
  },
  {
    id: 'details',
    title: 'Details',
    link: ({ businessUnit, id }) => `/cycles/${businessUnit}/${id}/details`,
    isVisible: ({ user }) => hasRequiredPermission(user, READ_CYCLES),
  },
  {
    id: 'nominations',
    title: 'Nominations',
    link: ({ businessUnit, id }) => `/cycles/${businessUnit}/${id}/nominations`,
    isVisible: ({ user }) => hasRequiredPermission(user, READ_NOMINATIONS),
  },
  {
    id: 'master-psa',
    title: 'Master PSA',
    link: ({ businessUnit, id }) => `/cycles/${businessUnit}/${id}/master-psa`,
    isVisible: ({ id, user }) => !!id && hasRequiredPermission(user, READ_MASTER_PSA),
  },
  {
    id: 'store-psa',
    title: 'Store PSA',
    link: ({ businessUnit, id }) => `/cycles/${businessUnit}/${id}/store-psa`,
    pattern: ({ businessUnit, id }) => new RegExp(`/cycles/${businessUnit}/${id}/store-psa`, 'i'),
    isVisible: ({ id, user }) => !!id && hasRequiredPermission(user, READ_PSA),
  },
  {
    id: 'change-controls',
    title: 'Change Controls',
    link: ({ businessUnit, id }) => `/cycles/${businessUnit}/${id}/change-controls`,
    isVisible: ({ id, user }) => !!id && hasRequiredPermission(user, READ_CHANGE_CONTROLS),
  },
  {
    id: 'shipper-compliance',
    title: 'Shipper Compliance',
    link: ({ businessUnit, id }) => `/cycles/${businessUnit}/${id}/shipper-compliance`,
    isVisible: ({ id, user }) => !!id && hasRequiredPermission(user, READ_SPACE_ASSIGNMENTS),
  },
  {
    id: 'sspsa-comments',
    title: 'SSPSA Comments',
    link: ({ businessUnit, id }) => `/cycles/${businessUnit}/${id}/sspsa-comments`,
    isVisible: ({ id, user }) => !!id && hasRequiredPermission(user, READ_SPACE_ASSIGNMENTS),
  },
]

export default buildTabsWrapper(tabs, 'cycles')
