import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DRAFT_ID, APPROVED_ID } from 'src/modules/SpacePlans/statuses'
import { APPROVED } from './approvalStatus'

const Status = ({ approval, spacePlanStatus }) => {
  const { status, changedBy = {}, changedAt = '' } = approval
  let content

  if (status === APPROVED) {
    const approvedBy = `${changedBy.name}`
    content = (
      <ul className="ln-o-bare-list">
        <li>Approved by {approvedBy}</li>
        <li>{moment(changedAt).format('Do MMM YYYY')}</li>
      </ul>
    )
  } else {
    switch (spacePlanStatus) {
      case DRAFT_ID:
        content = 'Pending'
        break
      case APPROVED_ID:
        content = 'Not approved'
        break
      default:
        content = undefined
    }
  }

  return <span data-control={`space-plan-approval-status-${status}`}>{content}</span>
}

Status.propTypes = {
  approval: PropTypes.shape({
    status: PropTypes.string,
    changedBy: PropTypes.shape({
      name: PropTypes.string,
    }),
    changedAt: PropTypes.string,
  }).isRequired,
  spacePlanStatus: PropTypes.string.isRequired,
}

export default Status
