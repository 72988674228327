import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup, FilledButton, Select } from '@jsluna/react'

export const TYPE_SSC = 'ssc'
export const TYPE_SSC_NON_JDA = 'ssc-non-jda'
export const TYPE_SKU_RMS_9 = 'sku/rms9'
export const TYPE_SKU_RMS_10 = 'sku/rms10'
export const TYPE_POS_ADVICE = 'pos-advice'

const VALID_TYPES = [TYPE_SSC, TYPE_SSC_NON_JDA, TYPE_SKU_RMS_9, TYPE_SKU_RMS_10, TYPE_POS_ADVICE]

class ImportForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: undefined,
      file: undefined,
    }
    this.onChangeType = this.onChangeType.bind(this)
    this.onChangeFile = this.onChangeFile.bind(this)
    this.submit = this.submit.bind(this)
  }

  onChangeFile(e) {
    const { files } = e.target
    this.setState({ file: files.length ? files[0] : undefined })
  }

  onChangeType(e) {
    const { value } = e.target
    const type = VALID_TYPES.includes(value) ? value : undefined
    this.setState({ type })
  }

  submit(e) {
    e.preventDefault()
    const { type, file } = this.state
    const { upload } = this.props

    if (type && file) {
      upload(type, file)
    }
  }

  /* eslint-disable max-len */
  render() {
    const { uploading } = this.props
    const { file, type } = this.state

    const importTypes = [
      { value: TYPE_SSC, label: 'Store Space Clusters CSV (JDA)' },
      { value: TYPE_SSC_NON_JDA, label: 'Store Space Clusters CSV (Non-JDA)' },
      { value: TYPE_SKU_RMS_9, label: 'SKUs CSV (RMS 9)' },
      { value: TYPE_SKU_RMS_10, label: 'SKUs CSV (RMS 10)' },
      { value: TYPE_POS_ADVICE, label: 'POS Advice' },
    ]

    return (
      <Form onSubmit={this.submit}>
        <p>
          To export files in CSV format. In Microsoft Excel select <strong>File</strong>{' '}
          &rarr;&nbsp;
          <strong>Save As...</strong> &rarr; then choose{' '}
          <strong>CSV UTF-8 (Comma delimited) (*.csv)</strong> as the <strong>File Format</strong>.
        </p>
        <Select
          className="ln-u-push-bottom"
          label="Type"
          required
          name="import-type"
          onChange={this.onChangeType}
          placeholder="Please select"
          options={importTypes}
        />
        <FormGroup name="import-input-group">
          <label className="ln-c-label" htmlFor="import-input">
            Choose a file
          </label>
          <div className="ln-u-soft-bottom">
            <input
              id="import-input"
              style={{ lineHeight: 'initial' }}
              type="file"
              accept=".csv, .gz, text/csv, application/x-gzip, application/gzip" /* https://stackoverflow.com/a/23706177/186965 */
              onChange={this.onChangeFile}
            />
          </div>
        </FormGroup>
        <div>
          <FilledButton type="submit" disabled={uploading || !file || !type}>
            {uploading ? 'Uploading...' : 'Upload'}
          </FilledButton>
        </div>
      </Form>
    )
  }
  /* eslint-enable max-len */
}

ImportForm.propTypes = {
  upload: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
}

export default ImportForm
