import Api from 'src/api/Base'

class PatchSpaceDetailById extends Api {
  requestUrl = `/api/space/${this.getParams().id}`

  method = 'PATCH'

  formatRequestData = params => params.space
}

export default PatchSpaceDetailById
