import React from 'react'
import { Route, IndexRoute } from 'react-router'

import { authorized } from 'src/hoc/auth'

import GroupsList from 'src/modules/Groups'
import GroupsDetail from 'src/modules/GroupsDetail'

const route = (
  <Route name="Groups" path="groups" showInNav>
    <IndexRoute component={authorized(GroupsList)} />
    <Route path="create" component={authorized(GroupsDetail)}>
      <IndexRoute component={authorized(GroupsDetail)} />
    </Route>
    <Route path=":id" component={authorized(GroupsDetail)} />
  </Route>
)

export default route
