import { MASTER_PSA_RESET_FILTER, MASTER_PSA_SET_FILTER } from '../actions'

const initialState = {
  category: '',
  space: '',
  buyers: [],
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MASTER_PSA_SET_FILTER: {
      return {
        ...state,
        [payload.filterType]: payload.value,
      }
    }
    case MASTER_PSA_RESET_FILTER: {
      return initialState
    }
    default:
      return state
  }
}

export default reducer
