import redirectAction from 'src/store/data/actions/redirect'
import { fetchFeatureToggles } from 'src/modules/FeatureToggles/store'
import { fetchUserSelf } from 'src/modules/UserSelf/store'
import { SET_TOKEN } from 'src/constants/api'

const login = ({ redirect, accessToken }) => async dispatch => {
  dispatch({ type: SET_TOKEN, payload: accessToken })

  await Promise.resolve()

  await dispatch(fetchUserSelf())
  await dispatch(fetchFeatureToggles())
  dispatch(redirectAction(redirect))
}

export const loginStoreColleague = ({ redirect, accessToken }) => async dispatch => {
  dispatch({ type: SET_TOKEN, payload: accessToken })

  /*
  TODO: We currently use Promise.Resolve to solve a
  timing issue which was discovered when we updated React-Redux v6
  this has been raised as a bug PROMCOMM-1848
  */
  await Promise.resolve()

  dispatch(redirectAction(redirect))
}

export default login
