import Api from 'src/api/Base'

class POSAdviceListImport extends Api {
  requestUrl = `/api/import/posadvice`

  method = 'POST'

  formatRequestData = params => params.formData
}
export default POSAdviceListImport
